import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appReloadDirective]'
})
export class ReloadDirectiveDirective implements OnChanges {

  @Input() appReloadDirective: boolean | undefined;

  constructor(
    private templateRef: TemplateRef<any>, 
    private viewContainerRef: ViewContainerRef) {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['appReloadDirective'] && changes['appReloadDirective'].previousValue!=undefined) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}

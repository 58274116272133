import {
  Component,
  OnInit,
  HostListener
} from "@angular/core";
import { ServicioGeneral } from "../_services/servicio-general.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment.novotaxi';
import { NodeService } from "src/app/_services/node.service";

export interface DataModel {
  menuListado: string;
}

@Component({
  selector: "app-board-admin",
  templateUrl: "./board-admin.component.html",
  styleUrls: ["./board-admin.component.css"],
})

export class BoardNovotaxiAdminComponent implements OnInit {

  API_URL: string = environment.API_URL;
  //content?: string;
  content: any = [];
  myForm: UntypedFormGroup;
  currentUser: any;
  currentEnv: string;
  totalElements: number = 0;

  showDiv = {
    crear: false,
    actualizar: false,
    eliminar: false
  }

  Listado: any = [];
  miData: any = [];
  menuListado: any = [];
  menuPerfil: any = [];

  seleccionado: string;
  miid;

  menuOption: String;

  formVisible: boolean;
  hijosList: any = [];
  menuPadre: any = [];
  selectedOption;
  environment = environment;
  getScreenWidth: any;

  constructor(
    private servicioGeneral: ServicioGeneral,
    private formBuilder: UntypedFormBuilder,
    private token: TokenStorageService,
    private router: Router,
    private _nodeService: NodeService
  ) {
    this.buildForm();
    this.currentEnv = environment.environment;
    this.getScreenWidth = window.innerWidth;
  }

  public buildForm() {
    this.myForm = this.formBuilder.group({
      opcionesmenu: [null],
    });
  }

  setSelectedOption(valueSelected) {

    if (this.currentUser.rol.includes(valueSelected.rol.id)) {
      this.selectedOption = valueSelected;
    } else {
      this.selectedOption = { action: -1, nombre: 'Home' };
    }
  }

  redirectUrl(menu) {
    this.router.navigateByUrl(menu.url)
  }

  public obtenerContenido() {
    //Get the value of your stypeControl
    //console.log('obtenerContenido ', this.myForm.controls["opcionesmenu"].value);

    return this.myForm.controls["opcionesmenu"].value;
    //return this.seleccionado['opcionesmenu'].value;
  }

  public onSubmit(data: DataModel) { }

  ngOnInit(): void {

    this.currentUser = this.token.getUser();
    this.verificarUserRol();

    /*
    this.userService.getAdminBoard().subscribe({
      next: (data) => {
        this.content = data;
        //console.log(this.content);
      },
      error: (err) => {
        //this.content = JSON.parse(err.error).message;
      },
    });
    */

    this.getMenuLateralList();
  }

  sendData(titulo: string) {
    this._nodeService.sendData(titulo);
  }

  getMenuLateralList() {
    this.servicioGeneral.getMenuLateralList().subscribe((data) => {
      this.menuListado = data['Menu']
    });
  }

  changeCollapse(menu) {
    let div = document.getElementById('desplegable' + menu.id);
    menu.isRowActive = !div.classList.contains('show');
  }

  esrolmenu;
  verificarMenuRol() {
    // return this.currentUser.id;
    for (let menulistado of this.menuListado) {
      if (this.currentUser.id == menulistado.id) {
        for (let miuser of menulistado.rol) {
          this.esrolmenu = miuser.id;
          return this.esrolmenu;
        }
      }
    }
  }

  esrol: any = [];
  verificarUserRol() {
    this.esrol = this.currentUser.rol;
    return this.esrol;
  }

  scrollIf(isDown) {
    if (isDown) {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
    } else {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }

  showMenu(submenu, currentUser) {
    let canShow = false;
    if (submenu.rol != null && currentUser.rol.includes(submenu.rol['id'])) {
      canShow = true;
      if (submenu.action == 30 || submenu.action == 31 || submenu.action == 3) {
        if (currentUser.rol.includes(2) && !currentUser.rol.includes(3)) {
          canShow = false;
        } else {
          canShow = true;
        }
      }
    }
    return canShow;
  }

  getCorporativo() {
    let style = '';
    return style;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }
}



import { IConductor } from './../../conductores/conductor.model';
import { Licencia } from 'src/app/model/Emisora';
import { Recaudacion } from './../../model/novotaxi/recaudacion/recaudacion.model';
import { NotificationService } from './../../_services/notification.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ConductorService } from './../../conductores/services/conductor.service';
import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-ranking-conductores',
  templateUrl: './ranking-conductores.component.html',
  styleUrls: ['./ranking-conductores.component.css']
})
export class RankingConductoresComponent implements OnInit {

  displayedColumns: string[] = ['posicion', 'licencia', 'conductor.nombre', 'diasTrabajados', 'importeTotal', 'importeDia', 'kmTotal', 'kmDia'];

  conductores: IConductor[];
  isSearch: Boolean = false;
  recaudaciones: Recaudacion[];
  rankingData: Ranking[] = [];
  dataSource;
  currentDate: Date = new Date();
  form: FormGroup;
  fechaInicio;
  fechaFin;
  listColumns;

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  constructor(
    private conductorService: ConductorService,
    private recaudacionService: RecaudacionService,
    private _notificationService: NotificationService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.fechaInicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
    this.fechaFin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.initiateForm();

    
    this.searchRanking();
    this.listColumns = {
      'Posición':true,
      'Licencia': true,
      'Conductor': true,
      'Días trabajados': true,
      'Importe total': true,
      'Importe por día': true,
      'Km. totales': true,
      'Km. por día': true
    }
  }

  initiateForm() {
    this.form = new FormGroup({
      fechaInicio: new FormControl(this.fechaInicio),
      fechaFin: new FormControl(this.fechaFin),
    })
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  getConductores() {
    this.conductorService.query().subscribe((res) => {
      this.conductores = res['conductores']
    });
  }

  searchRanking() {
    this.rankingData = []; //Para que el contenido no se duplique cada vez que se pulsa 'Buscar'
    if (this.isValidFieldsSearch()) {

      let searchMap = {
        fechaInicio: this.form.value.fechaInicio,
        fechaFinal: this.form.value.fechaFin,
      };

      this.recaudacionService.searchRecaudaciones(searchMap).subscribe((data) => {

        if (data['status'] == '630') {
          this.isSearch = false
          this._notificationService.info("INFO", "No se han encontrado datos", 3000)
          this.recaudaciones = [];
          this.dataSource = []
        } else {
          this.isSearch = true;
          this.recaudaciones = data['recaudaciones'];
          this.recaudaciones = this.recaudaciones.filter(recaudacion => recaudacion.no_computa == false)
          this.createRankingObject()
        }

        /*this.dataSource = new MatTableDataSource(this.rankingData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
        //this.dataSource.filterPredicate = this.getFilterPredicate();

      })
    }
  }

  isValidFieldsSearch() {
    if (this.form.value.fechaInicio == undefined || this.form.value.fechaFin == undefined) {
      this._notificationService.warning("¡Atención!", "Debe rellenar todos los campos de búsqueda", 3000);
      return false;
    } else {
      if (this.form.value.fechaInicio > this.form.value.fechaFin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }

  formatDateForBE(dateString: string): string {
    if (dateString) {
      const splitDate = dateString.split('-');

      const date = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      return this.datePipe.transform(date, 'yyyy-MM-dd')
    }
    return null;
  }

  createRankingObject() {

    let groupedConductores = this.recaudaciones.reduce((result: any, recaudacion: any) => ({ //Agrupamos las recaudaciones por conductor
      ...result,
      [recaudacion.conductor?.id]: [...(result[recaudacion.conductor?.id] || []), recaudacion]
    }), [])
    groupedConductores = Object.values(groupedConductores)

    groupedConductores.forEach(
      conductor => {
        var licencia = conductor[0].licencia ?? null;
        var conductorRanking = conductor[0].conductor ?? null;
        var diasTrabajados = conductor.length;
        var importeTotal = 0;
        var kmTotal = 0;
        conductor.forEach(
          grupo => {
            grupo.informe?.importe_bruto ? importeTotal += grupo.informe?.importe_bruto : 0;
            grupo.kilometraje?.km_totales ? kmTotal += grupo.kilometraje?.km_totales : 0;
          }
        )

        let ranking: Ranking = {
          licencia: licencia,
          conductor: conductorRanking,
          diasTrabajados: diasTrabajados,
          importeTotal: importeTotal,
          importeDia: Math.round(importeTotal / diasTrabajados * 100) / 100,
          kmTotal: kmTotal,
          kmDia: Math.round(kmTotal / diasTrabajados * 100) / 100
        }

        this.rankingData.push(ranking)
      }
    )

    this.dataSource = new MatTableDataSource(this.rankingData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
  }

}

export interface Ranking {
  licencia: Licencia,
  conductor: IConductor,
  diasTrabajados: number,
  importeTotal: number,
  kmTotal: number,
  kmDia: number,
  importeDia: number
}

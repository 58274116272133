<div class="page__content">
  <div class="content__inner">
    <div class="content__login">
      <div class="card__login">
        <form [formGroup]="form">
          <div *ngIf="!isLoggedIn && !tfaFlag && !resetQR">

            <div class="d-flex flex-row align-items-center mb-12" *ngIf="currentEnv == 'novotaxi'">
              <div class="logo-compania"></div>
              <h2 class="m-0 ml-2 nombre-compania">Novotaxi</h2>
            </div>

            <h2 class="text-uppercase font-weight-normal" *ngIf="currentEnv == 'novotaxi'">Empieza ahora</h2>
            <h1>{{currentEnv === 'novotaxi' ? '¡Bienvenido!' : 'Iniciar sesión'}}</h1>

            <div class="form-group">
              <input type="email" class="form-control" name="email" formControlName="email" required email
                placeholder="{{currentEnv === 'novotaxi' ? 'Introduce tu correo' : 'Email'}}" [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}" />
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="password" formControlName="password"
                placeholder="{{currentEnv === 'novotaxi' ? 'Introduce tu contraseña' : 'Contraseña'}}"
                [ngClass]="{'is-invalid': submitted && this.form.controls['password'].errors}" />
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" (click)="onSubmit()">{{currentEnv === 'novotaxi' ? 'Entrar' : 'Iniciar sesión'}}</button>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </div>

            <p class="forgot-password-wrapper mt-4 text-muted" *ngIf="currentEnv == 'novotaxi'">
              ¿Has olvidado tu contraseña?
              <a routerLink="../forgot-password" class="forgot-password">{{currentEnv === 'novotaxi' ? 'Recupérala' : '¿Olvidó la contraseña?'}}</a>
            </p>

            <a routerLink="../forgot-password" class="forgot-password" *ngIf="currentEnv != 'novotaxi'">¿Olvidó la contraseña?</a>

            <!--<div class="d-flex flex-row justify-content-around mt-12 align-items-center text-muted" *ngIf="currentEnv == 'novotaxi'">
              <p class="m-0">Política de privacidad</p>
              <p class="m-0">Términos de uso</p>
            </div>-->

          </div>

          <div *ngIf="tfaFlag">
            <input type="text" class="form-control mb-3 text-center" autocomplete="off"
              placeholder="Código Authenticator" formControlName="totpkey" name="totpkey"
              [ngClass]="{'is-invalid': submitted && this.form.controls['totpkey'].errors}" maxlength="6" />
            <div class="form-group">
              <button class="btn btn-primary btn-block" (click)="loginTfaEnabled()">Confirmar</button>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </div>
          </div>
        </form>
        <div *ngIf="resetQR && !resetedQR" class="centerQR">
          <div id="qr">
            Se ha solicitado un reestablecimiento de Two Factor.
            Vuelva a escanear el código QR con la aplicación Authenticator en su móvil
            <div>
              <img [src]=qr.secretURL class="m-2" />

              <form [formGroup]="form2" (ngSubmit)="guardarQR()">
                <div class="d-flex justify-content-center">
                  <input type="text" name="totpkey" formControlName="totpkey"
                    class="col-sm-4 mr-2 text-center form-control" placeholder="Código Authenticator" maxlength="6"
                    [ngClass]="{'is-invalid': submitted && this.form2.controls['totpkey'].errors}">
                  <button type="submit" class="btn btn-primary m-left">
                    Activar 2FA
                  </button>
                </div>
              </form>
            </div>
            <div *ngIf="errorQR" class="m-2">
              <p>Error al activar doble factor.
                Por favor vuelva a escaner el código QR e introduzca los 6 dígitos.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="alert alert-success" *ngIf="isLoggedIn">
          <div *ngIf="midata | json">
            <p>
              Conectado como <br /><strong>{{ midata["nombre"] }}</strong>
            </p>
            <p>
              MI id es: <br /><strong>{{ midata["id"] }}</strong>
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="text-center">
      <p *ngIf="resetedQR">Two factor activado, se le redirigirá a la página de login de nuevo</p>
    </div>
  </div>
</div>
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IConductor } from 'src/app/conductores/conductor.model';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { Licencia } from 'src/app/model/Emisora';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { Informe } from 'src/app/model/novotaxi/recaudacion/informe.model';
import { Kilometraje } from 'src/app/model/novotaxi/recaudacion/kilometraje.model';
import { OperacionesConductor } from 'src/app/model/novotaxi/recaudacion/operaciones-conductor.model';
import { Recaudacion, TipoTarjeta, TipoVale } from 'src/app/model/novotaxi/recaudacion/recaudacion.model';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { DataRecaudacion } from '../listado-recaudacion';
import { DiasLibranzaService } from 'src/app/_services/novotaxi/dias-libranza.service';
import { ConductorSueldo } from 'src/app/model/novotaxi/conductor-sueldo.model';
import { SueldoServiceService } from 'src/app/conductores/services/sueldo-service.service';

@Component({
  selector: 'app-create-update-recaudacion',
  templateUrl: './create-update-recaudacion.component.html',
  styleUrls: ['./create-update-recaudacion.component.css']
})

export class CreateUpdateRecaudacionComponent implements OnInit {

  // -- Variables para la actualización
  no_computa: boolean = false;
  recaudacionToSave: Recaudacion = {};

  // -- Variables para la creación
  kilometraje: Kilometraje = new Kilometraje();
  operacionesConductor: OperacionesConductor = new OperacionesConductor();
  Informe: Informe = new Informe();
  fechaAlta: Date;
  selectedIdLicencia: number = null;
  selectedIdConductor: number = null;
  selectedConductor: IConductor;
  licencias: Licencia[] = [];
  conductores: IConductor[] = [];
  porcentaje = null;
  salarioFijo = null;
  dia_libranza = null;
  conductorPorcentaje: IConductor;
  hasLicencias: boolean = false;
  isValidDiaLibranza: boolean = true;

  // -- Variables control de roles
  currentUser;
  userSaved: Usuario = null;
  isUserAdmin: boolean = true;

  // -- Variables para array de vales
  sum: number = 0;
  valesList = [{ tipo: null, importe: '' }];
  valesSaved = [];
  tipoValesList: TipoVale[] = [];

  // -- Variables para array de tarjetas
  sumTarjetas: number = 0;
  tarjetasList = [{ tipo: null, importe: '' }];
  tarjetasSaved = [];
  tipoTarjetasList: TipoTarjeta[] = [];

  sueldoConductor: ConductorSueldo = null;

  hasPorcentaje: boolean = true;

  @Input() data: DataRecaudacion;
  @Output() mostrarFormulario = new EventEmitter<DataRecaudacion>();

  constructor(
    private _recaudacionService: RecaudacionService,
    private _tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private _notificationService: NotificationService,
    private _licenciaService: LicenciaServiece,
    private _conductorService: ConductorService,
    private _diaLibranzaService: DiasLibranzaService,
    private _sueldoService: SueldoServiceService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
    this.getTipoVales();
    this.getTipoTarjetas();

    if (!this.data.isForUpdate) {
      this.getLicencias();
      this.recaudacionToSave.kilometraje = this.kilometraje;
      this.recaudacionToSave.operacion_conductor = this.operacionesConductor;
      this.recaudacionToSave.informe = this.Informe;
      this.tarjetasList.pop();
      this.tarjetasList.push({ tipo: 1, importe: '' });

    } else {
      let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

      if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && this.data.recaudacion.fechaAlta != currentDate) {
        this._notificationService.info("INFO", "No tiene permisos, solo puede actualizar una recaudación del día actual", 3000);
        this.isUserAdmin = false;
      } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && this.data.recaudacion.fechaAlta != currentDate) {
        this._notificationService.info("INFO", "No tiene permisos, solo puede actualizar una recaudación del día actual", 3000);
        this.isUserAdmin = false;
      } else {
        this.isUserAdmin = true;
      }

      this.recaudacionToSave = JSON.parse(JSON.stringify(this.data.recaudacion));
      this.selectedIdLicencia = this.recaudacionToSave.licencia.id;

      this.selectedIdConductor = null;
      this.getConductores();
      this.selectedIdConductor = this.recaudacionToSave.conductor.id;
      this.sueldoConductor = this.recaudacionToSave.conductor.sueldos.find(sueldo => sueldo.licencia.id == this.selectedIdLicencia);
      // this.porcentaje = this.recaudacionToSave.conductor?.porcentaje;
      // this.salarioFijo = this.recaudacionToSave.conductor?.salario_fijo;
      this.fechaAlta = new Date(this.data.recaudacion.fechaAlta.toString().split("-").reverse().join("-"));

      this.getLicencias();
      this.setValesList();
      this.setTarjetasList();
      this.dia_libranza = this.getDiaLibranza(this.recaudacionToSave.conductor, this.recaudacionToSave.licencia);
    }
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  getValue(event) {
    this.fechaAlta = event.target.value;
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe(
      (data) => {
        this.licencias = data.licencias;
        this.hasLicencias = true;

        if (data.licencias == null) {
          this.hasLicencias = false;
          this._notificationService.info("INFO", "No existen licencias disponibles para crear una recaudación", 3000);
        }
      });
    if (this.licencias[0]?.vehiculo?.length >= 0) {
      this.licencias[0].vehiculo = null;
    }
  }

  getConductores(event?) {
    this.selectedIdConductor = null;

    if (this.data.isForUpdate) {
      if (this.selectedIdLicencia != this.data.recaudacion.licencia.id) {
        this.selectedIdConductor = null;
      }
    }
    this._conductorService.getConductorByLicencia(this.selectedIdLicencia).subscribe((res) => {
      this.conductores = res['conductores'];
      if (this.conductores == null) {
        this._notificationService.info("INFO", "No existen conductores para la licencia seleccionada, seleccione otra licencia", 3000);
        this.sueldoConductor = null;
      }
    });
  }

  // Rehacer
  getPorcentajeOrSalario(event) {
    this.getLastRecaudacion();

    this.conductorPorcentaje = this.conductores.find((conductor) => conductor.id == event?.target.value);
    this.sueldoConductor = this.conductorPorcentaje.sueldos.find(sueldo => sueldo.licencia.id == this.selectedIdLicencia);
    this.dia_libranza = this.getDiaLibranza(this.conductorPorcentaje);
  }

  getLastRecaudacion() {
    let map = {
      'idConductor': this.selectedIdConductor,
      'idLicencia': this.selectedIdLicencia,
    };

    this._recaudacionService.getLastRecaudacion(map).subscribe((data) => {
      let recaudacion = data['recaudacion'];

      if (data['status'] == 616) {
        this.recaudacionToSave.kilometraje.km_entrada = recaudacion.kilometraje.km_salida;
        this.recaudacionToSave.taximetro_entrada = recaudacion.taximetro_salida;
        this.setValuesNoComputa();
        this.validateDiaLibranza();
      } else {
        this.recaudacionToSave.kilometraje.km_entrada = 0;
        this.recaudacionToSave.taximetro_entrada = 0;
        this.setValuesNoComputa();
        this.validateDiaLibranza();
      }

    })
  }

  getDiaLibranza(conductor, licencia?) {
    let licenciaSave = this.licencias.find(licencia => licencia.id == this.selectedIdLicencia);

    let libranza;
    if (conductor?.dia_libranza) {
      libranza = conductor.dia_libranza;
    } else {
      if (licenciaSave) {
        libranza = licenciaSave?.dia_libranza;
      } else {
        libranza = licencia?.dia_libranza;
      }
    }

    switch (libranza) {
      case 'L':
        return 'Lunes';
      case 'M':
        return 'Martes';
      case 'X':
        return 'Miércoles';
      case 'J':
        return 'Jueves';
      case 'V':
        return 'Viernes';
      case 'S':
        return 'Sábado';
      case 'D':
        return 'Domingo';
      default:
        return null;
    };
  }

  getTipoVales() {
    this._recaudacionService.getallTiposVales().subscribe((data) => {
      this.tipoValesList = data['tiposVales'];
    })
  }

  getTipoTarjetas() {
    this._recaudacionService.getallTiposTarjetas().subscribe((data) => {
      this.tipoTarjetasList = data['tiposTarjeta'];
    })
  }

  setValesList() {
    if (this.recaudacionToSave?.vales?.length > 0) {
      this.valesList.pop();
      this.recaudacionToSave.vales.forEach(vale => {
        this.valesList.push({ tipo: vale.tipo.id, importe: vale.importe })
      });
    }
    this.getSum();
  }

  setTarjetasList() {
    if (this.recaudacionToSave?.tarjetas?.length > 0) {
      this.tarjetasList.pop();
      this.recaudacionToSave.tarjetas.forEach(tarjeta => {
        this.tarjetasList.push({ tipo: tarjeta.tipo.id, importe: tarjeta.importe });
      });
    }
    this.getSumTarjetas();
  }

  addVales() {
    this.valesList.push({ tipo: null, importe: '' });
  }

  removeVales(i) {
    this.valesList.splice(i, 1);
    this.getSum();
  }

  addTarjeta() {
    this.tarjetasList.push({ tipo: 1, importe: '' });
  }

  removeTarjeta(i) {
    this.tarjetasList.splice(i, 1);
    this.getSumTarjetas();
  }

  getSum() {
    this.sum = 0;
    this.valesList.forEach((a) => {
      var y: number = + a.importe;
      this.sum += y;
    })
    this.recaudacionToSave.total_vales = this.sum;
  }

  getSumTarjetas() {
    this.sumTarjetas = 0;
    this.tarjetasList.forEach((a) => {
      var y: number = + a.importe;
      this.sumTarjetas += y;
    });
    this.recaudacionToSave.total_tarjeta = this.sumTarjetas;
  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = "guardar"

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.createRecaudacion();
      } else {
        null;
      }
    });
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm(true);
      } else {
        null;
      }
    });
  }


  // Añadir en el modelo tipoSueldo y cantidad (del objeto sueldoConductor) y aqui setearselo a la recaudación
  async createRecaudacion() {
    this.saveOperations();

    let recaudacionSaved = new Recaudacion(this.recaudacionToSave);

    if (this.selectedIdConductor == null) {
      recaudacionSaved.conductor = null;
    }

    if (this.selectedIdLicencia == null) {
      recaudacionSaved.licencia = null;
    }

    if (recaudacionSaved.conductor?.id != null && recaudacionSaved.licencia?.id != null) {

      if (this.validateNegativeNumbers()) {
        if (recaudacionSaved.licencia.fechaBaja != null) {
          this._notificationService.error('ERROR', "No se puede crear una recaudación si la licencia está dada de baja", 3000);
        }

        recaudacionSaved.licencia.vehiculo = null;
        recaudacionSaved.licencia.fechaBaja = null;
        recaudacionSaved.tipoSueldo = this.sueldoConductor.tipoSueldo;
        recaudacionSaved.cantidad = this.sueldoConductor.cantidad;
        recaudacionSaved.efectivo = this.calcularEfectivo();

        console.log("Recaudación: ", recaudacionSaved);

        this._recaudacionService.saveUpdateRecaudacion(recaudacionSaved).subscribe((result) => {
          if (result['status'] == 608) {
            this.data.recaudacion = result['recaudacion'];
            this._notificationService.success('OK', "Recaudación creada correctamente", 3000);
            this.resetearForm(false);
          } else {
            this._notificationService.error('ERROR', result['message']['message'], 3000);
          }
        });
      } else {
        this._notificationService.error("ERROR", "No pueden existir valores negativos para crear la recaudación", 3000);
      }

    } else {
      this._notificationService.info("INFO", "No se puede crear una recaudación sin licencia ni conductor", 3000);
    }
  }

  convertDate(fecha) {
    const splitDate = fecha.split('-');

    const date = new Date(
      Number(splitDate[2]),   // year
      Number(splitDate[1]) - 1, // month
      Number(splitDate[0])    // day
    );
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  async validateDiaLibranza() {
    let dayOfDate;
    let date;
    let currentDate;
    this.isValidDiaLibranza = true;

    if (this.selectedIdLicencia != null && this.selectedIdConductor != null) {

      this.recaudacionToSave.licencia = this.licencias.find(licencia => licencia.id == this.selectedIdLicencia);
      this.recaudacionToSave.conductor = this.conductores?.find(conductor => conductor.id == this.selectedIdConductor);

      if (this.fechaAlta) {
        date = new Date(this.fechaAlta);
        dayOfDate = new Date(date).toLocaleString('es', { weekday: 'long' });
      } else {
        currentDate = this.datePipe.transform(new Date, 'yyyy-MM-dd');
        this.fechaAlta = currentDate;
        dayOfDate = currentDate.toLocaleString('es', { weekday: 'long' });
      }

      let map = {
        'fecha': this.fechaAlta,
        'idLicencia': this.selectedIdLicencia,
        'idConductor': this.selectedIdConductor,
      };

      await this._diaLibranzaService.checkDiasLibranza(map).subscribe(async (data) => {

        if (data['diaActual'] || data['diaCambio']) {
          if (data['permitirRecaudacion']) {
            this.isValidDiaLibranza = true;
          } else {
            this.isValidDiaLibranza = false;
          }

        } else {
          this.isValidDiaLibranza = true;

          if (dayOfDate.toLowerCase() == 'sábado' || dayOfDate.toLowerCase() == 'domingo') {
            if (date.getDate() % 2 != 0 && parseInt(this.recaudacionToSave.licencia.numero_licencia) % 2 == 0) {
              this.isValidDiaLibranza = false;
            } else if (date.getDate() % 2 == 0 && parseInt(this.recaudacionToSave.licencia.numero_licencia) % 2 != 0) {
              this.isValidDiaLibranza = false;
            }
          }
          if (dayOfDate.toLowerCase() == this.dia_libranza?.toLowerCase()) {
            this.isValidDiaLibranza = false;
          }
        }

        if (!this.isValidDiaLibranza) {
          this._notificationService.info("INFO", "No puede crear una recaudación para el día de la semana que libra el conductor", 3000);
        }
      });
    }
  }

  validateNegativeNumbers() {
    if (this.recaudacionToSave.kilometraje.km_totales < 0 || this.recaudacionToSave.kilometraje.km_entrada < 0 || this.recaudacionToSave.kilometraje.km_salida < 0 || this.recaudacionToSave.taximetro_entrada < 0 || this.recaudacionToSave.taximetro_salida < 0) {
      return false;
    }
    return true;
  }

  saveOperations() {
    this.recaudacionToSave.no_computa = this.no_computa;
    this.recaudacionToSave.informe.importe_bruto = this.calcularImporteBruto();
    this.recaudacionToSave.informe.importe_conductor = this.calcularImporteConductor()
    this.recaudacionToSave.informe.importe_propietario = this.calcularImportePropietario()

    this.recaudacionToSave.informe.total_invertaxi = this.calcularDiferenciaConductor();
    this.recaudacionToSave.informe.total_propietario = this.recaudacionToSave.informe.importe_propietario;
    this.recaudacionToSave.kilometraje.km_totales = (this.recaudacionToSave.kilometraje.km_salida ?? 0) - (this.recaudacionToSave.kilometraje.km_entrada ?? 0);

    this.recaudacionToSave.usuario = this.userSaved;
    // this.recaudacionToSave.licencia.vehiculo = null;
    this.recaudacionToSave.total_incidencias = this.recaudacionToSave?.incidencias ?? 0;
    this.recaudacionToSave.total_vales = this.sum ?? 0;
    this.recaudacionToSave.total_tarjeta = this.sumTarjetas ?? 0;
    this.recaudacionToSave.fechaAlta = this.datePipe.transform(this.fechaAlta, 'dd-MM-yyyy')

    this.recaudacionToSave.licencia = this.licencias.find(licencia => licencia.id == this.selectedIdLicencia);
    this.recaudacionToSave.conductor = this.conductores?.find(conductor => conductor.id == this.selectedIdConductor);

    if (this.recaudacionToSave.conductor) {
      this.recaudacionToSave.conductor.fechaAlta = this.convertDate(this.recaudacionToSave.conductor.fechaAlta);
      if (this.recaudacionToSave?.conductor?.fechaVencimientoBTP) {
        this.recaudacionToSave.conductor.fechaVencimientoBTP = this.convertDate(this.recaudacionToSave?.conductor?.fechaVencimientoBTP);
      }
    }

    this.setTipoVale();
    this.setTipoTarjeta();

    this.recaudacionToSave.vales = this.validateVales();
    this.recaudacionToSave.tarjetas = this.validateTarjeta();
  }

  setTipoVale() {
    this.valesList.forEach((vale) => {
      if (vale.tipo != undefined && vale.importe != null) {
        let tipoVale = this.tipoValesList.find(tipo => tipo.id == vale.tipo);
        vale.tipo = tipoVale;
      }
    })
  }

  setTipoTarjeta() {
    this.tarjetasList.forEach((tarjeta) => {
      if (tarjeta.tipo != undefined && tarjeta.importe != null) {
        let tipoTarjeta = this.tipoTarjetasList.find(tipo => tipo.id == tarjeta.tipo);
        tarjeta.tipo = tipoTarjeta;
      }
    })
  }

  validateVales() {
    this.valesList.forEach((vale) => {
      if (vale.tipo != null) {
        this.valesSaved.push({ tipo: vale.tipo, importe: vale.importe });
      }
    })
    return this.valesSaved;
  }

  validateTarjeta() {
    this.tarjetasList.forEach((tarjeta) => {
      if (tarjeta.tipo != null && tarjeta.importe != '') {
        this.tarjetasSaved.push({ tipo: tarjeta.tipo, importe: tarjeta.importe });
      }
    })
    return this.tarjetasSaved;
  }

  resetearForm(isCancel: Boolean) {

    if (isCancel) {
      this.data.recaudacion = this.data.recaudacion;
    }

    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }

  setValuesNoComputa() {

    if (this.no_computa) {
      this.recaudacionToSave.kilometraje.km_salida = this.recaudacionToSave.kilometraje.km_entrada ?? 0;
      this.recaudacionToSave.kilometraje.km_totales = (this.recaudacionToSave.kilometraje.km_salida ?? 0) - (this.recaudacionToSave.kilometraje.km_entrada ?? 0);
      this.recaudacionToSave.taximetro_salida = this.recaudacionToSave?.taximetro_entrada ?? 0;
    } else {
      this.recaudacionToSave.kilometraje.km_salida = 0;
      this.recaudacionToSave.kilometraje.km_totales = 0;
    }
  }

  calcularImporteBruto(): number {
    return ((this.recaudacionToSave.taximetro_salida ?? 0) - (this.recaudacionToSave.taximetro_entrada ?? 0) - (this.recaudacionToSave?.incidencias ?? 0))
  }

  calcularImportePropietario(): number {
    return (this.calcularImporteBruto()) - this.calcularImporteConductor();
  }

  calcularEfectivo(): number {
    return (this.calcularImporteBruto()) - this.sum - this.sumTarjetas;
  }

  calcularImporteConductor(): number {

    if (!this.sueldoConductor) {
      return 0;
    }

    if (this.sueldoConductor?.tipoSueldo?.id == 3) {
      // return this.sueldoConductor.cantidad;
      return 0;
    } else if (this.sueldoConductor.tipoSueldo.id == 2) {
      return this.calcularImporteBruto() - this.sueldoConductor.cantidad;
    } else {
      var porcentajeTotal = this.sueldoConductor?.cantidad + (this.sueldoConductor?.porcentajeCondicional ?? 0);
      return this.calcularImporteBruto() * (porcentajeTotal / 100);
    }
  }

  calcularDiferenciaConductor(): number {
    return this.calcularEfectivo() - this.calcularImporteConductor();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent {

  constructor(

  ){}

  changeBorder(focus: Boolean){
    let buscador = document.getElementById('buscador-wrapper');

    if(focus)  buscador.style.borderColor = "#94959660";
    else  buscador.style.borderColor = "#94959620"
   
  }
}

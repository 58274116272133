import { IVehiculo } from 'src/app/vehiculo/vehiculo.model';
import { VehiculoService } from './../../../vehiculo/services/vehiculo.service';
import { ConductorService } from './../../../conductores/services/conductor.service';
import { LicenciaServiece } from './../../../_services/licenciaService.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { PropietarioService } from 'src/app/_services/novotaxi/propietario.service';

@Component({
  selector: 'app-card-detalles-graficos',
  templateUrl: './card-detalles-graficos.component.html',
  styleUrls: ['./card-detalles-graficos.component.css']
})

export class CardDetallesGraficosComponent implements OnInit {

  @Input() tipoDetalle: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private licenciaService: LicenciaServiece,
    private propietarioService: PropietarioService,
    private conductorService: ConductorService,
    private vehiculoService: VehiculoService
  ) { }

  ngOnInit(): void {
    this.convertDate()
    this.getUserName()
    this.getAllLicencias();
    this.getAllPropietarios();
    this.getAllConductores();
    this.getAllVehiculos();

  }

  userName;
  graficos: Boolean = false;
  detalles: Boolean = true;
  infoLicencias: Map<String, String> = new Map();
  infoPropietarios: Map<String, String> = new Map();
  infoConductores: Map<String, String> = new Map();
  infoVehiculos: Map<String, String> = new Map();
  datosDetalles: Map<String, Map<String, String>> = new Map([
    ['infoLicencias', this.infoLicencias],
    ['infoPropietarios', this.infoPropietarios],
    ['infoConductores', this.infoConductores],
    ['infoVehiculos', this.infoVehiculos]
  ]);
  listaVehiculos;
  public filters = [];
  public sorts = [];
  public totalElements = 0;

  mostrarGraficos() {
    this.graficos = true;
    this.detalles = false;
  }

  mostrarDetalles() {
    this.graficos = false;
    this.detalles = true;
  }

  convertDate(): String {
    var fechaGeneracion = new Intl.DateTimeFormat("es-ES", { dateStyle: "full", }).format(new Date());
    var fechaString = fechaGeneracion.charAt(0).toUpperCase() + fechaGeneracion.substring(1);
    return fechaString;
  }

  getTimeMoment(): String {
    var timeNow = new Date(Date.now());
    var hora = timeNow.getHours();
    if (hora >= 19 && hora < 6) {
      return 'Buenas noches';
    }
    else if (hora >= 6 && hora < 13) {
      return 'Buenos días';
    }
    else return 'Buenas tardes';
  }

  getUserName() {
    this.userName = this.tokenStorageService.getUser();
  }

  getAllLicencias() {
    this.licenciaService.getDetails().subscribe(
      (data) => {
        this.infoLicencias.set("licenciasTotales", data['total'] ?? 0);
        this.infoLicencias.set("licenciasActivas", data['totalesActivos'] ?? 0);
      }
    )
  }

  getAllPropietarios() {
    this.propietarioService.getDetails().subscribe(
      (data) => {
        this.infoPropietarios.set("propietariosTotales", data['total'] ?? 0);
        this.infoPropietarios.set("propietariosActivos", data['totalesActivos'] ?? 0);
      }
    )
  }

  getAllConductores() {
    this.conductorService.getDetails().subscribe(
      (data) => {
        this.infoConductores.set("conductoresTotales", data['total'] ?? 0);
        this.infoConductores.set("conductoresActivos", data['totalesActivos'] ?? 0);
      }
    )
  }

  getAllVehiculos() {
    this.vehiculoService.getDetails().subscribe(
      (data) => {        
        this.infoVehiculos.set("vehiculosTotales", data['total'] ?? 0);
        this.infoVehiculos.set("vehiculosActivos", data['totalesActivos'] ?? 0);
        this.infoVehiculos.set("marcaMasUsada", data['marcaMasUsada'] ?? '');
        this.infoVehiculos.set("modeloMasUsado", data['modeloMasUsado'] ?? '');
      }
    )
  }

  mostUsedMarca(modo: Boolean): String {
    var mayor = 0;
    var marcaMayor = '';
    var modeloMayor = '';

    let groupedVehiculosModelo;

    if (this.listaVehiculos) {

      groupedVehiculosModelo = this.listaVehiculos.reduce((result: any, vehiculo: any) => ({ //Agrupamos los por modelo
        ...result,
        [vehiculo.modelo?.id]: [...(result[vehiculo.modelo?.id] || []), vehiculo]
      }), [])
      groupedVehiculosModelo = Object.values(groupedVehiculosModelo)



      if (modo) {
        groupedVehiculosModelo.forEach(
          grupo => {
            if (mayor < grupo.length) {
              mayor = grupo.length;
              marcaMayor = grupo[0].modelo.marca.nombre;
            }
          }
        )
        return marcaMayor;
      }
      else {
        groupedVehiculosModelo.forEach(
          grupo => {
            if (mayor < grupo.length) {
              mayor = grupo.length;
              modeloMayor = grupo[0].modelo.nombre;
            }
          }
        )
        return modeloMayor;
      }
    }
    return '';
  }
}

import { IConductor } from 'src/app/conductores/conductor.model';
import { Licencia } from '../../Emisora';
import { Usuario } from '../gastos-ingresos/gastos-ingresos.model';
import { ConceptosProveedores } from '../proveedores/concepto-proveedores.model';
import { Proveedor } from '../proveedores/proveedor.model';
import { TipoCantidad } from './tipo_cantidad.model';

export class GastosProveedores {
    id?: number | null;
    fechaAlta?: string | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;
    usuario?: Usuario | null;
    conductor?: IConductor | null;
    licencia?: Licencia | null;
    tipo?: string | null;
    concepto?: ConceptosProveedores | null;
    proveedor?: Proveedor | null;
    importe?: number | null;
    numFactura?: string | null;
    tipo_cantidad?: TipoCantidad | null;
    cantidad_propietario?: number | null;
    cantidad_conductor?: number | null;
    importe_iva?: number | null;

    constructor(mappedData) {
        this.id = mappedData?.id ?? null;
        this.fechaAlta = mappedData?.fechaAlta ?? null;
        this.fechaBaja = mappedData?.fechaBaja ?? null;
        this.fechaModificacion = mappedData?.fechaModificacion ?? null;
        this.usuario = new Usuario(mappedData?.usuario) ?? null;
        this.conductor = mappedData?.conductor ? new IConductor(mappedData?.conductor) : null;
        this.licencia = new Licencia(mappedData?.licencia) ?? null;
        this.tipo = mappedData?.tipo ?? null;
        this.concepto = new ConceptosProveedores(mappedData?.concepto) ?? null;
        this.proveedor = new Proveedor(mappedData?.proveedor) ?? null;
        this.importe = mappedData?.importe ?? null;
        this.numFactura = mappedData?.numFactura ?? null;
        this.tipo_cantidad = mappedData?.tipo_cantidad ?? null;
        this.cantidad_propietario = mappedData?.cantidad_propietario ?? null;
        this.cantidad_conductor = mappedData?.cantidad_conductor ?? null;
        this.importe_iva = mappedData?.importe_iva ?? null;
    }
}
import { IConductor, TipoSueldo } from "src/app/conductores/conductor.model";
import { Licencia } from "../Emisora";
import { IVehiculo } from "src/app/vehiculo/vehiculo.model";

export class ConductorSueldo {
    id!: number;
    conductor!: IConductor;
    licencia!: Licencia;
    vehiculo!: IVehiculo;
    tipoSueldo!: TipoSueldo;
    cantidad!: number;
    porcentajeCondicional!: number;

    public constructor(data?: any, init?: Partial<ConductorSueldo>) {

        if (data) {
            this.id = data.id;
            this.conductor = data.conductor;
            this.licencia = data.licencia;
            this.vehiculo = data.vehiculo;
            this.tipoSueldo = data.tipoSueldo;
            this.cantidad = data.cantidad;
            this.porcentajeCondicional = data.porcentajeCondicional;
        }
    }
}
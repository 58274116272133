<div class="bg-white rounded p-2" >
  <div class="container mw-100 mt-3">
    <div class="row" >
      <div class="col">
        <div class="card mb-3">
          <div class="card-header bg-white font-weight-bold h5">
            <span *ngIf="isMarca && !editar">Nueva Marca</span>
            <span *ngIf="!isMarca && !editar">Nuevo Modelo</span>
            <span *ngIf="editar">Editar Modelo</span>
          </div>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="editar == false ? submit() : openDialogConfirmarUpdate()">

                <ng-container *ngIf="isMarca == true">
                  <div class="row">
                    <div class="form-group col">
                      <label for="nombre">Marca</label>
                      <input class="form-control" type="text" id="nombre" formControlName="nombre">
                      <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es obligatorio</small>
                    </div>
                  </div> 
                </ng-container>

                <ng-container *ngIf="!isMarca">
                  <div class="row">
                    <div class="form-group col">
                      <label for="marca">Marca</label>
                      <select id="marca" class="form-control form-select" formControlName="marca">
                        <option [value]="marca.id" *ngFor="let marca of listaMarcas">{{marca.nombre}}</option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label for="nombre">Modelo</label>
                      <input class="form-control" type="text" id="nombre" formControlName="nombre">
                      <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es obligatorio</small>
                    </div>     
                  </div>       
                </ng-container>

                <div id="container" class="d-flex justify-content-end pt-3">
                  <button *ngIf="editar == false" class="btn btn-success mx-3" type="submit">Guardar</button>
                  <button *ngIf="editar == true && form.dirty" class="btn btn-success mx-3" type="submit">Guardar</button>
                  <button class="btn btn-secondary ml-3" type="button" (click)="form.dirty ? openDialogConfirmarUnsaved() : dialogRef.close()">Cerrar</button> 
                </div>
              </form>
            </div>         
        </div>
      </div>
    </div>
  </div>

</div>

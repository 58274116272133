<div class="d-flex flex-row justify-content-between align-items-start mt-2">
    <h1 class="titulo-formulario">Histórico de propietario</h1>

    <button class="btn btn-primary btn-sm align-middle" (click)="setOcultarHistorico()">
        <mat-icon class="align-middle mr-2">edit_note</mat-icon><span class="align-middle">Mostrar formulario</span>
    </button>

</div>

<div>
    <form>

        <div class="row mt-4 mb-2">
            <div class="col-sm-3">
                <label for="propietario">Propietario</label>
            </div>
            <div class="col">
                <label for="propietario">DNI</label>
            </div>
            <div class="col">
                <label for="propietario">Licencia</label>
            </div>
            <!-- <div class="col">
                <label for="propietario">Fecha de alta</label>
            </div> -->
            <div class="col">
                <label for="propietario">Fecha de baja</label>
            </div>
            <div class="col">
                <label for="propietario">Fecha desde</label>
            </div>
            <div class="col">
                <label for="propietario">Fecha hasta</label>
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let historico of propietarioLicenciaHistorico">
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="propietario"
                    placeholder="{{historico.propietario.nombre}} {{historico.propietario.apellidos}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni"
                    placeholder="{{historico.propietario.numero_documento}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni"
                    placeholder="{{historico.licencia.numero_licencia}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja"
                    [value]="formatDate(historico.propietario.fechaBaja)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(historico?.fechaDesde)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(historico?.fechaHasta)">
            </div>
        </div>

        <div>
            <div class="row mt-1 mb-1" *ngFor="let propietario of propietarioHistorico">
                <div class="col-sm-3">
                    <input disabled class="form-control" type="text" id="propietario"
                        placeholder="{{propietario.nombre}} {{propietario.apellidos}}">
                </div>
                <div class="col">
                    <input disabled class="form-control" type="text" id="dni"
                        placeholder="{{propietario.numero_documento}}">
                </div>
                <div class="col">
                    <input disabled class="form-control" type="text" id="dni" placeholder="">
                </div>
                <div class="col">
                    <input disabled class="form-control" type="date" id="baja"
                        [value]="formatDate(propietario.fechaBaja)">
                </div>
                <div class="col">
                    <input disabled class="form-control" type="date" id="baja" [value]="">
                </div>
                <div class="col">
                    <input disabled class="form-control" type="date" id="baja" [value]="">
                </div>
            </div>
        </div>
    </form>
    <hr>
</div>
<div id="collapse">
    <p class="d-flex justify-content-between flex-wrap">
        <a class="btn btn-primary" data-toggle="collapse" href="#licencia" role="button">
          Información licencia
        </a>
        <a class="btn btn-primary" data-toggle="collapse" href="#propietario" role="button">
          Información propietario
        </a>
        <a class="btn btn-primary" data-toggle="collapse" href="#vehiculo" role="button">
          Información vehículo
        </a>
      </p>
      <div class="collapse" id="licencia" data-parent="#collapse">
        <div class="card">
          <div class="card-header">Licencia</div>
          <div class="card card-body">
            Información sobre la licencia
          </div>
        </div>  
      </div>
      <div class="collapse" id="propietario" data-parent="#collapse">
        <div class="card">
          <div class="card-header">Propietario</div>
          <div class="card card-body">
            Información sobre el propietario
          </div>
        </div> 
      </div>
      <div class="collapse" id="vehiculo" data-parent="#collapse">
        <div class="card">
          <div class="card-header">Vehículo</div>
          <div class="card card-body">
            Información sobre el vehículo
          </div>
        </div> 
    </div>
</div>

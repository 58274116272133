import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Propietario } from 'src/app/model/novotaxi/propietario.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { PropietarioService } from 'src/app/_services/novotaxi/propietario.service';
import { FormControl, FormGroup } from '@angular/forms';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { InformePDF } from '../informe-licencia/informePDF';
import { CardDetallesGraficosComponent } from '../card-detalles-graficos/card-detalles-graficos.component';
import { ModalBajaComponent } from 'src/app/modal/modal-baja/modal-baja.component';
import * as JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { ExcelExportService } from 'src/app/_services/excel-export.service';

@Component({
  selector: 'app-gestion-propietarios',
  templateUrl: './gestion-propietarios.component.html',
  styleUrls: ['./gestion-propietarios.component.scss']
})

export class GestionPropietariosComponent implements OnInit/* , AfterViewInit */ {

  displayedColumns: string[] = ['numero_documento', 'nombre', 'apellidos', 'domicilio', 'codigo_postal', 'poblacion', 'provincia',
    'telefonos', 'emails', 'gestoria', 'fechaAlta', 'licencias.numero_licencia', 'fechaBaja', 'acciones'];

  displayedSearchHeaders: string[] = ['dni-buscador', 'nombre-buscador', 'apellidos-buscador', 'domicilio-buscador', 'codigoPostal-buscador', 'poblacion-buscador',
    'provincia-buscador', 'telefono-buscador', 'email-buscador', 'gestoria-buscador', 'fechaAlta-buscador', 'licencia-buscador', 'activo-buscador', 'acciones-buscador'];

  public dataSource: MatTableDataSource<Propietario>;
  tipoDetalle: string = 'propietarios';


  propietariosList: Propietario[] = [];
  listColumns;
  isForUpdate = false;
  listaMunicipios = [];

  // -- Filtrado por columnas
  public searchForm: FormGroup;
  public numero_documento = '';
  public nombre = '';
  public apellidos = '';
  public domicilio = '';
  public cp = '';
  public poblacion = '';
  public provincia = '';
  public telefono = '';
  public email = '';
  public licencia = ''
  public gestoria = '';
  public fechaAlta = '';

  isActivo = true;
  isInactivo = true;

  // -- Variables para pasar al componente
  mostrarFormulario: Boolean = false;
  propietarioUpdate: Propietario;
  editar: Boolean = false;

  // -- Variables optimización
  public filters = [];
  public sorts = [];
  public totalElements = 0;

  // -- Paginado
  length = this.propietariosList?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  data: DataPropietario = { mostrarFormulario: false, elementUpdate: null, isForUpdate: false, municipios: [] }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesGraficosComponent) detallesComponent;

  constructor(
    private _propietarioService: PropietarioService,
    private _servicioLicencia: LicenciaServiece,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.listColumns = {
      'DNI': true,
      'Nombre': true,
      'Apellidos': true,
      'Domicilio': true,
      'Código postal': true,
      'Población': true,
      'Provincia': true,
      'Teléfono': true,
      'Correo electrónico': true,
      'Gestoría': true,
      'Fecha de alta': true,
      'Licencia': true,
      'Activo': true,
    }

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getPropietarios(request);
    this.searchFormInit();
    this.listMunicipios();
  }

  setMostrarFormulario(mode: Boolean, element?: Propietario, isForUpdate?: Boolean) {
    this.mostrarFormulario = mode;
    this.data = { mostrarFormulario: mode, elementUpdate: element, isForUpdate: isForUpdate, municipios: this.listaMunicipios }
  }

  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getPropietarios(request);
    this.detallesComponent.getAllPropietarios();
  }

  listMunicipios() {
    this._servicioLicencia.listMunicipio().subscribe(
      (data) => {
        this.listaMunicipios = data['Municipio']
      });
  }

  generarInforme() {
    let nombre = 'Propietario';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  async getPropietarios(request?) {

    await this._propietarioService.getAllPropietarios(request).subscribe((data) => {
      this.propietariosList = []
      this.totalElements = 0;
      if (data['propietarios']) {
        if (data['propietarios']['content']) {
          for (const propietarioJSON of data['propietarios']['content']) {
            this.propietariosList.push(new Propietario(propietarioJSON));
          }
        }

        this.totalElements = data['propietarios']['totalElements'];
      }

      request['sorts']?.forEach(sort => {
        if (sort['key'] == 'licencias.numero_licencia') {
          if (sort.direction == "CUSTOM_ASC") {
            this.propietariosList = this.propietariosList.sort((a, b) => a.licencias[0]?.numero_licencia.localeCompare(b.licencias[0]?.numero_licencia));
          } else {
            this.propietariosList = this.propietariosList.sort((a, b) => b.licencias[0]?.numero_licencia.localeCompare(a.licencias[0]?.numero_licencia));
          }
        }
      });

      this.dataSource = new MatTableDataSource(this.propietariosList);
    })
  }

  async openDialogDelete(element) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(ModalBajaComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result != '') {
        this.deletePropietario(element, result.result ?? null);
      }
    })

  }

  deletePropietario(element, fecha) {
    this._propietarioService.deletePropietario(element.id, fecha).subscribe((data) => {
      if (data["status"] == '622') {
        let request = {
          "filters": this.filters,
          "sorts": this.sorts,
          page: this.paginator.pageIndex,
          size: this.paginator.pageSize,
        }

        this.getPropietarios(request);
        this._notificationService.success('Hecho', 'Propietario desactivado con éxito', 3000);
        this.detallesComponent.getAllPropietarios();
      }
    });
  }

  // -- Inicio filtrado optimización 
  searchFormInit() {
    this.searchForm = new FormGroup({
      numero_documento: new FormControl(''),
      nombre: new FormControl(''),
      apellidos: new FormControl(''),
      domicilio: new FormControl(''),
      cp: new FormControl(''),
      poblacion: new FormControl(''),
      provincia: new FormControl(''),
      gestoria: new FormControl(''),
      fechaAlta: new FormControl(''),
      telefono: new FormControl(''),
      email: new FormControl(''),
      licencia: new FormControl(''),
    });
  }

  async applyFilter(event?, key?, type?) {

    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];
    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event.target.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {


    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type.toUpperCase() == "BOOLEAN") {
        filter.operator = "EQUAL"
      }

      if (type.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }
      
      if (type.toUpperCase() == "DATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == "ISNULLDATE") {
        this.isNullFechaBaja(filter);
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
            if (type.toUpperCase() == "ISNULLDATE") {
              this.isNullFechaBaja(filter);
            }
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,

    }

    this.paginator.firstPage();
    await this.getPropietarios(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
    };

    await this.getPropietarios(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }

    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }
      }
      this.sorts = [{
        "key": sort.active,
        "direction": direction
      }];
      request['sorts'] = this.sorts;
    }

    await this.getPropietarios(request)
  }

  isNullFechaBaja(filter) {
    filter.field_type = "DATE";
    if (
      (this.isActivo == true && this.isInactivo == false) ||
      (this.isActivo == false && this.isInactivo == true)
    ) {
      if (this.isActivo == true) {
        filter.operator = "IS_NULL";
        filter.value = null;
      }

      if (this.isInactivo == true) {
        filter.operator = "IS_NOT_NULL";
      }
    } else if (this.isActivo == false && this.isInactivo == false) {
      filter.operator = "IS_NULL";
    } else if (this.isActivo == true && this.isInactivo == true) {
      filter.operator = "IS_NULL";
    }
  }

  //  -- CheckBox
  setActivoInactivo(event, activoInactivo) {
    if (activoInactivo == "activo") {
      this.isActivo = event.target.checked;
    }
    if (activoInactivo == "inactivo") {
      this.isInactivo = event.target.checked;
    }
    this.applyFilter(event, "fechaBaja", "ISNULLDATE");
  }

  // -- Fin filtrado optimización 

  // -- Descarga zip de documentos
  isDownloadFiles: Boolean = false;
  downloadFiles(idPropietario: number) {
    this.isDownloadFiles = true;

    this._propietarioService.getDocuments(idPropietario).subscribe({
      next: (data) => {
        this.crearZIPyDescargar(data).finally(() => {
          this.isDownloadFiles = false;
        });
      }
    })
  }

  async crearZIPyDescargar(data) {
    const zip = new JSZip();
    
    data['documentos'].forEach((document) => {
      zip.file(`${document['nombre']}`, document['base64'], { base64: true });
    });

    const content = await zip.generateAsync({ type: 'blob' });
    const zipURL = URL.createObjectURL(content);

    const a = document.createElement('a');
    a.href = zipURL;
    a.download = `documentos_${data['propietario']['nombre']}_${data['propietario']['apellidos']}.zip`;

    a.click();

    URL.revokeObjectURL(zipURL);
  }

  // -- Exportación a excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, fechaModificacion, isActive, tipo_documento, iban, observaciones, ...rest } = item;

    if (item.provincia) {
      rest.provincia = item.provincia.ccaa.nombre;
    }

    if (item.emails) {
      rest.emails = item.emails.join(', ');
    }

    if (item.telefonos) {
      rest.telefonos = item.telefonos.join(', ');
    }

    if (item.licencias) {
      rest.licencias = item.licencias.map((licencia) => licencia.numero_licencia).join(', ');
    }

    return rest;
  }

  generateExcel() {
    const data = this.dataSource.data.map((propietario) => {
      const formattedPropietario = { ...propietario };
      formattedPropietario.fechaAlta = this.formatDate(propietario.fechaAlta);
      formattedPropietario.fechaModificacion ? formattedPropietario.fechaModificacion = this.formatDate(propietario.fechaModificacion).toLocaleDateString() : null;
      return this.filterFields(formattedPropietario);
    });

    const fileName = 'Tabla_propietarios';
    this._excelService.exportToExcel(data, fileName);
  }
}

export interface DataPropietario {
  elementUpdate: Propietario; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  municipios: any[];
}


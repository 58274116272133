<!-- ------------------------------- Botones para la búsqueda ----------------------- -->
<!--<div class="row">
    <div class="col-md">
        <label>Desde: </label>
        <input _ngcontent-vka-c280="" [(ngModel)]="fechainicio" class="form-control ng-pristine ng-invalid ng-touched"
            type="date">
    </div>

    <div class="col-md">
        <label>Hasta: </label>
        <input _ngcontent-vka-c280="" [(ngModel)]="fechafin" class="form-control ng-pristine ng-invalid ng-touched"
            type="date">
    </div>

    <button class="btn btn-success align-middle" style="margin-top: 2rem; width: 15%;"
        (click)="searchOperacionesBanco()">
        <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
    </button>
</div>-->

<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Operaciones banco</h1>
    <div>
        <button class="btn btn-outline-primary align-middle" *ngIf="isSearch">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar informe</span> 
        </button>
    </div>
</div>
  
<hr class="linea-divisoria">
  
<!-- Detalles/Gráficos -->
<!-- <app-card-detalles-graficos></app-card-detalles-graficos> -->
<!---->

<!-- ------------------------------- Acciones ----------------------- -->
<!--<div class="row" style="padding: 0 15px; margin-top: 2rem;" *ngIf="isSearch">
    <div style="padding-right: 10px;">
        <button class="btn btn-success align-middle">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">PDF</span>
        </button>
    </div>

    <div style="padding-right: 10px;">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
    </div>
</div>-->

<!-- ------------------------------- Tabla ----------------------- -->
<div class="px-5 pb-4 contenedor mb-5 pt-4">
    <div class="d-flex flex-row align-items-center">
        <div class="row align-items-center flex-grow-1 crear-editar-form">
            <div class="col-sm-2">
                <label>Desde</label>
                <input _ngcontent-vka-c280="" [(ngModel)]="fechainicio" class="form-control form-control-sm ng-pristine ng-invalid ng-touched"
                    type="date">
            </div>
            <div class="col-sm-2">
                <label>Hasta</label>
                <input _ngcontent-vka-c280="" [(ngModel)]="fechafin" class="form-control form-control-sm ng-pristine ng-invalid ng-touched"
                    type="date">
            </div>        
            <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="searchOperacionesBanco()">
                <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
            </button>
        </div>
        <div class="btn-group btn-group-sm align-self-start" role="group">
            <app-multi-select-dropdown [listColumns]="listColumns">
            </app-multi-select-dropdown>
        </div>       
    </div>
<div class="table_container mt-5" *ngIf="isSearch">

    <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white">

        <!-- ------------------------------- Columna fecha operación ----------------------- -->
        <ng-container matColumnDef="fechaOperacion">
            <th mat-header-cell [hidden]="!listColumns['Fecha operación']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaOperacion" class="header">
                <div>
                    <p>Fecha operación</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input formControlName="Fecha operación" matInput (keyup)="applyFilter()"
                                placeholder="Buscar fecha operación" style="padding: 3px;" />
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Fecha operación']" *matCellDef="let element">Fecha operación</td>
        </ng-container>

        <!-- ------------------------------- Columna fecha valor ----------------------- -->
        <ng-container matColumnDef="fechaValor">
            <th mat-header-cell [hidden]="!listColumns['Fecha Valor']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by Fechavalor" class="header">
                <div>
                    <p>Fecha Valor</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input formControlName="Fecha operación" matInput (keyup)="applyFilter()"
                                placeholder="Buscar fecha valor" style="padding: 3px;" />
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Fecha Valor']" *matCellDef="let element">Fecha valor</td>
        </ng-container>

        <!-- ------------------------------- Columna G/I ----------------------- -->
        <ng-container matColumnDef="gastosIngresos">
            <th mat-header-cell [hidden]="!listColumns['G/I']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>G/I</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="G/I" formControlName="gastosIngresos"
                                (keyup)="applyFilter()" placeholder="Buscar por G/I" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['G/I']" *matCellDef="let element">G/I</td>
        </ng-container>

        <!-- ------------------------------- Columna importe ----------------------- -->
        <ng-container matColumnDef="importe">
            <th mat-header-cell [hidden]="!listColumns['Importe']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>Importe</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Importe" formControlName="importe"
                                (keyup)="applyFilter()" placeholder="Buscar por importe" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Importe']" *matCellDef="let element">Importe</td>
        </ng-container>

        <!-- ------------------------------- Columna concepto ----------------------- -->
        <ng-container matColumnDef="concepto">
            <th mat-header-cell [hidden]="!listColumns['Concepto']" *matHeaderCellDef class="header">
                <div>
                    <p>Concepto</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Concepto" formControlName="concepto"
                                (keyup)="applyFilter()" placeholder="Buscar por concepto" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Concepto']" *matCellDef="let element">{{element.concepto}}</td>
        </ng-container>

        <!-- ------------------------------- Columna tipo ----------------------- -->
        <ng-container matColumnDef="tipo">
            <th mat-header-cell [hidden]="!listColumns['Tipo']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>Tipo</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Tipo" formControlName="tipo" (keyup)="applyFilter()"
                                placeholder="Buscar por tipo" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Tipo']" *matCellDef="let element">{{element.tipo}}
            </td>
        </ng-container>



        <!-- ------------------------------- Columna número de documento ----------------------- -->
        <ng-container matColumnDef="numDocumento">
            <th mat-header-cell [hidden]="!listColumns['Nº documento']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by numFactura" class="header">
                <div>
                    <p>Nº documento</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="numDocumento" formControlName="numDocumento"
                                (keyup)="applyFilter()" placeholder="Buscar por número de documento"
                                style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Nº documento']" *matCellDef="let element">Número de documento</td>
        </ng-container>

        <!-- ------------------------------- Columna licencia ----------------------- -->
        <ng-container matColumnDef="licencia">
            <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>Licencia</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Licencia" formControlName="licencia"
                                (keyup)="applyFilter()" placeholder="Buscar por licencia" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Licencia']" *matCellDef="let element">
                Licencia</td>
        </ng-container>

        <!-- ------------------------------- Columna conductor ----------------------- -->
        <ng-container matColumnDef="conductor">
            <th mat-header-cell [hidden]="!listColumns['Conductor']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>Conductor</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Conductor" formControlName="conductor"
                                (keyup)="applyFilter()" placeholder="Buscar por conductor" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Conductor']" *matCellDef="let element">Conductor</td>
        </ng-container>

        <!-- ------------------------------- Columna usuario ----------------------- -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell [hidden]="!listColumns['Usuario']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                <div>
                    <p>Usuario</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="Usuario" formControlName="usuario"
                                (keyup)="applyFilter()" placeholder="Buscar por usuario" style="padding: 3px;">
                        </mat-form-field>
                    </form>
                </div>
            </th>
            <td mat-cell [hidden]="!listColumns['Usuario']" *matCellDef="let element">Usuario</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>
</div>

<mat-paginator class="mt-3" *ngIf="isSearch" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
    aria-label="Select page" #paginator>
</mat-paginator>
</div>
import { IProvincia } from './../../../model/novotaxi/propietario.model';
import { ServicioGeneral } from './../../../_services/servicio-general.service';
import { IEmisora } from '../emisora.model';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmarUnsavedDialog, ConfirmarUpdateDialog } from 'src/app/conductores/listado-conductores/listado-conductores.component';
import { NotificationService } from 'src/app/_services/notification.service';
import { EmisoraService } from '../services/emisora.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';

@Component({
  selector: 'app-listado-emisora',
  templateUrl: './listado-emisora.component.html',
  styleUrls: ['./listado-emisora.component.css']
})
export class ListadoEmisoraComponent implements OnInit {

  displayedColumns: string[] = ['nombre', 'cif', 'email', 'telefono', 'domicilio', 'municipio', 'provincia.nombre', 'codigoPostal', 'fechaAlta', 'acciones'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() nombreMenu;

  listColumns;
  dataSource = new MatTableDataSource();
  listaEmisora: IEmisora[] = [];
  listaProvincias: IProvincia[];
  listMunicipios: any[] = [];
  emisoraUpdate: IEmisora;
  editar: Boolean = false;
  mostrarFormulario: Boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private servicioGeneral: ServicioGeneral,
    public _notificationService: NotificationService,
    private emisoraService: EmisoraService,
    private informe: InformePDF,
  ) { }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  ngOnInit(): void {
    window.scroll(0, 0)
    this.getMunicipios();
    this.getAllProvincias();
    this.getAllEmisora();
    this.listColumns = {
      'Nombre': true,
      'CIF': true,
      'Correo electrónico': true,
      'Teléfono': true,
      'Domicilio': true,
      'Municipio': true,
      'Provincia': true,
      'Código postal': true,
      'Fecha alta': true,
    }
  }
  generarInforme(){
    let nombre = 'Emisora';
    var inicio= this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
    var final=inicio + this.dataSource.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData,nombre,inicio,final);
  }
  //Paginado
  length = this.listaEmisora.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  getAllProvincias() {
    this.servicioGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    )
  }
  
  getMunicipios() {
    this.servicioGeneral.getMunicipios().subscribe((data) => {
      this.listMunicipios = data['Municipio']
    })
  }

  setMostrarFormulario(mode: Boolean, gestoria?: IEmisora, tipo?: Boolean) {
    this.mostrarFormulario = mode;
    this.emisoraUpdate = gestoria;
    this.editar = tipo;
    if (mode) {
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
    }
  }

  getAllEmisora() {
    this.emisoraService.list().subscribe((res) => {
      this.listaEmisora = res['emisoras']
      this.listaEmisora = this.listaEmisora?.filter(emisora => emisora.fechaBaja == null) //Mostramos el conjunto de elementos que no están borrados, es decir, que no tienen fecha de baja
      this.dataSource = new MatTableDataSource(this.listaEmisora);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    });
  }

  openDialogCrearEmisora() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '60vw';
    dialogConfig.minWidth = '60vw';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.data = this.listaProvincias;
    dialogConfig.disableClose = true;
    this.dialog.open(CrearEmisoraDialog, dialogConfig);
  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogBorrarEmisora(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "borrar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.delete(id);
      }
    })
  }

  openDialogMostrarEmisora(emisora: IEmisora) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '60vw';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = [emisora, this.listaProvincias];
    this.dialog.open(MostrarEmisoraDialog, dialogConfig);
  }

  delete(id: number): void {
    this.emisoraService.delete(id).subscribe({
      next: (data) => {
        this._notificationService.success("Operación realizada con éxito", "Los datos han sido borrados correctamente", 3000)
        this.reloadToMenuOption();
      }
    });
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 25 } });
  }
}



@Component({
  selector: 'app-crear-emisora-dialog',
  templateUrl: '../crear-emisora/crear-emisora-dialog.html',
  styleUrls: ['../crear-emisora/crear-emisora-dialog.css']
})
export class CrearEmisoraDialog {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private emisoraService: EmisoraService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CrearEmisoraDialog>,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public listaProvincias: IProvincia[],
    private router: Router,
  ) { }

  emisora: IEmisora;
  isSubmitted = false;
  editar = false;
  expandedPhone: number = 1;
  expandedEmail: number = 1;

  form = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    cif: new FormControl(null, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    email: new FormArray([new FormControl("", Validators.pattern(this.REGEX_EMAIL)),]),
    telefono: new FormArray([new FormControl("", Validators.pattern("[0-9]{9}"))]),
    domicilio: new FormControl(),
    municipio: new FormControl(),
    provincia: new FormControl(0),
    codigoPostal: new FormControl(null, Validators.pattern("^[0-9]{5}")),
    observaciones: new FormControl()
  });

  // Para tener más de un teléfono o email

  addTelefono() {
    let tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }
  addEmail() {
    let emails = this.form.controls.email as FormArray;
    emails.push(
      new FormControl("", Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }
  get emails() {
    return this.form.get("email") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }
  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    this.emisora = {
      id: null,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      email: this.form.value.email?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal,
      observaciones: this.form.value.observaciones,
    }

    /*if (this.form.invalid) {
      this._notificationService.error("La operación no se ha podido realizar", "Existen datos incorrectos o incompletos")
      this.isSubmitted = true;
    }*/
    this.emisoraService.create(this.emisora).subscribe({
      next: (data) => {

        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000)
          this.dialogRef.close()
          this.isSubmitted = false;
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data['message']
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);

        }
      }
    })

  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }


  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 25 } });
  }
}


@Component({
  templateUrl: '../crear-emisora/crear-emisora-dialog.html',
  styleUrls: ['../crear-emisora/crear-emisora-dialog.css']
})

export class MostrarEmisoraDialog {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private emisoraService: EmisoraService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MostrarEmisoraDialog>,
    private router: Router,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],
  ) { }

  emisora: IEmisora = this.dialogData[0]
  listaProvincias: IProvincia[] = this.dialogData[1];
  emisoraUpdate: IEmisora;
  isSubmitted: Boolean = false;
  editar: Boolean = true; //Para cambiar el título de 'nueva gestoría' a 'editar gestoría'
  expandedPhone: number = 1;
  expandedEmail: number = 1;

  form = new FormGroup({
    nombre: new FormControl(this.emisora.nombre, Validators.required),
    cif: new FormControl(this.emisora.cif, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    email: this.camposEmail(),
    domicilio: new FormControl(this.emisora.domicilio),
    municipio: new FormControl(this.emisora.municipio),
    provincia: new FormControl(this.emisora.provincia?.id ? this.emisora.provincia.id : 0),
    codigoPostal: new FormControl(this.emisora.codigoPostal),
    telefono: this.camposTelefono(),
    fechaAlta: new FormControl(this.emisora.fechaAlta),
    observaciones: new FormControl(this.emisora.observaciones),
  });

  // Si el usuario tiene varios telefonos o emals, los muestra y permite así eliminarlos o añadir más
  camposTelefono() {
    let telefonosEmisora = this.emisora.telefono;
    let telefono = new FormArray([new FormControl(telefonosEmisora[0], Validators.pattern("^[0-9]{9}"))])
    for (let i = 1; i < telefonosEmisora.length; i++) {
      telefono.push(new FormControl(telefonosEmisora[i].toString(), Validators.pattern("^[0-9]{9}")));
      this.expandedPhone++;
    }
    return telefono;

  }

  addTelefono() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  camposEmail() {
    let emailsEmisora = this.emisora.email;
    let email = new FormArray([new FormControl(emailsEmisora[0], Validators.pattern(this.REGEX_EMAIL))])
    for (let i = 1; i < emailsEmisora.length; i++) {
      email.push(new FormControl(emailsEmisora[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
      this.expandedPhone++;
    }
    return email;

  }

  addEmail() {
    let emails = this.form.controls.email as FormArray;
    emails.push(
      new FormControl("", Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get emails() {
    return this.form.get("email") as FormArray;
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    this.emisoraUpdate = {
      id: this.emisora.id,
      nombre: this.form.value.nombre == "" ? null : this.form.value.nombre,
      cif: this.form.value.cif == "" ? "null" : this.form.value.cif,
      email: this.form.value.email?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal == "" ? null : this.form.value.codigoPostal,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      observaciones: this.form.value.observaciones,
    }

    this.emisoraService.create(this.emisoraUpdate).subscribe({
      next: (data) => {

        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000)
          this.dialogRef.close()
          this.isSubmitted = false;
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data['message']
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);

        }
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 25 } });
  }

}




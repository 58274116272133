import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { DiasLibranzaService } from 'src/app/_services/novotaxi/dias-libranza.service';
import { Licencia } from 'src/app/model/Emisora';
import { DiasLibranza } from 'src/app/model/novotaxi/dias-libranza.model';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-listado-dias-libranza',
  templateUrl: './listado-dias-libranza.component.html',
  styleUrls: ['./listado-dias-libranza.component.css']
})

export class ListadoDiasLibranzaComponent {

  // -- Variables matTable
  displayedColumns: string[] = ['libranzaActual', 'libranzaNueva', 'conductor', 'licencia', 'usuario', 'acciones']
  public dataSource: MatTableDataSource<DiasLibranza>;
  listColumns;

  //  -- Variables para enviar al componente de crear/actualizar
  mostrarFormulario: Boolean = false;
  data: DataDiasLibranza = { mostrarFormulario: false, elementUpdate: null, isForUpdate: false, licencias: null }

  diasLibranzaList: DiasLibranza[] = [];
  licencias: Licencia[] = [];
  isForUpdate: Boolean;

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _notificationService: NotificationService,
    private _diasLibranzaService: DiasLibranzaService,
    private _licenciaService: LicenciaServiece,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getDiasLibranza();
    this.getLicencias();
    this.listColumns = {
      "Libranza actual": true,
      "Libranza nueva": true,
      "Conductor": true,
      "Licencia": true,
      "Usuario": true,
    }
  }

  // -- Paginado
  length = this.diasLibranzaList?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }
  // -- Fin paginado

  getDiasLibranza() {
    this._diasLibranzaService.listDiasLibranza().subscribe((data) => {
      this.diasLibranzaList = data['diasLibranza'];
      this.diasLibranzaList = this.diasLibranzaList?.filter(element => element.fechaBaja == null);

      this.dataSource = new MatTableDataSource(this.diasLibranzaList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe((data) => {
      this.licencias = data["licencias"];
    })
  }

  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    // -- Llamar método de obtención de datos
    this.getDiasLibranza();
  }

  setMostrarFormulario(mode: Boolean, isForUpdate?: Boolean, elementUpdate?: DiasLibranza) {
    this.mostrarFormulario = mode;
    this.data = { mostrarFormulario: mode, isForUpdate: isForUpdate, elementUpdate: elementUpdate, licencias: this.licencias }
    this.isForUpdate = isForUpdate;
  }

  async openDialogDelete(element) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "desactivar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.delete(element.id);
      }
    })
  }

  delete(id) {
    this._diasLibranzaService.deleteDiasLibranza(id).subscribe(data => {
      this._notificationService.success("OK", "Día de libranza eliminado correctamente", 3000);
      this.getDiasLibranza();
    })
  }
}

export interface DataDiasLibranza {
  elementUpdate: DiasLibranza; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  licencias?: Licencia[];
}
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-operaciones-banco',
  templateUrl: './operaciones-banco.component.html',
  styleUrls: ['./operaciones-banco.component.css']
})
export class OperacionesBancoComponent implements OnInit {

  //Campos tabla
  displayedColumns: string[] = ['fechaOperacion', 'fechaValor', 'gastosIngresos', 'importe', 'concepto', 'tipo', 'numDocumento', 'licencia', 'conductor', 'usuario'];
  dataSource;

  //Campos búsqueda
  isSearch: boolean = false;
  fechainicio: string;
  fechafin: string;
  searchMap;

  //Filtrado y ocultación de columnas
  listColumns;
  public searchForm: FormGroup;
  public fechaOperacion = '';
  public fechaValor = '';
  public gastosIngresos = '';
  public importe = '';
  public concepto = '';
  public tipo = '';
  public numDocumento = '';
  public licencia = '';
  public conductor = '';
  public usuario = '';

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    //Variables para ocultación de columnas
    this.listColumns = {
      'Fecha operación': true,
      'Fecha Valor': true,
      'G/I': true,
      'Importe': true,
      'Concepto': true,
      'Tipo': true,
      'Nº documento': true,
      'Licencia': true,
      'Conductor': true,
      'Usuario': true,
    }

    this.searchFormInit();
  }

  //Paginado
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchOperacionesBanco() {
    if (this.isValidFieldsSearch()) {
      this.isSearch = true;

      this.searchMap = {
        fechaInicio: this.fechainicio,
        fechaFinal: this.fechafin,
      };

      //Llamada al service para búsqueda y pasada de datos al dataSource
    }

  }

  isValidFieldsSearch() {
    if (this.fechainicio == undefined || this.fechainicio == undefined) {
      this._notificationService.warning("¡Atención!", "Debe rellenar todos los campos de búsqueda", 3000);
      return false;
    } else {
      if (this.fechainicio > this.fechafin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }
  
   // ------------ Inicio  filtrado
   searchFormInit() {
    this.searchForm = new FormGroup({
      fechaOperacion: new FormControl(''),
      fechaValor: new FormControl(''),
      gastosIngresos: new FormControl(''),
      importe: new FormControl(''),
      concepto: new FormControl(''),
      tipo: new FormControl(''),
      numDocumento: new FormControl(''),
      licencia: new FormControl(''),
      conductor: new FormControl(''),
      usuario: new FormControl(''),
    });
  }

  getFilterPredicate() {
    return (row: any, filters: string) => {

      const matchFilter = [];

      return matchFilter.every(Boolean);
    }
  }

  applyFilter() {
    let filterValue = '';

    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // ------------ Fin filtrado

}

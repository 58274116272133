<footer class="pb-0">
  <div class="page__content">
    <ul class="w-100">
      <li class="organization">
        <img *ngIf="currentEnv != 'novotaxi'" src="../assets/footer/hacienda-logo.png" alt="Hacienda logo" />
      </li>
      <li class="organization">
        <h6 *ngFor="let titulo of environment.FOOTER_INFO_TITLE">{{titulo}}</h6>
        <p *ngFor="let info of environment.FOOTER_INFO">{{info}}</p>
      </li>
      <li class="organization"><img *ngIf="currentEnv != 'novotaxi'" src="../assets/footer/feder-logo.png" alt="Feder logo" style="width:100%;" /></li>
    </ul>
  </div>
</footer> 
<div class="legal-info w-100 py-2 d-flex align-items-center">
  <div class="info d-flex align-items-center">
    <p class="text-white ml-3 my-0 align-middle">{{environment.COPYRIGHT}}</p>
    <div *ngIf="currentEnv != 'novotaxi'">
      <a class="btn-info-legal mx-2" routerLink="/info/aviso-legal" >Aviso legal</a>
      <a class="btn-info-legal mx-2" routerLink="/info/politica-privacidad" >Política de privacidad</a>
      <a class="btn-info-legal mx-2" routerLink="/info/proteccion-de-datos" >Política de protección de datos</a>
    </div>
  </div>
</div>
<div class="bg-white rounded p-5">
    <div class="d-flex justify-content-center mb-2" *ngIf="modoDialog === 'borrar'"><img src="/assets/novotaxi/trashCan.svg"></div>
    <div class="d-flex justify-content-center mb-2" *ngIf="modoDialog === 'descartar'"><img src="/assets/novotaxi/discard.svg"></div>
    <div class="d-flex justify-content-center mb-2" *ngIf="modoDialog === 'guardar'"><img src="/assets/novotaxi/save.svg"></div>
    <div class="d-flex justify-content-center mb-2" *ngIf="modoDialog === 'desactivar'"><img src="/assets/novotaxi/deactivate.svg"></div>
    <div class="d-flex justify-content-center mb-2" *ngIf="modoDialog === 'activar'"><img src="/assets/novotaxi/activate.svg"></div>
    <div class="text-center pt-4">
        <h5 class="font-weight-bold" *ngIf="modoDialog === 'borrar'">¿Quieres continuar?</h5>
        <h5 class="font-weight-bold" *ngIf="modoDialog === 'descartar'">¿Descartar cambios?</h5>
        <h5 class="font-weight-bold" *ngIf="modoDialog === 'guardar'">¿Guardar cambios?</h5>
        <h5 class="font-weight-bold" *ngIf="modoDialog === 'desactivar'">¿Desactivar elemento?</h5>
        <h5 class="font-weight-bold" *ngIf="modoDialog === 'activar'">Activar elemento?</h5>

        <p class="mt-2" *ngIf="modoDialog === 'borrar'">El elemento seleccionado será borrado</p>
        <p class="mt-2" *ngIf="modoDialog === 'descartar'">Los cambios realizados se perderán</p>
        <p class="mt-2" *ngIf="modoDialog === 'guardar'">Los cambios realizados se guardarán</p>
        <p class="mt-2" *ngIf="modoDialog === 'desactivar'">El elemento seleccionado se desactivará</p>
        <p class="mt-2" *ngIf="modoDialog === 'activar'">El elemento seleccionado se activará</p>
    </div>
    <div class="mt-4 d-flex justify-content-center">
        <button class="btn btn-danger mx-3" (click)="dialogRef.close({result: 'Confirmar'})" *ngIf="modoDialog === 'borrar'">Borrar</button>
        <button class="btn btn-danger mx-3" (click)="dialogRef.close({result: 'Confirmar'})" *ngIf="modoDialog === 'descartar'">Descartar</button>
        <button class="btn btn-success mx-3" (click)="dialogRef.close({result: 'Confirmar'})" *ngIf="modoDialog === 'guardar'">Guardar</button>
        <button class="btn btn-danger mx-3" (click)="dialogRef.close({result: 'Confirmar'})" *ngIf="modoDialog === 'desactivar'">Desactivar</button>
        <button class="btn btn-success mx-3" (click)="dialogRef.close({result: 'Confirmar'})" *ngIf="modoDialog === 'activar'">Activar</button>
        <button class="btn btn-secondary mx-3" (click)="dialogRef.close({result: ''})">Cancelar</button>
    </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConceptosProveedores } from 'src/app/model/novotaxi/proveedores/concepto-proveedores.model';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { NotificationService } from 'src/app/_services/notification.service';
import { ProveedoresService } from 'src/app/_services/novotaxi/proveedores/proveedores.service';
import { InformePDF } from 'src/app/novotaxi/licencias/informe-licencia/informePDF';

@Component({
  selector: 'app-listado-proveedores',
  templateUrl: './listado-proveedores.component.html',
  styleUrls: ['./listado-proveedores.component.css']
})

export class ListadoProveedoresComponent implements OnInit {

  // -- Variables dataSource
  displayedColumns: string[] = ['proveedor.nombre', 'proveedor.cif', 'concepto', 'iva', 'activo', 'acciones']
  public dataSource: MatTableDataSource<ConceptosProveedores>;
  proveedoresConceptosList: ConceptosProveedores[] = [];

  listColumns;

  // -- Variables para pasar al componente
  mostrarFormulario: Boolean = false;
  conceptoProveedorUpdate: ConceptosProveedores;
  editar: Boolean = false;

  data: DataConceptosProveedores = { mostrarFormulario: false, elementUpdate: null, isForUpdate: false }

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _proveedoresService: ProveedoresService,
    private dialog: MatDialog,
    private _notificationService: NotificationService,
    private informe: InformePDF,
  ) { }


  ngOnInit(): void {
    this.listColumns = {
      "Nombre proveedor": true,
      "CIF proveedor": true,
      "Concepto": true,
      "IVA": true,
      "Activo": true,
    }

    this.findAllConceptosProveedores();
  }

  generarInforme() {
    let nombre = 'Proveedores';
    var inicio = this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
    var final = inicio + this.dataSource.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData, nombre, inicio, final);
  }

  // -- Paginado
  length = this.proveedoresConceptosList?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }
  // -- Fin paginado

  setMostrarFormulario(mode: Boolean, isForUpdate?: Boolean, element?: ConceptosProveedores) {
    this.mostrarFormulario = mode;
    this.data = { mostrarFormulario: mode, elementUpdate: element, isForUpdate: isForUpdate }
  }

  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    //Llamar método de obtención de datos
    this.findAllConceptosProveedores();
  }

  findAllConceptosProveedores() {    
    this._proveedoresService.getAllConceptosProveedores().subscribe((data) => {
      this.proveedoresConceptosList = data['conceptosProveedores'];

      this.dataSource = new MatTableDataSource(this.proveedoresConceptosList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      //Pasamos el filtro de búsqueda al datasource
      // this.dataSource.filterPredicate = this.getFilterPredicate();
    })
  }

  async openDialogDelete(element) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "desactivar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.deleteProveedor(element);
      }
    })

  }

  deleteProveedor(element) {
    this._proveedoresService.delete(element.proveedor.id).subscribe((data) => {
      if (data["status"] == '622') {
        this.findAllConceptosProveedores();
        this._notificationService.success('Hecho', 'Proveedor dado de baja con éxito', 3000);
      }
    });
  }
}

export interface DataConceptosProveedores {
  elementUpdate: ConceptosProveedores; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConductor } from '../conductor.model';

@Component({
  selector: 'app-historico-conductor',
  templateUrl: './historico-conductor.component.html',
  styleUrls: ['./historico-conductor.component.css']
})
export class HistoricoConductorComponent {

  constructor(
    private datePipe: DatePipe,
  ) {}

  @Input() conductorHistorico: IConductor[];
  @Input() conductorLicenciaHistorico;
  @Output() mostrarHistorico = new EventEmitter<Boolean>();

  setOcultarHistorico() {
    this.mostrarHistorico.emit(false);
  }

  formatDate(date) {
    
    if(date) {
      const splitDate = date.split('-');
  
      const dateFormatted = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      date = this.datePipe.transform(dateFormatted, 'yyyy-MM-dd');
      return date;
    }

    return '';
  }
}

<header>
    <nav class="header__wrap">
        <a [ngClass]="{'nav-link': environment.environment != 'novotaxi', 'disabledLink': environment.environment == 'novotaxi'}"
            routerLink="/#" class="navbar-brand">
            <img alt=">Logo" src="{{environment.LOGO_HEAD}}" /></a>

        <ul>
            <li class="nav-item" *ngIf="showModeratorBoard">
                <a href="/mod" class="iniciar" routerLinkActive="activo" routerLink="mod">Moderator Board</a>
            </li>
        </ul>

        <ul *ngIf="!isLoggedIn">
            <li *ngIf="environment.TIENE_INFO">
                <a class="informacion"></a>
            </li>
            <li class="nav-item" *ngIf="environment.environment != 'novotaxi'">
                <a routerLink="/register" routerLinkActive="activo" class="iniciar">Registrarse</a>
            </li>
            <li *ngIf="environment.environment != 'novotaxi'">
                <a (click)="reload()" routerLink="/login" routerLinkActive="activo" class="registrar">Identifíquese</a>
            </li>
        </ul>

        <ul *ngIf="isLoggedIn">

            <li *ngIf="environment.TIENE_INFO">
                <a class="informacion"></a>
            </li>
            <li>
                <a class="iniciar" routerLink="manual-usuario" routerLinkActive="activo"><i>
                    <img src="/assets/manual/info_icon.svg" routerLink="manual-usuario" routerLinkActive="activo" class="iniciar">
                </i></a>
            </li>
            <li>
                <a class="iniciar" routerLink="admin-novotaxi" routerLinkActive="activo"><i>{{ usuario.nombre }} {{
                        usuario.apellido1 }}</i></a>
            </li>
            <li class="nav-item">
                <a href class="iniciar" (click)="logout()">Cerrar sesión</a>
            </li>

        </ul>
    </nav>
    <nav class="navbar navbar-secondary">
        <form class="form-inline ml-auto">
        </form>
    </nav>
</header>
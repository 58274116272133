<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Gestión de propietarios</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle" >Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle" >Generar
        informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)" [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo propietario</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-graficos #detalles [tipoDetalle]="tipoDetalle"></app-card-detalles-graficos>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">

  <div class="mostrarFormulario" *ngIf="data.mostrarFormulario">
    <app-create-update-propietario (mostrarFormulario)="getValueForm($event)"
      [data]="data"></app-create-update-propietario>
  </div>

  <div class="mostrarTabla" [hidden]="mostrarFormulario">

    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
      <app-multi-select-dropdown [listColumns]="listColumns">
      </app-multi-select-dropdown>
    </div>

    <div class="table_container">
      <!--FILTRADO POR COLUMNAS CON INPUTS FUERA DE LA TABLA  -->

      <!-- <div style="width: ;"> -->
      <!-- <form [formGroup]="searchForm" class="search__form table_container"
      style="width: max-content; margin-top: 2rem; margin-bottom: 1rem;">
  
      <div class="row" style="margin: 0;">
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline" style="width: 7rem;">
            <input type="text" matInput aria-label="Dni" formControlName="numero_documento" (keyup)="applyFilter()"
              placeholder="Dni">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline" style="width: 7rem;">
            <input type="text" matInput aria-label="Nombre" formControlName="nombre" (keyup)="applyFilter()"
              placeholder="nombre">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Apellidos" formControlName="apellidos" (keyup)="applyFilter()"
              placeholder="Apellidos">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Domicilio" formControlName="domicilio" (keyup)="applyFilter()"
              placeholder="Domicilio">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="cp" formControlName="cp" (keyup)="applyFilter()" placeholder="CP">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Población" formControlName="poblacion" (keyup)="applyFilter()"
              placeholder="Población">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Provincia" formControlName="provincia" (keyup)="applyFilter()"
              placeholder="Provincia">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Telefono" formControlName="telefono" (keyup)="applyFilter()"
              placeholder="Teléfono">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Email" formControlName="email" (keyup)="applyFilter()"
              placeholder="Email">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
            <input type="text" matInput aria-label="Gestoria" formControlName="gestoria" (keyup)="applyFilter()"
              placeholder="Gestoría">
          </mat-form-field>
        </div>
        <div class="col-md">
          <mat-form-field class="search__form_field" appearance="outline">
  
            <input type="text" matInput aria-label="Licencia" formControlName="licencia" (keyup)="applyFilter()"
              placeholder="Licencia">
          </mat-form-field>
        </div>
        <div class="col-md">
          <input matInput formControlName="fechaAlta" [matDatepicker]="picker" (dateInput)="applyFilter()">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
    </form> -->

      <!-- </div> -->

      <div>
        <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
          (matSortChange)="sortData($event)">

          <!-- Columna DNI -->
          <ng-container matColumnDef="dni-buscador" sticky>
            <th [hidden]="!listColumns['DNI']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      (keyup)="applyFilter($event,'numero_documento', 'STRING')" aria-label="Dni"
                      formControlName="numero_documento" placeholder="Buscar por..." class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="numero_documento" sticky>
            <th mat-header-cell [hidden]="!listColumns['DNI']" *matHeaderCellDef mat-sort-header class="header">
              <!-- DNI -->
              DNI
            </th>
            <td mat-cell [hidden]="!listColumns['DNI']" *matCellDef="let row">
              {{row.numero_documento}}</td>
          </ng-container>

          <!-- Columna Nombre -->
          <ng-container matColumnDef="nombre-buscador">
            <th [hidden]="!listColumns['Nombre']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Nombre" formControlName="nombre" (keyup)="applyFilter($event,'nombre', 'STRING')"
                      placeholder="Buscar por..." class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell [hidden]="!listColumns['Nombre']" *matHeaderCellDef mat-sort-header class="header">
              Nombre
            </th>
            <td mat-cell [hidden]="!listColumns['Nombre']" *matCellDef="let row">
              {{row.nombre}}</td>
          </ng-container>

          <!-- Columna Apellidos -->
          <ng-container matColumnDef="apellidos-buscador">
            <th [hidden]="!listColumns['Apellidos']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Apellidos" formControlName="apellidos"
                      (keyup)="applyFilter($event,'apellidos', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="apellidos">
            <th mat-header-cell [hidden]="!listColumns['Apellidos']" *matHeaderCellDef mat-sort-header class="header">
              Apellidos
            </th>
            <td mat-cell [hidden]="!listColumns['Apellidos']" *matCellDef="let row">
              {{row.apellidos}}</td>
          </ng-container>

          <!-- Columna Domicilio -->
          <ng-container matColumnDef="domicilio-buscador">
            <th [hidden]="!listColumns['Domicilio']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Domicilio" formControlName="domicilio"
                      (keyup)="applyFilter($event,'domicilio', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="domicilio">
            <th mat-header-cell [hidden]="!listColumns['Domicilio']" *matHeaderCellDef mat-sort-header class="header">
              Domicilio
            </th>
            <td mat-cell [hidden]="!listColumns['Domicilio']" *matCellDef="let row">
              {{row.domicilio}}</td>
          </ng-container>

          <!-- Columna Código postal-->
          <ng-container matColumnDef="codigoPostal-buscador">
            <th [hidden]="!listColumns['Código postal']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Código Postal" formControlName="cp"
                      (keyup)="applyFilter($event,'codigo_postal', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="codigo_postal">
            <th mat-header-cell [hidden]="!listColumns['Código postal']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by codigo postal" class="header">
              Código postal
            </th>
            <td mat-cell [hidden]="!listColumns['Código postal']" *matCellDef="let row">
              {{row.codigo_postal}}</td>
          </ng-container>

          <!-- Columna Población -->
          <ng-container matColumnDef="poblacion-buscador">
            <th [hidden]="!listColumns['Población']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Población" formControlName="poblacion"
                      (keyup)="applyFilter($event,'poblacion', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="poblacion">
            <th mat-header-cell [hidden]="!listColumns['Población']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by poblacion" class="header">
              Población
            </th>
            <td mat-cell [hidden]="!listColumns['Población']" *matCellDef="let row">
              {{row.poblacion}}</td>
          </ng-container>

          <!-- Columna Provincia-->
          <ng-container matColumnDef="provincia-buscador">
            <th [hidden]="!listColumns['Provincia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Provincia" formControlName="provincia"
                      (keyup)="applyFilter($event,'provincia.nombre', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="provincia">
            <th mat-header-cell [hidden]="!listColumns['Provincia']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by provincia" class="header">
              Provincia
            </th>
            <td mat-cell [hidden]="!listColumns['Provincia']" *matCellDef="let row">
              <ng-container *ngIf="row.provincia; else defaultValue">
                {{row.provincia.nombre}}
              </ng-container>
            </td>
          </ng-container>

          <!-- Columna Teléfono -->
          <ng-container matColumnDef="telefono-buscador">
            <th [hidden]="!listColumns['Teléfono']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Teléfono" formControlName="telefono"
                      (keyup)="applyFilter($event,'telefonos', 'CONTAINS_STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="telefonos">
            <th mat-header-cell [hidden]="!listColumns['Teléfono']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by telefono" class="header">
              Teléfono
            </th>
            <td mat-cell [hidden]="!listColumns['Teléfono']" *matCellDef="let row">
              <p class="m-0">{{row.telefonos[0]}}</p>
            </td>
          </ng-container>

          <!-- Columna Emails -->
          <ng-container matColumnDef="email-buscador">
            <th [hidden]="!listColumns['Correo electrónico']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Email" formControlName="email"
                      (keyup)="applyFilter($event,'emails', 'CONTAINS_STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="emails">
            <th mat-header-cell [hidden]="!listColumns['Correo electrónico']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by email" class="header">
              Correo electrónico
            </th>
            <td mat-cell [hidden]="!listColumns['Correo electrónico']" *matCellDef="let row">
              <p class="m-0">{{row.emails[0]}}</p>
            </td>
          </ng-container>

          <!-- Columna Gestoría -->
          <ng-container matColumnDef="gestoria-buscador">
            <th [hidden]="!listColumns['Gestoría']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Gestoría" formControlName="gestoria"
                      (keyup)="applyFilter($event,'gestoria.nombre', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="gestoria">
            <th mat-header-cell [hidden]="!listColumns['Gestoría']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by gestoria" class="header">
              Gestoría
            </th>
            <td mat-cell [hidden]="!listColumns['Gestoría']" *matCellDef="let row">
              <ng-container *ngIf="row.gestoria; else defaultValue">
                {{row.gestoria.nombre}}
              </ng-container>
            </td>
            <ng-template #defaultValue></ng-template>
          </ng-container>

          <!-- Columna Fecha Alta -->
          <ng-container matColumnDef="fechaAlta-buscador">
            <th [hidden]="!listColumns['Fecha de alta']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="fechaAlta" formControlName="fechaAlta"
                      (keyup)="applyFilter($event,'fechaAlta', 'DATE_BETWEEN')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="fechaAlta">
            <th mat-header-cell [hidden]="!listColumns['Fecha de alta']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by fechaAlta" class="header">
              Fecha de Alta
            </th>
            <td mat-cell [hidden]="!listColumns['Fecha de alta']" *matCellDef="let row">
              {{row.fechaAlta | date:'dd-MM-yyyy'}}</td>
          </ng-container>

          <!-- Columna Licencia -->
          <ng-container matColumnDef="licencia-buscador">
            <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                      aria-label="Licencia" formControlName="licencia"
                      (keyup)="applyFilter($event,'licencias.numero_licencia', 'STRING')" placeholder="Buscar por..."
                      class="form-control">
                  </span>
                </div>
              </form>
            </th>
          </ng-container>

          <ng-container matColumnDef="licencias.numero_licencia">
            <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by licencia" class="header">
              Licencia
            </th>
            <td mat-cell [hidden]="!listColumns['Licencia']" *matCellDef="let row">
              <p class="m-0">{{row.licencias[0]?.numero_licencia}}</p>
            </td>
          </ng-container>

          <!-- Columna Activo -->
          <ng-container matColumnDef="activo-buscador">
            <th [hidden]="!listColumns['Activo']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
              <div class="form-check form-check-inline">
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                  id="activo" name="activo" class="form-check-input" checked
                  (change)="setActivoInactivo($event, 'activo')">
                <label for="activo" class="form-check-label">Activo</label>
              </div>
              <div class="form-check form-check-inline">
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                  id="inactivo" name="inactivo" class="form-check-input" checked
                  (change)="setActivoInactivo($event, 'inactivo')">
                <label for="inactivo" class="form-check-label">Inactivo</label>
              </div>
            </th>
          </ng-container>

          <ng-container matColumnDef="fechaBaja">
            <th mat-header-cell [hidden]="!listColumns['Activo']" *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by activo" class="header">
              Activo
            </th>
            <td mat-cell [hidden]="!listColumns['Activo']" *matCellDef="let row">
              <p *ngIf="row.fechaBaja == null; else no" class="m-0"><mat-icon>check</mat-icon></p>
              <ng-template #no><mat-icon>close</mat-icon></ng-template>
            </td>
          </ng-container>

          <!-- Columna Acciones -->
          <ng-container matColumnDef="acciones-buscador" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
          </ng-container>

          <ng-container matColumnDef="acciones" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="header"></th>
            <td mat-cell *matCellDef="let row" class="mat-column acciones">
              <div class="buttons-flex-container">
                <!-- <a class="eye-button activated" (click)="openDialogCreatePropietario(row)"></a> -->
                <a class="eye-button activated" (click)="setMostrarFormulario(true, row, true)"></a>
                <a (click)="downloadFiles(row.id)" *ngIf="!isDownloadFiles" class="download-button activated"></a>
                <a (click)="downloadFiles(row.id)" *ngIf="isDownloadFiles" class="download-button not-activated"></a>
                <a class="delete-button activated" (click)="openDialogDelete(row)"></a>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>
    </div>

    <mat-paginator class="mt-3" #paginator (page)="nextPage($event)" [length]="totalElements" [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>
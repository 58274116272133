
import { IGestoria, NewGestoria } from './../gestoria.model';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';

export type PartialUpdateGestoria = Partial<IGestoria> & Pick<IGestoria, 'id'>;

type RestOf<T extends IGestoria | NewGestoria> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestGestoria = RestOf<IGestoria>;

export type NewRestGestoria = RestOf<NewGestoria>;

export type EntityResponseType = HttpResponse<IGestoria>;
export type EntityArrayResponseType = HttpResponse<IGestoria[]>;

@Injectable({
    providedIn: 'root'
  })
  export class GestoriaService {
  
    protected resourceUrl = `${environment.API_URL}/gestoria`;
  
    constructor(protected http: HttpClient) { }

    list(){
        return this.http.get<any>(`${this.resourceUrl}/list`, {})
    }
  
    create(gestoria: IGestoria){
      return this.http.post(`${this.resourceUrl}/`, gestoria);
    }
  
    update(gestoria: IGestoria): Observable<EntityResponseType> {
      return this.http
        .post<RestGestoria>(this.resourceUrl, gestoria, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<RestGestoria>(`${this.resourceUrl}/${id}`, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    query(req?: any): Observable<EntityArrayResponseType> {
      const options = {};
      return this.http
        .get<RestGestoria[]>(this.resourceUrl, { params: options, observe: 'response' })
        .pipe(map(res => this.convertResponseArrayFromServer(res)));
    }

    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
    }

    protected convertDateFromClient<T extends IGestoria | NewGestoria | PartialUpdateGestoria>(gestoria: T): any {
        return {
          ...gestoria,
          fechaAlta: gestoria.fechaAlta?.toString() ?? null,
          fechaBaja: gestoria.fechaBaja?.toISOString() ?? null,
          fechaModificacion: gestoria.fechaModificacion?.toISOString() ?? null,
        };
      }
    
      protected convertDateFromServer(restGestoria: RestGestoria): IGestoria {
        let fechaBaja = restGestoria.fechaBaja?.split("-");
        let fechaModificacion = restGestoria.fechaModificacion?.split("-");
        return {
          ...restGestoria,
          fechaBaja: restGestoria.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
          fechaModificacion: restGestoria.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
          
        };
      }


    protected convertResponseFromServer(res: HttpResponse<RestGestoria>): HttpResponse<IGestoria> {
        return res.clone({
          body: res.body ? this.convertDateFromServer(res.body) : null,
        });
      }
    
      protected convertResponseArrayFromServer(res: HttpResponse<RestGestoria[]>): HttpResponse<IGestoria[]> {
        return res.clone({
          body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
        });
      }
}
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IConductor } from "src/app/conductores/conductor.model";
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { Licencia } from "src/app/model/Emisora";
import { Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { Prestamos } from "src/app/model/novotaxi/prestamos.model";
import { ModalConfirmacionComponent } from "src/app/novotaxi/modal-confirmacion/modal-confirmacion.component";
import { NotificationService } from "src/app/_services/notification.service";
import { PrestamosService } from "src/app/_services/novotaxi/prestamos/prestamos.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { DataPrestamos } from "../prestamos-devoluciones.component";

@Component({
    selector: 'app-create-update-prestamos',
    templateUrl: './create-update-prestamos.component.html',
    styleUrls: ['./create-update.prestamos.component.css']
})

export class CreateUpdatePrestamosComponent implements OnInit {

    // -- Variables para el guardado del préstamo
    prestamosList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, tipo: null, posiblesLicencias: null }];
    prestamosListToSave: Prestamos[] = [];
    prestamoToSave: Prestamos;

    conductores: IConductor[] = [];
    licencias: Licencia[] = [];
    selectedValueConductor: number = null;
    selectedValueLicencia: number = null;
    fechaAlta: Date;

    // -- Variables control de roles
    userSaved: Usuario;
    currentUser;
    isUserAdmin: boolean = true;

    @Input() data: DataPrestamos;
    @Output() mostrarFormulario = new EventEmitter<DataPrestamos>();

    constructor(
        private _notificationService: NotificationService,
        private _servicioGeneral: ServicioGeneral,
        private _tokenStorageService: TokenStorageService,
        private _prestamosService: PrestamosService,
        private _conductorService: ConductorService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {

        if (!this.data.conductores) {
            this._notificationService.info("INFO", 'No existen conductores disponibles para crear un préstamo', 3000)
        }

        this.conductores = this.data.conductores;

        /* if (this.data.conductor != null) {
            this.prestamosList = [{ fechaAlta: null, licencia: null, conductor: this.data.conductor.nombre + ' ' + this.data.conductor.apellidos, importe: null, concepto: null, numDocumento: null, tipo: null }];
            this.getLicenciasByConductor(this.data.conductor.id);
        } else {
            this.prestamosList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, tipo: null }];
        } */

        this.currentUser = this._tokenStorageService.getUser();
        this.getUser();

        if (this.data.isForUpdate) {
            this.getDataForUpdate();
        }
    }

    /* getConductores() {
        this._conductorService.query().subscribe((res) => {
            this.conductores = res['conductores'];
        });
    } */

    /* getLicencias() {
        this._licenciaService.listLicencia().subscribe((data) => {
            this.licencias = data["licencias"];
        })
    } */

    getUser() {
        this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
            this.userSaved = data['Usuario'];
        })
    }

    getDataForUpdate() {
        this.prestamoToSave = JSON.parse(JSON.stringify(this.data.elementUpdate));

        this.prestamosList = [
            {
                fechaAlta: this.data.elementUpdate?.fechaAlta,
                licencia: this.data.elementUpdate?.licencia,
                conductor: this.data.elementUpdate.conductor,
                importe: this.data.elementUpdate?.importe,
                concepto: this.data.elementUpdate?.concepto,
                numDocumento: this.data.elementUpdate?.numDocumento,
                tipo: this.data.elementUpdate?.tipo,
                posiblesLicencias: this.data.licencias
            }
        ]

        this.fechaAlta = new Date(this.data.elementUpdate.fechaAlta.toString().split("-").reverse().join("-"))
        this.selectedValueLicencia = this.data.elementUpdate.licencia?.id;
        this.selectedValueConductor = this.data.elementUpdate.conductor?.id;

        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

        if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && this.data.elementUpdate?.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un préstamo del día actual", 3000)
            this.isUserAdmin = false;
        } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && this.data.elementUpdate?.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un préstamo del día actual", 3000)
            this.isUserAdmin = false;
        } else {
            this.isUserAdmin = true;
        }
    }

    getLicenciasByConductor(idConductor?, prestamo?) {

        if (prestamo) {
            prestamo.licencia = null;
        }

        this.selectedValueLicencia = null;
        if (idConductor != null) {
            this._conductorService.getLicenciasByConductor(idConductor).subscribe((response) => {
                prestamo.posiblesLicencias = response['licencias']
                this.licencias = response['licencias'];
            });
        }
    }

    saveOrUpdate() {
        if (this.data.isForUpdate) {
            this.updatePrestamo();
        } else {
            this.savePrestamos()
        }
    }

    async openDialogConfirm() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = "guardar";

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.saveOrUpdate();
            } else {
                null
            }
        })
    }

    async confirmCancelOperation() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = 'descartar';

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.resetearForm(true)
            } else {
                null
            }
        })
    }

    savePrestamos() {
        let isValidFields = true;

        this.prestamosList.forEach(element => {

            this.prestamoToSave = JSON.parse(JSON.stringify(element))
            this.prestamoToSave.fechaAlta = this.datePipe.transform(this.prestamoToSave.fechaAlta, 'dd-MM-yyyy');

            if (this.prestamoToSave.conductor) {
                this.prestamoToSave.conductor.fechaAlta = this.convertDate(this.prestamoToSave?.conductor?.fechaAlta);
                if (this.prestamoToSave?.conductor?.fechaVencimientoBTP) {
                    this.prestamoToSave.conductor.fechaVencimientoBTP = this.convertDate(this.prestamoToSave?.conductor?.fechaVencimientoBTP);
                }
            }

            if (this.prestamoToSave.numDocumento == '') {
                this.prestamoToSave.numDocumento = null
            }

            if (this.prestamoToSave.importe != null && (this.selectedValueLicencia != null || this.prestamoToSave.licencia != null)) {
                delete element['posiblesLicencias'];
                this.prestamoToSave.usuario = this.userSaved;
                let newPrestamo: Prestamos = new Prestamos(this.prestamoToSave);
                newPrestamo.licencia.vehiculo = null;

                this.prestamosListToSave.push(newPrestamo);
            } else {
                isValidFields = false
            }
        })

        if (!isValidFields) {
            this._notificationService.info('INFO', 'No se puede crear un préstamo sin importe ni licencia', 5000)
        }

        if (this.prestamosListToSave.length > 0 && isValidFields) {
            this._prestamosService.saveAllPrestamos(this.prestamosListToSave).subscribe((data) => {
                if (data['message']['status'] == 400) {
                    this._notificationService.error("ERROR", data['message']['message'], 3000)
                } else {
                    this.data.prestamos = data['prestamos'];
                    this._notificationService.success("Hecho", 'Préstamo creado correctamente', 3000)
                    this.resetearForm(false);
                }
                this.prestamosListToSave = [];
            })
        }
    }

    updatePrestamo() {

        this.prestamoToSave = JSON.parse(JSON.stringify(this.data.elementUpdate))

        let dataUpdate = JSON.parse(JSON.stringify(this.prestamosList[0]));

        this.prestamoToSave.importe = dataUpdate.importe;
        this.prestamoToSave.concepto = dataUpdate.concepto;
        this.prestamoToSave.numDocumento = dataUpdate.numDocumento;
        this.prestamoToSave.licencia = dataUpdate.posiblesLicencias.find(licencia => licencia.id == this.selectedValueLicencia);
        this.prestamoToSave.conductor = this.conductores.find(conductor => conductor.id == this.selectedValueConductor);
        this.prestamoToSave.fechaAlta = this.datePipe.transform(this.fechaAlta, 'dd-MM-yyyy');

        if (this.prestamoToSave.conductor) {
            this.prestamoToSave.conductor.fechaAlta = this.convertDate(this.prestamoToSave?.conductor?.fechaAlta);
            if (this.prestamoToSave?.conductor?.fechaVencimientoBTP) {
                this.prestamoToSave.conductor.fechaVencimientoBTP = this.convertDate(this.prestamoToSave?.conductor?.fechaVencimientoBTP);
            }
        }

        if (!this.prestamoToSave.licencia && this.prestamoToSave.importe != null) {
            this._notificationService.info('INFO', 'No se puede crear un préstamo sin importe ni licencia', 3000)
        } else {
            delete this.prestamoToSave['posiblesLicencias'];
            this.prestamoToSave.licencia.vehiculo = null;

            this._prestamosService.savePrestamo(new Prestamos(this.prestamoToSave)).subscribe((data) => {
                if (data['message']['status'] == 400) {
                    this._notificationService.error("ERROR", data['message']['message'], 3000)
                } else {
                    this.data.prestamos.push(data['prestamos']);
                    this._notificationService.success("Hecho", 'Préstamo modificado correctamente', 3000)
                    this.resetearForm(false);
                }
                this.prestamosList = [];
            })
        }
    }

    getValue(event) {
        this.fechaAlta = event.target.value;
    }

    addPrestamo() {

        if (this.data.conductor != null) {
            this.prestamosList.push({
                fechaAlta: null, licencia: null, conductor: this.data.conductor, importe: null, concepto: null, numDocumento: null, tipo: null, posiblesLicencias: null
            });
        } else {
            this.prestamosList.push({
                fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, tipo: null, posiblesLicencias: null
            });
        }

    }

    removePrestamo(i) {
        this.prestamosList.splice(i, 1);
    }

    resetearForm(isCancel: Boolean) {

        if (this.data.isForUpdate && isCancel) {
            this.data.prestamos.push(this.data.elementUpdate);
        }

        this.data.mostrarFormulario = false;
        this.mostrarFormulario.emit(this.data);
    }

    convertDate(fecha) {
        const splitDate = fecha.split('-');
        const date = new Date(
            Number(splitDate[2]),   // year
            Number(splitDate[1]) - 1, // month
            Number(splitDate[0])    // day
        );
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
}
<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Gestión de caja</h1>
  <div>
    <button class="btn btn-outline-primary align-middle" *ngIf="isSearch">
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar informe</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-caja #detalles [fecha]="fecha" [tipoDetalle]="tipoDetalle"
  [gestionCajaList]="gestionCajaList"></app-card-detalles-caja>
<!---->

<!-- ------------------------------- Acciones ----------------------- -->
<!--<div class="row" style="padding: 0 15px; margin-top: 2rem;" *ngIf="isSearch">
    <div style="padding-right: 10px;">
        <button class="btn btn-success align-middle">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">PDF</span>
        </button>
    </div>

    <div style="padding-right: 10px;">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
    </div>
</div>-->

<!-- ------------------------------- Tabla ----------------------- -->
<div class="px-5 pb-4 contenedor mb-5 pt-4">
  <div class="d-flex flex-row align-items-center">
    <div class="row align-items-center flex-grow-1 crear-editar-form">
      <div class="col-sm-2">
        <label>Día</label>
        <input _ngcontent-vka-c280="" [(ngModel)]="fechaSearch"
          class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
      </div>
      <div class="col-sm-3">
        <label>Usuario</label>

        <div>
          <div (mouseleave)="showDropDown = false">
            <button class="form-control form-control-sm ng-pristine ng-invalid ng-touched btn-outline btn-select"
              (click)="showDropDown=!showDropDown; focusOut()">
              <i class="fa fa-angle-down"></i>
              {{selectedValueString}}
            </button>
            <div class="drop-show div-drop" *ngIf="showDropDown">
              <input class="input-filter-select" type="text" placeholder="Usuario" (keyup)="filterColumns($event)">
              <label class="label-select">
                <input class="input-select" type="button" (click)="getSelectedValue('TODOS')" />
                TODOS </label>
              <label class="label-select" *ngFor="let a of keys">
                <input class="input-select" type="button" (click)="getSelectedValue(a)" />
                {{a.nombre ?? ''}} {{a.apellido1 ?? ''}} </label>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-sm align-self-end ml-3"
        (click)="search(); detalles.getTotalGestionCaja(this.gestionCajaList);">
        <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
      </button>
      <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="addFila()">
        <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Mostrar totales</span>
      </button>
    </div>
    <div class="btn-group btn-group-sm align-self-start" role="group">
      <app-multi-select-dropdown [listColumns]="listColumns">
      </app-multi-select-dropdown>
    </div>
  </div>
  <div class="table_container mt-5" *ngIf="isSearch">

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="table align-middle mb-0 bg-white">

      <!-- ------------------------------- Columna licencia ----------------------- -->
      <!-- <ng-container matColumnDef="licencia_buscador">
        <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Licencia" formControlName="licencia" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="licencia">
        <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by fechaAlta" class="header">
          Licencia
        </th>
        <td mat-cell [hidden]="!listColumns['Licencia']" *matCellDef="let element">
          {{element['licencia_id']?.numero_licencia}}</td>
      </ng-container>

      <!-- ------------------------------- Columna conductor ----------------------- -->
      <!-- <ng-container matColumnDef="conductor_buscador">
        <th [hidden]="!listColumns['Conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Conductor" formControlName="conductor" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="conductor">
        <th mat-header-cell [hidden]="!listColumns['Conductor']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by fechaAlta" class="header">
          Conductor
        </th>
        <td mat-cell [hidden]="!listColumns['Conductor']" *matCellDef="let element">
          {{element['conductor_id']?.nombre}} {{element['conductor_id']?.apellidos}}</td>
      </ng-container>

      <!-- ------------------------------- Columna recaudación ----------------------- -->
      <!-- <ng-container matColumnDef="recaudacion_buscador">
        <th [hidden]="!listColumns['Recaudacion']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Recaudación" formControlName="recaudacion" (keyup)="applyFilter()"
                  placeholder="Buscar por..." class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="recaudacion">
        <th mat-header-cell [hidden]="!listColumns['Recaudación']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by recaudacion" class="header">
          Recaudación
        </th>
        <td mat-cell [hidden]="!listColumns['Recaudación']" *matCellDef="let element">
          {{element['importe_conductor']}}</td>
      </ng-container>

      <!-- ------------------------------- Columna gasto licencia ----------------------- -->
      <!-- <ng-container matColumnDef="gastoLicencia_buscador">
        <th [hidden]="!listColumns['Gasto licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Gasto Licencia" formControlName="gastoLicencia" (keyup)="applyFilter()"
                  placeholder="Buscar por..." class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="gastoLicencia">
        <th mat-header-cell [hidden]="!listColumns['Gasto licencia']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by gastoLicencia" class="header">
          Gasto licencia
        </th>
        <td mat-cell [hidden]="!listColumns['Gasto licencia']" *matCellDef="let element">
          {{element['importe_total_gastos']}}</td>
      </ng-container>

      <!-- ------------------------------- Columna gasto gestoría ----------------------- -->
      <!-- <ng-container matColumnDef="gastoGestoria_buscador">
        <th [hidden]="!listColumns['Gasto gestoría']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Gasto Gestoría" formControlName="gastoGestoria" (keyup)="applyFilter()"
                  placeholder="Buscar por..." class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="gastoGestoria">
        <th mat-header-cell [hidden]="!listColumns['Gasto gestoría']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by gastoLicencia" class="header">
          Gasto gestoría
        </th>
        <td mat-cell [hidden]="!listColumns['Gasto gestoría']" *matCellDef="let element"></td>
      </ng-container>

      <!-- ------------------------------- Columna Préstamo ----------------------- -->
      <!-- <ng-container matColumnDef="prestamo_buscador">
        <th [hidden]="!listColumns['Préstamo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Préstamo" formControlName="prestamo" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="prestamo">
        <th mat-header-cell [hidden]="!listColumns['Préstamo']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by prestamo" class="header">
          Préstamo
        </th>
        <td mat-cell [hidden]="!listColumns['Préstamo']" *matCellDef="let element">
          {{element['importe_total_prestamos']}}</td>
      </ng-container>

      <!-- ------------------------------- Columna retención ----------------------- -->
      <!-- <ng-container matColumnDef="retencion_buscador">
        <th [hidden]="!listColumns['Retención']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Retención" formControlName="retencion" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="retencion">
        <th mat-header-cell [hidden]="!listColumns['Retención']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by retencion" class="header">
          Retención
        </th>
        <td mat-cell [hidden]="!listColumns['Retención']" *matCellDef="let element"></td>
      </ng-container>

      <!-- ------------------------------- Columna ingreso ----------------------- -->
      <!-- <ng-container matColumnDef="ingreso_buscador">
        <th [hidden]="!listColumns['Ingreso']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Ingreso" formControlName="ingreso" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="ingreso">
        <th mat-header-cell [hidden]="!listColumns['Ingreso']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by ingreso" class="header">
          Ingreso
        </th>
        <td mat-cell [hidden]="!listColumns['Ingreso']" *matCellDef="let element">
          {{element['importe_total_ingresos']}}</td>
      </ng-container>

      <!-- ------------------------------- Columna banco ----------------------- -->
      <!-- <ng-container matColumnDef="banco_buscador">
        <th [hidden]="!listColumns['Banco']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Banco" formControlName="banco" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="banco">
        <th mat-header-cell [hidden]="!listColumns['Banco']" *matHeaderCellDef class="header">
          Banco
        </th>
        <td mat-cell [hidden]="!listColumns['Banco']" *matCellDef="let element"></td>
      </ng-container>

      <!-- ------------------------------- Columna cuadre ----------------------- -->
      <!-- <ng-container matColumnDef="cuadre_buscador">
        <th [hidden]="!listColumns['Cuadre']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Cuadre" formControlName="cuadre" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="cuadre">
        <th mat-header-cell [hidden]="!listColumns['Cuadre']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by cuadre" class="header">
          Cuadre
        </th>
        <td mat-cell [hidden]="!listColumns['Cuadre']" *matCellDef="let element">
          {{element['cuadre'] | numberFormat}}
        </td>
      </ng-container>

      <!-- ------------------------------- Columna fecha ----------------------- -->
      <!-- <ng-container matColumnDef="fecha_buscador">
        <th [hidden]="!listColumns['Fecha']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Fecha" formControlName="fecha" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="fecha">
        <th mat-header-cell [hidden]="!listColumns['Fecha']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by numFactura" class="header">
          Fecha
        </th>
        <td mat-cell [hidden]="!listColumns['Fecha']" *matCellDef="let element">{{element['fecha']}}</td>
      </ng-container>

      <!-- ------------------------------- Columna usuario ----------------------- -->
      <!-- <ng-container matColumnDef="usuario_buscador">
        <th [hidden]="!listColumns['Usuario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
          <form [formGroup]="searchForm" class="search__form">
            <div class="d-flex flex-row align-items-center bg-white rounded">
              <div class="flex-grow-1 d-flex align-items-center">
                <mat-icon class="align-middle pl-1">search</mat-icon>
              </div>
              <span>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                  aria-label="Usuario" formControlName="usuario" (keyup)="applyFilter()" placeholder="Buscar por..."
                  class="form-control">
              </span>
            </div>
          </form>
        </th>
      </ng-container> -->

      <ng-container matColumnDef="usuario">
        <th mat-header-cell [hidden]="!listColumns['Usuario']" *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by fechaAlta" class="header">
          Usuario
        </th>
        <td mat-cell [hidden]="!listColumns['Usuario']" *matCellDef="let element">
          {{element['usuario_id']?.nombre}} {{element['usuario_id']?.apellido1}}
          {{element['usuario_id']?.apellido2}}</td>
      </ng-container>

      <!-- ------------------------------- Acciones ----------------------- -->
      <!-- <ng-container matColumnDef="acciones" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
      </ng-container>

      <ng-container matColumnDef="acciones" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="header"></th>
        <td mat-cell *matCellDef="let element" class="mat-column acciones">
          <div class="buttons-flex-container">
            <a class="eye-button activated" (click)="showDetails(element);"></a>
          </div>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="expand" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element; let last = last">
          <button *ngIf="element.fecha !== ''" mat-icon-button style="background-color: #EFF3FB;"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation(); getDetails(element)">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="div-details mt-2">
              <div *ngFor="let recaudacion of recaudaciones">
                <div class="row mt-4 ml-4">
                  <div class="col-sm-2">
                    <label>RECAUDACION</label>
                  </div>

                  <div class="col-sm-2">
                    <label> {{recaudacion.informe.importe_conductor | numberFormat}} </label>
                  </div>

                  <div class="col-sm-3">
                    <label>0</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{recaudacion.fechaAlta }}</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{recaudacion.usuario.nombre}}</label>
                  </div>
                </div>
                <hr>
              </div>

              <!-- <div class="row mt-4 ml-4">
                <div class="col-sm-2">
                  <label>EFECTIVO</label>
                </div>

                <div class="col-sm-2">
                  <label> {{efectivo | numberFormat}} </label>
                </div>
              </div> 
              <hr>-->

              <div *ngFor="let gastoIngreso of gastosIngresos">
                <div class="row mt-4 ml-4">
                  <div class="col-sm-2">
                    <label *ngIf="gastoIngreso?.tipo_ingreso?.id != 3 ">{{gastoIngreso.tipoGestion}}</label>
                    <label *ngIf="gastoIngreso?.tipo_ingreso?.id == 3">EFECTIVO</label>
                  </div>

                  <div class="col-sm-2">
                    <label> {{gastoIngreso?.importe}} </label>
                  </div>

                  <div class="col-sm-3">
                    <label class="label-text">{{gastoIngreso?.concepto}}</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{gastoIngreso?.fechaAlta }}</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{gastoIngreso?.usuario?.nombre}}</label>
                  </div>
                </div>
                <hr>
              </div>

              <div *ngFor="let prestamo of prestamos">
                <div class="row mt-4 ml-4">
                  <div class="col-sm-2">
                    <label>PRESTAMOS</label>
                  </div>

                  <div class="col-sm-2">
                    <label> {{prestamo?.importe}} </label>
                  </div>

                  <div class="col-sm-3">
                    <label class="label-text">{{prestamo?.concepto}}</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{prestamo?.fechaAlta}}</label>
                  </div>

                  <div class="col-sm-2">
                    <label>{{prestamo?.usuario?.nombre}}</label>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
        [class.example-expanded-row]="expandedElement === element" [class.no-expand]="expandedElement === nuevaFila"
        [ngClass]="{'last-row': isLastRow(element) && !showExpandButtonForLastRow}">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

      <!-- <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr> -->
      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr> -->
    </table>
  </div>

  <mat-paginator class="mt-3" *ngIf="isSearch" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
    aria-label="Select page" #paginator>
  </mat-paginator>
</div>
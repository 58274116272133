import { Documento } from "../model/documento";
import { ConductorSueldo } from "../model/novotaxi/conductor-sueldo.model";
import { IProvincia } from "../model/novotaxi/propietario.model";
import { IVehiculo } from "../vehiculo/vehiculo.model";

export class IConductor {

  id: number;
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
  fechaVencimientoBTP?: string | null;
  tipo_documento?: Documento | null;
  dni?: string | null;
  nombre?: string | null;
  apellidos?: string | null;
  domicilio?: string | null;
  codigoPostal?: string | null;
  provincia?: IProvincia | null;
  poblacion?: string | null;
  mail?: string[] | null;
  cuentaBancaria?: string[] | null;
  retencion?: number | null;
  puntuacion?: number | null;
  valoracion?: number | null;
  lopd?: boolean | null;
  riesgosLaborales?: boolean | null;
  telefono?: string[] | null;
  observaciones?: string[] | null;
  vehiculos?: IVehiculo[] | null;
  // porcentaje?: number | null;
  // salario_fijo?: number | null;
  // nomina?: number | null;
  // tipoSueldo?: TipoSueldo | null;
  dia_libranza?: string | null;
  sueldos?: ConductorSueldo[] | null;

  constructor(mappedData) {
    this.id = mappedData?.id ?? null;
    this.fechaAlta = mappedData?.fechaAlta ? this.convertDatesConductor(mappedData?.fechaAlta) : null;
    this.fechaBaja = mappedData?.fechaBaja ? this.convertDatesConductor(mappedData?.fechaBaja) : null;
    this.fechaModificacion = mappedData?.fechaModificacion ? this.convertDatesConductor(mappedData?.fechaModificacion) : null;
    // this.fechaVencimientoBTP = mappedData?.fechaVencimientoBTP ?? null;
    this.fechaVencimientoBTP = mappedData?.fechaVencimientoBTP ? this.convertDatesConductor(mappedData?.fechaVencimientoBTP) : null;
    this.tipo_documento = mappedData?.tipo_documento ?? null;
    this.dni = mappedData?.dni ?? null;
    this.nombre = mappedData?.nombre ?? null;
    this.apellidos = mappedData?.apellidos ?? null;
    this.domicilio = mappedData?.domicilio ?? null;
    this.codigoPostal = mappedData?.codigoPostal ?? null;
    this.provincia = mappedData?.provincia ?? null;
    this.poblacion = mappedData?.poblacion ?? null;
    this.mail = mappedData?.mail ?? null;
    this.cuentaBancaria = mappedData?.cuentaBancaria ?? null;
    this.retencion = mappedData?.retencion ?? null;
    this.puntuacion = mappedData?.puntuacion ?? null;
    this.valoracion = mappedData?.valoracion ?? null;
    this.lopd = mappedData?.lopd ?? null;
    this.riesgosLaborales = mappedData?.riesgosLaborales ?? null;
    this.telefono = mappedData?.telefono ?? null;
    this.observaciones = mappedData?.observaciones ?? null;
    this.vehiculos = mappedData?.vehiculos ? this.getVehiculos(mappedData.vehiculos) : null;
    // this.porcentaje = mappedData?.porcentaje ?? null;
    // this.salario_fijo = mappedData?.salario_fijo ?? null;
    // this.nomina = mappedData?.nomina ?? null;
    // this.tipoSueldo = mappedData?.tipoSueldo ?? null;
    this.dia_libranza = (mappedData?.dia_libranza == '' || mappedData?.dia_libranza == null) ? null : mappedData.dia_libranza;
    this.sueldos = mappedData.sueldos
  }

  getVehiculos?(vehiculos?) {
    let vehiculosReturn: IVehiculo[] = [];

    vehiculos.forEach((data) => {
      vehiculosReturn.push(new IVehiculo(data));
    })
    return vehiculosReturn;
  }

  convertDatesConductor?(date) {
    let string: String = "";

    if (date != null && typeof date == typeof string && date != '') {

      let fecha = new Date(date);
      let annio = fecha.getFullYear();
      let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
      let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');

      return (dia + '-' + mes + '-' + annio);
    } else {

      if (date == null || date == '') {
        return null;
      } else {
        if (typeof date != 'number') {
          date = new Date(date)
          return date
        } else {
          return date;
        }
      }
    }
  }
}

export type NewConductor = Omit<IConductor, 'id'> & { id: null };


export class TipoSueldo {
  id: number;
  nombre: string;
}
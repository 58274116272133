import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }

  exportToExcel(data: any[], fileName: string): void {
    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Datos');
    XLSX.writeFile(workBook, fileName + '.xlsx');
  }

  exportToExcelWithHeader(data: any[], fileName: string, header): void {
    const workSheet = XLSX.utils.json_to_sheet(data, { header: header });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Datos');
    XLSX.writeFile(workBook, fileName + '.xlsx');
  }
}

<div class="row">
    <div class="col-1 mt-3"></div>
    <ul class="col-10 nav nav-tabs ml-4 mt-4">
        <li class="nav-item">
            <button class="nav-link inidice ml-1"
                [className]="showIndice? 'nav-link indice active ml-1' : 'nav-link indice ml-1'"
                (click)="toggleIndice()">Índice</button>
        </li>
        <li class="nav-item">
            <button class="nav-link funcionamiento ml-1"
                [className]="showFuncionamiento ? 'nav-link funcionamiento active ml-1' : 'nav-link funcionamiento ml-1'"
                (click)="toggleFuncionamiento()">Funcionamiento</button>
        </li>
        <li class="nav-item">
            <button class="nav-link licencias ml-1"
                [className]="showLicencias ? 'nav-link licencias active ml-1' : 'nav-link licencias ml-1'"
                (click)="toggleLicencias()">Licencias</button>
        </li>
        <li class="nav-item">
            <button class="nav-link propietario"
                [className]="showPropietarios ? 'nav-link propietario active ml-1' : 'nav-link propietario ml-1'"
                (click)="togglePropietarios()">Propietarios</button>
        </li>
        <li class="nav-item">
            <button class="nav-link conductores"
                [className]="showConductores ? 'nav-link conductores active ml-1' : 'nav-link conductores ml-1'"
                (click)="toggleConductores()">Conductores</button>
        </li>
        <li class="nav-item">
            <button class="nav-link vehiculos"
                [className]="showVehiculos ? 'nav-link vehiculos active ml-1' : 'nav-link vehiculos ml-1'"
                (click)="toggleVehiculos()">Vehículos</button>
        </li>
        <li class="nav-item">
            <button class="nav-link documentos "
                [className]="showSubidaDoc ? 'nav-link documentos active ml-1' : 'nav-link documentos ml-1'"
                (click)="toggleSubidaDoc()">Subida de documentos</button>
        </li>
        <li class="nav-item">
            <button class="nav-link caja " [className]="showCaja ? 'nav-link caja active ml-1' : 'nav-link caja ml-1'"
                (click)="toggleCaja()">Caja</button>
        </li>
        <li class="nav-item">
            <button class="nav-link gestion-caja "
                [className]="showGestionCaja ? 'nav-link gestion-caja active ml-1' : 'nav-link gestion-caja ml-1'"
                (click)="toggleGestionCaja()">Gestión de caja</button>
        </li>
        <li class="nav-item">
            <button class="nav-link adminstrar "
                [className]="showAdministrar ? 'nav-link adminstrar active ml-1' : 'nav-link adminstrar ml-1'"
                (click)="toggleAdministrar()">Administrar</button>
        </li>
        <li class="nav-item">
            <button class="nav-link informes "
                [className]="showInformes ? 'nav-link informes active ml-1' : 'nav-link informes ml-1'"
                (click)="toggleInformes()">Informes</button>
        </li>
    </ul>
    <div class="col-1"></div>
</div>


<div class="card">

    <!-- <div class="header-container">
        <h4 class="title card-title"></h4>
    </div> -->

    <!-- card índice -->
    <div class="card-body m-0 p-0 d-flex align-items-center" *ngIf="showIndice">
        <div class="row col-6 contenedor info-container m-0">
            <div class="col-12  text-container align-self-start p-0 m-0">
                <h1>Manual de Usuario Novotaxi</h1>
                <hr>
                <img src="assets/novotaxi/novotaxi_logo_transparent.png" alt="logo novotaxi">
            </div>
        </div>
        <div class="row indice col-6 flex-column info-container ml-3 pl-3">

            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleFuncionamiento()">
                    Funcionamiento
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleLicencias()">
                    Licencias
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="togglePropietarios()">
                    Propietarios
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleConductores()">
                    Conductores
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleVehiculos()">
                    Vehículos
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleSubidaDoc()">
                    Subida de documentos
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleCaja()">
                    Caja
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleGestionCaja()">
                    Gestión de Caja
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    (click)="toggleAdministrar()">
                    Administrar
                </a></p>
            <p><a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                    (click)="toggleInformes()">
                    Informes
                </a></p>
        </div>
    </div>

    <!-- card funcionamiento -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showFuncionamiento">
        <div class="row info-container m-2">
            <div class="col-12">
                <p>Existen ciertas funcionalidades que son iguales para todas las pantallas de la aplicación. Estas
                    funcionalidades son las siguientes.
                </p>
            </div>
            <div class="col-12">
                <p>Para comenzar a utilizar la aplicación, primero habrá que dirigirse a la sección<a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleAdministrar()">
                        <b>Administrar</b>
                    </a> para crear marcas, modelos y proveedores, ya que sin estos datos no se podrán crear ni
                    vehículos ni gastos a proveedores.
                </p>
            </div>
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Todas las pantallas a excepción de los informes y el ranking de conductores presentan en la parte
                    superior una tarjeta que mostrará información importante sobre cada menú. Dependiendo del menú en el
                    que nos encontremos se mostrarán unos datos u otros.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/detalles_vehiculos.png" alt="Detalles" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Justo debajo de la tarjeta de detalles, tendremos una tabla que mostrará datos paginados, los cuales
                    se podrán filtrar y ordenar. En esta tabla también se podrán eliminar columnas. En aquellos menús en
                    los que no existe tarjeta de detalles, se mostrará directamente la tabla.
                </p>
                <p>Las pantallas de generación de informes no tendrán ninguna tabla.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/tabla.png" alt="Tabla" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En la tabla, al final de cada fila, encontraremos dos iconos, uno de un ojo que permitirá editar el
                    elemento de la fila y otro de una papelera que permitirá dar de baja el elemento de la fila.
                </p>
                <p>Todas las pantallas que contengan una tabla contendrán estos iconos a excepción del ranking de
                    conductores en el que no se pueden realizar modificaciones.</p>
                <p>Las pantallas de gestión de licencias, propietarios, conductores y control de informes, además de los
                    iconos ya mencionados, también contendrán uno de descarga que se explicará en el apartado de <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleSubidaDoc()">
                        <b>Subida de documentos.</b>
                    </a></p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/iconos_acciones.png" alt="Iconos de acción" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>El icono del ojo, como ya se ha mencionado anteriormente, permitirá editar el elemento de la fila, al
                    pulsarlo, la tabla se sustituirá por un formulario de actualización. Cada menú tiene su formulario
                    personalizado, en este caso, se usará el de gastos e ingresos para mostrar el formulario.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/formulario_actualizar.png" alt="Formulario de actualización"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>A la hora de editar una licencia, un propietario, un vehículo y un conductor, se podrá ver el
                    histórico de cambios del elemento seleccionado en la fila,
                    esto se hará en el botón "Mostrar histórico" en la parte superior derecha del formulario.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/boton_historico.png" alt="Botón histórico" width="90%">
            </div>

            <div class="col-12">
                <p>A la hora de realizar cualquier acción en la aplicación que pueda producir cambios sobre los objetos,
                    aparecerán unas pantallas de confirmación de operación.</p>
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>A la hora de crear o actualizar un elemento, al confirmar la operación, nos saldrá la siguiente
                    pantalla, la cual habrá que aceptar para guardar o actualizar, o cancelar para anular la operación.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/dialog_confirmar.png" alt="Dialog confirmación" width="40%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>A la hora de crear o actualizar un elemento, al cancelar la operación, nos saldrá la siguiente
                    pantalla, la cual habrá que aceptar para descartar los cambios, o cancelar para seguir con la
                    operación.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/dialog_cancelar.png" alt="Dialog cancelar" width="40%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>A la hora de dar de baja (en el icono de la papelera), nos saldrá una pantalla, en la que
                    habrá que introducir una fecha de baja y pulsar sobre el botón 'Borrar' para dar de baja el elemento
                    o sobre el botón 'Cancelar' para anular la operación.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/dialog_eliminar.png" alt="Dialog eliminar" width="40%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Cuando se da de baja un elemento, seguirá apareciendo en la tabla, se podrá saber si el elemento está
                    dado de baja o no,mediante la columna que aparece en la imagen.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/estado_elemento_tabla.png" alt="Botón activación" width="40%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para volver a dar de alta un elemento que se ha dado de baja anteriormente, únicamente habrá que
                    editarlo (a través del icono del ojo explicado anteriormente), y en la parte inferior nos aparecerá
                    un botón de "Activar", únicamente habrá que pulsarlo para volver a dar de alta el elemento.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/activar.png" alt="Botón activación" width="40%">
            </div>


            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En todas las pantallas, en la esquina superior derecha, junto al botón nuevo... tendremos los botones
                    de generar informe en PDF y en Excel, estos botones nos exportarán la tabla a excel y pdf.
                    Únicamente exportará los datos que se muestren en la página de la tabla que nos encontramos.</p>
                <p>El botón de informe en PDF, nos abrirá el pdf generado en otra pestaña.</p>
                <p>El botón de informe en Excel, nos descargará el informe.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/funcionamiento/botones_informe.png" alt="Botones generación de informes"
                    width="90%">
            </div>
        </div>
    </div>


    <!-- card licencia -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showLicencias">
        <div class="row info-container m-2">
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para poder acceder a la sección de las licencias, habrá que dirigirse al apartado Licencias ->
                    Gestión de licencias.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/menu_licencia.png" alt="Menú de licencia" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para crear una licencia habrá que pulsar sobre el botón "Nueva Licencia" que se encuenta en la
                    esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/boton_crear_licencia.png" alt="Botón crear licencia" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Una vez pulsemos el botón "Nueva licencia" se abrirá un formulario en el que tendremos los campos a
                    rellenar para crear una licencia.
                </p>
                <p>En el formulario de licencia, en la parte superior, hay diferentes pestañas en las que se podrá crear
                    el propietario, el vehículo y el conductor asociados a la licencia que se va a crear.</p>
                <p>Aquellos campos que tengan un asterisco rojo <span>*</span> serán obligatorios.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/formulario_licencia.png" alt="Formulario crear licencia" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Al abrir el formulario, la pestaña para crear el conductor no aparecerá hasta que se introduzca la
                    matrícula del vehículo.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/formulario_licencia_vehiculo.png" alt="formulario licencia-vehículo"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Si no se rellenan los campos obligatorios, a la hora de guardar, aparecerá un mensaje de error en la
                    esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/error_licencia.png" alt="error creación licencia" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el histórico de licencia se mostrará la fecha en la que se ha dado de baja y los cambios de
                    propietario junto con las fechas de alta y baja del propietario.</p>
                <p>La explicación para acceder al histórico de licencia se encuentra en el apartado <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleFuncionamiento()">
                        <b>Funcionamiento.</b>
                    </a></p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/licencia/historico_licencia.png" alt="Histórico licencia" width="90%">
            </div>
        </div>
    </div>

    <!-- card propietario -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showPropietarios">
        <div class="row info-container m-2">
            <div class="col-12 col-md-4 mt-2">
                <p>Para poder ver todos los propietarios, habrá que dirigirse al apartado Licencias -> Gestión de
                    propietarios.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center">
                <img src="assets/manual/propietario/menu_propietario.png" alt="Menú de propietario" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para crear un propietario habrá que pulsar sobre el botón "Nuevo propietario" que se encuenta en la
                    esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/boton_crear_propietario.png" alt="Botón crear propietario"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Una vez pulsemos el botón "Nuevo propietario" se abrirá un formulario en el que tendremos los campos
                    a rellenar para crear un propietario.
                </p>
                <p>Aquellos campos que tengan un asterisco rojo <span>*</span> serán obligatorios.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/formulario_propietario.png" alt="Formulario crear propietario"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Si no se rellenan los campos obligatorios o la validación de algún campo falla, a la hora de guardar,
                    aparecerá un mensaje de error en la esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/error_propietario.png" alt="Error creación propietario" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para asociar el propietario a una licenciahabrá que dirigirse al apartado "Licencias" del formulario.
                </p>
                <p>A continuación, habrá que seleccionar la licencia a la que se quiere asociar dicho propietario.</p>
                <p> En el caso de que se quiera asociar a más de una licencia, habrá que pulsar sobre el botón "Añadir
                    licencia" y seleccionar otra licencia diferente.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/asociar_licencia_propietario.png"
                    alt="Asociar licencia a propietario" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para desvincular el propietario de una licencia habrá que dirigirse al apartado "Licencias" del
                    formulario.
                </p>
                <p>En el caso de que tenga más de una licencia, únicamente habrá que darle al botón de eliminar.</p>
                <p> En el caso de que solo tenga una licencia, para desvincularla, habrá que seleccionar la opción
                    "Selecciona una licencia".</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/desvincular_licencia_propietario.png"
                    alt="Desvincular una licencia de un propietario" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el histórico de propietario se mostrará la fecha en la que se ha dado de baja y los cambios de
                    licencia, aparecerá desde que fecha y hasta que fecha ha estado el propietario asociado a la
                    licencia.</p>
                <p>La explicación para acceder al histórico de propietario se encuentra en el apartado <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleFuncionamiento()">
                        <b>Funcionamiento.</b>
                    </a></p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/propietario/historico_propietario.png" alt="Histórico propietario" width="90%">
            </div>
        </div>
    </div>

    <!-- card conductores -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showConductores">
        <div class="row info-container m-2">
            <div class="col-12 col-md-4 mt-2">
                <p>Para poder ver todos los conductores, habrá que dirigirse al apartado Licencias -> Gestión de
                    conductores.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center">
                <img src="assets/manual/conductor/menu_conductor.png" alt="Menú de conductores" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para crear un conductor habrá que pulsar sobre el botón "Nuevo conductor" que se encuenta en la
                    esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/boton_crear_conductor.png" alt="Botón crear conductor" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Una vez pulsemos el botón "Nuevo conductor" se abrirá un formulario en el que tendremos los campos
                    a rellenar para crear un conductor.
                </p>
                <p>Aquellos campos que tengan un asterisco rojo <span>*</span> serán obligatorios.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/formulario_conductor.png" alt="Formulario crear conductor"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Si no se rellenan los campos obligatorios o la validación de algún campo falla, a la hora de guardar,
                    aparecerá un mensaje de error en la esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/error_conductor.png" alt="Error creación conductor" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para asociar el conductor a una licencia habrá que dirigirse a la parte superior del formulario, al
                    campo licencias.
                    A continuación, habrá que seleccionar la licencia a la que se quiere asociar dicho conductor. En el
                    caso de que se quiera asociar a más de una licencia, habrá que pulsar sobre el botón "Añadir
                    licencia" y seleccionar otra licencia. En este caso, el conductor se asociará al vehículo y a la
                    licencia.
                <p>En el caso de que se quiera asociar el conductor a una licencia, y esta licencia no disponga de
                    vehículos, el conductor no se podrá asociar.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/asociar_licencia_conductor.png" alt="Asociación licencia y conductor"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Al asociar un conductor a una licencia, habrá que rellenar los campos tipo sueldo y cantidad. Estos
                    campos serán necesarios para luego poder crear una recaudación. Estos campos hacen referencia a la
                    forma en la que el conductor cobrará (Nómina, porcentaje, salario fijo)</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/guardar_sueldo_conductor.png" alt="Guardar sueldo conductor"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para desvincular el conductor de una licencia habrá que dirigirse a la parte superior del formulario,
                    al campo licencias. En el caso de que tenga más de una licencia, únicamente habrá que darle al botón
                    de eliminar. En el caso de que solo tenga una licencia, para desvincularla, habrá que seleccionar la
                    opción "Selecciona una licencia".
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/desvincular_licencia_conductor.png"
                    alt="Desvinculación licencia y conductor" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el histórico de conductor, se mostrará la fecha en la que se ha dado de baja y los cambios de
                    licencia, aparecerá desde que fecha y hasta que fecha ha estado el conductor asociado a la
                    licencia.</p>
                <p>La explicación para acceder al histórico se encuentra en el apartado <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleFuncionamiento()">
                        <b>Funcionamiento.</b>
                    </a></p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/conductor/historico_conductor.png" alt="Histórico conductor" width="90%">
            </div>
        </div>
    </div>

    <!-- card vehiculos -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showVehiculos">
        <div class="row info-container m-2">
            <div class="col-12 col-md-4 mt-2">
                <p>Para poder ver todos los vehículos, habrá que dirigirse al apartado Licencias -> Gestión de
                    vehículos.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center">
                <img src="assets/manual/vehiculo/menu_vehiculo.png" alt="Menú de vehiculos" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para crear un vehículo habrá que pulsar sobre el botón "Nuevo vehículo" que se encuenta en la
                    esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/boton_crear_vehiculo.png" alt="Botón crear vehículo" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Una vez pulsemos el botón "Nuevo vehículo" se abrirá un formulario en el que tendremos los campos
                    a rellenar para crear un vehículo.
                </p>
                <p>Aquellos campos que tengan un asterisco rojo <span>*</span> serán obligatorios.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/formulario_vehiculo.png" alt="Formulario creación vehículo"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Si no se rellenan los campos obligatorios o la validación de algún campo falla, a la hora de guardar,
                    aparecerá un mensaje de error en la esquina superior derecha.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/error_vehiculo.png" alt="Error creación vehículo" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para asociar el vehículo a una licencia, habrá que dirigirse a la sección "Datos vehículo", al
                    campo licencia. A continuación, habrá que seleccionar la licencia a la que se quiere asociar dicho
                    vehículo.
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/asociar_licencia_vehiculo.png" alt="Asociación licencia y vehículo"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para desvincular el vehículo de una licencia habrá que dirigirse a la sección "Datos vehículo", al
                    campo licencia. A continuación, habrá que seleccionar la opción "Selecciona una licencia".
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/desvincular_licencia_vehiculo.png"
                    alt="Desvinculación licencia y vehículo" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el histórico de vehículo, se mostrará la fecha en la que se ha dado de baja y los cambios de
                    licencia, aparecerá desde que fecha y hasta que fecha ha estado el vehículo asociado a la
                    licencia.</p>
                <p>La explicación para acceder al histórico se encuentra en el apartado <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleFuncionamiento()">
                        <b>Funcionamiento.</b>
                    </a></p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/vehiculo/historico_vehiculo.png" alt="Histórico vehículo" width="90%">
            </div>
        </div>
    </div>


    <!-- card Subida de documentos -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showSubidaDoc">
        <div class="row info-container m-2">

            <div class="col-12">
                <p>
                    En la aplicación, en los apartados licencia, propietario y conductor, se permite la subida de
                    documentos. Estos documentos serán subidos a la nube para su almacenamiento.
                </p>
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Los documentos se podrán subir desde los formularios utilizados para crear un propietario y un
                    conductor, y en los apartados conductor y propietario del formulario crear licencia. </p>
                <p>En la parte inferior del formulario, en el apartado "Documentos", podremos pulsar sobre el botón y
                    adjuntar el documento que queramos, se puede adjuntar más de uno. También se podrán adjuntar los
                    documentos arrastrándolos desde el explorador de archivos a la caja de documentos.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/documentos/subida_documentos.png" alt="Botón subida de documentos" width="90%">
            </div>


            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Se puede adjuntar más de un documento. Una vez adjuntados los documentos, en la parte derecha,
                    veremos el nombre del documento y un icono de acción. Este icono de una papelera, nos permitirá
                    eliminar el documento en el caso de que nos hayamos equivocado al subirlo.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/documentos/eliminar_documento.png" alt="Botón eliminar documento" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>A la hora de actualizar un propietario o un conductor, también nos permitirá modificar los documentos
                    asociados.
                </p>
                <p>A la hora de actualizar una licencia, únicamente nos permitirá ver los documentos asociados del
                    propietario y el conductor y descargarlos, pero no podremos editarlos ni eliminarlos, esto habrá que
                    hacerlo desde la sección de "Gestión de propietarios" o "Gestión de conductores".</p>
                <p>Para modificarlos, será igual que en el paso anterior, podremos adjuntar más documentos y al pulsar
                    sobre el icono de la papelera, podremos eliminar un documento.</p>
                <p>A la hora de editar, tendremos un icono de acción extra, este icono nos permitirá descargar el
                    documento y se nos guardará en nuestro PC.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/documentos/descarga_editar_documentos.png" alt="Botón descarga documento"
                    width="100%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>También podremos desde el listado de propietarios y conductores, descargar un zip con todos los
                    documentos que tenga asociados.
                </p>
                <p>Esto se hará desde el botón de descarga de los iconos de acción de la tabla.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/documentos/descarga_zip_documentos.png" alt="Botón descargar zip" width="90%">
            </div>
        </div>
    </div>


    <!-- card caja -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showCaja">
        <div class="row info-container m-2">

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    En el apartado Caja del menú, podremos crear gastos e ingresos, préstamos, recaudaciones...
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/menu_caja.png" alt="Menú caja" width="35%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    En todas las opciones de caja, para que nos aparezca la tabla con todos los datos, habrá previamente
                    que realizar una búsqueda
                    introduciendo un intervalo de fechas, una licencia y un conductor o solo una licencia, los únicos
                    campos obligatorios para la búsqueda, son las fechas.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/buscador_caja.png" alt="Buscador" width="90%">
            </div>

            <!-- Recaudación -->
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    En la sección <b>Recaudación</b>, al pulsar sobre el botón "Nueva recaudación", se abrirá el
                    formulario para crear una nueva recaudación para una licencia y un conductor.
                </p>
                <p>Al seleccionar la licencia y el conductor, nos aparecerán automáticamente algunos valores:
                    <li>Día de libranza.</li>
                    <li>Kilómentros de entrada.</li>
                    <li>Taxímetro de entrada.</li>
                    <li>Porcentaje del conductor.</li>
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/formulario_recaudacion.png" alt="Formulario recaudación" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el caso de que se seleccione una fecha en la que el conductor libre, nos aparecerá un mensaje de
                    advertencia en la parte superior derecha y se deshabilitarán todos los campos del formulario.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/error_libranza.png" alt="Error día de libranza" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En la tabla de recaudación, a la derecha del botón Buscar, encontramos dos botones:
                    <li>Mostrar totales.</li>
                    <li>Cobrar.</li>
                    <li>Liquidar.</li>
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/botones_recaudacion.png" alt="Botones recaudación" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>El botón mostrar totales, nos mostrará al final de la tabla, todos los totales de recaudación.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/totales_recaudacion.png" alt="Totales recaudación" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Por otro lado, el botón cobrar, nos hará los cálculos necesarios sobre la recaudación en función del
                    tipo de sueldo del conductor y nos creará un préstamo con dicha recaudación. Sólo se podrá hacer uso
                    de este botón, si están todos los campos de búsquedar rellenos.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/prestamo_boton_cobrar.png" alt="Cobrar recaudación" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Por otro lado, el botón liquidar, nos hará los cálculos necesarios sobre la recaudación en función
                    del tipo de sueldo del conductor y nos creará una liquidación con cuyo importe será la cantidad
                    total del conductor. Sólo se podrá hacer uso de este botón, si están todos los campos de
                    búsquedar rellenos.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/liquidacion_boton_liquidar.png" alt="Liquidar recaudación" width="90%"
                    height="100px">
            </div>

            <!-- Gastos e ingresos y prestamos-->
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En la sección <b>Gastos e Ingresos</b>, <b>Préstamos</b> y <b>Retenciones y Liquidaciones</b> los
                    formularios son practicamente iguales, por lo que todos funcionan de la misma manera.</p>
                <p>A la hora de crear uno de estos elementos, nos aparecerá el siguiente formulario.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/formulario_gastos.png" alt="Formulario gastos" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Para facilitar el guardado de varios registros a la vez, se ha añadido un botón en la parte inferior,
                    en el que permite añadir un nuevo formulario.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/añadir_formulario.png" alt="Botón añadir formulario" width="90%">
            </div>

            <div class="col-12 mt-2">
                <p>En el caso de los <b>Ingresos</b>, en el campo tipo, se podrá seleccionar la opción <b>EFECTIVO</b>,
                    la cual representará el dinero en efectivo queluego se mostrará en la <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleGestionCaja()">
                        <b>Gestión de caja.</b>
                    </a></p>
            </div>

            <!-- Gastos a proveedores-->
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En la sección <b>Gastos a proveedores</b> para crear un nuevo gasto habrá que pulsar sobre el botón
                    "Nuevo gasto a proveedor" en la parte superior derecha.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/boton_crear_gasto_proveedor.png" alt="Botón crear gasto a proveedores"
                    width="100%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Un gasto a proveedor solo se podrá crear si se han creado anteriormente proveedores (la explicación
                    de como crear un proveedor se encuentra en la sección <a
                        class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover disabled"
                        (click)="toggleAdministrar()">
                        <b>Administrar.</b>
                    </a>), en el caso de que no se hayan creado proveedores anteriormente y no se disponga de ninguno, a
                    la hora de crear un gasto, aparecerá el siguiente mensaje de información en la parte superior
                    derecha.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/error_proveedor.png" alt="Error proveedor" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Una vez creado un proveedor, se podrá crear un gasto de proveedores a través del formulario.</p>
                <p>Los campos marcados con un asterisco rojo <span>*</span> son obligatorios.</p>
                <p>En el campo IVA, se mostrará el iva calculado sobre el importe introducido, la cantidad del IVA será
                    la que se haya introducido anteriormente en el concepto del proveedor.</p>
                <p>En la parte inferior del formulario se observa el tipo de cantidad y la cantidad del conductor y el
                    propietario, esta cantidad podrá ser un porcentaje o un importe y será lo que le corresponde pagar a
                    cada uno.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/caja/formulario_gasto_proveedores.png" alt="Formulario gasto a proveedores"
                    width="90%">
            </div>
        </div>
    </div>


    <!-- card Gestión de caja -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showGestionCaja">
        <div class="row info-container m-2">

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    En el apartado Caja/Bancos del menú, en la sección Gestión de caja, se podrán ver los detalles de la
                    caja del día seleccionado.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/gestionCaja/menu_gestion_caja.png" alt="Menú gestión caja" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    De primeras, aparecerán unas opciones de búsqueda, en las que habrá que introducir el día y el
                    usuario para el que se quiere obtener la caja. Una vez hecho esto, habrá que pulsar sobre el botón
                    "Buscar".
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/gestionCaja/buscador_gestionCaja.png" alt="Buscador gestión caja" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Al pulsar sobre el botón "Buscar", nos aparecerá una tabla con todos los datos de la caja.
                </p>
                <p>Cada fila de la tabla, se podrá desplegar para ver toda la información de la caja.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/gestionCaja/gestionCaja.png" alt="Tabla gestión caja" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el desplegable, podremos observar, las recaudaciones realizadas, el efectivo (se ingresará a
                    través de los igresos, seleccionando el tipo EFECTIVO), los préstamos, los gastos... </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/gestionCaja/tabla_gestionCaja.png" alt="Tabla gestión caja desplegada"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Por otro lado, en la parte superior, al lado del botón "Buscar", tendremos un botón "Mostrar
                    totales", este será el encargado de mostrarnos los totales de la gestión de caja, al pulsare sobre
                    el botón de nuevo, los totales desaparecerán.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/gestionCaja/boton_totales_gestionCaja.png" alt="Botón totales gestión caja"
                    width="90%">
            </div>

        </div>
    </div>

    <!-- card Administrar -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showAdministrar">
        <div class="row info-container m-2">

            <div class="col-12">
                <p>
                    En esta sección se crearán elementos que serán necesarios para crear vehículos, propietarios,
                    licencias...
                </p>
            </div>
            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Estos elemtos se crearán en la sección administrar del menú, y encontraremos:
                    <li>Gestorías.</li>
                    <li>Compañías de seguros.</li>
                    <li>Emisoras.</li>
                    <li>Marcas/Modelos.</li>
                    <li>Proveedores.</li>
                    <li>Días de libranza.</li>
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/menu_administrar.png" alt="Menú administrar" width="35%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Será necesario crear marcas y modelos para posteriormente poder crear los vehículos.
                </p>
                <p>Primero habrá que pulsar sobre el botón marca y una vez creada esta, habrá que crear el modelo
                    asociado a la marca.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/crear_marca_modelo.png" alt="Botones crear marca y modelo"
                    width="100%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Será necesario crear proveedores para posteriormente poder crear los gastos a proveedores.
                </p>
                <p>Primero habrá que pulsar sobre el botón "Nuevo proveedores".</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/crear_proveedor.png" alt="Botón crear proveedor" width="100%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Una vez hecho esto, se abrirá el formulario de crear proveedor.
                </p>
                <p>Los campos marcados con un asterisco rojo <span>*</span> son obligatorios.</p>
                <p>Se podrá añadir más de un concepto para cada proveedor, para esto, habrá que pulsar sobre el botón
                    "Añadir concepto" e ir añadiendo los diferentes conceptos.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/formulario_crear_proveedor.png" alt="Formulario creación proveedor"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Se podrán modificar los días de libranza, en el menú "Días de libranza".
                </p>
                <p>Para modificar el día de libranza de un conductor habrá que pulsar sobre el botón "Cambiar día de
                    libranza".</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/boton_diaLibranza.png" alt="Botón cambio día de libranza"
                    width="100%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    Al pulsar sobre el botón "Cambiar día de libranza" se nos abrirá un formulario.
                </p>
                <p>Los campos marcados con un asterisco rojo <span>*</span> son obligatorios.</p>
                <p>En este formulario habrá que introducir la licencia, el conductor, el día de libranza actual y el
                    nuevo día de libranza.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/administrar/formulario_diaLibranza.png" alt="Formulario día de libranza"
                    width="90%">
            </div>
        </div>
    </div>


    <!-- card informes -->
    <div class="card-body ml-1 d-flex align-items-center" *ngIf="showInformes">
        <div class="row info-container m-2">

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>
                    En la aplicación, en el menú de informe, se podrán generar tanto informes de propietario, como
                    informes de conductor. También habrá un control de informes.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/informes/menu_informes.png" alt="Menú informes" width="45%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>En el apartado de informe de propietario como en el apartado de informe de conductor, tendremos
                    varios campos a rellenar antes de generar el
                    informe. Una vez rellenos los campos, únicamente habrá que pulsar sobre el botón "Informe" para que
                    nos abra el informe generado en una ventana nueva.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/informes/pantalla_generar_informe.png" alt="Formulario generar informe"
                    width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Al generar un informe como está explicado en el paso anterior, este informe será subido a NextCloud y
                    guardado en base de datos.</p>
                <p>En el apartado del menú "Control de informes", tras realizar una búsqueda por fechas, se mostrarán
                    todos los informes generados en ese intervalo de fechas.
                </p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/informes/tabla_informe.png" alt="Tabla control de informes" width="90%">
            </div>

            <div class="col-12 col-md-4 mt-2 pt-4 pb-4">
                <p>Al final de cada fila de la tabla, encontramos dos iconos.</p>
                <p>El icono de la papelera nos permitirá eliminar el informe, tanto de NextCloud como de base de datos.
                </p>
                <p>El icono de descarga, nos permitirá descargar el informe.</p>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center pt-4">
                <img src="assets/manual/informes/iconos_tabla_informe.png" alt="Acciones tabla control de informes"
                    width="90%">
            </div>
        </div>
    </div>
</div>
import { FormControl, FormGroup } from "@angular/forms";
import { LicenciaServiece } from "src/app/_services/licenciaService.service";
import { NotificationService } from "src/app/_services/notification.service";
import { GastosIngresosService } from "src/app/_services/novotaxi/gastos-ingresos.service";
import { RecaudacionService } from "src/app/_services/novotaxi/recaudacion/recaudacion.service";
import { IConductor } from "src/app/conductores/conductor.model";
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { Licencia } from "src/app/model/Emisora";
import { firstValueFrom } from 'rxjs';
import { image } from '../../img/image.const';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Injectable } from "@angular/core";
import { DatePipe } from '@angular/common'

@Injectable()
export class InformePDF {



  constructor(
    public licenciaService: LicenciaServiece,
    private conductorService: ConductorService,
    private _recaudacionService: RecaudacionService,
    private _gastosIngresosService: GastosIngresosService,
    private _notificationService: NotificationService,
    private datepipe: DatePipe,

  ) { }
  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
    idLicencia: null,
    idConductor: null
  };
  respuestaGastosIngresos;
  gastosIngresosArray: any[] = [
    ['Factura', 'Tipo', 'Concepto', 'Importe'],
  ]
  datosConductor: any[] = [
    ['DNI', 'Nombre', 'Apellidos', 'Fecha Alta', 'Fecha Vencimiento BTP', 'Domicilio', 'Poblacion',
      'Provincia nombre', 'Codigo Postal', 'Mail', 'Telefono', 'Cuenta Bancaria', 'fijo/dia', 'Retencion', 'Porcentaje',
      'lp', 'Rl', 'Fecha Baja']
  ]
  datosPropietario: any[] = [
    ['DNI', 'Nombre', 'Apellidos', 'Domicilio', 'Provincia', 'Poblacion', 'Codigo postal',
      'Emails', 'Telefonos', 'Gestoria', 'FechaAlta', 'Licencia']
  ]
  datosVehiculo: any[] = [
    ['Matricula', 'Marca', 'Modelo',
      'Seguro', 'Padronal',
      'Casa Taximetro', 'Clase Taximetro', 'Vencimiento Taximetro', 'Ultima TarifaAplicada',
      'km Entrada', 'km Ultimo Cambio Aceite', 'km Proximo Cambio Aceite',
      'Tipo Aceite', 'Vencimiento ITV', 'Utima Revision',
      'Fecha Baja', 'tipo Vehiculo']
  ]
  datosLicencia: any[] = [
    ['Nº licencia', 'Fecha Alta', "Nombre Propietario", "Apellido Propietario", "Nombre Conductor",
      "Apellidos conductor", "Matricula", "Marca", "Modelo", "CIA. Seguro", "Gestoria", 'Día libranza', 'Emisora',
      'Clave emisora', 'Estado']
  ]
  datosRecaudacion: any[] = [
    ['Nombre conductor', 'Fech Alta', 'Festivo', 'km entrada', 'km salida', 'km totales', 'Taximetro entrada',
      'Taximetro salida', 'Importe bruto', 'Importe conductor', 'Importe propietario', 'Total propietario', 'visa',
      'Total vales', 'Banco', 'Efectivo', 'Observaciones']
  ]
  datosRecaudacionTotales: any[] = [
    ['Importe bruto', 'Importe conductor', 'Importe propietario', 'Total tarjetas', 'Total vales', 'Efectivo', 'Kilometros totales', 'Banco']
  ]
  datosGastosIngresos: any[] = [
    ['Fecha Alta', 'Tipo Gestion', 'Importe', 'Concepto', 'Tipo', 'Nº Factura', 'Nº Licencia', 'Nombre Conductor', 'Nombre usuario']
  ]

  datosGastosProveedores: any[] = [
    ['Fecha Alta', 'Tipo', 'Importe', 'Nombre', 'CIF', 'Concepto',
      'IVA', 'Nº Factura', 'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario',]
  ]
  datosRecaudacionLiquidacion: any[] = [
    ['Fecha Alta', 'Importe', 'Tipo', 'Concepto', 'Nº Factura',
      'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario']
  ]
  datosPrestamosDevoluciones: any[] = [
    ['Fecha Alta', 'Importe', 'Tipo', 'Concepto', 'Nº Factura',
      'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario']
  ]
  datosGestoria: any[] = [
    ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
  ]
  datosCompania: any[] = [
    ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
  ]
  datosEmisora: any[] = [
    ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
  ]
  datosMarcaModelo: any[] = [
    ['Marca', 'Modelo']
  ]
  datosProveedores: any[] = [
    ['Nombre', 'CIF', 'Concepto', 'IVA']
  ]
  datosIngresos: any[] = [
    ['Fecha Registro', 'Importe', 'Concepto', 'Usuario']
  ]

  /*ngOnInit(): void {
    this.getAllLicencias();Banco
 
  }*/

  listaLicencias: Licencia[];
  licenciaPrueba: Licencia;
  licenciaConductores: IConductor[];

  getAllLicencias() {
    this.licenciaService.listLicencia().subscribe(
      (res) => {
        this.listaLicencias = res['licencias'];
        this.licenciaPrueba = this.listaLicencias ? this.listaLicencias[0] : null;
      }
    )
  }

  getAllConductoresLicencia() {
    this.conductorService.getConductorByLicencia(this.form.value.licencia).subscribe(
      (res) => {
        this.licenciaConductores = res['conductores']
      }
    )
  }

  form = new FormGroup({
    licencia: new FormControl(0),
    conductor: new FormControl(0),
    desde: new FormControl(),
    hasta: new FormControl(),
  })

  licenciaSeleccionada = false;
  obtenerLicencia() {
    this.licenciaSeleccionada = true;
    this.licenciaPrueba = this.listaLicencias.find(licencia => licencia.id == this.form.value.licencia);
    if (this.licenciaPrueba != null) {
      this.getAllConductoresLicencia();
    }
  }

  buildConductor(importeConductor, dias, conductor) {
    let body = [
      { text: 'Conductor', bold: true, fontSize: 13, margin: [0, 20, 0, 20] },
      {
        columns: [
          {
            table: {
              body: [
                [{ text: 'Nombre: ', bold: true }, { text: conductor?.nombre + " " + conductor?.apellidos }],
                [{ text: 'DNI: ', bold: true }, { text: conductor?.dni }],
                //[{ text: 'Días computados: ', bold: true, margin: [0, 5, 0, 0] }, { text: 17, margin: [0, 5, 0, 0] }],
                //[{ text: 'Días laborables: ', bold: true }, { text: 22 }]
              ]
            }, fontSize: 10, layout: 'noBorders'
          },
          {
            table: {
              widths: ['auto', 58, 'auto', 58],
              body: [
                [{ text: 'Imp. bruto: ', bold: true }, { text: importeConductor?.importe_bruto != null ? Number(importeConductor?.importe_bruto?.toFixed(2)) + ' €' : 0 + ' €' }, { text: 'Imp. medio diario: ', bold: true }, { text: importeConductor.importe_bruto != null ? Number(importeConductor?.importe_bruto / this.numRecaudacionesConductor?.length ?? 0).toFixed(2) + ' €' : 0 + ' €' }],
                [{ text: 'Km. totales: ', bold: true }, { text: importeConductor?.km_totales != null ? Number(importeConductor?.km_totales?.toFixed(2)) + ' Km' : 0 + ' Km' }, { text: 'Km. medios diarios: ', bold: true }, { text: importeConductor?.km_totales != null ? Number(importeConductor?.km_totales / this.numRecaudacionesConductor?.length ?? 0).toFixed(2) + ' Km' : 0 + ' Km' }],
                [{ text: 'Imp. propietario: ', bold: true }, { text: importeConductor?.importe_propietario != null ? Number(importeConductor?.importe_propietario?.toFixed(2)) + ' €' : 0 + ' €' }, { text: 'Porcentaje : ', bold: true }, { text: (100 - (conductor?.porcentaje ?? 0)) + ' %' }],
                [{ text: 'Imp. Tarjetas: ', bold: true }, { text: importeConductor?.importe_tarjeta != null ? Number(importeConductor?.importe_tarjeta?.toFixed(2)) + ' €' : 0 + ' €' }, { text: 'Imp. Emisoras : ', bold: true }, { text: (0) + ' €' }],
                [{ text: 'Imp. Efectivo: ', bold: true }, { text: importeConductor?.efectivo != null ? Number(importeConductor?.efectivo?.toFixed(2)) + ' €' : 0 + ' €' }, { text: 'D. laborables : ', bold: true }, { text: dias }],
                [{ text: 'D. computados : ', bold: true }, { text: this.numRecaudacionesConductor?.length ?? 0 }, {}, {}],

              ]
            }, fontSize: 10, margin: [-45, 0, 0, 0]
          },
        ], columnGap: -20
      },
      //{canvas: [ { type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB'} ], alignment: 'center', margin:[0, 20, 0, 0]},
    ];

    return body;



  }
  tipoCobroConductor: any[] = [
    [{}, {}],
  ];
  buildPago(recaudacion, conductor, importeConductor) {
    let porcentajeConductor = conductor?.porcentaje / 100;

    if (conductor?.salario_fijo) {

      this.tipoCobroConductor.push([{ text: 'Transferir a conductor: ' }, { text: (conductor?.salario_fijo ?? 0) + '€', bold: true, alignment: 'right' }],
        [{ text: 'A propietario: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
        [{ text: 'Ajuste del mes anterior: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
        [{ text: 'Saldo: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }])
    } else if (conductor?.porcentaje != null) {
      this.tipoCobroConductor.push([{ text: 'Porcerntaje Conductor: ' }, { text: (conductor?.porcentaje ?? 0) + ' %', bold: true, alignment: 'right' }],
        [{ text: 'Importe conductor: ' }, { text: (((recaudacion?.importe_bruto ?? 0) * (porcentajeConductor ?? 0)).toFixed(2) ?? 0) + ' €', bold: true, alignment: 'right' }],
        [{ text: 'A propietario: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
        [{ text: 'Ajuste del mes anterior: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
        [{ text: 'Saldo: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }])

    }
    //let liquidacionConductorAux = importeConductor?.importe_conductor != null ? importeConductor?.importe_conductor : 0  + this.respuestaGastosIngresos != null ? this.respuestaGastosIngresos : 0;
    //let liquidacionConductor = liquidacionConductorAux !=  null ? Number(liquidacionConductorAux.toFixed(2)) : 0 
    let body = [
      { text: 'Pago', bold: true, fontSize: 13 },
      {
        columns: [
          {
            table: {
              widths: [250, 250],
              heights: 20,
              body: this.tipoCobroConductor,
            }, fontSize: 10, layout: 'lightHorizontalLines'
          },
          /*{
          table: {
            widths: [180, 100],
            body: [
              [{ text: 'Liquidación conductor: ' }, { text: (Number(importeConductor.importe_conductor.toFixed(2) )?? 0) + (this.respuestaGastosIngresos?? 0) + '€', bold: true }],
              [{ text: 'Líquido nómina: ' }, { text: (conductor.salario_fijo ?? 0 )+ ' €', bold: true }],
              [{ text: 'Suplidos facturas: ' }, { text: 0+ ' €', bold: true }],
              [{ text: 'Anticipo salarial: ' }, { text: 0+ ' €', bold: true }],
 
 
            ]
          }, fontSize: 10
        }*/
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 20, 0, 0] },
    ]

    return body;
  }

  buildTituloFecha() {
    let body = [
      { text: 'Informe', alignment: 'center', lineHeight: 1.3, margin: [0, 15, 0, 0], bold: true, fontSize: 12 },
      { text: this.form.value.desde + ' - ' + this.form.value.hasta, alignment: 'center', fontSize: 11 }
    ];

    return body;
  }


  buildDetalleGastos() {
    let body = [
      { text: 'Gastos', bold: true, fontSize: 13, margin: [0, 20, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [60, 170, 150, 60],
              heights: 20,
              body: this.gastosIngresosArray,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 10, dontBreakRows: false,
          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 20, 0, 0] },
    ];

    return body;
  }
  buildInformeConductor(dataSource) {
    dataSource.forEach(element => {
      this.datosConductor.push([{ text: element?.dni ?? '' }, { text: element?.nombre ?? 'Fernando' }, { text: element?.apellidos ?? '' }, { text: element?.fechaAlta ?? '' }, {}, { text: element?.domicilio ?? '' }, { text: element?.provincia?.ccaa?.nombre ?? '' }, { text: element?.poblacion ?? '' }, { text: element?.codigoPostal ?? '' }, { text: element?.mail[0] ?? '' }, { text: element?.telefono[0] ?? '' }, { text: element?.cuentaBancaria[0] ?? '' }, {}, { text: element?.retencion ?? '' }, { text: element?.porcentaje ?? '' }, element?.lopd == true ? { text: 'T' } : { text: 'F' }, element?.riesgosLaborales == true ? { text: 'T' } : { text: 'F' }, { text: element?.fechaBaja ?? '' }])

    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 7, 7, 32],
              heights: 20,
              body: this.datosConductor,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 6.5, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformePropietario(dataSource) {
    dataSource.forEach(element => {
      this.datosPropietario.push([{ text: element?.numero_documento ?? '' }, { text: element?.nombre ?? 'Fernando' }, { text: element?.apellidos ?? '' }, { text: element?.domicilio ?? '' }, { text: element?.provincia?.ccaa?.nombre ?? '' }, { text: element?.poblacion ?? '' }, { text: element?.codigo_postal ?? '' }, { text: element?.emails[0] ?? '' }, { text: element?.telefonos[0] ?? '' }, { text: element?.gestoria ?? '' }, { text: element?.fechaAlta ?? '' }, { text: element?.licencias[0]?.numero_licencia ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45],
              heights: 20,
              body: this.datosPropietario,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 8, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeVehiculo(dataSource) {
    dataSource.forEach(element => {
      this.datosVehiculo.push([{ text: element?.matricula ?? '' }, { text: element?.modelo?.marca?.nombre ?? '' }, { text: element?.modelo?.nombre ?? '' }, { text: element?.seguro?.ciaSeguro?.nombre ?? '' }, { text: element?.seguro.mutuaPadronal ?? '' }, { text: element?.taximetro?.casaTaximetro ?? '' }, { text: element?.taximetro?.claseTaximetro ?? '' }, { text: element?.documentacion?.revision?.vencimientoTaximetro ?? '' }, { text: '' }, { text: element?.mantenimiento?.kmEntrada ?? '' }, { text: element?.mantenimiento.kmUltimoCambioDeAceite ?? '' }, { text: element?.mantenimiento.kmProximoCambioDeAceite ?? '' }, { text: element?.mantenimiento?.tipoAceite ?? '' }, { text: element?.documentacion?.vencimientoITV ?? '' }, { text: element?.documentacion?.ultimaRevision ?? '' }, { text: element?.fechaBaja ?? '' }, {
        text: element?.tipo
          ?.nombre ?? ''
      }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32],
              heights: 20,
              body: this.datosVehiculo,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 7, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeLicencia(dataSource) {
    dataSource.forEach(element => {
      this.datosLicencia.push([{ text: element?.numero_licencia ?? '' }, { text: element?.fechaAlta ?? '' }, { text: element?.propietario?.nombre ?? '' }, { text: element?.propietario?.apellidos ?? '' }, { text: element?.vehiculo[0]?.conductor?.nombre ?? '' }, { text: element?.vehiculo[0]?.conductor?.apellidos ?? '' }, { text: element?.vehiculo[0]?.matricula ?? '' }, { text: element?.vehiculo[0]?.modelo?.marca?.nombre ?? '' }, { text: element?.vehiculo[0]?.modelo?.nombre ?? '' }, { text: element?.vehiculo[0]?.seguro?.ciaSeguro?.nombre ?? '' }, { text: element?.propietario?.gestoria?.nombre ?? '' }, { text: element?.dia_libranza ?? '' }, { text: element?.emisora?.nombre ?? '' }, { text: element?.clave_emisora ?? '' }, { text: element?.estadoLicencia ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [38, 38, 40, 40, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38],
              heights: 20,
              body: this.datosLicencia,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 8, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }

  buildInformeRecaudacion(dataSource, mapaInforme) {
    dataSource.forEach(element => {
      
      this.datosRecaudacion.push([{ text: element?.conductor?.nombre ?? '' }, { text: element?.fechaAlta ?? '' },
      { text: element?.licencia?.dia_libranza ?? '' },
      { text: (element?.kilometraje?.km_entrada).toFixed(2) ?? '' },
      { text: (element?.kilometraje?.km_salida).toFixed(2) ?? '' },
      { text: (element?.kilometraje?.km_totales).toFixed(2) ?? '' },
      { text: (element?.taximetro_entrada).toFixed(2) ?? '' },
      { text: (element?.taximetro_salida).toFixed(2) ?? '' },
      { text: (element?.informe?.importe_bruto)?.toFixed(2) ?? '' },
      { text: (element?.informe?.importe_conductor)?.toFixed(2) ?? '' },
      { text: (element?.informe?.importe_propietario)?.toFixed(2) ?? '' },
      { text: (element?.informe?.totalPropietario)?.toFixed(2) ?? '' },
      { text: (element?.total_tarjeta)?.toFixed(2) ?? '' },
      { text: (element?.total_vales)?.toFixed(2) ?? '' },
      { text: (element?.operacion_conductor?.prestamo)?.toFixed(2) ?? '' },
      { text: (element?.operacion_conductor?.efectivo)?.toFixed(2) ?? '' },
      { text: (element?.observaciones) ?? '' },
    ])
    });

    
    
    this.datosRecaudacionTotales.push([{ text: (mapaInforme?.importe_bruto).toFixed(2) ?? '' },
    { text: (mapaInforme?.importe_conductor).toFixed(2) ?? '' },
    { text: (mapaInforme?.importe_propietario).toFixed(2) ?? '' },
    { text: (mapaInforme?.total_tarjeta).toFixed(2) ?? '' },
    { text: (mapaInforme?.total_vales).toFixed(2) ?? '' },
    { text: (mapaInforme?.efectivoTotal).toFixed(2) ?? '' },
    { text: (mapaInforme?.km_totales).toFixed(2) ?? '' },
    { text: (mapaInforme?.prestamos).toFixed(2) ?? '' }])

    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [38, 38, 20, 25, 25, 25, 38, 38, 38, 38, 38, 38, 15, 25, 15, 15, 38],
              heights: 20,
              body: this.datosRecaudacion,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 7.5, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 1, color: '#DBDBDB' }], alignment: 'center', margin: [30, 0, 30, 0] },

      {
        columns: [
          { width: '*', text: '', },
          {
            margin: [0, 50, 0, 0],
            width: 'auto',
            table: {
              widths: [90, 90, 90, 90, 90, 90, 90, 90],
              heights: 20,
              body: this.datosRecaudacionTotales,

              alignment: "center"

            }, fontSize: 8, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      }
    ];

    return body;
  }
  buildInformeGastosIngresos(dataSource) {
    dataSource.forEach(element => {
      this.datosGastosIngresos.push([{ text: element?.fechaAlta ?? '' }, { text: element?.tipoGestion ?? '' }, { text: element?.importe ?? '' }, { text: element?.concepto ?? '' }, element?.tipoGestion == 'GASTO' ? { text: element?.tipo_gasto?.nombre ?? '' } : { text: element?.tipo_ingreso?.nombre ?? '' }, { text: element?.numDocumento ?? '' }, { text: element?.licencia?.numero_licencia ?? '' }, { text: element?.conductor?.nombre ?? '' }, { text: element?.usuario?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [50, 50, 50, 50, 90, 50, 50, 50, 50],
              heights: 20,
              body: this.datosGastosIngresos,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },

      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeGastosProveedores(dataSource) {
    dataSource.forEach(element => {
      this.datosGastosProveedores.push([{ text: element?.fechaAlta ?? '' }, { text: element?.tipo ?? '' }, { text: element?.importe ?? '' }, { text: element?.proveedor?.nombre ?? '' }, { text: element?.proveedor?.cif ?? '' }, { text: element?.concepto?.concepto ?? '' }, { text: element?.concepto?.iva ?? '' }, { text: element?.numFactura ?? '' }, { text: element?.licencia?.numero_licencia ?? '' }, { text: element?.conductor?.nombre ?? '' }, { text: element?.usuario?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
              heights: 20,
              body: this.datosGastosProveedores,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeRetencionLiquidacion(dataSource) {
    dataSource.forEach(element => {
      this.datosRecaudacionLiquidacion.push([{ text: element?.fechaAlta ?? '' }, { text: element?.importe ?? '' }, { text: element?.tipo ?? '' }, { text: element?.concepto ?? '' }, { text: element?.numDocumento ?? '' }, { text: element?.licencia?.numero_licencia ?? '' }, { text: element?.conductor?.nombre ?? '' }, { text: element?.usuario?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [70, 70, 70, 70, 70, 70, 70, 70],
              heights: 20,
              body: this.datosRecaudacionLiquidacion,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformePrestamosDevoluciones(dataSource) {
    dataSource.forEach(element => {
      this.datosPrestamosDevoluciones.push([{ text: element?.fechaAlta ?? '' }, { text: element?.importe ?? '' }, { text: element?.tipo_liquidacion ?? '' }, { text: element?.concepto ?? '' }, { text: element?.numDocumento ?? '' }, { text: element?.licencia?.numero_licencia ?? '' }, { text: element?.conductor?.nombre ?? '' }, { text: element?.usuario?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [70, 70, 70, 70, 70, 70, 70, 70],
              heights: 20,
              body: this.datosPrestamosDevoluciones,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeGestoria(dataSource) {
    dataSource.forEach(element => {
      this.datosGestoria.push([{ text: element?.nombre ?? '' }, { text: element?.cif ?? '' }, { text: element?.email ?? '' }, { text: element?.telefono ?? '' }, { text: element?.domicilio ?? '' }, { text: element?.municipio ?? '' }, { text: element?.provincia?.nombre ?? '' }, { text: element?.codigoPostal ?? '' }, { text: element?.fechaAlta ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [70, 70, 70, 70, 70, 70, 70, 70, 70],
              heights: 20,
              body: this.datosGestoria,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeCompania(dataSource) {
    dataSource.forEach(element => {
      this.datosCompania.push([{ text: element?.nombre ?? '' }, { text: element?.cif ?? '' }, { text: element?.email ?? '' }, { text: element?.telefono ?? '' }, { text: element?.domicilio ?? '' }, { text: element?.municipio ?? '' }, { text: element?.provincia?.nombre ?? '' }, { text: element?.codigoPostal ?? '' }, { text: element?.fechaAlta ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [70, 70, 70, 70, 70, 70, 70, 70, 70],
              heights: 20,
              body: this.datosCompania,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeEmisora(dataSource) {
    dataSource.forEach(element => {
      this.datosEmisora.push([{ text: element?.nombre ?? '' }, { text: element?.cif ?? '' }, { text: element?.email ?? '' }, { text: element?.telefono ?? '' }, { text: element?.domicilio ?? '' }, { text: element?.municipio ?? '' }, { text: element?.provincia?.nombre ?? '' }, { text: element?.codigoPostal ?? '' }, { text: element?.fechaAlta ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [70, 70, 70, 70, 70, 70, 70, 70, 70],
              heights: 20,
              body: this.datosEmisora,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeMarcaModelo(dataSource, inicio, final) {
    dataSource.slice(inicio, final).forEach(element => {
      this.datosMarcaModelo.push([{ text: element?.marca?.nombre ?? '' }, { text: element?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [100, 100],
              heights: 20,
              body: this.datosMarcaModelo,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeProveedores(dataSource) {
    dataSource.slice(0, 10).forEach(element => {
      this.datosProveedores.push([{ text: element?.proveedor?.nombre ?? '' }, { text: element?.proveedor?.cif ?? '' }, { text: element?.concepto ?? '' }, { text: element?.iva ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [100, 100, 100, 100],
              heights: 20,
              body: this.datosProveedores,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }
  buildInformeIngresos(dataSource) {
    dataSource.slice(0, 10).forEach(element => {
      this.datosIngresos.push([{ text: element?.fechaAlta ?? '' }, { text: element?.importe ?? '' }, { text: element?.concepto ?? '' }, { text: element?.usuario?.nombre ?? '' }])
    });
    let body = [
      { text: 'Datos', bold: true, fontSize: 13, margin: [0, 0, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [100, 100, 100, 100],
              heights: 20,
              body: this.datosIngresos,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 9, dontBreakRows: false,

          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },
    ];

    return body;
  }



  fechaAux;
  diferenciaDias;
  importesConductor;
  numRecaudacionesConductor;
  async cargarDatosPdf(conductor) {

    this.searchMap.idConductor = conductor.id;
    this.searchMap.idLicencia = this.licenciaPrueba.id;
    this.searchMap.fechaInicio = this.form.value.desde, true;
    this.searchMap.fechaFinal = this.form.value.hasta, true;

    this.fechaAux = new Date(this.searchMap.fechaFinal).getTime() - new Date(this.searchMap.fechaInicio).getTime();
    this.diferenciaDias = this.fechaAux / (1000 * 60 * 60 * 24)
    await firstValueFrom(this._recaudacionService.searchRecaudaciones(this.searchMap)).then((res) => {
      this.numRecaudacionesConductor = res['recaudaciones'];
    });

    await firstValueFrom(this._recaudacionService.searchRecaudacionesConductor(this.searchMap)).then((res) => {
      this.importesConductor = res['recaudaciones'];
    });

    await firstValueFrom(this._gastosIngresosService.searchGastosIngresos(this.searchMap)).then((res) => {
      let sumatorioAux = 0;
      if (res['GastosIngresos'] != null) {
        var num = 0;
        res['GastosIngresos'].forEach(element => {
          if (element.tipo_gasto != null) {
            if (element.tipo_gasto.nombre == "PAGO PROVEEDORES") {
              sumatorioAux = sumatorioAux + element.importe;
            }
          }

          if (num % 2 == 0) {
            this.gastosIngresosArray.push([{ text: element.numDocumento ?? '' }, element.tipoGestion == 'GASTO' ? { text: element.tipo_gasto.nombre ?? '' } : { text: element.tipo_ingreso.nombre ?? '' }, { text: element.concepto ?? '' }, { text: (element.importe ?? '') + ' €' }])
          } else {
            this.gastosIngresosArray.push([{ text: element.numDocumento ?? '', fillColor: '#dedede' }, element.tipoGestion == 'GASTO' ? { text: element.tipo_gasto.nombre ?? '', fillColor: '#dedede' } : { text: element.tipo_ingreso.nombre ?? '', fillColor: '#dedede' }, { text: element.concepto ?? '', fillColor: '#dedede' }, { text: (element.importe ?? '') + ' €', fillColor: '#dedede' }])

          }
          num++;
        });
      }
      this.respuestaGastosIngresos = res['total']['importe'];
      let test1 = [{}, {}, { text: 'Total: ', bold: true, alignment: 'right', margin: [0, 5, 0, 0] }, { text: (res['total']['importe'] ?? 0) + ' €', margin: [0, 5, 0, 0] }];
      this.gastosIngresosArray.push(test1);
    });
  }


  crearPDF(dataSource, nombre, mapaInforme?, inicio?, final?) {
    //        this.cargarDatosPdf(conductorToBuild);
    var content = []
    var header = []
    if (nombre == 'Conductor') {
      content.push(this.buildInformeConductor(dataSource))
    }
    if (nombre == 'Propietario') {
      content.push(this.buildInformePropietario(dataSource))

    }
    if (nombre == 'Vehiculo') {
      content.push(this.buildInformeVehiculo(dataSource))

    }
    if (nombre == 'Licencia') {
      content.push(this.buildInformeLicencia(dataSource))

    }
    if (nombre == 'Recaudacion') {
      content.push(this.buildInformeRecaudacion(dataSource, mapaInforme))

    }
    if (nombre == 'Gastos/Ingresos') {
      content.push(this.buildInformeGastosIngresos(dataSource))

    }
    if (nombre == 'GastosProveedores') {
      content.push(this.buildInformeGastosProveedores(dataSource))

    }
    if (nombre == 'Retencion/Liquidacion') {
      content.push(this.buildInformeRetencionLiquidacion(dataSource))

    }
    if (nombre == 'Prestamos/Devoluciones') {
      content.push(this.buildInformeRetencionLiquidacion(dataSource))

    }
    if (nombre == 'Gestorias') {
      content.push(this.buildInformeGestoria(dataSource))

    }
    if (nombre == 'Compania') {
      content.push(this.buildInformeCompania(dataSource))

    }
    if (nombre == 'Emisora') {
      content.push(this.buildInformeEmisora(dataSource))

    }
    if (nombre == 'MarcaModelo') {
      content.push(this.buildInformeMarcaModelo(dataSource, inicio, final))

    }
    if (nombre == 'Proveedores') {
      content.push(this.buildInformeProveedores(dataSource))

    }
    if (nombre == 'Ingresos') {
      content.push(this.buildInformeIngresos(dataSource))
    }
    let dateDay = new Date();
    let date = this.datepipe.transform(dateDay, 'dd-MM-yyyy');
    header = [
      {
        columns: [
          {
            table: {
              heights: [25, 10, 10],
              body: [

                [{ text: date, colSpan: 2, bold: true }, '']

              ],


            }, margin: [40, 0, 0, 0], layout: 'noBorders', fontSize: 11

          },
          {
            table: {
              heights: [25, 10, 10],
              body: [
                [{ image: `data:image/png;base64,${image}`, width: 200 },
                ],
              ],


            }, margin: [65, 0, 0, 0], layout: 'noBorders', fontSize: 11
          },
        ], margin: [0, 30, 0, 0],
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 0, 0, 0] },

    ]


    //content.push(this.buildTituloFecha);

    var saldoLicencia = [
      {
        columns: [
          { text: 'Saldo: ', bold: true },
          { text: -35.4 + ' €' }
        ], alignment: 'center', margin: [0, 20, 0, 0], columnGap: -435
      }
    ]

    //content.push(this.buildConductor(this.importesConductor, this.diferenciaDias, conductorToBuild), this.buildDetalleGastos(), this.buildPago(this.importesConductor, conductorToBuild, this.importesConductor));

    var docDefinition = {
      pageOrientation: 'landscape',
      info: {
        title: 'Informe_General_', nombre,
        author: 'Novotaxi',
      },
      header: header,
      content: [
        { text: 'Informe ' + nombre, alignment: 'center', lineHeight: 1.3, margin: [0, 0, 0, 0], bold: true, fontSize: 12 },
        content
      ],
      /*pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return (currentNode.id === 1 || currentNode.startPosition.top >700)},*/
      footer: function (currentPage, pageCount) {
        return (
          {
            columns: [
              { text: ' Novotaxi Madrid, S.L', alignment: 'left', fontSize: 9, margin: [40, 0, 0, 0] },
              { text: `Página ${currentPage} de ${pageCount}`, alignment: 'left', fontSize: 9 }
            ], columnGap: -40
          }
        );
      },
      pageMargins: [40, 120, 40, 20],
    };

    pdfMake.createPdf(docDefinition).open();
    this.resetearArrayCarga();

  }
  resetearArrayCarga() {
    this.datosConductor = [
      ['DNI', 'Nombre', 'Apellidos', 'Fecha Alta', 'Fecha Vencimiento BTP', 'Domicilio', 'Poblacion',
        'Provincia nombre', 'Codigo Postal', 'Mail', 'Telefono', 'Cuenta Bancaria', 'fijo/dia', 'Retencion', 'Porcentaje',
        'lp', 'Rl', 'Fecha Baja']
    ];
    this.datosPropietario = [
      ['DNI', 'Nombre', 'Apellidos', 'Domicilio', 'Provincia', 'Poblacion', 'Codigo postal',
        'Emails', 'Telefonos', 'Gestoria', 'FechaAlta', 'Licencia']
    ]
    this.datosVehiculo = [
      ['Matricula', 'Marca', 'Modelo',
        'Seguro', 'Padronal',
        'Casa Taximetro', 'Clase Taximetro', 'Vencimiento Taximetro', 'Ultima TarifaAplicada',
        'km Entrada', 'km Ultimo Cambio Aceite', 'km Proximo Cambio Aceite',
        'Tipo Aceite', 'Vencimiento ITV', 'Utima Revision',
        'Fecha Baja', 'tipo Vehiculo']
    ]
    this.datosLicencia = [
      ['Nº licencia', 'Fecha Alta', "Nombre Propietario", "Apellido Propietario", "Nombre Conductor",
        "Apellidos conductor", "Matricula", "Marca", "Modelo", "CIA. Seguro", "Gestoria", 'Día libranza', 'Emisora',
        'Clave emisora', 'Estado']
    ]
    this.datosRecaudacion = [
      ['Nombre conductor', 'Fech Alta', 'Festivo', 'km entrada', 'km salida', 'km totales', 'Taximetro entrada',
        'Taximetro salida', 'Importe bruto', 'Importe conductor', 'Importe propietario', 'Total propietario', 'visa',
        'Total vales', 'Banco', 'Efectivo', 'Observaciones']
    ]
    this.datosRecaudacionTotales = [
      ['Importe bruto', 'Importe conductor', 'Importe propietario', 'Total tarjetas', 'Total vales', 'Efectivo', 'Kilometros totales', 'Banco']
    ]
    this.datosGastosIngresos = [
      ['Fecha Alta', 'Tipo Gestion', 'Importe', 'Concepto', 'Tipo', 'Nº Factura', 'Nº Licencia', 'Nombre Conductor', 'Nombre usuario']
    ]
    this.datosGastosProveedores = [
      ['Fecha Alta', 'Tipo', 'Importe', 'Nombre', 'CIF', 'Concepto',
        'IVA', 'Nº Factura', 'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario',]
    ]

    this.datosRecaudacionLiquidacion = [
      ['Fecha Alta', 'Importe', 'Tipo', 'Concepto', 'Nº Factura',
        'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario']
    ]
    this.datosPrestamosDevoluciones = [
      ['Fecha Alta', 'Importe', 'Tipo', 'Concepto', 'Nº Factura',
        'Nº Licencia', 'Nombre Conductor', 'Nombre Usuario']
    ]
    this.datosGestoria = [
      ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
    ]
    this.datosEmisora = [
      ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
    ]
    this.datosCompania = [
      ['Nombre', 'CIF', 'Email', 'Telefono', 'Domicilio', 'Municipio', 'Provincia', 'Codigo Postal', 'Fecha Alta']
    ]
    this.datosMarcaModelo = [
      ['Marca', 'Modelo']
    ]
    this.datosProveedores = [
      ['Nombre', 'CIF', 'Concepto', 'IVA']
    ]
    this.datosIngresos = [
      ['Fecha Registro', 'Importe', 'Concepto', 'Usuario']
    ]
  }
  // Convrsión de fechas para poder mostrar en el front y enviar al back
  convertDate(date: string, modo: Boolean): string {

    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '/' + mes + '/' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }
}
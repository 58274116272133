import { PropietarioService } from './../../../../_services/novotaxi/propietario.service';
import { Component, Input, OnInit } from '@angular/core';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';

@Component({
  selector: 'app-card-detalles',
  templateUrl: './card-detalles.component.html',
  styleUrls: ['./card-detalles.component.css']
})
export class CardDetallesComponent implements OnInit{

  constructor(
  ){}

  ngOnInit(): void {
    this.fillMap();
  }

  @Input()
  detallesVisible: Boolean;
  @Input()
  date: String;
  @Input()
  datosDetalles: Map<String, Map<String,String>> = new Map()
  @Input() tipoDetalle: string;

  infoLicencias: Map<String,String>;
  infoPropietarios: Map<String,String>;
  infoConductores: Map<String,String>;
  infoVehiculos: Map<String,String>;

  fillMap(){
    this.infoLicencias = this.datosDetalles.get('infoLicencias');
    this.infoPropietarios = this.datosDetalles.get('infoPropietarios');
    this.infoConductores = this.datosDetalles.get('infoConductores');
    this.infoVehiculos = this.datosDetalles.get('infoVehiculos');
  }
  

}

<div class="crear-editar-form">
    <h1 class="titulo-formulario">Cambiar un día de libranza</h1>
    <div class="mw-100 mt-2 ">

        <div class="row">
            <div class="col">
                <div *ngFor="let diaLibranza of diasLibranzaList; let i = index">
                    <div class="row">

                        <!-- ------------------------------- Campo libranza actual ----------------------- -->
                        <div class="col-sm-3">
                            <label>Fecha de libranza actual<span style="color: red;">*</span></label>
                            <input _ngcontent-vka-c280=""
                                class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date"
                                name="fecha_libranza_actual{{i}}" [(ngModel)]="diaLibranza.fecha_libranza_actual">
                        </div>

                        <!-- ------------------------------- Campo libranza nueva ----------------------- -->
                        <div class="col-sm-3">
                            <label>Fecha de libranza nueva<span style="color: red;">*</span></label>
                            <input _ngcontent-vka-c280=""
                                class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date"
                                name="fecha_libranza_cambio{{i}}" [(ngModel)]="diaLibranza.fecha_libranza_cambio">
                        </div>

                        <!-- ------------------------------- Campo licencia ----------------------- -->
                        <div class="form-group col-sm-3">
                            <label>Licencia<span style="color: red;">*</span></label>
                            <select class="form-control form-select" [(ngModel)]="diaLibranza.licencia" #name="ngModel"
                                name="licencia{{i}}" (change)="getConductoresByLicencia(diaLibranza)">
                                <option [ngValue]=null>Selecciona una licencia</option>
                                <option *ngFor="let licencia of licencias" [ngValue]="licencia">
                                    {{licencia.numero_licencia}}
                                </option>
                            </select>
                        </div>


                        <!-- ------------------------------- Campo conductor ----------------------- -->

                        <!--  -->
                        <div class="form-group col-sm-3">
                            <label>Conductor<span style="color: red;">*</span></label>
                            <select class="form-control form-select" [(ngModel)]="diaLibranza.conductor" #name="ngModel"
                                name="conductor{{i}}">
                                <option [ngValue]=null>Selecciona un conductor</option>
                                <option *ngFor="let conductor of diaLibranza.posiblesConductores" [ngValue]="conductor">
                                    {{conductor.nombre}} {{conductor.apellidos}}
                                </option>
                            </select>
                        </div>
                        <!--  -->
                    </div>

                    <div class="row justify-content-start">
                        <div class="form-group col-sm-auto mb-4">
                            <button type="button" [disabled]="i==0 "
                                class="btn btn-outline-primary btn-sm d-flex flex-row align-items-center"
                                (click)="removePrestamo(i)"><mat-icon>delete</mat-icon>Eliminar</button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                <div class="row justify-content-start">
                    <div class="form-group col-sm-auto">
                        <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"
                            (click)="addPrestamo()"><mat-icon>add</mat-icon>Añadir
                            día de libranza</button>
                    </div>
                </div>

                <!-- ------------------------------- Botones ----------------------- -->
                <div id="container" class="d-flex justify-content-end pt-3">
                    <button class="btn btn-success" type="submit"
                        (click)="openDialogConfirm()">Guardar</button>
                    <button class="btn btn-secondary ml-3" type="button"
                        (click)="confirmCancelOperation()">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
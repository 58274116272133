import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IConductor } from "src/app/conductores/conductor.model";
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { Licencia } from "src/app/model/Emisora";
import { Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { IRetencionLiquidacion } from "src/app/model/novotaxi/retenciones-liquidaciones/liquidacion-retencion.model";
import { TipoGestion } from "src/app/model/novotaxi/retenciones-liquidaciones/tipo-gestion.liquidacion.model";
import { ModalConfirmacionComponent } from "src/app/novotaxi/modal-confirmacion/modal-confirmacion.component";
import { NotificationService } from "src/app/_services/notification.service";
import { LiquidacionService } from "src/app/_services/novotaxi/retenciones-liquidaciones/retenciones-liquidaciones.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { DataRetencionesLiquidaciones } from "../liquidaciones-retenciones.component";

@Component({
    selector: 'app-create-update-liquidacion',
    templateUrl: './create-update-retenciones-liquidaciones.component.html',
    styleUrls: ['./create-update-retenciones-liquidaciones.component.css']
})

export class CreateUpdateLiquidacionRetencion implements OnInit {

    @Input() data: DataRetencionesLiquidaciones;
    @Output() mostrarFormulario = new EventEmitter<DataRetencionesLiquidaciones>();

    liquuidacionList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, posiblesLicencias: null }];

    // -- Variables para el control del usuario
    currentUser;
    userSaved: Usuario = null;
    isUserAdmin: boolean = true;

    // -- Variables para el guardado de la retención/liquidación
    liquidacionRetencionSaved: IRetencionLiquidacion;
    fechaAlta: Date;
    selectedValueLicencia: number = null;
    selectedValueConductor: number = null;

    // -- Variables listas
    licencias: Licencia[] = [];
    liquidacionesRetencionesList: IRetencionLiquidacion[] = [];
    conductores: IConductor[] = [];

    constructor(
        private _conductorService: ConductorService,
        private _liquidacionService: LiquidacionService,
        private _notificationService: NotificationService,
        private _servicioGeneral: ServicioGeneral,
        private _tokenStorageService: TokenStorageService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {

        if (!this.data.conductores) {
            this._notificationService.info("INFO", `No existen conductores disponibles para crear una ${this.data.tipo.toLowerCase()}`, 3000)
        }

        this.conductores = this.data.conductores;

        /*  if (this.data.conductor != null) {
            this.getLicenciasByConductor(this.data.conductor.id);
             this.liquuidacionList = [{ fechaAlta: null, licencia: null, conductor: this.data.conductor.nombre + ' ' + this.data.conductor.apellidos, importe: null, concepto: null, numDocumento: null, posiblesLicencias: this.licencias }];
         } else {
             this.liquuidacionList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, posiblesLicencias: this.licencias }];
         } */

        if (this.data.isForUpdate) {
            if (this.data.tipo == 'LIQUIDACION') {
                this.data.tipo = 'liquidación'
            } else {
                this.data.tipo = 'retención'
            }
        }

        this.currentUser = this._tokenStorageService.getUser();
        this.getUser();

        if (this.data.isForUpdate) {
            this.getDataForUpdate();
        }
    }

    getUser() {
        this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
            this.userSaved = data['Usuario'];
        })
    }

    getLicenciasByConductor(idConductor?, liquidacion?) {

        if (liquidacion) {
            liquidacion.licencia = null;
        }

        this.selectedValueLicencia = null;
        if (idConductor != null) {
            this._conductorService.getLicenciasByConductor(idConductor).subscribe((response) => {
                liquidacion.posiblesLicencias = response['licencias']
                this.licencias = response['licencias'];
            });
        }
    }

    getDataForUpdate() {
        this.liquidacionRetencionSaved = JSON.parse(JSON.stringify(this.data.elementUpdate));

        this.liquuidacionList = [
            {
                fechaAlta: this.data.elementUpdate.fechaAlta,
                licencia: this.data.elementUpdate?.licencia,
                conductor: this.data.elementUpdate?.conductor,
                importe: this.data.elementUpdate.importe,
                concepto: this.data.elementUpdate.concepto,
                numDocumento: this.data.elementUpdate.numDocumento,
                posiblesLicencias: this.data.licencias
            }
        ]
        this.fechaAlta = new Date(this.data.elementUpdate.fechaAlta.toString().split("-").reverse().join("-"))
        this.selectedValueLicencia = this.data.elementUpdate.licencia?.id;
        this.selectedValueConductor = this.data.elementUpdate.conductor?.id;


        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

        if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && this.data.elementUpdate.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar una retención/liquidación del día actual", 3000)
            this.isUserAdmin = false;
        } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && this.data.elementUpdate.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar una retención/liquidación del día actual", 3000)
            this.isUserAdmin = false;
        } else {
            this.isUserAdmin = true;
        }
    }

    addLiquidacion() {

        if (this.data.conductor != null) {
            this.liquuidacionList.push({
                fechaAlta: null, licencia: null, conductor: this.data.conductor, importe: null, concepto: null, numDocumento: null, posiblesLicencias: null
            });
        } else {
            this.liquuidacionList.push({
                fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, posiblesLicencias: null
            });
        }
    }

    removeLiquidacion(i) {
        this.liquuidacionList.splice(i, 1);
    }

    saveOrUpdate() {
        if (this.data.isForUpdate) {
            this.updateLiquidacionRetencion();
        } else {
            this.saveLiquidacionRetencion();
        }
    }

    async openDialogConfirm() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = "guardar";

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.saveOrUpdate();
            } else {
                null
            }
        })
    }

    async confirmCancelOperation() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = 'descartar';

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.resetearForm(true)
            } else {
                null
            }
        })
    }

    // -- Método para guardar la retención/liquidación
    saveLiquidacionRetencion() {
        let isValidFields = true;

        this.liquuidacionList.forEach(element => {
            this.liquidacionRetencionSaved = JSON.parse(JSON.stringify(element))

            if (this.liquidacionRetencionSaved.conductor) {
                this.liquidacionRetencionSaved.conductor.fechaAlta = this.convertDate(this.liquidacionRetencionSaved?.conductor?.fechaAlta);
                if (this.liquidacionRetencionSaved?.conductor?.fechaVencimientoBTP) {
                    this.liquidacionRetencionSaved.conductor.fechaVencimientoBTP = this.convertDate(this.liquidacionRetencionSaved?.conductor?.fechaVencimientoBTP);
                }
            }

            this.liquidacionRetencionSaved.fechaAlta = this.datePipe.transform(this.liquidacionRetencionSaved.fechaAlta, 'dd-MM-yyyy')

            if (this.data.tipo == 'liquidación') {
                this.liquidacionRetencionSaved.gestion = TipoGestion.LIQUIDACION;
            } else {
                this.liquidacionRetencionSaved.gestion = TipoGestion.RETENCION;
            }

            if (this.liquidacionRetencionSaved.numDocumento == '') {
                this.liquidacionRetencionSaved.numDocumento = null
            }

            // this.liquidacionRetencionSaved.conductor = new IConductor(this.data.conductor);

            if (this.liquidacionRetencionSaved.importe != null && (this.selectedValueLicencia != null || this.liquidacionRetencionSaved.licencia != null)) {
                delete element['posiblesLicencias'];
                this.liquidacionRetencionSaved.usuario = this.userSaved;
                let newLiquidacionRetencion: IRetencionLiquidacion = new IRetencionLiquidacion(this.liquidacionRetencionSaved);
                newLiquidacionRetencion.licencia.vehiculo = null;

                this.liquidacionesRetencionesList.push(newLiquidacionRetencion);
            } else {
                isValidFields = false
            }
        });

        if (!isValidFields) {
            this._notificationService.info('INFO', 'No se pueden crear liquidaciones/retenciones sin importe ni licencia', 5000)
        }

        if (this.liquidacionesRetencionesList.length > 0 && isValidFields) {
            this._liquidacionService.saveAllLiquidacionesRetenciones(this.liquidacionesRetencionesList).subscribe((data) => {

                if (data['message']['status'] == 400) {
                    this._notificationService.error("ERROR", data['message']['message'], 3000)
                } else {
                    this.data.retencionesLiquidaciones = data['liquidaciones'];
                    this._notificationService.success("Hecho", this.data.tipo + ' creado correctamente', 3000)
                    this.resetearForm(false)
                }
                this.liquidacionesRetencionesList = [];
            })
        }
    }

    // -- Método para actualizar la retención/liquidación
    updateLiquidacionRetencion() {
        this.liquidacionRetencionSaved = JSON.parse(JSON.stringify(this.data.elementUpdate));
        let dataUpdate = JSON.parse(JSON.stringify(this.liquuidacionList[0]));

        this.liquidacionRetencionSaved.importe = dataUpdate.importe;
        this.liquidacionRetencionSaved.concepto = dataUpdate.concepto;
        this.liquidacionRetencionSaved.numDocumento = dataUpdate.numDocumento;
        this.liquidacionRetencionSaved.licencia = dataUpdate.posiblesLicencias.find(licencia => licencia.id == this.selectedValueLicencia);
        this.liquidacionRetencionSaved.conductor = this.conductores.find(conductor => conductor.id == this.selectedValueConductor);
        this.liquidacionRetencionSaved.fechaAlta = this.datePipe.transform(this.fechaAlta, 'dd-MM-yyyy');

        if (this.liquidacionRetencionSaved.conductor) {
            this.liquidacionRetencionSaved.conductor.fechaAlta = this.convertDate(this.liquidacionRetencionSaved?.conductor?.fechaAlta);
            if (this.liquidacionRetencionSaved?.conductor?.fechaVencimientoBTP) {
                this.liquidacionRetencionSaved.conductor.fechaVencimientoBTP = this.convertDate(this.liquidacionRetencionSaved?.conductor?.fechaVencimientoBTP);
            }
        }

        if (!this.liquidacionRetencionSaved.licencia) {
            this._notificationService.info('INFO', 'No se puede crear una liquidación sin importe ni licencia', 5000)
        } else {
            delete this.liquidacionRetencionSaved['posiblesLicencias'];
            this.liquidacionRetencionSaved.licencia.vehiculo = null;

            this._liquidacionService.updateRetencionLiquidacion(new IRetencionLiquidacion(this.liquidacionRetencionSaved)).subscribe((data) => {

                if (data['message']['status'] == 400) {
                    this._notificationService.error("ERROR", data['message']['message'], 3000)
                } else {
                    this.data.retencionesLiquidaciones.push(data['liquidacion']);
                    this._notificationService.success("Hecho", this.data.tipo.toLowerCase() + ' modificada correctamente', 3000);
                    this.resetearForm(false);
                }
                this.liquidacionesRetencionesList = [];
            })
        }
    }



    getValue(event) {
        this.fechaAlta = event.target.value;
    }

    resetearForm(isCancel: Boolean) {

        if (this.data.isForUpdate && isCancel) {
            this.data.retencionesLiquidaciones.push(this.data.elementUpdate);
        }

        this.data.mostrarFormulario = false;
        this.mostrarFormulario.emit(this.data);
    }

    convertDate(fecha) {
        const splitDate = fecha.split('-');
        const date = new Date(
            Number(splitDate[2]),   // year
            Number(splitDate[1]) - 1, // month
            Number(splitDate[0])    // day
        );
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
}
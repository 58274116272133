import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/_services/notification.service';
import { ControlInformes } from 'src/app/model/novotaxi/control_informes/control_informes.model';
import { DatePipe } from "@angular/common";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';
import { ControlInformesService } from 'src/app/_services/control_informes/control_informes.service';
import { MatSort, Sort } from "@angular/material/sort";

@Component({
  selector: 'app-control-informes',
  templateUrl: './control-informes.component.html',
  styleUrls: ['./control-informes.component.css']
})
export class ControlInformesComponent {

  displayedColumns: string[] = ['fechaCreacion', 'fechaDesde', 'fechaHasta', "tipoInforme", 'conductor.nombre', 'conductor.apellidos', 'documento.nombre_documento', 'licencia.numero_licencia', 'propietario.nombre', 'propietario.apellidos', 'usuario.nombre', 'acciones'];

  displayedSearchHeaders: string[] = ['fechaCreacion_buscador', 'fechaDesde_buscador', 'fechaHasta_buscador', 'tipoInforme_buscador', 'conductor_nombre_buscador', 'conductor_apellido_buscador', 'documento_buscador', 'licencia_buscador', 'propietario_nombre_buscador', 'propietario_apellido_buscador', 'usuario_buscador'];

  controlInformesList: ControlInformes[] = [];
  currentDate: Date = new Date();
  fechainicio: string;
  fechafin: string;
  isSearch: boolean = false;
  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
  };

  listColumns;
  dataSource = new MatTableDataSource<ControlInformes>();

  // -- Variables optimización
  public filters = [];
  public staticFilters;
  public sorts = [];
  public totalElements = 0;

  // -- Paginado
  length = this.controlInformesList.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  // -- Filtrado por columnas
  public searchForm: FormGroup;
  public fecha = '';
  public tipoGestion = '';
  public importe = '';
  public concepto = '';
  public tipo = '';
  public numDocumento = '';
  public licencia = '';
  public conductor = '';
  public usuario = '';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _notificationService: NotificationService,
    private _controlInformesService: ControlInformesService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.pageIndex = 0;
    this.pageSize = 10;
    this.listColumns = {
      'Fecha de creación': true,
      'Fecha desde': true,
      'Fecha hasta': true,
      'Tipo informe': true,
      'Nombre conductor': true,
      'Apellidos conductor': true,
      'Documento': true,
      'Licencia': true,
      'Nombre propietario': true,
      'Apellidos propietario': true,
      'Usuario': true,
    }

    this.fechainicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
    this.fechafin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.searchMap.fechaInicio = this.fechainicio;
    this.searchMap.fechaFinal = this.fechafin;

    this.staticFilters = {
      key: 'fechaCreacion',
      operator: "BETWEEN_DATES",
      field_type: 'DATE_BETWEEN',
      value: this.fechainicio.toString().split("-").reverse().join("-"),
      value_to: this.fechafin.toString().split("-").reverse().join("-"),
    };

    this.setRequestForSearch();
    this.searchFormInit();
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  // -- Sort (Creo que se puede eliminar)
  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  isValidFieldsSearch() {
    if (this.fechainicio == undefined || this.fechafin == undefined) {
      this._notificationService.warning("¡Atención!", "Debe rellenar las fechas para la búsqueda", 3000);
      return false;
    } else {
      if (this.fechainicio > this.fechafin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }

  // -- Hacer contorl para mostrar mensaje
  deleteInforme(element) {
    this._controlInformesService.delete(element.id).subscribe((result) => {
      if (result['status'] == '622') {
        this._notificationService.success('Hecho', 'Informe eliminado con éxito', 3000);
        this.setRequestForSearch();
      }
    });
  }

  async openDialogDelete(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'borrar'

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {

        if (element.id != null) {
          let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
          this.deleteInforme(element);
        } else {
          null
        }
      }
    })
  }

  // -- Inicio optimización
  searchFormInit() {
    this.searchForm = new FormGroup({
      fechaCreacion: new FormControl(''),
      fechaDesde: new FormControl(''),
      fechaHasta: new FormControl(''),
      tipoInforme: new FormControl(''),
      conductor: new FormControl(''),
      documento: new FormControl(''),
      licencia: new FormControl(''),
      propietario: new FormControl(''),
      usuario: new FormControl(''),
    });
  }

  setRequestForSearch(request?) {
    let requestSearchFields;

    this.searchMap = {
      fechaInicio: this.fechainicio,
      fechaFinal: this.fechafin,
    };

    if (this.isValidFieldsSearch) {
      requestSearchFields = {
        "filters": [{
          key: 'fechaCreacion',
          operator: "BETWEEN_DATES",
          field_type: 'DATE_BETWEEN',
          value: this.fechainicio.toString().split("-").reverse().join("-"),
          value_to: this.fechafin.toString().split("-").reverse().join("-"),
        },],
        "sorts": this.sorts,
        page: this.pageIndex,
        size: this.pageSize,
      }
    }

    this.searchInformes(requestSearchFields, request);
  }

  async searchInformes(requestSearchFields?, request?) {
    let filtersToSend = [];

    if (request) {
      if (requestSearchFields) {
        filtersToSend = [...request['filters'], ...requestSearchFields['filters']];

      } else {
        filtersToSend = [...request['filters']];
      }

    } else {
      filtersToSend = [...requestSearchFields['filters']];
    }

    let requestToSend = {
      "filters": filtersToSend,
      "sorts": this.sorts,
      page: this.pageIndex,
      size: this.pageSize,
    }

    await this._controlInformesService.getControlInformes(requestToSend).subscribe((data) => {
      this.controlInformesList = [];
      this.totalElements = 0;

      if (data['controlInformes']) {        

        if (data['controlInformes']['content']) {
          this.isSearch = true;
          for (const gastosIngresosJSON of data['controlInformes']['content']) {
            this.controlInformesList.push(new ControlInformes(gastosIngresosJSON));
          }
        } else {
          this._notificationService.info("INFO", "No se han encontrado datos", 3000);
          this.isSearch = false;
        }
        this.totalElements = data['controlInformes']['totalElements'];
      }
      this.dataSource = new MatTableDataSource(this.controlInformesList);
    });
  }

  async applyFilter(event?, key?, type?) {
    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];

    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      let value = event?.target?.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {

    if (!this.filters.find(x => x.key == key)) {

      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type?.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN";
      }

      if (type?.toUpperCase() == "IN") {
        filter.operator = "IN";
        filter.field_type = "STRING";
      }

      if (type?.toUpperCase() == "DATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX";
      }

      if (type?.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS";
        filter.field_type = "STRING";
      }

      if (type?.toUpperCase() == "DOUBLE" || type?.toUpperCase() == "LONG" || type?.toUpperCase() == "BIG_DECIMAL" || type?.toUpperCase() == "INTEGER") {
        filter.operator = "LIKE";
        filter.field_type = "STRING";
      }

      if (type.toUpperCase() == 'ENUM_TIPO_INFORME') {
        filter.operator = "LIKE_TIPO_INFORME";
        filter.field_type = "STRING";
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,
    }

    this.paginator.firstPage();
    await this.setRequestForSearch(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setRequestForSearch(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }

      } else {
        if (sort.active == "tipo") {
          this.sorts = [{
            "key": 'tipo_gasto.nombre',
            "direction": direction
          },
          {
            "key": 'tipo_ingreso.nombre',
            "direction": direction
          }];

        } else {
          this.sorts = [{
            "key": sort.active,
            "direction": direction
          }];
        }
      }
      request['sorts'] = this.sorts;
    }

    await this.setRequestForSearch(request)
  }

  clearSearchInputs() {
    this.searchForm.reset();
    this.filters = [];
  }

  // -- Fin optimización

  getDocument(element) {
    this._controlInformesService.getDocument(element.id).subscribe({
      next: (data) => {
        this.downloadDocument(data['documento'])
      }
    })
  }

  downloadDocument(documento) {
    const base64Data = documento.base64;
    const mime = documento.mime;
    const nombre = documento.nombre;

    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mime });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = nombre; // Establece el nombre del archivo

    document.body.appendChild(a);
    a.click();

    // Limpia recursos
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}


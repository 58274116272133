import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Licencia } from 'src/app/model/Emisora';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-historico-licencia',
  templateUrl: './historico-licencia.component.html',
  styleUrls: ['./historico-licencia.component.css']
})
export class HistoricoLicenciaComponent {

  @Input() licenciaHistorico;
  @Input() licenciaHistoricoPropietario;
  @Output() mostrarHistorico = new EventEmitter<Boolean>();

  constructor(
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
        
  }

  setOcultarHistorico() {
    this.mostrarHistorico.emit(false);
  }

  formatDate(date) {
    if (date != null) {
      if (typeof date == 'number') {
        date = this.datePipe.transform(date, 'yyyy-MM-dd')
      } else {
        const splitDate = date.split('-');

        const dateFormatted = new Date(
          Number(splitDate[2]),   // year
          Number(splitDate[1]) - 1, // month
          Number(splitDate[0])    // day
        );
        date = this.datePipe.transform(dateFormatted, 'yyyy-MM-dd')
      }
    }

    return date;
  }

}

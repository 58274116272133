import { IConductor } from '../../../conductores/conductor.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Licencia } from 'src/app/model/Emisora';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { Component, OnInit } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { firstValueFrom } from 'rxjs';
import { GastosIngresosService } from 'src/app/_services/novotaxi/gastos-ingresos.service';
import { image } from '../../img/image.const';
import { NotificationService } from 'src/app/_services/notification.service';
import { ControlInformes } from 'src/app/model/novotaxi/control_informes/control_informes.model';
import { ControlInformesService } from 'src/app/_services/control_informes/control_informes.service';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TipoInforme } from 'src/app/model/novotaxi/control_informes/tipo_informe.model';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import { InformesService } from 'src/app/_services/control_informes/informes-service.service';
import { GastosProveedoresService } from 'src/app/_services/novotaxi/gastos/gastos-proveedores.service';

@Component({
  selector: 'app-informe-propietarioPDF',
  templateUrl: './informe-propietarioPDF.component.html'
})

export class InformePropietarioPDFComponent implements OnInit {

  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
    idLicencia: null,
    idConductor: null,
  };

  // controlInforme: ControlInformes = {};

  respuestaGastosIngresos;
  gastosIngresosArray: any[] = [['Factura', 'Tipo', 'Concepto', 'Cantidad conductor', 'Cantidad propietario', 'Importe']];
  gastosProveedoresArray: any[] = [['Factura', 'Proveedor', 'Concepto', 'Cantidad conductor', 'Cantidad propietario', 'Importe']];

  listaLicencias: Licencia[];
  licenciaPrueba: Licencia;
  licenciaSeleccionada = false;
  licenciaConductores: IConductor[];
  importesTotalesPorTipo: {};
  datosInforme: {} = {};

  form = new FormGroup({
    licencia: new FormControl(0),
    conductor: new FormControl(0),
    desde: new FormControl(),
    hasta: new FormControl(),
  });

  tipoCobroConductor: any[] = [[{}, {}]];

  fechaAux;
  diferenciaDias;
  importesConductor;
  diasComputados;
  informacionPropietario;
  recaudaciones = [];

  currentUser;
  userSaved: Usuario = null;

  constructor(
    public licenciaService: LicenciaServiece,
    private conductorService: ConductorService,
    private _recaudacionService: RecaudacionService,
    private _gastosIngresosService: GastosIngresosService,
    private _notificationService: NotificationService,
    private _tokenStorageService: TokenStorageService,
    private _controlInformesService: ControlInformesService,
    private _servicioGeneral: ServicioGeneral,
    private datePipe: DatePipe,
    private _informesService: InformesService,
    private _gastosProveedoresService: GastosProveedoresService
  ) { }

  ngOnInit(): void {
    this.getAllLicencias();
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
  }

  getDatosInforme() {
    this.searchMap = {
      fechaInicio: this.form.value.desde,
      fechaFinal: this.form.value.hasta,
      idLicencia: this.form.value.licencia,
      idConductor: this.form.value.conductor,
    };

    this._informesService.getInformePropietario(this.searchMap).subscribe({
      next: (data) => {
        this.datosInforme = data;
      }
    })

  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  getAllLicencias() {
    this.licenciaService.listLicencia().subscribe(
      (res) => {
        this.listaLicencias = res['licencias'];
        this.licenciaPrueba = this.listaLicencias ? this.listaLicencias[0] : null;
      }
    );
  }

  getAllConductoresLicencia() {
    this.conductorService.getConductorByLicencia(this.form.value.licencia).subscribe(
      (res) => {
        this.licenciaConductores = res['conductores'];
      }
    );
  }

  obtenerLicencia() {
    this.licenciaSeleccionada = true;
    this.licenciaPrueba = this.listaLicencias.find(licencia => licencia.id == this.form.value.licencia);

    if (this.licenciaPrueba != null) {
      this.getAllConductoresLicencia();
    }
  }

  /* getTotalEmisoras(): Number {

    let totalEmisoras = 0;

    if (this.importesTotalesPorTipo['Freenow']) {
      totalEmisoras = totalEmisoras + Number(this.importesTotalesPorTipo['Freenow'])
    }

    if (this.importesTotalesPorTipo['Cabify']) {
      totalEmisoras = totalEmisoras + Number(this.importesTotalesPorTipo['Cabify'])
    }

    if (this.importesTotalesPorTipo['Uber']) {
      totalEmisoras = totalEmisoras + Number(this.importesTotalesPorTipo['Uber'])
    }

    if (this.importesTotalesPorTipo['Otros']) {
      totalEmisoras = totalEmisoras + Number(this.importesTotalesPorTipo['Otros'])
    }

    return Number(totalEmisoras.toFixed(2));
  } */

  buildConductor(importeConductor, dias, conductor) {

    let sueldo = conductor.sueldos.find(sueldo => sueldo.licencia.id == this.form.value.licencia);
    let porcentaje = sueldo.cantidad;

    if (sueldo.porcentajeCondicional) {
      porcentaje = porcentaje + sueldo.porcentajeCondicional;
    }

    // let totalEmisoras = this.getTotalEmisoras();

    let body = [
      { text: 'Conductor', bold: true, fontSize: 13, margin: [0, 20, 0, 20] },
      {
        columns: [
          {
            table: {
              body: [
                [{ text: 'Nombre: ', bold: true }, { text: (conductor?.nombre ?? "") }],
                [{ text: 'Apellidos: ', bold: true }, { text: (conductor?.apellidos ?? "") }],
                [{ text: 'DNI: ', bold: true }, { text: conductor?.dni }],
                //[{ text: 'Días computados: ', bold: true, margin: [0, 5, 0, 0] }, { text: 17, margin: [0, 5, 0, 0] }],
                //[{ text: 'Días laborables: ', bold: true }, { text: 22 }]
              ]
            }, fontSize: 10, layout: 'noBorders'
          },
          {
            table: {
              widths: ['auto', 58, 'auto', 58],
              body: [
                [{ text: 'Imp. bruto:', bold: true }, { text: (this.informacionPropietario['importeBruto'].toFixed(2)) + ' €' }, { text: 'Imp. medio diario:', bold: true }, { text: (this.informacionPropietario['importeMedioDiario']).toFixed(2) + ' €' }],
                [{ text: 'Km. totales:', bold: true }, { text: (this.informacionPropietario['kmTotales']).toFixed(2) + ' Km' }, { text: 'Km. medios diarios:', bold: true }, { text: (this.informacionPropietario['kmMedioDiario']).toFixed(2) + ' Km' }],
                [{ text: 'Imp. propietario:', bold: true }, { text: (this.informacionPropietario['importePropietario']).toFixed(2) + ' €' }, { text: 'Porcentaje:', bold: true }, { text: this.informacionPropietario['porcentaje'] + ' %' }],

                [{ text: 'Imp. Tarjetas:', bold: true }, { text: (this.informacionPropietario['ImporteTarjetas']).toFixed(2) + ' €' }, { text: 'Imp. Emisoras:', bold: true }, { text: (this.informacionPropietario['ImporteVales']).toFixed(2) + ' €' }],

                [{ text: 'Freenow:', bold: true }, { text: (this.informacionPropietario['sumaVales']['Freenow'] ?? 0).toFixed(2) + ' €' }, { text: 'Cabify:', bold: true }, { text: (this.informacionPropietario['sumaVales']['Cabify'] ?? 0).toFixed(2) + ' €' }],
                [{ text: 'Uber:', bold: true }, { text: (this.informacionPropietario['sumaVales']['Uber'] ?? 0).toFixed(2) + ' €' }, { text: 'Otros:', bold: true }, { text: (this.informacionPropietario['sumaVales']['Otros'] ?? 0).toFixed(2) + ' €' }],
                [{ text: 'Imp. Efectivo:', bold: true }, { text: importeConductor?.efectivo != null ? Number(importeConductor?.efectivo?.toFixed(2)) + ' €' : 0 + ' €' }, { text: 'D. laborables:', bold: true }, { text: this.informacionPropietario['diasLaborales'] }],
                [{ text: 'D. computados:', bold: true }, { text: this.diasComputados ?? 0 }, {}, {}],

              ]
            }, fontSize: 10, margin: [-45, 0, 0, 0]
          },
        ], columnGap: -20
      },
    ];
    return body;
  }

  buildPago(recaudacion, conductor, importeConductor) {

    // this.getInformePropietario();
    let porcentajeConductor = conductor?.porcentaje / 100;

    // if (conductor?.salario_fijo || (conductor?.salario_fijo == null && conductor?.porcentaje == null)) {

    this.tipoCobroConductor.push([{ text: 'Cantidad retenida por el conductor: ' }, { text: (this.datosInforme['transferirConductor']?.toFixed(2) ?? 0) + '€', bold: true, alignment: 'right' }],
      [{ text: 'A propietario: ' }, { text: (this.datosInforme['aPropietario']?.toFixed(2) ?? 0) + ' €', bold: true, alignment: 'right' }],
      [{ text: 'Ajuste del mes anterior: ' }, { text: (this.datosInforme['ajusteMesAnterior']?.toFixed(2) ?? 0) + ' €', bold: true, alignment: 'right' }],
      [{ text: 'Saldo: ' }, { text: (this.datosInforme['saldo']?.toFixed(2) ?? 0) + ' €', bold: true, alignment: 'right' }])
    // } else if (conductor?.porcentaje != null) {
    //   this.tipoCobroConductor.push([{ text: 'Porcentaje Conductor: ' }, { text: (conductor?.porcentaje ?? 0) + ' %', bold: true, alignment: 'right' }],
    //     [{ text: 'Importe conductor: ' }, { text: (((recaudacion?.importe_bruto ?? 0) * (porcentajeConductor ?? 0)).toFixed(2) ?? 0) + ' €', bold: true, alignment: 'right' }],
    //     [{ text: 'A propietario: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
    //     [{ text: 'Ajuste del mes anterior: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }],
    //     [{ text: 'Saldo: ' }, { text: 0 + ' €', bold: true, alignment: 'right' }])

    // }
    //let liquidacionConductorAux = importeConductor?.importe_conductor != null ? importeConductor?.importe_conductor : 0  + this.respuestaGastosIngresos != null ? this.respuestaGastosIngresos : 0;
    //let liquidacionConductor = liquidacionConductorAux !=  null ? Number(liquidacionConductorAux.toFixed(2)) : 0
    let body = [
      { text: 'Pago', bold: true, fontSize: 13 },
      {
        columns: [
          {
            table: {
              widths: [250, 250],
              heights: 20,
              body: this.tipoCobroConductor,
            }, fontSize: 10, layout: 'lightHorizontalLines'
          },
          /*{
          table: {
            widths: [180, 100],
            body: [
              [{ text: 'Liquidación conductor: ' }, { text: (Number(importeConductor.importe_conductor.toFixed(2) )?? 0) + (this.respuestaGastosIngresos?? 0) + '€', bold: true }],
              [{ text: 'Líquido nómina: ' }, { text: (conductor.salario_fijo ?? 0 )+ ' €', bold: true }],
              [{ text: 'Suplidos facturas: ' }, { text: 0+ ' €', bold: true }],
              [{ text: 'Anticipo salarial: ' }, { text: 0+ ' €', bold: true }],


            ]
          }, fontSize: 10
        }*/
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 20, 0, 0] },
    ];

    return body;
  }

  buildTituloFecha() {
    let body = [
      { text: 'Informe', alignment: 'center', lineHeight: 1.3, margin: [0, 15, 0, 0], bold: true, fontSize: 12 },
      { text: this.form.value.desde + ' - ' + this.form.value.hasta, alignment: 'center', fontSize: 11 }
    ];
    return body;
  }

  buildDetalleGastosProveedor() {
    let body = [
      { text: 'Gastos proveedores', bold: true, fontSize: 13, margin: [0, 20, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [60, 100, 110, 60, 60, 60],
              heights: 20,
              body: this.gastosProveedoresArray,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 10, dontBreakRows: false,
          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 20, 0, 0, 0] },
    ];
    return body;
  }

  buildDetalleGastos() {
    let body = [
      { text: 'Gastos', bold: true, fontSize: 13, margin: [0, 20, 0, 20] },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              widths: [60, 60, 120, 60, 60, 60],
              heights: 20,
              body: this.gastosIngresosArray,
              alignment: "center"

            }, layout: 'lightHorizontalLines', fontSize: 10, dontBreakRows: false,
          },
          { width: '*', text: '' },
        ]
      },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 400, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 20, 0, 0, 0, 0] },
    ];
    return body;
  }



  //-- Prueba
  calcularImporteTotalPorTipo(recaudaciones: any[]): { [tipoNombre: string]: number } {
    const importesTotalesPorTipo: { [tipoNombre: string]: number } = {};

    for (const recaudacion of recaudaciones) {
      for (const vale of recaudacion.vales) {
        const nombreTipo = vale.tipo.nombre;
        importesTotalesPorTipo[nombreTipo] = (importesTotalesPorTipo[nombreTipo] || 0) + vale.importe;
      }
    }
    return importesTotalesPorTipo;
  }
  //-- Fin Prueba







  async cargarDatosPdf(conductor) {

    this.searchMap.idConductor = conductor.id;
    this.searchMap.idLicencia = this.licenciaPrueba.id;
    this.searchMap.fechaInicio = this.form.value.desde, true;
    this.searchMap.fechaFinal = this.form.value.hasta, true;

    this.fechaAux = new Date(this.searchMap.fechaFinal).getTime() - new Date(this.searchMap.fechaInicio).getTime();
    this.diferenciaDias = this.fechaAux / (1000 * 60 * 60 * 24);

    await firstValueFrom(this._recaudacionService.searchRecaudaciones(this.searchMap)).then((res) => {
      this.recaudaciones = res['recaudaciones'];
      this.diasComputados = this.recaudaciones?.filter(recaudacion => !recaudacion.no_computa).length
    });

    await firstValueFrom(this._recaudacionService.searchRecaudacionesConductor(this.searchMap)).then((res) => {
      this.importesConductor = res['recaudaciones'];
    });

    await firstValueFrom(this._informesService.getInformacionInformePropietario(this.searchMap)).then((res) => {
      this.informacionPropietario = res;
    });

    await firstValueFrom(this._gastosIngresosService.searchGastosIngresos(this.searchMap)).then((res) => {      

      let sumatorioAux = 0;
      if (res['GastosIngresos'] != null) {
        var num = 0;
        res['GastosIngresos'].forEach(element => {

          if (element.tipoGestion == 'INGRESO') {
            if (element.tipo_ingreso.id != 8) {
              return;
            }
          }

          if (element.tipo_gasto != null) {
            if (element.tipo_gasto.nombre == "PAGO PROVEEDORES") {
              sumatorioAux = sumatorioAux + element.importe;
            }
          }

          if (num % 2 == 0) {
            if (element?.tipo_gasto?.id != 20) {
              this.gastosIngresosArray.push([{ text: element?.numDocumento ?? '' }, element?.tipoGestion == 'GASTO' ? { text: element?.tipo_gasto?.nombre ?? '' } : { text: element?.tipo_ingreso?.nombre ?? '' }, { text: element?.concepto ?? '' }, { text: (element?.cantidadConductor ?? '') + ' €' }, { text: (element?.cantidadPropietario ?? '') + ' €' }, { text: (element?.importe ?? '') + ' €' }])
            }
          } else {
            if (element?.tipo_gasto?.id != 20) {
              this.gastosIngresosArray.push([{ text: element?.numDocumento ?? '', fillColor: '#dedede' }, element?.tipoGestion == 'GASTO' ? { text: element?.tipo_gasto?.nombre ?? '', fillColor: '#dedede' } : { text: element?.tipo_ingreso.nombre ?? '', fillColor: '#dedede' }, { text: element?.concepto ?? '', fillColor: '#dedede' }, { text: (element?.cantidadConductor ?? '') + ' €', fillColor: '#dedede' }, { text: (element?.cantidadPropietario ?? '') + ' €', fillColor: '#dedede' }, { text: (element.importe ?? '') + ' €', fillColor: '#dedede' }])
            }
          }

          num++;

        });
      }

      // let test1 = [{}, {}, {}, {}, { text: 'Total: ', bold: true, alignment: 'right', margin: [0, 5, 0, 0] }, { text: (totalGastosIngresos) + ' €', margin: [0, 5, 0, 0] }];
      // this.gastosIngresosArray.push(test1);
    });

    await firstValueFrom(this._gastosIngresosService.getImportePropietarioTotal(this.searchMap)).then((res) => {

      let totalGastosIngresos = res['total']['importe'] ?? 0;
      console.log("REs:::    ", res);
      

      let test1 = [{}, {}, {}, {}, { text: 'Total: ', bold: true, alignment: 'right', margin: [0, 5, 0, 0] }, { text: (totalGastosIngresos) + ' €', margin: [0, 5, 0, 0] }];
      this.gastosIngresosArray.push(test1);
    });

    await firstValueFrom(this._gastosProveedoresService.searchGastosProveedores(this.searchMap)).then((res) => {
      if (res['gastosProveedores'] != null) {
        var num = 0;
        res['gastosProveedores'].forEach(element => {
          if (num % 2 == 0) {
            this.gastosProveedoresArray.push([{ text: element?.numFactura }, { text: element?.proveedor?.nombre ?? '' }, { text: element?.concepto?.concepto ?? '' }, { text: (element?.cantidad_conductor ?? '0') + ' €' }, { text: (element?.cantidad_propietario ?? '0') + ' €' }, { text: (element?.importe ?? '') + ' €' }])
          } else {
            this.gastosProveedoresArray.push([{ text: element?.numFactura ?? '', fillColor: '#dedede' }, { text: element?.proveedor?.nombre ?? '', fillColor: '#dedede' }, { text: element?.concepto?.concepto ?? '', fillColor: '#dedede' }, { text: (element?.cantidad_conductor ?? '0') + ' €', fillColor: '#dedede' }, { text: (element?.cantidad_propietario ?? '0') + ' €', fillColor: '#dedede' }, { text: (element?.importe ?? '') + ' €', fillColor: '#dedede' }])
          }
          num++;
        });
      }

      let test1 = [{}, {}, {}, {}, { text: 'Total: ', bold: true, alignment: 'right', margin: [0, 5, 0, 0] }, { text: (res['total']['importe'] ?? 0) + ' €', margin: [0, 5, 0, 0] }];
      this.gastosProveedoresArray.push(test1);
    });
  }

  crearPDF() {
    this.getDatosInforme();
    let conductorToBuild = this.licenciaConductores.find((conductor) => conductor.id == this.form.value.conductor);
    var fechaGeneracion = new Intl.DateTimeFormat("es-ES", { dateStyle: "full", }).format(new Date());
    var fechaString = fechaGeneracion.charAt(0).toUpperCase() + fechaGeneracion.substring(1);
    var nombreApellidosPropietario = this.licenciaPrueba.propietario ? (this.licenciaPrueba.propietario.nombre + ' ' ?? "") + (this.licenciaPrueba.propietario.apellidos ?? "") : '';

    this.cargarDatosPdf(conductorToBuild);

    setTimeout(() => {
      var content = []
      var header = []
      header = [
        {
          columns: [
            {
              table: {
                heights: [25, 10, 10],
                body: [
                  [{ text: fechaString, colSpan: 2, bold: true }, ''],
                  [{ text: 'Licencia:', bold: true }, { text: this.licenciaPrueba.numero_licencia }],
                  [{ text: 'Propietario:', bold: true }, { text: nombreApellidosPropietario }]
                ],
              }, margin: [40, 0, 0, 0], layout: 'noBorders', fontSize: 11
            },
            {
              table: {
                heights: [25, 10, 10],
                body: [
                  [{ image: `data:image/png;base64,${image}`, width: 200 },
                  ],
                ],
              }, margin: [65, 0, 0, 0], layout: 'noBorders', fontSize: 11
            },
          ], margin: [0, 30, 0, 0],
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 0.2, color: '#DBDBDB' }], alignment: 'center', margin: [0, 10, 0, 0] },
      ];

      content.push(this.buildTituloFecha);

      var saldoLicencia = [
        {
          columns: [
            { text: 'Saldo: ', bold: true },
            { text: -35.4 + ' €' }
          ], alignment: 'center', margin: [0, 20, 0, 0], columnGap: -435
        }
      ]

      content.push(this.buildConductor(this.importesConductor, this.diferenciaDias, conductorToBuild), this.buildDetalleGastos(), this.buildDetalleGastosProveedor(), this.buildPago(this.importesConductor, conductorToBuild, this.importesConductor));

      var docDefinition = {
        info: {
          title: 'Informe_' + this.licenciaPrueba.numero_licencia + '_' + nombreApellidosPropietario.replace(/\s/gi, '_'),
          author: 'Novotaxi',
        },
        header: header,
        content: [
          { text: 'Informe', alignment: 'center', lineHeight: 1.3, margin: [0, 15, 0, 0], bold: true, fontSize: 12 },
          { text: this.convertDate(this.form.value.desde, true) + ' - ' + this.convertDate(this.form.value.hasta, true), alignment: 'center', fontSize: 11 },
          content
        ],

        /*pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          return (currentNode.id === 1 || currentNode.startPosition.top >700)},*/
        footer: function (currentPage, pageCount) {
          return (
            {
              columns: [
                { text: 'Novotaxi Madrid, S.L', alignment: 'left', fontSize: 9, margin: [40, 0, 0, 0] },
                { text: `Página ${currentPage} de ${pageCount}`, alignment: 'left', fontSize: 9 }
              ], columnGap: -40
            }
          );
        },
        pageMargins: [40, 120, 40, 20],
      };

      if (this.form.value.desde > this.form.value.hasta) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
      } else {
        // pdfMake.createPdf(docDefinition).open();

        const pdfDoc = pdfMake.createPdf(docDefinition);
        pdfDoc.open();

        pdfDoc.getBase64((dataURL) => {
          const document = {
            "nameDocument": `InformePropietario_${this.licenciaPrueba.propietario.nombre}_${this.licenciaPrueba.propietario.apellidos}.pdf`,
            "typeDocument": "application/pdf",
            "b64Document": dataURL.toString()
          }

          let licencia = this.listaLicencias.find(licencia => licencia.id == this.searchMap.idLicencia);

          this.searchMap.fechaInicio = this.datePipe.transform(this.form.value.desde, 'dd-MM-yyyy');
          this.searchMap.fechaFinal = this.datePipe.transform(this.form.value.hasta, 'dd-MM-yyyy');

          let controlInforme: ControlInformes = {};
          licencia.vehiculo = null;

          controlInforme.licencia = licencia;
          controlInforme.propietario = this.licenciaPrueba.propietario;
          controlInforme.conductor = conductorToBuild;
          controlInforme.fechaDesde = this.searchMap.fechaInicio;
          controlInforme.fechaHasta = this.searchMap.fechaFinal
          controlInforme.tipoInforme = TipoInforme.PROPIETARIO;

          let newControlInforme = new ControlInformes(controlInforme);

          this._controlInformesService.saveInforme(newControlInforme).subscribe({
            next: (data) => {
              this._controlInformesService.saveDocuments(data.id, document).subscribe({
                next: (data) => {
                  if (data['statusCode'] == '200') {
                    this._notificationService.success("HECHO", "Informe guardado y subido a NextCloud", 5000);
                  } else {
                    this._notificationService.error("ERROR", "Se ha producido un error guardando el informe", 5000);
                  }
                }
              })
            }, error: (error) => {
              this._notificationService.error("ERROR", "Se ha producido un error al guardar el informe", 5000);
            }
          })
        });
      }

      this.gastosIngresosArray = [
        ['Factura', 'Tipo', 'Concepto', 'Cantidad conductor', 'Cantidad propietario', 'Importe'],
      ];

      this.gastosProveedoresArray = [
        ['Factura', 'Proveedor', 'Concepto', 'Cantidad conductor', 'Cantidad propietario', 'Importe'],
      ];

      this.tipoCobroConductor = [
        [{}, {}],
      ];
    }, 500);
  }

  // Convrsión de fechas para poder mostrar en el front y enviar al back
  convertDate(date: string, modo: Boolean): string {

    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '/' + mes + '/' + annio);
      } else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }
}

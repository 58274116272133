import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RaizComponent } from './raiz/raiz.component';
import { BoardNovotaxiAdminComponent } from './board-admin-novotaxi/board-admin.component';
import { EnvironmentGuard } from './guards/environment.guard';
import { ListarLicenciaComponent } from './listar-licencia/listar-licencia.component';
import { GestionPropietariosComponent } from './novotaxi/licencias/gestion-propietarios/gestion-propietarios.component';
import { ListadoVehiculosComponent } from './vehiculo/listado-vehiculos/listado-vehiculos.component';
import { ListadoConductoresComponent } from './conductores/listado-conductores/listado-conductores.component';
import { RecaudacionListado } from './novotaxi/caja/recaudacion/listado-recaudacion';
import { GastosIngresosComponent } from './novotaxi/caja/gastos-ingresos/gastos-ingresos.component';
import { GastosProveedoresComponent } from './novotaxi/caja/gastos-proveedores/gastos-proveedores.component';
import { LiquidacionesRetencionesComponent } from './novotaxi/caja/liquidaciones-retenciones/liquidaciones-retenciones.component';
import { PrestamosDevolucionesComponent } from './novotaxi/caja/prestamos-devoluciones/prestamos-devoluciones.component';
import { RankingConductoresComponent } from './novotaxi/ranking-conductores/ranking-conductores.component';
import { GestionCajaComponent } from './novotaxi/caja/gestion-caja/gestion-caja.component';
import { IngresosACajaComponent } from './novotaxi/caja/ingresos-a-caja/ingresos-a-caja.component';
import { OperacionesBancoComponent } from './novotaxi/caja/operaciones-banco/operaciones-banco.component';
import { ListadoGestoriaComponent } from './novotaxi/gestoria/listado-gestoria/listado-gestoria.component';
import { ListadoCompaniaSegurosComponent } from './novotaxi/companiaSeguros/listado-compania-seguros/listado-compania-seguros.component';
import { ListadoEmisoraComponent } from './novotaxi/emisora/listado-emisora/listado-emisora.component';
import { ListadoMarcaModeloComponent } from './novotaxi/marca-modelo/listado-marca-modelo/listado-marca-modelo.component';
import { ListadoProveedoresComponent } from './novotaxi/administrar/proveedores/listado-proveedores/listado-proveedores.component';
import { ListadoDiasLibranzaComponent } from './novotaxi/administrar/dias-libranza/listado-dias-libranza/listado-dias-libranza.component';
import { InformeConductorComponent } from './novotaxi/licencias/informe-propietarios/informe-conductor.component';
import { InformePropietarioPDFComponent } from './novotaxi/licencias/informe-propietarioPDF/informe-propietarioPDF.component';
import { ControlInformesComponent } from './novotaxi/control-informes/control-informes.component';
import { ManualUsuarioComponent } from './manual-usuario/manual-usuario.component';

const routes: Routes = [
  { path: '', component: RaizComponent,
    children: [
      { path: 'inicio', component: InicioComponent },
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:identificador', component: ResetPasswordComponent },
      { path: 'manual-usuario', component: ManualUsuarioComponent },


      { path: 'admin-novotaxi', component: BoardNovotaxiAdminComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
        //Apartado 'Licencias'
        { path: 'gestion-licencias', component: ListarLicenciaComponent, canActivate: [AuthGuard, EnvironmentGuard]}, 
        { path: 'gestion-propietarios', component: GestionPropietariosComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'gestion-vehiculos', component: ListadoVehiculosComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'gestion-conductores', component: ListadoConductoresComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        //Apartado 'Caja'
        { path: 'recaudacion', component: RecaudacionListado, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'gastos-ingresos', component: GastosIngresosComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'gastos-proveedores', component: GastosProveedoresComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'retenciones-liquidaciones', component: LiquidacionesRetencionesComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'gestion-conductores', component: ListadoConductoresComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'prestamos-devoluciones', component: PrestamosDevolucionesComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'ranking-conductores', component: RankingConductoresComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        //Apartado 'Caja/Bancos'
        { path: 'gestion-caja', component: GestionCajaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'ingresos-caja', component: IngresosACajaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'operaciones-banco', component: OperacionesBancoComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        //Apartado 'Administrar'
        { path: 'gestorias', component: ListadoGestoriaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'companias-seguro', component: ListadoCompaniaSegurosComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'emisoras', component: ListadoEmisoraComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'marcas-modelos', component: ListadoMarcaModeloComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'proveedores', component: ListadoProveedoresComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'dias-libranza', component: ListadoDiasLibranzaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        //Apartado 'Informes'
        { path: 'informe-conductor', component: InformeConductorComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'informe-propietario', component: InformePropietarioPDFComponent, canActivate: [AuthGuard, EnvironmentGuard]},
        { path: 'control-informes', component: ControlInformesComponent, canActivate: [AuthGuard, EnvironmentGuard]},
      ]},
    ]
  },
  { path: '**', redirectTo: 'inicio' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true,
    anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

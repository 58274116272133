import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DiasLibranza } from "src/app/model/novotaxi/dias-libranza.model";
import { environment } from "src/environments/environment.novotaxi";

@Injectable({
    providedIn: "root",
})

export class DiasLibranzaService {

    API_URL: string = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) { }

    saveAllDiasLibranza(diaLibranza) {
        return this.http.post(`${this.API_URL}/diaLibranza/save`, diaLibranza, {
        });
    }

    listDiasLibranza() {
        return this.http.get(`${this.API_URL}/diaLibranza/list`, {})
    }

    updateDiasLibranza(diaLibranza: DiasLibranza) {
        return this.http.post(`${this.API_URL}/diaLibranza/update`, diaLibranza, {
        })
    }

    deleteDiasLibranza(id) {
        return this.http
            .get(`${this.API_URL}/diaLibranza/delete/${id}`);
    }

    checkDiasLibranza(map) {
        return this.http.post(`${this.API_URL}/diaLibranza/checkDiaLibranza`, map, {
        })
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, throwError } from "rxjs";
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: "root",
})

export class LiquidacionService {

  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  searchLiquidaciones(mapa) {
    return this.http
      .post(`${this.API_URL}/liquidacionesretenciones/search`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllTiposLiquidacion() {
    return this.http
      .get(`${this.API_URL}/liquidacionesretenciones/listTipoLiquidacion`)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveAllLiquidacionesRetenciones(listLiquidacionesRetenciones) {
    return this.http
      .post(`${this.API_URL}/liquidacionesretenciones/saveAll`, listLiquidacionesRetenciones, {})
    /* .pipe(retry(1), catchError(this.handleError)); */
  }

  updateRetencionLiquidacion(liquidacionRetencion) {
    return this.http
      .post(`${this.API_URL}/liquidacionesretenciones`, liquidacionRetencion, {})
  }

  delete(id: number) {
    return this.http.get(`${this.API_URL}/liquidacionesretenciones/delete/${id}`, {})
  }

  getTotalLiquidacionesRetenciones(map) {
    return this.http
      .post(`${this.API_URL}/liquidacionesretenciones/getTotal`, map)
  }

  getRetencionesLiquidaciones(request?) {
    return this.http
      .post(`${this.API_URL}/liquidacionesretenciones/list`, request)
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}


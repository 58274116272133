import { IVehiculo, NewVehiculo } from './../vehiculo.model';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';

export type PartialUpdateVehiculo = Partial<IVehiculo> & Pick<IVehiculo, 'id'>;

type RestOf<T extends IVehiculo | NewVehiculo> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestVehiculo = RestOf<IVehiculo>;

export type NewRestVehiculo = RestOf<NewVehiculo>;

export type EntityResponseType = HttpResponse<IVehiculo>;
export type EntityArrayResponseType = HttpResponse<IVehiculo[]>;

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  protected resourceUrl = `${environment.API_URL}/vehiculo`;
  API_URL: string = environment.API_URL;

  constructor(protected http: HttpClient) { }

  create(vehiculo: IVehiculo) {
    return this.http.post(`${this.resourceUrl}/`, vehiculo);
  }

  update(vehiculo: IVehiculo) {
    return this.http
      .post<RestVehiculo>(this.resourceUrl, vehiculo, {})
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<RestVehiculo>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map(res => this.convertResponseFromServer(res)));
  }
  findTipoVehiculo() {
    return this.http
      .get(`${this.resourceUrl}/tipo_vehiculo`)
  }
  query(req?: any) {
    const options = {};
    return this.http
      .get<RestVehiculo[]>(this.resourceUrl, {});
  }

  // delete(id: number, fecha: Date): Observable<HttpResponse<{}>> {
  //   return this.http.post(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
  // }

  delete(id, fecha) {
    const url = `${this.resourceUrl}/delete/${id}`;
    return this.http.post(url, fecha);
  }

  getHistorico(id) {
    return this.http
      .get(`${this.resourceUrl}/getHistorico/${id}`)
    // .pipe(retry(1), catchError(this.handleError));
  }

  getHistoricoLicenciaVehiculo(id) {
    return this.http
      .get(`${this.API_URL}/vehiculoLicenciaHistorico/getHistorico/${id}`)
    // .pipe(retry(1), catchError(this.handleError));
  }

  getVehiculos(request?) {
    return this.http
      .post(`${this.resourceUrl}/list`, request)
  }

  getDetails() {
    return this.http
      .get(`${this.resourceUrl}/getDetails`)
  }

  protected convertDateFromClient<T extends IVehiculo | NewVehiculo | PartialUpdateVehiculo>(vehiculo: T): any {
    return {
      ...vehiculo,
      fechaAlta: vehiculo.fechaAlta?.toString() ?? null,
      fechaBaja: vehiculo.fechaBaja?.toISOString() ?? null,
      fechaModificacion: vehiculo.fechaModificacion?.toISOString() ?? null,
    };
  }

  protected convertDateFromServer(restVehiculo: RestVehiculo): IVehiculo {
    let fechaAlta = restVehiculo.fechaAlta?.split("-");
    let fechaBaja = restVehiculo.fechaBaja?.split("-");
    let fechaModificacion = restVehiculo.fechaModificacion?.split("-");
    return {
      ...restVehiculo,
      fechaBaja: restVehiculo.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
      fechaModificacion: restVehiculo.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
    };
  }

  protected convertResponseFromServer(res: HttpResponse<RestVehiculo>): HttpResponse<IVehiculo> {
    return res.clone({
      body: res['vehiculos'] ? this.convertDateFromServer(res['vehiculos']) : null,
    });
  }

  protected convertResponseArrayFromServer(res: HttpResponse<RestVehiculo[]>): HttpResponse<IVehiculo[]> {
    return res.clone({
      body: res['vehiculos'] ? res['vehiculos'].map(item => this.convertDateFromServer(item)) : null,
    });
  }
}
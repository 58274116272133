import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: 'root'
})

export class VehiculoService {
  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  getVehiculoId(id: number) {
    const url = `${this.API_URL}/vehiculo/{id}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  getAllVehiculo() {
    const url = `${this.API_URL}/vehiculo`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  getVehiculosByIdConductor(idCondcutor) {
    const url = `${this.API_URL}/vehiculo/vehiculoConductor/${idCondcutor}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  getAllConductoresFromVehiculo(id) {
    const url = `${this.API_URL}/licencia/listConductores/${id}`;
    return this.http.get(url).pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
<div class="bg-white rounded p-5">
    <div class="d-flex justify-content-center mb-5 mt-5"><mat-icon class="text-danger">delete_forever</mat-icon></div>
    <div class="text-center pt-4">
        <h2>¿Quieres continuar?</h2>
        <p class="mt-3">Recuerda que esta acción no podrá deshacerse desde este panel</p>
    </div>
    <div class="pt-4 d-flex justify-content-center">
        <button class="btn btn-danger btn-lg mx-3" (click)="confirm()">Borrar</button>
        <button class="btn btn-secondary btn-lg mx-3" (click)="dialogRef.close({result: ''})">Cancelar</button>
    </div>
</div>
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IConductor } from 'src/app/conductores/conductor.model';
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { IRetencionLiquidacion } from 'src/app/model/novotaxi/retenciones-liquidaciones/liquidacion-retencion.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { LiquidacionService } from 'src/app/_services/novotaxi/retenciones-liquidaciones/retenciones-liquidaciones.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { CardDetallesCajaComponent } from '../card-detalles-caja/card-detalles-caja.component';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Licencia } from 'src/app/model/Emisora';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';
import { ExcelExportService } from 'src/app/_services/excel-export.service';


@Component({
  selector: 'app-liquidaciones-retenciones',
  templateUrl: './liquidaciones-retenciones.component.html',
  styleUrls: ['./liquidaciones-retenciones.component.css']
})

export class LiquidacionesRetencionesComponent implements OnInit {
  displayedColumns: string[] = ['fechaAlta', 'importe', 'tipo_liquidacion', 'concepto', 'numDocumento', 'licencia.numero_licencia', 'conductor.nombre', 'conductor.apellidos', 'usuario.nombre', 'acciones'];

  displayedSearchHeaders: string[] = ['fecha_buscador', 'importe_buscador', 'tipo_buscador', 'concepto_buscador', 'numDocumento_buscador', 'licencia_buscador', 'conductor_nombre_buscador', 'conductor_apellidos_buscador', 'usuario_buscador', 'acciones_buscador'];

  dataSource;
  public conductores: IConductor[] = [];
  isSearch: boolean = false;
  fechainicio: string;
  fechafin: string;
  selectedValueConductor: IConductor;
  listColumns;
  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
    idConductor: null,
  };

  currentDate: Date = new Date();
  liquidacionesList: IRetencionLiquidacion[] = [];

  // -- Variables para el control del usuario
  currentUser;
  userSaved: Usuario = null;

  // -- Variables filtrado select
  showDropDown = false;
  keys;
  allKeys;
  nameSearch: string;
  filterArray: IConductor[] = [];

  // -- Paginado
  length = this.liquidacionesList.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  // -- Variables optimización
  public filters = [];
  public staticFilters;
  public sorts = [];
  public totalElements = 0;

  // -- Filtrado por columnas
  public searchForm: FormGroup;
  public fecha = '';
  public importe = '';
  public tipo = '';
  public concepto = '';
  public numFactura = '';
  public conductor = '';
  public licencia = '';
  public usuario = '';

  // -- Variables para pasar al componente
  mostrarFormulario: Boolean = false;
  retencionLiquidacionUpdate: IRetencionLiquidacion;
  editar: Boolean = false;
  tipoAccion: string;
  licencias: Licencia[] = [];

  data: DataRetencionesLiquidaciones = { mostrarFormulario: false, retencionesLiquidaciones: [], conductor: null, tipo: null, elementUpdate: null, isForUpdate: false, conductores: this.conductores }

  tipoDetalle: string = 'liquidaciones';

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesCajaComponent) child;

  constructor(
    private _conductorService: ConductorService,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private _liquidacionService: LiquidacionService,
    private _tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private datePipe: DatePipe,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
    this.getConductores();
    this.listColumns = {
      'Fecha': true,
      'Importe': true,
      'Tipo': true,
      'Concepto': true,
      'Nº factura': true,
      'Licencia': true,
      'Nombre conductor': true,
      'Apellidos conductor': true,
      'Usuario': true,
    }

    this.fechainicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
    this.fechafin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.searchMap.fechaInicio = this.fechainicio;
    this.searchMap.fechaFinal = this.fechafin;

    this.staticFilters = {
      key: 'fechaAlta',
      operator: "BETWEEN_DATES",
      field_type: 'DATE_BETWEEN',
      value: this.fechainicio.toString().split("-").reverse().join("-"),
      value_to: this.fechafin.toString().split("-").reverse().join("-"),
    };

    this.setRequestForSearch();
    this.searchFormInit();
  }

  generarInforme() {
    let nombre = 'Retencion/Liquidacion';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  setMostrarFormulario(mode: Boolean, isForUpdate?: Boolean, tipo?: string, element?: IRetencionLiquidacion) {

    if (isForUpdate) {
      this.mostrarFormulario = mode;
      this._conductorService.getLicenciasByConductor(element?.conductor?.id).subscribe((response) => {
        this.licencias = response['licencias'];
        this.data = { mostrarFormulario: mode, retencionesLiquidaciones: [], conductor: element.conductor, tipo: element != null ? element.gestion : tipo, elementUpdate: element, isForUpdate: isForUpdate, conductores: this.conductores, licencias: this.licencias }
      });
    } else {
      this.mostrarFormulario = mode;

      if (this.selectedValueConductor != null) {
        this.data = { mostrarFormulario: mode, retencionesLiquidaciones: [], conductor: this.selectedValueConductor, tipo: element != null ? element.gestion : tipo, elementUpdate: element, isForUpdate: isForUpdate, conductores: this.conductores, licencias: this.licencias }
      } else {
        this.data = { mostrarFormulario: mode, retencionesLiquidaciones: [], conductor: null, tipo: element != null ? element.gestion : tipo, elementUpdate: element, isForUpdate: isForUpdate, conductores: this.conductores, licencias: this.licencias }
      }
    }

    if (mode) {
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
    }
  }

  //Método en el que obtenemos el objeto guardado o actualizado en el formulario y se lo pasamos al dataSource
  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    this.child.getTotalesLiquidacionesRetenciones(this.searchMap);
    this.setRequestForSearch();
  }

  getConductores() {
    this._conductorService.query().subscribe((res) => {
      this.conductores = res['conductores']
      this.keys = this.conductores;
      this.allKeys = this.keys;
    });
  }

  isValidFieldsSearch() {
    if (this.fechainicio == undefined || this.fechafin == undefined /* || this.selectedValueConductor == undefined */) {
      this._notificationService.warning("¡Atención!", "Debe rellenar las fechas para la búsqueda", 3000);
      return false;
    } else {
      if (this.fechainicio > this.fechafin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }

  async openDialogDelete(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'borrar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {

        if (element.id != null) {
          let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

          if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar una retención/liquidación del día actual", 3000);
          } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar una retención/liquidación del día actual", 3000);
          } else {
            this.deleteLiquidacionRetencion(element);
          }
        }
      } else {
        null
      }
    })
  }

  deleteLiquidacionRetencion(element) {
    this._liquidacionService.delete(element.id).subscribe((result) => {
      if (result['status'] == '622') {
        this._notificationService.success('Hecho', 'Liquidación/Retención eliminado con éxito', 3000);
        this.setRequestForSearch();
      } else {
        this._notificationService.success('ERROR', 'Liquidación/Retención eliminado con éxito', 3000);
        this.setRequestForSearch();
      }
    });
  }

  formatDateForBE(dateString: string): string {
    if (dateString) {
      const splitDate = dateString.split('-');

      const date = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      return this.datePipe.transform(date, 'yyyy-MM-dd')
    }
    return null;
  }

  // -- Filtrado select

  focusOut() {
    this.nameSearch = "";
    this.keys = this.allKeys;
  }

  filterColumns(event) {
    this.nameSearch = event.target.value;
    if (this.nameSearch && this.nameSearch != "") {
      this.filterArray = [];

      this.allKeys.forEach(element => {
        let nombreCompleto = element.nombre + ' ' + element.apellidos + ' ' + element.dni
        if (nombreCompleto.toLowerCase().includes(this.nameSearch.toLowerCase())) {
          this.filterArray.push(element);
        }
      });

    } else {
      this.filterArray = this.allKeys;
    }
    this.keys = this.filterArray
  }

  getSelectedValue(value: IConductor) {
    this.selectedValueConductor = value;
    this.showDropDown = false;
  }

  // -- Fin filtrado select


  // -- Inicio optimización
  searchFormInit() {
    this.searchForm = new FormGroup({
      fecha: new FormControl(''),
      importe: new FormControl(''),
      tipo: new FormControl(''),
      concepto: new FormControl(''),
      numFactura: new FormControl(''),
      conductor: new FormControl(''),
      licencia: new FormControl(''),
      usuario: new FormControl(''),
    });
  }

  setRequestForSearch(request?) {
    let requestSearchFields;

    this.searchMap = {
      fechaInicio: this.fechainicio,
      fechaFinal: this.fechafin,
      idConductor: this.selectedValueConductor ? this.selectedValueConductor.id : null,
    };

    if (this.isValidFieldsSearch) {
      if (this.selectedValueConductor != null) {
        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechainicio.toString().split("-").reverse().join("-"),
            value_to: this.fechafin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },
          {
            key: 'conductor.id',
            operator: "EQUAL",
            field_type: 'STRING',
            value: this.selectedValueConductor?.id,
          },],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      } else if (this.selectedValueConductor == null) {
        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechainicio.toString().split("-").reverse().join("-"),
            value_to: this.fechafin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      }
    }

    this.searchRetencionesLiquidaciones(requestSearchFields, request);
  }

  async searchRetencionesLiquidaciones(requestSearchFields?, request?) {
    let filtersToSend = [];

    if (request) {
      if (requestSearchFields) {
        filtersToSend = [...request['filters'], ...requestSearchFields['filters']];

      } else {
        filtersToSend = [...request['filters']];
      }

    } else {
      filtersToSend = [...requestSearchFields['filters']];
    }

    let requestToSend = {
      "filters": filtersToSend,
      "sorts": this.sorts,
      page: this.pageIndex,
      size: this.pageSize,
    }

    await this._liquidacionService.getRetencionesLiquidaciones(requestToSend).subscribe((data) => {
      this.liquidacionesList = [];
      this.totalElements = 0;

      if (data['liquidacionesRetenciones']) {

        if (data['liquidacionesRetenciones']['content']) {
          this.isSearch = true;
          for (const retencionesLiquidacionesJSON of data['liquidacionesRetenciones']['content']) {
            this.liquidacionesList.push(new IRetencionLiquidacion(retencionesLiquidacionesJSON));
          }
        } else {
          this._notificationService.info("INFO", "No se han encontrado datos", 3000);
          this.isSearch = false;
        }
        this.totalElements = data['liquidacionesRetenciones']['totalElements'];
      }
      this.dataSource = new MatTableDataSource(this.liquidacionesList);
    })
  }

  async applyFilter(event?, key?, type?) {
    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];

    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event?.target?.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {

    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type?.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type?.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }

      if (type?.toUpperCase() == "DATE_BETWEEN" || type?.toUpperCase() == "LOCALDATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type?.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type?.toUpperCase() == "DOUBLE" || type?.toUpperCase() == "LONG" || type?.toUpperCase() == "BIG_DECIMAL" || type?.toUpperCase() == "INTEGER") {
        filter.operator = "LIKE"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == 'ENUM_TIPO_GESTION') {
        filter.operator = "LIKE_TIPO_GESTION"
        filter.field_type = "STRING"
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,
    }

    this.paginator.firstPage();
    await this.setRequestForSearch(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setRequestForSearch(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }

      } else {
        if (sort.active == "tipo") {
          this.sorts = [{
            "key": 'tipo_gasto.nombre',
            "direction": direction
          },
          {
            "key": 'tipo_ingreso.nombre',
            "direction": direction
          }];

        } else {
          this.sorts = [{
            "key": sort.active,
            "direction": direction
          }];
        }

      }
      request['sorts'] = this.sorts;
    }

    await this.setRequestForSearch(request)
  }

  clearSearchInputs() {
    this.searchForm.reset();
    this.filters = [];
  }
  // -- Fin optimización

  // -- Exportación Excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, fechaBaja, fechaModificacion, numDocumento, conductor, gestion, ...rest } = item;

    if (item.usuario) {
      rest.usuario = item.usuario.nombre;
    }

    if (item.licencia) {
      rest.licencia = item.licencia.numero_licencia;
    }

    return rest;
  }

  generateExcel() {

    const data = this.dataSource.data.map((retencionesLiquidaciones) => {
      const formattedRetencionesLiquidaciones = { ...retencionesLiquidaciones };
      formattedRetencionesLiquidaciones.fechaAlta = this.formatDate(retencionesLiquidaciones.fechaAlta).toLocaleDateString();
      formattedRetencionesLiquidaciones.fechaModificacion ? formattedRetencionesLiquidaciones.fechaModificacion = this.formatDate(retencionesLiquidaciones.fechaModificacion) : null;
      return formattedRetencionesLiquidaciones;
    });

    this.addRows(data);

    const dataFormatted = data.map((retencionesLiquidaciones) => {
      const formattedRetencionesLiquidaciones = { ...retencionesLiquidaciones };
      formattedRetencionesLiquidaciones.fechaAlta = this.formatDate(retencionesLiquidaciones.fechaAlta).toLocaleDateString();
      formattedRetencionesLiquidaciones.fechaModificacion ? formattedRetencionesLiquidaciones.fechaModificacion = this.formatDate(retencionesLiquidaciones.fechaModificacion) : null;
      return this.filterFields(formattedRetencionesLiquidaciones);
    });

    const fileName = 'Tabla_RetencionesLiquidaciones';
    this._excelService.exportToExcel(dataFormatted, fileName);
  }

  addRows(data) {
    data.forEach((retencionesLiquidaciones) => {
      retencionesLiquidaciones.num_factura = retencionesLiquidaciones.numDocumento ?? '';
      retencionesLiquidaciones.nombre_conductor = retencionesLiquidaciones.conductor.nombre;
      retencionesLiquidaciones.apellidos_conductor = retencionesLiquidaciones.conductor.apellidos;
    });
  }
}

//Datos que se pasarán al componente hijo y se recibirán posteriormente
export interface DataRetencionesLiquidaciones {
  retencionesLiquidaciones?: IRetencionLiquidacion[];
  conductor: IConductor;
  tipo: string;
  elementUpdate: IRetencionLiquidacion; //Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  conductores: IConductor[];
  licencias?: Licencia[];
}
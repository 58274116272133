<!-- ------------------------------- Botones para la búsqueda ----------------------- -->
<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Retenciones/Liquidaciones</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3"  (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle"
        >Generar informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle mr-3" (click)="setMostrarFormulario(true, false, 'retención')"
      [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva retención</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true, false, 'liquidación')"
      [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva liquidación</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles -->
<app-card-detalles-caja #detalles [searchMap]="searchMap" [tipoDetalle]="tipoDetalle"></app-card-detalles-caja>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4" id="scroll">

  <!-- -- Llamada al componente de guardado -->
  <div class="mostrarFormulario" *ngIf="data.mostrarFormulario">
    <app-create-update-liquidacion (mostrarFormulario)="getValueForm($event)"
      [data]="data"></app-create-update-liquidacion>
  </div>

  <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row align-items-center">
      <div class="row align-items-center flex-grow-1 crear-editar-form">
        <div class="col-sm-2">
          <label>Desde</label>
          <input _ngcontent-vka-c280="" [(ngModel)]="fechainicio"
            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
        </div>
        <div class="col-sm-2">
          <label>Hasta</label>
          <input _ngcontent-vka-c280="" [(ngModel)]="fechafin"
            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
        </div>
        <div class="col-sm-3">
          <label>Conductor</label>

          <div>
            <div (mouseleave)="showDropDown = false">
              <button class="form-control form-control-sm ng-pristine ng-invalid ng-touched btn-outline btn-select"
                (click)="showDropDown=!showDropDown; focusOut()">
                <i class="fa fa-angle-down"></i>
                {{selectedValueConductor?.nombre}} {{selectedValueConductor?.apellidos}}
                {{selectedValueConductor?.dni}}
              </button>
              <div class="drop-show div-drop" *ngIf="showDropDown">
                <input class="input-filter-select" type="text" placeholder="Conductor" (keyup)="filterColumns($event)">
                <label class="label-select" *ngFor="let a of keys">
                  <input class="input-select" type="button" [(ngModel)]="a.nombre + ' ' + a.apellidos + ' ' + a.dni"
                    (click)="getSelectedValue(a)" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary btn-sm align-self-end ml-3"
          (click)="setRequestForSearch(); detalles.getTotalesLiquidacionesRetenciones(searchMap); clearSearchInputs()">
          <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
        </button>
      </div>
      <div class="btn-group btn-group-sm align-self-start" role="group">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
      </div>
    </div>

    <div class="table_container mt-5" *ngIf="isSearch">

      <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
        (matSortChange)="sortData($event)">

        <!-- ------------------------------- Columna fecha ----------------------- -->
        <ng-container matColumnDef="fecha_buscador">
          <th [hidden]="!listColumns['Fecha']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha" formControlName="fecha"
                    (keyup)="applyFilter($event,'fechaAlta', 'LOCALDATE_BETWEEN')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="fechaAlta">
          <th mat-header-cell [hidden]="!listColumns['Fecha']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Fecha
          </th>
          <td mat-cell [hidden]="!listColumns['Fecha']" *matCellDef="let element">{{element?.fechaAlta}}</td>
        </ng-container>

        <!-- ------------------------------- Columna importe ----------------------- -->
        <ng-container matColumnDef="importe_buscador">
          <th [hidden]="!listColumns['Importe']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Importe" formControlName="importe"
                    (keyup)="applyFilter($event,'importe', 'BIG_DECIMAL')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="importe">
          <th mat-header-cell [hidden]="!listColumns['Importe']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Importe
          </th>
          <td mat-cell [hidden]="!listColumns['Importe']" *matCellDef="let element">{{element?.importe | numberFormat}}
          </td>
        </ng-container>

        <!-- ------------------------------- Columna tipo ----------------------- -->
        <ng-container matColumnDef="tipo_buscador">
          <th [hidden]="!listColumns['Tipo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Tipo" formControlName="tipo" (keyup)="applyFilter($event,'tipo_liquidacion', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="tipo_liquidacion">
          <th mat-header-cell [hidden]="!listColumns['Tipo']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Tipo
          </th>
          <td mat-cell [hidden]="!listColumns['Tipo']" *matCellDef="let element">{{element?.tipo_liquidacion}}
          </td>
        </ng-container>

        <!-- ------------------------------- Columna concepto ----------------------- -->
        <ng-container matColumnDef="concepto_buscador">
          <th [hidden]="!listColumns['Concepto']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Concepto" formControlName="concepto" (keyup)="applyFilter($event,'concepto', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="concepto">
          <th mat-header-cell [hidden]="!listColumns['Concepto']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Concepto
          </th>
          <td mat-cell [hidden]="!listColumns['Concepto']" *matCellDef="let element">{{element?.concepto}}
          </td>
        </ng-container>

        <!-- ------------------------------- Columna número de factura ----------------------- -->
        <ng-container matColumnDef="numDocumento_buscador">
          <th [hidden]="!listColumns['Nº factura']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Num Factura" formControlName="numFactura"
                    (keyup)="applyFilter($event,'numDocumento', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="numDocumento">
          <th mat-header-cell [hidden]="!listColumns['Nº factura']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Nº factura
          </th>
          <td mat-cell [hidden]="!listColumns['Nº factura']" *matCellDef="let element">
            {{element?.numDocumento}}
          </td>
        </ng-container>

        <!-- ------------------------------- Columna licencia ----------------------- -->
        <ng-container matColumnDef="licencia_buscador">
          <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Licencia" formControlName="licencia"
                    (keyup)="applyFilter($event,'licencia.numero_licencia', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="licencia.numero_licencia">
          <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Licencia
          </th>
          <td mat-cell [hidden]="!listColumns['Licencia']" *matCellDef="let element">
            {{element?.licencia?.numero_licencia}}</td>
        </ng-container>

        <!-- ------------------------------- Columna nombre conductor ----------------------- -->
        <ng-container matColumnDef="conductor_nombre_buscador">
          <th [hidden]="!listColumns['Nombre conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Conductor" formControlName="conductor"
                    (keyup)="applyFilter($event,'conductor.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="conductor.nombre">
          <th mat-header-cell [hidden]="!listColumns['Nombre conductor']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Nombre conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Nombre conductor']" *matCellDef="let element">
            {{element?.conductor.nombre}}</td>
        </ng-container>

        <!-- ------------------------------- Columna apellido conductor ----------------------- -->
        <ng-container matColumnDef="conductor_apellidos_buscador">
          <th [hidden]="!listColumns['Apellidos conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Conductor" formControlName="conductor"
                    (keyup)="applyFilter($event,'conductor.apellidos', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="conductor.apellidos">
          <th mat-header-cell [hidden]="!listColumns['Apellidos conductor']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Apellidos conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Apellidos conductor']" *matCellDef="let element">
            {{element?.conductor.apellidos}}</td>
        </ng-container>



        <!-- ------------------------------- Columna usuario ----------------------- -->
        <ng-container matColumnDef="usuario_buscador">
          <th [hidden]="!listColumns['Usuario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Usuario" formControlName="usuario"
                    (keyup)="applyFilter($event,'usuario.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="usuario.nombre">
          <th mat-header-cell [hidden]="!listColumns['Usuario']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Usuario
          </th>
          <td mat-cell [hidden]="!listColumns['Usuario']" *matCellDef="let element">
            {{element?.usuario.nombre}}
          </td>
        </ng-container>

        <!-- Columna acciones -->
        <ng-container matColumnDef="acciones_buscador" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
        </ng-container>

        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="header"></th>
          <td mat-cell *matCellDef="let element" class="mat-column acciones">
            <div class="buttons-flex-container">
              <!-- <a class="eye-button activated"
                                (click)="openDialogCreateLiquidacion('', element, true)"></a>-->
              <a class="eye-button activated"
                (click)="setMostrarFormulario(true, true, element?.tipoGestion, element)"></a>
              <a class="delete-button activated" (click)="openDialogDelete(element)"></a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator class="mt-3" #paginator *ngIf="isSearch" (page)="nextPage($event)" [length]="totalElements"
      [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>
  </div>
</div>
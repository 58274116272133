<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Gestión de conductores</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle" >Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar
        informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)" [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo conductor</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-graficos #detalles [tipoDetalle]="tipoDetalle"></app-card-detalles-graficos>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">
  <div class="mostrarFormulario" *ngIf="mostrarFormulario">
    <app-crear-conductor (mostrarFormulario)="getValueForm($event)" [conductorUpdate]="conductorUpdate"
      [editar]="editar"></app-crear-conductor>
  </div>
  <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
      <app-multi-select-dropdown [listColumns]="listColumns">
      </app-multi-select-dropdown>
    </div>

    <div class="table_container" [hidden]="mostrarFormulario">

      <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
        (matSortChange)="sortData($event)">

        <!-- Columna DNI -->
        <ng-container matColumnDef="dni-buscador" sticky>
          <th [hidden]="!listColumns['DNI']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Dni" formControlName="dni" (keyup)="applyFilter($event,'dni', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="dni" sticky>
          <th [hidden]="!listColumns['DNI']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by licencia" class="header">
            DNI
          </th>
          <td [hidden]="!listColumns['DNI']" mat-cell *matCellDef="let element">{{element.dni}}</td>
        </ng-container>

        <!-- Columna Nombre -->
        <ng-container matColumnDef="nombre-buscador">
          <th [hidden]="!listColumns['Nombre']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre" formControlName="nombre" (keyup)="applyFilter($event,'nombre', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th [hidden]="!listColumns['Nombre']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by nombre" class="header">
            Nombre
          </th>
          <td [hidden]="!listColumns['Nombre']" mat-cell *matCellDef="let element">{{element.nombre}}</td>
        </ng-container>

        <!-- Columna Apellidos -->
        <ng-container matColumnDef="apellidos-buscador">
          <th [hidden]="!listColumns['Apellidos']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Apellidos" formControlName="apellidos"
                    (keyup)="applyFilter($event,'apellidos', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="apellidos">
          <th [hidden]="!listColumns['Apellidos']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by apellidos" class="header">
            Apellidos
          </th>
          <td [hidden]="!listColumns['Apellidos']" mat-cell *matCellDef="let element">{{element.apellidos}}</td>
        </ng-container>

        <!-- Columna Fecha de Alta -->
        <ng-container matColumnDef="fechaAlta-buscador">
          <th [hidden]="!listColumns['Fecha alta']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre" formControlName="fechaAlta"
                    (keyup)="applyFilter($event,'fechaAlta', 'LOCALDATE_BETWEEN')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="fechaAlta">
          <th [hidden]="!listColumns['Fecha alta']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Fecha de alta
          </th>
          <td [hidden]="!listColumns['Fecha alta']" mat-cell *matCellDef="let element">{{element.fechaAlta}}</td>
        </ng-container>

        <!-- Columna Fecha Vencimiento BTP -->
        <ng-container matColumnDef="fechaVencimientoBTP-buscador">
          <th [hidden]="!listColumns['Fecha BTP']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Vencimiento BTP" formControlName="fechaBTP"
                    (keyup)="applyFilter($event,'fechaVencimientoBTP', 'LOCALDATE_BETWEEN')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="fechaVencimientoBTP">
          <th [hidden]="!listColumns['Fecha BTP']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by marca" class="header">
            Fecha BTP
          </th>
          <td [hidden]="!listColumns['Fecha BTP']" mat-cell *matCellDef="let element">{{element.fechaVencimientoBTP}}
          </td>
        </ng-container>

        <!-- Columna Domicilio -->
        <ng-container matColumnDef="domicilio-buscador">
          <th [hidden]="!listColumns['Domicilio']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Domicilio" formControlName="domicilio"
                    (keyup)="applyFilter($event,'domicilio', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="domicilio">
          <th [hidden]="!listColumns['Domicilio']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by domicilio" class="header">
            Domicilio
          </th>
          <td [hidden]="!listColumns['Domicilio']" mat-cell *matCellDef="let element">{{element.domicilio}}</td>
        </ng-container>

        <!-- Columna Población -->
        <ng-container matColumnDef="poblacion-buscador">
          <th [hidden]="!listColumns['Población']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Poblacion" formControlName="poblacion"
                    (keyup)="applyFilter($event,'poblacion', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="poblacion">
          <th [hidden]="!listColumns['Población']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Poblacion
          </th>
          <td [hidden]="!listColumns['Población']" mat-cell *matCellDef="let element">{{element.poblacion}}</td>
        </ng-container>

        <!-- Columna Provincia -->
        <ng-container matColumnDef="provincia-buscador">
          <th [hidden]="!listColumns['Provincia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Provincia" formControlName="provincia"
                    (keyup)="applyFilter($event,'provincia.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="provincia.nombre">
          <th [hidden]="!listColumns['Provincia']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Provincia
          </th>
          <td [hidden]="!listColumns['Provincia']" mat-cell *matCellDef="let element">{{element.provincia?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Código postal -->
        <ng-container matColumnDef="codigoPostal-buscador">
          <th [hidden]="!listColumns['Código postal']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Código postal" formControlName="codigoPostal"
                    (keyup)="applyFilter($event,'codigoPostal', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="codigoPostal">
          <th [hidden]="!listColumns['Código postal']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Código postal
          </th>
          <td [hidden]="!listColumns['Código postal']" mat-cell *matCellDef="let element">{{element.codigoPostal}}
          </td>
        </ng-container>

        <!--Columna Email -->
        <ng-container matColumnDef="mail-buscador">
          <th [hidden]="!listColumns['Correo electrónico']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Mail" formControlName="mail" (keyup)="applyFilter($event,'mail', 'CONTAINS_STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mail">
          <th [hidden]="!listColumns['Correo electrónico']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Correo electrónico
          </th>
          <td [hidden]="!listColumns['Correo electrónico']" mat-cell *matCellDef="let element" class="py-2">
            <p class="m-0">{{element.mail[0]}}</p>
          </td>
        </ng-container>

        <!-- Columna teléfono -->
        <ng-container matColumnDef="telefono-buscador">
          <th [hidden]="!listColumns['Teléfono']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Telefono" formControlName="telefono"
                    (keyup)="applyFilter($event,'telefono', 'CONTAINS_STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="telefono">
          <th mat-sort-header [hidden]="!listColumns['Teléfono']" mat-header-cell *matHeaderCellDef
            sortActionDescription="Sort by modelo" class="header">
            Teléfono
          </th>
          <td [hidden]="!listColumns['Teléfono']" mat-cell *matCellDef="let element" class="py-2">
            <p class="m-0">{{element.telefono[0]}}</p>
          </td>
        </ng-container>

        <!-- Columna Cuenta bancaria -->
        <ng-container matColumnDef="cuentaBancaria-buscador">
          <th [hidden]="!listColumns['Cuenta bancaria']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Cuenta bancaria" formControlName="cuentaBancaria"
                    (keyup)="applyFilter($event,'cuentaBancaria', 'CONTAINS_STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="cuentaBancaria">
          <th [hidden]="!listColumns['Cuenta bancaria']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Cuenta bancaria
          </th>
          <td [hidden]="!listColumns['Cuenta bancaria']" mat-cell *matCellDef="let element" class="py-2">
            <p class="m-0">{{element.cuentaBancaria[0]}}</p>
          </td>
        </ng-container>

        <!-- Columna Salario fijo -->
        <ng-container matColumnDef="salarioFijo-buscador">
          <th [hidden]="!listColumns['Salario fijo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Salario fijo" formControlName="salario_fijo"
                    (keyup)="applyFilter($event,'salario_fijo', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="salario_fijo">
          <th [hidden]="!listColumns['Salario fijo']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Salario fijo
          </th>
          <td [hidden]="!listColumns['Salario fijo']" mat-cell *matCellDef="let element" class="py-2">
            <p class="m-0">{{element.salario_fijo}}</p>
          </td>
        </ng-container>

        <!-- Columna Retención-->
        <ng-container matColumnDef="retencion-buscador">
          <th [hidden]="!listColumns['Retención']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Retencion" formControlName="retencion"
                    (keyup)="applyFilter($event,'retencion', 'BIG_DECIMAL')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="retencion">
          <th [hidden]="!listColumns['Retención']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Retención
          </th>
          <td [hidden]="!listColumns['Retención']" mat-cell *matCellDef="let element">{{element.retencion}}<span
              *ngIf="element.retencion != null"> %</span></td>
        </ng-container>

        <!-- Columna Porcentaje-->
        <ng-container matColumnDef="porcentaje-buscador">
          <th [hidden]="!listColumns['Porcentaje']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Porcentaje" formControlName="porcentaje"
                    (keyup)="applyFilter($event,'porcentaje', 'LONG')" placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="porcentaje">
          <th [hidden]="!listColumns['Porcentaje']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by porcentaje" class="header">
            Porcentaje
          </th>
          <td [hidden]="!listColumns['Porcentaje']" mat-cell *matCellDef="let element">{{element.porcentaje}}<span
              *ngIf="element.porcentaje != null"> %</span></td>
        </ng-container>

        <!-- Columna Puntuación-->
        <ng-container matColumnDef="puntuacion-buscador">
          <th [hidden]="!listColumns['Puntuación']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Puntuacion" formControlName="puntuacion"
                    (keyup)="applyFilter($event,'puntuacion', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="puntuacion">
          <th [hidden]="!listColumns['Puntuación']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by puntuacion" class="header">
            Puntuación
          </th>
          <td [hidden]="!listColumns['Puntuación']" mat-cell *matCellDef="let element">{{element.puntuacion}}</td>
        </ng-container>

        <!-- Columna Valoración-->
        <ng-container matColumnDef="valoracion-buscador">
          <th [hidden]="!listColumns['Valoración']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Valoración" formControlName="valoracion"
                    (keyup)="applyFilter($event,'valoracion', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="valoracion">
          <th [hidden]="!listColumns['Valoración']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by valoracion" class="header">
            Valoración
          </th>
          <td [hidden]="!listColumns['Valoración']" mat-cell *matCellDef="let element">{{element.valoracion}}</td>
        </ng-container>

        <!-- Columna LOPD -->
        <ng-container matColumnDef="lopd-buscador">
          <th [hidden]="!listColumns['LOPD']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setLopdActivoInactivo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setLopdActivoInactivo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>

            <!--<fieldset style="display: flex; justify-content: space-around; text-align: center;">
              <div>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                  id="activo" name="activo" style="vertical-align: middle;" checked
                  (change)="setLopdActivoInactivo($event, 'activo')">
                <label for="activo" style="vertical-align: middle; margin: 0 5px">Activo</label>
              </div>

              <div>
                <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                  id="inactivo" name="inactivo" style="vertical-align: middle;" checked
                  (change)="setLopdActivoInactivo($event, 'inactivo')">
                <label for="inactivo" style="vertical-align: middle; margin: 0 5px">Inactivo</label>
              </div>
            </fieldset>-->
          </th>
        </ng-container>
        <ng-container matColumnDef="lopd">
          <th [hidden]="!listColumns['LOPD']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by lopd" class="header">
            LOPD
          </th>
          <td [hidden]="!listColumns['LOPD']" mat-cell *matCellDef="let element">
            <p *ngIf="element.lopd; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Riesgos Laborales -->
        <ng-container matColumnDef="riesgosLaborales-buscador">
          <th [hidden]="!listColumns['Riesgos laborales']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setRiesgosLaboralesActivo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setRiesgosLaboralesActivo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="riesgosLaborales">
          <th [hidden]="!listColumns['Riesgos laborales']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by riesgos laborales" class="header">
            Riesgos laborales
          </th>
          <td [hidden]="!listColumns['Riesgos laborales']" mat-cell *matCellDef="let element">
            <p *ngIf="element.riesgosLaborales; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Activo -->
        <ng-container matColumnDef="activo-buscador">
          <th [hidden]="!listColumns['Activo']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="fechaBaja">
          <th mat-header-cell [hidden]="!listColumns['Activo']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by activo" class="header">
            Activo
          </th>
          <td mat-cell [hidden]="!listColumns['Activo']" *matCellDef="let row">
            <p *ngIf="row.fechaBaja == null; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Acciones -->
        <ng-container matColumnDef="acciones-buscador" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
        </ng-container>

        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="header"></th>
          <td mat-cell *matCellDef="let element" class="mat-column acciones">
            <div class="buttons-flex-container">
              <a class="eye-button activated" (click)="setMostrarFormulario(true, element, true)"></a>
              <a (click)="downloadFiles(element.id)" *ngIf="!isDownloadFiles" class="download-button activated"></a>
              <a (click)="downloadFiles(element.id)" *ngIf="isDownloadFiles" class="download-button not-activated"></a>
              <a class="delete-button activated" (click)="openDialogDesactivarConductor(element.id)"></a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>
    <mat-paginator class="mt-3" #paginator (page)="nextPage($event)" [length]="totalElements" [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex">
    </mat-paginator>

  </div>
</div>
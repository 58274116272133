<div class="d-flex flex-row justify-content-between align-items-start mt-2">
    <h1 class="titulo-formulario">Histórico de vehículo</h1>

    <button class="btn btn-primary btn-sm align-middle" (click)="setOcultarHistorico()">
        <mat-icon class="align-middle mr-2">edit_note</mat-icon><span class="align-middle">Mostrar formulario</span>
    </button>

</div>

<div>
    <form>

        <div class="row mt-4 mb-2">
            <div class="col-sm-2">
                <label for="vehiculo">Matrícula</label>
            </div>
            <div class="col">
                <label for="vehiculo">Modelo</label>
            </div>
            <div class="col">
                <label for="vehiculo">Licencia</label>
            </div>
            <div class=" col">
                <label for="vehiculo">Fecha de baja</label>
            </div>
            <div class=" col">
                <label for="vehiculo">Fecha desde</label>
            </div>
            <div class=" col">
                <label for="vehiculo">Fecha hasta</label>
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let historico of vehiculoLicenciaHistorico">
            <div class="col-sm-2">
                <input disabled class="form-control" type="text" id="matricula"
                    placeholder="{{historico?.vehiculo?.matricula}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni"
                    placeholder="{{historico?.vehiculo?.modelo?.marca?.nombre ?? ''}} {{historico?.vehiculo?.modelo?.nombre ?? ''}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico?.licencia?.numero_licencia ?? ''}}">
            </div>
            <!-- <div class="col">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(historico?.vehiculo?.fechaAlta)">
            </div> -->
            <div class="col">
                <input disabled class="form-control" type="date" id="baja"
                    [value]="formatDate(historico?.vehiculo.fechaBaja)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="desde" [value]="formatDate(historico?.fechaDesde)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="hasta" [value]="formatDate(historico?.fechaHasta)">
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let vehiculo of vehiculoHistorico">
            <div class="col-sm-2">
                <input disabled class="form-control" type="text" id="propietario" placeholder="{{vehiculo.matricula}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni"
                    placeholder="{{vehiculo.modelo?.marca?.nombre ?? ''}} {{vehiculo.modelo?.nombre ?? ''}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni"
                    placeholder="{{vehiculo .licencia?.numero_licencia ?? ''}}">
            </div>
            <!-- <div class="col">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(vehiculo.fechaAlta)">
            </div> -->
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(vehiculo.fechaBaja)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="">
            </div>
        </div>
    </form>
    <hr>
</div>
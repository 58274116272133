<!-- ------------------------------- Botones para la búsqueda ----------------------- -->
<!--<div class="row">
    <div class="col-2">
        <label>Desde: </label>
        <input [(ngModel)]="fecha" class="form-control ng-pristine ng-invalid ng-touched" type="date">
    </div>

    <div class="col-md">
        <button class="btn btn-success align-middle" style="margin-top: 2rem; width: 10%;"
            (click)="searchIngresosCaja()">
            <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
        </button>
    </div>
</div>-->

<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Ingresos a caja</h1>
    <div>
        <button class="btn btn-outline-primary align-middle" *ngIf="isSearch">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle" (click)="generarInforme()">Generar informe</span> 
        </button>
    </div>
</div>
  
<hr class="linea-divisoria">
  
<!-- Detalles/Gráficos -->
<app-card-detalles-caja #detalles [fecha]="fecha" [tipoDetalle]="tipoDetalle"></app-card-detalles-caja>
<!---->

<!-- ------------------------------- Acciones ----------------------- -->
<!--<div class="row" style="margin-top: 2rem;" *ngIf="isSearch">
    <div class="col-2">
        <button class="btn btn-success align-middle" style="width: 100%;">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Generar informes</span>
        </button>
    </div>

    <div class="col-3">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
    </div>
</div>-->

<!-- ------------------------------- Tabla ----------------------- -->
<div class="px-5 pb-4 contenedor mb-5 pt-4">
    <div class="d-flex flex-row align-items-center">
        <div class="row align-items-center flex-grow-1 crear-editar-form">
            <div class="col-sm-2">
                <label>Día</label>
                <input _ngcontent-vka-c280="" [(ngModel)]="fecha" class="form-control form-control-sm ng-pristine ng-invalid ng-touched"
                    type="date">
            </div>        
            <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="searchIngresosCaja(); detalles.getTotalIngresosCaja(fecha)">
                <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
            </button>
        </div>
        <div class="btn-group btn-group-sm align-self-start" role="group">
            <app-multi-select-dropdown [listColumns]="listColumns">
            </app-multi-select-dropdown>
        </div>       
    </div>
<div class="table_container mt-5" *ngIf="isSearch">

    <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white">

        <!-- ------------------------------- Columna fecha Registro ----------------------- -->
        <ng-container matColumnDef="fecha_buscador">
            <th [hidden]="!listColumns['Fecha registro']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Registro" formControlName="fechaRegistro" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control"> 
                  </span>
              </div> 
              </form>
            </th>
          </ng-container>

        <ng-container matColumnDef="fechaRegistro">
            <th mat-header-cell [hidden]="!listColumns['Fecha registro']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaOperacion" class="header">
                Fecha registro
            </th>
            <td mat-cell [hidden]="!listColumns['Fecha registro']" *matCellDef="let element">{{element.fechaAlta}}</td>
        </ng-container>

        <!-- ------------------------------- Columna importe ----------------------- -->
        <ng-container matColumnDef="importe_buscador">
            <th [hidden]="!listColumns['Importe']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Importe" formControlName="importe" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control"> 
                  </span>
              </div> 
              </form>
            </th>
          </ng-container>

        <ng-container matColumnDef="importe">
            <th mat-header-cell [hidden]="!listColumns['Importe']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                Importe
            </th>
            <td mat-cell [hidden]="!listColumns['Importe']" *matCellDef="let element">{{element.importe  | numberFormat}}</td>
        </ng-container>

        <!-- ------------------------------- Columna concepto ----------------------- -->
        <ng-container matColumnDef="concepto_buscador">
            <th [hidden]="!listColumns['Concepto']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Concepto" formControlName="concepto" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control"> 
                  </span>
              </div> 
              </form>
            </th>
          </ng-container>

        <ng-container matColumnDef="concepto">
            <th mat-header-cell [hidden]="!listColumns['Concepto']" *matHeaderCellDef class="header">
                Concepto
            </th>
            <td mat-cell [hidden]="!listColumns['Concepto']" *matCellDef="let element">{{element.concepto}}</td>
        </ng-container>

        <!-- ------------------------------- Columna usuario ----------------------- -->
        <ng-container matColumnDef="usuario_buscador">
            <th [hidden]="!listColumns['Usuario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
              <form [formGroup]="searchForm" class="search__form">
                <div class="d-flex flex-row align-items-center bg-white rounded">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <mat-icon class="align-middle pl-1">search</mat-icon>
                  </div>
                  <span>
                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Usuario" formControlName="usuario" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control"> 
                  </span>
              </div> 
              </form>
            </th>
          </ng-container>

        <ng-container matColumnDef="usuario">
            <th mat-header-cell [hidden]="!listColumns['Usuario']" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by fechaAlta" class="header">
                Usuario
            </th>
            <td mat-cell [hidden]="!listColumns['Usuario']" *matCellDef="let element">{{element.usuario?.nombre}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>
</div>

<mat-paginator class="mt-3" *ngIf="isSearch" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
    aria-label="Select page" #paginator>
</mat-paginator>
</div>
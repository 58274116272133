<div class="crear-editar-form">

    <div class="d-flex flex-row justify-content-between align-items-center">
        <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crear un nuevo propietario</h1>
        <!-- <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita un propietario</h1> -->
    </div>

    <app-historico-propietario *ngIf="mostrarHistorico" (mostrarHistorico)="setMostrarHistorico($event)"
        [propietarioHistorico]="propietarioHistorico"
        [propietarioLicenciaHistorico]="propietarioLicenciaHistorico"></app-historico-propietario>

    <div *ngIf="!mostrarHistorico">
        <div class="d-flex flex-row justify-content-between align-items-start mt-2" *ngIf="data.isForUpdate">
            <h1 class="titulo-formulario">Edita un propietario</h1>

            <button class="btn btn-primary btn-sm align-middle" (click)="setMostrarHistorico(true)">
                <mat-icon class="align-middle mr-2">access_time</mat-icon><span class="align-middle">Mostrar
                    histórico</span>
            </button>
        </div>

        <h2 class="divisiones-formularios">Datos</h2>
        <hr>

        <div class="row">
            <div class="form-group col-sm-4">
                <label>Fecha alta</label>
                <input *ngIf="!data.isForUpdate" [disabled]="propietario.fechaBaja != null" type="date"
                    class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'" (change)="getValue($event)">
                <input *ngIf="data.isForUpdate" [disabled]="data.isForUpdate ? true : false" type="date"
                    class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'" (change)="getValue($event)">
            </div>
        </div>

        <div class="row mt-2 mb-4">
            <div class="form-group col-sm-4">
                <label>Nombre</label>
                <input [disabled]="propietario.fechaBaja != null" type="text" class="form-control"
                    [(ngModel)]="propietario.nombre" placeholder="{{propietario.nombre}}">
            </div>
            <div class="form-group col-sm-4">
                <label>Apellidos</label>
                <input [disabled]="propietario.fechaBaja != null" type="text" class="form-control"
                    [(ngModel)]="propietario.apellidos" placeholder="{{propietario.apellidos}}">
            </div>
            <div class="form-group col-sm-1">
                <label>Tipo<span style="color: red;">*</span></label>
                <select [disabled]="propietario.fechaBaja != null" class="form-control form-select"
                    [(ngModel)]="selectedTipoDocumento" name="tipoDocumento">
                    <option value=null>Selecciona un tipo de documento</option>
                    <option *ngFor="let tipoDocumento of tipoDocumentoList" [value]="tipoDocumento.id">
                        {{tipoDocumento.nombre}}
                    </option>
                </select>
            </div>
            <div class="form-group col-sm-3">
                <label>Número de documento<span style="color: red;">*</span></label>
                <input [disabled]="propietario.fechaBaja != null" maxlength="9" minlength="9" type="text"
                    class="form-control" [(ngModel)]="propietario.numero_documento"
                    placeholder="{{propietario.numero_documento}}">
            </div>
        </div>

        <h2 class="divisiones-formularios">Contacto</h2>
        <hr>

        <div class="row mb-2">

            <div class="col-sm-4">
                <div *ngFor="let email of emails; let i = index">
                    <label>Email {{ emails.length > 1 ? i+1 : ''}}</label>
                    <div class="input-group">
                        <input [disabled]="propietario.fechaBaja != null" class="form-control" type="text"
                            [(ngModel)]="email.value" #name="ngModel" name="value{{i}}">
                        <div class="input-group-append">
                            <button [hidden]="propietario.fechaBaja != null" type="button"
                                class="btn btn-outline-primary-input" *ngIf="i > 0"
                                (click)="removeEmail(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>
                </div>
                <button [hidden]="propietario.fechaBaja != null" type="button"
                    class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
                    (click)="addEmail()"><mat-icon>add</mat-icon>Añadir email</button>
            </div>

            <div class="col-sm-4">
                <div *ngFor="let telefono of telefonos; let i = index">
                    <label>Teléfono {{ telefonos.length > 1 ? i+1 : ''}}</label>
                    <div class="input-group">
                        <input [disabled]="propietario.fechaBaja != null" minlength="9" maxlength="9"
                            class="form-control" type="number" [(ngModel)]="telefono.value" #name="ngModel"
                            name="value{{i}}">
                        <div class="input-group-append">
                            <button [hidden]="propietario.fechaBaja != null" type="button"
                                class="btn btn-outline-primary-input" *ngIf="i > 0"
                                (click)="removePhone(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>
                </div>
                <button [hidden]="propietario.fechaBaja != null" type="button"
                    class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
                    (click)="addPhone()"><mat-icon>add</mat-icon>Añadir teléfono</button>
            </div>

            <div class="col-sm-4">
                <div *ngFor="let iban of ibanList; let i = index">
                    <label>IBAN {{ ibanList.length > 1 ? i+1 :
                        ''}}</label>
                    <div class="input-group">
                        <input [disabled]="propietario.fechaBaja != null" class="form-control" type="text"
                            [(ngModel)]="iban.value" #name="ngModel" name="value{{i}}">
                        <div class="input-group-append">
                            <button [hidden]="propietario.fechaBaja != null" type="button"
                                class="btn btn-outline-primary-input" *ngIf="i > 0"
                                (click)="removeIban(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>
                </div>
                <button [hidden]="propietario.fechaBaja != null" type="button"
                    class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
                    (click)="addIban()"><mat-icon>add</mat-icon>Añadir cuenta</button>
            </div>
        </div>

        <div class="row mb-4 mt-4">
            <div class="form-group col-sm-4">
                <label>Gestoría</label>
                <select [disabled]="propietario.fechaBaja != null" class="form-control form-select"
                    [(ngModel)]="selectedValueGestoria" name="gestoria">
                    <option value=0>Selecciona una gestoría</option>
                    <option *ngFor="let gestoria of gestorias" [value]="gestoria.nombre">
                        {{gestoria.nombre}}
                    </option>
                </select>
            </div>
        </div>

        <h2 class="divisiones-formularios">Licencias</h2>
        <hr>

        <div class="row mb-4">
            <div class="form-group col-sm-4">
                <div *ngFor="let licenciaArray of licenciasAsociadas; let i = index">
                    <label>Licencia</label>
                    <div class="input-group">
                        <select [disabled]="propietario.fechaBaja != null" class="form-control form-select"
                            name="licencia" (change)="addSelectedLicencia($event, i)">
                            <option [value]=null>Selecciona una licencia</option>
                            <option *ngFor="let licencia of licencias" [value]="licencia.numero_licencia"
                                [selected]="licenciaArray.value == licencia.numero_licencia ? 'selected': ''">
                                {{ licencia.numero_licencia }}
                            </option>
                        </select>
                        <div class="input-group-append">
                            <button [hidden]="propietario.fechaBaja != null" type="button"
                                class="btn btn-outline-primary-input" *ngIf="i > 0"
                                (click)="removeLicencia(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>
                </div>
                <button [hidden]="propietario.fechaBaja != null" type="button"
                    class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
                    (click)="addLicencia()"><mat-icon>add</mat-icon>Añadir licencia</button>
            </div>
        </div>

        <h2 class="divisiones-formularios">Dirección</h2>
        <hr>

        <div class="row">
            <div class="form-group col-sm-4">
                <label>Domicilio</label>
                <input [disabled]="propietario.fechaBaja != null" type="text" class="form-control"
                    [(ngModel)]="propietario.domicilio" placeholder="{{propietario.domicilio}}">
            </div>
        </div>

        <div class="row mb-4">
            <div class="form-group col-sm-4">
                <label>Provincia</label>
                <select [disabled]="propietario.fechaBaja != null" class="form-control form-select"
                    [(ngModel)]="selectedValueProvincia" name="provincia"
                    (change)="cargaMunicipios(selectedValueProvincia)">
                    <option value=0>Selecciona una provincia</option>
                    <option *ngFor="let provincia of provincias" [value]="provincia.id">
                        {{provincia.nombre}}
                    </option>
                </select>
            </div>
            <div class="form-group col-sm-4">
                <label for="provincia">Municipio</label>
                <select [disabled]="propietario.fechaBaja != null" [(ngModel)]='selectedValueMunicipio'
                    class="form-control form-select">
                    <option [value]="0">Selecciona un municipio</option>
                    <option [value]="municipio.nombre" *ngFor="let municipio of municipiosToSelect">{{municipio.nombre}}
                    </option>
                </select>
            </div>
            <div class="form-group col-sm-4">
                <label>Código postal</label>
                <input [disabled]="propietario.fechaBaja != null" pattern="\d*" maxlength="5" type="text"
                    class="form-control" [(ngModel)]="propietario.codigo_postal"
                    placeholder="{{propietario.codigo_postal}}">
            </div>
        </div>

        <h2 class="divisiones-formularios h2-margin">Observaciones</h2>
        <hr>

        <div class="row">
            <div class="form-group col">
                <textarea [disabled]="propietario.fechaBaja != null" class="form-control" id="observaciones"
                    [(ngModel)]="propietario.observaciones" rows="3"
                    placeholder="{{propietario.observaciones}}"></textarea>
            </div>
        </div>

        <h2 class="divisiones-formularios">Documentos</h2>
        <hr>

        <br>
        <h6 class="col-sm-12 tituloAdjuntar">Adjuntar Ficheros</h6>

        <div class="col-sm-12 d-flex" style="padding: 0;">

            <!-- Parte adjuntar -->
            <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-3 p-3" appDrag
                (files)="filesDropped($event)">

                <div class="text-center ">
                    <h6 class="text_bold mt-1">Arrastre y suelte aqui</h6>
                    <h6 class="text">o</h6>
                    <button class="btn btn-light btn_file">Seleccionar Archivos</button>
                    <input id="file" value="file" name="file" class="input_file" (change)="onFileChange($event)"
                        onclick="event.target.value=null" type="file" />
                    <h6 class="indicaciones mt-1">PDF, JPEG o PNG de 20MB máximo. </h6>
                </div>
            </div>

            <!-- Parte documentos -->
            <div class="col-8 ordenar-archivos">
                <div *ngFor="let documento of this.arrayDocuments" class="card card-generic archivos-seleccionados">
                    <a class="cortar-texto">{{documento['nombre']}}</a>
                    <!-- <a *ngIf="!editar" class="cortar-texto">{{documento['nombre']}}</a> -->
                    <div class="card border-0 card-width-actions">
                        <div>
                            <a (click)="removeFile(arrayDocuments.indexOf(documento))"
                                class="delete-button activated boton-eliminar"></a>
                            <a *ngIf="data.isForUpdate" (click)="downloadDocument(documento)"
                                class="download-button activated boton-eliminar"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="container" class="d-flex justify-content-end pt-3">

            <!-- <button *ngIf="propietario.fechaBaja == null" class="btn btn-success mx-3"
                (click)="openDialogConfirmarUpdate()">Guardar</button> -->

            <button [hidden]="data.isForUpdate && propietario?.fechaBaja != null" class="btn btn-success mx-3"
                (click)="openDialogConfirmarUpdate()">
                <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
                <div *ngIf="!uploadDocuments">Guardar</div>
            </button>

            <button class="btn btn-warning mx-3" tabindex="-1" type="button" (click)="openDialogConfirmarActivate()"
                *ngIf="propietario.fechaBaja != null">Activar</button>
            <button class="btn btn-secondary" (click)="openDialogConfirmarUnsaved()">Cerrar</button>
        </div>
    </div>
</div>
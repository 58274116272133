<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Ranking conductores</h1>
    <div>
        <button class="btn btn-outline-primary align-middle" *ngIf="isSearch">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar informe</span> 
        </button>
    </div>
  </div>
  
  <hr class="linea-divisoria">

<div class="px-5 pb-4 contenedor mb-5 pt-4">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <form [formGroup]="form" class="flex-grow-1 crear-editar-form">
            <div class="row align-items-center">
                <div class="col-sm-2">
                    <label>Desde</label>
                    <input _ngcontent-vka-c280="" formControlName="fechaInicio" class="form-control form-control-sm ng-pristine ng-invalid ng-touched"
                        type="date">
                </div>
                <div class="col-sm-2">
                    <label>Hasta</label>
                    <input _ngcontent-vka-c280="" formControlName="fechaFin" class="form-control form-control-sm ng-pristine ng-invalid ng-touched"
                        type="date">
                </div>
                <button class="btn btn-primary btn-sm align-self-end ml-3" type="submit" (click)="searchRanking()">
                    <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
                </button>  
            </div>
        </form>
        <div class="btn-group btn-group-sm align-self-start" role="group">
            <app-multi-select-dropdown [listColumns]="listColumns">
            </app-multi-select-dropdown>
        </div>            
    </div>

    <div class="table_container mt-4" *ngIf="isSearch">

        <table mat-table [dataSource]="dataSource" matSort
        class="table align-middle mb-0 bg-white" matSortActive="importeDia" matSortDirection="desc">
    
        <ng-container matColumnDef="posicion">
            <th [hidden]="!listColumns['Posición']" mat-header-cell *matHeaderCellDef class="header">Posición</th>
            <td [hidden]="!listColumns['Posición']" mat-cell *matCellDef="let index = index">{{index + 1}}</td>
        </ng-container>
    
            <ng-container matColumnDef="licencia">
                <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>Licencia</th>
                <td [hidden]="!listColumns['Licencia']" mat-cell *matCellDef="let element">{{element.licencia?.numero_licencia}}</td>
            </ng-container>
    
            <ng-container matColumnDef="conductor.nombre">
                <th [hidden]="!listColumns['Conductor']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>Conductor</th>
                <td [hidden]="!listColumns['Conductor']" mat-cell *matCellDef="let element">{{element.conductor?.nombre}} {{element.conductor?.apellidos}}</td>
            </ng-container>
    
            <ng-container matColumnDef="diasTrabajados">
                <th [hidden]="!listColumns['Días trabajados']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Días trabajados</th>
                <td [hidden]="!listColumns['Días trabajados']" mat-cell *matCellDef="let element">{{element.diasTrabajados}}</td>
            </ng-container>
    
            <ng-container matColumnDef="importeTotal">
                <th [hidden]="!listColumns['Importe total']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Importe total</th>
                <td [hidden]="!listColumns['Importe total']" mat-cell *matCellDef="let element">{{element.importeTotal}}</td>
            </ng-container>
    
            <ng-container matColumnDef="importeDia">
                <th [hidden]="!listColumns['Importe por día']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Importe por día</th>
                <td [hidden]="!listColumns['Importe por día']" mat-cell *matCellDef="let element">{{element.importeDia}}</td>
            </ng-container>
    
            <ng-container matColumnDef="kmTotal">
                <th [hidden]="!listColumns['Km. totales']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Km. totales</th>
                <td [hidden]="!listColumns['Km. totales']" mat-cell *matCellDef="let element">{{element.kmTotal}}</td>
            </ng-container>
    
            <ng-container matColumnDef="kmDia">
                <th [hidden]="!listColumns['Km. por día']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Km. por día</th>
                <td [hidden]="!listColumns['Km. por día']" mat-cell *matCellDef="let element">{{element.kmDia}}</td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
    
        <!--<mat-paginator class="mt-3" #paginator
                (page)="handlePageEvent($event)" 
                [length]="length" 
                [pageSize]="pageSize"
                [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="pageIndex"
                aria-label="Select page" >
        </mat-paginator>-->
        
    </div> 
    <mat-paginator class="mt-3" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons *ngIf="isSearch">
    </mat-paginator>
</div>


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment.novotaxi';


@Injectable({
  providedIn: 'root'
})

export class UserService {

  API_URL: string = environment.API_URL;


  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(`${this.API_URL}/user/`, { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(`${this.API_URL}/user/` + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(`${this.API_URL}/user/` + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(`${this.API_URL}/` + 'admin', { responseType: 'text' });
  }

  getCurrentUserFromServer(): Observable<any> {
    return this.http.get(`${this.API_URL}/user/getCurrent`);
  }

  getCurrentUserRepresented(): Observable<any> {
    return this.http.get(`${this.API_URL}/user/getRepresented`);
  }
  sendRequestRepresented(idUser): Observable<any> {
    return this.http.get(`${this.API_URL}/user/setRepresented/` + idUser);
  }
  sendAnswerRepresented(accept, idUser): Observable<any> {
    return this.http.post(`${this.API_URL}/user/answerRepresented/` + idUser, { "allow": accept });
  }

  searchUserByDocumemt(document): Observable<any> {
    return this.http.post(`${this.API_URL}/user/searchUserByDocument` , { "document": document });
  }
}

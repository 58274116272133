import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IVehiculo } from '../vehiculo.model';

@Component({
  selector: 'app-historico-vehiculo',
  templateUrl: './historico-vehiculo.component.html',
  styleUrls: ['./historico-vehiculo.component.css']
})
export class HistoricoVehiculoComponent {

  constructor(
    private datePipe: DatePipe,
  ) { }

  @Input() vehiculoHistorico: IVehiculo[];
  @Input() vehiculoLicenciaHistorico: any;
  @Output() mostrarHistorico = new EventEmitter<Boolean>();

  setOcultarHistorico() {
    this.mostrarHistorico.emit(false);
  }

  formatDate(date) {
    if (date) {
      const splitDate = date.split('-');

      const dateFormatted = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      date = this.datePipe.transform(dateFormatted, 'yyyy-MM-dd');
      return date;
    }

    return '';
  }
}

export class OperacionesConductor {
    id?: number;
    efectivo?: number;
    prestamo?: number;

    constructor() {
        this.id = null;
        this.efectivo = 0;
        this.prestamo = 0;
    }
}
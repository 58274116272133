import { Component, OnInit, Input } from '@angular/core';

import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.novotaxi';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //@Input() RolUsuario: RolUsuario; 

  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  usuario: any = [];
  miData: any = [];
  environment = environment;

  constructor(
    private tokenStorageService: TokenStorageService,
    private serviciogeneral: ServicioGeneral,
    private router: Router

  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }




  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {

      //this.getRolUsuario();  
      const user = this.tokenStorageService.getUser();

      //console.log(user);

      this.usuario = user;
      // this.showAdminBoard = this.roles.includes('ROLE_ADMIN'); 
      //this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

  isVisible = true;

  toggleDisplay() {
    this.isVisible = !this.isVisible;
  }


  reload(){
    this.router.navigateByUrl('/login');
  }
  /*
    getRolUsuario() {
      return this.serviciogeneral.getRolUsuario().subscribe((data) => {
        this.miData = data;     
        //console.log(this.miData);
      });
    }
    */


}

import { Proveedor } from "./proveedor.model";

export class ConceptosProveedores {
    id?: number;
    fechaAlta?: Date | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;
    concepto?: string | null;
    iva?: number | null;
    proveedor: Proveedor | null

    constructor(mappedData) {
        this.id = mappedData?.id ?? null;
        this.fechaAlta = mappedData?.fechaAlta ?? null;
        this.fechaBaja = mappedData?.fechaBaja ?? null;
        this.fechaModificacion = mappedData?.fechaModificacion ?? null;
        this.concepto = mappedData?.concepto ?? null;
        this.iva = mappedData?.iva ?? null;
        this.proveedor = mappedData?.proveedor ?? null;
    }
}

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmacion',
  templateUrl: './modal-confirmacion.component.html'
})
export class ModalConfirmacionComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public modoDialog: String,
  ) { }

}

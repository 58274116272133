import { Licencia } from "../Emisora";
import { Documento } from "../documento";

export class Propietario {
    id?: number;
    fechaAlta?: Date;
    fechaModificacion?: string;
    fechaBaja?: string;
    tipo_documento?: Documento;
    numero_documento?: string;
    nombre?: string;
    apellidos?: string;
    domicilio?: string;
    codigo_postal?: string;
    provincia?: IProvincia;
    poblacion?: string;
    telefonos?: string[];
    emails?: string[];
    iban?: string[];
    gestoria?: Gestoria;
    isActive?: boolean;
    licencias?: Licencia[];
    observaciones?: string;

    constructor(mappedData) {
        this.id = mappedData?.id;
        this.fechaAlta = this.convertDatesPropietario(mappedData?.fechaAlta, 'fechaAlta');
        this.fechaModificacion = this.convertDatesPropietario(mappedData?.fechaModificacion, 'fechaMd');
        this.fechaBaja = mappedData?.fechaBaja;
        this.tipo_documento = mappedData?.tipo_documento;
        this.numero_documento = mappedData?.numero_documento;
        this.nombre = mappedData?.nombre;
        this.apellidos = mappedData?.apellidos;
        this.domicilio = mappedData?.domicilio;
        this.codigo_postal = mappedData?.codigo_postal;
        this.provincia = mappedData?.provincia;
        this.poblacion = mappedData?.poblacion;
        this.telefonos = mappedData?.telefonos;
        this.emails = mappedData?.emails;
        this.iban = mappedData?.iban;
        this.gestoria = mappedData?.gestoria;
        this.isActive = mappedData?.isActive;
        this.licencias = mappedData?.licencias;
        this.observaciones = mappedData?.observaciones;
    }

    convertDatesPropietario?(date, cambio) {
        let string: String = "";        

        if (date != null && typeof date == typeof string && date != '') {

            let splitDate = date.split('-');
            let dateSaved;

            if (splitDate[2].length > 2) {
                dateSaved = new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);
            } else {
                dateSaved = new Date(`${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`);
            }

            let miliseconds = dateSaved.getTime();
            date = miliseconds;

            return date;

        } else {
            if (date == null || date == '') {
                return null;
            } else {
                
                if (typeof date != 'number') {
                    date = new Date(date)
                    return date
                } else {
                    return date;
                }

            }
        }
    }
}

export interface Gestoria {
    id?: number;
    nombre?: string;
}

export interface IProvincia {
    id?: number;
    ccaa?: string;
    nombre?: string;
}
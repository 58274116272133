import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, retry, throwError } from "rxjs";
import { environment } from "src/environments/environment.novotaxi";

@Injectable({
  providedIn: "root",
})

export class ControlInformesService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          return new HttpResponse({
            body: event.body,
            status: event.status,
            headers: event.headers,
          });
        }
        return event;
      })
    );
  }

  API_URL: string = environment.API_URL;
  private resourceUrl: string = `${environment.API_URL}/controlInforme`;

  constructor(
    private http: HttpClient,
  ) { }

  saveInforme(controlInformes): Observable<any> {
    const url = `${this.resourceUrl}/save`;

    return this.http.post(url, controlInformes).pipe(
      retry(1),
      catchError((error) => {
        // Manejar errores aquí
        console.error("Ocurrió un error:", error);
        throw error; // Lanza el error para que sea manejado en el componente que hace la llamada
      })
    );
  }

  saveDocuments(id, documents) {
    return this.http.post(`${this.resourceUrl}/saveDocuments/${id}`, documents);
  }

  getControlInformes(request?) {
    return this.http
      .post(`${this.resourceUrl}/list`, request)
  }

  getDocument(id) {
    return this.http
      .get(`${this.resourceUrl}/getDocument/${id}`);
  }

  delete(id) {
    return this.http
      .get(`${this.resourceUrl}/delete/${id}`);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Licencia } from 'src/app/model/Emisora';
import { GastosProveedores } from 'src/app/model/novotaxi/gastos-proveedores/gastos-proveedores.model';
import { GastosProveedoresService } from 'src/app/_services/novotaxi/gastos/gastos-proveedores.service';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { IConductor } from 'src/app/conductores/conductor.model';
import { VehiculoService } from 'src/app/_services/vehiculo.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Proveedor } from 'src/app/model/novotaxi/proveedores/proveedor.model';
import { ProveedoresService } from 'src/app/_services/novotaxi/proveedores/proveedores.service';
import { ConceptosProveedores } from 'src/app/model/novotaxi/proveedores/concepto-proveedores.model';
import { FormControl, FormGroup } from '@angular/forms';
import { CardDetallesCajaComponent } from '../card-detalles-caja/card-detalles-caja.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';
import { ExcelExportService } from 'src/app/_services/excel-export.service';

@Component({
  selector: 'app-gastos-proveedores',
  templateUrl: './gastos-proveedores.component.html',
  styleUrls: ['./gastos-proveedores.component.css']
})
export class GastosProveedoresComponent implements OnInit {

  displayedColumns: string[] = ['fechaAlta', 'tipo', 'importe', 'proveedor.nombre', 'proveedor.cif', 'concepto.concepto', 'concepto.iva', 'numFactura', 'licencia.numero_licencia', 'conductor.nombre', 'conductor.apellidos', 'usuario.nombre', 'acciones'];

  displayedSearchHeaders: string[] = ['fecha_buscador', 'tipo_buscador', 'importe_buscador', 'nombreProveedor_buscador', 'cifProveedor_buscador', 'concepto_buscador', 'iva_buscador', 'numFactura_buscador', 'licencia_buscador', 'conductor_buscador', 'conductor_apellido_buscador', 'usuario_buscador', 'acciones_buscador'];

  gastosProveedoresList: GastosProveedores[] = [];
  tipoDetalle: string = 'gastosProveedores';
  listColumns;
  dataSource = new MatTableDataSource(this.gastosProveedoresList);

  // -- Variables de búsqueda
  licencias: Licencia[] = [];
  selectedValueLicencia: Licencia;
  currentDate: Date = new Date();
  fechainicio: string;
  fechafin: string;
  isSearch: boolean = false;
  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
    idLicencia: null,
  };

  // -- Variables para el control del usuario
  currentUser;
  userSaved: Usuario = null;

  // -- Variables filtrado select
  showDropDown = false;
  keys;
  allKeys;
  nameSearch: string;

  // -- Paginado
  length = this.gastosProveedoresList.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  // -- Filtrado por columnas
  public searchForm: FormGroup;
  public fecha = '';
  public tipo = '';
  public importe = '';
  public nombreProveedor = '';
  public cif = '';
  public concepto = '';
  public iva = '';
  public numFactura = '';
  public licencia = '';
  public conductor = '';
  public usuario = '';

  // -- Variables optimización
  public filters = [];
  public staticFilters;
  public sorts = [];
  public totalElements = 0;

  // -- Variables component actualizar/crear
  mostrarFormulario: Boolean = false;
  conductores: IConductor[] = [];
  proveedores: Proveedor[] = [];
  conceptos: ConceptosProveedores[] = [];

  data: DataGastosProveedores = { mostrarFormulario: false, gastosProveedores: null, licencias: null, elementUpdate: null, isForUpdate: false }

  @Input() nombreMenu;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(CardDetallesCajaComponent) child;


  constructor(
    private _gastosProveedoresService: GastosProveedoresService,
    private _licenciaService: LicenciaServiece,
    private _vehiculoService: VehiculoService,
    private _proveedoresService: ProveedoresService,
    private _notificationService: NotificationService,
    private _tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
    this.getLicencias();
    this.getProveedores();
    // this.getConceptos();

    this.listColumns = {
      "Fecha": true,
      "Tipo": true,
      "Importe": true,
      "Nombre proveedor": true,
      "CIF proveedor": true,
      "Concepto": true,
      "IVA": true,
      "Nº factura": true,
      "Licencia": true,
      "Conductor": true,
      'Apellidos conductor': true,
      "Usuario": true,
    }

    this.fechainicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
    this.fechafin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.searchMap.fechaInicio = this.fechainicio;
    this.searchMap.fechaFinal = this.fechafin;

    this.setRequestForSearch();
    this.searchFormInit();
  }

  generarInforme() {
    let nombre = 'GastosProveedores';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  async setMostrarFormulario(mode: Boolean, isForUpdate?: Boolean, element?: GastosProveedores) {

    if (isForUpdate) {

      await this.getConceptos(element).then(() => {
        this._vehiculoService.getAllConductoresFromVehiculo(element.licencia.id).subscribe((data) => {
          this.mostrarFormulario = mode;
          this.conductores = data['conductores']
          this.data = { mostrarFormulario: mode, gastosProveedores: [], elementUpdate: element, isForUpdate: isForUpdate, licencias: this.licencias, conductores: this.conductores, proveedores: this.proveedores, conceptos: this.conceptos }
        })
      });

    } else {
      this.mostrarFormulario = mode;
      this.data = { mostrarFormulario: mode, gastosProveedores: [], elementUpdate: element, isForUpdate: isForUpdate, licencias: this.licencias, conductores: this.conductores, proveedores: this.proveedores }
    }

    if (mode) {
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
    }
  }

  async getConceptos(element) {
    this._proveedoresService.getConceptosByProveedor(element.proveedor.id).subscribe((result) => {
      this.conceptos = result['conceptos'];
    })
  }

  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    this.child.getTotalesGastosProveedores(this.searchMap);
    this.setRequestForSearch();
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe((data) => {
      this.licencias = data["licencias"];
      this.keys = this.licencias;
      this.allKeys = this.keys;
    })
  }

  getProveedores() {
    this._proveedoresService.getAllProveedores().subscribe((data) => {
      this.proveedores = data["proveedores"];
    })
  }

  isValidFieldsSearch() {
    if (this.fechainicio == undefined || this.fechafin == undefined /* || this.selectedValueLicencia == undefined */) {
      this._notificationService.warning("¡Atención!", "Debe rellenar las fechas para la búsqueda", 3000);
      return false;
    } else {
      if (this.fechainicio > this.fechafin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }

  async openDialogDelete(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'borrar'

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {

        if (element.id != null) {
          let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

          if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar un gasto a proveedor del día actual", 3000);
          } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar un gasto a proveedor del día actual", 3000);
          } else {
            this.deleteGastoProveedor(element);
          }
        }
      } else {
        null
      }
    })
  }

  deleteGastoProveedor(element) {
    this._gastosProveedoresService.delete(element.id).subscribe((result) => {
      if (result.body['status'] == '622') {
        this._notificationService.success('Hecho', 'Gasto eliminado con éxito', 3000);
        this.setRequestForSearch();
        this.child.getTotalesGastosProveedores(this.searchMap);
      }
    });
  }

  // -- Select con filtrado
  focusOut() {
    this.nameSearch = "";
    this.keys = this.allKeys;
  }

  filterColumns(event) {
    this.nameSearch = event.target.value;
    if (this.nameSearch && this.nameSearch != "") {
      this.keys = this.allKeys.filter(key => key.numero_licencia.includes(this.nameSearch.toLowerCase()));
    } else {
      this.keys = this.allKeys;
    }
  }

  getSelectedValue(value: Licencia) {
    this.selectedValueLicencia = value;
    this.showDropDown = false;
  }
  // -- Fin select con filtrado


  // -- Inicio optimzación
  searchFormInit() {
    this.searchForm = new FormGroup({
      fecha: new FormControl(null),
      tipo: new FormControl(''),
      importe: new FormControl(''),
      nombreProveedor: new FormControl(''),
      cif: new FormControl(''),
      concepto: new FormControl(''),
      iva: new FormControl(''),
      numFactura: new FormControl(''),
      licencia: new FormControl(''),
      conductor: new FormControl(''),
      usuario: new FormControl(''),
    });
  }

  setRequestForSearch(request?) {
    let requestSearchFields;

    this.searchMap = {
      fechaInicio: this.fechainicio,
      fechaFinal: this.fechafin,
      idLicencia: this.selectedValueLicencia ? this.selectedValueLicencia.id : null,
    };

    if (this.isValidFieldsSearch) {
      if (this.selectedValueLicencia != null) {
        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechainicio.toString().split("-").reverse().join("-"),
            value_to: this.fechafin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },
          {
            key: 'licencia.id',
            operator: "EQUAL",
            field_type: 'STRING',
            value: this.selectedValueLicencia?.id,
          },],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      } else if (this.selectedValueLicencia == null) {
        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechainicio.toString().split("-").reverse().join("-"),
            value_to: this.fechafin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      }
    }

    this.searchRecaudacion(requestSearchFields, request);
  }

  async searchRecaudacion(requestSearchFields?, request?) {
    let filtersToSend = [];

    if (request) {
      if (requestSearchFields) {
        filtersToSend = [...request['filters'], ...requestSearchFields['filters']];
      } else {
        filtersToSend = [...request['filters']];
      }
    } else {
      filtersToSend = [...requestSearchFields['filters']];
    }

    let requestToSend = {
      "filters": filtersToSend,
      "sorts": this.sorts,
      page: this.pageIndex,
      size: this.pageSize,
    }

    await this._gastosProveedoresService.getGastosProveedores(requestToSend).subscribe((data) => {
      this.gastosProveedoresList = [];
      this.totalElements = 0;

      if (data['gastosProveedores']) {

        if (data['gastosProveedores']['content']) {
          this.isSearch = true;
          for (const gastosProveedoresJSON of data['gastosProveedores']['content']) {
            this.gastosProveedoresList.push(new GastosProveedores(gastosProveedoresJSON));
          }
        } else {
          this._notificationService.info("INFO", "No se han encontrado datos", 3000);
          this.isSearch = false;
        }
        this.totalElements = data['gastosProveedores']['totalElements'];
      }
      this.dataSource = new MatTableDataSource(this.gastosProveedoresList);
    })
  }

  async applyFilter(event?, key?, type?) {
    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];

    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event?.target?.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {


    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type.toUpperCase() == 'TIPOS') {
        filter.operator = "TIPOS_GASTO_INGRESO"
      }

      if (type?.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type?.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }

      if (type?.toUpperCase() == "DATE_BETWEEN" || type?.toUpperCase() == "LOCALDATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type?.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type?.toUpperCase() == "DOUBLE" || type?.toUpperCase() == "LONG" || type?.toUpperCase() == "BIG_DECIMAL" || type?.toUpperCase() == "INTEGER") {
        filter.operator = "LIKE"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == 'ENUM_TIPO_GESTION') {
        filter.operator = "LIKE_TIPO_GESTION"
        filter.field_type = "STRING"
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,

    }

    this.paginator.firstPage();
    await this.setRequestForSearch(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setRequestForSearch(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }

      } else {
        if (sort.active == "tipo") {
          this.sorts = [{
            "key": 'tipo_gasto.nombre',
            "direction": direction
          },
          {
            "key": 'tipo_ingreso.nombre',
            "direction": direction
          }];

        } else {
          this.sorts = [{
            "key": sort.active,
            "direction": direction
          }];
        }

      }
      request['sorts'] = this.sorts;
    }

    await this.setRequestForSearch(request)
  }

  clearSearchInputs() {
    this.filters = [];
    this.searchForm.reset();
  }

  // -- Fin optimización

  // -- Exportación Excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, fechaBaja, fechaModificacion, tipo_cantidad, cantidad_propietario, cantidad_conductor, conductor, ...rest } = item;

    if (item.usuario) {
      rest.usuario = item.usuario.nombre;
    }

    if (item.licencia) {
      rest.licencia = item.licencia.numero_licencia;
    }

    if (item.concepto) {
      rest.concepto = item.concepto.concepto;
    }

    if (item.proveedor) {
      rest.proveedor = item.proveedor.nombre;
    }

    return rest;
  }

  generateExcel() {

    const data = this.dataSource.data.map((gasto) => {
      const formattedGasto = { ...gasto };
      formattedGasto.fechaAlta = this.formatDate(gasto.fechaAlta).toLocaleDateString();
      formattedGasto.fechaModificacion ? formattedGasto.fechaModificacion = this.formatDate(gasto.fechaModificacion) : null;
      return formattedGasto;
    });

    this.addRows(data);

    const dataFormatted = data.map((gasto) => {
      const formattedGasto = { ...gasto };
      formattedGasto.fechaAlta = this.formatDate(gasto.fechaAlta).toLocaleDateString();
      formattedGasto.fechaModificacion ? formattedGasto.fechaModificacion = this.formatDate(gasto.fechaModificacion) : null;
      return this.filterFields(formattedGasto);
    });

    const fileName = 'Tabla_gastos_proveedores';
    this._excelService.exportToExcel(dataFormatted, fileName);
  }

  addRows(data) {
    data.forEach((gasto) => {
      gasto.nombre_conductor = gasto.conductor.nombre;
      gasto.apellidos_conductor = gasto.conductor.apellidos;
      gasto.cif_proveedor = gasto.proveedor.cif;
      gasto.iva = gasto.concepto.iva;
    });
  }
}

export interface DataGastosProveedores {
  gastosProveedores?: GastosProveedores[];
  tipo?: string;
  elementUpdate?: GastosProveedores;
  licencias?: Licencia[];
  proveedores?: Proveedor[];
  isForUpdate?: Boolean;
  mostrarFormulario?: Boolean;
  conductores?: IConductor[];
  conceptos?: ConceptosProveedores[];
}
import { IConductor } from "src/app/conductores/conductor.model";
import { Licencia } from "../../Emisora";
import { Usuario } from "../gastos-ingresos/gastos-ingresos.model";
import { TipoGestion } from "./tipo-gestion.liquidacion.model";

export class IRetencionLiquidacion {
  id?: number;
  fechaAlta?: string | null;
  fechaBaja?: Date | null;
  fechaModificacion?: Date | null;
  importe?: number | null;
  gestion?: TipoGestion | null;
  numDocumento?: string | null;
  concepto?: string | null;
  usuario?: Usuario | null;
  conductor?: IConductor | null;
  licencia?: Licencia | null;
  tipo_liquidacion?: string | null;

  constructor(mappedData) {
    this.id = mappedData?.id ?? null;
    this.fechaAlta = mappedData?.fechaAlta ?? null;
    this.fechaBaja = mappedData?.fechaBaja ?? null;
    this.fechaModificacion = mappedData?.fechaModificacion ?? null;
    this.importe = mappedData?.importe ?? null;
    this.gestion = mappedData?.gestion ?? null;
    this.numDocumento = mappedData?.numDocumento ?? null;
    this.concepto = mappedData?.concepto ?? null;
    this.usuario = new Usuario(mappedData?.usuario ?? null);
    this.conductor = mappedData?.conductor ? new IConductor(mappedData?.conductor) : null;
    this.licencia = mappedData?.licencia != null ? new Licencia(mappedData?.licencia ?? null) : null;
    this.tipo_liquidacion = mappedData?.tipo_liquidacion ?? null;
  }
}

<div class="page__content">
    <div class="content__inner">
        <div class="content__login">
            <div class="card__login">
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                    <h1>¿Olvidaste la contraseña?</h1>

                    <div class="form-group">
                        <input type="email" class="form-control" name="email" [(ngModel)]="form.email" required email #email="ngModel" placeholder="Email" />
                        <div class="alert-danger" *ngIf="email.errors && f.submitted">
                            <div *ngIf="email.errors['required']">El email es requerido</div>
                            <div *ngIf="email.errors['email']">Escriba un email válido</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <button class="btn btn-primary btn-block" [disabled]="loading">Enviar</button>
                    </div>
                    <div class="form-group">
                        <a routerLink="../login" class="forgot-password">Cancelar</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Componente detalles -->
<div class="contenedor px-4 pt-3 pb-4 mb-5 mt-4" [hidden]="detallesVisible">
    <!-- <mat-tab-group animationDuration="1000ms" [selectedIndex]="0"> -->
    <!-- Detalle Licencias -->
    <!-- <mat-tab label="Detalles licencias" class="mb-4"> -->

    <div class="d-flex flex-row mt-4 ml-4">
        <h5 *ngIf="tipoDetalle == 'licencias'">Detalles licencias</h5>
        <h5 *ngIf="tipoDetalle == 'propietarios'">Detalles propietarios</h5>
        <h5 *ngIf="tipoDetalle == 'vehiculos'">Detalles vehículos</h5>
        <h5 *ngIf="tipoDetalle == 'conductores'">Detalles conductores</h5>
    </div>


    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'licencias'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Activas</span>
            <span class="descDetalle">{{infoLicencias.get('licenciasActivas')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Totales</span>
            <span class="descDetalle">{{infoLicencias.get('licenciasTotales')}}</span>
        </div>
        <!--  <div class="d-flex flex-column px-5 detallesLicencia">
                    <span>Total recaudación</span>
                    <span class="descDetalle">15.000€</span>
                </div> -->
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Fecha actual</span>
            <span class="descDetalle">{{date}}</span>
        </div>
    </div>
    <!-- </mat-tab> -->
    <!-- Detalles Propietarios -->
    <!-- <mat-tab label="Detalles propietarios" class="mb-4"> -->
    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'propietarios'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Activos</span>
            <span class="descDetalle">{{infoPropietarios.get('propietariosActivos')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Totales</span>
            <span class="descDetalle">{{infoPropietarios.get('propietariosTotales')}}</span>
        </div>
        <!-- <div class="d-flex flex-column px-5 detallesLicencia">
                    <span>Total recaudación</span>
                    <span class="descDetalle">15.000€</span>
                </div> -->
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Fecha actual</span>
            <span class="descDetalle">{{date}}</span>
        </div>
    </div>
    <!-- </mat-tab> -->
    <!-- Detalles Vehículos -->
    <!-- <mat-tab label="Detalles vehículos" class="mb-4"> -->
    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'vehiculos'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Activos</span>
            <span class="descDetalle">{{infoVehiculos.get('vehiculosActivos')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Totales</span>
            <span class="descDetalle">{{infoVehiculos.get('vehiculosTotales')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Marca más usada</span>
            <span class="descDetalle">{{infoVehiculos.get('marcaMasUsada')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Modelo más usado</span>
            <span class="descDetalle">{{infoVehiculos.get('modeloMasUsado')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Fecha actual</span>
            <span class="descDetalle">{{date}}</span>
        </div>
    </div>
    <!-- </mat-tab> -->
    <!-- Detalles Conductores -->
    <!-- <mat-tab label="Detalles conductores" class="mb-4"> -->
    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'conductores'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Activos</span>
            <span class="descDetalle">{{infoConductores.get('conductoresActivos')}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Totales</span>
            <span class="descDetalle">{{infoConductores.get('conductoresTotales')}}</span>
        </div>
        <!-- <div class="d-flex flex-column px-5 detallesLicencia">
                    <span>Total recaudación</span>
                    <span class="descDetalle">15.000€</span>
                </div> -->
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Fecha actual</span>
            <span class="descDetalle">{{date}}</span>
        </div>
    </div>
    <!-- </mat-tab> -->
    <!-- </mat-tab-group> -->

</div>
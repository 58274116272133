import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { DataGastosProveedores } from '../gastos-proveedores.component';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { Licencia } from 'src/app/model/Emisora';
import { Proveedor } from 'src/app/model/novotaxi/proveedores/proveedor.model';
import { ProveedoresService } from 'src/app/_services/novotaxi/proveedores/proveedores.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { GastosProveedores } from 'src/app/model/novotaxi/gastos-proveedores/gastos-proveedores.model';
import { GastosProveedoresService } from 'src/app/_services/novotaxi/gastos/gastos-proveedores.service';
import { DatePipe } from "@angular/common";
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { IConductor } from 'src/app/conductores/conductor.model';
import { ConceptosProveedores } from 'src/app/model/novotaxi/proveedores/concepto-proveedores.model';
import { TipoCantidad } from 'src/app/model/novotaxi/gastos-proveedores/tipo_cantidad.model';
import { Prestamos } from 'src/app/model/novotaxi/prestamos.model';
import { PrestamosService } from 'src/app/_services/novotaxi/prestamos/prestamos.service';

@Component({
  selector: 'app-create-update-gastos-proveedores',
  templateUrl: './create-update-gastos-proveedores.component.html',
  styleUrls: ['./create-update-gastos-proveedores.component.css']
})

export class CreateUpdateGastosProveedoresComponent implements OnInit {

  @Input() data: DataGastosProveedores;
  @Output() mostrarFormulario = new EventEmitter<DataGastosProveedores>();

  // -- Variables para el control del usuario
  currentUser;
  userSaved: Usuario = null;
  isUserAdmin: boolean = true;

  // -- Variables para el guardado de los gastos a proveedor
  fechaAlta: Date;
  selectedValueLicencia: number = null;
  selectedValueConductor: number = null;
  selectedValueProveedor: number = null;
  selectedValueConcepto: number = null;
  selectedValueTipoCantidad: string = '';
  licencias: Licencia[] = [];
  conductores: IConductor[] = [];
  conceptos: ConceptosProveedores[] = [];
  proveedores: Proveedor[] = [];
  gastoProveedorCreate: GastosProveedores = {};
  gastoProveedorUpdate: GastosProveedores = {};
  gastoProveedorList: GastosProveedores[] = [];
  iva;

  gastosList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numFactura: null, proveedor: null, tipo_cantidad: null, cantidad_propietario: null, cantidad_conductor: null, posiblesConductores: null, posiblesConceptos: null, importe_iva: null }];

  constructor(
    private _notificationService: NotificationService,
    private _conductorService: ConductorService,
    private _proveedorService: ProveedoresService,
    private _gastosProveedor: GastosProveedoresService,
    private _tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private _prestamosService: PrestamosService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
    this.getConductores();
    this.getConceptos();

    if (!this.data.licencias) {
      this._notificationService.info("INFO", `No existen licencias disponibles para crear un gasto a proveedor`, 3000);
    }

    if (!this.data.proveedores) {
      this._notificationService.info("INFO", `No existen proveedores disponibles para crear un gasto a proveedor`, 3000);
    }

    if (this.data.isForUpdate) {
      this.getDataForUpdate();
    }

    this.licencias = this.data.licencias;
    this.proveedores = this.data.proveedores;
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  getConductores() {
    this._conductorService.query().subscribe((res) => {
      this.conductores = res['conductores']
    });
  }

  getConceptos() {
    this._proveedorService.getAllConceptosProveedores().subscribe((data) => {
      this.conceptos = data['conceptosProveedores'];
    })
  }

  getDataForUpdate() {
    let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

    if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && this.data.elementUpdate.fechaAlta != currentDate) {
      this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un gasto a proveedor del día actual", 3000);
      this.isUserAdmin = false;
    } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && this.data.elementUpdate.fechaAlta != currentDate) {
      this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un gasto a proveedor del día actual", 3000);
      this.isUserAdmin = false;
    } else {
      this.isUserAdmin = true;
    }

    this.gastosList = [{
      fechaAlta: this.data.elementUpdate.fechaAlta,
      licencia: this.data.elementUpdate.licencia,
      conductor: this.data.elementUpdate.conductor,
      importe: this.data.elementUpdate.importe,
      concepto: this.data.elementUpdate.concepto,
      numFactura: this.data.elementUpdate.numFactura,
      proveedor: this.data.elementUpdate.proveedor,
      posiblesConductores: this.data.conductores,
      posiblesConceptos: this.data.conceptos,
      tipo_cantidad: this.data.elementUpdate.tipo_cantidad.toLowerCase(),
      cantidad_conductor: this.data.elementUpdate.cantidad_conductor,
      cantidad_propietario: this.data.elementUpdate.cantidad_propietario,
      importe_iva: this.data.elementUpdate.importe_iva,
    }];

    this.iva = this.data.elementUpdate?.concepto?.iva;

    this.fechaAlta = new Date(this.data.elementUpdate.fechaAlta.toString().split("-").reverse().join("-"));
    this.selectedValueLicencia = this.data.elementUpdate.licencia?.id;
    this.selectedValueConductor = this.data.elementUpdate.conductor?.id ? this.data.elementUpdate.conductor?.id : null;
    this.selectedValueProveedor = this.data.elementUpdate.proveedor?.id;
    this.selectedValueConcepto = this.data.elementUpdate.concepto?.id;
  }

  async getConductoresByLicencia(gasto?) {
    gasto.conductor = null;
    this.selectedValueConductor = null;
    let idLicencia = null;

    if (!this.data.isForUpdate) {
      idLicencia = gasto.licencia.id;
    } else {
      idLicencia = this.selectedValueLicencia;
    }

    gasto.posiblesConductores = []

    await this._conductorService.getConductorByLicencia(idLicencia).subscribe((res) => {
      gasto.posiblesConductores = res['conductores'];
      if (res['conductores'] == null) {
        this._notificationService.info("INFO", "No existen conductores para la licencia seleccionada", 3000);
      }
    });
  }

  async getConceptosByProveedor(gasto?) {
    gasto.concepto = null;
    this.selectedValueConcepto = null;
    let idProveedor = null;

    if (!this.data.isForUpdate) {
      idProveedor = gasto.proveedor.id;
    } else {
      idProveedor = this.selectedValueProveedor;
    }

    gasto.posiblesConceptos = []
    await this._proveedorService.getConceptosByProveedor(idProveedor).subscribe((res) => {
      gasto.posiblesConceptos = res['conceptos'];
      if (res['conceptos'] == null) {
        this._notificationService.info("INFO", "No existen conceptos para el proveedor seleccionado", 3000);
      }
    });
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      } else {
        null;
      }
    });
  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'guardar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.saveOrUpdate();
      } else {
        null;
      }
    });
  }

  saveOrUpdate() {
    if (this.data.isForUpdate) {
      this.updateGasto();
    } else {
      this.createGastos();
    }
  }

  updateGasto() {

    this.gastoProveedorUpdate = JSON.parse(JSON.stringify(this.data.elementUpdate));
    let dataUpdate = JSON.parse(JSON.stringify(this.gastosList[0]));

    this.gastoProveedorUpdate.importe = dataUpdate.importe;
    this.gastoProveedorUpdate.numFactura = dataUpdate.numFactura;
    this.gastoProveedorUpdate.licencia = this.licencias.find(licencia => licencia.id == this.selectedValueLicencia);
    this.gastoProveedorUpdate.conductor = this.conductores.find(conductor => conductor.id == this.selectedValueConductor);
    this.gastoProveedorUpdate.proveedor = this.proveedores.find(proveedor => proveedor.id == this.selectedValueProveedor);
    this.gastoProveedorUpdate.concepto = this.conceptos.find(concepto => concepto.id == this.selectedValueConcepto);
    this.gastoProveedorUpdate.fechaAlta = this.datePipe.transform(this.fechaAlta, 'dd-MM-yyyy');
    this.gastoProveedorUpdate.licencia.vehiculo = null;
    this.gastoProveedorUpdate.cantidad_propietario = dataUpdate.cantidad_propietario;
    this.gastoProveedorUpdate.cantidad_conductor = dataUpdate.cantidad_conductor;

    if (dataUpdate.tipo_cantidad.toLowerCase() == "porcentaje") {
      this.gastoProveedorUpdate.tipo_cantidad = TipoCantidad.PORCENTAJE;
    } else {
      this.gastoProveedorUpdate.tipo_cantidad = TipoCantidad.CANTIDAD;
    }

    if (this.gastoProveedorUpdate.conductor) {
      this.gastoProveedorUpdate.conductor.fechaAlta = this.convertDate(this.gastoProveedorUpdate?.conductor?.fechaAlta);
      if (this.gastoProveedorUpdate?.conductor?.fechaVencimientoBTP) {
        this.gastoProveedorUpdate.conductor.fechaVencimientoBTP = this.convertDate(this.gastoProveedorUpdate?.conductor?.fechaVencimientoBTP);
      }
    }
    this.gastoProveedorUpdate.importe_iva = (this.gastoProveedorUpdate.concepto?.iva / 100) * this.gastoProveedorUpdate.importe ?? 0;

    this._gastosProveedor.update(new GastosProveedores(this.gastoProveedorUpdate)).subscribe((data) => {

      if (data['status'] == 608) {
        this.data.gastosProveedores.push(data['gastosIngresos']);
        this._notificationService.success('OK', "Gasto/Ingreso modificado correctamente", 3000);
        this.resetearForm();
      } else {
        this._notificationService.error('ERROR', data['message']['message'], 3000);
      }
    });
  }

  getValueIva() {
    let concepto = this.conceptos.find(concepto => concepto.id == this.selectedValueConcepto);
    this.iva = concepto?.iva ?? '';
  }

  calcularIva(iva, importe) {
    let ivaCalculado: number = (iva * 100) * importe ?? 0;
  }

  createGastos() {
    this.gastosList.forEach(element => {

      this.gastoProveedorCreate = JSON.parse(JSON.stringify(element));
      this.gastoProveedorCreate.fechaAlta = this.datePipe.transform(this.gastoProveedorCreate.fechaAlta, 'dd-MM-yyyy');

      if (this.gastoProveedorCreate.conductor) {
        this.gastoProveedorCreate.conductor.fechaAlta = this.convertDate(this.gastoProveedorCreate?.conductor?.fechaAlta);
        if (this.gastoProveedorCreate?.conductor?.fechaVencimientoBTP) {
          this.gastoProveedorCreate.conductor.fechaVencimientoBTP = this.convertDate(this.gastoProveedorCreate?.conductor?.fechaVencimientoBTP);
        }
      }

      if (typeof this.gastoProveedorCreate.conductor == 'string') {
        this.gastoProveedorCreate.conductor = null;
      }

      if (typeof this.gastoProveedorCreate.licencia == 'string') {
        this.gastoProveedorCreate.licencia = null;
      }

      if (this.gastoProveedorCreate.numFactura == '') {
        this.gastoProveedorCreate.numFactura = null;
      }

      if (element.tipo_cantidad == "porcentaje") {
        this.gastoProveedorCreate.tipo_cantidad = TipoCantidad.PORCENTAJE;
      } else {
        this.gastoProveedorCreate.tipo_cantidad = TipoCantidad.CANTIDAD;
      }

      if (this.gastoProveedorCreate.importe != null && this.gastoProveedorCreate.licencia != null && this.gastoProveedorCreate.proveedor != null && this.gastoProveedorCreate.concepto != null) {
        delete element['posiblesConductores'];
        delete element['posiblesConceptos'];
        this.gastoProveedorCreate.usuario = this.userSaved;
        this.gastoProveedorCreate.importe_iva = (this.gastoProveedorCreate.concepto?.iva / 100) * this.gastoProveedorCreate.importe ?? 0;
        let newGasto: GastosProveedores = new GastosProveedores(this.gastoProveedorCreate);
        newGasto.licencia.vehiculo = null;
        this.gastoProveedorList.push(newGasto);
      } else {
        this._notificationService.info('INFO', 'No se puede crear un gasto/ingreso sin importe, sin licencia y sin proveedor y concepto', 5000);
      }
    });

    if (this.gastoProveedorList.length > 0) {
      this._gastosProveedor.saveAllGastosProveedores(this.gastoProveedorList).subscribe((data) => {

        if (data['message']['status'] == 400) {
          this._notificationService.error("ERROR", data['message']['message'], 3000);
        } else {

          this.data.gastosProveedores = data['gastosProveedores'];
          this._notificationService.success("Hecho", 'Gasto/s creado/s correctamente', 3000);

          this.getImporteConductor(data['gastosProveedores']);
          this.resetearForm();
        }
        this.gastoProveedorList = [];
      });
    }
  }

  addGasto() {
    this.gastosList.push({
      fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numFactura: null, proveedor: null, posiblesConductores: null, posiblesConceptos: null, tipo_cantidad: null, cantidad_propietario: null, cantidad_conductor: null, importe_iva: null
    });
  }

  removeGasto(i) {
    this.gastosList.splice(i, 1);
  }

  getValue(event) {
    this.fechaAlta = event.target.value;
  }

  resetearForm() {
    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }

  convertDate(fecha) {
    const splitDate = fecha.split('-');
    const date = new Date(
      Number(splitDate[2]),   // year
      Number(splitDate[1]) - 1, // month
      Number(splitDate[0])    // day
    );
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  getImporteConductor(gastosProveedores) {
    gastosProveedores.forEach(gasto => {
      let importeSave = 0;

      if (gasto.tipo_cantidad.toLowerCase() === 'porcentaje') {
        importeSave = (gasto.cantidad_conductor / 100) * gasto.importe;
      } else {
        importeSave = gasto.cantidad_conductor;
      }

      this.prestamoToSave.concepto = `Gasto proveedores ${gasto.concepto.concepto}`;
      this.prestamoToSave.conductor = gasto.conductor;
      this.prestamoToSave.fechaAlta = gasto.fechaAlta;
      this.prestamoToSave.licencia = gasto.licencia
      this.prestamoToSave.importe = importeSave;
      this.prestamoToSave.usuario = this.userSaved;

      this.prestamoToSave.conductor.fechaAlta = this.convertDate(this.prestamoToSave?.conductor?.fechaAlta);
      if (this.prestamoToSave?.conductor?.fechaVencimientoBTP) {
        this.prestamoToSave.conductor.fechaVencimientoBTP = this.convertDate(this.prestamoToSave?.conductor?.fechaVencimientoBTP);
      }

      let newPrestamo: Prestamos = new Prestamos(this.prestamoToSave);
      this.prestamosListToSave.push(newPrestamo);
    });

    // this.createPrestamo();
  }

  prestamoToSave: Prestamos = new Prestamos(null);
  prestamosListToSave: Prestamos[] = [];

  createPrestamo() {
    this._prestamosService.saveAllPrestamos(this.prestamosListToSave).subscribe((data) => {
      if (data['message']['status'] == 400) {
        this._notificationService.error("ERROR", data['message']['message'], 3000)
      } else {
        this._notificationService.success("Hecho", "Gasto proveedor añadido a préstamos", 3000)
      }
      this.prestamosListToSave = [];
    });
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Recaudacion } from 'src/app/model/novotaxi/recaudacion/recaudacion.model';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { Licencia } from 'src/app/model/Emisora';
import { IConductor } from 'src/app/conductores/conductor.model';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { DatePipe } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { CardDetallesCajaComponent } from '../card-detalles-caja/card-detalles-caja.component';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';
import { Prestamos } from 'src/app/model/novotaxi/prestamos.model';
import { ExcelExportService } from 'src/app/_services/excel-export.service';

@Component({
  selector: 'app-listado-recaudacion',
  templateUrl: './listado-recaudacion.html',
  styleUrls: ['./listado-recaudacion.css']
})

export class RecaudacionListado implements OnInit {
  displayedColumns: string[] = ['conductor.nombre', 'conductor.apellidos', 'conductor.sueldos.cantidad', 'conductor.sueldos.porcentajeCondicional', 'fechaAlta', /* 'dayOfDate', 'festivo', */ 'kilometraje.km_entrada', 'kilometraje.km_salida', 'kilometraje.km_totales', 'taximetro_entrada',
    'taximetro_salida', 'informe.importe_bruto', 'informe.total_invertaxi', 'informe.importe_conductor', 'informe.importe_propietario', 'informe.total_propietario', 'total_tarjeta',
    'total_vales', 'operacion_conductor.prestamo', 'operacion_conductor.efectivo', 'observaciones', 'no_computa', 'acciones'];

  displayedSearchHeaders: string[] = ['conductor_nombre_buscador', 'conductor_apellidos_buscador', 'conductor_sueldos_cantidad_buscador', 'conductor_sueldos_porcentajeCondicional_buscador', 'fechaAlta_buscador', /* 'dia_buscador', 'festivo_buscador',  */'kmEntrada_buscador', 'kmSalida_buscador', 'kmTotales_buscador',
    'taximetroEntrada_buscador', 'taximetroSalida_buscador', 'importeBruto_buscador', 'totalNovotaxi_buscador', 'importeConductor_buscador', 'importePropietario_buscador', 'totalPropietario_buscador',
    'visa_buscador', 'vales_buscador', 'banco_buscador', 'efectivo_buscador', 'observaciones_buscador', 'computa_buscador', 'acciones_buscador'];

  listDated = [];
  dataSource = new MatTableDataSource<Recaudacion>();
  recaudacionList: Recaudacion[] = [];
  isSearch: boolean = false;
  selectedValueLicencia: Licencia = null;
  selectedValueConductor: IConductor = null;
  fechaInicio: string;
  fechaFin: string;
  licencias: Licencia[] = [];
  conductores: IConductor[] = [];
  listColumns;
  searchMap = {
    fechaInicio: null,
    fechaFinal: null,
    idLicencia: null,
    idConductor: null
  };
  tipoDetalle: string = 'recaudacion';

  // -- Variables para el control del usuario
  currentUser;
  userSaved: Usuario = null;

  //Filtrado por columnas
  public searchForm: FormGroup;
  // public nombre = '';
  // public porcentaje = '';
  // public porcentajeCondicional = '';
  // public fechaAlta = '';
  // public dia = '';
  // public festivo = '';
  // public kmEntrada = '';
  // public kmSalida = '';
  // public kmTotales = '';
  // public taximetroEntrada = '';
  // public taximetroSalida = '';
  // public importeBruto = '';
  // public totalInvertaxi = '';
  // public importeConductor = '';
  // public importePropietario = '';
  // public totalPropietario = '';
  // public rpro = '';
  // public visa = '';
  // public vales = '';
  // public banco = '';
  // public efectivo = '';
  // public observaciones = '';
  // public validado = '';
  // public ingresoAsignado = '';

  computa = true;
  noComputa = true;
  isComputaActivo: boolean = null;
  currentDate: Date = new Date();
  isTotal: boolean = false;

  // -- Paginado
  length = this.listDated.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  // -- Variables optimización
  public filters = [];
  public sorts = [{
    key: "fechaAlta",
    direction: "ASC"
  }];
  public totalElements = 0;

  // -- Variables para pasar al componente
  mostrarFormulario: Boolean = false;
  showButtons: Boolean = false;
  data: DataRecaudacion = { mostrarFormulario: false, recaudacion: null, isForUpdate: false };

  // -- Variables totales tabla
  importe_bruto = 0;
  importe_conductor = 0;
  importe_propietario = 0;
  total_tarjeta = 0;
  total_vales = 0;
  efectivoTotal = 0;
  km_totales;
  prestamo;
  total_importe_novotaxi = 0;
  total: number = 0;

  // -- Variable para botón cobrar
  prestamoToSave: Prestamos = new Prestamos(null);
  prestamosListToSave: Prestamos[] = [];

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesCajaComponent) child;

  constructor(
    private _recaudacionService: RecaudacionService,
    private _licenciaService: LicenciaServiece,
    private _conductorService: ConductorService,
    private _notificationService: NotificationService,
    private _tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();
    this.getLicencias();
    //this.getConductores();
    this.listColumns = {
      'Nombre conductor': true,
      'Apellidos conductor': true,
      'Porcentaje base': true,
      'Porcentaje condicional': true,
      'Fecha alta': true,
      'Día': true,
      'Festivo': true,
      'Km entrada': true,
      'Km salida': true,
      'Km totales': true,
      'Taxímetro entrada': true,
      'Taxímetro salida': true,
      'Importe bruto': true,
      'Diferencia a conductor': true,
      'Importe conductor': true,
      'Importe propietario': true,
      'Total propietario': true,
      /*'Rpro': true,*/
      'Visa': true,
      'Vales': true,
      'Banco': true,
      'Efectivo': true,
      'Observaciones': true,
      'Computa': true,
      'Validado': true,
      'Ingreso asignado': true,
    };

    this.fechaInicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
    this.fechaFin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.searchMap.fechaInicio = this.fechaInicio;
    this.searchMap.fechaFinal = this.fechaFin;

    this.setRequestForSearch();
    this.searchFormInit();
  }

  //carga de datos para el informe(totales de pantalla actual y no globales de la consulta)


  generarInforme() {
    let mapaInforme = {
      'importe_bruto': 0,
      'importe_conductor': 0,
      'importe_propietario': 0,
      'total_tarjeta': 0,
      'total_vales': 0,
      'efectivoTotal': 0,
      'km_totales': 0,
      'prestamos': 0,
    };
    let nombre = 'Recaudacion';    

    this.dataSource.filteredData.forEach((data) => {
      mapaInforme.importe_bruto = mapaInforme?.importe_bruto + data['informe']?.importe_bruto;
      mapaInforme.importe_conductor = mapaInforme?.importe_conductor + data['informe']?.importe_conductor;
      mapaInforme.importe_propietario = mapaInforme?.importe_propietario + data['informe']?.importe_propietario;
      mapaInforme.total_tarjeta = mapaInforme?.total_tarjeta + data['total_tarjeta'];
      mapaInforme.total_vales = mapaInforme?.total_vales + data['total_vales'];
      mapaInforme.efectivoTotal = mapaInforme?.efectivoTotal + data['operacion_conductor']?.efectivo;
      mapaInforme.km_totales = mapaInforme?.km_totales + data['kilometraje']?.km_totales;
      mapaInforme.prestamos = mapaInforme?.prestamos + data['operacion_conductor']?.prestamo;
    });

    this.informe.crearPDF(this.dataSource.filteredData, nombre, mapaInforme);
  }

  mostrarTotales() {
    if (this.isTotal) {
      this.isTotal = false;
    } else if (!this.isTotal) {
      this.isTotal = true;
    }
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  setMostrarFormulario(mode: Boolean, isForUpdate?: Boolean, element?: Recaudacion) {
    this.mostrarFormulario = mode;
    this.data = { mostrarFormulario: mode, recaudacion: element, isForUpdate: isForUpdate }
    if (mode) {
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth" });
    }
  }

  // -- Método para recargar la tabla
  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    this.child.getTotalesRecaudacion(this.searchMap);
    // this.searchRecaudacion();
    this.setRequestForSearch();
  }

  formatDateForBE(dateString: string): string {
    if (dateString) {
      const splitDate = dateString.split('-');

      const date = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    return null;
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe((data) => {
      this.licencias = data["licencias"];
    })
  }

  getConductores(event) {
    this.filters = [];
    this.selectedValueConductor = null;
    
    this._conductorService.getConductorByLicencia(this.selectedValueLicencia.id).subscribe((res) => {
      this.conductores = res['conductores'];
    });
  }

  isValidFieldsSearch() {
    if (this.fechaInicio == undefined || this.fechaFin == undefined || this.fechaInicio == '' || this.fechaFin == '') {
      this._notificationService.warning("¡Atención!", "Debe rellenar las fechas de búsqueda", 3000);
      return false;
    } else {
      if (this.fechaInicio > this.fechaFin) {
        this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
        return false;
      }
      return true;
    }
  }

  getDataFromDate(date: string) {
    if (this.recaudacionList != undefined) {
      let index = this.recaudacionList.findIndex(recaudacion => {
        return recaudacion.fechaAlta == date;
      })
      return this.recaudacionList[index];
    }
    return null;
  }

  // -- Fin métodos todas las fechas


  getDiaFestivo(diaSemana) {
    let diaLibranza: string = '';

    if (diaSemana == "miércoles") {
      diaLibranza = 'X';
    } else {
      diaLibranza = diaSemana.charAt(0).toUpperCase();
    }

    if (diaLibranza != this.selectedValueLicencia?.dia_libranza) {
      diaLibranza = '';
    }
    return diaLibranza;
  }

  async openDialogDelete(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'borrar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        if (element.id != null) {
          let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

          if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar una recaudación del día actual", 3000);
          } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && element.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar una recaudación del día actual", 3000);
          } else {
            this.deleteRecaudacion(element);
          }
        }
      } else {
        null;
      }
    });
  }

  deleteRecaudacion(element) {
    if (element.id.toString() != '') {
      this._recaudacionService.deleteRecaudacion(element.id).subscribe((data) => {
        if (data["status"] == '622') {
          // this.searchRecaudacion();
          this._notificationService.success('Hecho', 'Recaudación eliminada con éxito', 3000);
          this.setRequestForSearch();
        }
      });
    }
    else {
      this._notificationService.info("INFO", "No se puede eliminar una recaudación vacía", 3000)
    }
  }

  // -- Inicio optimización

  searchFormInit() {
    this.searchForm = new FormGroup({
      nombre: new FormControl(''),
      porcentaje: new FormControl(''),
      porcentajeCondicional: new FormControl(''),
      fechaAlta: new FormControl(''),
      dia: new FormControl(''),
      festivo: new FormControl(''),
      kmEntrada: new FormControl(''),
      kmSalida: new FormControl(''),
      kmTotales: new FormControl(''),
      taximetroEntrada: new FormControl(''),
      taximetroSalida: new FormControl(''),
      importeBruto: new FormControl(''),
      totalInvertaxi: new FormControl(''),
      importeConductor: new FormControl(''),
      importePropietario: new FormControl(''),
      totalPropietario: new FormControl(''),
      rpro: new FormControl(''),
      visa: new FormControl(''),
      vales: new FormControl(''),
      banco: new FormControl(''),
      efectivo: new FormControl(''),
      observaciones: new FormControl(''),
      validado: new FormControl(''),
      ingresoAsignado: new FormControl(''),
    });
  }

  setRequestForSearch(request?) {
    let requestSearchFields;

    this.searchMap = {
      fechaInicio: this.fechaInicio,
      fechaFinal: this.fechaFin,
      idLicencia: this.selectedValueLicencia ? this.selectedValueLicencia.id : null,
      idConductor: this.selectedValueConductor ? this.selectedValueConductor.id : null,
    };

    if (this.isValidFieldsSearch) {

      if (this.selectedValueLicencia != null && this.selectedValueConductor == null) {

        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechaInicio.toString().split("-").reverse().join("-"),
            value_to: this.fechaFin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          }
            , {
            key: 'licencia.id',
            operator: "LIKE",
            field_type: 'STRING',
            value: this.selectedValueLicencia?.id,
          },],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      } else if (this.selectedValueLicencia == null && this.selectedValueConductor != null) {

        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechaInicio.toString().split("-").reverse().join("-"),
            value_to: this.fechaFin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },
          {
            key: 'conductor.id',
            operator: "LIKE",
            field_type: 'STRING',
            value: this.selectedValueConductor?.id
          }],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }

      } else if (this.selectedValueLicencia != null && this.selectedValueConductor != null) {

        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechaInicio.toString().split("-").reverse().join("-"),
            value_to: this.fechaFin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          },
          {
            key: 'licencia.id',
            operator: "LIKE",
            field_type: 'STRING',
            value: this.selectedValueLicencia?.id,
          },
          {
            key: 'conductor.id',
            operator: "LIKE",
            field_type: 'STRING',
            value: this.selectedValueConductor?.id
          }],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      } else {

        requestSearchFields = {
          "filters": [{
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechaInicio.toString().split("-").reverse().join("-"),
            value_to: this.fechaFin.toString().split("-").reverse().join("-"),
          },
          {
            key: 'fechaBaja',
            operator: "IS_NULL",
            field_type: 'DATE',
          }],
          "sorts": this.sorts,
          page: this.pageIndex,
          size: this.pageSize,
        }
      }
    }

    this.searchRecaudacion(requestSearchFields, request);
  }

  async searchRecaudacion(requestSearchFields?, request?) {
    let filtersToSend = [];    

    if (request) {
      if (requestSearchFields) {
        filtersToSend = [...request['filters'], ...requestSearchFields['filters']];

      } else {
        filtersToSend = [...request['filters']];
      }

    } else {
      filtersToSend = [...requestSearchFields['filters']];
    }

    if (this.sorts.length == 0) {
      this.sorts = [{
        key: "fechaAlta",
        direction: "ASC"
      }];
    }

    let requestToSend = {
      "filters": filtersToSend,
      "sorts": this.sorts,
      page: this.pageIndex,
      size: this.pageSize,
    };
    
    await this._recaudacionService.getRecaudaciones(requestToSend).subscribe((data) => {
      this.listDated = [];
      this.totalElements = 0;      

      if (data['recaudaciones']) {
        this.recaudacionList = data['recaudaciones']['content'];

        if (this.recaudacionList) {
          this.isSearch = true;

          data["recaudaciones"]['content'].forEach(element => {
            let fechaAlta = this.formatDateForBE(element.fechaAlta);
            element['dayOfDate'] = new Date(fechaAlta).toLocaleString('es', { weekday: 'long' });
            this.listDated.push(element);
          });

          this.totalElements = data['recaudaciones']['totalElements'];

        } else {
          this._notificationService.info("INFO", "No se han encontrado datos", 3000);
          this.isSearch = false;
        }
      }
      this.dataSource = new MatTableDataSource(this.listDated);

      this.cargaTotales(this.listDated);
    })
  }

  async applyFilter(event?, key?, type?) {
    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];

    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event?.target?.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {

    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value,
      };

      if (type?.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN";
      }

      if (type?.toUpperCase() == "IN") {
        filter.operator = "IN";
        filter.field_type = "STRING";
      }

      if (type.toUpperCase() == "BOOLEAN") {
        filter.operator = "EQUAL";
        this.setFilterBoolean(filter);
      }

      if (type?.toUpperCase() == "DATE_BETWEEN" || type?.toUpperCase() == "LOCALDATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX";
      }

      if (type?.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS";
        filter.field_type = "STRING";
      }

      if (type?.toUpperCase() == "DOUBLE" || type?.toUpperCase() == "LONG" || type?.toUpperCase() == "BIG_DECIMAL" || type?.toUpperCase() == "INTEGER") {
        filter.operator = "LIKE";
        filter.field_type = "STRING";
      }

      if (type.toUpperCase() == 'ENUM_TIPO_GESTION') {
        filter.operator = "LIKE_TIPO_GESTION";
        filter.field_type = "STRING";
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0);
          } else {
            filter.value = value;

            if (type.toUpperCase() == "BOOLEAN") {
              this.setFilterBoolean(filter);
            }
          }
        }
      });
    }

    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,
    };

    this.paginator.firstPage();
    await this.setRequestForSearch(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setRequestForSearch(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage();
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    };

    if (sort.direction) {
      let direction = sort.direction.toUpperCase();
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC";
        } else {
          direction = "CUSTOM_DESC";
        }

      } else {
        this.sorts = [{
          "key": sort.active,
          "direction": direction,
        }];
      }
      request['sorts'] = this.sorts;
    }

    await this.setRequestForSearch(request);
  }

  setFilterBoolean(filter) {
    if (filter.key == 'no_computa') {
      if (!this.computa || !this.noComputa) {
        filter.value = this.isComputaActivo;
      } else {
        let index = this.filters.findIndex(x => x.key === "no_computa");
        this.filters.splice(index, 1);
      }
    }
  }

  //  -- Checkbox
  setActivoInactivo(event, computaNoComputa) {
    if (computaNoComputa == 'computa') {
      this.computa = event.target.checked;
      this.isComputaActivo = true;
    }
    if (computaNoComputa == 'noComputa') {
      this.noComputa = event.target.checked;
      this.isComputaActivo = false;
    }
    this.applyFilter(event, "no_computa", "BOOLEAN");
  }

  clearSearchInputs() {
    this.searchForm.reset();
    this.filters = [];
  }
  // -- Fin optimización

  cargaTotales(listaRecaudaciones) {
    this.importe_bruto = 0;
    this.importe_conductor = 0;
    this.importe_propietario = 0;
    this.total_tarjeta = 0;
    this.total_vales = 0;
    this.efectivoTotal = 0;
    this.km_totales = 0;
    this.prestamo = 0;
    this.total_importe_novotaxi = 0;

    listaRecaudaciones.forEach((data) => {
      this.total_importe_novotaxi = this.total_importe_novotaxi + data.informe?.total_invertaxi;
      this.importe_bruto = this.importe_bruto + data.informe?.importe_bruto;
      this.importe_conductor = this.importe_conductor + data.informe?.importe_conductor;
      this.importe_propietario = this.importe_propietario + data.informe?.importe_propietario;
      this.total_tarjeta = this.total_tarjeta + data?.total_tarjeta;
      this.total_vales = this.total_vales + data?.total_vales;
      this.efectivoTotal = this.efectivoTotal + data?.operacion_conductor?.efectivo;
      this.km_totales = this.km_totales + data?.kilometraje?.km_totales;
      this.prestamo = this.prestamo + data?.operacion_conductor?.prestamo;
    });

    this.total = this.efectivoTotal - this.importe_propietario;
  }

  // -- Funcionalidad cobrar
  cobrar() {
    this.searchMap = {
      fechaInicio: this.fechaInicio,
      fechaFinal: this.fechaFin,
      idLicencia: this.selectedValueLicencia ? this.selectedValueLicencia.id : null,
      idConductor: this.selectedValueConductor ? this.selectedValueConductor.id : null,
    };

    // if (this.selectedValueConductor == null || this.selectedValueLicencia == null) {
    //   this._notificationService.info("INFO", "Debe seleccionar un conductor y una licencia", 3000);
    //   return;
    // }

    this._recaudacionService.cobrarRecaudacion(this.searchMap).subscribe({
      next: (data) => {
        if (data) {
          this._notificationService.success("OK", "Se ha creado o actualizado un préstamo con la recaudaión", 3000);
        }
      }, error: (err) => {
        this._notificationService.error("ERROR", err["error"]["message"], 3000)
      }
    });
  }

  convertDate(fecha) {
    const splitDate = fecha.split('-');
    const date = new Date(
      Number(splitDate[2]),   // year
      Number(splitDate[1]) - 1, // month
      Number(splitDate[0])    // day
    );
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  // -- Exportación a excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, createdDate, lastModifiedDate, createdBy, kilometraje, retencion, incidencias, total_incidencias, informe, operacion_conductor, usuario, vales, dayOfDate, tarjetas, conductor, licencia, no_computa, total_tarjeta, ...rest } = item;

    if (item.licencias) {
      rest.licencias = item.licencias.map((licencia) => licencia.numero_licencia).join(', ');
    }

    return rest;
  }

  generateExcel() {
    const data = this.dataSource.data.map((recaudacion) => {
      const formattedRecaudacion = { ...recaudacion };
      formattedRecaudacion.fechaAlta = this.formatDate(recaudacion.fechaAlta).toLocaleDateString();
      formattedRecaudacion.fechaModificacion ? formattedRecaudacion.fechaModificacion = this.formatDate(recaudacion.fechaModificacion) : null;
      return formattedRecaudacion;
    });

    this.addRows(data);

    const dataFormatted = data.map((recaudacion) => {
      const formattedRecaudacion = { ...recaudacion };
      formattedRecaudacion.fechaAlta = this.formatDate(recaudacion.fechaAlta).toLocaleDateString();
      formattedRecaudacion.fechaModificacion ? formattedRecaudacion.fechaModificacion = this.formatDate(recaudacion.fechaModificacion) : null;
      return this.filterFields(formattedRecaudacion);
    });

    const fileName = 'Tabla_recaudacion';
    this._excelService.exportToExcel(dataFormatted, fileName);
  }

  // generateExcel() {
  //   const data = this.dataSource.data.map((recaudacion) => {
  //     const formattedRecaudacion = { ...recaudacion };
  //     formattedRecaudacion.fechaAlta = this.formatDate(recaudacion.fechaAlta).toLocaleDateString();
  //     formattedRecaudacion.fechaModificacion ? formattedRecaudacion.fechaModificacion = this.formatDate(recaudacion.fechaModificacion): null;
  //     return this.filterFields(formattedRecaudacion);
  //   });

  //   const fileName = 'Tabla_recaudacion';
  //   this._excelService.exportToExcel(data, fileName);
  // }

  addRows(data) {
    data.forEach((recaudacion) => {
      recaudacion.nombre_conductor = recaudacion.conductor?.nombre;
      recaudacion.apellidos_conductor = recaudacion.conductor?.apellidos;
      recaudacion.km_entrada = recaudacion.kilometraje?.km_entrada;
      recaudacion.km_salida = recaudacion.kilometraje?.km_salida;
      recaudacion.km_totales = recaudacion.kilometraje?.km_totales;
      recaudacion.importe_bruto = recaudacion.informe?.importe_bruto;
      recaudacion.total_novotaxi = recaudacion.informe?.total_invertaxi;
      recaudacion.importe_conductor = recaudacion.informe?.importe_conductor;
      recaudacion.importe_propietario = recaudacion.informe?.importe_propietario;
      recaudacion.total_propietario = recaudacion.informe?.total_propietario;
      recaudacion.visa = recaudacion.total_tarjeta ?? '';
      recaudacion.banco = recaudacion.operacion_conductor?.prestamo;
      recaudacion.efectivo = recaudacion.operacion_conductor?.efectivo;
      recaudacion.computa = !recaudacion.no_computa;
    });
  }


  // -- Funcionalidad liquidar
  liquidar() {
    this.searchMap = {
      fechaInicio: this.fechaInicio,
      fechaFinal: this.fechaFin,
      idLicencia: this.selectedValueLicencia ? this.selectedValueLicencia.id : null,
      idConductor: this.selectedValueConductor ? this.selectedValueConductor.id : null,
    };

    if (this.selectedValueConductor == null || this.selectedValueLicencia == null) {
      this._notificationService.info("INFO", "Debe seleccionar un conductor y una licencia", 3000);
      return;
    }

    this._recaudacionService.liquidarRecaudacion(this.searchMap).subscribe({
      next: (data) => {
        if (data) {
          this._notificationService.success("OK", "Se ha creado o actualizado una liquidación con la recaudaión", 3000);
          this.setRequestForSearch();
        }
      }, error: (err) => {
        this._notificationService.error("ERROR", err["error"]["message"], 3000)
      }
    })

    let sueldo = this.selectedValueConductor.sueldos.find(sueldo => sueldo.licencia.id == this.selectedValueLicencia.id);
    if (sueldo.tipoSueldo.id == 2) {
      this.cobrar();
    }
  }

  getporcentaje(element) {
    var sueldo = element.conductor.sueldos.find(sueldo => sueldo.licencia.id == element.licencia.id);
    return sueldo.cantidad;
  }

  getporcentajeCondicional(element) {
    var sueldo = element.conductor.sueldos.find(sueldo => sueldo.licencia.id == element.licencia.id);
    return sueldo.porcentajeCondicional;
  }
}

export interface DataRecaudacion {
  recaudacion: Recaudacion;
  mostrarFormulario: Boolean;
  isForUpdate: Boolean;
}

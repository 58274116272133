<div class="bg-white rounded p-5">
    <div class="d-flex justify-content-center mb-5 mt-5"><mat-icon class="text-danger iconoAuxiliar">warning_amber</mat-icon></div>
    <div class="text-center pt-4">
        <h2>¿Descartar cambios?</h2>
        <p class="mt-3">Los cambios realizados se perderán</p>
    </div>
    <div class="pt-4 d-flex justify-content-center">
        <button class="btn btn-danger btn-lg mx-3" (click)="discard()">Descartar</button>
        <button class="btn btn-secondary btn-lg mx-3" (click)="dialogRef.close({result: ''})">Cancelar</button>
    </div>
</div>
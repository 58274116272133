import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { IConductor, TipoSueldo } from "../conductor.model";
import { ConductorService } from "../services/conductor.service";
import { NotificationService } from "src/app/_services/notification.service";
import { VehiculoService } from "src/app/_services/vehiculo.service";
import { IVehiculo } from "src/app/vehiculo/vehiculo.model";
import { Observable, ReplaySubject, firstValueFrom } from "rxjs";
import { ModalConfirmacionComponent } from "src/app/novotaxi/modal-confirmacion/modal-confirmacion.component";
import { LicenciaServiece } from "src/app/_services/licenciaService.service";
import { Documento } from "src/app/model/documento";
import { ConductorSueldo } from "src/app/model/novotaxi/conductor-sueldo.model";


@Component({
  selector: 'app-crear-conductor',
  templateUrl: './crear-conductor.component.html',
  styleUrls: ['./crear-conductor.component.css']
})

export class CrearConductorComponent implements OnInit {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";
  REGEX_IBAN = "(^ES\s?\d{2}\s?\d{4}\s?\d{4}\s?\d{2}\s?\d{10}$)";

  // -- Variables filtrado select
  showDropDown = false;
  keys;
  allKeys;
  nameSearch: string;

  filterList = [{ listVehiculos: [] }];
  filter: string = '';

  listaVehiculos;
  listaProvincias;
  vehiculosByConductor;
  municipiosToSelect;
  listaMunicipios;
  tipoDocumentoList: Documento[] = [];
  vehiculoToSave: IVehiculo[] = [];
  tipoSueldoList: TipoSueldo[] = [];
  sueldosList: ConductorSueldo[] = [];
  sueldoObject: ConductorSueldo = new ConductorSueldo

  conductor: IConductor;
  expandedPhone: number = 1;
  expandedVehiculo: number = 1;
  isSubmitted: Boolean = false;
  expandedEmail: number = 1;
  expandedIban: number = 1;
  form;
  porcentajeSalarioSelected = 0;
  libranzaSelected = '';

  // -- Variables histórico
  mostrarHistorico: Boolean = false;
  mostrarBotones: Boolean = true;
  conductorHistorico: IConductor[] = [];
  conductorLicenciaHistorico;

  @Output() mostrarFormulario = new EventEmitter<Boolean>();
  @Input() conductorUpdate: IConductor;
  @Input() editar: Boolean;

  constructor(
    private servicioLicencia: LicenciaServiece,
    protected conductorService: ConductorService,
    public servicioGeneral: ServicioGeneral,
    private dialog: MatDialog,
    private router: Router,
    private vehiculoService: VehiculoService,
    public _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getAllVehiculos();
    this.getTipoDocumento();
    this.getTipoSueldo();
    this.initiateForm();

    if (this.editar) {
      this.sueldos.clear();
      this.getSueldosUpdate();
      this.getHistoricoConductor();
      this.getDocumentsByConductor();

      this.libranzaSelected = this.conductorUpdate?.dia_libranza ?? '';
      // if (this.conductorUpdate?.porcentaje != null) {
      //   this.porcentajeSalarioSelected = 2;
      // } else if (this.conductorUpdate?.salario_fijo != null) {
      //   this.porcentajeSalarioSelected = 1;
      // }
    }

    this.getAllProvincias();
    this.listMunicipios();

    if (this.conductorUpdate?.fechaBaja != null) {
      for (const key in this.form.controls) {
        this.form.controls[key].disable();
      }
    }
  }

  getTipoDocumento() {
    this.servicioGeneral.getTipoDocumento().subscribe((data) => {
      if (data["message"]["status"] = "616") {
        this.tipoDocumentoList = data["TipoDocumento"];
      }
    })
  }

  getTipoSueldo() {
    this.conductorService.getTiposSueldo().subscribe({
      next: (data) => {
        console.error("DAtra: ", data);

        if (data["status"] = "616") {
          this.tipoSueldoList = data["tiposSueldos"];
        }
      }
    })
  }

  getVehiculosAsociated() {
    let vehiculosConductor = this.conductorUpdate?.vehiculos;

    if (vehiculosConductor != null || vehiculosConductor.length != 0) {
      this.filterList.pop();
      for (let i = 0; i < vehiculosConductor.length; i++) {
        this.filterList[i] = { listVehiculos: this.allKeys };
      }
    } else {
      this.filterList.push({ listVehiculos: this.allKeys });
    }
  }

  setMostrarHistorico(mode: Boolean) {
    this.mostrarHistorico = mode;
    if (mode) {
      this.mostrarBotones = false;
    }
    else {
      this.mostrarBotones = true;
    }
  }

  getHistoricoConductor() {
    this.conductorService.getHistorico(this.conductorUpdate.id).subscribe((result) => {
      this.conductorHistorico = result.body['historico'];
    });

    this.conductorService.getLicenciaConductorHistorico(this.conductorUpdate.id).subscribe((result) => {
      this.conductorLicenciaHistorico = result.body;
    });
  }

  getAllProvincias() {
    this.servicioGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    );
  }

  listMunicipios() {
    this.servicioLicencia.listMunicipio().subscribe(
      (data) => {
        this.listaMunicipios = data['Municipio'];
        this.cargaMunicipios(this.conductorUpdate?.provincia?.id);
      });
  }

  cargaMunicipios(idProvincia) {
    this.municipiosToSelect = this.listaMunicipios.filter(option => idProvincia == option.municipioPK.provincia);
  }

  checkporcentaje() {
    if (this.form.get("sueldos").value.tipoSueldo == 1) {
      if (this.form.get("sueldos").value.cantidad > 100) {
        this.form.get("sueldos").value.cantidad = 100;
      }
    }
  }

  getAllVehiculos() {
    this.vehiculoService.getAllVehiculo().subscribe((res) => {
      this.listaVehiculos = res['vehiculos'];
      this.keys = this.listaVehiculos;
      this.allKeys = this.keys;

      if (this.editar && this.conductorUpdate?.vehiculos == null || this.conductorUpdate?.vehiculos?.length > 0) {
        this.getVehiculosAsociated();
      } else {
        this.filterList = [{ listVehiculos: this.allKeys }];
      }
    });

  }

  initiateForm() {
    this.form = new FormGroup({
      fechaVencimientoBTP: new FormControl(this.convertDate(this.conductorUpdate?.fechaVencimientoBTP, false)),
      // vehiculo: this.camposVehiculo(),
      sueldos: new FormArray([
        new FormGroup({
          id: new FormControl({ value: "", disabled: false }),
          vehiculo: new FormControl({ value: 0, disabled: false }),
          licencia: new FormControl({ value: "", disabled: false }),
          tipoSueldo: new FormControl({ value: 0, disabled: false }),
          cantidad: new FormControl({ value: "", disabled: false }),
          porcentajeCondicional: new FormControl({ value: "", disabled: false }),
        }),
      ]),
      tipo_documento: new FormControl(this.conductorUpdate?.tipo_documento?.id ? this.conductorUpdate?.tipo_documento.id : 0, [Validators.required]),
      dni: new FormControl(this.conductorUpdate?.dni, [Validators.required, Validators.pattern("^[0-9]{8}[A-Z]$")]),
      nombre: new FormControl(this.conductorUpdate?.nombre, Validators.required),
      apellidos: new FormControl(this.conductorUpdate?.apellidos),
      domicilio: new FormControl(this.conductorUpdate?.domicilio),
      provincia: new FormControl(this.conductorUpdate?.provincia?.id ? this.conductorUpdate?.provincia.id : 0),
      poblacion: new FormControl(this.conductorUpdate?.poblacion ? this.conductorUpdate?.poblacion : 0),
      codigoPostal: new FormControl(this.conductorUpdate?.codigoPostal, [Validators.pattern("[0-9]{5}")]),
      mail: this.camposEmail(),
      telefono: this.camposTelefono(),
      cuentaBancaria: this.camposCuentaBancaria(),
      retencion: new FormControl(this.conductorUpdate?.retencion),
      valoracion: new FormControl(this.conductorUpdate?.valoracion),
      puntuacion: new FormControl(this.conductorUpdate?.puntuacion),
      lopd: new FormControl(this.conductorUpdate?.lopd),
      riesgosLaborales: new FormControl(this.conductorUpdate?.riesgosLaborales),
      observaciones: new FormControl(this.conductorUpdate?.observaciones),
      // porcentaje: new FormControl(this.conductorUpdate?.porcentaje ?? null),
      // salario_fijo: new FormControl(this.conductorUpdate?.salario_fijo ?? null),
      // nomina: new FormControl(this.conductorUpdate?.nomina ?? null),
      // tipoSueldo: new FormControl(this.conductorUpdate?.tipoSueldo?.id ? this.conductorUpdate?.tipoSueldo.id : 0, [Validators.required]),
    }, { updateOn: 'change' });
    /*     if (this.editar) {
          this.setPorcentajeSalario();
        } */
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.data = "descartar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      }
    });
  }

  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.data = "guardar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    });
  }

  openDialogConfirmarActivate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "activar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    });
  }

  // Para tener más de un teléfono o email

  camposTelefono() {
    let telefonosConductor = this.conductorUpdate?.telefono;

    if (telefonosConductor == null || telefonosConductor.length == 0) {
      var telefono = new FormArray([new FormControl("", Validators.pattern("^[0-9]{9}"))]);
    } else {
      telefono = new FormArray([new FormControl(telefonosConductor[0], Validators.pattern("^[0-9]{9}"))]);
      for (let i = 1; i < telefonosConductor.length; i++) {
        telefono.push(new FormControl(telefonosConductor[i].toString(), Validators.pattern("^[0-9]{9}")));
        this.expandedPhone++;
      }
    }
    return telefono;
  }

  camposVehiculo() {
    let vehiculosConductor = this.conductorUpdate?.vehiculos;

    if (vehiculosConductor == null || vehiculosConductor.length == 0) {
      var vehiculo = new FormArray([new FormControl(0)]);
      return vehiculo;
    } else {
      vehiculo = new FormArray([new FormControl(vehiculosConductor[0].id)]);
      for (let i = 1; i < vehiculosConductor.length; i++) {
        vehiculo.push(new FormControl(vehiculosConductor[i].id));
        this.expandedVehiculo++;
      }
      return vehiculo;
    }
  }

  camposEmail() {
    let emailsConductor = this.conductorUpdate?.mail;

    if (emailsConductor == null || emailsConductor.length == 0) {
      var email = new FormArray([new FormControl("", Validators.pattern(this.REGEX_EMAIL))]);
    } else {
      email = new FormArray([new FormControl(emailsConductor[0], Validators.pattern(this.REGEX_EMAIL))]);
      for (let i = 1; i < emailsConductor.length; i++) {
        email.push(new FormControl(emailsConductor[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
        this.expandedPhone++;
      }
    }
    return email;

  }

  camposCuentaBancaria() {
    let cuentasConductor = this.conductorUpdate?.cuentaBancaria;

    if (cuentasConductor == null || cuentasConductor.length == 0) {
      var cuenta = new FormArray([new FormControl("")]);
    } else {
      cuenta = new FormArray([new FormControl(cuentasConductor[0])]);
      for (let i = 1; i < cuentasConductor.length; i++) {
        cuenta.push(new FormControl(cuentasConductor[i].toString()));
        this.expandedPhone++;
      }
    }
    return cuenta;
  }

  addTelefono() {
    let tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl(null, Validators.pattern("[0-9]{9}")),
    );
    this.expandedPhone++;
  }

  addVehiculo() {
    let vehiculos = this.form.controls.vehiculo as FormArray;
    vehiculos.push(
      this.crearVehiculoFormGroup()
    );
    this.expandedVehiculo++;
    this.filterList.push({ listVehiculos: this.allKeys });
  }

  crearVehiculoFormGroup(): FormGroup {
    return new FormGroup({
      vehiculo: new FormControl(null, Validators.required), // Control para el vehículo, requerido
      tipoSueldo: new FormControl(null, Validators.required), // Control para el tipo de sueldo, requerido
      cantidad: new FormControl(null, [Validators.required, Validators.min(0)]) // Control para la cantidad, requerido y mínimo 0
    });
  }

  addEmail() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl("", Validators.pattern(this.REGEX_EMAIL)),
    );
    this.expandedEmail++;
  }

  addCuentaBancaria() {
    let cuentas = this.form.controls.cuentaBancaria as FormArray;
    cuentas.push(
      new FormControl(null, Validators.pattern(this.REGEX_IBAN)),
    );
    this.expandedIban++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  get emails() {
    return this.form.get("mail") as FormArray;
  }

  get vehiculos() {
    return this.form.get("vehiculos") as FormArray;
  }

  get cuentasBancarias() {
    return this.form.get("cuentaBancaria") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  // removeVehiculo(i: number) {
  //   this.vehiculo.removeAt(i);
  //   this.expandedVehiculo--;
  //   this.form.markAsDirty();
  //   this.filterList.splice(i, 1);
  // }

  removeCuentaBancaria(i: number) {
    this.cuentasBancarias.removeAt(i);
    this.expandedIban--;
    this.form.markAsDirty();
  }

  async getVehiculosByConductor(conductor) {
    await firstValueFrom(this.vehiculoService.getVehiculosByIdConductor(conductor.id)).then((res) => {
      this.vehiculosByConductor = res;
    });
  }

  uploadDocuments: Boolean = false;
  submit() {
    this.vehiculoToSave = [];

    if (this.listaVehiculos != null) {
      for (const vehiculo of this.listaVehiculos) {
        for (const numero of this.form.value.sueldos) {
          if (vehiculo.id == numero.vehiculo) {
            let vehiculoObject = new IVehiculo(vehiculo);
            if (vehiculoObject?.licencia?.propietario) {
              vehiculoObject.licencia.propietario.fechaModificacion = null;
            }
            this.vehiculoToSave.push(vehiculoObject);
          }
        }
      }
    }

    this.prepararsueldosCreate();

    // if (this.form.value?.elegirPorcentajeSalario == 2) {
    //   this.form.value.salario_fijo = null;
    // } else if (this.form.value?.elegirPorcentajeSalario == 1) {
    //   this.form.value.porcentaje = null;
    // }

    this.conductor = {
      id: this.conductorUpdate?.id,
      tipo_documento: this.form.value.tipo_documento == 0 ? null : this.tipoDocumentoList?.find(tipoDoc => tipoDoc.id == this.form.value.tipo_documento),
      dni: this.form.value.dni?.length > 0 ? this.form.value.dni : null,
      nombre: this.form.value.nombre,
      apellidos: this.form.value.apellidos,
      // fechaVencimientoBTP: this.form.value.fechaVencimientoBTP != null ? this.convertDate(this.form.value.fechaVencimientoBTP, true) : null,
      fechaVencimientoBTP: this.form.value.fechaVencimientoBTP != null ? this.form.value.fechaVencimientoBTP : null,
      domicilio: this.form.value.domicilio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      poblacion: this.form.value.poblacion == 0 ? null : this.form.value.poblacion,
      codigoPostal: this.form.value.codigoPostal != "" ? this.form.value.codigoPostal : null,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      mail: this.form.value.mail?.filter(mail => mail != "" && mail != null),
      cuentaBancaria: this.form.value.cuentaBancaria?.filter(cuentaBancaria => cuentaBancaria != "" && cuentaBancaria != null),
      retencion: this.form.value.retencion,
      valoracion: this.form.value.valoracion,
      puntuacion: this.form.value.puntuacion,
      lopd: this.form.value.lopd,
      riesgosLaborales: this.form.value.riesgosLaborales,
      observaciones: this.form.value.observaciones,
      vehiculos: this.vehiculoToSave,
      // porcentaje: this.form.value.porcentaje,
      // salario_fijo: this.form.value.salario_fijo,
      // nomina: this.form.value.nomina,
      // tipoSueldo: this.form.value.tipoSueldo == 0 ? null : this.tipoSueldoList?.find(tipo => tipo.id == this.form.value.tipoSueldo),
      sueldos: this.sueldosList
    };

    this.conductor.vehiculos = this.vehiculoToSave;
    let objectConductor = new IConductor(this.conductor);

    // if (objectConductor.sueldos.length != 0) {
    //   objectConductor.sueldos.forEach((sueldo) => {
    //     console.log("Sueldo id diferente null: ", sueldo.id != null);
    //     console.log("Sueldo porcentaje condicional diferente null: ", sueldo.porcentajeCondicional != null);
        
    //     sueldo.id = null;
    //     // sueldo.porcentajeCondicional = null;
    //   });
    // }

    if (objectConductor.vehiculos.length != 0) {
      objectConductor.vehiculos.forEach((vehiculo) => {
        vehiculo.licencia = null;
        vehiculo.conductores = null;
        // sueldo.porcentajeCondicional = null;
      });
    }

    if (!this.editar) {
      this.uploadDocuments = true;
      this.conductorService.create(objectConductor).subscribe({
        next: (data) => {
          if (data['message'].status == "608") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
            this.isSubmitted = false;

            if (this.arrayDocuments.length == 0) {
              this.reloadToMenuOption();
            } else {
              this.createDocuments(data['Conductor'].id);
            }
          } else {
            this.uploadDocuments = false;
            this.isSubmitted = true;
            let message = data['message'];
            this._notificationService.error("La operación no se ha podido realizar", message, 3000);
          }
        }
      });
    } else {
      this.uploadDocuments = true;

      this.conductorService.updateOnlyConductor(objectConductor).subscribe({
        next: (data) => {
          if (data.body['message'].status == "608") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
            // this.isSubmitted = false;
            // this.reloadToMenuOption();

            this.updateDocuments(objectConductor.id);
            this.createDocuments(objectConductor.id);

          } else {
            this.isSubmitted = true;
            this.uploadDocuments = false;
            let message = data.body['message'];
            this._notificationService.error("La operación no se ha podido realizar", message, 3000);
          }
        }
      });
    }
  }

  updateDocuments(id) {
    this.conductorService.updateDocuments(id, this.arrayDocuments).subscribe({
      next: (data) => {
        if (data["statusCode"] == '200') {
          // this._notificationService.success("OK", "Documentos subidos correctamente", 3000);
          this.reloadToMenuOption();
        } else {
          this.uploadDocuments = false;
          this._notificationService.error("ERROR", data['message'], 3000);
        }
      }, error: (err) => {
        console.log("Error: ", err);
      },
    });
  }

  createDocuments(id) {
    this.conductorService.saveDocuments(id, this.arrayDocuments).subscribe({
      next: (data) => {
        if (data["statusCode"] == '200') {

          // this.isSubmitted = false;
          this._notificationService.success("OK", "Documentos subidos correctamente", 3000);
          this.reloadToMenuOption();
        } else {
          this.uploadDocuments = false;
          this._notificationService.error("ERROR", data['message'], 3000);
          this.reloadToMenuOption();
        }
      }, error(err) {
        console.log("Error create: ", err);
      },
    });
  }

  resetearForm() {
    this.form.reset();
    this.form.controls.provincia.setValue(0);
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  convertDate(date: string, modo: Boolean): string {
    if (date != null && date != '') {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      } else { //Para convertir el date del back y del objeto conductor al formato del input date        
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia);
      }
    } else return null;
  }

  // -- Select con filtrado
  onKey(event, i) {
    this.nameSearch = event.target.value;
    this.filterList[i].listVehiculos = this.search(event.target.value);
  }

  search(value: string) {
    this.filter = value.toLowerCase();
    return this.keys.filter(option => option.licencia.numero_licencia.includes(this.filter));
  }

  setValueList(i) {
    this.allKeys = this.listaVehiculos;
    this.filterList[i].listVehiculos = this.allKeys;
  }

  focusOut(event) {
    event.target.value = "";
    this.nameSearch = "";
    this.keys = this.listaVehiculos;
  }
  // -- Fin select con filtrado


  // -- Funcionalidad adjuntar ficheros
  arrayDocuments = [];

  filesDropped(event: Event): void {
    event.preventDefault(); // Prevenir el comportamiento predeterminado del navegador al soltar archivos.
    const files: FileList = (event as DragEvent).dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.convertFile(files[i]).subscribe((base64) => {
        const document = { nombre: files[i].name, mime: files[i].type, base64: base64 };
        this.arrayDocuments.push(document);
        // this.form.controls['documento'].setValue(this.arrayDocuments);
      });
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf" ||
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments.push(document);
        // this.form.controls["documento"].setValue(this.arrayDocuments);
      });
    } else {
      this._notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        1500
      );
    }
  }


  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  // -- Métodos para actualizar:
  getDocumentsByConductor() {
    const id = this.conductorUpdate.id;

    this.conductorService.getDocuments(this.conductorUpdate.id).subscribe({
      next: (data) => {
        this.arrayDocuments = data['documentos'];
      }
    })
  }

  downloadDocument(documento) {
    const base64Data = documento.base64;
    const mime = documento.mime;
    const nombre = documento.nombre;


    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mime });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = nombre; // Establece el nombre del archivo

    document.body.appendChild(a);
    a.click();

    // Limpia recursos
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  // -- Fin funcionalidad adjuntar ficheros




  // Pruebas
  private formBuilder: FormBuilder = new FormBuilder;

  get sueldos() {
    return this.form.get("sueldos") as FormArray;
  }

  addSueldos(): void {
    const metadato = this.formBuilder.group({
      id: new FormControl({ value: "", disabled: false }),
      vehiculo: new FormControl({ value: 0, disabled: false }),
      licencia: new FormControl({ value: "", disabled: false }),
      tipoSueldo: new FormControl({ value: 0, disabled: false }),
      cantidad: new FormControl({ value: "", disabled: false }),
      porcentajeCondicional: new FormControl({ value: "", disabled: false }),
    });

    this.sueldos.push(metadato);
  }

  removeSueldos(index: number): void {
    this.sueldos.removeAt(index);
  }

  prepararsueldosCreate() {

    if (this.form.value.sueldos) {
      this.form.value.sueldos.forEach(element => {
        if (element.vehiculo) {
          element.vehiculo = this.listaVehiculos.find(vehiculo => vehiculo.id == element.vehiculo);
          element.tipoSueldo = this.tipoSueldoList.find(tipo => tipo.id == element.tipoSueldo);
          element.licencia = element.vehiculo.licencia;
        }
        if (element.tipoSueldo && element.cantidad != '') {
          this.sueldosList.push(element);
        }
      });
    }
  }

  getSueldosUpdate() {
    this.conductorUpdate.sueldos.forEach((sueldo) => {
      const metadato = this.formBuilder.group({
        id: new FormControl({ value: sueldo.id, disabled: false }),
        vehiculo: new FormControl({ value: sueldo.vehiculo.id, disabled: false }),
        licencia: new FormControl({ value: sueldo.licencia.id, disabled: false }),
        tipoSueldo: new FormControl({ value: sueldo.tipoSueldo.id, disabled: false }),
        cantidad: new FormControl({ value: sueldo.cantidad, disabled: false }),
        porcentajeCondicional: new FormControl({ value: sueldo?.porcentajeCondicional, disabled: false }),
      });

      this.sueldos.push(metadato);
    })
  }
}
export class Proveedor {
    id?: number;
    fechaAlta?: Date | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;
    nombre?: string | null;
    cif?: string | null;

    constructor(mappedData) {
        this.id = mappedData?.id ?? null;
        this.fechaAlta = mappedData?.fechaAlta ?? null;
        this.fechaBaja = mappedData?.fechaBaja ?? null;
        this.fechaModificacion = mappedData?.fechaModificacion ?? null;
        this.nombre = mappedData?.nombre ?? null;
        this.cif = mappedData?.cif ?? null;
    }
}

<div [class]="(environment.environment=='novotaxi')?'page__content_novotaxi':'page__content'"  *ngIf="menuListado">
    <div [class]="(environment.environment=='novotaxi')?'content__inner_novotaxi':'content__inner'">
        
        <div id="container">
            
            <div class="left collapse" [class.show]="(getScreenWidth>768)"  id="navbarSupportedContent" aria-controls="navbarSupportedContent">

                <div *ngFor="let item of menuListado" id="lista-crud">
                    <li *ngIf="item.listadoHijos &&  item.listadoHijos.length == 0 && item.route != 'perfil' && currentUser.rol.includes(item.rol.id)"
                         class="router-group title-menus"
                        [routerLink]="[ item.route ]"
                        (click)="sendData(item.nombre)"
                        routerLinkActive="active {{getCorporativo()}}">
                        <img src="{{ environment.API_URL }}{{ item.icono }}"/> {{ item.nombre }}
                    </li>
                    <div class="parentMenu" *ngIf="item.listadoHijos.length != 0 && currentUser.rol.includes(item.rol.id)">
                        <li class="router-group"
                        (click)="changeCollapse(item)"
                        routerLinkActive="active"
                        data-toggle="collapse" data-parent="#submenu" disableRipple="true">
                        <div class="title-menus">
                            <img class="menuIcon" src="{{ environment.API_URL }}{{ item.icono }}"/> {{ item.nombre }} 
                            <img [ngClass]="!item.isRowActive ? 'chevron-icon-down' : 'chevron-icon-up'" src="/assets/chevron-down-solid.svg" alt="">
                        </div>
                    </li>
                        <li class="collapse" [attr.id]="'desplegable' + item.id"
                        [class]=" item.isRowActive ? 'show' : '' "
                        *ngFor="let submenu of item.listadoHijos">
                            <div class="pl-3" class="desplegable"
                            *ngIf="showMenu(submenu, currentUser) && currentUser.rol.includes(submenu.rol.id)"
                            [routerLink]="[ submenu.url ]"
                            (click)="sendData(submenu.nombre)"
                            routerLinkActive="active {{getCorporativo()}}">
                            {{ submenu.nombre }}
                            </div>
                        </li>
                    </div>
                </div>
            </div>
            <div class="center">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
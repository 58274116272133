import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Licencia } from "src/app/model/Emisora";
import { IGastosIngresos, Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { IConductor } from "src/app/conductores/conductor.model";
import { TipoGastoIngreso } from "src/app/model/novotaxi/gastos-ingresos/tipo-gasto-ingreso.model";
import { GastosIngresosService } from "src/app/_services/novotaxi/gastos-ingresos.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TipoGestion } from "src/app/model/novotaxi/gastos-ingresos/tipo-gestion.model";
import { DatePipe } from "@angular/common";
import { NotificationService } from "src/app/_services/notification.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { DataGastosIngresos } from "../../gastos-ingresos/gastos-ingresos.component";
import { ModalConfirmacionComponent } from "src/app/novotaxi/modal-confirmacion/modal-confirmacion.component";

@Component({
    selector: 'app-create-update-gastos-ingresos',
    templateUrl: './create-update-gastos-ingresos.component.html',
    styleUrls: ['./create-update-gastos-ingresos.component.css']
})

export class CreateUpdateGastosIngresosComponent implements OnInit {

    @Input() data: DataGastosIngresos;
    @Output() mostrarFormulario = new EventEmitter<DataGastosIngresos>();

    gastosList = [{ fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, tipo_ingreso: null, tipo_gasto: null, posiblesConductores: null, tipoGestion: null, cantidadPropietario: null, cantidadConductor: null }];

    // -- Variables para el control del usuario
    currentUser;
    userSaved: Usuario = null;
    isUserAdmin: boolean = true;

    // -- Variables para el guardado de los gastos e ingresos
    fechaAlta: Date;
    gastoIngresoSaved: IGastosIngresos;
    selectedValueLicencia: number = null;
    selectedValueConductor: number = null;
    tipoGastoSelected: number = null;
    tipoIngresoSelected: number = null;
    gastoIngresoCreate: IGastosIngresos = {};
    gastoIngresoUpdate: IGastosIngresos = {};

    // -- Listas
    listTipoGasto: TipoGastoIngreso[] = [];
    listTipoIngreso: TipoGastoIngreso[] = [];
    licencias: Licencia[] = [];
    conductores: IConductor[] = [];
    gastosIngresosList: IGastosIngresos[] = [];

    constructor(
        private _conductorService: ConductorService,
        private _gastosIngresosService: GastosIngresosService,
        private _notificationService: NotificationService,
        private _servicioGeneral: ServicioGeneral,
        private _tokenStorageService: TokenStorageService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {

        if (!this.data.licencias) {
            this._notificationService.info("INFO", `No existen licencias disponibles para crear un ${this.data.tipo.toLowerCase()}`, 3000)
        }

        this.licencias = this.data.licencias;
        this.getTiposGasto();
        this.getTiposIngreso();
        this.getConductores();

        this.currentUser = this._tokenStorageService.getUser();
        this.getUser();

        if (this.data.isForUpdate) {
            this.getDataForUpdate();
        }
    }

    getConductores() {
        this._conductorService.query().subscribe((res) => {
            this.conductores = res['conductores']
        });
    }

    getUser() {
        this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
            this.userSaved = data['Usuario'];
        })
    }

    getDataForUpdate() {
        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

        if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && this.data.elementUpdate.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un gasto/ingreso del día actual", 3000)
            this.isUserAdmin = false;
        } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && this.data.elementUpdate.fechaAlta != currentDate) {
            this._notificationService.info("INFO", "No tiene permisos, sólo puede actualizar un gasto/ingreso del día actual", 3000)
            this.isUserAdmin = false;
        } else {
            this.isUserAdmin = true;
        }

        this.gastosList = [
            {
                fechaAlta: this.data.elementUpdate.fechaAlta,
                licencia: this.data.elementUpdate?.licencia,
                conductor: this.data.elementUpdate.conductor,
                importe: this.data.elementUpdate.importe,
                concepto: this.data.elementUpdate.concepto,
                tipoGestion: this.data.elementUpdate.tipoGestion,
                numDocumento: this.data.elementUpdate.numDocumento,
                tipo_ingreso: this.data.elementUpdate.tipo_ingreso,
                tipo_gasto: this.data.elementUpdate.tipo_gasto,
                posiblesConductores: this.data.conductores,
                cantidadConductor: this.data.elementUpdate.cantidadConductor,
                cantidadPropietario: this.data.elementUpdate.cantidadPropietario,
            }
        ]

        this.fechaAlta = new Date(this.data.elementUpdate.fechaAlta.toString().split("-").reverse().join("-"));
        this.selectedValueLicencia = this.data.elementUpdate.licencia?.id;
        this.selectedValueConductor = this.data.elementUpdate.conductor?.id ? this.data.elementUpdate.conductor?.id : null;
        this.tipoGastoSelected = this.data.elementUpdate.tipo_gasto?.id ? this.data.elementUpdate.tipo_gasto?.id : null;
        this.tipoIngresoSelected = this.data.elementUpdate.tipo_ingreso?.id ? this.data.elementUpdate?.tipo_ingreso?.id : null;
    }

    saveOrUpdate() {
        if (this.data.isForUpdate) {
            this.updateGastoingreso();
        } else {
            this.createGastoIngreso();
        }
    }

    addGastoIngreso() {
        this.gastosList.push({
            fechaAlta: null, licencia: null, conductor: null, importe: null, concepto: null, numDocumento: null, tipo_ingreso: null, tipo_gasto: null, posiblesConductores: null, tipoGestion: null, cantidadPropietario: null, cantidadConductor: null
        });
    }

    removeGastoIngreso(i) {
        this.gastosList.splice(i, 1);
    }

    createGastoIngreso() {

        this.gastosList.forEach(element => {
            this.gastoIngresoCreate = JSON.parse(JSON.stringify(element))

            this.gastoIngresoCreate.fechaAlta = this.datePipe.transform(this.gastoIngresoCreate.fechaAlta, 'dd-MM-yyyy');

            if (this.gastoIngresoCreate.conductor) {
                this.gastoIngresoCreate.conductor.fechaAlta = this.convertDate(this.gastoIngresoCreate?.conductor?.fechaAlta);
                if (this.gastoIngresoCreate?.conductor?.fechaVencimientoBTP) {
                    this.gastoIngresoCreate.conductor.fechaVencimientoBTP = this.convertDate(this.gastoIngresoCreate?.conductor?.fechaVencimientoBTP);
                }
            }

            if (this.data.tipo.toLowerCase() == 'gasto') {
                this.gastoIngresoCreate.tipoGestion = TipoGestion.GASTO;
            } else {
                this.gastoIngresoCreate.tipoGestion = TipoGestion.INGRESO;
            }

            if (typeof this.gastoIngresoCreate.conductor == 'string') {
                this.gastoIngresoCreate.conductor = null
            }

            if (typeof this.gastoIngresoCreate.licencia == 'string') {
                this.gastoIngresoCreate.licencia = null
            }

            if (this.gastoIngresoCreate.numDocumento == '') {
                this.gastoIngresoCreate.numDocumento = null
            }

            if (this.gastoIngresoCreate.importe != null && this.gastoIngresoCreate.licencia != null) {
                delete element['posiblesConductores'];
                this.gastoIngresoCreate.usuario = this.userSaved;
                let newGasto: IGastosIngresos = new IGastosIngresos(this.gastoIngresoCreate);
                newGasto.licencia.vehiculo = null;
                this.gastosIngresosList.push(newGasto);
            } else {
                this._notificationService.info('INFO', 'No se puede crear un gasto/ingreso sin importe y sin licencia', 3000)
            }
        });

        if (this.gastosIngresosList.length > 0) {
            this._gastosIngresosService.saveAllGastosIngresos(this.gastosIngresosList).subscribe((data) => {

                if (data['message']['status'] == 400) {
                    this._notificationService.error("ERROR", data['message']['message'], 3000)
                } else {
                    this.data.gastosIngresos = data['gastosIngresos'];
                    this._notificationService.success("Hecho", this.data.tipo.toLowerCase() + '/s creado correctamente', 3000)
                    this.resetearForm(false);
                }
                this.gastosIngresosList = [];
            })
        }
    }

    updateGastoingreso() {
        delete this.gastosList[0]['posiblesConductores']

        this.gastoIngresoUpdate = JSON.parse(JSON.stringify(this.data.elementUpdate));
        let dataUpdate = JSON.parse(JSON.stringify(this.gastosList[0]));

        this.gastoIngresoUpdate.importe = dataUpdate.importe;
        this.gastoIngresoUpdate.concepto = dataUpdate.concepto;
        this.gastoIngresoUpdate.numDocumento = dataUpdate.numDocumento;
        this.gastoIngresoUpdate.licencia = this.licencias.find(licencia => licencia.id == this.selectedValueLicencia);
        this.gastoIngresoUpdate.conductor = this.conductores.find(conductor => conductor.id == this.selectedValueConductor);
        this.gastoIngresoUpdate.fechaAlta = this.datePipe.transform(this.fechaAlta, 'dd-MM-yyyy');
        this.gastoIngresoUpdate.tipo_gasto = this.listTipoGasto.find(gasto => gasto.id == this.tipoGastoSelected);
        this.gastoIngresoUpdate.tipo_ingreso = this.listTipoIngreso.find(ingreso => ingreso.id == this.tipoIngresoSelected);
        this.gastoIngresoUpdate.licencia.vehiculo = null;
        this.gastoIngresoUpdate.cantidadPropietario = dataUpdate.cantidadPropietario;
        this.gastoIngresoUpdate.cantidadConductor = dataUpdate.cantidadConductor;

        if (this.gastoIngresoUpdate.conductor) {
            this.gastoIngresoUpdate.conductor.fechaAlta = this.convertDate(this.gastoIngresoUpdate?.conductor?.fechaAlta);
            if (this.gastoIngresoUpdate?.conductor?.fechaVencimientoBTP) {
                this.gastoIngresoUpdate.conductor.fechaVencimientoBTP = this.convertDate(this.gastoIngresoUpdate?.conductor?.fechaVencimientoBTP);
            }
        }

        this._gastosIngresosService.update(new IGastosIngresos(this.gastoIngresoUpdate)).subscribe((data) => {
            if (data.body['status'] == 608) {
                this.data.gastosIngresos.push(data.body['gastosIngresos']);
                this._notificationService.success('OK', "Gasto/Ingreso modificado correctamente", 3000)
                this.resetearForm(true);
            } else {
                this._notificationService.error('ERROR', data.body['message']['message'], 3000)
            }
        })
    }

    async openDialogConfirm() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = 'guardar';

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.saveOrUpdate();
            } else {
                null
            }
        })
    }

    async confirmCancelOperation() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = 'descartar';

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {
                this.resetearForm(true)
            } else {
                null
            }
        })
    }

    getValue(event) {
        this.fechaAlta = event.target.value
    }

    async getConductoresByLicencia(gasto?) {
        gasto.conductor = null;
        this.selectedValueConductor = null;
        let idLicencia = null;

        if (!this.data.isForUpdate) {
            idLicencia = gasto.licencia.id;
        } else {
            idLicencia = this.selectedValueLicencia;
        }

        gasto.posiblesConductores = []
        await this._conductorService.getConductorByLicencia(idLicencia).subscribe((res) => {
            gasto.posiblesConductores = res['conductores'];
            if (res['conductores'] == null) {
                this._notificationService.info("INFO", "No existen conductores para la licencia seleccionada", 3000)
            }
        });
    }

    getTiposGasto() {
        this._gastosIngresosService.getAllTiposGasto().subscribe((data) => {
            this.listTipoGasto = data['tiposGastos']
        })
    }

    getTiposIngreso() {
        this._gastosIngresosService.getallTiposIngreso().subscribe((data) => {
            this.listTipoIngreso = data['tiposIngreso']
        })
    }

    resetearForm(isCancel: Boolean) {
        this.data.mostrarFormulario = false;
        this.mostrarFormulario.emit(this.data);
    }

    convertDate(fecha) {
        const splitDate = fecha.split('-');
        const date = new Date(
            Number(splitDate[2]),   // year
            Number(splitDate[1]) - 1, // month
            Number(splitDate[0])    // day
        );
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    calcularImporteConductor(i, event) {
        var importe = this.gastosList[i].importe;
        var cantidadPropietario = event.target.value;

        if (cantidadPropietario > importe) {
            this._notificationService.warning("ALERTA", "La cantidad del propietario no puede ser mayor que el importe total del gasto", 3000);
            this.gastosList[i].cantidadConductor = 0;
            this.gastosList[i].cantidadPropietario = 0;
            return;
        }

        this.gastosList[i].cantidadConductor = importe - cantidadPropietario;
    }

    calcularImportePropietario(i, event) {
        var importe = this.gastosList[i].importe;
        var cantidadConductor = event.target.value;

        if (cantidadConductor > importe) {
            this._notificationService.warning("ALERTA", "La cantidad del conductor no puede ser mayor que el importe total del gasto", 3000);
            this.gastosList[i].cantidadConductor = 0;
            this.gastosList[i].cantidadPropietario = 0;
            return;
        }

        this.gastosList[i].cantidadPropietario = importe - cantidadConductor;
    }
}
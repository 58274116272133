import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.novotaxi';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { 
     
  }
  currentEnv = environment.environment;
  environment = environment;

  ngOnInit(): void {
    
  }
}

export const environment = {
    //production: false,
    production: true,
    environment: 'novotaxi',
    // API_URL: "http://localhost:7090",
    // PRE
    // API_URL: "https://novotaxi-desa.aeioros.com/portal",
    // PRO
    API_URL: "https://gestion.novotaxi.com.es/portal",

    optionalModules: [],

    TITLE_HEAD: 'NovoTaxi',
    ICON_TITLE_HEAD: 'assets/novotaxi/favicon.ico',
    TITLE_REGISTER: 'Bienvenido a la Gestión de NovoTaxi',
    TEXT_REGISTER: '',
    ICON_LATERAL: 'assets/novotaxi/novotaxi_logo_transparent.png',
    LOGO_HEAD: 'assets/novotaxi/novotaxi_logo.png',
    TIENE_CALENDARIO: false,
    TIENE_INFO: false,
    TIENE_BUSQUEDA: false,
    COPYRIGHT: '@Copyright - Novotaxi Madrid, S.L. Todos los derechos reservados',
    FOOTER_INFO_TITLE: ['NovoTaxi Gestión'],
    FOOTER_INFO: ['28012 Madrid'],
};
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConductor } from 'src/app/conductores/conductor.model';
import { Licencia } from 'src/app/model/Emisora';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { DiasLibranza } from 'src/app/model/novotaxi/dias-libranza.model';
import { DatePipe } from '@angular/common';
import { DiasLibranzaService } from 'src/app/_services/novotaxi/dias-libranza.service';
import { DataDiasLibranza } from '../listado-dias-libranza/listado-dias-libranza.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-update-dias-libranza',
  templateUrl: './update-dias-libranza.component.html',
  styleUrls: ['./update-dias-libranza.component.css']
})
export class UpdateDiasLibranzaComponent {

  @Input() data: DataDiasLibranza;
  @Output() mostrarFormulario = new EventEmitter<DataDiasLibranza>();

  permisosList = [{ fecha_cambio: null, licencia: null, conductor: null, user: null }];

  // -- Listas
  conductores: IConductor[] = [];
  licencias: Licencia[] = [];
  usuarios: Usuario[] = [];

  selectedValueLicencia: number = null;
  selectedValueConductor: number = null;
  selectedValueUsuario: number = null;

  diasLibranza: DiasLibranza;

  fechaLibranzaActual: string;
  fechaLibranzaNueva: string;

  constructor(
    private _conductorService: ConductorService,
    private _notificationService: NotificationService,
    private _servicioGeneral: ServicioGeneral,
    private _diasLibranzaService: DiasLibranzaService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.diasLibranza = JSON.parse(JSON.stringify(this.data.elementUpdate));
    this.getConductoresByLicencia(this.data.elementUpdate.licencia.id);
    this.fechaLibranzaActual = this.data?.elementUpdate?.fecha_libranza_actual.toString().split("-").reverse().join("-");
    this.fechaLibranzaNueva = this.data?.elementUpdate?.fecha_libranza_cambio.toString().split("-").reverse().join("-");
    this.selectedValueConductor = this.data?.elementUpdate?.conductor?.id;
    this.selectedValueLicencia = this.data?.elementUpdate?.licencia?.id;
    this.selectedValueUsuario = this.data?.elementUpdate?.user?.id;

    this.licencias = this.data.licencias;
    this.getUsersList();
  }

  getValue(event, isFechaChanged) {
    if (!isFechaChanged) {
      this.fechaLibranzaActual = event.target.value
    } else {
      this.fechaLibranzaNueva = event.target.value
    }
  }

  getConductoresByLicencia(id) {
    if (id != null) {
      this._conductorService.getConductorByLicencia(id).subscribe((response) => {
        this.conductores = response['conductores'];
      });
    }
  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'guardar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.actualizar();
      } else {
        null
      }
    })
  }

  actualizar() {
    this.diasLibranza.conductor = this.conductores.find(element => element.id == this.selectedValueConductor);
    this.diasLibranza.licencia = this.licencias.find(element => element.id == this.selectedValueLicencia);
    this.diasLibranza.user = this.usuarios.find(element => element.id == this.selectedValueUsuario);
    this.diasLibranza.fecha_libranza_actual = this.datePipe.transform(this.fechaLibranzaActual, 'dd-MM-yyyy');
    this.diasLibranza.fecha_libranza_cambio = this.datePipe.transform(this.fechaLibranzaNueva, 'dd-MM-yyyy');
    this.diasLibranza.id = this.data?.elementUpdate?.id

    if (this.diasLibranza.fecha_libranza_actual != null && this.diasLibranza.fecha_libranza_cambio != null && this.diasLibranza.licencia != null && this.diasLibranza.conductor != null) {
      this._diasLibranzaService.updateDiasLibranza(new DiasLibranza(this.diasLibranza)).subscribe({
        next: (data) => {
          if (data["status"] == "OK") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
          } else {
            this._notificationService.error("La operación no se ha podido realizar", "Error al guardar en base de datos"), 3000;
          }
          this.resetearForm();
        }
      });
    } else {
      this._notificationService.info('INFO', 'Debe rellenar todos los datos para actualizar', 3000)
    }
  }

  getUsersList() {
    this._servicioGeneral.getUserList().subscribe((data) => {
      this.usuarios = data['Usuario']
    })
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      } else {
        null
      }
    })
  }

  resetearForm() {
    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }
}

<form [formGroup]="form" (ngSubmit)="!editar ? submit() : openDialogConfirmarUpdate()">
  
    <h1 class="titulo-formulario" *ngIf="!editar && isMarca">Crea una nueva marca</h1>
    <h1 class="titulo-formulario" *ngIf="!editar && !isMarca">Crea un nuevo modelo</h1>
    <h1 class="titulo-formulario" *ngIf="editar && !isMarca">Edita un modelo</h1>

    <ng-container *ngIf="isMarca && !editar">
      <div class="row">
        <div class="form-group col-sm-3">
          <label for="nombre">Marca <span style="color: red;">*</span></label>
          <input class="form-control" type="text" id="nombre" formControlName="nombre">
          <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es obligatorio</small>
        </div>
      </div> 
    </ng-container>

    <ng-container *ngIf="!isMarca || editar">
      <div class="row">
        <div class="form-group col-sm-3">
          <label for="marca">Marca <span style="color: red;">*</span></label>
          <select id="marca" class="form-control form-select" formControlName="marca">
            <option [value]="0">Selecciona una marca</option>
            <option [value]="marca.id" *ngFor="let marca of listaMarcas">{{marca.nombre}}</option>
          </select>
          <small class="text-danger" *ngIf="isSubmitted && form.get('marca').hasError('min')">El campo es obligatorio</small>
        </div>
        <div class="form-group col-sm-3">
          <label for="nombre">Modelo</label>
          <input class="form-control" type="text" id="nombre" formControlName="nombre">
          <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es obligatorio</small>
        </div>     
      </div>       
    </ng-container>

    <div id="container" class="d-flex justify-content-end pt-3">
      <button class="btn btn-success mx-3" type="submit" [disabled]="editar && !form.dirty">Guardar</button>
      <button class="btn btn-secondary" type="button" (click)="editar && form.dirty ? openDialogConfirmarUnsaved() : resetearForm()">Cerrar</button>  
    </div>
</form>
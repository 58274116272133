import { IMarca, IModelo, NewModelo } from '../modelo.model';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';

export type PartialUpdateMarca = Partial<IMarca> & Pick<IMarca, 'id'>;

type RestOf<T extends IMarca | NewModelo> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestModelo = RestOf<IModelo>;

export type NewRestModelo = RestOf<NewModelo>;

export type EntityResponseType = HttpResponse<IMarca>;
export type EntityArrayResponseType = HttpResponse<IMarca[]>;

@Injectable({
    providedIn: 'root'
  })
  export class MarcaService {
  
    protected resourceUrl = `${environment.API_URL}/modelo`;
  
    constructor(protected http: HttpClient) { }
  
    create(modelo: IMarca){
      return this.http.post(`${this.resourceUrl}/`, modelo);
    }

    createMarca(marca: IMarca){
      return this.http.post(`${environment.API_URL}/marca/`, marca);
    }
  
    update(modelo: IModelo): Observable<EntityResponseType> {
      return this.http
        .post<RestModelo>(`${this.resourceUrl}/`, modelo, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<RestModelo>(`${this.resourceUrl}/${id}`, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    query(req?: any){
      return this.http.get<any>(this.resourceUrl, {})
    }

    queryMarcas(req?: any){
      return this.http.get<any>(`${environment.API_URL}/marca/`, {})
    }
  
    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    protected convertDateFromClient<T extends IModelo | NewModelo | PartialUpdateMarca>(modelo: T): any {
        return {
          ...modelo,
          fechaAlta: modelo.fechaAlta?.toString() ?? null,
          fechaBaja: modelo.fechaBaja?.toISOString() ?? null,
          fechaModificacion: modelo.fechaModificacion?.toISOString() ?? null,
        };
      }
    
      protected convertDateFromServer(RestModelo: RestModelo): IModelo {
        let fechaAlta = RestModelo.fechaAlta?.split("-");
        let fechaBaja = RestModelo.fechaBaja?.split("-");
        let fechaModificacion = RestModelo.fechaModificacion?.split("-");
        return {
          ...RestModelo,
          fechaBaja: RestModelo.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
          fechaModificacion: RestModelo.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
          
        };
      }

    protected convertResponseFromServer(res: HttpResponse<RestModelo>): HttpResponse<IModelo> {
        return res.clone({
          body: res.body ? this.convertDateFromServer(res.body) : null,
        });
      }
    
      protected convertResponseArrayFromServer(res: HttpResponse<RestModelo[]>): HttpResponse<IModelo[]> {
        return res.clone({
          body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
        });
      }
}
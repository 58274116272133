import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.novotaxi';
import { Observable } from 'rxjs/internal/Observable';
import { TokenStorageService } from './token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class LicenciaServiece {


  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  listLicencia(): Observable<any> {
    return this.http.get(`${this.API_URL}/licencia/list`, {})
  }

  listLicenciasActivas(): Observable<any> {
    return this.http.get(`${this.API_URL}/licencia/list/active`, {})
  }

  listEmisora(): Observable<any> {
    return this.http.get(`${this.API_URL}/emisora/list`, {})
  }

  listMunicipio() {
    return this.http.get(`${this.API_URL}/municipio/list`, {})
  }

  listGestoria(): Observable<any> {
    return this.http.get(`${this.API_URL}/gestoria/list`, {})
  }

  saveLicencia(licencia) {
    return this.http.post(`${this.API_URL}/licencia/save`, licencia, {
    });
  }

  updateLicencia(licencia) {
    return this.http.post(`${this.API_URL}/licencia/update`, licencia, {
    });
  }

  deleteLicencia(id, fecha) {
    return this.http.post(`${this.API_URL}/licencia/delete/${id}`, fecha);
  }

  getHistorico(id) {
    return this.http
      .get(`${this.API_URL}/licencia/getHistorico/${id}`)
  }

  getHistoricoAsociacion(id) {
    return this.http
      .get(`${this.API_URL}/propietarioLicenciaAsociacion/list/${id}`)
  }

  getLicenciaById(id) {
    return this.http
      .get(`${this.API_URL}/licencia/${id}`)
  }

  getDetails() {
    return this.http
      .get(`${this.API_URL}/licencia/getDetails`)
  }

  getAllLicencias(request?) {
    return this.http
      .post(`${this.API_URL}/licencia/listAll`, request)
  }
}

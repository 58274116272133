import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormat implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value): string {
    return value.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

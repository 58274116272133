import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConductor } from 'src/app/conductores/conductor.model';
import { Licencia } from 'src/app/model/Emisora';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { DiasLibranzaService } from 'src/app/_services/novotaxi/dias-libranza.service';
import { DataDiasLibranza } from '../listado-dias-libranza/listado-dias-libranza.component';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { DiasLibranza } from 'src/app/model/novotaxi/dias-libranza.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-dias-libranza',
  templateUrl: './create-dias-libranza.component.html',
  styleUrls: ['./create-dias-libranza.component.css']
})

export class CreateDiasLibranzaComponent {

  @Input() data: DataDiasLibranza;
  @Output() mostrarFormulario = new EventEmitter<DataDiasLibranza>();

  diasLibranzaList = [{ fecha_libranza_actual: null, fecha_libranza_cambio: null, licencia: null, conductor: null, posiblesConductores: null }];

  // -- listas
  conductores: IConductor[] = [];
  licencias: Licencia[] = [];
  diasLibranzaListToSave: DiasLibranza[] = [];

  diaLibranzaCreate: DiasLibranza;
  isValidFields: boolean = false;

  // -- Variables para obtener el usuario
  currentUser;
  userSaved: Usuario = null;


  constructor(
    private _licenciaService: LicenciaServiece,
    private _conductorService: ConductorService,
    private _notificationService: NotificationService,
    private _servicioGeneral: ServicioGeneral,
    private _tokenStorageService: TokenStorageService,
    private _diasLibranzaService: DiasLibranzaService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.currentUser = this._tokenStorageService.getUser();
    this.getUser();

    if (this.data.licencias == null) {
      this._notificationService.info("INFO", `No existen licencias disponibles para cambiar días de libranza`, 3000)
    }

    this.licencias = this.data.licencias;
  }

  getUser() {
    this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
      this.userSaved = data['Usuario'];
    })
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe(
      (data) => {
        this.licencias = data.licencias;
        if (data.licencias == null) {
          this._notificationService.info("INFO", "No existen licencias disponibles para crear una recaudación", 3000);
        }
      });
    if (this.licencias[0]?.vehiculo?.length >= 0) {
      this.licencias[0].vehiculo = null;
    }
  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'guardar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.guardar();
      } else {
        null
      }
    })
  }

  async getConductoresByLicencia(diaLibranza?) {
    diaLibranza.conductor = null;
    let idLicencia = null;

    if (diaLibranza.licencia != null) {
      idLicencia = diaLibranza.licencia?.id;

      diaLibranza.posiblesConductores = []
      await this._conductorService.getConductorByLicencia(idLicencia).subscribe((res) => {
        diaLibranza.posiblesConductores = res['conductores'];
        if (res['conductores'] == null) {
          this._notificationService.info("INFO", "No existen conductores para la licencia seleccionada", 3000)
        }
      });
    }
  }

  guardar() {
    this.isValidFields = true;
    this.diasLibranzaList.forEach((element) => {
      this.diaLibranzaCreate = JSON.parse(JSON.stringify(element));

      this.diaLibranzaCreate.fecha_libranza_actual = this.convertDate(element.fecha_libranza_actual, true);
      this.diaLibranzaCreate.fecha_libranza_cambio = this.convertDate(element.fecha_libranza_cambio, true);

      if (this.diaLibranzaCreate.fecha_libranza_actual != null && this.diaLibranzaCreate.fecha_libranza_cambio != null && this.diaLibranzaCreate.licencia != null && this.diaLibranzaCreate.conductor != null) {
        delete element['posiblesConductores'];
        this.diaLibranzaCreate.user = this.userSaved;
        let newDiaLibranza: DiasLibranza = new DiasLibranza(this.diaLibranzaCreate);
        newDiaLibranza.conductor.fechaAlta = null;
        this.diasLibranzaListToSave.push(newDiaLibranza);
      } else {
        this.isValidFields = false;
      }
    })

    if (this.isValidFields) {
      this._diasLibranzaService.saveAllDiasLibranza(this.diasLibranzaListToSave).subscribe({
        next: (data) => {
          if (data["status"] == "OK") {
            this._notificationService.success("OK", "Los datos han sido guardados correctamente", 3000);
          } else {
            this._notificationService.error("ERROR", "Error al guardar en base de datos"), 3000;
          }
          this.resetearForm();
        }, error: (error: HttpErrorResponse) => { // Mueve el manejo de errores aquí
          console.error(`Error: ${error.message}`);
          console.error(`Status code: ${error.status}`);
        }
      });
    } else {
      this._notificationService.info('INFO', 'Debe rellenar todos los datos para guardar', 3000)
    }
  }

  removePrestamo(i) {
    this.diasLibranzaList.splice(i, 1);
  }

  addPrestamo() {
    this.diasLibranzaList.push({
      fecha_libranza_actual: null, fecha_libranza_cambio: null, licencia: null, conductor: null, posiblesConductores: null
    });
  }

  convertDate(date: string, modo: Boolean): string {
    if (date != null && date != '') {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm()
      } else {
        null
      }
    })
  }

  resetearForm() {
    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }
}

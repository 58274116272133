import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ServicioGeneral } from "../_services/servicio-general.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormGroup, FormControl } from "@angular/forms";
import { UrlService } from "../_services/urlService";
import { environment } from 'src/environments/environment.novotaxi';
import { UserService } from "../_services/user.service";
import { NotificationService } from "../_services/notification.service";
import { RaizComponent } from "../raiz/raiz.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {

  currentEnv = environment.environment;  
  
  form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    totpkey: new FormControl(""),
  });

  form2: FormGroup = new FormGroup({
    totpkey: new FormControl("", [Validators.required, Validators.minLength(6)])
  });

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = "";
  midata: string[] = [];
  tfaFlag: boolean = false;
  tfa: string = null;
  userRoles: any = [];
  submitted = false;
  loading = false;
  returnUrl: string;
  errorTfa: boolean;
  check2fa: boolean = false;
  errorQR: boolean;
  tfActivated: boolean;
  resetQR: boolean = false;
  resetedQR: boolean = false;
  environment: string = environment.environment;
  previousUrl: string = '';
  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private urlService: UrlService,
    private _notificationSvc: NotificationService,
    private raiz: RaizComponent
  ) {
  }

  ngOnInit(): void {
    this.checkPrevious()
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.midata = this.tokenStorage.getUser();
    }
    if (this.previousUrl) {
      this.returnUrl = this.previousUrl
    } else {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/profile";
    }
  }

  checkPrevious() {
    this.previousUrl = this.urlService.getPreviousUrl();
  }



  onSubmit(): any {
    this.submitted = true;

    if (this.form.get('email').hasError('pattern')) {
      this._notificationSvc.warning('Información', 'El email no es válido.', 5000);
      return false;
    }
    if (this.form.get('password').hasError('minlength')) {
      this._notificationSvc.warning('Información', 'La contraseña debe contener 6 caracteres.', 5000);
      return false;
    }
    if (!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 5000);
    } else {
      this.authService.login(this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['totpkey'].value).subscribe({
        next: async (data) => {

          if (data['2faEnabled'] === "true" && data['tfa'] === "disabled") {
            this.tokenStorage.saveToken(data.accessToken);
            this.resetearQR();
          } else if (data['tfa'] === "disabled") {
            //console.log('entro en disabled');
            this.tokenStorage.saveToken(data.accessToken);
            this.loginTfaDisabled();
          } else if (data['tfa'] !== "disabled") {
            //console.log('entro en enabled');
            this.form.controls['totpkey'].addValidators([Validators.required, Validators.minLength(6)])
            this.submitted = false;
            this.tfaFlag = true;
          }
        },
        error: (error) => {
          console.log(error);

          if (error.error.status == 650) {
            this._notificationSvc.error('Error', 'Datos de acceso inválidos', 50000);
          }
          this.isLoginFailed = true;
        },
      });
    }
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  loginTfaEnabled(): any {
    this.submitted = true;

    if (this.form.get('totpkey').hasError('minlength')) {
      this._notificationSvc.error('Error', 'El código debe contener 6 caracteres.', 5000);
      return false;
    }

    if (!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 5000);
      return false;
    }
    this.authService.login(this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['totpkey'].value).subscribe({
      next: (dataTotpkey) => {
        if (dataTotpkey.codigoError !== "INVALID_2FA") {
          this.tokenStorage.saveToken(dataTotpkey.accessToken);
          this.authService.updateAuthStatus(true);
          this.userService.getCurrentUserFromServer().subscribe({
            next: (data) => {
              console.log(data);
              this.tokenStorage.setUser(data.Usuario);
              this.raiz.reloadHeader = !this.raiz.reloadHeader;
              if (this.environment == 'novotaxi') {
                this.router.navigateByUrl('/admin-novotaxi').then(() => {
                  this.router.navigate(['admin-novotaxi']);
                });
              } else {
                this.router.navigateByUrl(this.previousUrl);
              }
            },
            error: (error) => {
              //TODO error
            }
          })
        } else {
          this._notificationSvc.error('Error', 'El código no es correcto.', 5000);
          this.form.controls['totpkey'].setErrors({ 'incorrect': true });
        }
      },
      error: (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      },
    })
  }

  loginTfaDisabled(): any {
    this.isLoggedIn = true;
    this.isLoginFailed = false;
    this.authService.updateAuthStatus(true);
    this.userService.getCurrentUserFromServer().subscribe({
      next: (data) => {
        this.tokenStorage.setUser(data.Usuario);
        this.raiz.reloadHeader=!this.raiz.reloadHeader;
        if (this.environment == 'novotaxi') {
          this.router.navigateByUrl('/admin-novotaxi').then(() => {
            this.router.navigate(['admin-novotaxi/gestion-licencias']);
          });
        } else {
          this.router.navigateByUrl(this.previousUrl);
        }
      },
      error: (error) => {

      }
    })

  }

  resetearQR(): any {
    this.resetQR = true;
    this.pedirQR();
    //this.guardarQR();
  }

  qr;
  pedirQR(): any {
    this.submitted = false;
    this.authService.pedirQr().subscribe({
      next: (data) => {

        console.log(data);
        this.qr = data;

      },
      error: (err) => {
        console.log('Error de qr');

        console.log(err);
      }
    });
  }

  guardarQR(): any {
    this.submitted = true;

    if (this.form2.get('totpkey').hasError('minlength')) {
      this._notificationSvc.error('Error', 'El código debe contener 6 caracteres.', 5000);
      return false;
    }

    if (!this.form2.valid) {
      this._notificationSvc.warning('Error', 'Faltan campos por rellenar', 50000);
    } else {
      this.authService.guardarQR(this.qr.secret, this.form2.get('totpkey').value).subscribe({
        next: (data) => {
          this.qr = data;
          if (this.qr.result === "success") {
            this.tfActivated = true;
            this.errorQR = false;
            this.resetedQR = true;
            this.authService.updateAuthStatus(true);
            this.userService.getCurrentUserFromServer().subscribe({
              next: (data) => {
                console.log(data);

                this.tokenStorage.setUser(data.Usuario);
                this.raiz.reloadHeader = !this.raiz.reloadHeader;
                this.router.navigateByUrl(this.previousUrl);
              },
              error: (error) => {

              }
            })
          } else {
            this.form2 = this.formBuilder.group({
              totpkey: [""]
            });
            this.errorQR = true;
          }
        },
        error: (error) => {
          if (error.error.codigo == 637) {
            this._notificationSvc.error('Error', 'Código incorrecto', 50000);
          }
        }
      });
    }
  }
}


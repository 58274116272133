import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Licencia } from 'src/app/model/Emisora';
import { Gestoria, IProvincia, Propietario } from 'src/app/model/novotaxi/propietario.model';
import { DataPropietario } from '../gestion-propietarios.component';
import { NotificationService } from 'src/app/_services/notification.service';
import { PropietarioService } from 'src/app/_services/novotaxi/propietario.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { IVehiculo } from 'src/app/vehiculo/vehiculo.model';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { Documento } from 'src/app/model/documento';
import { Observable, ReplaySubject } from 'rxjs';
import { error } from 'console';

@Component({
  selector: 'app-create-update-propietario',
  templateUrl: './create-update-propietario.component.html',
  styleUrls: ['./create-update-propietario.component.css']
})

export class CreateUpdatePropietarioComponent implements OnInit {

  @Input() data: DataPropietario;
  @Output() mostrarFormulario = new EventEmitter<DataPropietario>();

  isSelected = true;
  emails = [{ value: '' }];
  telefonos = [{ value: '' }];
  ibanList = [{ value: '' }];
  emailsSaved: string[] = [];
  telefonosSaved: string[] = [];
  ibanSaved: string[] = [];
  licenciaSaved: any[] = [];
  municipiosToSelect: any;
  gestorias: Gestoria[] = [];
  licencias: Licencia[] = [];
  tipoDocumentoList: Documento[] = [];
  selectedValueGestoria: string = "0";
  selectedTipoDocumento: number = null;
  provincias: IProvincia[] = [];
  selectedValueProvincia: any = 0;
  selectedValueMunicipio: any = "0";
  propietario: Propietario = {};
  fechaAlta: Date;
  licenciasAsociadas = [{ value: '' }];
  listaMunicipios;

  // -- Variables histórico
  mostrarHistorico: Boolean = false;
  mostrarBotones: Boolean = true;
  propietarioHistorico: Propietario[] = [];
  propietarioLicenciaHistorico: any;

  licenciasAdded = [];

  constructor(
    private dialog: MatDialog,
    private _propietarioService: PropietarioService,
    private _servicioGeneral: ServicioGeneral,
    private _notificationService: NotificationService,
    private _servicioLicencia: LicenciaServiece,
  ) { }

  ngOnInit(): void {

    // this.listMunicipios();
    this.listaMunicipios = this.data.municipios;
    this.cargaMunicipios(this.data.elementUpdate?.provincia?.id);
    this.getTipoDocumento();
    this.getGestorias();
    this.getProvincias();
    this.getLicencia();

    if (this.data.isForUpdate) {
      this.getHistoricoPropietario();
      this.getDocumentsByPropietario();

      this.propietario = JSON.parse(JSON.stringify(this.data.elementUpdate));

      //this.fechaAlta = new Date(this.propietario.fechaAlta)
      this.fechaAlta = this.propietario.fechaAlta
      this.selectedValueProvincia = this.propietario.provincia?.id ? this.propietario.provincia?.id : 0;
      this.selectedValueGestoria = this.propietario.gestoria?.nombre ? this.propietario.gestoria.nombre : "0";
      this.selectedTipoDocumento = this.propietario.tipo_documento?.id ? this.propietario.tipo_documento.id : null;
      this.selectedValueMunicipio = this.propietario?.poblacion ? this.propietario?.poblacion : "0";

      if (this.propietario.emails.length > 0) {
        this.emails.pop()
        this.propietario.emails.forEach(email => {
          this.emails.push({ value: email })
        });
      }

      if (this.propietario.telefonos.length > 0) {
        this.telefonos.pop()
        this.propietario.telefonos.forEach(tlf => {
          this.telefonos.push({ value: tlf })
        });
      }

      if (this.propietario.iban.length > 0) {
        this.ibanList.pop()
        this.propietario.iban.forEach(iban => {
          this.ibanList.push({ value: iban })
        });
      }

      if (this.propietario?.licencias.length > 0) {
        this.licenciasAsociadas.pop();
        this.propietario.licencias.forEach(licencia => {
          delete licencia["propietario"];

          let licenciaArrayToSave: Licencia[] = [];
          let vehiculoArrayToSave: IVehiculo[] = [];

          if (licencia?.vehiculo.length > 0) {
            licencia.vehiculo.forEach(vehiculo => {
              vehiculoArrayToSave.push(new IVehiculo(vehiculo));
            });
            licencia.vehiculo = vehiculoArrayToSave;
            licenciaArrayToSave.push(new Licencia(licencia));
          }

          this.licenciasAsociadas.push({ "value": licencia.numero_licencia });
        });
      }
    }
  }

  getTipoDocumento() {
    this._servicioGeneral.getTipoDocumento().subscribe((data) => {
      if (data["message"]["status"] = "616") {
        this.tipoDocumentoList = data["TipoDocumento"];
      }
    })
  }

  setMostrarHistorico(mode: Boolean) {
    this.mostrarHistorico = mode;
    if (mode) {
      this.mostrarBotones = false;
    }
    else {
      this.mostrarBotones = true;
    }
  }

  getHistoricoPropietario() {
    this._propietarioService.getHistorico(this.data.elementUpdate?.id).subscribe((result) => {
      this.propietarioHistorico = result['historico'];
    });

    this._propietarioService.getHistoricoLicenciaByPropietario(this.data.elementUpdate?.id).subscribe((result) => {
      this.propietarioLicenciaHistorico = result;
    });
  }

  // -- Variables para pasar al componente
  addSelectedLicencia(event, i) {
    this.licenciasAsociadas[i] = { value: event.target.value };
  }

  getValue(event) {
    this.fechaAlta = event.target.value;
  }

  addLicencia() {
    this.licenciasAsociadas.push({ value: "" });
  }

  removeLicencia(index) {
    this.licenciasAsociadas.splice(index, 1);
  }

  getLicencia() {
    this._servicioLicencia.listLicencia().subscribe(
      (data) => {
        this.licencias = data.licencias;
      });
    if (this.licencias[0]?.vehiculo?.length >= 0) {
      this.licencias[0].vehiculo = null;
    }
  }

  cargaMunicipios(idProvincia) {
    this.municipiosToSelect = this.listaMunicipios.filter(option => idProvincia == option.municipioPK.provincia)
    this.municipiosToSelect = ordenarMunicipios(this.municipiosToSelect);
  }

  getGestorias() {
    this._propietarioService.getGestorias().subscribe((data) => {
      this.gestorias = data['gestorias'];
    })
  }

  getProvincias() {
    this._servicioGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia'];
    })
  }

  addEmail() {
    this.emails.push({ value: '' });
  }

  removeEmail(i) {
    this.emails.splice(i, 1);
  }

  removePhone(i) {
    this.telefonos.splice(i, 1);
  }

  addPhone() {
    this.telefonos.push({ value: '' });
  }

  addIban() {
    this.ibanList.push({ value: '' });
  }

  removeIban(i) {
    this.ibanList.splice(i, 1);
  }

  scrollTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  createPropietario() {
    this.setAndValidateFields();
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "guardar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.createPropietario();
      }
    });
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "descartar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      }
    });
  }

  openDialogConfirmarActivate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = "activar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.createPropietario();
      }
    });
  }

  uploadDocuments: Boolean = false;
  setAndValidateFields() {
    this.emailsSaved = [];
    this.telefonosSaved = [];
    this.licenciaSaved = [];
    this.ibanSaved = [];

    if (this.licenciasAsociadas != null && this.licenciasAsociadas.length > 0) {
      this.licenciasAsociadas.forEach(lic => {
        if (lic.value != 'null' && lic.value != '') {
          let licenciaToSave = new Licencia(this.licencias.find(licencia => licencia.numero_licencia == lic.value));
          let arrVehiculos: IVehiculo[] = [];
          this.licenciaSaved.push(licenciaToSave);
        }
      });

      this.propietario.licencias = this.licenciaSaved;

      if (this.licenciaSaved != null && this.licenciaSaved.length > 0) {
        this.propietario.licencias[0].vehiculo = null;
        for (const licencia of this.propietario.licencias) {
          licencia.vehiculo = null;
        }
      }
    }

    if (this.selectedValueGestoria != null && this.selectedValueGestoria != '') {
      this.propietario.gestoria = this.gestorias?.find(gestoria => gestoria.nombre == this.selectedValueGestoria);
    }

    if (this.selectedTipoDocumento != null) {
      this.propietario.tipo_documento = this.tipoDocumentoList?.find(tipoDoc => tipoDoc.id == this.selectedTipoDocumento);
    }

    if (this.selectedValueProvincia != null) {
      this.propietario.provincia = this.provincias?.find(provincia => provincia.id == this.selectedValueProvincia);
    }

    if (this.selectedValueMunicipio != null) {
      let municipio = this.municipiosToSelect?.find(municipio => municipio.nombre == this.selectedValueMunicipio);
      this.propietario.poblacion = municipio?.nombre;
    }

    if (this.propietario.codigo_postal == '') {
      this.propietario.codigo_postal = null;
    }

    this.emails.forEach(email => {
      if (email.value != '') {
        this.emailsSaved.push(email.value);
      }
    });

    this.ibanList.forEach(iban => {
      if (iban.value != '') {
        this.ibanSaved.push(iban.value);
      }
    });

    this.telefonos.forEach(telefono => {
      if (telefono.value != '') {
        this.telefonosSaved.push(telefono.value);
      }
    });

    if (this.emailsSaved.length > 0) {
      this.propietario.emails = this.emailsSaved;
    }

    this.propietario.iban = null;
    if (this.ibanSaved.length > 0) {
      this.propietario.iban = this.ibanSaved;
    }

    if (this.telefonosSaved.length > 0) {
      this.propietario.telefonos = this.telefonosSaved;
    }

    this.propietario.fechaAlta = this.fechaAlta;
    this.propietario.fechaBaja = null;

    let finalPropietario = new Propietario(this.propietario);
    let licenciasBaja = [];

    finalPropietario.licencias?.forEach(element => {
      if (element.fechaBaja != null) {
        licenciasBaja.push(element.numero_licencia);
      }
    });

    if (licenciasBaja.length != 0) {
      this._notificationService.error("ERROR", `Las siguientes licencias están dadas de baja: ${licenciasBaja.join(', ')}`, 3000);
    } else {
      this.uploadDocuments = true;

      this._propietarioService.saveUpdatePropietario(finalPropietario).subscribe((result) => {

        if (result['message'].status == 400) {
          this._notificationService.error("Error", result['message'].message, 3000);
          this.uploadDocuments = false;
        } else {
          this._notificationService.success("Hecho", result['message'], 3000);

          if (this.data.isForUpdate) {
            this.updateDocument(finalPropietario.id);
          }

          if (this.arrayDocuments.length == 0) {
            this.resetearForm();
          } else {
            this.createDocument(result['propietario'].id);
          }
        }
      });
    }
  }

  resetearForm() {
    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }

  comprobarCambioInput() {
    let cambioInput: Boolean;
    document.querySelectorAll('input').forEach((item) => {
      item.addEventListener('change', function () {
        cambioInput = true;
      });
    });
    return cambioInput;
  }







  // -- Pruebas adjuntar ficheros
  arrayDocuments = [];

  filesDropped(event: Event): void {
    event.preventDefault(); // Prevenir el comportamiento predeterminado del navegador al soltar archivos.
    const files: FileList = (event as DragEvent).dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.convertFile(files[i]).subscribe((base64) => {
        const document = { nombre: files[i].name, mime: files[i].type, base64: base64 };
        this.arrayDocuments.push(document);
      });
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf" ||
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments.push(document);
        // this.form.controls["documento"].setValue(this.arrayDocuments);
      });
    } else {
      this._notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        1500
      );
    }
  }


  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  createDocument(id) {    
    this._propietarioService.saveDocuments(id, this.arrayDocuments).subscribe({
      next: (data) => {        
        if (data["statusCode"] == '200') {
          this._notificationService.success("OK", "Documentos subidos correctamente", 3000);
          this.resetearForm();
        } else {
          this.uploadDocuments = false;
          this._notificationService.error("ERROR", data['message'], 3000);
          this.resetearForm();
        }
      }, error: (err) => {
        console.log("Error: ", err);
      }
    });
  }

  // -- Métodos para actualizar
  updateDocument(id) {
    this._propietarioService.updateDocuments(id, this.arrayDocuments).subscribe({
      next: (data) => {
        if (data["statusCode"] == '200') {
          // this._notificationService.success("OK", "Documentos subidos correctamente", 3000);
          this.resetearForm();
        } else {
          this.uploadDocuments = false;
          this._notificationService.error("ERROR", data['message'], 3000);
        }
      }
    });
  }

  getDocumentsByPropietario() {
    this._propietarioService.getDocuments(this.data.elementUpdate.id).subscribe({
      next: (data) => {
        this.arrayDocuments = data['documentos'];
      }
    })
  }

  downloadDocument(documento) {
    const base64Data = documento.base64;
    const mime = documento.mime;
    const nombre = documento.nombre;


    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mime });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = nombre; // Establece el nombre del archivo

    document.body.appendChild(a);
    a.click();

    // Limpia recursos
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  // -- Fin pruebas adjuntar ficheros

}

function ordenarMunicipios(listado) {
  return listado.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment.novotaxi";

@Injectable({
    providedIn: "root",
})

export class GastosProveedoresService {

    API_URL: string = environment.API_URL;
    private resourceUrl: string = `${environment.API_URL}/gastosProveedores`;

    constructor(
        private http: HttpClient,
    ) { }

    searchGastosProveedores(mapa) {
        return this.http
            .post(`${this.resourceUrl}/search`, mapa)
    }

    saveAllGastosProveedores(listGastos) {
        return this.http
            .post(`${this.resourceUrl}/saveAll`, listGastos, {})
    }

    update(gasto) {
        return this.http
            .post(`${this.resourceUrl}`, gasto, {})
    }

    getTotalGastosProveedores(map) {
        return this.http
            .post(`${this.resourceUrl}/getTotal`, map)
    }

    delete(id: number) {
        return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
    }

    getGastosProveedores(request?) {
        return this.http
            .post(`${this.resourceUrl}/list`, request)
    }

    handleError(error: any) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }
}

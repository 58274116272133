import { IProvincia } from './../../../model/novotaxi/propietario.model';
import { ServicioGeneral } from './../../../_services/servicio-general.service';
import { IGestoria } from '../gestoria.model';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmarUnsavedDialog, ConfirmarUpdateDialog } from 'src/app/conductores/listado-conductores/listado-conductores.component';
import { NotificationService } from 'src/app/_services/notification.service';
import { GestoriaService } from '../services/gestoria.service';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';


@Component({
  selector: 'app-listado-gestoria',
  templateUrl: './listado-gestoria.component.html',
  styleUrls: ['./listado-gestoria.component.css']
})
export class ListadoGestoriaComponent implements OnInit{

  displayedColumns: string[] = ['nombre', 'cif', 'email', 'telefono', 'domicilio', 'municipio', 'provincia.nombre', 'codigoPostal', 'fechaAlta', 'acciones'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  @Input() nombreMenu;

  listColumns;
  dataSource = new MatTableDataSource();
  listaGestoria: IGestoria[];
  listaProvincias: IProvincia[];
  listMunicipios: any[] = [];
  mostrarFormulario: Boolean = false;
  gestoria: IGestoria;
  gestoriaUpdate: IGestoria;
  editar: Boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private servicioGeneral: ServicioGeneral,
    public _notificationService: NotificationService,
    private gestoriaService: GestoriaService,
    private informe: InformePDF,

  ){}

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  ngOnInit(): void {
    window.scroll(0, 0)
    this.getMunicipios();
    this.getAllProvincias();
    this.getAllGestoria();
    this.listColumns = {
      'Nombre': true,
      'CIF': true,
      'Correo electrónico': true,
      'Teléfono': true,
      'Domicilio': true,
      'Municipio': true,
      'Provincia': true,
      'Código postal': true,
      'Fecha alta': true,
    }
  }
  generarInforme(){
    let nombre = 'Gestorias';
    var inicio= this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
    var final=inicio + this.dataSource.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData,nombre,inicio,final);
  }
  getAllProvincias(){
    this.servicioGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    )
  }

  getMunicipios() {
    this.servicioGeneral.getMunicipios().subscribe((data) => {
      this.listMunicipios = data['Municipio']
    })
  }

  setMostrarFormulario(mode: Boolean, gestoria?: IGestoria, tipo?: Boolean){
    this.mostrarFormulario = mode;
    this.gestoriaUpdate = gestoria;
    this.editar = tipo;
    if (mode){
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth"})
    }
  }

  getAllGestoria(){
    this.gestoriaService.list().subscribe((res) => {
      this.listaGestoria = res['gestorias']
      this.listaGestoria = this.listaGestoria?.filter(gestoria => gestoria.fechaBaja == null) //Mostramos el conjunto de elementos que no están borrados, es decir, que no tienen fecha de baja
      this.dataSource = new MatTableDataSource(this.listaGestoria);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    });
  }

  openDialogCrearGestoria(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '60vw';
    dialogConfig.minWidth = '60vw';
    dialogConfig.maxHeight= '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.data = this.listaProvincias;
    dialogConfig.disableClose = true;
    this.dialog.open(CrearGestoriaDialog, dialogConfig);
  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogBorrarGestoria(id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data="borrar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.delete(id);
      }
    })
  }

  openDialogMostrarGestoria(gestoria: IGestoria){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '60vw';
    dialogConfig.maxHeight= '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = [gestoria, this.listaProvincias];
    this.dialog.open(MostrarGestoriaDialog, dialogConfig);
  }

  delete(id: number): void{
    this.gestoriaService.delete(id).subscribe({
      next: (data) => {
        this._notificationService.success("Operación realizada con éxito", "Los datos han sido borrados correctamente", 3000)
        this.reloadToMenuOption();   
      }
    });
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 18 } });
  }
}



@Component({
  selector: 'app-crear-gestoria-dialog',
  templateUrl: '../crear-gestoria/crear-gestoria-dialog.html',
  styleUrls: ['../crear-gestoria/crear-gestoria-dialog.css' ]
})
export class CrearGestoriaDialog{

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private gestoriaService: GestoriaService,
    private dialog: MatDialog,
    private servicioGeneral: ServicioGeneral,
    public dialogRef: MatDialogRef<CrearGestoriaDialog>,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public listaProvincias: IProvincia[],
    private router: Router,
  ){ }

  gestoria: IGestoria;
  isSubmitted = false;
  editar = false;
  expandedPhone: number = 1;
  expandedEmail: number = 1;

  form = new FormGroup({
    nombre: new FormControl("", Validators.required),
    cif: new FormControl(null, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    email: new FormArray([new FormControl("", Validators.pattern(this.REGEX_EMAIL)),]),
    telefono: new FormArray([new FormControl("", Validators.pattern("[0-9]{9}"))]),
    domicilio: new FormControl(""),
    municipio: new FormControl(""),
    provincia: new FormControl(0),
    codigoPostal: new FormControl(null, Validators.pattern("^[0-9]{5}")),
    observaciones: new FormControl(),
  });

  // Para tener más de un teléfono o email

  addTelefono() {
    let tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl(null, Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }
  addEmail() {
    let emails = this.form.controls.email as FormArray;
    emails.push(
      new FormControl("", Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get telefonos(){
    return this.form.get("telefono") as FormArray;
  }
  get emails(){
    return this.form.get("email") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }
  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit(){
    this.gestoria = {
      id: null,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      email: this.form.value.email?.filter(tel => tel != "" && tel != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal,
      observaciones: this.form.value.observaciones,
    }

    this.gestoriaService.create(this.gestoria).subscribe({
      next: (data) => {

        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000)
          this.dialogRef.close()
          this.isSubmitted = false;
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data['message']
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);
          
        }
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result=>   
      {
        if(result.result=='Confirmar'){
          this.submit();
        }
      })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);
    
    return dialogRef.afterClosed().subscribe(result=>
      {
        if(result.result=='descartar'){
          this.dialogRef.close()
        }
    })
  }


  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 18 } });
  }
}


@Component({
  templateUrl: '../crear-gestoria/crear-gestoria-dialog.html',
  styleUrls: ['../crear-gestoria/crear-gestoria-dialog.css' ]
})

export class MostrarGestoriaDialog{

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private gestoriaService: GestoriaService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MostrarGestoriaDialog>,
    private router: Router,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],
  ){}

  gestoria: IGestoria = this.dialogData[0]
  listaProvincias: IProvincia[] = this.dialogData[1];
  gestoriaUpdate: IGestoria;
  isSubmitted: Boolean = false;
  editar: Boolean = true; //Para cambiar el título de 'nueva gestoría' a 'editar gestoría'
  expandedPhone: number = 1;
  expandedEmail: number = 1;

  form = new FormGroup({
    nombre: new FormControl(this.gestoria.nombre, Validators.required),
    cif: new FormControl(this.gestoria.cif, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    email: this.camposEmail(),
    domicilio: new FormControl(this.gestoria.domicilio),
    municipio: new FormControl(this.gestoria.municipio),
    provincia: new FormControl(this.gestoria.provincia?.id ? this.gestoria.provincia.id : 0),
    codigoPostal: new FormControl(this.gestoria.codigoPostal),
    telefono: this.camposTelefono(),
    fechaAlta: new FormControl(this.gestoria.fechaAlta),
    observaciones: new FormControl(this.gestoria.observaciones),
  });

  // Si el usuario tiene varios telefonos o emals, los muestra y permite así eliminarlos o añadir más
  camposTelefono() {
    let telefonosConductor = this.gestoria.telefono;
    
    let telefono = new FormArray([new FormControl(telefonosConductor[0], Validators.pattern("^[0-9]{9}"))])
    for (let i = 1; i < telefonosConductor.length; i++) {
      telefono.push(new FormControl(telefonosConductor[i].toString(), Validators.pattern("^[0-9]{9}")));
      this.expandedPhone++;
    }
    return telefono;
  }

  addTelefono() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("^[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  get telefonos(){
    return this.form.get("telefono") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  camposEmail() {
    let emailsGestoria = this.gestoria.email;
    let email = new FormArray([new FormControl(emailsGestoria[0], Validators.pattern(this.REGEX_EMAIL))])
    for (let i = 1; i < emailsGestoria.length; i++) {
      email.push(new FormControl(emailsGestoria[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
      this.expandedPhone++;
    }
    return email;
  }

  addEmail() {
    let emails = this.form.controls.email as FormArray;
    emails.push(
      new FormControl(null, Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get emails(){
    return this.form.get("email") as FormArray;
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit(){
    this.gestoriaUpdate = {
      id: this.gestoria.id,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif,
      email: this.form.value.email?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      observaciones: this.form.value.observaciones,
    }

    this.gestoriaService.create(this.gestoriaUpdate).subscribe({
      next: (data) => {

        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000)
          this.dialogRef.close()
          this.isSubmitted = false;
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data['message']
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);
          
        }
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);
    
    return dialogRef.afterClosed().subscribe(result=>
      {
        if(result.result=='descartar'){
          this.dialogRef.close()
        }
    })
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result=>
      {
        if(result.result=='Confirmar'){
          this.submit();
        }
      })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 18 } });
  }

}





import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TipoPersona } from '../model/tipopersona';
import { Provincia } from '../model/provincia';
import { Municipio } from '../model/municipio';
import { Documento } from '../model/documento';
import { Via } from '../model/via';
import { Pais } from '../model/pais';

import { environment } from 'src/environments/environment.novotaxi';
import { RolUsuario } from '../model/rolusuario';

import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({
  providedIn: 'root'
})


export class ServicioGeneral {

  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
    private tokenservice: TokenStorageService
  ) {}

  /*
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
*/

  getTipoPersona(): Observable<TipoPersona> {
    return this.http
      .get<TipoPersona>(`${this.API_URL}/tipopersona/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getProvincias(): Observable<Provincia> {
    return this.http
      .get<Provincia>(`${this.API_URL}/provincia/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getComunidades(): Observable<Provincia> {
    return this.http
      .get<Provincia>(`${this.API_URL}/ccaa/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMunicipios(): Observable<Municipio> {
    return this.http
      .get<Municipio>(`${this.API_URL}/municipio/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNombreMunicipio(provincia: string, municipio:string): Observable<Municipio> {
    return this.http
      .get<Municipio>(`${this.API_URL}/municipio/get/` + provincia + `/` + municipio)
      .pipe(retry(1), catchError(this.handleError));
  }

  getTipoDocumento(): Observable<Documento> {
    return this.http
      .get<Documento>(`${this.API_URL}/tipodocumento/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getTipoVias(): Observable<Via> {
    return this.http
      .get<Via>(`${this.API_URL}/tipovia/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getPais(): Observable<Pais> {
    return this.http
      .get<Pais>(`${this.API_URL}/pais/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getRolUsuario(): Observable<RolUsuario> {
    let headers = new HttpHeaders({
      Authorization: `Basic ${this.tokenservice.getUser()}`
    })

    return this.http
      .get<RolUsuario>(`${this.API_URL}/user/${this.tokenservice.getId()}/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getUserList(): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/user/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getUserId(id: number): Observable<any> {
    const url = `${this.API_URL}/user/get/${id}`;
    return this.http.get<any>(url)
      .pipe(retry(1), catchError(this.handleError));

  }

  getMenuLateralList(): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/menu/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getMenuPadreHijo( id: number): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/menu/getChildren/${id}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMenuParent(): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/menu/getParentList`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getParents(): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/menu/getParent`)
      .pipe(retry(1), catchError(this.handleError));
  }

  tieneRol(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/user/usuario/${id}/roles`)
      .pipe(retry(1), catchError(this.handleError));
  }


  exportarCSV(identificador: string, id: number): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      responseType: 'blob',
      Accept: 'application/pdf',
      observe: 'response',
    }).set('identificador', identificador)

    let API_URL = `${this.API_URL}/exportar/csv/${id}`;
    return this.http.get(API_URL, { headers: headers, responseType: 'blob' })

  }

  forgotPassword(email: string): Observable<any> {
    let API_URL = `${this.API_URL}/recovery/recover`;
    return this.http.post(`${API_URL}`, { email });
  }


  resetPassword(identificador: string, email: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.API_URL}/recovery/recovering`, { identificador, email, newPassword }, httpOptions);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

}

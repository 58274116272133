import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.novotaxi";

@Injectable({
    providedIn: "root",
})

export class ProveedoresService {

    API_URL: string = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) { }

    getAllProveedores() {
        return this.http
            .get(`${this.API_URL}/proveedor/list`);
    }

    getConceptosByProveedor(id) {
        return this.http
            .get(`${this.API_URL}/conceptosProveedores/getConceptos/${id}`);
    }

    getAllConceptosProveedores() {
        return this.http
            .get(`${this.API_URL}/conceptosProveedores/list`);
    }

    save(conceptos) {
        return this.http
            .post(`${this.API_URL}/proveedor/save`, conceptos);
    }

    delete(id) {
        return this.http
            .get(`${this.API_URL}/proveedor/delete/${id}`);
    }

    activateProveedor(id) {
        return this.http
            .get(`${this.API_URL}/proveedor/activate/${id}`);
    }
}


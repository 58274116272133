import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { Licencia, Tpv, Emisora } from 'src/app/model/Emisora';
import { IProvincia, Propietario, Gestoria } from 'src/app/model/novotaxi/propietario.model';
import { ICompaniaSeguros } from 'src/app/novotaxi/companiaSeguros/companiaSeguros.model';
import { CompaniaSegurosService } from 'src/app/novotaxi/companiaSeguros/services/companiaSeguros.service';
import { IMarca, IModelo } from 'src/app/novotaxi/marca-modelo/modelo.model';
import { MarcaService } from 'src/app/novotaxi/marca-modelo/services/marca.service';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { IVehiculo } from 'src/app/vehiculo/vehiculo.model';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { VehiculoService } from 'src/app/vehiculo/services/vehiculo.service';
import { Documento } from 'src/app/model/documento';
import { Observable, ReplaySubject } from 'rxjs';
import { PropietarioService } from 'src/app/_services/novotaxi/propietario.service';
import { TipoSueldo } from 'src/app/conductores/conductor.model';
import { ConductorSueldo } from 'src/app/model/novotaxi/conductor-sueldo.model';

@Component({
  selector: 'app-crear-licencia',
  templateUrl: './crear-licencia.component.html',
  styleUrls: ['../listar-licencia.component.css']
})

export class CrearLicenciaComponent {

  @Output() mostrarFormulario = new EventEmitter<Boolean>();

  constructor(
    private servicioLicencia: LicenciaServiece,
    private obtenerProvinciaService: ServicioGeneral,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    protected conductorService: ConductorService,
    public servicioGeneral: ServicioGeneral,
    private marcaService: MarcaService,
    private companiaSegurosService: CompaniaSegurosService,
    protected vehiculoService: VehiculoService,
    private _propietarioService: PropietarioService,
  ) { }

  public telefonoCiaSeguros: string[] = null;
  public isCollapsed = false;
  isEmisora = false;
  tipo_emisora = null;
  clave_emisora = null;
  numero_serie = null;
  numero_comercio = null;
  fecha_alta = null;
  isTPV = false;
  numero_licencia = null;
  listaEmisora = null;
  provinciasToSelect: IProvincia[] = [];
  gestoriaToSelect = null;
  message = "";
  uploadSucesfully = false;
  uploadFail = true;
  emails = [{ value: '' }];
  telefonos = [{ value: '' }];
  ibanList = [{ value: '' }];
  ibanArray: string[] = [];
  telefonosArray = [];
  addTelefonoValue;
  municipiosToSelect;
  mostrarConductor: boolean = false;
  // variables para capturar el objeto entero .
  selectedValueProvincia: any = 0;
  selectedValueGestoria = 0;
  selectedValueMunicipio: any = 0;
  selectedTipoDocumento: number = null;

  //crear conductor parte ts ------------------------>
  expandedPhone: number = 1;
  expandedIban: number = 1;
  expandedEmail: number = 1;
  isSubmitted: Boolean = false;
  TProvincias: any = [];
  TMunicipios: any = [];
  tipoSueldoList: TipoSueldo[] = [];

  fechaAlta: Date;
  tipoVehiculo: any;

  // var formulario conductor
  form = new FormGroup({
    fechaVencimientoBTP: new FormControl(),
    tipo_documento: new FormControl(0, Validators.required),
    dni: new FormControl("", [Validators.pattern("^[0-9]{8}[A-Z]$")]),
    nombre: new FormControl(),
    apellidos: new FormControl(),
    domicilio: new FormControl(),
    provincia: new FormControl(0),
    poblacion: new FormControl(),
    codigoPostal: new FormControl(null, [Validators.pattern("[0-9]{5}")]),
    mail: new FormArray([new FormControl("")]),
    telefono: new FormArray([new FormControl("", Validators.pattern("[0-9]{9}")),]),
    cuentaBancaria: new FormArray([new FormControl("", [Validators.minLength(5), Validators.maxLength(34)])]),
    retencion: new FormControl(),
    valoracion: new FormControl(),
    puntuacion: new FormControl(),
    lopd: new FormControl(),
    riesgosLaborales: new FormControl(),
    observaciones: new FormControl(),
    // porcentaje: new FormControl(),
    // salario_fijo: new FormControl(),
    // nomina: new FormControl(),
    // tipoSueldo: new FormControl(0)
    sueldos: new FormGroup({
      id: new FormControl({ value: "", disabled: false }),
      vehiculo: new FormControl({ value: null, disabled: false }),
      licencia: new FormControl({ value: null, disabled: false }),
      tipoSueldo: new FormControl({ value: 0, disabled: false }, Validators.required),
      cantidad: new FormControl({ value: null, disabled: false }, Validators.required),
      porcentajeCondicional: new FormControl({ value: "", disabled: false }),
    })
  }, { updateOn: 'change' });
  //var formulario vehículo
  formVehiculo = new FormGroup({
    datos: new FormGroup({
      licencia: new FormControl(),
      tipo: new FormControl(),
      matricula: new FormControl(null, Validators.required),
      marca: new FormControl(0, [Validators.required, Validators.pattern("^[1-9]*$")]),
      modelo: new FormControl(0, [Validators.required, Validators.pattern("^[1-9]*$")]),
    }),
    seguro: new FormGroup({
      ciaSeguros: new FormControl(0),
      tlfContacto: new FormControl(this.telefonoCiaSeguros),
      mutuaPadronal: new FormControl(""),
      fechaVencimiento: new FormControl(),
    }),
    taximetro: new FormGroup({
      casaTaximetro: new FormControl(""),
      claseTaximetro: new FormControl(""),
      ultimaTarifaAplicada: new FormControl(),
      fechaUltimoCambioDeTarifa: new FormControl(),
    }),
    mantenimiento: new FormGroup({
      kmEntrada: new FormControl(""),
      fechaUltimaDistribucion: new FormControl(),
      fechaProximaDistribucion: new FormControl(),
      kmUltimoCambioDeAceite: new FormControl(""),
      kmProximoCambioDeAceite: new FormControl(""),
      tipoAceite: new FormControl(),
      hasFiltroAire: new FormControl(),
      hasFiltroCombustible: new FormControl(),
      hasFiltroHabitaculo: new FormControl(),
    }),
    revision: new FormGroup({
      ultimaRevision: new FormControl(),
      vencimientoTaximetro: new FormControl(),
      vencimientoITV: new FormControl(),
      descripcionVehiculoITV: new FormControl(""),
    })
  })

  public conductor: any;
  public listaMarcas: IMarca[] = [];
  public listaModeloMarca: IModelo[] = [];
  public listaModelos: IModelo[] = []
  public listaCiaSeguros: ICompaniaSeguros[] = [];
  public licencia: Licencia = {};
  public provincia: IProvincia[] = [];
  public tpv: Tpv = {};
  public emisora: Emisora = { id: 0 };
  public propietario: Propietario = {};
  public gestoria: Gestoria = {};
  tipoDocumentoList: Documento[] = [];

  ngOnInit(): void {
    this.selectedValueGestoria = 0;
    this.getTipoDocumento();
    this.getTipoVehiculo();
    this.listEmisora();
    this.listProvincias();
    this.listGestoria();
    this.getAllModelo();
    this.getAllMarca();
    this.listAllCiaSeguros();
    this.listMunicipios();
    this.getTipoSueldo();
  }

  getTipoSueldo() {
    this.conductorService.getTiposSueldo().subscribe({
      next: (data) => {
        if (data["status"] = "616") {
          this.tipoSueldoList = data["tiposSueldos"];
        }
      }
    })
  }

  getTipoDocumento() {
    this.servicioGeneral.getTipoDocumento().subscribe((data) => {
      if (data["message"]["status"] = "616") {
        this.tipoDocumentoList = data["TipoDocumento"];
      }
    })
  }

  listAllMarca() {
    this.marcaService.query().subscribe((res) => {
      this.listaMarcas = res.body;
    });
  }

  listAllCiaSeguros() {
    this.companiaSegurosService.query().subscribe(
      (res) => {

        this.listaCiaSeguros = res['CompaniasSeguros'];
      }
    )

  }
  getTipoVehiculo() {
    this.vehiculoService.findTipoVehiculo().subscribe((result) => {
      this.tipoVehiculo = result;
    })
  }
  listAllModeloMarca(event: Event) {
    let idMarca: number = +(event.target as HTMLInputElement).value - 1;
    if (idMarca == -1) {
      this.formVehiculo.controls.datos.controls.modelo.setValue(0); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
      this.listaModeloMarca = []
    }
    else {
      //this.listaModeloMarca = this.listaMarcas[idMarca].modelos;
    }
  }

  listEmisora() {
    this.servicioLicencia.listEmisora().subscribe(
      (data) => {
        this.listaEmisora = data.emisoras;

      });
  }

  listaMunicipios;
  listMunicipios() {
    this.servicioLicencia.listMunicipio().subscribe(
      (data) => {
        this.listaMunicipios = data['Municipio']
      });
  }
  getValue(event) {
    this.fechaAlta = event.target.value
  }
  cargaMunicipios(idProvincia) {
    this.municipiosToSelect = this.listaMunicipios.filter(option => idProvincia == option.municipioPK.provincia)
    this.municipiosToSelect = ordenarMunicipios(this.municipiosToSelect);
  }

  listGestoria() {
    this.servicioLicencia.listGestoria().subscribe(
      (data) => {
        this.gestoriaToSelect = data['gestorias'];

      });
  }

  listProvincias() {
    this.obtenerProvinciaService.getProvincias().subscribe(
      (data) => {
        this.provinciasToSelect = data['Provincia'];
      });
  }

  getTelefonoCiaSeguros(event: Event) {
    let idCiaSeguros: number = +(event.target as HTMLInputElement).value - 1;
    if (idCiaSeguros == -1) {
      this.formVehiculo.controls.seguro.controls.tlfContacto.setValue(null); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      if (this.listaCiaSeguros != null) {
        this.telefonoCiaSeguros = this.listaCiaSeguros[idCiaSeguros ?? 0]?.telefono;
        this.formVehiculo.controls.seguro.controls.tlfContacto.setValue(this.telefonoCiaSeguros);
      }
    }
  }

  controlJsonAEnviar() {

    this.submitVehiculo();

    if (this.vehiculo.matricula == "") {
      this.licencia.vehiculo = null
    }

    if (this.vehiculo.matricula != "") {
      this.licencia.vehiculo = [];
      this.licencia.vehiculo[0] = this.vehiculo
    }

    if (this.tpv.numero_serie != null) {
      this.licencia.tpv = this.tpv;
    }

    if (this.emisora.id != null && this.emisora.id != 0) {
      this.licencia.emisora = this.emisora;
    } else {
      this.licencia.emisora = null;
    }

    if (this.selectedValueProvincia != null && this.selectedValueProvincia != '') {
      this.propietario.provincia = this.provinciasToSelect.find(provincia => provincia.id == this.selectedValueProvincia);
    }

    if (this.selectedValueMunicipio != null && this.selectedValueMunicipio != '') {
      this.propietario.poblacion = this.selectedValueMunicipio
    }

    if (this.selectedValueGestoria != null && this.selectedValueGestoria != 0) {
      this.propietario.gestoria = this.gestoriaToSelect.find(gestoria => gestoria.id == this.selectedValueGestoria);
    }

    if (this.propietario.numero_documento != undefined) {
      this.licencia.propietario = this.propietario
    }
    if (this.fechaAlta) {
      this.propietario.fechaAlta = this.fechaAlta;
    }

    // if (this.conductor.dni != "") {
    //   this.licencia.conductores = [];
    //   // this.conductor.licencia = this.licencia;
    //   this.licencia.conductores[0] = this.conductor

    // }
    this.crearLicencia(this.licencia);
  }

  mostrarFormConductor() {

    if (this.formVehiculo.value.datos.matricula != null && this.formVehiculo.value.datos.matricula != '') {
      this.mostrarConductor = true;
    } else {
      this.mostrarConductor = false;
    }

  }
  addTelefono() {
    this.telefonos.push({ value: "" });
  }

  addEmailValue = null;
  emailArray = [];
  addEmail() {
    this.emails.push({ value: "" });
  }

  removeEmails(i) {
    this.emails.splice(i, 1);
  }

  removeTelefono(i) {
    this.telefonos.splice(i, 1);
  }

  addIban() {
    this.ibanList.push({ value: '' });
  }

  removeIban(i) {
    this.ibanList.splice(i, 1);
  }

  crearLicencia(licencia) {
    this.emailArray = this.emails.map(el => el.value)
    this.telefonosArray = this.telefonos.map(el => el.value)
    this.ibanArray = this.ibanList.map(el => el.value)

    if (licencia.propietario != undefined) {
      licencia.propietario.fechaAlta = this.convertDate(licencia?.propietario?.fechaAlta, true)

      if (this.telefonosArray[0] != '') {
        licencia.propietario.telefonos = this.telefonosArray;
      }

      if (this.emailArray[0] != '') {
        licencia.propietario.emails = this.emailArray;
      }

      licencia.propietario.iban = null;

      if (this.ibanArray[0] != '') {
        licencia.propietario.iban = this.ibanArray;
      }

      if (licencia.propietario.codigo_postal == '') {
        licencia.propietario.codigo_postal = null;
      }

      licencia.propietario = new Propietario(licencia.propietario);
    }
    //si no lleva matricula el vehiculo va a ir a null , campo obligatorio.
    //revisar una mejor condicion

    if (licencia.vehiculo == null || licencia.vehiculo[0].matricula == null) {
      licencia.vehiculo = null
    }

    if (licencia?.propietario?.numero_documento == '') {
      licencia.propietario = null
    }

    if (this.vehiculo?.tipo?.id == null) {
      if (licencia.vehiculo) {
        licencia.vehiculo[0].tipo = null;
      }
    }

    if (this.selectedTipoDocumento != null) {
      this.licencia.propietario.tipo_documento = this.tipoDocumentoList?.find(tipoDoc => tipoDoc.id == this.selectedTipoDocumento);
    }

    this.servicioLicencia.saveLicencia(licencia).subscribe(
      (data) => {
        if (data['status'] == "608") {
          this.message = data['message'];

          if (this.arrayDocumentsPropietario.length > 0) {
            this.createDocumentsPropietario(data['licencia']['propietario']['id']);
          }

          if (data['vehiculo']) {
            if (data['vehiculo']['body']['vehiculo']) {
              this.crearConductor(data['licencia']);
            } else {
              this.notificationService.success("Hecho", this.message, 3000)
              this.reloadLicencia();
              //this.onNoClick();
            }
          } else {
            this.reloadLicencia();
          }
        } else {
          this.message = data['message'].message
          this.notificationService.error("Error", this.message, 3000)
        }
      });
  }

  elegir() {
    let value = Number((<HTMLSelectElement>document.getElementById('elegirS')).value);
    let Csalario_fijo = document.getElementById("Csalario_fijo");
    let Cporcentaje = document.getElementById("Cporcentaje");

    // -- Mirar
    // this.form.get('porcentaje').setValue(null);
    // this.form.get('salario_fijo').setValue(null);

    if (value == 1) {
      Csalario_fijo.classList.remove("d-none")
      Cporcentaje.classList.add("d-none")
    } else if (value == 2) {

      Csalario_fijo.classList.add("d-none")
      Cporcentaje.classList.remove("d-none")
    } else {
      Csalario_fijo.classList.add("d-none")
      Cporcentaje.classList.add("d-none")
    }
  }

  checkporcentaje() {    
    if (this.form.get("sueldos").value.tipoSueldo == 1) {
      if (this.form.get("sueldos").value.cantidad > 100) {
        this.form.get("sueldos").value.cantidad = 100;
      }
    }
  }

  setSueldo(licencia): ConductorSueldo {
    var sueldo: ConductorSueldo = new ConductorSueldo;

    if (this.form.value.sueldos.cantidad && this.form.value.sueldos.tipoSueldo != 0) {
      sueldo.cantidad = this.form.value.sueldos.cantidad;
      sueldo.tipoSueldo = this.tipoSueldoList.find(tipo => tipo.id == this.form.value.sueldos.tipoSueldo);
      sueldo.licencia = licencia;
      sueldo.vehiculo = licencia.vehiculo[0];
      return new ConductorSueldo(sueldo);
    }

    return null;
  }

  crearConductor(licencia) {
    this.uploadDocuments = true;

    if (this.setSueldo(licencia) != null) {
      this.conductor.sueldos = [this.setSueldo(licencia)];
    } else {
      this.conductor.sueldos = null;
    }

    if (this.conductor.dni) {
      if (this.conductor.mail[0] == null) {
        this.conductor.mail = null;
      }
      
      this.conductorService.create(this.conductor).subscribe({
        next: (data) => {
          if (data['message'].status == "608") {
            this.notificationService.success("Operación realizada con éxito", "Los datos han sido creados correctamente", 3000);

            if (this.arrayDocumentsConductor.length != 0) {
              this.conductorService.saveDocuments(data['Conductor'].id, this.arrayDocumentsConductor).subscribe({
                next: (data) => {

                  if (data["statusCode"] == '200') {
                    this.notificationService.success("OK", "Documentos del conductor subidos correctamente", 3000);
                    this.resetearForm();
                  } else {
                    this.uploadDocuments = false;
                    this.notificationService.error("ERROR", data['message'], 3000);
                    this.reloadLicencia();
                  }

                  // if (data["statusCode"] == '200') {
                  //   this.notificationService.success("OK", "Documentos del conductor subidos correctamente", 3000);
                  //   this.reloadLicencia();
                  // } else {
                  //   this.uploadDocuments = false;
                  //   this.notificationService.error("ERROR", "Se ha producido un error a lsubir los documentos", 3000);
                  // }
                }
              });
            } else {
              this.reloadLicencia();
            }

          } else {
            this.uploadDocuments = false;
            this.conductorService.rollback(licencia).subscribe((data) => {
            });
            let message = data['message'];
            this.notificationService.error("La operación no se ha podido realizar", message, 3000);
          }
        }
      });
    } else {
      this.notificationService.success("Hecho", "Cambios guardados correctamente", 3000)
      this.reloadLicencia();
      //this.onNoClick();
    }
  }

  relacionConductorVehiculo(map) {
    this.conductorService.saveCondcutorVehiculo(map).subscribe({
      next: (data) => {
        //    if (data.body['message'].status == "608") {
        //      this.notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente");
        //      this.dialogRef.close() 
        this.reloadLicencia();
        //this.onNoClick();
        //    } else {
        //      let message = data.body['message'];
        //      this.notificationService.error("La operación no se ha podido realizar", message);

        //    }
      }
    });
  }

  reloadLicencia() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.onSameUrlNavigation = 'reload';
    this.scrollTop();
    this.router.navigate([this.router.url]);
  }

  scrollTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  /*onNoClick(): void {
    this.dialogRef.close();
  }*/

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento


  // Para que los inputs de los teléfonos agregados sean independientes y no compartan los datos introducidos
  trackByFn(item: any) {
    return item.trackingId;
  }

  submitConductor() {

    this.conductor = {
      id: null,
      dni: this.form.value.dni,
      tipo_documento: this.form.value.tipo_documento == 0 ? null : this.tipoDocumentoList?.find(tipoDoc => tipoDoc.id == this.form.value.tipo_documento),
      nombre: this.form.value.nombre,
      apellidos: this.form.value.apellidos,
      fechaVencimientoBTP: this.form.value.fechaVencimientoBTP != null ? this.convertDate(this.form.value.fechaVencimientoBTP, true) : null,
      domicilio: this.form.value.domicilio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      poblacion: this.form.value.poblacion,
      codigoPostal: this.form.value.codigoPostal != "" ? this.form.value.codigoPostal : null,
      mail: this.form.value.mail,
      telefono: this.form.value.telefono,
      cuentaBancaria: this.form.value.cuentaBancaria,
      retencion: this.form.value.retencion,
      valoracion: this.form.value.valoracion,
      puntuacion: this.form.value.puntuacion,
      lopd: this.form.value.lopd,
      riesgosLaborales: this.form.value.riesgosLaborales,
      observaciones: this.form.value.observaciones,

      // -- Mirar
      // porcentaje: this.form.value.porcentaje,
      // salario_fijo: this.form.value.salario_fijo,
      // nomina: this.form.value.nomina,
      // tipoSueldo: this.form.value.tipoSueldo == 0 ? null : this.tipoSueldoList?.find(tipo => tipo.id == this.form.value.tipoSueldo),
      sueldos: this.form.value.sueldos
    }
    this.conductor.telefono = this.conductor.telefono?.filter(tel => tel != "");
    this.conductor.mail = this.conductor.mail.filter(mail => mail != "");
    this.conductor.cuentaBancaria = this.conductor.cuentaBancaria.filter(cuentaBancaria => cuentaBancaria != "");
    this.conductor.vehiculos = [this.vehiculo];
  }

  get telefonosConductor() {
    return this.form.get("telefono") as FormArray;
  }
  get emailsCondcutor() {
    return this.form.get("mail") as FormArray;
  }
  get cuentasBancarias() {
    return this.form.get("cuentaBancaria") as FormArray;
  }
  // Añadir las cuentas bancarias 

  addTelefonoConductor() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  addEmailConductor() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl(""),
    )
    this.expandedEmail++;
  }

  addCuentaBancariaCondcutor() {
    let cuentas = this.form.controls.cuentaBancaria as FormArray;
    cuentas.push(
      new FormControl("", [Validators.minLength(5), Validators.maxLength(34)]),
    )
    this.expandedIban++;
  }

  removeEmailConductor(i) {
    this.emailsCondcutor.removeAt(i);
    this.expandedEmail--;
  }

  removeTelefonoConductor(i: number) {
    const tels = this.form.controls.telefono;
    tels.removeAt(i);
    this.expandedPhone--;
  }

  removeCuentaBancariaConductor(i: number) {
    this.cuentasBancarias.removeAt(i);
    this.expandedIban--;
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 8 } });
  }

  convertDate(date: string, modo: Boolean): string {

    if (date != null && date != '') {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];

        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }

  //Los modelos pertenecientes a la marca que se selecciona en el select
  getAllMarca() {
    this.marcaService.queryMarcas().subscribe((res) => {
      this.listaMarcas = res['marcas'];
    });
  }

  getAllModelo() {
    this.marcaService.query().subscribe({
      next: (res) => {
        this.listaModelos = res['modelos'];
        this.listaModelos = this.listaModelos?.filter(modelo => modelo.fechaBaja == null);
      }
    })
  }

  getAllModeloMarca(event: Event) {
    let idMarca: number = +(event.target as HTMLInputElement).value - 1;
    if (idMarca == -1) {
      this.formVehiculo.controls.datos.controls.modelo.setValue(0); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      this.listaModeloMarca = this.listaModelos.filter(modelo => modelo.marca.id == idMarca + 1);
    }
  }

  vehiculo: IVehiculo;
  isSubmittedVehiculo: Boolean = false;
  editar: Boolean = false;

  submitVehiculo() {

    let marca: IMarca;
    let modelo: IModelo;


    this.vehiculo = {
      id: null,
      tipo: {
        id: this.formVehiculo.value.datos.tipo,
      },
      modelo: {
        id: this.formVehiculo.value.datos.modelo
      },
      matricula: this.formVehiculo.value.datos.matricula,
      seguro: {
        ciaSeguros: this.formVehiculo.value.seguro.ciaSeguros == 0 ? null : ( //Para permitir que no se introduzca una companía de seguros
          {
            id: this.formVehiculo.value.seguro.ciaSeguros,
            telefono: this.formVehiculo.value.seguro.tlfContacto
          }
        ),
        mutuaPadronal: this.formVehiculo.value.seguro.mutuaPadronal,
        fechaVencimiento: this.convertDate(this.formVehiculo.value.seguro.fechaVencimiento, true),
      },
      taximetro: {
        casaTaximetro: this.formVehiculo.value.taximetro.casaTaximetro,
        claseTaximetro: this.formVehiculo.value.taximetro.claseTaximetro,
        ultimaTarifaAplicada: this.formVehiculo.value.taximetro.ultimaTarifaAplicada,
        fechaUltimoCambioDeTarifa: this.convertDate(this.formVehiculo.value.taximetro.fechaUltimoCambioDeTarifa, true),
      },
      mantenimiento: {
        kmEntrada: this.formVehiculo.value.mantenimiento.kmEntrada,
        fechaUltimaDistribucion: this.convertDate(this.formVehiculo.value.mantenimiento.fechaUltimaDistribucion, true),
        fechaProximaDistribucion: this.convertDate(this.formVehiculo.value.mantenimiento.fechaProximaDistribucion, true),
        kmUltimoCambioDeAceite: this.formVehiculo.value.mantenimiento.kmUltimoCambioDeAceite,
        kmProximoCambioDeAceite: this.formVehiculo.value.mantenimiento.kmProximoCambioDeAceite,
        tipoAceite: this.formVehiculo.value.mantenimiento.tipoAceite,
        hasFiltroAire: this.formVehiculo.value.mantenimiento.hasFiltroAire,
        hasFiltroCombustible: this.formVehiculo.value.mantenimiento.hasFiltroCombustible,
        hasFiltroHabitaculo: this.formVehiculo.value.mantenimiento.hasFiltroHabitaculo,
      },
      documentacion: {
        revision: {
          ultimaRevision: this.convertDate(this.formVehiculo.value.revision.ultimaRevision, true),
          vencimientoTaximetro: this.convertDate(this.formVehiculo.value.revision.vencimientoTaximetro, true),
          vencimientoITV: this.convertDate(this.formVehiculo.value.revision.vencimientoITV, true),
          descripcionVehiculoITV: this.formVehiculo.value.revision.descripcionVehiculoITV,
        }
      }
    }
    this.submitConductor();

  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = 'guardar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.controlJsonAEnviar();
      } else {
        null
      }
    })
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      } else {
        null
      }
    })
  }

  resetearForm() {
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  // -- Pruebas documentos
  arrayDocumentsConductor = [];
  arrayDocumentsPropietario = [];
  uploadDocuments: Boolean = false;

  filesDropped(event: Event, arrayDocuments): void {
    event.preventDefault(); // Prevenir el comportamiento predeterminado del navegador al soltar archivos.
    const files: FileList = (event as DragEvent).dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.convertFile(files[i]).subscribe((base64) => {
        const document = { nombre: files[i].name, mime: files[i].type, base64: base64 };
        arrayDocuments.push(document);
        // this.form.controls['documento'].setValue(this.arrayDocuments);
      });
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event, arrayDocuments) {
    if (
      event.target.files[0].type == "application/pdf" ||
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        arrayDocuments.push(document);
      });
    } else {
      this.notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        1500
      );
    }
  }


  removeFile(index: number, arrayDocuments): void {
    arrayDocuments.splice(index, 1);
  }

  createDocumentsPropietario(id) {
    this._propietarioService.saveDocuments(id, this.arrayDocumentsPropietario).subscribe({
      next: (data) => {

        if (data["statusCode"] == '200') {
          this.notificationService.success("OK", "Documentos del propietario subidos correctamente", 3000);
          this.resetearForm();
        } else {
          this.uploadDocuments = false;
          this.notificationService.error("ERROR", data['message'], 3000);
          // this.resetearForm();
        }

        // if (data["statusCode"] == '200') {
        //   this.notificationService.success("OK", "Documentos subidos correctamente", 3000);
        //   this.resetearForm();
        // } else {
        //   this.uploadDocuments = false;
        //   this.notificationService.error("ERROR", "Se ha producido un error a lsubir los documentos", 3000);
        // }
      }
    });
  }

  // -- Fin pruebas documentos

}

function ordenarMunicipios(listado) {
  return listado.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
}
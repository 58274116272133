<form>
  <!-- <form class="crear-editar-form mt-5" (ngSubmit)="controlJsonAEnviar()">
    <h1 class="titulo-formulario">Crea una nueva licencia</h1>

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="row mt-2 mb-4">
      <div class="form-group col-sm-4">
        <label>Licencia</label>
        <input type="number" [(ngModel)]='licencia.numero_licencia' class="form-control">
      </div>
      <div class="form-group col-sm-4">
        <label for="cif">Fecha alta</label>
        <input type="date" [(ngModel)]='licencia.fechaAlta' class="form-control">
      </div>
    </div> 

    <h2 class="divisiones-formularios">TPV</h2>
    <hr>

    <div class="row mt-2 mb-4">
      <div class="form-group col-sm-4">
        <label>Nº comercio</label>
        <input type="number" [(ngModel)]='tpv.numero_comercio' class="form-control">
      </div>
      <div class="form-group col-sm-4">
        <label for="cif">Nº serie</label>
        <input type="text" [(ngModel)]='tpv.numero_serie' class="form-control">
      </div>
    </div> 

    <h2 class="divisiones-formularios">Emisora</h2>
    <hr>

    <div class="row mt-2 mb-4">
      <div class="form-group col-sm-4">
        <label>Emisora</label>
        <select [(ngModel)]="emisora.id" class="form-control form-select">
          <option value="0" selected>Selecciona una opción</option>
          <option *ngFor="let tipodocumento of listaEmisora" [value]="tipodocumento.id">
            {{ tipodocumento.nombre }}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4">
        <label>Clave emisora</label>
        <input type="text" [(ngModel)]='licencia.clave_emisora' class="form-control">
      </div>
    </div>
  </form> -->
  <mat-tab-group animationDuration="0ms" [selectedIndex]="0" class="tabForms">

    <mat-tab label="Licencia">
      <form class="crear-editar-form mt-5" (ngSubmit)="controlJsonAEnviar()">
        <h1 class="titulo-formulario">Crea una nueva licencia</h1>

        <h2 class="divisiones-formularios">Datos</h2>
        <hr>

        <div class="row mt-2 mb-4">
          <div class="form-group col-sm-4">
            <label>Licencia <span style="color: red;">*</span></label>
            <input type="number" [(ngModel)]='licencia.numero_licencia' class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <label for="cif">Fecha alta</label>
            <input type="date" [(ngModel)]='licencia.fechaAlta' class="form-control">
          </div>
        </div>

        <h2 class="divisiones-formularios">TPV</h2>
        <hr>

        <div class="row mt-2 mb-4">
          <div class="form-group col-sm-4">
            <label for="cif">Nº serie</label>
            <input type="text" [(ngModel)]='tpv.numero_serie' class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <label>Nº comercio</label>
            <input type="number" [(ngModel)]='tpv.numero_comercio' class="form-control">
          </div>
        </div>

        <h2 class="divisiones-formularios">Emisora</h2>
        <hr>

        <div class="row mt-2 mb-4">
          <div class="form-group col-sm-4">
            <label>Emisora</label>
            <select [(ngModel)]="emisora.id" class="form-control form-select" name="emisora">
              <option value=0>Selecciona una emisora</option>
              <option *ngFor="let tipodocumento of listaEmisora" [value]="tipodocumento.id">
                {{ tipodocumento.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-4">
            <label>Clave emisora</label>
            <input type="text" [(ngModel)]='licencia.clave_emisora' class="form-control">
          </div>
        </div>
      </form>
    </mat-tab>

    <mat-tab label="Propietario">
      <form class="crear-editar-form mt-5">

        <h1 class="titulo-formulario">Crea un nuevo propietario</h1>

        <h2 class="divisiones-formularios">Datos</h2>
        <hr>

        <div class="row">
          <div class="form-group col-sm-4">
            <label>Fecha alta</label>
            <input type="date" class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'"
              (change)="getValue($event)">
            <!-- <input type="date" class="form-control" [value]="fecha_alta" [(ngModel)]="propietario.fechaAlta"> -->
          </div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="form-group col-sm-4">
            <label>Nombre</label>
            <input type="text" class="form-control" [(ngModel)]="propietario.nombre">

          </div>
          <div class="form-group col-sm-4">
            <label>Apellidos</label>
            <input type="text" class="form-control" [(ngModel)]="propietario.apellidos">
          </div>
          <div class="form-group col-sm-1">
            <label>Tipo<span style="color: red;">*</span></label>
            <select class="form-control form-select" [(ngModel)]="selectedTipoDocumento" name="tipoDocumento">
              <option value=null>Selecciona un tipo de documento</option>
              <option *ngFor="let tipoDocumento of tipoDocumentoList" [value]="tipoDocumento.id">
                {{tipoDocumento.nombre}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-3">
            <label>Número de documento<span style="color: red;">*</span></label>
            <input type="text" maxlength="9" minlength="9" class="form-control" maxlength="9"
              [(ngModel)]="propietario.numero_documento">
          </div>
        </div>

        <h2 class="divisiones-formularios">Contacto</h2>
        <hr>

        <div class="row mb-2">
          <div class="col-sm-4">
            <div *ngFor="let email of emails; let i = index">
              <label>Email{{ emails.length > 1 ? i+1 : ''}}</label>
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="email.value" #name="ngModel" name="value{{i}}">
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                    (click)="removeEmails(i)"><mat-icon>close</mat-icon></button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
              (click)="addEmail()"><mat-icon>add</mat-icon>Añadir email</button>
          </div>

          <div class="col-sm-4">
            <div *ngFor="let telefono of telefonos; let i = index">
              <label>Teléfono{{ telefonos.length > 1 ? i+1 : ''}}</label>
              <div class="input-group">
                <input minlength="9" maxlength="9" type="text" [(ngModel)]="telefono.value" #name="ngModel"
                  name="value{{i}}" class="form-control">
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                    (click)="removeTelefono(i)"><mat-icon>close</mat-icon></button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
              (click)="addTelefono()"><mat-icon>add</mat-icon>Añadir teléfono</button>
          </div>

          <div class="col-sm-4">
            <div *ngFor="let iban of ibanList; let i = index">
              <label>IBAN{{ ibanList.length > 1 ? i+1 : ''}}</label>
              <div class="input-group">
                <input type="text" [(ngModel)]="iban.value" #name="ngModel" name="value{{i}}" class="form-control">
                <div class="input-group-append">
                  <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                    (click)="removeIban(i)"><mat-icon>close</mat-icon></button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3"
              (click)="addIban()"><mat-icon>add</mat-icon>Añadir cuenta</button>
          </div>
        </div>

        <div class="row mb-4 mt-4">
          <div class="form-group col-sm-4">
            <label>Gestoría</label>
            <select class="form-control form-select" [(ngModel)]="selectedValueGestoria" name="gestoria">
              <option value=0>Selecciona una gestoría</option>
              <option *ngFor="let gestoria of gestoriaToSelect" [value]="gestoria.id">
                {{gestoria.nombre}}
              </option>
            </select>

          </div>
        </div>

        <h2 class="divisiones-formularios">Dirección</h2>
        <hr>

        <div class="row">
          <div class="form-group col-sm-4">
            <label>Domicilio</label>
            <input type="text" class="form-control" [(ngModel)]="propietario.domicilio">
          </div>
        </div>
        <div class="row mb-4">
          <div class="form-group col-sm">
            <label>Provincia</label>
            <select [(ngModel)]='selectedValueProvincia' class="form-control form-select"
              (change)="cargaMunicipios(selectedValueProvincia)" name="provincia">
              <option value=0>Selecciona una provincia</option>
              <option *ngFor="let provincia of provinciasToSelect" [value]="provincia.id">
                {{ provincia.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm">
            <label for="provincia">Municipio</label>
            <select [(ngModel)]='selectedValueMunicipio' class="form-control form-select" name="municipio">
              <option value=0>Selecciona un municipio</option>
              <option [value]="municipio.nombre" *ngFor="let municipio of municipiosToSelect">{{municipio.nombre}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm">
            <label>Código postal</label>
            <input pattern="\d*" maxlength="5" type="text" class="form-control" [(ngModel)]="propietario.codigo_postal">
          </div>
        </div>


        <h2 class="divisiones-formularios">Documentos</h2>
        <hr>

        <br>
        <h6 class="col-sm-12 tituloAdjuntar">Adjuntar Ficheros</h6>

        <div class="col-sm-12 d-flex" style="padding: 0;">
          <!-- Parte adjuntar -->
          <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-3 p-3" appDrag
            (files)="filesDropped($event, arrayDocumentsPropietario)">

            <div class="text-center ">
              <h6 class="text_bold mt-1">Arrastre y suelte aqui</h6>
              <h6 class="text">o</h6>
              <button class="btn btn-light btn_file">Seleccionar Archivos</button>
              <input id="file" value="file" name="file" class="input_file"
                (change)="onFileChange($event, arrayDocumentsPropietario)" onclick="event.target.value=null"
                type="file" />
              <h6 class="indicaciones mt-1">PDF, JPEG o PNG de 20MB máximo. </h6>
            </div>
          </div>

          <!-- Parte documentos -->
          <div class="col-8 ordenar-archivos">
            <div *ngFor="let documento of this.arrayDocumentsPropietario"
              class="card card-generic archivos-seleccionados">
              <a class="cortar-texto">{{documento['nombre']}}</a>
              <div class="card border-0 card-width-actions">
                <div>
                  <a (click)="removeFile(arrayDocumentsPropietario.indexOf(documento), arrayDocumentsPropietario)"
                    class="delete-button activated boton-eliminar"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </mat-tab>

    <mat-tab label="Vehículo" class="overflow-hidden">
      <!--<form [formGroup]="formVehiculo" (ngSubmit)="controlJsonAEnviar()">-->
      <form [formGroup]="formVehiculo" (ngSubmit)="controlJsonAEnviar()" class="crear-editar-form mt-5">

        <h1 class="titulo-formulario">Crea un nuevo vehículo</h1>

        <h2 class="divisiones-formularios">Datos</h2>
        <hr>
        <!--Datos vehículo-->
        <form formGroupName="datos" class="mb-4">
          <div class="row">
            <div class="form-group col">
              <label for="tipo">Tipo de vehículo</label>
              <select id="tipo" class="form-control form-select" formControlName="tipo">
                <option [value]="0">Seleccione tipo de vehículo</option>
                <option [value]="tipo.id" *ngFor="let tipo of tipoVehiculo">
                  {{tipo.nombre}}</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="matricula">Matrícula <span style="color: red;">*</span></label>
              <input class="form-control" type="text" id="matricula" formControlName="matricula"
                (keyup)="mostrarFormConductor()">
              <small class="text-danger"
                *ngIf="isSubmitted && formVehiculo.controls.datos.get('matricula').hasError('required')">El
                campo es obligatorio</small>
            </div>
            <div class="form-group col">
              <label for="marca">Marca <span style="color: red;">*</span></label>
              <select id="marca" class="form-control form-select" formControlName="marca"
                (change)="getAllModeloMarca($event)">
                <option [value]=0>Selecciona una marca</option>
                <option [value]="marca.id" *ngFor="let marca of listaMarcas">{{marca.nombre}}</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="modelo">Modelo <span style="color: red;">*</span></label>
              <select id="modelo" class="form-control form-select" formControlName="modelo">
                <option [value]=0>Selecciona un modelo</option>
                <option [value]="modelo.id" *ngFor="let modelo of listaModeloMarca">{{modelo.nombre}}
                </option>
              </select>
            </div>

          </div>
        </form>

        <!--Seguro-->
        <h2 class="divisiones-formularios">Seguro</h2>
        <hr>

        <form formGroupName="seguro" class="mb-4">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="ciaSeguros">Compañía de seguros / Broker</label>
              <select id="ciaSeguros" class="form-control form-select" formControlName="ciaSeguros"
                (change)="getTelefonoCiaSeguros($event)">
                <!-- <option [value]=null>Selecciona una compañía</option> -->
                <option [value]="cia.id" *ngFor="let cia of listaCiaSeguros">{{cia.nombre}}</option>
              </select>
            </div>
            <div class="form-group col">
              <label for="mutuaPatronal">Mutua patronal</label>
              <input class="form-control" type="text" id="mutuaPatronal" formControlName="mutuaPadronal">
            </div>
            <div class="form-group col">
              <label for="tlfContacto">Teléfono de contacto</label>
              <input class="form-control" type="text" id="tlfContacto" formControlName="tlfContacto" readonly>
            </div>
            <div class="form-group col">
              <label for="fechaVencimiento">Fecha vencimiento</label>
              <input class="form-control" type="date" id="fechaVencimiento" formControlName="fechaVencimiento">
            </div>
          </div>
        </form>

        <!--Taxímetro-->
        <h2 class="divisiones-formularios">Taxímetro</h2>
        <hr>

        <form formGroupName="taximetro" class="mb-4">
          <div class="row">
            <div class="form-group col-sm-3">
              <label for="casaTaximetro">Casa taxímetro</label>
              <input class="form-control" type="text" id="casaTaximetro" formControlName="casaTaximetro">
            </div>
            <div class="form-group col-sm-3">
              <label for="claseTaximetro">Clase taxímetro</label>
              <input class="form-control" type="text" id="claseTaximetro" formControlName="claseTaximetro">
            </div>
            <div class="form-group col-sm-3">
              <label for="marca">Cambio tarifa</label>
              <input class="form-control" type="date" id="marca" formControlName="fechaUltimoCambioDeTarifa">
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-sm-6">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="ultimaTarifaAplicada"
                  formControlName="ultimaTarifaAplicada">
                <label class="form-check-label ml-2" for="ultimaTarifaAplicada">Ejecutado último cambio de
                  tarifa</label>
              </div>
            </div>
          </div>
        </form>

        <!--Mantenimiento-->
        <h2 class="divisiones-formularios">Mantenimiento</h2>
        <hr>

        <form formGroupName="mantenimiento" class="mb-5">
          <div class="row">
            <div class="form-group col">
              <label for="kmEntrada">Km. entrada</label>
              <input class="form-control" type="number" id="kmEntrada" formControlName="kmEntrada">
            </div>
            <div class="form-group col">
              <label for="fechaUltimaDistribucion">Última distribución</label>
              <input class="form-control" type="date" id="fechaUltimaDistribucion"
                formControlName="fechaUltimaDistribucion">
            </div>
            <div class="form-group col">
              <label for="fechaProximaDistribucion">Próxima distribución</label>
              <input class="form-control" type="date" id="fechaProximaDistribucion"
                formControlName="fechaProximaDistribucion">
            </div>
            <div class="form-group col">
              <label for="kmUltimoCambioDeAceite">Km. último aceite</label>
              <input class="form-control" type="number" id="kmUltimoCambioDeAceite"
                formControlName="kmUltimoCambioDeAceite">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-3">
              <label for="kmProximoCambioDeAceite">Km. próximo aceite</label>
              <input class="form-control" type="number" id="kmProximoCambioDeAceite"
                formControlName="kmProximoCambioDeAceite">
            </div>
            <div class="form-group col-sm-3">
              <label for="tipoAceite">Tipo de aceite</label>
              <select id="tipoAceite" class="form-control form-select" formControlName="tipoAceite">
                <option value=null></option>
                <option value="ORGANICO">Orgánico</option>
                <option value="SINTETICO">Sintético</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroAire" formControlName="hasFiltroAire">
                <label class="form-check-label" for="hasFiltroAire">Filtro aire</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroCombustible"
                  formControlName="hasFiltroCombustible">
                <label class="form-check-label" for="hasFiltroCombustible">Filtro combustible</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroHabitaculo"
                  formControlName="hasFiltroHabitaculo">
                <label class="form-check-label" for="hasFiltroHabitaculo">Filtro habitáculo</label>
              </div>
            </div>
          </div>

        </form>

        <!--Mantenimiento-->
        <h2 class="divisiones-formularios">Revisión</h2>
        <hr>

        <form formGroupName="revision" class="mb-4">
          <div class="row">
            <div class="form-group col">
              <label for="ultimaRevision">Última revisión</label>
              <input class="form-control" type="date" id="ultimaRevision" formControlName="ultimaRevision">
            </div>
            <div class="form-group col">
              <label for="vencimientoTaximetro">Vencimiento taxímetro</label>
              <input class="form-control" type="date" id="vencimientoTaximetro" formControlName="vencimientoTaximetro">
            </div>
            <div class="form-group col">
              <label for="vencimientoITV">Vencimiento ITV</label>
              <input class="form-control" type="date" id="vencimientoITV" formControlName="vencimientoITV">
              <small class="text-danger"
                *ngIf="isSubmitted && formVehiculo.controls.revision.get('vencimientoITV').hasError('required')">El
                campo es obligatorio</small>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label for="descripcionVehiculoITV">Observaciones ITV</label>
              <textarea class="form-control" id="descripcionVehiculoITV" rows="3"
                formControlName="descripcionVehiculoITV"></textarea>
            </div>
          </div>

        </form>

      </form>
    </mat-tab>

    <mat-tab label="Conductor" *ngIf="mostrarConductor">
      <form [formGroup]="form" (ngSubmit)="controlJsonAEnviar()" class="crear-editar-form mt-5">
        <h1 class="titulo-formulario">Crea un nuevo conductor</h1>

        <h2 class="divisiones-formularios">Datos</h2>
        <hr>
        <div class="row">
          <div class="form-group col-sm-4">
            <label for="vencimientoBTP">Vencimiento BTP</label>
            <input class="form-control" type="date" id="vencimientoBTP" formControlName="fechaVencimientoBTP">
          </div>
        </div>

        <div class="row mb-0">
          <div class="form-group col-sm-1">
            <label for="tipoDocumento">Tipo <span style="color: red;">*</span></label>
            <select id="tipoDocumento" class="form-control form-select" formControlName="tipo_documento">
              <option [value]=0>Selecciona un tipo de documento</option>
              <option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of tipoDocumentoList">
                {{tipoDocumento.nombre}}
              </option>
            </select>
            <small class="text-danger" *ngIf="isSubmitted && form.get('tipo_documento').hasError('required')">El campo
              es
              obligatorio</small>
          </div>
          <div class="form-group col-sm-3">
            <label for="dni">Número de documento<span style="color: red;">*</span></label>
            <input class="form-control" type="text" id="dni" maxlength="9" minlength="9" formControlName="dni">
            <small class="text-danger" *ngIf="isSubmitted && form.get('dni').hasError('required')">El campo es
              obligatorio</small>
          </div>
          <div class="form-group col-sm-4">
            <label for="nombre">Nombre <span style="color: red;">*</span></label>
            <input class="form-control" type="text" id="nombre" formControlName="nombre">
            <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo
              es obligatorio</small>
          </div>

          <div class="form-group col-sm-4">
            <label for="apellidos">Apellidos</label>
            <input class="form-control" type="text" id="apellidos" formControlName="apellidos">
          </div>  
        </div>
        
        <div formGroupName="sueldos" class="row mb-4">
          <div class="form-group col-sm-4">
            <label for="tipoSueldo">Tipo sueldo<span style="color: red;">*</span></label>
            <select id="tipoSueldo" class="form-control form-select" formControlName="tipoSueldo">
              <option [value]=0>Selecciona una opción</option>
              <option [value]="tipoSueldo.id" *ngFor="let tipoSueldo of tipoSueldoList">{{tipoSueldo.nombre}}
              </option>
            </select>
            <small class="text-danger" *ngIf="isSubmitted && form.get('tipoSueldo').hasError('required')">El campo es
              obligatorio</small>
          </div>

          <div class="form-group col-sm-4">
            <label>Cantidad<span style="color: red;">*</span></label>
            <input class="form-control" type="number" id="porcentaje" formControlName="cantidad"
              (focusout)="checkporcentaje()">
          </div>
        </div>


        <h2 class="divisiones-formularios">Dirección</h2>
        <hr>

        <div class="row mb-4">
          <div class="form-group col-sm-4">
            <label for="domicilio">Domicilio</label>
            <input class="form-control" type="text" id="domicilio" formControlName="domicilio">
          </div>
          <div class="form-group col-sm-3">
            <label for="provincia">Provincia</label>
            <select id="provincia" class="form-control form-select" formControlName="provincia"
              (change)="cargaMunicipios(form.value.provincia)">
              <option [value]=0>Selecciona una provincia</option>
              <option [value]="provincia.id" *ngFor="let provincia of provinciasToSelect">{{provincia.nombre}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-3">
            <label for="poblacion">Municipio</label>
            <select id="poblacion" class="form-control form-select" formControlName="poblacion">
              <option [value]=0>Selecciona una provincia</option>
              <option [value]="municipio.nombre" *ngFor="let municipio of municipiosToSelect">{{municipio.nombre}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-2">
            <label for="codigoPostal">Código postal</label>
            <input class="form-control" type="text" id="codigoPostal" formControlName="codigoPostal" minlength="5"
              maxlength="5" />
          </div>
        </div>

        <h2 class="divisiones-formularios">Contacto</h2>
        <hr>

        <div class="row mb-4">
          <div class="form-group col">
            <div class="form-group" formArrayName="telefono"
              *ngFor="let tel of form.controls.telefono?.value; let i = index; trackBy: trackByFn">
              <div>
                <label for="telefono">Teléfono<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                <span class="input-group">
                  <input class="form-control" type="tel" id="telefono" [formControlName]="i" />
                  <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="i > 0"
                    (click)="removeTelefonoConductor(i)"><mat-icon>close</mat-icon></button>
                </span>
              </div>
            </div>
            <button *ngIf="expandedPhone != 3" type="button" class="btn btn-outline-primary btn-sm"
              (click)="addTelefonoConductor()"><mat-icon>add</mat-icon> Añadir teléfono</button>
          </div>
          <div class="form-group col">
            <div class="form-group" formArrayName="mail" *ngFor="let tel of emailsCondcutor.controls; let i = index">
              <div>
                <label for="mail">Correo electrónico<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                <span class="input-group">
                  <input class="form-control" type="email" id="mail" [formControlName]="i" />
                  <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="i > 0"
                    (click)="removeEmailConductor(i)"><mat-icon>close</mat-icon></button>
                </span>
              </div>
            </div>
            <button *ngIf="expandedEmail != 3" type="button" class="btn btn-outline-primary btn-sm"
              (click)="addEmailConductor()"><mat-icon>add</mat-icon> Añadir email</button>
          </div>
          <div class="form-group col">
            <div class="form-group" formArrayName="cuentaBancaria"
              *ngFor="let tel of cuentasBancarias.controls; let i = index">
              <div>
                <label for="cuentaBancaria">Cuenta Bancaria<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                <span class="input-group">
                  <input class="form-control" type="text" id="cuentaBancaria" [formControlName]="i" />
                  <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="i > 0"
                    (click)="removeCuentaBancariaConductor(i)"><mat-icon>close</mat-icon></button>
                </span>
              </div>
            </div>
            <button *ngIf="expandedIban != 3" type="button" class="btn btn-outline-primary btn-sm"
              (click)="addCuentaBancariaCondcutor()"><mat-icon>add</mat-icon> Añadir cuenta</button>
          </div>
        </div>

        <h2 class="divisiones-formularios">Otros</h2>
        <hr>

        <div class="row mb-4">
          <div class="form-group col-sm-2">
            <label for="retencion">Retención</label>
            <input class="form-control" type="number" id="retencion" formControlName="retencion" />
          </div>
          <div class="form-group col-sm-2">
            <label for="valoracion">Valoración</label>
            <input class="form-control" type="number" id="valoracion" formControlName="valoracion" min="0" />
          </div>
          <div class="form-group col-sm-2">
            <label for="puntuacion">Puntuación</label>
            <input class="form-control" type="number" id="puntuacion" formControlName="puntuacion" min="0" />
          </div>
          <div class="form-check form-check-inline ml-5">
            <input class="form-check-input" type="checkbox" id="lopd" formControlName="lopd" />
            <label class="form-check-label" for="lopd">LOPD</label>
          </div>
          <div class="form-check form-check-inline ml-2">
            <input class="form-check-input" type="checkbox" id="riesgosLaborales" formControlName="riesgosLaborales">
            <label class="form-check-label" for="riesgosLaborales">Riesgos laborales</label>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label for="observaciones">Observaciones</label>
            <textarea class="form-control" id="observaciones" rows="3" formControlName="observaciones"></textarea>
          </div>
        </div>


        <h2 class="divisiones-formularios">Documentos</h2>
        <hr>

        <br>
        <h6 class="col-sm-12 tituloAdjuntar">Adjuntar Ficheros</h6>

        <div class="col-sm-12 d-flex" style="padding: 0;">
          <!-- Parte adjuntar -->
          <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-3 p-3" appDrag
            (files)="filesDropped($event, arrayDocumentsConductor)">

            <div class="text-center ">
              <h6 class="text_bold mt-1">Arrastre y suelte aqui</h6>
              <h6 class="text">o</h6>
              <button class="btn btn-light btn_file">Seleccionar Archivos</button>
              <input id="file" value="file" name="file" class="input_file"
                (change)="onFileChange($event, arrayDocumentsConductor)" onclick="event.target.value=null"
                type="file" />
              <h6 class="indicaciones mt-1">PDF, JPEG o PNG de 20MB máximo. </h6>
            </div>
          </div>

          <!-- Parte documentos -->
          <div class="col-8 ordenar-archivos">
            <div *ngFor="let documento of this.arrayDocumentsConductor"
              class="card card-generic archivos-seleccionados">
              <a class="cortar-texto">{{documento['nombre']}}</a>
              <div class="card border-0 card-width-actions">
                <div>
                  <a (click)="removeFile(arrayDocumentsConductor.indexOf(documento), arrayDocumentsConductor)"
                    class="delete-button activated boton-eliminar"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-tab>

  </mat-tab-group>

  <div id="container" class="d-flex justify-content-end pt-3">
    <button *ngIf="!editar" class="btn btn-success mx-3" (click)="openDialogConfirm()" type="submit">
      <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
      <div *ngIf="!uploadDocuments">Guardar</div>
    </button>


    <button class="btn btn-secondary" (click)="confirmCancelOperation()" type="button">Cerrar</button>
  </div>
</form>

<!--<div class="btnGuardarCancelar row">
  <button class="btn btn-primary" tabindex="-1" type="submit">Guardar</button>
  <button class="btn btn-secondary" tabindex="-1" type="button">Cancelar</button>
</div>-->
<div class="crear-editar-form">
    <h1 class="titulo-formulario">Edita un día de libranza</h1>

    <div class="mw-100 mt-2">

        <div class="row">
            <div class="col">
                <div class="row">

                    <!-- ------------------------------- Campo libranza actual ----------------------- -->
                    <div class="col-sm-3">
                        <label>Fecha de libranza actual</label>
                        <input _ngcontent-vka-c280="" [ngModel]="fechaLibranzaActual | date:'yyyy-MM-dd'"
                            (change)="getValue($event, false)"
                            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date"
                            name="fechaLibranzaActual">
                    </div>

                    <!-- ------------------------------- Campo libranza nueva ----------------------- -->
                    <div class="col-sm-3">
                        <label>Fecha de libranza nueva</label>
                        <input _ngcontent-vka-c280="" [ngModel]="fechaLibranzaNueva | date:'yyyy-MM-dd'"
                            (change)="getValue($event, true)"
                            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date"
                            name="fechaLibranzaNueva">
                    </div>

                    <!-- ------------------------------- Campo licencia ----------------------- -->
                    <div class="form-group col-sm-3">
                        <label>Licencia<span style="color: red;">*</span></label>
                        <select class="form-control form-select" name="licencia" [(ngModel)]="selectedValueLicencia"
                            (change)="getConductoresByLicencia(selectedValueLicencia)">
                            <option [ngValue]=null>Selecciona una licencia</option>
                            <option *ngFor="let licencia of licencias" [value]="licencia.id">
                                {{licencia.numero_licencia}}
                            </option>
                        </select>
                    </div>

                    <!-- ------------------------------- Campo conductor ----------------------- -->
                    <div class="form-group col-sm-3">
                        <label>Conductor<span style="color: red;">*</span></label>
                        <select class="form-control form-select" [(ngModel)]="selectedValueConductor" name="conductor">
                            <option [ngValue]=null>Selecciona un conductor</option>
                            <option *ngFor="let conductor of conductores" [value]="conductor.id">
                                {{conductor.nombre}} {{conductor.apellidos}}
                            </option>
                        </select>
                    </div>

                </div>

                <!-- ------------------------------- Botones ----------------------- -->
                <div id="container" class="d-flex justify-content-end pt-3">
                    <button class="btn btn-success" type="submit" (click)="openDialogConfirm()">Guardar</button>
                    <button class="btn btn-secondary ml-3" type="button"
                        (click)="confirmCancelOperation()">Cerrar</button>
                </div>
            </div>
        </div>



    </div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  private subject: Subject<any> = new BehaviorSubject<any>([]);

  prueba: string;
 
  sendData(message: string) {
      this.subject.next(message);      
  }

  getData() {
      return this.subject.asObservable();
  }
}

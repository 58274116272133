import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { IConductor, TipoSueldo } from 'src/app/conductores/conductor.model';
import { Licencia, Tpv, Emisora } from 'src/app/model/Emisora';
import { IProvincia, Propietario, Gestoria } from 'src/app/model/novotaxi/propietario.model';
import { ICompaniaSeguros } from 'src/app/novotaxi/companiaSeguros/companiaSeguros.model';
import { CompaniaSegurosService } from 'src/app/novotaxi/companiaSeguros/services/companiaSeguros.service';
import { IMarca, IModelo } from 'src/app/novotaxi/marca-modelo/modelo.model';
import { MarcaService } from 'src/app/novotaxi/marca-modelo/services/marca.service';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { VehiculoService } from 'src/app/vehiculo/services/vehiculo.service';
import { IVehiculo } from 'src/app/vehiculo/vehiculo.model';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Documento } from 'src/app/model/documento';
import { PropietarioService } from 'src/app/_services/novotaxi/propietario.service';
import { ConductorSueldo } from 'src/app/model/novotaxi/conductor-sueldo.model';

@Component({
  selector: 'app-actualizar-licencia',
  templateUrl: './actualizar-licencia.component.html',
  styleUrls: ['../listar-licencia.component.css']
})

export class ActualizarLicenciaComponent {

  constructor(
    private servicioLicencia: LicenciaServiece,
    private router: Router,
    public dialog: MatDialog,
    public _notificationService: NotificationService,
    private obtenerProvinciaService: ServicioGeneral,
    private _servicioGeneral: ServicioGeneral,
    private marcaService: MarcaService,
    private companiaSegurosService: CompaniaSegurosService,
    protected conductorService: ConductorService,
    protected vehiculoService: VehiculoService,
    private _propietarioService: PropietarioService,
  ) { }

  @Input() editar: Boolean;
  @Input() licenciaUpdateDialog: Licencia;
  @Input() listaMunicipios: any[];
  @Output() mostrarFormulario = new EventEmitter<Boolean>();

  mostrarBotones: Boolean = true;
  isSubmitted;
  selectedValueGestoria: string;
  selectedValueProvincia: any;
  selectedValueMunicipio: any;
  selectedTipoDocumento: number;
  selectedTipoSueldo: number;
  selectedValueEmisora: any = 0;
  isEmisora = false;
  fecha_alta = null;
  isTPV = false;
  numero_licencia = null;
  TDocumentos = null;
  message = null;
  uploadSucesfully = false;
  uploadFail = true;
  isFocused = false;
  licenciaToUpdate = null;
  expandedPhone: number = 1;
  expandedMail: number = 1;
  gestoriaToSelect = null;
  municipiosToSelect = null;
  emails = [{ value: '' }];
  telefonos = [{ value: '' }];
  ibanList = [{ value: '' }];
  ibanArray: string[] = [];
  telefonosArray = [];
  emailsArray = [];
  tipoDocumentoList: Documento[] = [];
  tipoVehiculo: any;
  expandedEmail: number = 1;

  // -- Variables histórico licencia
  propietarioTemp: Propietario = null;
  licenciaHistoricoToSend: Licencia[] = [];
  isPropietarioExist: boolean = false;
  dateTemp: Date = null;

  form: any;
  formVehiculo: any;
  public listaMarcas: IMarca[] = [];
  public listaModeloMarca: IModelo[] = [];
  public listaModelos: IModelo[] = [];
  public listaCiaSeguros: ICompaniaSeguros[] = [];
  tipoSueldoList: TipoSueldo[] = [];

  provinciasToSelect: IProvincia[] = [];
  conductorAux;
  vehiculoAux: IVehiculo;
  conductor;
  vehiculo;
  conductorUpdate: IConductor;
  vehiculoUpdate: IVehiculo;
  public licencia: Licencia = {};
  public tpv: Tpv = {};
  public emisora: Emisora = {};
  public propietario: Propietario = {};
  public gestoria: Gestoria = {};

  //variables vehiculo
  public telefonoCiaSeguros: string[] = null;

  // -- Variables histórico
  mostrarHistorico: Boolean = false;
  mostrarBotonesHistorico: Boolean = true;
  licenciaHistorico;
  licenciaHistoricoPropietario;

  ngOnInit(): void {
    this.getHistoricoLicencia();
    this.getTipoDocumento();
    this.getDocumentsByConductor();
    this.getDocumentsByPropietario();
    this.getTipoSueldo();

    if (this.licenciaUpdateDialog?.emisora) {
      this.selectedValueEmisora = this.licenciaUpdateDialog?.emisora?.id;
    }

    if (!this.licenciaUpdateDialog?.propietario) {

      this.licenciaUpdateDialog.propietario = new Propietario(this.propietario);
      this.emails.pop();
      this.telefonos.pop();
      this.ibanList.pop();
      this.emails.push({ value: '' });
      this.telefonos.push({ value: '' });
      this.ibanList.push({ value: '' });

      this.emailsArray = this.emails.map(el => el.value);
      this.licenciaUpdateDialog.propietario.emails = this.emailsArray;

      this.telefonosArray = this.telefonos.map(tlf => tlf.value);
      this.licenciaUpdateDialog.propietario.telefonos = this.telefonosArray;
      this.ibanArray = this.ibanList.map(el => el.value);
      this.licenciaUpdateDialog.propietario.iban = this.ibanArray;

    } else {
      this.licenciaUpdateDialog.propietario = new Propietario(this.licenciaUpdateDialog.propietario);
      this.selectedValueGestoria = this.licenciaUpdateDialog?.propietario?.gestoria?.nombre ? this.licenciaUpdateDialog?.propietario?.gestoria?.nombre : '';
      this.selectedValueProvincia = this.licenciaUpdateDialog?.propietario?.provincia?.id;
      this.selectedValueMunicipio = this.licenciaUpdateDialog?.propietario?.poblacion;
      this.selectedTipoDocumento = this.licenciaUpdateDialog?.propietario?.tipo_documento.id;
    }

    if (this.licenciaUpdateDialog?.propietario?.emails?.length > 0) {
      this.emails.pop();
      this.licenciaUpdateDialog.propietario?.emails.forEach(email => {
        this.emails.push({ value: email });
      });
    }

    if (this.licenciaUpdateDialog?.propietario?.telefonos?.length > 0) {
      this.telefonos.pop();
      this.licenciaUpdateDialog.propietario?.telefonos.forEach(tlf => {
        this.telefonos.push({ value: tlf });
      });
    }

    if (this.licenciaUpdateDialog?.propietario?.iban?.length > 0) {
      this.ibanList.pop();
      this.licenciaUpdateDialog.propietario?.iban.forEach(iban => {
        this.ibanList.push({ value: iban });
      });
    }

    this.vehiculo = this.licenciaUpdateDialog.vehiculo;
    this.licencia = JSON.parse(JSON.stringify(this.licenciaUpdateDialog));

    this.getTipoVehiculo();
    this.listEmisora();
    this.listGestoria();
    this.listProvincias();

    if (this.licenciaUpdateDialog.propietario.provincia != null) {
      this.cargaMunicipios(this.licenciaUpdateDialog.propietario.provincia.id);
    }

    // this.listMunicipios();
    this.cargarVehiculo();
    this.cargarConductor();
    this.getAllModelo();
    this.getAllMarca();
    this.listAllCiaSeguros();
  }

  getTipoSueldo() {
    this.conductorService.getTiposSueldo().subscribe({
      next: (data) => {
        if (data["status"] = "616") {
          this.tipoSueldoList = data["tiposSueldos"];
        }
      }
    })
  }

  resetearForm() {
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  getTipoDocumento() {
    this._servicioGeneral.getTipoDocumento().subscribe((data) => {
      if (data["message"]["status"] = "616") {
        this.tipoDocumentoList = data["TipoDocumento"];
      }
    })
  }

  setMostrarHistorico(mode: Boolean) {
    this.mostrarHistorico = mode;
    if (mode) {
      this.mostrarBotonesHistorico = false;
    }
    else {
      this.mostrarBotonesHistorico = true;
    }
  }

  getHistoricoLicencia() {
    this.servicioLicencia.getHistoricoAsociacion(this.licenciaUpdateDialog?.id).subscribe((result) => {
      this.licenciaHistoricoPropietario = result;
    });

    this.servicioLicencia.getHistorico(this.licenciaUpdateDialog?.id).subscribe((result) => {
      this.licenciaHistorico = result['historico'];
    });
  }

  /*prepareData() {
    this.licenciaHistorico = [];
    this.licenciaHistoricoToSend.forEach(element => {
      
      if (element.propietario != null && element.fechaBaja == null) {        
        this.propietarioTemp = element.propietario;
        this.dateTemp = element.fechaModificacion;
        this.isPropietarioExist = true;
      } else {
        if (this.isPropietarioExist) {
          element.propietario = this.propietarioTemp;
          element.fechaAlta = this.dateTemp;
          this.licenciaHistorico.push(element);
        } else {
          if (element.fechaBaja != null) {
            this.licenciaHistorico.push(element);
          }
          this.isPropietarioExist = false;
        }
      }
    });
  }*/

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      } else {
        null;
      }
    });
  }

  async openDialogConfirm(licencia) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = 'guardar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.validacionLicenciaUpdate(licencia);
      } else {
        null;
      }
    });
  }

  getTipoVehiculo() {
    this.vehiculoService.findTipoVehiculo().subscribe((result) => {
      this.tipoVehiculo = result;
    });
  }

  getValue(event) {
    this.licencia.propietario.fechaAlta = event.target.value;
  }

  //metodos compania de seguro
  listAllCiaSeguros() {
    this.companiaSegurosService.query().subscribe(
      (res) => {
        this.listaCiaSeguros = res['CompaniasSeguros'];
      }
    );
  }

  getTelefonoCiaSeguros(event: Event) {
    let idCiaSeguros: number = +(event.target as HTMLInputElement).value - 1;

    if (idCiaSeguros == -1) {
      this.formVehiculo.controls.seguro.controls.tlfContacto.setValue(null); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      this.telefonoCiaSeguros = this.listaCiaSeguros[idCiaSeguros].telefono;
      this.formVehiculo.controls.seguro.controls.tlfContacto.setValue(this.telefonoCiaSeguros);
    }
  }

  removeIban(i) {
    this.ibanList.splice(i, 1);
  }

  addIban() {
    this.ibanList.push({ value: '' });
  }

  cargarConductor() {
    this.conductorAux = this.vehiculo[0]?.conductores[0] ? this.vehiculo[0]?.conductores[0] : null;

    if (this.conductorAux) {
      var sueldo: ConductorSueldo = this.conductorAux.sueldos.find(sueldo => sueldo.licencia.id == this.licenciaUpdateDialog.id && sueldo.vehiculo.id == this.vehiculo[0].id);
    }

    this.form = new FormGroup({
      fechaVencimientoBTP: new FormControl(this.convertDateCarga(this.conductorAux?.fechaVencimientoBTP, false)),
      fechaAlta: new FormControl(this.convertDateCarga(this.conductorAux?.fechaAlta, false)),
      tipo_documento: new FormControl(this.conductorAux?.tipo_documento?.id | 0),
      dni: new FormControl(this.conductorAux?.dni, [Validators.pattern("^[0-9]{8}[A-Z]$")]),
      nombre: new FormControl(this.conductorAux?.nombre),
      apellidos: new FormControl(this.conductorAux?.apellidos),
      domicilio: new FormControl(this.conductorAux?.domicilio),
      provincia: new FormControl(this.conductorAux?.provincia?.id | 0),
      poblacion: new FormControl(this.conductorAux?.poblacion),
      codigoPostal: new FormControl(this.conductorAux?.codigoPostal, [Validators.pattern("[0-9]{5}")]),
      mail: this.camposEmail(),
      telefono: this.camposTelefono(),
      cuentaBancaria: new FormControl(this.conductorAux?.cuentaBancaria, /*[Validators.required, Validators.minLength(5), Validators.maxLength(34)]*/),
      retencion: new FormControl(this.conductorAux?.retencion),
      valoracion: new FormControl(this.conductorAux?.valoracion),
      puntuacion: new FormControl(this.conductorAux?.puntuacion),
      lopd: new FormControl(this.conductorAux?.lopd),
      riesgosLaborales: new FormControl(this.conductorAux?.riesgosLaborales),
      observaciones: new FormControl(this.conductorAux?.observaciones),
      sueldos: new FormGroup({
        tipoSueldo: new FormControl({ value: sueldo?.tipoSueldo?.id, disabled: true }, Validators.required),
        cantidad: new FormControl({ value: sueldo?.cantidad, disabled: true }, Validators.required),
      })
      /*      porcentaje: new FormControl(this.conductorAux?.porcentaje),
           salario_fijo: new FormControl(this.conductorAux?.salario_fijo),
           nomina: new FormControl(this.conductorAux?.nomina),
           tipoSueldo: new FormControl(this.conductorAux?.tipoSueldo?.id | 0), */
    }, { updateOn: 'change' });
    for (const key in this.form.controls) {
      this.form.controls[key].disable();
    }
  }

  cargarVehiculo() {
    this.vehiculoAux = (this.vehiculo.length == 0 || this.vehiculo == null) ? null : new IVehiculo(this.vehiculo[0]);
    this.formVehiculo = new FormGroup({
      datos: new FormGroup({
        licencia: new FormControl(),
        tipo: new FormControl(this.vehiculoAux?.tipo?.id),
        matricula: new FormControl(this.vehiculoAux?.matricula, Validators.required),
        marca: new FormControl(this.vehiculoAux?.modelo?.marca?.id, [Validators.required, Validators.pattern("^[1-9]*$")]),
        modelo: new FormControl(this.vehiculoAux?.modelo?.id, [Validators.required, Validators.pattern("^[1-9]*$")]),
      }),
      seguro: new FormGroup({
        ciaSeguros: new FormControl(this.vehiculoAux?.seguro?.ciaSeguros?.id),
        tlfContacto: new FormControl(this.vehiculoAux?.seguro?.ciaSeguros?.telefono),
        mutuaPadronal: new FormControl(this.vehiculoAux?.seguro?.mutuaPadronal),
        fechaVencimiento: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.seguro?.fechaVencimiento, false)),
      }),
      taximetro: new FormGroup({
        casaTaximetro: new FormControl(this.vehiculoAux?.taximetro?.casaTaximetro),
        claseTaximetro: new FormControl(this.vehiculoAux?.taximetro?.claseTaximetro),
        ultimaTarifaAplicada: new FormControl(this.vehiculoAux?.taximetro?.ultimaTarifaAplicada),
        fechaUltimoCambioDeTarifa: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.taximetro?.fechaUltimoCambioDeTarifa, false)),
      }),
      mantenimiento: new FormGroup({
        kmEntrada: new FormControl(this.vehiculoAux?.mantenimiento?.kmEntrada, Validators.required),
        fechaUltimaDistribucion: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.mantenimiento?.fechaUltimaDistribucion, false)),
        fechaProximaDistribucion: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.mantenimiento?.fechaProximaDistribucion, false)),
        kmUltimoCambioDeAceite: new FormControl(this.vehiculoAux?.mantenimiento?.kmUltimoCambioDeAceite),
        kmProximoCambioDeAceite: new FormControl(this.vehiculoAux?.mantenimiento?.kmProximoCambioDeAceite),
        tipoAceite: new FormControl(this.vehiculoAux?.mantenimiento?.tipoAceite),
        hasFiltroAire: new FormControl(this.vehiculoAux?.mantenimiento?.hasFiltroAire),
        hasFiltroCombustible: new FormControl(this.vehiculoAux?.mantenimiento?.hasFiltroCombustible),
        hasFiltroHabitaculo: new FormControl(this.vehiculoAux?.mantenimiento?.hasFiltroHabitaculo),
      }),
      revision: new FormGroup({
        ultimaRevision: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.documentacion?.revision?.ultimaRevision, false)),
        vencimientoTaximetro: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.documentacion?.revision?.vencimientoTaximetro, false)),
        vencimientoITV: new FormControl(this.convertDateVehiculoConductor(this.vehiculoAux?.documentacion?.revision?.vencimientoITV, false)),
        descripcionVehiculoITV: new FormControl(this.vehiculoAux?.documentacion?.revision?.descripcionVehiculoITV),
      })
    }, { updateOn: 'change' })
    for (const key in this.formVehiculo.controls) {
      this.formVehiculo.controls[key].disable();
    }
  }

  trackByFn(item: any) {
    return item.trackingId;
  }

  submitConductorUpdate(licencia) {
    let conductor = {
      id: licencia.vehiculo[0]?.conductores?.id ?? null,
      dni: this.form.value.dni,
      nombre: this.form.value.nombre,
      apellidos: this.form.value.apellidos,
      fechaVencimientoBTP: this.form.value.fechaVencimientoBTP != "" ? this.convertDateCarga(this.form.value.fechaVencimientoBTP, true) : null,
      domicilio: this.form.value.domicilio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      poblacion: this.form.value.poblacion,
      codigoPostal: this.form.value.codigoPostal,
      mail: this.form.value.mail,
      telefono: this.form.value.telefono,
      cuentaBancaria: this.form.value.cuentaBancaria,
      retencion: this.form.value.retencion,
      valoracion: this.form.value.valoracion,
      puntuacion: this.form.value.puntuacion,
      lopd: this.form.value.lopd,
      riesgosLaborales: this.form.value.riesgosLaborales,
      observaciones: this.form.value.observaciones,
      porcentaje: this.form.value.porcentaje,
      salario_fijo: this.form.value.salario_fijo,
    }
    this.conductorUpdate = conductor;

    //this.conductorUpdate['vehiculos'] = [licencia?.vehiculo[0]]
    return this.submitVehiculoUpdate(licencia, conductor);
  }

  camposTelefono() {
    let telefono;

    if (this.conductorAux) {
      let telefonosConductor = this.conductorAux?.telefono;
      telefono = new FormArray([new FormControl(telefonosConductor[0])]);
      for (let i = 1; i < telefonosConductor.length; i++) {
        telefono.push(new FormControl(telefonosConductor[i].toString()));
        this.expandedPhone++;
      }
    } else {
      telefono = new FormArray([new FormControl(null)]);
    }

    return telefono;
  }

  camposEmail() {
    let email;

    if (this.conductorAux) {
      let emailsConductor = this.conductorAux?.mail;
      email = new FormArray([new FormControl(emailsConductor[0])]);
      for (let i = 1; i < emailsConductor.length; i++) {
        email.push(new FormControl(emailsConductor[i].toString()));
        this.expandedMail++;
      }
    } else {
      email = new FormArray([new FormControl(null)]);
    }

    return email;
  }

  addTelefonoConductor() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  get telefonosConductor() {
    return this.form.get("telefono") as FormArray;
  }

  get emailsCondcutor() {
    return this.form.get("mail") as FormArray;
  }

  addEmailConductor() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl(""),
    )
    this.expandedEmail++;
  }

  removeEmailConductor(i) {
    this.emailsCondcutor.removeAt(i);
    this.expandedEmail--;
  }

  removeTelefonoConductor(i: number) {
    const tels = this.form.controls.telefono;
    tels.removeAt(i);
    this.expandedPhone--;
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  convertDateCarga(date: string, modo: Boolean): string {
    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Añádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia);
      }
    }
    else return null;
  }

  addEmails() {
    this.emails.push({ value: "" });
  }

  addTelefono() {
    this.telefonos.push({ value: "" });
  }

  removeTelefono(i) {
    this.telefonos.splice(i, 1);
  }

  removeEmails(i) {
    this.emails.splice(i, 1);
  }

  //acceso a endPoints de carga de datos.
  listProvincias() {
    this.obtenerProvinciaService.getProvincias().subscribe(
      (data) => {
        this.provinciasToSelect = data['Provincia'];
      });
  }

  listEmisora() {
    this.servicioLicencia.listEmisora().subscribe(
      (data) => {
        this.TDocumentos = data.emisoras;
      });
  }

  //puede desaparecer
  listGestoria() {
    this.servicioLicencia.listGestoria().subscribe(
      (data) => {
        this.gestoriaToSelect = data['gestorias'];
      });
  }

  /* listMunicipios() {
    this.servicioLicencia.listMunicipio().subscribe(
      (data) => {
        this.listaMunicipios = data['Municipio']
        this.cargaMunicipios(this.selectedValueProvincia);

      });
  } */

  cargaMunicipios(idProvincia) {
    this.municipiosToSelect = this.listaMunicipios.filter(option => idProvincia == option.municipioPK.provincia);
  }

  //vehiculo
  submitVehiculoUpdate(licencia, conductorSave?) {

    let marca: IMarca;
    let modelo: IModelo;

    let vehiculo = {
      id: licencia.vehiculo[0]?.id ?? null,

      modelo: this.formVehiculo.value.datos.modelo == 0 ? null : ( //Para permitir que no se introduzca una companía de seguros
        {
          id: this.formVehiculo.value.datos.modelo,
        }
      ),
      matricula: this.formVehiculo.value.datos.matricula,
      seguro: {
        ciaSeguros: this.formVehiculo.value.seguro.ciaSeguros == null ? null : ( //Para permitir que no se introduzca una companía de seguros
          {
            id: this.formVehiculo.value.seguro.ciaSeguros,
            telefono: this.formVehiculo.value.seguro.tlfContacto
          }
        ),
        mutuaPadronal: this.formVehiculo.value.seguro.mutuaPadronal,
        fechaVencimiento: this.convertDateVehiculoConductor(this.formVehiculo.value.seguro.fechaVencimiento, true),
      },
      taximetro: {
        casaTaximetro: this.formVehiculo.value.taximetro.casaTaximetro,
        claseTaximetro: this.formVehiculo.value.taximetro.claseTaximetro,
        ultimaTarifaAplicada: this.formVehiculo.value.taximetro.ultimaTarifaAplicada,
        fechaUltimoCambioDeTarifa: this.convertDateVehiculoConductor(this.formVehiculo.value.taximetro.fechaUltimoCambioDeTarifa, true)
      },
      mantenimiento: {
        kmEntrada: this.formVehiculo.value.mantenimiento.kmEntrada,
        fechaUltimaDistribucion: this.convertDateVehiculoConductor(this.formVehiculo.value.mantenimiento.fechaUltimaDistribucion, true),
        fechaProximaDistribucion: this.convertDateVehiculoConductor(this.formVehiculo.value.mantenimiento.fechaProximaDistribucion, true),
        kmUltimoCambioDeAceite: this.formVehiculo.value.mantenimiento.kmUltimoCambioDeAceite,
        kmProximoCambioDeAceite: this.formVehiculo.value.mantenimiento.kmProximoCambioDeAceite,
        tipoAceite: this.formVehiculo.value.mantenimiento.tipoAceite,
        hasFiltroAire: this.formVehiculo.value.mantenimiento.hasFiltroAire,
        hasFiltroCombustible: this.formVehiculo.value.mantenimiento.hasFiltroCombustible,
        hasFiltroHabitaculo: this.formVehiculo.value.mantenimiento.hasFiltroHabitaculo,
      },
      documentacion: {
        revision: {
          ultimaRevision: this.convertDateVehiculoConductor(this.formVehiculo.value.revision.ultimaRevision, true),
          vencimientoTaximetro: this.convertDateVehiculoConductor(this.formVehiculo.value.revision.vencimientoTaximetro, true),
          vencimientoITV: this.convertDateVehiculoConductor(this.formVehiculo.value.revision.vencimientoITV, true),
          descripcionVehiculoITV: this.formVehiculo.value.revision.descripcionVehiculoITV,
        }
      }
    }

    return licencia;

    // if(conductorSave.vehiculos[0] == null){
    //   conductorSave['vehiculos']=[vehiculo];
    //   // vehiculo['conductores']=[conductorSave];
    //   // vehiculo['conductores'].vehiculos=null;
    // }
    // licencia.vehiculo.push(JSON.parse(JSON.stringify(new IVehiculo(vehiculo))));

    /* conductorSave['vehiculos'] = [new IVehiculo(vehiculo)]
    console.log("ConductorSave: ", conductorSave);
    
    vehiculo['conductores'] = [new IConductor(conductorSave)];
    if (vehiculo.matricula != null && vehiculo.matricula != '') {
      licencia['vehiculo'] = [new IVehiculo(vehiculo)]
    } */
  }

  listAllModeloMarca(event: Event) {
    let idMarca: number = +(event.target as HTMLInputElement).value - 1;
    if (idMarca == -1) {
      this.formVehiculo.controls.datos.controls.modelo.setValue(0); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
      this.listaModeloMarca = [];
    }
    else {
      //this.listaModeloMarca = this.listaMarcas[idMarca].modelos;
    }
  }

  getAllMarca() {
    this.marcaService.queryMarcas().subscribe((res) => {
      this.listaMarcas = res['marcas'];
      this.getAllModeloMarcaEditar(this.vehiculo[0]?.modelo?.marca?.id);
    });
  }

  getAllModelo() {
    this.marcaService.query().subscribe({
      next: (res) => {
        this.listaModelos = res['modelos'];
        this.listaModelos = this.listaModelos?.filter(modelo => modelo?.fechaBaja == null);
      }
    });
  }

  getAllModeloMarca(event: Event) {
    let idMarca: number = +(event.target as HTMLInputElement).value - 1;
    if (idMarca == -1) {
      this.formVehiculo.controls.datos.controls.modelo.setValue(0); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      this.listaModeloMarca = this.listaModelos.filter(modelo => modelo.marca.id == idMarca + 1);
    }
  }

  getAllModeloMarcaEditar(idMarca: number) {
    this.listaModeloMarca = this.listaModelos.filter(modelo => modelo.marca.id == idMarca);
  }

  convertDateVehiculoConductor(date: string, modo: Boolean): string {
    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];

        return (annio + '-' + mes + '-' + dia);
      }
    }
    else return null;
  }

  //UpdateJorge
  newUpdate(updateOfLicencia) {
    if (this.tpv.id) {
      updateOfLicencia['tpv'] = this.tpv;
    }
    if (this.emisora.id) {
      updateOfLicencia['emisora'] = this.emisora;
    }
    updateOfLicencia = this.submitConductorUpdate(updateOfLicencia);
    this.actualizarLicencia(updateOfLicencia);
  }

  validacionLicenciaUpdate(licencia) {
    let newLicencia = new Licencia(licencia);
    newLicencia = new Licencia(this.submitConductorUpdate(newLicencia));

    if (newLicencia.id == null) {
      newLicencia.id = this.licenciaUpdateDialog.id;
    }

    if (newLicencia.numero_licencia == null) {
      newLicencia.numero_licencia = this.licenciaUpdateDialog.numero_licencia;
    }

    if (newLicencia.dia_libranza == null) {
      newLicencia.dia_libranza = this.licenciaUpdateDialog.dia_libranza;
    }

    //validacion tpv
    if (this.tpv.numero_serie != undefined && this.tpv.numero_comercio != undefined) {
      newLicencia.tpv = this.tpv;
    }

    if (this.tpv.numero_serie != undefined && this.tpv.numero_comercio === undefined) {
      newLicencia.tpv = this.tpv;
      newLicencia.tpv.numero_serie = this.tpv.numero_serie;
      if (this.licenciaUpdateDialog.tpv != undefined) {
        newLicencia.tpv.numero_comercio = this.licenciaUpdateDialog.tpv.numero_comercio;
        newLicencia.tpv.id = this.licenciaUpdateDialog.tpv.id;
      }
    }

    if (this.tpv.numero_comercio != undefined && this.tpv.numero_serie === undefined) {
      newLicencia.tpv = this.tpv;
      newLicencia.tpv.numero_comercio = this.tpv.numero_comercio;
      if (this.licenciaUpdateDialog.tpv != undefined) {
        newLicencia.tpv.numero_serie = this.licenciaUpdateDialog.tpv.numero_serie;
        newLicencia.tpv.id = this.licenciaUpdateDialog.tpv.id;
      }
    }

    //validacion emisora
    if (this.emisora.id != null) {
      newLicencia.emisora = this.emisora;
    }

    if (newLicencia.clave_emisora == null) {
      newLicencia.clave_emisora = this.licenciaUpdateDialog.clave_emisora;
    }

    //validacion propietario
    if (this.selectedValueGestoria != null && this.selectedValueGestoria != '') {
      newLicencia.propietario.gestoria = this.gestoriaToSelect?.find(gestoria => gestoria.nombre == this.selectedValueGestoria);
    }

    if (this.selectedValueProvincia != null) {
      newLicencia.propietario.provincia = this.provinciasToSelect?.find(provincia => provincia.id == this.selectedValueProvincia);
    }

    if (this.selectedValueMunicipio != null && this.selectedValueMunicipio != '') {
      newLicencia.propietario.poblacion = this.selectedValueMunicipio;
    }

    if (this.selectedValueEmisora != null) {
      newLicencia.emisora = this.TDocumentos?.find(emisora => emisora.id == this.selectedValueEmisora);
    }

    if (!newLicencia.propietario?.numero_documento) {
      newLicencia.propietario = null;
    } else {
      this.telefonosArray = this.telefonos.map(el => el.value);

      if (this.telefonosArray[0] != '') {
        newLicencia.propietario.telefonos = this.telefonosArray;
      } else {
        newLicencia.propietario.telefonos = null;
      }

      this.emailsArray = this.emails.map(el => el.value);

      if (this.emailsArray[0] != '') {
        newLicencia.propietario.emails = this.emailsArray;
      } else {
        newLicencia.propietario.emails = null;
      }

      this.ibanArray = this.ibanList.map(el => el.value)

      if (this.ibanArray[0] != '') {
        newLicencia.propietario.iban = this.ibanArray;
      } else {
        newLicencia.propietario.iban = null;
      }
      // newLicencia.propietario = new Propietario(newLicencia.propietario);
    }

    newLicencia = new Licencia(newLicencia);

    if (this.licencia.fechaBaja != null) {
      newLicencia.fechaBaja = null;
    }

    this.actualizarLicencia(newLicencia);
    // this.controlJsonAEnviar(licencia);
  }

  actualizarLicencia(licencia) {
    licencia.vehiculo = null;

    this.servicioLicencia.updateLicencia(licencia).subscribe(
      (data) => {
        this.TDocumentos = null;
        if (data['status'] == "602") {
          this._notificationService.success("HECHO", data['message'], 3000);
          //          this.licencia.propietario = this.propietario

          this.reloadLicencia();
          this.onNoClick();
          //this.actualizarCondcutor();
        } else {
          this._notificationService.error("ERROR", data['message'].message, 3000);
        }
      });
  }

  /* actualizarCondcutor() {
    if (this.conductorUpdate.dni) {
      if (this.conductorUpdate.mail[0] == null) {
        this.conductorUpdate.mail = null;
      }
      if (this.conductorUpdate.telefono[0] == null) {
        this.conductorUpdate.telefono = null;
      }
      this.conductorService.update(this.conductorUpdate).subscribe({
        next: (data) => {
          if (data.body['message'].status == "608") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
            //this.dialogRef.close()
            this.reloadLicencia();
            this.onNoClick();
          } else {
            let message = data.body['message'];
            this._notificationService.error("La operación no se ha podido realizar", message, 3000);

          }
        }
      });
    } else {
      this.reloadLicencia();
      this.onNoClick();
    }
  } */

  reloadLicencia() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.onSameUrlNavigation = 'reload';
    this.scrollTop();
    this.router.navigate([this.router.url]);
  }

  scrollTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  onNoClick(): void {
    //this.dialogRef.close();
  }

  isFocusedInput() {
    this.isFocused = true;
  }

  arrayDocuments = [];
  getDocumentsByConductor() {

    let conductor = this.licenciaUpdateDialog.vehiculo[0]?.conductores[0];

    if (conductor != null) {
      this.conductorService.getDocuments(conductor.id).subscribe({
        next: (data) => {
          this.arrayDocuments = data['documentos'];
        }
      })
    }
  }

  arrayDocumentsPropietario = [];
  getDocumentsByPropietario() {

    let id = this.licenciaUpdateDialog?.propietario?.id;

    if (id != null) {
      this._propietarioService.getDocuments(id).subscribe({
        next: (data) => {
          this.arrayDocumentsPropietario = data['documentos'];
        }
      })
    }
  }

  // -- Pruebas descarga

  downloadDocument(documento) {
    const base64Data = documento.base64;
    const mime = documento.mime;
    const nombre = documento.nombre;


    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mime });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = nombre; // Establece el nombre del archivo

    document.body.appendChild(a);
    a.click();

    // Limpia recursos
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

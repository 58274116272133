import { Component, OnInit, ViewChild, Inject, Input } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { IConductor } from "../conductor.model";
import { ConductorService } from "../services/conductor.service";
import { NotificationService } from "src/app/_services/notification.service";
import { IProvincia } from "src/app/model/novotaxi/propietario.model";
import { VehiculoService } from "src/app/_services/vehiculo.service";
import { IVehiculo } from "src/app/vehiculo/vehiculo.model";
import { firstValueFrom } from "rxjs";
import { InformePDF } from "src/app/novotaxi/licencias/informe-licencia/informePDF";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { CardDetallesGraficosComponent } from "src/app/novotaxi/licencias/card-detalles-graficos/card-detalles-graficos.component";
import { ModalBajaComponent } from "src/app/modal/modal-baja/modal-baja.component";
import * as JSZip from 'jszip';
import { ExcelExportService } from "src/app/_services/excel-export.service";

@Component({
  selector: 'app-listado-conductores',
  templateUrl: './listado-conductores.component.html',
  styleUrls: ['./listado-conductores.component.css'],
})

export class ListadoConductoresComponent implements OnInit {

  displayedColumns: string[] = ['dni', 'nombre', 'apellidos', 'fechaAlta', 'fechaVencimientoBTP', 'domicilio', 'poblacion',
    'provincia.nombre', 'codigoPostal', 'mail', 'telefono', 'cuentaBancaria', 'salario_fijo', 'retencion', 'porcentaje', 'puntuacion', 'valoracion',
    'lopd', 'riesgosLaborales', 'fechaBaja', 'acciones'];

  displayedSearchHeaders: string[] = ['dni-buscador', 'nombre-buscador', 'apellidos-buscador', 'fechaAlta-buscador', 'fechaVencimientoBTP-buscador',
    'domicilio-buscador', 'poblacion-buscador', 'provincia-buscador', 'codigoPostal-buscador', 'mail-buscador', 'telefono-buscador', 'cuentaBancaria-buscador',
    'salarioFijo-buscador', 'retencion-buscador', 'porcentaje-buscador', 'puntuacion-buscador', 'valoracion-buscador', 'lopd-buscador', 'riesgosLaborales-buscador',
    'activo-buscador', 'acciones-buscador'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesGraficosComponent) detallesComponent;


  // -- Paginado
  length = this.conductores?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  @Input() nombreMenu;

  public dataSource: MatTableDataSource<IConductor>;
  listColumns;
  conductores?: IConductor[];
  listaProvincias: IProvincia[];
  conductorUpdate: IConductor;
  editar: Boolean = false;
  mostrarFormulario: Boolean = false;
  tipoDetalle: string = 'conductores';

  public searchForm: FormGroup;
  public vehiculo = '';
  public dni = '';
  public nombre = '';
  public apellidos = '';
  public fechaAlta = '';
  public fechaBTP = '';
  public domicilio = '';
  public poblacion = '';
  public provincia = '';
  public codigoPostal = '';
  public mail = '';
  public telefono = '';
  public cuentaBancaria = '';
  public salario_fijo = '';
  public retencion = '';
  public porcentaje = '';
  public puntuacion = '';
  public valoracion = '';
  isLopdActivo = true; //(boolean)
  isLopdInactivo = true; //(boolean)
  public isRiesgosLaboralesActivo = true; //(boolean)
  public isRiesgosLaboralesInactivo = true; //(boolean)
  isActivo = true;
  isInactivo = true;

  isLopd: boolean = null;
  isRiesgosLaborales: boolean = null;

  // -- Variables optimización
  public filters = [];
  public sorts = [];
  public totalElements = 0;

  constructor(
    private conductorService: ConductorService,
    private dialog: MatDialog,
    public _notificationService: NotificationService,
    private servicioGeneral: ServicioGeneral,
    private vehiculoService: VehiculoService,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.getAllProvincias();

    this.listColumns = {
      'DNI': true,
      'Nombre': true,
      'Apellidos': true,
      'Fecha alta': true,
      'Fecha BTP': true,
      'Domicilio': true,
      'Población': true,
      'Provincia': true,
      'Código postal': true,
      'Correo electrónico': true,
      'Teléfono': true,
      'Cuenta bancaria': true,
      'Retención': true,
      'Salario fijo': true,
      'Puntuación': true,
      'Valoración': true,
      'Porcentaje': true,
      'LOPD': true,
      'Riesgos laborales': true,
      'Activo': true,
    }


    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getAllConductor(request);
    this.searchFormInit();

  }

  generarInforme() {
    let nombre = 'Conductor';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  // Obtiene las propiedades de los objetos para poder filtrarlos en la tabla
  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  getAllProvincias() {
    this.servicioGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    )
  }

  async getAllConductor(request?) {
    await this.conductorService.getAllConductores(request).subscribe((data) => {
      this.conductores = []
      this.totalElements = 0;
      if (data['conductores']) {
        if (data['conductores']['content']) {
          this.conductores = data['conductores']['content'];
          /* for (const conductorJSON of data['conductores']['content']) {
            this.conductores.push(new IConductor(conductorJSON));
          } */
        }

        this.totalElements = data['conductores']['totalElements'];
      }
      this.dataSource = new MatTableDataSource(this.conductores);
    })
  }

  async getVehiculosByConductor(conductor) {

    await firstValueFrom(this.vehiculoService.getVehiculosByIdConductor(conductor.id)).then((res) => {
      conductor['vehiculos'] = res;
    });
  }

  setMostrarFormulario(mode: Boolean, conductor?: IConductor, tipo?: Boolean) {
    this.mostrarFormulario = mode;
    this.conductorUpdate = conductor;
    this.editar = tipo;

  }


  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getAllConductor(request);
    this.detallesComponent.getAllConductores();
  }

  openDialogMostrarConductor(conductor: IConductor): void {
    this.getVehiculosByConductor(conductor);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '80vw';
    dialogConfig.minHeight = '90vh';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.data = [conductor, this.listaProvincias];
    dialogConfig.disableClose = true;
    this.dialog.open(MostrarConductorDialog, dialogConfig)

  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogDesactivarConductor(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';

    const dialogRef = this.dialog.open(ModalBajaComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result != '') {
        this.deleteConductor(id, result.result);
      }
    })
  }

  deleteConductor(id: number, fecha: Date): void {
    this.conductorService.delete(id, fecha).subscribe({
      next: (data) => {

        let request = {
          "filters": this.filters,
          "sorts": this.sorts,
          page: this.paginator.pageIndex,
          size: this.paginator.pageSize,
        }

        this.getAllConductor(request);
        this._notificationService.success("Operación realizada con éxito", "El conductor ha sido desactivado", 3000)
        this.detallesComponent.getAllConductores();

      }
    });
  }

  // -- Inicio optimización

  searchFormInit() {
    this.searchForm = new FormGroup({
      vehiculo: new FormControl(''),
      dni: new FormControl(''),
      nombre: new FormControl(''),
      apellidos: new FormControl(''),
      fechaAlta: new FormControl(''),
      fechaBTP: new FormControl(''),
      domicilio: new FormControl(''),
      poblacion: new FormControl(''),
      provincia: new FormControl(''),
      codigoPostal: new FormControl(''),
      mail: new FormControl(''),
      telefono: new FormControl(''),
      cuentaBancaria: new FormControl(''),
      salario_fijo: new FormControl(''),
      retencion: new FormControl(''),
      puntuacion: new FormControl(''),
      porcentaje: new FormControl(''),
      valoracion: new FormControl(''),
    });
  }

  async applyFilter(event?, key?, type?) {

    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];
    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event.target.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {


    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type.toUpperCase() == "BOOLEAN") {
        filter.operator = "EQUAL"
        this.setFilterBoolean(filter);
      }

      if (type.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == "DATE_BETWEEN" || type.toUpperCase() == "LOCALDATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == "ISNULLDATE") {
        this.isNullFechaBaja(filter);
      }

      if (type.toUpperCase() == "DOUBLE" || type.toUpperCase() == "LONG" || type.toUpperCase() == "BIG_DECIMAL") {
        filter.operator = "LIKE"
        filter.field_type = "STRING"
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
            if (type.toUpperCase() == "ISNULLDATE") {
              this.isNullFechaBaja(filter);
            } else if (type.toUpperCase() == "BOOLEAN") {
              this.setFilterBoolean(filter);
            }
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,

    }

    this.paginator.firstPage();
    await this.getAllConductor(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    await this.getAllConductor(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }
      }
      this.sorts = [{
        "key": sort.active,
        "direction": direction
      }];
      request['sorts'] = this.sorts;
    }

    await this.getAllConductor(request)
  }

  setFilterBoolean(filter) {

    if (filter.key == 'lopd') {

      if (!this.isLopdActivo || !this.isLopdInactivo) {
        filter.value = this.isLopd;
      } else {
        let index = this.filters.findIndex(x => x.key === "lopd");
        this.filters.splice(index, 1);
      }

    } else if (filter.key == 'riesgosLaborales') {
      if (!this.isRiesgosLaboralesActivo || !this.isRiesgosLaboralesInactivo) {
        filter.value = this.isRiesgosLaborales;
      } else {
        let index = this.filters.findIndex(x => x.key === "riesgosLaborales");
        this.filters.splice(index, 1);
      }
    }
  }

  isNullFechaBaja(filter) {

    filter.field_type = "DATE";
    if (
      (this.isActivo == true && this.isInactivo == false) ||
      (this.isActivo == false && this.isInactivo == true)
    ) {
      if (this.isActivo == true) {
        filter.operator = "IS_NULL";
        filter.value = null;
      }

      if (this.isInactivo == true) {
        filter.operator = "IS_NOT_NULL";
      }
    } else if (this.isActivo == false && this.isInactivo == false) {
      filter.operator = "IS_NULL";
    } else if (this.isActivo == true && this.isInactivo == true) {
      filter.operator = "IS_NULL";
    }
  }

  //  -- CheckBox
  setLopdActivoInactivo(event, activoInactivo) {

    if (activoInactivo == 'activo') {
      this.isLopdActivo = event.target.checked
      this.isLopd = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isLopdInactivo = event.target.checked
      this.isLopd = true;
    }
    this.applyFilter(event, "lopd", "BOOLEAN");
  }

  setRiesgosLaboralesActivo(event, activoInactivo) {

    if (activoInactivo == 'activo') {
      this.isRiesgosLaboralesActivo = event.target.checked
      this.isRiesgosLaborales = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isRiesgosLaboralesInactivo = event.target.checked
      this.isRiesgosLaborales = true;
    }

    this.applyFilter(event, "riesgosLaborales", "BOOLEAN");
  }

  setActivoInactivo(event, activoInactivo) {
    if (activoInactivo == "activo") {
      this.isActivo = event.target.checked;
    }
    if (activoInactivo == "inactivo") {
      this.isInactivo = event.target.checked;
    }
    this.applyFilter(event, "fechaBaja", "ISNULLDATE");
  }

  // -- Fin optimización


  // -- Descarga prueba paula
  isDownloadFiles: Boolean = false;
  downloadFiles(idConductor: number) {
    this.isDownloadFiles = true;

    this.conductorService.getDocuments(idConductor).subscribe({
      next: (data) => {
        this.crearZIPyDescargar(data).finally(() => {
          this.isDownloadFiles = false;
        });
      }
    })
  }

  async crearZIPyDescargar(data) {
    const zip = new JSZip();

    data['documentos'].forEach((document) => {
      zip.file(`${document['nombre']}`, document['base64'], { base64: true });
    });

    const content = await zip.generateAsync({ type: 'blob' });
    const zipURL = URL.createObjectURL(content);
    const a = document.createElement('a');

    a.href = zipURL;
    a.download = `documentos_${data['conductor']['nombre']}_${data['conductor']['apellidos']}.zip`;
    a.click();

    URL.revokeObjectURL(zipURL);
  }

  // -- Exportación a excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { createdDate, lastModifiedDate, createdBy, lastModifiedBy, id, riesgosLaborales, vehiculos, observaciones, tipo_documento, ...rest } = item;

    if (item.telefono) {
      rest.telefono = item.telefono.join(', ');
    }

    if (item.provincia) {
      rest.provincia = item.provincia.ccaa.nombre;
    }

    if (item.mail) {
      rest.mail = item.mail.join(', ');
    }

    if (item.cuentaBancaria) {
      rest.cuentaBancaria = item.cuentaBancaria.join(', ');
    }

    return rest;
  }

  generateExcel() {
    const data = this.dataSource.data.map((conductor) => {
      const formattedConductor = { ...conductor };
      return this.filterFields(formattedConductor);
    });

    const fileName = 'Tabla_conductores';
    this._excelService.exportToExcel(data, fileName);
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
//Mostrar y editar un conductor

@Component({
  selector: "mostrar-conductor-dialog",
  templateUrl: "../mostrar-conductor/mostrar-conductor-dialog.html",
  styleUrls: ['../mostrar-conductor/mostrar-conductor-dialog.css']
})

export class MostrarConductorDialog implements OnInit {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";
  REGEX_IBAN = "(^ES\s?\d{2}\s?\d{4}\s?\d{4}\s?\d{2}\s?\d{10}$)";

  constructor(public dialogRef: MatDialogRef<MostrarConductorDialog>,
    public dialog: MatDialog,
    protected conductorService: ConductorService,
    public servicioGeneral: ServicioGeneral,
    private router: Router,
    public _notificationService: NotificationService,
    private vehiculoService: VehiculoService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],

  ) { }

  ngOnInit(): void {
    this.getAllVehiculos();

    if (this.form.value.fechaBaja != null) {
      for (const key in this.form.controls) {
        this.form.controls[key].disable();
      }
    }

  }

  listaVehiculos
  getAllVehiculos() {
    this.vehiculoService.getAllVehiculo().subscribe((res) => {
      this.listaVehiculos = res['vehiculos'];
    });
  }

  listaVehiculosByConductor;
  getVehiculosByConductor(idCondcutor) {
    this.vehiculoService.getVehiculosByIdConductor(idCondcutor).subscribe((res) => {
      this.listaVehiculosByConductor = res;
    });
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }

  conductor = this.dialogData[0];
  listaProvincias = this.dialogData[1];
  conductorUpdate: IConductor;
  isSubmitted: Boolean = false;
  TProvincias: any = [];
  expandedPhone: number = 1;
  expandedEmail: number = 1;
  expandedIban: number = 1;
  expandedVehiculo: number = 1;

  form = new FormGroup({
    fechaVencimientoBTP: new FormControl(this.convertDate(this.conductor.fechaVencimientoBTP, false)),
    vehiculo: this.camposVehiculo(),
    fechaAlta: new FormControl(this.convertDate(this.conductor.fechaAlta, false)),
    fechaBaja: new FormControl(this.convertDate(this.conductor.fechaBaja, false)),
    dni: new FormControl(this.conductor.dni, [Validators.required, Validators.pattern("^[0-9]{8}[A-Z]$")]),
    nombre: new FormControl(this.conductor.nombre, Validators.required),
    apellidos: new FormControl(this.conductor.apellidos),
    domicilio: new FormControl(this.conductor.domicilio),
    provincia: new FormControl(this.conductor.provincia?.id ? this.conductor.provincia.id : 0),
    poblacion: new FormControl(this.conductor.poblacion),
    codigoPostal: new FormControl(this.conductor.codigoPostal, [Validators.pattern("[0-9]{5}")]),
    mail: this.camposEmail(),
    telefono: this.camposTelefono(),
    cuentaBancaria: this.camposCuentaBancaria(),
    retencion: new FormControl(this.conductor.retencion),
    valoracion: new FormControl(this.conductor.valoracion),
    puntuacion: new FormControl(this.conductor.puntuacion),
    lopd: new FormControl(this.conductor.lopd,),
    riesgosLaborales: new FormControl(this.conductor.riesgosLaborales),
    observaciones: new FormControl(this.conductor.observaciones),
  }, { updateOn: 'change' });

  // Si el usuario tiene varios telefonos o emals, los muestra y permite así eliminarlos o añadir más
  camposTelefono() {
    let telefonosConductor = this.conductor.telefono;
    let telefono = new FormArray([new FormControl(telefonosConductor[0], Validators.pattern("[0-9]{9}"))])
    for (let i = 1; i < telefonosConductor.length; i++) {
      telefono.push(new FormControl(telefonosConductor[i].toString(), Validators.pattern("[0-9]{9}")));
      this.expandedPhone++;
    }
    return telefono;
  }

  addTelefono() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl("", Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  camposVehiculo() {
    let vehiculosConductor = this.conductor.vehiculos;
    let vehiculo;
    if (vehiculosConductor.length == 0) {
      vehiculo = new FormArray([new FormControl(null)])
    } else {
      vehiculo = new FormArray([new FormControl(vehiculosConductor[0].id)])
    }
    for (let i = 1; i < vehiculosConductor.length; i++) {
      vehiculo.push(new FormControl(vehiculosConductor[i].id));
      this.expandedVehiculo++;
    }
    return vehiculo;
  }

  addVehiculo() {
    let vehiculos = this.form.controls.vehiculo as FormArray;
    vehiculos.push(
      new FormControl(null),
    )
    this.expandedVehiculo++;
  }
  get vehiculo() {
    return this.form.get("vehiculo") as FormArray;
  }

  removeVehiculo(i: number) {
    this.vehiculo.removeAt(i);
    this.expandedVehiculo--;
  }

  camposEmail() {
    let emailsConductor = this.conductor.mail;
    let email = new FormArray([new FormControl(emailsConductor[0], Validators.pattern(this.REGEX_EMAIL))])
    for (let i = 1; i < emailsConductor.length; i++) {
      email.push(new FormControl(emailsConductor[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
      this.expandedPhone++;
    }
    return email;
  }


  addEmail() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl("", Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get cuentasBancarias() {
    return this.form.get("cuentaBancaria") as FormArray;
  }

  removeCuentaBancaria(i: number) {
    this.cuentasBancarias.removeAt(i);
    this.expandedIban--;
    this.form.markAsDirty();
  }

  camposCuentaBancaria() {
    let cuentasBancariasConductor = this.conductor.cuentaBancaria;
    let cuentaBancaria = new FormArray([new FormControl(cuentasBancariasConductor[0])])
    for (let i = 1; i < cuentasBancariasConductor.length; i++) {
      cuentaBancaria.push(new FormControl(cuentasBancariasConductor[i].toString()));
      this.expandedPhone++;
    }
    return cuentaBancaria;
  }

  addCuentaBancaria() {
    let cuentaBancaria = this.form.controls.cuentaBancaria as FormArray;
    cuentaBancaria.push(
      new FormControl("", Validators.pattern(this.REGEX_IBAN)),
    )
    this.expandedIban++;
  }

  get emails() {
    return this.form.get("mail") as FormArray;
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    let vehiculoToSave: IVehiculo[] = [];
    for (const vehiculo of this.listaVehiculos) {
      for (const numero of this.form.value.vehiculo) {
        if (vehiculo.id == numero) {
          let vehiculoObject = new IVehiculo(vehiculo)
          vehiculoObject.licencia.propietario.fechaModificacion = null;
          vehiculoToSave.push(vehiculoObject);

        }
      }
    }

    this.conductorUpdate = {
      id: this.conductor.id,
      dni: this.form.value.dni,
      nombre: this.form.value.nombre,
      apellidos: this.form.value.apellidos,
      fechaVencimientoBTP: this.form.value.fechaVencimientoBTP != "" ? this.convertDate(this.form.value.fechaVencimientoBTP, true) : null,
      domicilio: this.form.value.domicilio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      poblacion: this.form.value.poblacion,
      codigoPostal: this.form.value.codigoPostal,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      mail: this.form.value.mail?.filter(mail => mail != "" && mail != null),
      cuentaBancaria: this.form.value.cuentaBancaria?.filter(cuentaBancaria => cuentaBancaria != "" && cuentaBancaria != null),
      retencion: this.form.value.retencion,
      valoracion: this.form.value.valoracion,
      puntuacion: this.form.value.puntuacion,
      lopd: this.form.value.lopd,
      riesgosLaborales: this.form.value.riesgosLaborales,
      observaciones: this.form.value.observaciones
    }

    if (this.conductor.fechaBaja != null) {
      this.conductorUpdate.fechaBaja = null;
    }

    this.conductorUpdate.vehiculos = vehiculoToSave;
    let conductorObject = new IConductor(this.conductorUpdate)
    this.conductorService.updateOnlyConductor(conductorObject).subscribe({
      next: (data) => {
        if (data.body['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
          this.isSubmitted = false;
          this.dialogRef.close()
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data.body['message'];
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);

        }
      }
    });
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 8 } });
  }

  convertDate(date: string, modo: Boolean): string {

    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Añádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
//Borrar conductor

@Component({
  selector: "borrar-conductor-dialog",
  templateUrl: "../borrar-conductor/borrar-conductor-dialog.html",
  styleUrls: ['../borrar-conductor/borrar-conductor-dialog.css']
})

export class BorrarConductorDialog {

  constructor(public dialogRef: MatDialogRef<BorrarConductorDialog>,
    public dialog: MatDialog,
  ) { }

  confirm() {
    this.dialogRef.close({ result: 'Confirmar' });
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
//Confirmar Update 

@Component({
  selector: "confirmarUpdate-dialog",
  templateUrl: "../mostrar-conductor/confirmarUpdate-dialog.html",
  styleUrls: ['../mostrar-conductor/mostrar-conductor-dialog.css']
})

export class ConfirmarUpdateDialog {

  constructor(public dialogRef: MatDialogRef<ConfirmarUpdateDialog>,
    public dialog: MatDialog,
  ) { }

  confirm() {
    this.dialogRef.close({ result: 'Confirmar' });
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
// Confirmar Unsaved

@Component({
  selector: "confirmarUnsaved-dialog",
  templateUrl: "../mostrar-conductor/confirmarUnsaved-dialog.html",
  styleUrls: ['../mostrar-conductor/mostrar-conductor-dialog.css']
})

export class ConfirmarUnsavedDialog {

  constructor(public dialogRef: MatDialogRef<ConfirmarUnsavedDialog>,
    public dialog: MatDialog,
  ) { }

  discard() {
    this.dialogRef.close({ result: 'descartar' });
  }

}
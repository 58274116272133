<div class="crear-editar-form">
    <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crea un nuevo gasto a proveedor</h1>
    <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita un gasto a proveedor</h1>

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="mw-100 mt-2">
        <div class="row">
            <div class="col">

                <!-- ------------------------------- Campos ----------------------- -->
                <div *ngFor="let gasto of gastosList; let i = index">
                    <div class="row">
                        <!-- ------------------------------- Campo Fecha ----------------------- -->
                        <div class="form-group col-sm-2">
                            <label>Fecha</label>
                            <input [disabled]="!isUserAdmin" *ngIf="!this.data.isForUpdate" type="date"
                                class="form-control" [(ngModel)]="gasto.fechaAlta" #name="ngModel"
                                name="fechaAlta{{i}}">

                            <input [disabled]="!isUserAdmin" *ngIf="this.data.isForUpdate" type="date"
                                class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'"
                                (change)="getValue($event)">
                        </div>

                        <!-- ------------------------------- Campo Licencia ----------------------- -->
                        <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                            <div>Licencia<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="licencia"
                                [(ngModel)]="gasto.licencia" #name="ngModel" name="licencia{{i}}"
                                (change)="getConductoresByLicencia(gasto)">
                                <option value=null>Selecciona una licencia</option>
                                <option *ngFor="let licencia of licencias" [ngValue]="licencia">
                                    {{licencia.numero_licencia}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                            <div>Licencia<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="licencia{{i}}"
                                [(ngModel)]="selectedValueLicencia" #name="ngModel"
                                (change)="getConductoresByLicencia(gasto)">
                                <option *ngFor="let licencia of licencias" [value]="licencia.id">
                                    {{licencia.numero_licencia}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Conductor ----------------------- -->
                        <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                            <div>Conductor</div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="conductor"
                                [(ngModel)]="gasto.conductor" #name="ngModel" name="conductor{{i}}">
                                <option value=null>Selecciona un conductor</option>
                                <option *ngFor="let conductor of gasto.posiblesConductores" [ngValue]="conductor">
                                    {{conductor.nombre}} {{conductor.apellidos}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                            <div>Conductor</div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control"
                                [(ngModel)]="selectedValueConductor">
                                <option value=null>Selecciona un conductor</option>
                                <option *ngFor="let conductor of gasto.posiblesConductores" [value]="conductor.id">
                                    {{conductor.nombre}} {{conductor.apellidos}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Importe ----------------------- -->
                        <div class="form-group col-sm">
                            <div>Importe<span style="color: red;">*</span></div>
                            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                                [(ngModel)]="gasto.importe" #name="ngModel" name="importe{{i}}">
                        </div>

                        <!-- ------------------------------- Campo Proveedor ----------------------- -->
                        <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                            <div>Proveedor<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="proveedor"
                                [(ngModel)]="gasto.proveedor" #name="ngModel" name="proveedor{{i}}"
                                (change)="getConceptosByProveedor(gasto)">
                                <option value=null>Selecciona un proveedor</option>
                                <option *ngFor="let proveedor of proveedores" [ngValue]="proveedor">
                                    {{proveedor.nombre}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                            <div>Proveedor<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="proveedor{{i}}"
                                [(ngModel)]="selectedValueProveedor" #name="ngModel"
                                (change)="getConceptosByProveedor(gasto)">
                                <option *ngFor="let proveedor of proveedores" [value]="proveedor.id">
                                    {{proveedor.nombre}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Concepto ----------------------- -->
                        <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                            <div>Concepto<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control" name="concepto"
                                [(ngModel)]="gasto.concepto" #name="ngModel" name="concepto{{i}}">
                                <option value=null>Selecciona un concepto</option>
                                <option *ngFor="let concepto of gasto.posiblesConceptos" [ngValue]="concepto">
                                    {{concepto.concepto}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                            <div>Concepto<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control"
                                [(ngModel)]="selectedValueConcepto" (change)="getValueIva()">
                                <option *ngFor="let concepto of gasto.posiblesConceptos" [value]="concepto.id">
                                    {{concepto.concepto}}
                                </option>
                            </select>
                        </div>


                        <!-- ------------------------------- Campo iva ----------------------- -->
                        <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                            <div>IVA</div>
                            <input disabled type="number" class="form-control" placeholder="{{(gasto.concepto?.iva / 100)  * gasto.importe ?? 0}}">
                        </div>

                        <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                            <div>IVA</div>
                            <input disabled type="number" class="form-control" placeholder="{{iva}}">
                        </div>

                    </div>

                    <div class="row">

                        <!-- ------------------------------- Campo tipo cantidad (porcentaje o cantidad) ----------------------- -->
                        <div class="form-group col-sm">
                            <div>Tipo cantidad<span style="color: red;">*</span></div>
                            <select [disabled]="!isUserAdmin" class="form-select form-control"
                                [(ngModel)]="gasto.tipo_cantidad">
                                <option value="porcentaje">
                                    Porcentaje
                                </option>
                                <option value="cantidad">
                                    Cantidad
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Cantidad conductor ----------------------- -->
                        <div class="form-group col-sm">
                            <div>Cantidad conductor<span style="color: red;">*</span></div>
                            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                                [(ngModel)]="gasto.cantidad_conductor" #name="ngModel" name="importe{{i}}">
                        </div>

                        <!-- ------------------------------- Campo Cantidad propietario----------------------- -->
                        <div class="form-group col-sm">
                            <div>Cantidad propietario<span style="color: red;">*</span></div>
                            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                                [(ngModel)]="gasto.cantidad_propietario" #name="ngModel" name="importe{{i}}">
                            <!-- <input [disabled]="!isUserAdmin" mask="0*.00" type="text" class="form-control"
                                [(ngModel)]="gasto.cantidad_propietario" #name="ngModel" name="importe{{i}}"> -->
                        </div>

                        <!-- ------------------------------- Campo numFactura ----------------------- -->
                        <div class="form-group col-sm">
                            <div>Nº factura</div>
                            <input [disabled]="!isUserAdmin" type="text" class="form-control"
                                [(ngModel)]="gasto.numFactura" #name="ngModel" name="numFactura{{i}}">
                        </div>

                        <!--<div class=".col-xxl col-add-delete" *ngIf="!this.data.isForUpdate">
                        <button class="btn" (click)="addGasto()" [disabled]="!isUserAdmin"><mat-icon
                                class="align-middle mr-2">add</mat-icon></button>
                        <button class="btn btn-delete"
                            [disabled]="gastosList.length > 1 && isUserAdmin ? '' : 'disabled'"
                            (click)="removeGasto(i)"><mat-icon class="align-middle mr-2">delete</mat-icon></button>
                        </div>-->
                    </div>

                    <div class="row justify-content-start">
                        <div class="form-group col-sm-auto mb-4">
                            <button *ngIf="!data.isForUpdate" type="button" [disabled]="i==0 || !isUserAdmin"
                                class="btn btn-outline-primary btn-sm d-flex flex-row align-items-center"
                                (click)="removeGasto(i)"><mat-icon>delete</mat-icon>Eliminar</button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="row justify-content-start">
                    <div class="form-group col-sm-auto">
                        <button type="button" [disabled]="!isUserAdmin" *ngIf="!data.isForUpdate"
                            class="btn btn-primary btn-sm d-flex flex-row align-items-center"
                            (click)="addGasto()"><mat-icon>add</mat-icon>Añadir
                            gasto</button>
                    </div>
                </div>


                <!-- ------------------------------- Botones ----------------------- -->
                <div id="container" class="d-flex justify-content-end pt-3">
                    <button *ngIf="isUserAdmin" class="btn btn-success mx-3" type="submit"
                        (click)="openDialogConfirm()">Guardar</button>
                    <!-- <button class="btn btn-success mx-3">Guardar</button> -->
                    <button class="btn btn-secondary" type="button" (click)="confirmCancelOperation()">Cerrar</button>
                </div>

            </div>

        </div>
    </div>
</div>
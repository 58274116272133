<div class="crear-editar-form">
    <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crea un nuevo {{data.tipo.toLowerCase()}}</h1>
    <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita un {{data.tipo.toLowerCase()}}</h1>

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="mw-100 mt-2">
        <div class="row">
            <div class="col">

                <!-- ------------------------------- Campos ----------------------- -->
                <div *ngFor="let gasto of gastosList; let i = index">

                    <div class="row">
                        <!-- ------------------------------- Campo Fecha ----------------------- -->
                        <div class="form-group col-sm">
                            <label>Fecha</label>
                            <input [disabled]="!isUserAdmin" *ngIf="!this.data.isForUpdate" type="date"
                                class="form-control" [(ngModel)]="gasto.fechaAlta" #name="ngModel"
                                name="fechaAlta{{i}}">

                            <input [disabled]="!isUserAdmin" *ngIf="this.data.isForUpdate" type="date"
                                class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'"
                                (change)="getValue($event)">
                        </div>

                        <!-- ------------------------------- Campo Licencia ----------------------- -->
                        <div class="form-group col" *ngIf="!this.data.isForUpdate">
                            <label>Licencia<span style="color: red;">*</span></label>
                            <select [disabled]="!isUserAdmin" class="form-control form-select" name="licencia"
                                [(ngModel)]="gasto.licencia" #name="ngModel" name="licencia{{i}}"
                                (change)="getConductoresByLicencia(gasto)">
                                <option value=null>Selecciona una licencia</option>
                                <option *ngFor="let licencia of licencias" [ngValue]="licencia">
                                    {{licencia.numero_licencia}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col" *ngIf="this.data.isForUpdate">
                            <label>Licencia<span style="color: red;">*</span></label>
                            <select [disabled]="!isUserAdmin" class="form-control form-select" name="licencia{{i}}"
                                [(ngModel)]="selectedValueLicencia" #name="ngModel"
                                (change)="getConductoresByLicencia(gasto)">
                                <option *ngFor="let licencia of licencias" [value]="licencia.id">
                                    {{licencia.numero_licencia}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Conductor ----------------------- -->
                        <div class="form-group col" *ngIf="!this.data.isForUpdate">
                            <label>Conductor</label>
                            <select [disabled]="!isUserAdmin" class="form-control form-select" name="conductor"
                                [(ngModel)]="gasto.conductor" #name="ngModel" name="conductor{{i}}">
                                <option value=null>No asignar a conductor</option>
                                <option *ngFor="let conductor of gasto.posiblesConductores" [ngValue]="conductor">
                                    {{conductor.nombre}} {{conductor.apellidos}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col" *ngIf="this.data.isForUpdate">
                            <label>Conductor</label>
                            <select [disabled]="!isUserAdmin" class="form-control form-select"
                                [(ngModel)]="selectedValueConductor">
                                <option value=null>No asignar a conductor</option>
                                <option *ngFor="let conductor of gasto.posiblesConductores" [value]="conductor.id">
                                    {{conductor.nombre}} {{conductor.apellidos}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo Importe ----------------------- -->
                        <div class="form-group col">
                            <label>Importe<span style="color: red;">*</span></label>
                            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                                [(ngModel)]="gasto.importe" #name="ngModel" name="importe{{i}}">
                        </div>

                        <!-- ------------------------------- Campo Concepto ----------------------- -->
                        <div class="form-group col">
                            <label>Concepto</label>
                            <input [disabled]="!isUserAdmin" type="text" class="form-control"
                                [(ngModel)]="gasto.concepto" #name="ngModel" name="concepto{{i}}">
                        </div>

                        <!-- ------------------------------- Campo tipo en el caso de guardar ----------------------- -->
                        <div class="form-group col" *ngIf="!this.data.isForUpdate">
                            <label>Tipo</label>
                            <select [disabled]="!isUserAdmin" *ngIf="data.tipo.toLowerCase() == 'gasto'"
                                class="form-control form-select" name="tipoGasto" [(ngModel)]="gasto.tipo_gasto"
                                #name="ngModel" name="tipo_gasto{{i}}">
                                <option value=null>Selecciona un tipo</option>
                                <option *ngFor="let tipoGasto of listTipoGasto" [ngValue]="tipoGasto">
                                    {{tipoGasto.nombre}}
                                </option>
                            </select>

                            <select [disabled]="!isUserAdmin" *ngIf="data.tipo.toLowerCase() == 'ingreso'"
                                class="form-control form-select" name="tipoIngreso" [(ngModel)]="gasto.tipo_ingreso"
                                #name="ngModel" name="tipo_ingreso{{i}}">
                                <option value=null>Selecciona un tipo</option>
                                <option *ngFor="let tipoIngreso of listTipoIngreso" [ngValue]="tipoIngreso">
                                    {{tipoIngreso.nombre}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo tipo en el caso de actualizar ----------------------- -->
                        <div class="form-group col" *ngIf="this.data.isForUpdate">
                            <label>Tipo</label>
                            <select [disabled]="!isUserAdmin" *ngIf="data.tipo.toLowerCase() == 'gasto'"
                                class="form-control form-select" [(ngModel)]="tipoGastoSelected"
                                [disabled]="!isUserAdmin">
                                <option value=null>Selecciona un tipo</option>
                                <option *ngFor="let tipoGasto of listTipoGasto" [value]="tipoGasto.id">
                                    {{tipoGasto.nombre}}
                                </option>
                            </select>

                            <select [disabled]="!isUserAdmin" *ngIf="data.tipo.toLowerCase() == 'ingreso'"
                                class="form-control form-select" [(ngModel)]="tipoIngresoSelected"
                                [disabled]="!isUserAdmin">
                                <option value=null>Selecciona un tipo</option>
                                <option *ngFor="let tipoIngreso of listTipoIngreso" [ngValue]="tipoIngreso.id">
                                    {{tipoIngreso.nombre}}
                                </option>
                            </select>
                        </div>

                        <!-- ------------------------------- Campo numDocumento ----------------------- -->
                        <!-- <div class="form-group col">
                            <label>Nº factura</label>
                            <input [disabled]="!isUserAdmin" type="text" class="form-control"
                                [(ngModel)]="gasto.numDocumento" #name="ngModel" name="numDocumento{{i}}">
                        </div> -->

                    </div>

                    <div class="row">
                        <!-- ------------------------------- Campo cantidad conductor ----------------------- -->
                        <div class="form-group col-sm">
                            <div>Cantidad conductor</div>
                            <input [disabled]="!isUserAdmin || gasto.importe == null" type="number" class="form-control"
                                [(ngModel)]="gasto.cantidadConductor" #name="ngModel" name="importe{{i}}"
                                (input)="calcularImportePropietario(i, $event)">
                        </div>

                        <!-- ------------------------------- Campo Cantidad propietario----------------------- -->
                        <div class="form-group col-sm">
                            <div>Cantidad propietario</div>
                            <input [disabled]="!isUserAdmin || gasto.importe == null" type="number" class="form-control"
                                [(ngModel)]="gasto.cantidadPropietario" #name="ngModel" name="importe{{i}}"
                                (input)="calcularImporteConductor(i, $event)">
                        </div>

                        <!-- ------------------------------- Campo numFactura ----------------------- -->
                        <div class="form-group col">
                            <label>Nº factura</label>
                            <input [disabled]="!isUserAdmin" type="text" class="form-control"
                                [(ngModel)]="gasto.numDocumento" #name="ngModel" name="numDocumento{{i}}">
                        </div>
                    </div>




                    <div class="row justify-content-start">
                        <div class="form-group col-sm-auto mb-4">
                            <button *ngIf="!data.isForUpdate" type="button" [disabled]="i==0 || !isUserAdmin"
                                class="btn btn-outline-primary btn-sm d-flex flex-row align-items-center"
                                (click)="removeGastoIngreso(i)"><mat-icon>delete</mat-icon>Eliminar</button>
                        </div>
                    </div>

                </div>
                <hr class="mt-0">
                <div class="row justify-content-start">
                    <div class="form-group col-sm-auto">
                        <button *ngIf="!data.isForUpdate" type="button" [disabled]="!isUserAdmin"
                            class="btn btn-primary btn-sm d-flex flex-row align-items-center"
                            (click)="addGastoIngreso()"><mat-icon>add</mat-icon>Añadir
                            {{data.tipo.toLowerCase()}}</button>
                    </div>
                </div>


                <!-- ------------------------------- Botones ----------------------- -->
                <div id="container" class="d-flex justify-content-end pt-3">
                    <button *ngIf="isUserAdmin" class="btn btn-success" type="submit"
                        (click)="openDialogConfirm()">Guardar</button>
                    <!-- <button class="btn btn-success mx-3">Guardar</button> -->
                    <button class="btn btn-secondary ml-3" type="button"
                        (click)="confirmCancelOperation()">Cerrar</button>
                </div>
            </div>
        </div>
    </div>

</div>
import { Component} from "@angular/core";

@Component({
    selector: 'collapse-informacion-component',
    templateUrl: './collapseInformacion.component.html',
    styleUrls: ['./mostrar-conductor-dialog.css'],
  })
  
export class CollapseInformacionComponent{


}
<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Gestión de vehículos</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle" >Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle"
        >Generar
        informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)" [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo vehículo</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-graficos [tipoDetalle]="tipoDetalle"></app-card-detalles-graficos>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">
  <div class="mostrarFormulario" *ngIf="mostrarFormulario">
    <app-crear-actualizar-vehiculo (mostrarFormulario)="setMostrarFormulario($event)" [vehiculoUpdate]="vehiculoUpdate"
      [editar]="editar"></app-crear-actualizar-vehiculo>
  </div>

  <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
      <app-multi-select-dropdown [listColumns]="listColumns">
      </app-multi-select-dropdown>
    </div>

    <div class="table_container" [hidden]="mostrarFormulario">

      <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
        (matSortChange)="sortData($event)">

        <!-- Columna Licencia -->
        <ng-container matColumnDef="licencia_buscador" sticky>
          <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Licencia" formControlName="licencia"
                    (keyup)="applyFilter($event,'licencia.numero_licencia', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="licencia.numero_licencia" sticky>
          <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by licencia" class="header">
            Licencia
          </th>
          <td [hidden]="!listColumns['Licencia']" mat-cell *matCellDef="let element">
            {{element.licencia?.numero_licencia}}</td>
        </ng-container>

        <!-- Columna Matrícula -->
        <ng-container matColumnDef="matricula_buscador">
          <th [hidden]="!listColumns['Matrícula']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Matrícula" formControlName="matricula"
                    (keyup)="applyFilter($event,'matricula', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="matricula">
          <th [hidden]="!listColumns['Matrícula']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">
            Matrícula
          </th>
          <td [hidden]="!listColumns['Matrícula']" mat-cell *matCellDef="let element">
            {{element.matricula.toUpperCase()}}</td>
        </ng-container>

        <!-- Columna Libranza -->
        <ng-container matColumnDef="libranza_buscador">
          <th [hidden]="!listColumns['Libranza']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Libranza" formControlName="libranza"
                    (keyup)="applyFilter($event,'licencia.dia_libranza', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="licencia.dia_libranza">
          <th [hidden]="!listColumns['Libranza']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by libranza" class="header">
            Libranza
          </th>
          <td [hidden]="!listColumns['Libranza']" mat-cell *matCellDef="let element">
            {{element.licencia?.dia_libranza}}</td>
        </ng-container>

        <!-- Columna Marca -->
        <ng-container matColumnDef="marca_buscador">
          <th [hidden]="!listColumns['Marca']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Marca" formControlName="marca"
                    (keyup)="applyFilter($event,'modelo.marca.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="modelo.marca.nombre">
          <th [hidden]="!listColumns['Marca']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by marca" class="header">
            Marca
          </th>
          <td [hidden]="!listColumns['Marca']" mat-cell *matCellDef="let element">
            {{element.modelo?.marca?.nombre}}</td>
        </ng-container>

        <!-- Columna Modelo -->
        <ng-container matColumnDef="modelo_buscador">
          <th [hidden]="!listColumns['Modelo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Modelo" formControlName="modelo" (keyup)="applyFilter($event,'modelo.nombre', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="modelo.nombre">
          <th [hidden]="!listColumns['Modelo']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">
            Modelo
          </th>
          <td [hidden]="!listColumns['Modelo']" mat-cell *matCellDef="let element">{{element.modelo?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Nombre Compañía de Seguros -->
        <ng-container matColumnDef="ciaSeguros_buscador">
          <th [hidden]="!listColumns['Compañía de seguros']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Compañía de Seguros" formControlName="ciaSeguros"
                    (keyup)="applyFilter($event,'seguro.ciaSeguros.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="seguro.ciaSeguros.nombre">
          <th [hidden]="!listColumns['Compañía de seguros']" mat-header-cell *matHeaderCellDef mat-sort-header
            class="header">
            Compañía de Seguros
          </th>
          <td [hidden]="!listColumns['Compañía de seguros']" mat-cell *matCellDef="let element">
            {{element.seguro?.ciaSeguros?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Vencimiento Seguro -->
        <ng-container matColumnDef="vencimientoSeguro_buscador">
          <th [hidden]="!listColumns['Fecha vencimiento']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Vencimiento Seguro" formControlName="fechaVencimiento"
                    (keyup)="applyFilter($event,'seguro.fechaVencimiento', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="seguro.fechaVencimiento">
          <th [hidden]="!listColumns['Fecha vencimiento']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaVencimiento" class="header">
            Fecha de Vencimiento
          </th>
          <td [hidden]="!listColumns['Fecha vencimiento']" mat-cell *matCellDef="let element">
            {{element.seguro?.fechaVencimiento}}</td>
        </ng-container>

        <!-- Columna Teléfono Compañía de Seguros -->
        <ng-container matColumnDef="telefonoSeguro_buscador">
          <th [hidden]="!listColumns['Tlf contacto']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Teléfono Seguro" formControlName="tlfContacto"
                    (keyup)="applyFilter($event,'seguro.ciaSeguros.telefono', 'CONTAINS_STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="seguro.ciaSeguros.telefono">
          <th [hidden]="!listColumns['Tlf contacto']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by tlfContacto" class="header">
            Tlf. de contacto
          </th>
          <td [hidden]="!listColumns['Tlf contacto']" mat-cell *matCellDef="let element">
            {{element.seguro?.ciaSeguros?.telefono}}
          </td>
        </ng-container>

        <!-- Columna Mutua Patronal -->
        <ng-container matColumnDef="mutua_buscador">
          <th [hidden]="!listColumns['Mutua padronal']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Mutua Patronal" formControlName="mutuaPadronal"
                    (keyup)="applyFilter($event,'seguro.mutuaPadronal', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="seguro.mutuaPadronal">
          <th [hidden]="!listColumns['Mutua padronal']" mat-header-cell *matHeaderCellDef mat-sort-header
            class="header">
            Mutua Patronal
          </th>
          <td [hidden]="!listColumns['Mutua padronal']" mat-cell *matCellDef="let element">
            {{element.seguro?.mutuaPadronal}}
          </td>
        </ng-container>

        <!-- Columna Casa Taxímetro -->
        <ng-container matColumnDef="casaTaximetro_buscador">
          <th [hidden]="!listColumns['Casa taxímetro']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Casa Taxímetro" formControlName="casaTaximetro"
                    (keyup)="applyFilter($event,'taximetro.casaTaximetro', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="taximetro.casaTaximetro">
          <th [hidden]="!listColumns['Casa taxímetro']" mat-header-cell *matHeaderCellDef mat-sort-header
            class="header">
            Casa taxímetro
          </th>
          <td [hidden]="!listColumns['Casa taxímetro']" mat-cell *matCellDef="let element">
            {{element.taximetro?.casaTaximetro}}</td>
        </ng-container>

        <!-- Columna Clase Taxímetro -->
        <ng-container matColumnDef="claseTaximetro_buscador">
          <th [hidden]="!listColumns['Clase taxímetro']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Clase Taxímetro" formControlName="claseTaximetro"
                    (keyup)="applyFilter($event,'taximetro.claseTaximetro', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="taximetro.claseTaximetro">
          <th [hidden]="!listColumns['Clase taxímetro']" mat-header-cell *matHeaderCellDef mat-sort-header
            class="header">
            Clase taxímetro
          </th>
          <td [hidden]="!listColumns['Clase taxímetro']" mat-cell *matCellDef="let element">
            {{element.taximetro?.claseTaximetro}}</td>
        </ng-container>

        <!-- Columna Vencimiento Taxímetro -->
        <ng-container matColumnDef="vencimientoTaximetro_buscador">
          <th [hidden]="!listColumns['Vencimiento taxímetro']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Vencimiento Taxímetro" formControlName="vencimientoTaximetro"
                    (keyup)="applyFilter($event,'documentacion.revision.vencimientoTaximetro', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="documentacion.revision.vencimientoTaximetro">
          <th [hidden]="!listColumns['Vencimiento taxímetro']" mat-header-cell *matHeaderCellDef mat-sort-header
            class="header">
            Vencimiento taxímetro
          </th>
          <td [hidden]="!listColumns['Vencimiento taxímetro']" mat-cell *matCellDef="let element">
            {{element.documentacion?.revision?.vencimientoTaximetro}}</td>
        </ng-container>

        <!-- Columna Última Tarifa -->
        <ng-container matColumnDef="ultimaTarifa_buscador">
          <th [hidden]="!listColumns['Última tarifa aplicada']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setUltimaTarifaAplicada($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setUltimaTarifaAplicada($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="taximetro.ultimaTarifaAplicada">
          <th [hidden]="!listColumns['Última tarifa aplicada']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by ultimaTarifaAplicada" class="header">
            Última tarifa aplicada
          </th>
          <td [hidden]="!listColumns['Última tarifa aplicada']" mat-cell *matCellDef="let element">
            <p *ngIf="element.taximetro?.ultimaTarifaAplicada; else no" class="m-0">
              <mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Fecha Último Cambio de Tarifa -->
        <ng-container matColumnDef="fechaUltimaTarifa_buscador">
          <th [hidden]="!listColumns['Último cambio de tarifa']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Último Cambio de Tarifa" formControlName="fechaUltimoCambioDeTarifa"
                    (keyup)="applyFilter($event,'taximetro.fechaUltimoCambioDeTarifa', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="taximetro.fechaUltimoCambioDeTarifa">
          <th [hidden]="!listColumns['Último cambio de tarifa']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaUltimoCambioDeTarifa" class="header">
            Último cambio de tarifa
          </th>
          <td [hidden]="!listColumns['Último cambio de tarifa']" mat-cell *matCellDef="let element">
            {{element.taximetro?.fechaUltimoCambioDeTarifa}}</td>
        </ng-container>

        <!-- Columna Km Entrada -->
        <ng-container matColumnDef="kmEntrada_buscador">
          <th [hidden]="!listColumns['Km entrada']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Km Entrada" formControlName="kmEntrada"
                    (keyup)="applyFilter($event,'mantenimiento.kmEntrada', 'INTEGER')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.kmEntrada">
          <th [hidden]="!listColumns['Km entrada']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Km. entrada
          </th>
          <td [hidden]="!listColumns['Km entrada']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.kmEntrada}}<span *ngIf="element.mantenimiento?.kmEntrada != null">
              Km</span>
          </td>
        </ng-container>

        <!-- Columna Km Último Cambio de Aceite -->
        <ng-container matColumnDef="kmUltimoAceite_buscador">
          <th [hidden]="!listColumns['Último cambio de aceite']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Km Último Cambio de Aceite" formControlName="kmUltimoCambioDeAceite"
                    (keyup)="applyFilter($event,'mantenimiento.kmUltimoCambioDeAceite', 'INTEGER')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.kmUltimoCambioDeAceite">
          <th [hidden]="!listColumns['Último cambio de aceite']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Último cambio de aceite
          </th>
          <td [hidden]="!listColumns['Último cambio de aceite']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.kmUltimoCambioDeAceite}}<span
              *ngIf="element.mantenimiento?.kmUltimoCambioDeAceite != null"> Km</span></td>
        </ng-container>

        <!-- Columna Km Próximo Cambio de Aceite -->
        <ng-container matColumnDef="kmProximoAceite_buscador">
          <th [hidden]="!listColumns['Próximo cambio de aceite']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Km Próximo Cambio de Aceite" formControlName="kmProximoCambioDeAceite"
                    (keyup)="applyFilter($event,'mantenimiento.kmProximoCambioDeAceite', 'INTEGER')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.kmProximoCambioDeAceite">
          <th [hidden]="!listColumns['Próximo cambio de aceite']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Próximo cambio de aceite
          </th>
          <td [hidden]="!listColumns['Próximo cambio de aceite']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.kmProximoCambioDeAceite}}<span
              *ngIf="element.mantenimiento?.kmProximoCambioDeAceite != null"> Km</span></td>
        </ng-container>

        <!-- Columna Tipo Aceite -->
        <ng-container matColumnDef="tipoAceite_buscador">
          <th [hidden]="!listColumns['Tipo de aceite']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Tipo de Aceite" formControlName="tipoAceite"
                    (keyup)="applyFilter($event,'mantenimiento.tipoAceite', 'ENUM_ACEITE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.tipoAceite">
          <th [hidden]="!listColumns['Tipo de aceite']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Tipo de aceite
          </th>
          <td [hidden]="!listColumns['Tipo de aceite']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.tipoAceite}}</td>
        </ng-container>

        <!-- Columna Fecha Última Distribución -->
        <ng-container matColumnDef="fechaUltimaDistribucion_buscador">
          <th [hidden]="!listColumns['Última distribución']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Última Distribución" formControlName="fechaUltimaDistribucion"
                    (keyup)="applyFilter($event,'mantenimiento.fechaUltimaDistribucion', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.fechaUltimaDistribucion">
          <th [hidden]="!listColumns['Última distribución']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Última distribución
          </th>
          <td [hidden]="!listColumns['Última distribución']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.fechaUltimaDistribucion}}</td>
        </ng-container>

        <!-- Columna Fecha Próxima Distribución -->
        <ng-container matColumnDef="fechaProximaDistribucion_buscador">
          <th [hidden]="!listColumns['Próxima distribución']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Próxima Distribución" formControlName="fechaProximaDistribucion"
                    (keyup)="applyFilter($event,'mantenimiento.fechaProximaDistribucion', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.fechaProximaDistribucion">
          <th [hidden]="!listColumns['Próxima distribución']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Próxima distribución
          </th>
          <td [hidden]="!listColumns['Próxima distribución']" mat-cell *matCellDef="let element">
            {{element.mantenimiento?.fechaProximaDistribucion}}</td>
        </ng-container>

        <!-- Columna Filtro Combustible -->
        <ng-container matColumnDef="filtroCombustible_buscador">
          <th [hidden]="!listColumns['Filtro de combustible']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setHasCombustible($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasCombustible($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.hasFiltroCombustible">
          <th [hidden]="!listColumns['Filtro de combustible']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Filtro de combustible
          </th>
          <td [hidden]="!listColumns['Filtro de combustible']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.hasFiltroCombustible; else no" class="m-0">
              <mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Filtro Aire -->
        <ng-container matColumnDef="filtroAire_buscador">
          <th [hidden]="!listColumns['Filtro de aire']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked (change)="setHasAire($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasAire($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.hasFiltroAire">
          <th [hidden]="!listColumns['Filtro de aire']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Filtro de aire
          </th>
          <td [hidden]="!listColumns['Filtro de aire']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.hasFiltroAire; else no" class="m-0"><mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Filtro Habitáculo -->
        <ng-container matColumnDef="filtroHabitaculo_buscador">
          <th [hidden]="!listColumns['Filtro de habitáculo']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setHasHabitaculo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasHabitaculo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.hasFiltroHabitaculo">
          <th [hidden]="!listColumns['Filtro de habitáculo']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Filtro de habitáculo
          </th>
          <td [hidden]="!listColumns['Filtro de habitáculo']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.hasFiltroHabitaculo; else no" class="m-0">
              <mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Extintor -->
        <ng-container matColumnDef="extintor_buscador">
          <th [hidden]="!listColumns['Extintor']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked (change)="setHasExtintor($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasExtintor($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.extintor">
          <th [hidden]="!listColumns['Extintor']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Extintor
          </th>
          <td [hidden]="!listColumns['Extintor']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.extintor; else no" class="m-0"><mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Boletín Meteorológico -->
        <ng-container matColumnDef="boletinMeteorologico_buscador">
          <th [hidden]="!listColumns['Boletín meteorologico']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setHasBoletinMeteorologico($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasBoletinMeteorologico($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.boletinMeteorologico">
          <th [hidden]="!listColumns['Boletín meteorologico']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Boletín meteorológico
          </th>
          <td [hidden]="!listColumns['Boletín meteorologico']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.boletinMeteorologico; else no" class="m-0">
              <mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Tarjeta Transporte -->
        <ng-container matColumnDef="tarjetaTransporte_buscador">
          <th [hidden]="!listColumns['Tarjeta transporte']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setHasTarjetaTransporte($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setHasTarjetaTransporte($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="mantenimiento.tarjetaTransporte">
          <th [hidden]="!listColumns['Tarjeta transporte']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Tarjeta transporte
          </th>
          <td [hidden]="!listColumns['Tarjeta transporte']" mat-cell *matCellDef="let element">
            <p *ngIf="element.mantenimiento?.tarjetaTransporte; else no" class="m-0">
              <mat-icon>check</mat-icon>
            </p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Vencimiento ITV -->
        <ng-container matColumnDef="vencimientoITV_buscador">
          <th [hidden]="!listColumns['Vencimiento ITV']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Vencimiento ITV" formControlName="vencimientoITV"
                    (keyup)="applyFilter($event,'documentacion.revision.vencimientoITV', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="documentacion.revision.vencimientoITV">
          <th [hidden]="!listColumns['Vencimiento ITV']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Vencimiento ITV
          </th>
          <td [hidden]="!listColumns['Vencimiento ITV']" mat-cell *matCellDef="let element">
            {{element.documentacion?.revision?.vencimientoITV}}</td>
        </ng-container>

        <!-- Columna Última Revisión -->
        <ng-container matColumnDef="fechaUltimaRevision_buscador">
          <th [hidden]="!listColumns['Última revisión']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Última Revisión" formControlName="ultimaRevision"
                    (keyup)="applyFilter($event,'documentacion.revision.ultimaRevision', 'LOCALDATE_BETWEEN')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="documentacion.revision.ultimaRevision">
          <th [hidden]="!listColumns['Última revisión']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Última revisión
          </th>
          <td [hidden]="!listColumns['Última revisión']" mat-cell *matCellDef="let element">
            {{element.documentacion?.revision?.ultimaRevision}}</td>
        </ng-container>
        ´
        <!-- Columna Activo -->
        <ng-container matColumnDef="activo_buscador">
          <th [hidden]="!listColumns['Activo']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="fechaBaja">
          <th mat-header-cell [hidden]="!listColumns['Activo']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by activo" class="header">
            Activo
          </th>
          <td mat-cell [hidden]="!listColumns['Activo']" *matCellDef="let row">
            <p *ngIf="row.fechaBaja == null; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <!-- Columna Tipo Vehículo -->
        <ng-container matColumnDef="tipoVehiculo_buscador">
          <th [hidden]="!listColumns['Tipo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Tipo de Vehículo" formControlName="tipo"
                    (keyup)="applyFilter($event,'tipo.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="tipo.nombre">
          <th [hidden]="!listColumns['Tipo']" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by modelo" class="header">
            Tipo de vehículo
          </th>
          <td [hidden]="!listColumns['Tipo']" mat-cell *matCellDef="let element">
            {{element.tipo?.nombre}}</td>
        </ng-container>

        <!-- Columna Acciones -->
        <ng-container matColumnDef="acciones_buscador" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
        </ng-container>

        <ng-container matColumnDef="acciones" class="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="header"></th>
          <td mat-cell *matCellDef="let element" class="mat-column acciones">
            <div class="buttons-flex-container">
              <a class="eye-button activated" (click)="setMostrarFormulario(true, element, true)"></a>
              <a class="delete-button activated" (click)="openDialogBorrarVehiculo(element.id)"></a>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>
    <mat-paginator class="mt-3" #paginator (page)="nextPage($event)" [length]="totalElements" [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
  </div>

</div>
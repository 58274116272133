<div class="crear-editar-form">

    <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crear un nuevo préstamo</h1>
    <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita un préstamo</h1>

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="mw-100 mt-4">
        <div class="row">
            <div class="col">
                <div *ngFor="let prestamo of prestamosList; let i = index">
                    <div class="row">
                        <!-- ------------------------------- Campo Fecha ----------------------- -->
                    <div class="form-group col-sm">
                        <label>Fecha</label>
                        <input [disabled]="!isUserAdmin" *ngIf="!this.data.isForUpdate" type="date"
                            class="form-control" [(ngModel)]="prestamo.fechaAlta" #name="ngModel"
                            name="fechaAlta{{i}}">

                        <input [disabled]="!isUserAdmin" *ngIf="this.data.isForUpdate" type="date"
                            class="form-control" [ngModel]="fechaAlta | date:'yyyy-MM-dd'"
                            (change)="getValue($event)">
                    </div>

                    <!-- ------------------------------- Campo Conductor ----------------------- -->
                    <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                        <label>Conductor</label>
                        <select [disabled]="!isUserAdmin" class="form-control form-select" name="conductor"
                            [(ngModel)]="prestamo.conductor" #name="ngModel" name="conductor{{i}}"
                            (change)="getLicenciasByConductor(prestamo.conductor.id, prestamo)">
                            <option value=null>Selecciona un conductor</option>
                            <option *ngFor="let conductor of conductores" [ngValue]="conductor">
                                {{conductor.nombre}} {{conductor.apellidos}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                        <label>Conductor</label>
                        <select [disabled]="!isUserAdmin" class="form-control form-select" name="conductor{{i}}"
                            [(ngModel)]="selectedValueConductor" #name="ngModel"
                            (change)="getLicenciasByConductor(selectedValueConductor, prestamo)">
                            <option *ngFor="let conductor of conductores" [value]="conductor.id">
                                {{conductor.nombre}} {{conductor.apellidos}}
                            </option>
                        </select>
                    </div>
                    <!--  <div class="col">
                        <input type="string" class="positionInputs input" disabled placeholder="{{prestamo.conductor}}">
                    </div> -->

                    <!-- ------------------------------- Campo Licencia ----------------------- -->

                    <div class="form-group col-sm" *ngIf="!this.data.isForUpdate">
                        <label>Licencia<span style="color: red;">*</span></label>
                        <select [disabled]="!isUserAdmin" class="form-control form-select" name="licencia" [(ngModel)]="prestamo.licencia"
                            #name="ngModel" name="licencia{{i}}">
                            <option value=null>Selecciona una licencia</option>
                            <option *ngFor="let licencia of prestamo.posiblesLicencias" [ngValue]="licencia">
                                {{licencia.numero_licencia}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-sm" *ngIf="this.data.isForUpdate">
                        <label>Licencia<span style="color: red;">*</span></label>
                        <select [disabled]="!isUserAdmin" class="form-control form-select" name="licencia"
                            [(ngModel)]="selectedValueLicencia" #name="ngModel" name="licencia{{i}}">
                            <option *ngFor="let licencia of prestamo.posiblesLicencias" [value]="licencia.id">
                                {{licencia.numero_licencia}}
                            </option>
                        </select>
                    </div>

                    <!-- ------------------------------- Campo Importe ----------------------- -->
                    <div class="form-group col-sm">
                        <label>Importe<span style="color: red;">*</span></label>
                        <input [disabled]="!isUserAdmin" type="number" class="form-control"
                            [(ngModel)]="prestamo.importe" #name="ngModel" name="importe{{i}}">
                    </div>

                    <!-- ------------------------------- Campo Concepto ----------------------- -->
                    <div class="form-group col-sm">
                        <label>Concepto</label>
                        <input [disabled]="!isUserAdmin" type="text" class="form-control"
                            [(ngModel)]="prestamo.concepto" #name="ngModel" name="concepto{{i}}">
                    </div>

                    <!-- ------------------------------- Campo numDocumento/numFactura ----------------------- -->
                    <div class="form-group col-sm">
                        <label>Nº Factura</label>
                        <input [disabled]="!isUserAdmin" type="text" class="form-control"
                            [(ngModel)]="prestamo.numDocumento" #name="ngModel" name="numDocumento{{i}}">
                    </div>


                    <!--<div class=".col-xxl col-add-delete" *ngIf="!this.data.isForUpdate">
                        <button class="btn" (click)="addPrestamo()" [disabled]="!isUserAdmin"><mat-icon
                                class="align-middle mr-2">add</mat-icon></button>
                        <button class="btn btn-delete"
                            [disabled]="prestamosList.length > 1 && isUserAdmin ? '' : 'disabled'"
                            (click)="removePrestamo(i)"><mat-icon class="align-middle mr-2">delete</mat-icon></button>
                    </div>-->
                    </div>

                    <div class="row justify-content-start">
                        <div class="form-group col-sm-auto mb-4">
                            <button *ngIf="!data.isForUpdate" type="button" [disabled]="i==0 || !isUserAdmin"
                            class="btn btn-outline-primary btn-sm d-flex flex-row align-items-center" (click)="removePrestamo(i)"><mat-icon>delete</mat-icon>Eliminar</button>
                        </div>
                    </div>
                    
                </div>

                <hr class="mt-0">
                <div class="row justify-content-start">
                    <div class="form-group col-sm-auto">
                        <button *ngIf="!data.isForUpdate" type="button" [disabled]="!isUserAdmin"
                            class="btn btn-primary btn-sm d-flex flex-row align-items-center" (click)="addPrestamo()"><mat-icon>add</mat-icon>Añadir
                            préstamo</button>
                    </div>
                </div>

                <!-- ------------------------------- Botones de guardado ----------------------- -->
                <div id="container" class="d-flex justify-content-end pt-3">
                    <button *ngIf="isUserAdmin" class="btn btn-success"
                        (click)="openDialogConfirm()">Guardar</button>
                    <button class="btn btn-secondary ml-3" (click)="confirmCancelOperation()">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, throwError } from "rxjs";
import { Propietario } from "src/app/model/novotaxi/propietario.model";
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
    providedIn: "root",
})

export class PropietarioService {

    API_URL: string = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) { }

    getAllPropietarios(request?) {
        return this.http
            .post(`${this.API_URL}/propietario/list`, request)
    }

    getAllPropietariosActives() {
        return this.http
            .get(`${this.API_URL}/propietario/listActive`)
            .pipe(retry(1), catchError(this.handleError));
    }

    saveUpdatePropietario(propietario: Propietario) {
        return this.http
            .post(`${this.API_URL}/propietario`, propietario, {});
    }

    deletePropietario(id_propietario, fecha) {
        const url = `${this.API_URL}/propietario/delete/` + id_propietario;
        return this.http
            .post(url, fecha)
            .pipe(retry(1), catchError(this.handleError));
    }

    getGestorias() {
        return this.http
            .get(`${this.API_URL}/gestoria/list`)
            .pipe(retry(1), catchError(this.handleError));
    }

    getHistorico(id) {
        return this.http
            .get(`${this.API_URL}/propietario/getHistorico/${id}`)
        // .pipe(retry(1), catchError(this.handleError));
    }

    getHistoricoLicenciaByPropietario(id) {
        return this.http
            .get(`${this.API_URL}/propietarioLicenciaAsociacion/getHistoricoPropietario/${id}`)
        // .pipe(retry(1), catchError(this.handleError));
    }

    getDetails() {
        return this.http
            .get(`${this.API_URL}/propietario/getDetails`)
    }

    saveDocuments(id, documents) {
        return this.http.post(`${this.API_URL}/propietario/saveDocuments/${id}`, documents);
    }

    getDocuments(id) {
        return this.http.get(`${this.API_URL}/propietario/getDocuments/${id}`);
    }

    updateDocuments(id, documents) {
        return this.http.post(`${this.API_URL}/propietario/updateDocuments/${id}`, documents);
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }
}


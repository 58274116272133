import { IConductor, TipoSueldo } from "src/app/conductores/conductor.model";
import { Licencia } from "../../Emisora";
import { Usuario } from "../gastos-ingresos/gastos-ingresos.model";
import { Informe } from "./informe.model";
import { Kilometraje } from "./kilometraje.model";
import { OperacionesConductor } from "./operaciones-conductor.model";
import { IVehiculo } from "src/app/vehiculo/vehiculo.model";

export class Recaudacion {
    id?: number;
    createdDate?: string | null;
    fechaAlta?: string | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;
    no_computa?: boolean;
    kilometraje?: Kilometraje;
    taximetro_entrada?: number;
    taximetro_salida?: number;
    retencion?: number;
    tarjetas?: any[];
    vales?: any[];
    incidencias?: number;
    total_tarjeta?: number;
    total_vales?: number;
    total_incidencias?: number;
    informe?: Informe;
    licencia?: Licencia;
    conductor?: IConductor;
    observaciones?: string;
    operacion_conductor?: OperacionesConductor;
    usuario?: Usuario;
    tipoSueldo?: TipoSueldo;
    cantidad?: number;
    efectivo?: number;


    constructor(mappedData) {
        this.id = mappedData?.id;
        this.fechaAlta = mappedData?.fechaAlta;
        this.fechaBaja = mappedData?.fechaBaja;
        this.fechaModificacion = mappedData?.fechaModificacion;
        this.no_computa = mappedData?.no_computa;
        this.kilometraje = mappedData?.kilometraje;
        this.taximetro_entrada = mappedData?.taximetro_entrada ?? 0;
        this.taximetro_salida = mappedData?.taximetro_salida ?? 0;
        this.retencion = mappedData?.retencion ?? 0;
        this.tarjetas = mappedData?.tarjetas ?? 0;
        this.vales = mappedData?.vales ?? 0;
        this.incidencias = mappedData?.incidencias ?? 0;
        this.total_tarjeta = mappedData?.total_tarjeta ?? 0;
        this.total_vales = mappedData?.total_vales ?? 0;
        this.total_incidencias = mappedData?.total_incidencias ?? 0;
        this.informe = mappedData?.informe;
        // this.licencia = mappedData?.licencia ? this.getLicencia(mappedData.licencia) : null;
        this.licencia = mappedData?.licencia ? mappedData.licencia : null;
        this.conductor = new IConductor(mappedData?.conductor);
        this.observaciones = mappedData?.observaciones;
        this.operacion_conductor = mappedData?.operacion_conductor;
        this.usuario = new Usuario(mappedData?.usuario);
        this.tipoSueldo = mappedData?.tipoSueldo;
        this.cantidad = mappedData.cantidad;
        this.efectivo = mappedData.efectivo;
    }

    /* getLicencia?(licencia) {
        var listVehiculos: IVehiculo[] = [];

        licencia?.vehiculo.forEach(vehiculo => {
            var newVehiculo = new IVehiculo(vehiculo);
            listVehiculos.push(newVehiculo);
        });

        licencia.vehiculo = listVehiculos;
        return licencia;
    } */
}

export class TipoVale {
    id?: number;
    nombre?: string | null;
}

export class TipoTarjeta {
    id?: number;
    nombre?: string | null;
}
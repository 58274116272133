import { LicenciaServiece } from './../../_services/licenciaService.service';
import { IMarca, IModelo } from '../../novotaxi/marca-modelo/modelo.model';
import { MarcaService } from './../../novotaxi/marca-modelo/services/marca.service';
import { IVehiculo } from './../vehiculo.model';
import { VehiculoService } from "../services/vehiculo.service";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { CompaniaSegurosService } from 'src/app/novotaxi/companiaSeguros/services/companiaSeguros.service';
import { ICompaniaSeguros } from 'src/app/novotaxi/companiaSeguros/companiaSeguros.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { Licencia } from 'src/app/model/Emisora';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-crear-actualizar-vehiculo',
  templateUrl: './crear-actualizar-vehiculo.component.html',
  styleUrls: ['./crear-actualizar-vehiculo.component.css']
})

export class CrearActualizarVehiculoComponent {

  // -- Variables histórico
  mostrarHistorico: Boolean = false;
  mostrarBotones: Boolean = true;
  vehiculoHistorico: IVehiculo[] = [];
  vehiculoLicenciaHistorico: any;
  tipoVehiculo: any;

  listaMarcas: IMarca[] = [];
  listaModelos: IModelo[] = []
  listaModeloMarca: IModelo[] = []; //Los modelos pertenecientes a la marca que se selecciona en el select
  listaCiaSeguros: ICompaniaSeguros[] = [];
  telefonoCiaSeguros: string[] = null;
  listaLicencias: Licencia[] = [];
  form;

  vehiculo: IVehiculo;
  isSubmitted: Boolean = false;

  @Output() mostrarFormulario = new EventEmitter<Boolean>();
  @Input() vehiculoUpdate;
  @Input() editar: Boolean;

  constructor(
    protected vehiculoService: VehiculoService,
    private marcaService: MarcaService,
    private companiaSegurosService: CompaniaSegurosService,
    public servicioGeneral: ServicioGeneral,
    private router: Router,
    public dialog: MatDialog,
    public _notificationService: NotificationService,
    public licenciaService: LicenciaServiece,
  ) { }

  ngOnInit(): void {
    this.getTipoVehiculo();
    this.getAllModelo();
    this.getAllMarca();
    this.getAllCiaSeguros();
    this.getAllLicencia();
    this.cargarVehiculo();

    if(this.editar) {
      this.getHistoricoVehiculo();
    }

    if (this.vehiculoUpdate?.fechaBaja != null) {
      for (const key in this.form.controls) {
        this.form.controls[key].disable();
      }
    }
  }

  setMostrarHistorico(mode: Boolean) {
    this.mostrarHistorico = mode;
    if (mode) {
      this.mostrarBotones = false;
    }
    else {
      this.mostrarBotones = true;
    }
  }

  getTipoVehiculo() {
    this.vehiculoService.findTipoVehiculo().subscribe((result) => {
      this.tipoVehiculo = result;
    })
  }

  getHistoricoVehiculo() {
    this.vehiculoService.getHistorico(this.vehiculoUpdate.id).subscribe((result) => {
      this.vehiculoHistorico = result['historico'];
    });

    this.vehiculoService.getHistoricoLicenciaVehiculo(this.vehiculoUpdate.id).subscribe((result) => {
      this.vehiculoLicenciaHistorico = result;
    });
  }

  getAllMarca() {
    this.marcaService.queryMarcas().subscribe((res) => {
      this.listaMarcas = res['marcas'];
      //this.listaMarcas = ordenarMarcasModelos(this.listaMarcas);
    });
  }

  getAllModelo() {
    this.marcaService.query().subscribe({
      next: (res) => {
        this.listaModelos = res['modelos'];
        this.listaModelos = this.listaModelos?.filter(modelo => modelo.fechaBaja == null);
        //  this.listaMarcas = ordenarMarcasModelos(this.listaModelos);
        this.getAllModeloMarcaEditar(this.vehiculoUpdate?.modelo.marca.id); //Obtenemos el modelo asociado a esa marca que tenía asignado el vehículo  
      }
    })
  }

  getAllModeloMarca(event: Event) {
    let idMarca: number = +(event.target as HTMLInputElement).value - 1;
    if (idMarca == -1) {
      this.form.controls.datos.controls.modelo.setValue(0); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      this.listaModeloMarca = this.listaModelos?.filter(modelo => modelo.marca.id == idMarca + 1);
    }
  }

  getAllModeloMarcaEditar(idMarca: number) {
    this.listaModeloMarca = this.listaModelos?.filter(modelo => modelo.marca.id == idMarca);
  }

  getAllCiaSeguros() {
    this.companiaSegurosService.query().subscribe(
      (res) => {
        this.listaCiaSeguros = res['CompaniasSeguros'];
      }
    );
  }

  getTelefonoCiaSeguros(event: Event) {
    let idCiaSeguros: number = +(event.target as HTMLInputElement).value - 1;
    if (idCiaSeguros == -1) {
      this.form.controls.seguro.controls.tlfContacto.setValue(null); //Si el usuario marca 'selecciona una marca', modelo se pone a 'selecciona un modelo'
    }
    else {
      this.telefonoCiaSeguros = this.listaCiaSeguros[idCiaSeguros].telefono;
      this.form.controls.seguro.controls.tlfContacto.setValue(this.telefonoCiaSeguros);
    }
  }

  getAllLicencia() {
    this.licenciaService.listLicencia().subscribe(
      (res) => {
        this.listaLicencias = res['licencias'];
      }
    )
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "guardar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    });
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "descartar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      }
    });
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarActivate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "activar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    });
  }

  cargarVehiculo() {
    this.form = new FormGroup({
      datos: new FormGroup({
        licencia: new FormControl(this.vehiculoUpdate?.licencia?.id ? this.vehiculoUpdate?.licencia?.id : -1, [Validators.required]),
        tipo: new FormControl(this.vehiculoUpdate?.tipo?.id ? this.vehiculoUpdate?.tipo?.id : 0),
        matricula: new FormControl(this.vehiculoUpdate?.matricula, {
          validators: [
            Validators.required,
            Validators.pattern("(^[0-9]{4}[A-Za-z]{3}$)")
          ],
          updateOn: 'blur'
        }),
        marca: new FormControl(this.vehiculoUpdate?.modelo?.marca?.id ? this.vehiculoUpdate?.modelo?.marca?.id : 0, [Validators.required]),
        modelo: new FormControl(this.vehiculoUpdate?.modelo?.id ? this.vehiculoUpdate?.modelo?.id : 0, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]),
      }),
      seguro: new FormGroup({
        ciaSeguros: new FormControl(this.vehiculoUpdate?.seguro?.ciaSeguros?.id | 0),
        tlfContacto: new FormControl(this.vehiculoUpdate?.seguro?.ciaSeguros?.telefono),
        mutuaPadronal: new FormControl(this.vehiculoUpdate?.seguro?.mutuaPadronal),
        fechaVencimiento: new FormControl(this.convertDate(this.vehiculoUpdate?.seguro?.fechaVencimiento, false)),
      }),
      taximetro: new FormGroup({
        casaTaximetro: new FormControl(this.vehiculoUpdate?.taximetro?.casaTaximetro),
        claseTaximetro: new FormControl(this.vehiculoUpdate?.taximetro?.claseTaximetro),
        ultimaTarifaAplicada: new FormControl(this.vehiculoUpdate?.taximetro?.ultimaTarifaAplicada ?? false),
        fechaUltimoCambioDeTarifa: new FormControl(this.convertDate(this.vehiculoUpdate?.taximetro?.fechaUltimoCambioDeTarifa, false)),
      }),
      mantenimiento: new FormGroup({
        kmEntrada: new FormControl(this.vehiculoUpdate?.mantenimiento?.kmEntrada),
        fechaUltimaDistribucion: new FormControl(this.convertDate(this.vehiculoUpdate?.mantenimiento?.fechaUltimaDistribucion, false)),
        fechaProximaDistribucion: new FormControl(this.convertDate(this.vehiculoUpdate?.mantenimiento?.fechaProximaDistribucion, false)),
        kmUltimoCambioDeAceite: new FormControl(this.vehiculoUpdate?.mantenimiento?.kmUltimoCambioDeAceite),
        kmProximoCambioDeAceite: new FormControl(this.vehiculoUpdate?.mantenimiento?.kmProximoCambioDeAceite),
        tipoAceite: new FormControl(this.vehiculoUpdate?.mantenimiento?.tipoAceite ? this.vehiculoUpdate?.mantenimiento?.tipoAceite : 0),
        hasFiltroAire: new FormControl(this.vehiculoUpdate?.mantenimiento?.hasFiltroAire ?? false),
        hasFiltroCombustible: new FormControl(this.vehiculoUpdate?.mantenimiento?.hasFiltroCombustible ?? false),
        hasFiltroHabitaculo: new FormControl(this.vehiculoUpdate?.mantenimiento?.hasFiltroHabitaculo ?? false),
        extintor: new FormControl(this.vehiculoUpdate?.mantenimiento?.extintor ?? false),
        tarjetaTransporte: new FormControl(this.vehiculoUpdate?.mantenimiento?.tarjetaTransporte ?? false),
        boletinMeteorologico: new FormControl(this.vehiculoUpdate?.mantenimiento?.boletinMeteorologico ?? false),
      }),
      revision: new FormGroup({
        ultimaRevision: new FormControl(this.convertDate(this.vehiculoUpdate?.documentacion?.revision?.ultimaRevision, false)),
        vencimientoTaximetro: new FormControl(this.convertDate(this.vehiculoUpdate?.documentacion?.revision?.vencimientoTaximetro, false)),
        vencimientoITV: new FormControl(this.convertDate(this.vehiculoUpdate?.documentacion?.revision?.vencimientoITV, false)),
        descripcionVehiculoITV: new FormControl(this.vehiculoUpdate?.documentacion?.revision?.descripcionVehiculoITV),
      }),
      observacionesVehiculo: new FormControl(this.vehiculoUpdate?.observaciones),
      fechaBaja: new FormControl(this.convertDate(this.vehiculoUpdate?.fechaBaja, false)),
      // fechaAlta: new FormControl(this.convertDate(this.vehiculoUpdate?.fecha, false)),
    })
  }

  submit() {    

    this.vehiculo = {
      id: this.vehiculoUpdate?.id ? this.vehiculoUpdate?.id : null,
      licencia: {
        id: this.form.value.datos.licencia,
      },
      tipo: this.form.value.datos.tipo == 0 ? null : {
        id: this.form.value.datos.tipo,
      },
      modelo: this.form.value.datos.modelo == 0 ? null : {
        id: this.form.value.datos.modelo
      },
      matricula: this.form?.value?.datos?.matricula?.toUpperCase(),
      seguro: {
        ciaSeguros: this.form.value.seguro.ciaSeguros == 0 ? null : ( //Para permitir que no se introduzca una companía de seguros
          {
            id: this.form.value.seguro.ciaSeguros,
            telefono: this.form.value.seguro.tlfContacto
          }
        ),
        mutuaPadronal: this.form.value.seguro.mutuaPadronal,
        fechaVencimiento: this.convertDate(this.form.value.seguro.fechaVencimiento, true),
      },
      taximetro: {
        casaTaximetro: this.form.value.taximetro.casaTaximetro,
        claseTaximetro: this.form.value.taximetro.claseTaximetro,
        ultimaTarifaAplicada: this.form.value.taximetro.ultimaTarifaAplicada,
        fechaUltimoCambioDeTarifa: this.convertDate(this.form.value.taximetro.fechaUltimoCambioDeTarifa, true),
      },
      mantenimiento: {
        kmEntrada: this.form.value.mantenimiento.kmEntrada,
        fechaUltimaDistribucion: this.convertDate(this.form.value.mantenimiento.fechaUltimaDistribucion, true),
        fechaProximaDistribucion: this.convertDate(this.form.value.mantenimiento.fechaProximaDistribucion, true),
        kmUltimoCambioDeAceite: this.form.value.mantenimiento.kmUltimoCambioDeAceite,
        kmProximoCambioDeAceite: this.form.value.mantenimiento.kmProximoCambioDeAceite,
        tipoAceite: this.form.value.mantenimiento.tipoAceite == 0 ? null : this.form.value.mantenimiento.tipoAceite,
        hasFiltroAire: this.form.value.mantenimiento.hasFiltroAire,
        hasFiltroCombustible: this.form.value.mantenimiento.hasFiltroCombustible,
        hasFiltroHabitaculo: this.form.value.mantenimiento.hasFiltroHabitaculo,
        boletinMeteorologico: this.form.value.mantenimiento.boletinMeteorologico,
        extintor: this.form.value.mantenimiento.extintor,
        tarjetaTransporte: this.form.value.mantenimiento.tarjetaTransporte,
      },
      documentacion: {
        revision: {
          ultimaRevision: this.convertDate(this.form.value.revision.ultimaRevision, true),
          vencimientoTaximetro: this.convertDate(this.form.value.revision.vencimientoTaximetro, true),
          vencimientoITV: this.convertDate(this.form.value.revision.vencimientoITV, true),
          descripcionVehiculoITV: this.form.value.revision.descripcionVehiculoITV,
        }
      },
      observaciones: this.form.value.observacionesVehiculo,
      fechaAlta: this.vehiculoUpdate?.fechaAlta,
    };

    if (this.vehiculo?.tipo?.id == null) {
      this.vehiculo.tipo = null;
    }

    if (this.vehiculo.licencia.id == 0 || this.vehiculo.licencia.id == -1) {
      this.vehiculo.licencia = null;
    }
    
    this.vehiculoService.create(this.vehiculo).subscribe({
      next: (data) => {
        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
          this.isSubmitted = false;
          this.reloadToMenuOption();
        } else {
          this.isSubmitted = true;
          let message = data['message'];
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);
        }
      }
    });
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  /*   convertDate(date: string): string { //Conversor de fecha para poder enviarla al back de forma correcta
      if (date != null) {
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else return null
    } */

  resetearForm() {
    this.form.reset()
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  convertDate(date: string, modo: Boolean): string {
    if (date != null && date != '') {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Anádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia);
      }
    }
    else return null;
  }
}
<div  class="card p-3 d-flex justify-content-center">
    <div class="px-2">
        <div class="columnn">
            <div class="row">
                <h2 class="w-100">Confirmar operacion de {{data.titulo}}</h2>
            </div>
            <div class="row">
                <h6 class="w-100 subtitle my-3">¿Esta seguro de que desea realizar esta operación?</h6>
            </div>
    
            <div class="w-100 d-flex mt-3 justify-content-between row">
                <button (click)="cancel()" class="py-2 btn btn-secondary m-left footer-btn">Cancelar</button>
                <button (click)="finish()" class="py-2 btn btn-primary m-left footer-btn">Confirmar</button>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="crear-editar-form">

        <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crea un nuevo proveedor o concepto</h1>
        <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita un nuevo proveedor o concepto</h1>

        <h2 class="divisiones-formularios">Datos del proveedor</h2>
        <hr>

        <div class="row mt-2 mb-4">
            <div class="form-group col-sm-4">
                <label for="nombre">Nombre</label>
                <input [disabled]="isDelete" class="form-control" type="text"
                    id="nombre" [(ngModel)]="proveedorSave.nombre">
            </div>

            <div class="form-group col-sm-4">
                <label for="cif">CIF<span style="color: red;">*</span></label>
                <input [disabled]="isDelete" class="form-control" type="text"
                    id="cif" minlength="9" maxlength="9" [(ngModel)]="proveedorSave.cif">
            </div>
        </div>

        <h2 class="divisiones-formularios">Datos del concepto</h2>
        <hr>

        <div class="row mt-2">
            <div class="col-sm-4">
                <label for="nombre">Concepto</label>
            </div>

            <div class="col-sm-4">
                <label for="cif">IVA</label>
            </div>
        </div>

        <div class="row mt-2 mb-1" *ngFor="let concepto of conceptosList; let i = index">
            <div class="form-group col-sm-4 concepto">
                <input [disabled]="isDelete" type="text" class="form-control"
                    [(ngModel)]="concepto.concepto" #name="ngModel" name="concepto{{i}}">
            </div>

            <div class="form-group col-sm-4 concepto">
                <input [disabled]="isDelete" type="number" class="form-control"
                    [(ngModel)]="concepto.iva" #name="ngModel" name="iva{{i}}">
            </div>
            
            <div class=".col-xxl col-add-delete d-flex align-items-center">
                <!--<button  class="btn" [hidden]="isDelete"
                    (click)="addConcepto()"><mat-icon class="align-middle mr-2">add</mat-icon></button>-->
                <button *ngIf="i>0" class="btn btn-primary btn-sm d-flex flex-row align-items-center" [hidden]="isDelete"
                    (click)="removeConcepto(i)"><mat-icon class="align-middle mr-2">delete</mat-icon>Eliminar</button>
            </div>
        </div>

        <div class="row mb-4 mt-2">
            <div class="form-group col">
                <button (click)="addConcepto()" type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"><mat-icon>add</mat-icon>Añadir concepto</button>
            </div>  
        </div>

        <div id="container" class="d-flex justify-content-end pt-3">
            <!-- <button class="btn btn-primary mx-3" (click)="openDialogConfirm()">Guardar</button> -->

            <button [hidden]="isDelete" class="btn btn-success mx-3"
                (click)="openDialogConfirm()">Guardar</button>

            <button class="btn btn-warning" tabindex="-1" type="button" (click)="openDialogConfirmarActivate()"
                [hidden]="!isDelete">Activar</button>

            <button class="btn btn-secondary" (click)="confirmCancelOperation()">Cerrar</button>
        </div>
    </div>
</div>
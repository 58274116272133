import { IMarca, IModelo } from "../modelo.model";
import { MarcaService } from "../services/marca.service";
import { Component, OnInit, ViewChild, Inject, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from '@angular/router';
import { ConfirmarUnsavedDialog, ConfirmarUpdateDialog } from 'src/app/conductores/listado-conductores/listado-conductores.component';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { NotificationService } from "src/app/_services/notification.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ModalConfirmacionComponent } from "../../modal-confirmacion/modal-confirmacion.component";
import { InformePDF } from "../../licencias/informe-licencia/informePDF";

@Component({
  selector: 'app-listado-marca-modelo',
  templateUrl: './listado-marca-modelo.component.html',
  styleUrls: ['./listado-marca-modelo.component.css'],
})

export class ListadoMarcaModeloComponent implements OnInit {

  displayedColumns: string[] = ['marca.nombre', 'nombre', 'acciones', 'blank'];

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() nombreMenu;

  listColumns;
  dataSource = new MatTableDataSource();
  listaMarcas?: IMarca[];
  listaModelos?: IModelo[];
  listaMarcasModelosFormat = [];
  mostrarFormulario: Boolean = false;
  isMarca: Boolean;
  marcaModelo;
  editar: Boolean;
  ocultarBotonMarca;
  ocultarBotonModelo;

  constructor(
    private marcaService: MarcaService,
    private dialog: MatDialog,
    private router: Router,
    public _notificationService: NotificationService,
    private informe: InformePDF,
  ) { }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  ngOnInit(): void {
    window.scroll(0, 0)
    this.getAllModelo();
    this.getAllMarca();
    this.listColumns = {
      'Marca': true,
      'Modelo': true,
    }
  }
  generarInforme(){
    let nombre = 'MarcaModelo';
    var inicio= this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
    var final=inicio + this.dataSource.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData,nombre,inicio,final);
  }
  //Paginado
  length = this.listaMarcasModelosFormat.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  getAllModelo() {
    this.marcaService.query().subscribe({
      next: (res) => {
        this.listaModelos = res['modelos'];
        this.listaModelos = this.listaModelos?.filter(modelo => modelo.fechaBaja == null);
        this.dataSource = new MatTableDataSource(this.listaModelos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);

      }
    })
  }

  getAllMarca() {
    this.marcaService.queryMarcas().subscribe({
      next: (res) => {
        this.listaMarcas = res['marcas'];
        this.listaMarcas = this.listaMarcas?.filter(marca => marca.fechaBaja == null);
        this.listaMarcas = ordenarMarcasModelos(this.listaMarcas);
      }
    })
  }

  setMostrarFormulario(mode: Boolean, marcaModelo?: any, isMarca?: Boolean, tipo?: Boolean) {
    this.mostrarFormulario = mode;
    this.isMarca = isMarca
    this.marcaModelo = marcaModelo;
    this.editar = tipo;
    if (isMarca && mode) this.ocultarBotonMarca = true
    else this.ocultarBotonMarca = false

    if (!isMarca && mode) this.ocultarBotonModelo = true
    else this.ocultarBotonModelo = false

    document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })

  }

  /*getAllMarcaModelo() {
    this.marcaService.query().subscribe((res) => {
      this.listaMarcas = res['marcas'];
      this.listaMarcas = this.listaMarcas.filter(marca => marca.fechaBaja == null);
      this.listaMarcas.forEach(vehiculo =>
        vehiculo.modelos = vehiculo.modelos.filter(modelo => modelo.fechaBaja == null)
      )//Mostramos el conjunto de elementos que no están borrados, es decir, que no tienen fecha de baja

      this.formatTableData();
    });
  }*/

  /*formatTableData() { // Específico para mostrar, por cada modelo, su marca. En formato: Marca - Modelo
    this.listaMarcas.forEach(
      item => {
        item.modelos.forEach(modelo => {
          let marcasModelos = {
            idModelo: modelo?.id,
            idMarca: item?.id,
            marca: item?.nombre,
            modelo: modelo?.nombre
          }
          this.listaMarcasModelosFormat.push(marcasModelos);
        });
      }
    );

    this.dataSource = new MatTableDataSource(this.listaMarcasModelosFormat);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }*/

  openDialogCrearMarcaModelo(isMarca: Boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.minHeight = '15vh'
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.data = [this.listaMarcas, isMarca];
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    this.dialog.open(CrearMarcaModeloDialog, dialogConfig);
  }

  openDialogMostrarMarcaModelo(modelo: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.minHeight = '15vh'
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.data = [modelo, this.listaMarcas];
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    this.dialog.open(MostrarMarcaModeloDialog, dialogConfig);
  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogBorrarMarcaModelo(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "borrar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.delete(id);
      }
    })
  }

  delete(id: number): void {
    this.marcaService.delete(id).subscribe({
      next: (data) => {
        this._notificationService.success("Operación realizada con éxito", "Los datos han sido borrados correctamente", 3000)
        this.reloadToMenuOption();
      }
    })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 21 } });
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
// Crear entrada de marca y/o modelo

@Component({
  selector: "crear-marca-modelo-dialog",
  templateUrl: "../crear-marca-modelo/crear-marca-modelo-dialog.html",
  styleUrls: ['../crear-marca-modelo/crear-marca-modelo-dialog.css']
})

export class CrearMarcaModeloDialog implements OnInit {
  constructor(
    protected marcaService: MarcaService,
    public servicioGeneral: ServicioGeneral,
    public dialogRef: MatDialogRef<CrearMarcaModeloDialog>,
    private router: Router,
    public dialog: MatDialog,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],
  ) { }

  ngOnInit(): void {
  }

  vehiculoMarca: IMarca;
  vehiculoModelo: IModelo;
  isSubmitted: Boolean = false;
  editar: Boolean = false;
  isMarca = this.dialogData[1];
  listaMarcas: IMarca[] = this.dialogData[0];

  form = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    marca: new FormControl(1, Validators.required),
  })

  submit() {

    if (this.isMarca) {
      this.vehiculoMarca = {
        nombre: this.form.value.nombre,
      }

      this.marcaService.createMarca(this.vehiculoMarca).subscribe({
        next: (data) => {

          if (data['message'].status == "608") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
            this.dialogRef.close();
            this.isSubmitted = false;
            this.reloadToMenuOption();
          } else {
            this.isSubmitted = true;
            let message = data['message'];
            this._notificationService.error("La operación no se ha podido realizar", message, 3000);

          }
        }
      })
    }

    else {
      this.vehiculoModelo = {
        nombre: this.form.value.nombre,
        marca: {
          id: this.form.value.marca,
        }
      }

      this.marcaService.create(this.vehiculoModelo).subscribe({
        next: (data) => {
          if (data['message'].status == "608") {
            this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
            this.dialogRef.close();
            this.isSubmitted = false;
            this.reloadToMenuOption();
          }
          else {
            this.isSubmitted = true;
            let message = data['message'];
            this._notificationService.error("La operación no se ha podido realizar", message, 3000);

          }

        }
      })

    }
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 21 } });
  }

  convertDate(date: string): string {
    if (date != null) {
      let fecha = new Date(date);
      let annio = fecha.getFullYear();
      let dia = fecha.getDate().toString().padStart(2, '0') //Añádir el 0 a la izquierda;
      let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      return (dia + '-' + mes + '-' + annio);
    }
    else return null
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------//
// Mostrar y editar la entrada de un modelo

@Component({
  templateUrl: "../crear-marca-modelo/crear-marca-modelo-dialog.html",
  styleUrls: ['../crear-marca-modelo/crear-marca-modelo-dialog.css']
})

export class MostrarMarcaModeloDialog implements OnInit {
  constructor(
    protected marcaService: MarcaService,
    public servicioGeneral: ServicioGeneral,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MostrarMarcaModeloDialog>,
    private router: Router,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) { }

  vehiculoModeloUpdate: IModelo;
  vehiculoMarcaUpdate: IMarca;
  isSubmitted: Boolean = false;
  editar: Boolean = true; //Para cambiar el título de 'nuevo modelo' a 'editar modelo'
  isMarca = null;
  listaMarcas = this.dataDialog[1];
  marcaModelo = this.dataDialog[0]

  ngOnInit() {
  }

  form = new FormGroup({
    nombre: new FormControl(this.marcaModelo.nombre, Validators.required),
    marca: new FormControl(this.marcaModelo.marca.id, Validators.required)
  })

  submit() {

    this.vehiculoModeloUpdate = {
      id: this.marcaModelo.id,
      nombre: this.form.value.nombre,
      marca: {
        id: this.form.value.marca
      }
    }

    this.marcaService.update(this.vehiculoModeloUpdate).subscribe({
      next: (data) => {

        if (data.body['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
          this.dialogRef.close();
          this.isSubmitted = false;
          this.reloadToMenuOption();
        }
        else {
          this.isSubmitted = true;
          let message = data.body['message'];
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);
        }
      }

    })
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(
      result => {
        if (result.result == 'Confirmar') {
          this.submit();
        }
      })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(
      result => {
        if (result.result == 'descartar') {
          this.dialogRef.close()
        }
      })
  }

  // Convrsión de fechas para poder mostrar en el front y enviar al back
  convertDate(date: string, modo: Boolean): string {

    if (date != null) {
      if (modo == true) { //Para convertir el resultante del input date al objeto conductor y al back
        let fecha = new Date(date);
        let annio = fecha.getFullYear();
        let dia = fecha.getDate().toString().padStart(2, '0') //Añádir el 0 a la izquierda;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        return (dia + '-' + mes + '-' + annio);
      }
      else { //Para convertir el date del back y del objeto conductor al formato del input date
        let fecha = date.split("-");
        let annio = fecha[2];
        let dia = fecha[0];
        let mes = fecha[1];
        return (annio + '-' + mes + '-' + dia)
      }
    }
    else return null;
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 21 } });
  }
}

function ordenarMarcasModelos(listado) {
  return listado?.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
}
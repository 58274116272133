<div class="crear-editar-form">
    <h1 class="titulo-formulario" *ngIf="!data.isForUpdate">Crear una nueva recaudación</h1>
    <h1 class="titulo-formulario" *ngIf="data.isForUpdate">Edita una recaudación</h1>

    <!-- ------------------------------- Apartado datos ----------------------- -->
    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="row mt-2">
        <!-- <div class="form-group col-sm-4" *ngIf="conductorPorcentaje?.tipoSueldo?.id == 1">
            <label>Porcentaje</label>
            <input disabled type="text" class="form-control" placeholder="{{conductorPorcentaje?.porcentaje}}">
        </div>
        <div class="form-group col-sm-4" *ngIf="conductorPorcentaje?.tipoSueldo?.id == 2">
            <label>Salario fijo</label>
            <input disabled type="text" class="form-control" placeholder="{{conductorPorcentaje?.salario_fijo}}">
        </div>
        <div class="form-group col-sm-4" *ngIf="conductorPorcentaje?.tipoSueldo?.id == 3">
            <label>Nomina</label>
            <input disabled type="text" class="form-control" placeholder="{{conductorPorcentaje?.nomina}}">
        </div> -->

        <div class="form-group col-sm-4" *ngIf="sueldoConductor">
            <label>{{sueldoConductor?.tipoSueldo?.nombre}}</label>
            <input disabled type="text" class="form-control" placeholder="{{sueldoConductor?.cantidad}}">
        </div> 

        <div class="form-group col-sm-4">
            <label>Día de libranza conductor</label>
            <input disabled type="text" class="form-control" placeholder="{{dia_libranza}}">
        </div>
        <div class="form-group col-sm-4">
            <label>Fecha alta</label>
            <input [disabled]="(!isUserAdmin || data.isForUpdate) ? true : false" type="date" class="form-control"
                [ngModel]="fechaAlta | date:'yyyy-MM-dd'" (change)="getValue($event); validateDiaLibranza();">
        </div>
        <div class="form-group col-sm-4">
            <label>Licencia<span style="color: red;">*</span></label>
            <select [disabled]="(!isUserAdmin || !isValidDiaLibranza) ? true : false" class="form-control form-select"
                [(ngModel)]="selectedIdLicencia" name="licencia" (change)="getConductores($event);">
                <option [value]=null>Selecciona una licencia</option>
                <option *ngFor="let licencia of licencias" [value]="licencia.id">
                    {{licencia.numero_licencia}}
                </option>
            </select>
        </div>
        <div class="form-group col-sm-4" *ngIf="selectedIdLicencia != null">
            <label>Conductor<span style="color: red;">*</span></label>
            <select [disabled]="(!isUserAdmin || !isValidDiaLibranza) ? true : false" class="form-control form-select"
                [(ngModel)]="selectedIdConductor" (change)="getPorcentajeOrSalario($event);" name="conductor">
                <option [value]=null>Selecciona un conductor</option>
                <option *ngFor="let conductor of conductores" [value]="conductor.id">
                    {{conductor.nombre}} {{conductor.apellidos}}
                </option>
            </select>
        </div>
    </div>

    <div class="row mb-4">
        <div class="form-group col">
            <div class="form-check form-check-inline">
                <input [(ngModel)]='no_computa' [checked]="no_computa"
                    [disabled]="(!isUserAdmin || !isValidDiaLibranza) ? true : false" class="form-check-input"
                    type="checkbox" (change)="setValuesNoComputa()">
                <label class="form-check-label" for="hasFiltroCombustible">No computa</label>
            </div>
        </div>

    </div>

    <!-- ------------------------------- Apartado kilometraje ----------------------- -->
    <h2 class="divisiones-formularios h2-margin">Kilometraje</h2>
    <hr>

    <div class="row mt-2 mb-4">
        <div class="form-group col-sm-4">
            <label>Kilómetros entrada</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.kilometraje.km_entrada">
        </div>
        <div class="form-group col-sm-4">
            <label>Kilómetros salida</label>
            <input *ngIf="!no_computa" [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                type="number" class="form-control" [(ngModel)]="recaudacionToSave.kilometraje.km_salida">
            <input *ngIf="no_computa" [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                type="number" class="form-control" placeholder="{{recaudacionToSave.kilometraje.km_entrada}}"
                [(ngModel)]="recaudacionToSave.kilometraje.km_salida">
        </div>
        <div class="form-group col-sm-4">
            <label>Kilómetros totales</label>
            <input *ngIf="!no_computa" disabled type="number" class="form-control"
                placeholder="{{((recaudacionToSave.kilometraje?.km_salida ?? 0) - (recaudacionToSave.kilometraje?.km_entrada ?? 0))  | numberFormat}}">
            <input *ngIf="no_computa" disabled type="number" class="form-control"
                placeholder="{{recaudacionToSave.kilometraje.km_totales  | numberFormat}}">
        </div>
    </div>

    <!-- ------------------------------- Apartado recaudación ----------------------- -->
    <h2 class="divisiones-formularios h2-margin">Recaudación</h2>
    <hr>

    <div class="row mt-2">
        <div class="form-group col-sm-4">
            <label>Taxímetro entrada</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.taximetro_entrada">
        </div>
        <div class="form-group col-sm-4">
            <label>Taxímetro salida</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.taximetro_salida">
        </div>
        <div class="form-group col-sm-4">
            <label>Retención</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.retencion">
        </div>
    </div>

    <!-- <div class="row mt-2 mb-4">
        <div class="form-group col-sm-4">
            <label>Retención</label>
            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                [(ngModel)]="recaudacionToSave.retencion">
        </div>
        <div class="form-group col-sm-4">
            <label>Visa (TPV)</label>
            <input [disabled]="!isUserAdmin" type="number" class="form-control" [(ngModel)]="recaudacionToSave.visa">
        </div>
        <div class="form-group col-sm-4">
            <label>Incidencias</label>
            <input [disabled]="!isUserAdmin" type="number" class="form-control"
                [(ngModel)]="recaudacionToSave.incidencias">
        </div>
    </div> -->

    <div class="row">
        <div class="form-group col-sm-4">
            <label>Incidencias</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.incidencias">
        </div>
        <div class="form-group col-sm-4">
            <label>Vales</label>
            <div class="form-group col px-0">
                <div *ngFor="let vale of valesList; let i = index" class="row mb-2">

                    <div class="col-sm-6 pr-1">
                        <select [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                            class="form-control form-select" [(ngModel)]="vale.tipo" #name="ngModel" name="tipo{{i}}">
                            <option [value]=null>Selecciona una opción</option>
                            <option *ngFor="let tipoVales of tipoValesList" [value]="tipoVales.id">
                                {{tipoVales.nombre}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-6 pl-1 input-group">
                        <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                            class="form-control" type="number" [(ngModel)]="vale.importe" #name="ngModel"
                            name="importe{{i}}" (change)="getSum()">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                                [disabled]="(valesList.length > 1 && isUserAdmin) ? '' : 'disabled'"
                                (click)="removeVales(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>

                    <!--<button (click)="addVales()" class="btn btnAddDelete" [disabled]="!isUserAdmin"><mat-icon
                            class="align-middle mr-2">add</mat-icon></button>
                    <button (click)="removeVales(i)" class="btn btnAddDelete"
                        [disabled]="valesList.length > 1 && isUserAdmin ? '' : 'disabled'"><mat-icon
                            class="align-middle mr-2">delete</mat-icon></button>-->
                </div>
                <div class="form-group">
                    <button [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                        type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"
                        (click)="addVales()"><mat-icon>add</mat-icon> Añadir
                        vale</button>
                </div>
            </div>


        </div>

        <div class="form-group col-sm-4">
            <label>Tarjeta</label>
            <div class="form-group col px-0">
                <div *ngFor="let tarjeta of tarjetasList; let i = index" class="row mb-2">
                    <div class="col pr-1">
                        <select [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                            class="form-control form-select" [(ngModel)]="tarjeta.tipo" #name="ngModel"
                            name="tipo{{i}}">
                            <option *ngFor="let tipoTarjeta of tipoTarjetasList" [ngValue]="tipoTarjeta.id">
                                {{tipoTarjeta.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col pl-1 input-group">
                        <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                            class="form-control" type="number" [(ngModel)]="tarjeta.importe" #name="ngModel"
                            name="importe{{i}}" (change)="getSumTarjetas()">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                                [disabled]="(tarjetasList.length > 1 && isUserAdmin) ? '' : 'disabled'"
                                (click)="removeTarjeta(i)"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>

                    <!--<button (click)="addTarjeta()" class="btn btnAddDelete" [disabled]="!isUserAdmin"><mat-icon
                            class="align-middle mr-2">add</mat-icon></button>
                    <button (click)="removeTarjeta(i)" class="btn btnAddDelete"
                        [disabled]="tarjetasList.length > 1 && isUserAdmin ? '' : 'disabled'"><mat-icon
                            class="align-middle mr-2">delete</mat-icon></button>-->
                </div>
                <div class="form-group">
                    <button [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                        type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"
                        (click)="addTarjeta()"><mat-icon>add</mat-icon> Añadir
                        tarjeta</button>
                </div>
            </div>

        </div>
    </div>

    <div class="row mb-4">
        <div class="form-group col-sm-4">
            <label>Total incidencias</label>
            <input disabled type="number" class="form-control"
                placeholder="{{recaudacionToSave?.incidencias | numberFormat}}">
        </div>
        <div class="form-group col-sm-4">
            <label>Total vales</label>
            <input disabled type="number" class="form-control" placeholder="{{sum | numberFormat}}">
        </div>
        <div class="form-group col-sm-4">
            <label>Total tarjeta</label>
            <input disabled type="number" class="form-control" placeholder="{{sumTarjetas | numberFormat}}">
        </div>

    </div>

    <!-- ------------------------------- Apartado informe ----------------------- -->
    <h2 class="divisiones-formularios h2-margin">Informe</h2>
    <hr>

    <div class="row mt-2 mb-4">
        <div class="form-group col-sm-3">
            <label>Importe bruto</label>
            <input disabled type="number" class="form-control" placeholder="{{calcularImporteBruto() | numberFormat}}">

        </div>
        <div class="form-group col-sm-3">
            <label>Importe conductor</label>
            <input disabled type="number" class="form-control"
                placeholder="{{ calcularImporteConductor() | numberFormat}}">
        </div>
        <div class="form-group col-sm-3">
            <label>Importe propietario</label>
            <input disabled type="number" class="form-control"
                placeholder="{{calcularImportePropietario() | numberFormat}}">
        </div>
        <div class="form-group col-sm-3">
            <label>Efectivo</label>
            <input disabled type="number" class="form-control" placeholder="{{calcularEfectivo()}}">
        </div>
    </div>


    <div class="row mt-2 mb-4">
        <div class="form-group col-sm-3">
            <!-- Antiguo total novotaxi -->
            <label>Diferencia a conductor</label>
            <input disabled type="number" class="form-control"
                placeholder="{{calcularDiferenciaConductor() | numberFormat}}">
        </div>
        <div class="form-group col-sm-3">
            <label>Total propietario</label>
            <input disabled type="number" class="form-control"
                placeholder="{{(((this.recaudacionToSave.taximetro_salida ?? 0) - (this.recaudacionToSave.taximetro_entrada ?? 0)) - (this.recaudacionToSave?.incidencias ?? 0)) * ((100 - (this.porcentaje ?? 0)) / 100) | numberFormat}}">
        </div>
        <div class="form-group col-sm-3">
            <label>Entrega efectivo</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.operacion_conductor.efectivo" placeholder="0">
        </div>
        <div class="form-group col-sm-3">
            <label>Préstamo</label>
            <input [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false" type="number"
                class="form-control" [(ngModel)]="recaudacionToSave.operacion_conductor.prestamo" placeholder="0">
        </div>
    </div>

    <!-- ------------------------------- Apartado observaciones ----------------------- -->
    <h2 class="divisiones-formularios h2-margin">Observaciones</h2>
    <hr>

    <div class="row">
        <div class="form-group col">
            <textarea [disabled]="(!isUserAdmin || no_computa || !isValidDiaLibranza) ? true : false"
                class="form-control" rows="3" [(ngModel)]="recaudacionToSave.observaciones"></textarea>
        </div>
    </div>

    <div id="container" class="d-flex justify-content-end pt-3">
        <button [disabled]="(!hasLicencias || !isValidDiaLibranza) ? true : false" class="btn btn-success"
            (click)="openDialogConfirm()">Guardar</button>
        <button class="btn btn-secondary ml-3" (click)="confirmCancelOperation()">Cerrar</button>
    </div>

</div>
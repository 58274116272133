import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPresent } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';
import { IConductor } from '../conductor.model';
import { Licencia } from 'src/app/model/Emisora';


export type PartialUpdateConductor = Partial<IConductor> & Pick<IConductor, 'id'>;

type RestOf<T extends IConductor> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion' | 'fechaVencimientoBTP'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
  fechaVencimientoBTP?: string | null;
};

export type RestConductor = RestOf<IConductor>;


export type PartialUpdateRestConductor = RestOf<PartialUpdateConductor>;

export type EntityResponseType = HttpResponse<IConductor>;
export type EntityArrayResponseType = HttpResponse<IConductor[]>;

@Injectable({
  providedIn: 'root'
})
export class ConductorService {

  protected resourceUrl = `${environment.API_URL}/conductor`;
  API_URL: string = environment.API_URL;

  constructor(protected http: HttpClient) { }

  create(conductor: IConductor) {
    return this.http.post(`${this.resourceUrl}/`, conductor);
  }

  update(conductor: IConductor) {
    return this.http
      .post<RestConductor>(this.resourceUrl, conductor, { observe: 'response' })
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<RestConductor>(`${this.resourceUrl}/${id}`, { observe: 'response' })
  }

  query(req?: any) {
    const options = {};
    return this.http
      .get<RestConductor[]>(this.resourceUrl, {})
  }

  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
  // }

  delete(id, fecha) {
    const url = `${this.resourceUrl}/delete/${id}`;
    return this.http.post(url, fecha);
  }

  getConductorIdentifier(conductor: Pick<IConductor, 'id'>): number {
    return conductor.id;
  }

  compareConductor(o1: Pick<IConductor, 'id'> | null, o2: Pick<IConductor, 'id'> | null): boolean {
    return o1 && o2 ? this.getConductorIdentifier(o1) === this.getConductorIdentifier(o2) : o1 === o2;
  }

  getHistorico(id): Observable<EntityResponseType> {
    return this.http
      .get<RestConductor>(`${this.resourceUrl}/getHistorico/${id}`, { observe: 'response' })
  }

  getLicenciaConductorHistorico(id): Observable<EntityResponseType> {
    return this.http
      .get<RestConductor>(`${this.API_URL}/conductorLicenciaHistorico/getHistorico/${id}`, { observe: 'response' })
  }

  getConductorByLicencia(id: number) {
    return this.http.get(`${this.API_URL}/licencia/listConductores/${id}`)
  }

  getLicenciasByConductor(id) {
    return this.http.get(`${this.resourceUrl}/licencias/${id}`);
  }

  saveCondcutorVehiculo(map) {
    return this.http.post(`${this.API_URL}/conductor/saveCondcutorVehiculo`, map, {
    });
  }

  rollback(licencia: Licencia) {
    return this.http.post(`${this.API_URL}/propietario/rollback`, licencia)
  }

  addConductorToCollectionIfMissing<Type extends Pick<IConductor, 'id'>>(
    conductorCollection: Type[],
    ...conductorsToCheck: (Type | null | undefined)[]
  ): Type[] {
    const conductors: Type[] = conductorsToCheck.filter(isPresent);
    if (conductors.length > 0) {
      const conductorCollectionIdentifiers = conductorCollection.map(conductorItem => this.getConductorIdentifier(conductorItem)!);
      const conductorsToAdd = conductors.filter(conductorItem => {
        const conductorIdentifier = this.getConductorIdentifier(conductorItem);
        if (conductorCollectionIdentifiers.includes(conductorIdentifier)) {
          return false;
        }
        conductorCollectionIdentifiers.push(conductorIdentifier);
        return true;
      });
      return [...conductorsToAdd, ...conductorCollection];
    }
    return conductorCollection;
  }

  protected convertDateFromClient<T extends IConductor | PartialUpdateConductor>(conductor: T): RestOf<T> {
    return {
      ...conductor,
      fechaAlta: conductor.fechaAlta?.toString() ?? null,
      fechaBaja: conductor.fechaBaja ?? null,
      fechaModificacion: conductor.fechaModificacion ?? null,
      fechaVencimientoBTP: conductor.fechaVencimientoBTP?.toString() ?? null,
    };
  }

  //Metodo que actualiza conductor desde la vista de gestion de conductor
  updateOnlyConductor(conductor: IConductor) {
    const copy = this.convertDateFromClient(conductor);
    return this.http
      .post<RestConductor>(`${this.resourceUrl}/updateConductor`, copy, { observe: 'response' })
  }

  saveTurno(turno) {
    return this.http.post(`${this.API_URL}/turnos/save`, turno, {
    });
  }

  getAllConductores(request?) {
    return this.http
      .post(`${this.resourceUrl}/list`, request)
  }

  getDetails() {
    return this.http
      .get(`${this.resourceUrl}/getDetails`)
  }

  saveDocuments(id, documents) {
    return this.http.post(`${this.resourceUrl}/saveDocuments/${id}`, documents);
  }

  getDocuments(id) {
    return this.http.get(`${this.resourceUrl}/getDocuments/${id}`);
  }

  updateDocuments(id, documents) {    
    return this.http.post(`${this.resourceUrl}/updateDocuments/${id}`, documents);
  }

  getTiposSueldo() {
    return this.http.get(`${this.resourceUrl}/getTiposSueldo`);
  }
}

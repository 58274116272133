import { Component, Input, OnInit } from '@angular/core';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { GastosIngresosService } from 'src/app/_services/novotaxi/gastos-ingresos.service';
import { GastosProveedoresService } from 'src/app/_services/novotaxi/gastos/gastos-proveedores.service';
import { PrestamosService } from 'src/app/_services/novotaxi/prestamos/prestamos.service';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { LiquidacionService } from 'src/app/_services/novotaxi/retenciones-liquidaciones/retenciones-liquidaciones.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';

@Component({
  selector: 'app-card-detalles-caja',
  templateUrl: './card-detalles-caja.component.html',
  styleUrls: ['./card-detalles-caja.component.css']
})

export class CardDetallesCajaComponent implements OnInit {

  @Input() searchMap;
  @Input() tipoDetalle: string;
  @Input() fecha: string;
  @Input() gestionCajaList;

  usuarios: Usuario[] = [];
  userName;
  infoGastos: Map<String, any> = new Map();
  infoLiquidaciones: Map<String, any> = new Map();
  infoPrestamos: Map<String, any> = new Map();
  infoRecaudacion: Map<String, any> = new Map();
  infoGastosProveedores: Map<String, any> = new Map();
  infoIngresosCaja: Map<String, any> = new Map();
  infoGestionCaja: Map<String, any> = new Map();
  datosDetalles: Map<String, Map<String, any>> = new Map([
    ['infoGastos', this.infoGastos],
    ['infoLiquidaciones', this.infoLiquidaciones],
    ['infoPrestamos', this.infoPrestamos],
    ['infoRecaudacion', this.infoRecaudacion],
    ['infoGastosProveedores', this.infoGastosProveedores],
    ['infoIngresosCaja', this.infoIngresosCaja],
    ['infoGestionCaja', this.infoGestionCaja],
  ]);

  lista: [] = [];

  constructor(
    private _tokenStorageService: TokenStorageService,
    private _gastosIngresosService: GastosIngresosService,
    private _prestamosService: PrestamosService,
    private _liquidacionesService: LiquidacionService,
    private _recaudacionService: RecaudacionService,
    private _gastosProveedoresService: GastosProveedoresService,
  ) { }

  ngOnInit(): void {
    this.lista = this.gestionCajaList;
    this.getUserName();
    this.callTotalMethods();
  }

  callTotalMethods() {
    
    switch (this.tipoDetalle) {
      case 'gastosIngresos':
        this.getToalesGastosIngresos(this.searchMap);
        break;
      case 'prestamos':
        this.getTotalesPrestamos(this.searchMap);
        break;
      case 'liquidaciones':
        this.getTotalesLiquidacionesRetenciones(this.searchMap);
        break;
      case 'recaudacion':
        this.getTotalesRecaudacion(this.searchMap);
        break;
      case 'gastosProveedores':
        this.getTotalesGastosProveedores(this.searchMap);
        break;
      case 'ingresosCaja':
        this.getTotalIngresosCaja(this.fecha);
        break;
      case 'gestionCaja':
        this.getTotalGestionCaja(this.gestionCajaList);
        break;
      default:
        break;
    }
  }

  convertDate(date): String {    
    var fechaGeneracion = new Intl.DateTimeFormat("es-ES", { dateStyle: "full", }).format(new Date(date));
    var fechaString = fechaGeneracion.charAt(0).toUpperCase() + fechaGeneracion.substring(1);
    return fechaString;
  }

  getTimeMoment(): String {
    var timeNow = new Date(Date.now());
    var hora = timeNow.getHours();
    if (hora >= 19 && hora < 6) {
      return 'Buenas noches';
    }
    else if (hora >= 6 && hora < 13) {
      return 'Buenos días';
    }
    else return 'Buenas tardes';
  }

  getUserName() {
    this.userName = this._tokenStorageService.getUser();
  }

  getToalesGastosIngresos(searchMap) {
    this._gastosIngresosService.getTotalesGastosIngresos(searchMap).subscribe((result) => {
      if (result['status'] == 616) {
        let totalGastos = result['GastosIngresos']['total_gastos'] ?? 0;
        let totalIngresos = result['GastosIngresos']['total_ingresos'] ?? 0;
        this.infoGastos.set('totalGastos', totalGastos);
        this.infoGastos.set('totalIngresos', totalIngresos);
        this.infoGastos.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
        this.infoGastos.set('fechaFin', this.convertDate(searchMap.fechaFinal));
        let total = totalIngresos - totalGastos;
        this.infoGastos.set('total', total.toString());
        this.infoGastos.set("licencia", result['licencia']);
      }
    })
  }

  getTotalesPrestamos(searchMap) {
    this._prestamosService.geTTotalesPrestamos(searchMap).subscribe((result) => {
      if (result['status'] == 616) {
        this.infoPrestamos.set('totalPrestamos', result['totalPrestamos']['importe'] ?? 0)
        this.infoPrestamos.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
        this.infoPrestamos.set('fechaFin', this.convertDate(searchMap.fechaFinal));
        this.infoPrestamos.set("conductor", result['conductor']);
      } else {
        this.infoPrestamos.set('totalPrestamos', '0');
        this.infoPrestamos.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
        this.infoPrestamos.set('fechaFin', this.convertDate(searchMap.fechaFinal));
        this.infoPrestamos.set("conductor", result['conductor']);
      }
    })
  }

  getTotalesLiquidacionesRetenciones(searchMap) {
    this._liquidacionesService.getTotalLiquidacionesRetenciones(searchMap).subscribe((result) => {
      if (result['status'] == 616) {
        this.infoLiquidaciones.set('totalLiquidaciones', result['totales']['total_liquidaciones'] ?? 0);
        this.infoLiquidaciones.set('totalRetenciones', result['totales']['total_retenciones'] ?? 0);
        this.infoLiquidaciones.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
        this.infoLiquidaciones.set('fechaFin', this.convertDate(searchMap.fechaFinal));
        this.infoLiquidaciones.set("conductor", result['conductor']);
      }
    })
  }

  getTotalesRecaudacion(searchMap) {
    this._recaudacionService.getTotalRecaudacion(searchMap).subscribe((result) => {
      if (result['status'] == 616) {
        this.infoRecaudacion.set('totalImporteBruto', result['totales']['importe_bruto'] ?? 0);
        this.infoRecaudacion.set('totalImporteConductor', result['totales']['importe_conductor'] ?? 0);
        this.infoRecaudacion.set('totalInvertaxi', result['totales']['total_invertaxi'] ?? 0);
        this.infoRecaudacion.set('totalPropietario', result['totales']['importe_propietario'] ?? 0);
        this.infoRecaudacion.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
        this.infoRecaudacion.set('fechaFin', this.convertDate(searchMap.fechaFinal));
      }
    })
  }

  getTotalesGastosProveedores(searchMap) {
    this._gastosProveedoresService.getTotalGastosProveedores(searchMap).subscribe((result) => {
      this.infoGastosProveedores.set('totalImporte', result['totales'] ? result['totales']['importe'] : 0);
      this.infoGastosProveedores.set("licencia", result['licencia']);
      this.infoGastosProveedores.set('fechaInicio', this.convertDate(searchMap.fechaInicio));
      this.infoGastosProveedores.set('fechaFin', this.convertDate(searchMap.fechaFinal));
    })
  }

  getTotalIngresosCaja(fecha) {
    this._gastosIngresosService.getTotalIngresosCaja(fecha).subscribe((result) => {
      if (result['status'] == 616) {
        this.infoIngresosCaja.set('total', result['total'] ?? 0)
        this.infoIngresosCaja.set('fecha', this.convertDate(fecha));
      } else {
        this.infoIngresosCaja.set('total', 0);
        this.infoIngresosCaja.set('fecha', this.convertDate(fecha));
      }
    })
  }

  getTotalGestionCaja(lista?, idUsuario?) {

    let totalRecaudacion: number = 0;
    let totalGastos: number = 0;
    let totalIngresos: number = 0;
    let totalPrestamos: number = 0;

    setTimeout(() => {

      lista.forEach(element => {

        var y: number = element?.importe_bruto;
        element.importe_bruto ? totalRecaudacion += y : null;

        var i: number = + element?.importe_total_ingresos;
        element.importe_total_ingresos ? totalIngresos += i : null;

        var g: number = + element?.importe_total_gastos;
        element.importe_total_gastos ? totalGastos += g : null;

        var p: number = + element?.importe_total_prestamos;
        element.importe_total_prestamos ? totalPrestamos += p : null;
      });

      this.infoGestionCaja.set('totalRecaudacion', totalRecaudacion);
      this.infoGestionCaja.set('totalIngresos', totalIngresos);
      this.infoGestionCaja.set('totalGastos', totalGastos);
      this.infoGestionCaja.set('totalPrestamos', totalPrestamos);
      this.infoGestionCaja.set('fecha', this.convertDate(this.fecha));
    }, 1000);
  }
}

<div class="contenedor px-4 pt-3 pb-4 mb-5 mt-4" [hidden]="detallesVisible">

    <div class="d-flex flex-row mt-4 ml-4">
        <h5 *ngIf="tipoDetalle == 'gastosIngresos'">Detalles gastos e ingresos</h5>
        <h5 *ngIf="tipoDetalle == 'liquidaciones'">Detalles retenciones y liquidaciones</h5>
        <h5 *ngIf="tipoDetalle == 'prestamos'">Detalles préstamos</h5>
        <h5 *ngIf="tipoDetalle == 'recaudacion'">Detalles recaudación</h5>
        <h5 *ngIf="tipoDetalle == 'gastosProveedores'">Detalles gastos a proveedores</h5>
        <h5 *ngIf="tipoDetalle == 'ingresosCaja'">Detalles ingresos a caja</h5>
        <h5 *ngIf="tipoDetalle == 'gestionCaja'">Detalles gestión de caja</h5>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'gastosIngresos'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total Gastos</span>
            <span class="descDetalle">{{infoGastosIngresos.get('totalGastos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Total Ingresos</span>
            <span class="descDetalle">{{infoGastosIngresos.get('totalIngresos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Total Gastos/Ingresos</span>
            <span class="descDetalle">{{infoGastosIngresos.get('total') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia" *ngIf="infoGastosIngresos.get('licencia')">
            <span>Licencia</span>
            <span class="descDetalle">{{infoGastosIngresos.get('licencia').numero_licencia}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoGastosIngresos.get('fechaInicio')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Hasta</span>
            <span class="descDetalle">{{infoGastosIngresos.get('fechaFin')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'liquidaciones'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total liquidaciones</span>
            <span class="descDetalle">{{infoLiquidaciones.get('totalLiquidaciones') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Total retenciones</span>
            <span class="descDetalle">{{infoLiquidaciones.get('totalRetenciones') ?? 0 | numberFormat}}</span>
        </div>
        <!-- <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Total</span>
            <span class="descDetalle">{{infoGastosIngresos.get('total')}}</span>
        </div> -->
        <div class="d-flex flex-column px-5 detallesLicencia" *ngIf="infoLiquidaciones.get('conductor')">
            <span>Conductor</span>
            <span class="descDetalle">{{infoLiquidaciones.get('conductor').nombre}}
                {{infoLiquidaciones.get('conductor').apellidos}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoLiquidaciones.get('fechaInicio')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Hasta</span>
            <span class="descDetalle">{{infoLiquidaciones.get('fechaFin')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'prestamos'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total Prestamos</span>
            <span class="descDetalle">{{infoPrestamos.get('totalPrestamos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia" *ngIf="infoPrestamos.get('conductor')">
            <span>Conductor</span>
            <span class="descDetalle">{{infoPrestamos.get('conductor').nombre}}
                {{infoPrestamos.get('conductor').apellidos}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoPrestamos.get('fechaInicio')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Hasta</span>
            <span class="descDetalle">{{infoPrestamos.get('fechaFin')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'recaudacion'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Importe Bruto</span>
            <span class="descDetalle">{{infoRecaudacion.get('totalImporteBruto') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Importe conductor</span>
            <span class="descDetalle">{{infoRecaudacion.get('totalImporteConductor') ?? 0 |numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Diferencia a conductor</span>
            <span class="descDetalle">{{infoRecaudacion.get('totalInvertaxi') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Total propietario</span>
            <span class="descDetalle">{{infoRecaudacion.get('totalPropietario') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoRecaudacion.get('fechaInicio')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Hasta</span>
            <span class="descDetalle">{{infoRecaudacion.get('fechaFin')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'gastosProveedores'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total gastos a proveedores</span>
            <span class="descDetalle">{{infoGastosProveedores.get('totalImporte') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia" *ngIf="infoGastosProveedores.get('licencia')">
            <span>Licencia</span>
            <span class="descDetalle">{{infoGastosProveedores.get('licencia').numero_licencia}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoGastosProveedores.get('fechaInicio')}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Hasta</span>
            <span class="descDetalle">{{infoGastosProveedores.get('fechaFin')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'ingresosCaja'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total ingresos</span>
            <span class="descDetalle">{{infoIngresosCaja.get('total') ?? 0 |numberFormat}}</span>
        </div>
        <div class="d-flex flex-column pl-5 detallesLicencia">
            <span>Fecha</span>
            <span class="descDetalle">{{infoIngresosCaja.get('fecha')}}</span>
        </div>
    </div>

    <div class="d-flex flex-row mt-4 ml-4" *ngIf="tipoDetalle == 'gestionCaja'">
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total recaudación</span>
            <span class="descDetalle">{{infoGestionCaja.get('totalRecaudacion') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total gastos</span>
            <span class="descDetalle">{{infoGestionCaja.get('totalGastos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total ingresos</span>
            <span class="descDetalle">{{infoGestionCaja.get('totalIngresos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column pr-5 detallesLicencia">
            <span>Total préstamos</span>
            <span class="descDetalle">{{infoGestionCaja.get('totalPrestamos') ?? 0 | numberFormat}}</span>
        </div>
        <div class="d-flex flex-column px-5 detallesLicencia">
            <span>Desde</span>
            <span class="descDetalle">{{infoGestionCaja.get('fecha')}}</span>
        </div>
    </div>
</div>
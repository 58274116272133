import { IEmisora, NewEmisora } from '../emisora.model';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';

export type PartialUpdateGestoria = Partial<IEmisora> & Pick<IEmisora, 'id'>;

type RestOf<T extends IEmisora | NewEmisora> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestEmisora = RestOf<IEmisora>;

export type NewRestEmisora = RestOf<NewEmisora>;

export type EntityResponseType = HttpResponse<IEmisora>;
export type EntityArrayResponseType = HttpResponse<IEmisora[]>;

@Injectable({
    providedIn: 'root'
  })
  export class EmisoraService {
  
    protected resourceUrl = `${environment.API_URL}/emisora`;
  
    constructor(protected http: HttpClient) { }

    list(){
        return this.http.get<any>(`${this.resourceUrl}/list`, {})
    }
  
    create(emisora: IEmisora){
      return this.http.post(`${this.resourceUrl}/`, emisora);
    }
  
    update(companiaSeguros: IEmisora): Observable<EntityResponseType> {
      return this.http
        .post<RestEmisora>(this.resourceUrl, companiaSeguros, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<RestEmisora>(`${this.resourceUrl}/${id}`, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    query(req?: any): Observable<EntityArrayResponseType> {
      const options = {};
      return this.http
        .get<RestEmisora[]>(this.resourceUrl, { params: options, observe: 'response' })
        .pipe(map(res => this.convertResponseArrayFromServer(res)));
    }

    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
    }

    protected convertDateFromClient<T extends IEmisora | NewEmisora | PartialUpdateGestoria>(emisora: T): any {
        return {
          ...emisora,
          fechaAlta: emisora.fechaAlta?.toString() ?? null,
          fechaBaja: emisora.fechaBaja?.toISOString() ?? null,
          fechaModificacion: emisora.fechaModificacion?.toISOString() ?? null,
        };
      }
    
      protected convertDateFromServer(RestEmisora: RestEmisora): IEmisora {
        let fechaAlta = RestEmisora.fechaAlta?.split("-");
        let fechaBaja = RestEmisora.fechaBaja?.split("-");
        let fechaModificacion = RestEmisora.fechaModificacion?.split("-");
        return {
          ...RestEmisora,
          fechaBaja: RestEmisora.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
          fechaModificacion: RestEmisora.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
          
        };
      }


    protected convertResponseFromServer(res: HttpResponse<RestEmisora>): HttpResponse<IEmisora> {
        return res.clone({
          body: res.body ? this.convertDateFromServer(res.body) : null,
        });
      }
    
      protected convertResponseArrayFromServer(res: HttpResponse<RestEmisora[]>): HttpResponse<IEmisora[]> {
        return res.clone({
          body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
        });
      }
}
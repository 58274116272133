<!-- Componente gráficos -->
<div class="contenedor pl-4 pr-5 pt-3 pb-4 mb-5 mt-4" [hidden]="graficosVisible">
    <mat-tab-group animationDuration="1000ms">
        <mat-tab label="Gráficos licencias" class="mb-4">
            <div class="d-flex flex-row mt-4 ml-4 justify-content-between">
                <div class="w-50 border p-2" style="height: 150px">gráfico 1</div>
                <div class="w-50 border p-2" style="height: 150px">gráfico 2</div>
            
            </div>
        </mat-tab>
        <mat-tab label="Gráficos propietarios" class="mb-4"> Content 2 </mat-tab>
        <mat-tab label="Gráficos conductores" class="mb-4"> Content 3 </mat-tab>
        <mat-tab label="Gráficos vehículos" class="mb-4"> Content 3 </mat-tab>
      </mat-tab-group>
    
</div>

<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Compañías de seguro</h1>
    <div>
        <button class="btn btn-outline-primary align-middle mr-3" (click)="generarInforme()">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar informe</span> 
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)" [hidden]="mostrarFormulario">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva compañía de seguros</span> 
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span> 
        </button>
    </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<!-- <app-card-detalles-graficos></app-card-detalles-graficos> -->
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">
    <div class="mostrarFormulario" *ngIf="mostrarFormulario">
        <app-crear-compania-seguros (mostrarFormulario)="setMostrarFormulario($event)" [companiaUpdate]="companiaUpdate" [editar]="editar" [listMunicipios]="listMunicipios"></app-crear-compania-seguros>
    </div>
    <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
    </div>
    <div class="table_container" id="scroll">
    
        <table mat-table [dataSource]="dataSource" matSort
        class="table align-middle mb-0 bg-white">
    
            <ng-container matColumnDef="nombre" sticky> 
                <th [hidden]="!listColumns['Nombre']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Nombre</th>
                <td [hidden]="!listColumns['Nombre']" mat-cell *matCellDef="let element">{{element.nombre}}</td>
            </ng-container>
    
            <ng-container matColumnDef="cif">
                <th [hidden]="!listColumns['CIF']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">CIF</th>
                <td [hidden]="!listColumns['CIF']" mat-cell *matCellDef="let element">{{element.cif}}</td>
            </ng-container>
    
            <ng-container matColumnDef="mail">
                <th [hidden]="!listColumns['Correo electrónico']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Correo electrónico</th>
                <td [hidden]="!listColumns['Correo electrónico']" mat-cell *matCellDef="let element" class="py-2">{{element.mail[0]}}</td>
            </ng-container>
    
            <ng-container matColumnDef="telefono">
                <th [hidden]="!listColumns['Teléfono']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Teléfono</th>
                <td [hidden]="!listColumns['Teléfono']" mat-cell *matCellDef="let element" class="py-2">{{element.telefono[0]}}</td>
            </ng-container>
    
            <ng-container matColumnDef="domicilio">
                <th [hidden]="!listColumns['Domicilio']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Domicilio</th>
                <td [hidden]="!listColumns['Domicilio']" mat-cell *matCellDef="let element">{{element.domicilio}}</td>
            </ng-container>
    
            <ng-container matColumnDef="municipio">
                <th [hidden]="!listColumns['Municipio']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Municipio</th>
                <td [hidden]="!listColumns['Municipio']" mat-cell *matCellDef="let element">{{element.municipio}}</td>
            </ng-container>
    
            <ng-container matColumnDef="provincia.nombre">
                <th [hidden]="!listColumns['Provincia']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Provincia</th>
                <td [hidden]="!listColumns['Provincia']" mat-cell *matCellDef="let element">{{element.provincia?.nombre}}</td>
            </ng-container>
    
            <ng-container matColumnDef="codigoPostal">
                <th [hidden]="!listColumns['Código postal']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Código postal</th>
                <td [hidden]="!listColumns['Código postal']" mat-cell *matCellDef="let element">{{element.codigoPostal}}</td>
            </ng-container>
    
            <ng-container matColumnDef="fechaAlta">
                <th [hidden]="!listColumns['Fecha alta']" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by licencia" class="header">Fecha alta</th>
                <td [hidden]="!listColumns['Fecha alta']" mat-cell *matCellDef="let element">{{element.fechaAlta}}</td>
            </ng-container>
       
            <ng-container matColumnDef="acciones" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="header"></th>
                <td mat-cell *matCellDef="let element" class="mat-column acciones">
                    <div class="buttons-flex-container">
                    <a class="eye-button activated" (click)="setMostrarFormulario(true, element, true)"></a>
                    <a class ="delete-button activated" (click)="openDialogBorrarCompaniaSeguros(element.id)"></a>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
     
    </div> 
    
    <!-- <mat-paginator class="mt-3" [length]="100"
                  [pageSize]="25"
                  [pageSizeOptions]="[5, 10, 25, 50, 100]"
                  showFirstLastButtons>
    </mat-paginator> -->
    
    <mat-paginator class="mt-3" #paginator
                (page)="handlePageEvent($event)" 
                [length]="length" 
                [pageSize]="pageSize"
                [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="pageIndex"
                aria-label="Select page" >
    </mat-paginator>
    </div>
</div>


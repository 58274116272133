import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { MustMatch } from '../_helpers/must-match.validator';
import { ServicioGeneral } from '../_services/servicio-general.service';
import { HttpResponse } from '@angular/common/http';
import { NotificationService } from '../_services/notification.service';

enum TokenStatus {
  Validating,
  Valid,
  Invalid
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any = {
    email: null,
    newPassword: null,

  };
  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  isSuccessful = false;
  loading = false;
  identificador: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private serviciogeneral: ServicioGeneral,
    private _notificacionService: NotificationService

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });

    const token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.obtenerIdentificador();

  }

  obtenerIdentificador() {
    this.route.params
      .subscribe(params => {
        this.identificador = params['identificador'];
      }
      );
  }

  get f() { return this.form.controls; }

  onSubmit(): any {
    const { email, newPassword } = this.form;

    this.loading = true;
    this.serviciogeneral.resetPassword(this.identificador, email, newPassword).subscribe({
      next: data => {
        this.isSuccessful = true;
        this._notificacionService.success("Hecho", "Contraseña reseteada. Acceda con los nuevos datos de acceso.", 5000);
        this.router.navigate(['/login']);
      },
      error: err => {
        this.loading = false;
        this._notificacionService.error("Error", err.error.message, 5000);
      }
    });



  }

}

import { DatePipe } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPresent } from 'ngx-cookie';
import { catchError, map, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';
import { IGastosIngresos, NewGastosIngresos } from '../../model/novotaxi/gastos-ingresos/gastos-ingresos.model';

export type PartialUpdateGastosIngresos = Partial<IGastosIngresos> & Pick<IGastosIngresos, 'id'>;

type RestOf<T extends IGastosIngresos | NewGastosIngresos> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestGastosIngresos = RestOf<IGastosIngresos>;

export type NewRestGastosIngresos = RestOf<NewGastosIngresos>;

export type PartialUpdateRestGastosIngresos = RestOf<PartialUpdateGastosIngresos>;

export type EntityResponseType = HttpResponse<IGastosIngresos>;
export type EntityArrayResponseType = HttpResponse<IGastosIngresos[]>;

@Injectable({
  providedIn: 'root'
})
export class GastosIngresosService {

  private resourceUrl: string = `${environment.API_URL}/gastosingresos`;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }


  create(gastosIngresos: IGastosIngresos): Observable<EntityResponseType> {
    // const copy = this.convertDateFromClient(gastosIngresos);

    return this.http
      .post<RestGastosIngresos>(this.resourceUrl, gastosIngresos, { observe: 'response' })
      .pipe(map(res => this.convertResponseFromServer(res)));
  }

  update(gastosIngresos: IGastosIngresos): Observable<EntityResponseType> {
    // const copy = this.convertDateFromClient(gastosIngresos);
    return this.http
      .post<RestGastosIngresos>(this.resourceUrl, gastosIngresos, { observe: 'response' })
      .pipe(map(res => this.convertResponseFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<RestGastosIngresos>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map(res => this.convertResponseFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = {};
    return this.http
      .get<RestGastosIngresos[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map(res => this.convertResponseArrayFromServer(res)));
  }

  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
  // }

  delete(id) {
    return this.http
      .get(`${this.resourceUrl}/delete/${id}`);
  }

  searchGastosIngresos(mapa) {
    return this.http
      .post(`${this.resourceUrl}/search`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }

  searchGastosIngresosByDay(day) {
    return this.http
      .post(`${this.resourceUrl}/searchByDay`, day)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveAllGastosIngresos(listGastosIngresos) {
    // this.convertDateVehiculos(listGastosIngresos);
    // let lista = this.convertDateVehiculos(listGastosIngresos);

    return this.http
      // .post(`${this.resourceUrl}/saveAll`, this.convertDateVehiculos(listGastosIngresos), {})
      .post(`${this.resourceUrl}/saveAll`, listGastosIngresos, {})
  }

  getAllTiposGasto() {
    return this.http
      .get(`${this.resourceUrl}/listTipoGasto`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getallTiposIngreso() {
    return this.http
      .get(`${this.resourceUrl}/listTipoIngreso`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getGastosIngresosIdentifier(gastosIngresos: Pick<IGastosIngresos, 'id'>): number {
    return gastosIngresos.id;
  }

  compareGastosIngresos(o1: Pick<IGastosIngresos, 'id'> | null, o2: Pick<IGastosIngresos, 'id'> | null): boolean {
    return o1 && o2 ? this.getGastosIngresosIdentifier(o1) === this.getGastosIngresosIdentifier(o2) : o1 === o2;
  }

  getTotalesGastosIngresos(map) {
    return this.http
      .post(`${this.resourceUrl}/sum`, map)
  }
  
  getTotalIngresosCaja(day) {
    return this.http
      .post(`${this.resourceUrl}/getTotalIngresosByDay`, day)
  }

  getGastosIngresos(request?) {
    return this.http
      .post(`${this.resourceUrl}/list`, request)
  }

  getImportePropietarioTotal(mapa) {
    return this.http
      .post(`${this.resourceUrl}/getImportePropietario`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }


  addGastosIngresosToCollectionIfMissing<Type extends Pick<IGastosIngresos, 'id'>>(
    gastosIngresosCollection: Type[],
    ...gastosIngresosToCheck: (Type | null | undefined)[]
  ): Type[] {
    const gastosIngresos: Type[] = gastosIngresosToCheck.filter(isPresent);
    if (gastosIngresos.length > 0) {
      const gastosIngresosCollectionIdentifiers = gastosIngresosCollection.map(
        gastosIngresosItem => this.getGastosIngresosIdentifier(gastosIngresosItem)!
      );
      const gastosIngresosToAdd = gastosIngresos.filter(gastosIngresosItem => {
        const gastosIngresosIdentifier = this.getGastosIngresosIdentifier(gastosIngresosItem);
        if (gastosIngresosCollectionIdentifiers.includes(gastosIngresosIdentifier)) {
          return false;
        }
        gastosIngresosCollectionIdentifiers.push(gastosIngresosIdentifier);
        return true;
      });
      return [...gastosIngresosToAdd, ...gastosIngresosCollection];
    }
    return gastosIngresosCollection;
  }

  protected convertDateFromClient<T extends IGastosIngresos | NewGastosIngresos | PartialUpdateGastosIngresos>(
    gastosIngresos: T
  ): RestOf<T> {
    return {
      ...gastosIngresos,
      // fechaAlta: gastosIngresos.fechaAlta?.toISOString() ?? null,
      fechaBaja: gastosIngresos.fechaBaja?.toISOString() ?? null,
      fechaModificacion: gastosIngresos.fechaModificacion?.toISOString() ?? null,
    };
  }

  protected convertDateFromServer(restGastosIngresos: RestGastosIngresos): IGastosIngresos {
    let fechaAlta = restGastosIngresos.fechaAlta?.split("-");
    let fechaBaja = restGastosIngresos.fechaBaja?.split("-");
    let fechaModificacion = restGastosIngresos.fechaModificacion?.split("-");
    return {
      ...restGastosIngresos,
      // fechaAlta: restGastosIngresos.fechaAlta ? new Date(+fechaAlta[2], +fechaAlta[1] - 1, +fechaAlta[0]) : undefined,
      fechaBaja: restGastosIngresos.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
      fechaModificacion: restGastosIngresos.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
    };
  }

  protected convertResponseFromServer(res: HttpResponse<RestGastosIngresos>): HttpResponse<IGastosIngresos> {
    return res.clone({
      body: res.body ? this.convertDateFromServer(res.body) : null,
    });
  }

  protected convertResponseArrayFromServer(res: HttpResponse<RestGastosIngresos[]>): HttpResponse<IGastosIngresos[]> {
    return res.clone({
      body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
    });
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  convertDateVehiculos(listGastosIngresos: IGastosIngresos[]) {

    listGastosIngresos.forEach(element => {

      element.licencia.vehiculo.forEach(element => {
        const splitDate = element.fechaAlta.split('-');

        const date = new Date(
          Number(splitDate[2]),   // year
          Number(splitDate[1]) - 1, // month
          Number(splitDate[0])    // day
        );
        element.fechaAlta = this.datePipe.transform(date, 'yyyy-MM-dd')
      })
    });
    return listGastosIngresos;
  }
}

<h1 class="titulo-formulario mt-2" *ngIf="!editar">Crea un nuevo vehículo</h1>
<!-- <h1 class="titulo-formulario" *ngIf="editar">Edita un vehículo</h1> -->
<!--    <form [formGroup]="form" (ngSubmit)="editar == false ? submit() : openDialogConfirmarUpdate()"> -->
<!-- <form [formGroup]="form" (ngSubmit)="!editar ? submit() : openDialogConfirmarUpdate()"> -->
<form [formGroup]="form" (ngSubmit)="openDialogConfirmarUpdate()">
  <app-historico-vehiculo *ngIf="mostrarHistorico" (mostrarHistorico)="setMostrarHistorico($event)"
    [vehiculoHistorico]="vehiculoHistorico"
    [vehiculoLicenciaHistorico]="vehiculoLicenciaHistorico"></app-historico-vehiculo>

  <div *ngIf="!mostrarHistorico">
    <div class="d-flex flex-row justify-content-between align-items-start mt-2" *ngIf="editar">
      <h1 class="titulo-formulario">Edita un vehículo</h1>

      <button class="btn btn-primary btn-sm align-middle" (click)="setMostrarHistorico(true)">
        <mat-icon class="align-middle mr-2">access_time</mat-icon><span class="align-middle">Mostrar
          histórico</span>
      </button>
    </div>
    <div>
      <!--Datos vehículo-->
      <h2 class="divisiones-formularios">Datos vehículo</h2>
      <hr>
      <div>
        <form formGroupName="datos">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="marca">Licencia</label>
              <select id="licencia" class="form-control form-select" formControlName="licencia">
                <option value="-1">Selecciona una licencia</option>
                <option [value]="0">Desvincular licencia</option>
                <option [value]="licencia.id" *ngFor="let licencia of listaLicencias">
                  {{licencia.numero_licencia}}: {{licencia.propietario?.nombre}}
                  {{licencia.propietario?.apellidos}}</option>
              </select>
            </div>
            <div class="form-group col-sm-4">
              <label for="tipo">Tipo de vehículo</label>
              <select id="tipo" class="form-control form-select" formControlName="tipo">
                <option [value]=0>Seleccione tipo de vehículo</option>
                <option [value]="tipo.id" *ngFor="let tipo of tipoVehiculo">
                  {{tipo.nombre}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label for="matricula">Matrícula <span style="color: red;">*</span></label>
              <input class="form-control" type="text" id="matricula" formControlName="matricula"
                pattern="(^[0-9]{4}[A-Za-z]{3}$)">
              <small class="text-danger"
                *ngIf="isSubmitted && form.controls.datos.get('matricula').hasError('required')">El campo es
                obligatorio</small>
              <small class="text-danger"
                *ngIf="isSubmitted && form.controls.datos.get('matricula').hasError('pattern')">El valor
                introducido no es una matrícula válida</small>
            </div>
            <div class="form-group col">
              <label for="marca">Marca <span style="color: red;">*</span></label>
              <select id="marca" class="form-control form-select" formControlName="marca"
                (change)="getAllModeloMarca($event)">
                <option [value]=0>Selecciona una marca</option>
                <option [value]="marca.id" *ngFor="let marca of listaMarcas">{{marca.nombre}}</option>
              </select>
              <small class="text-danger" *ngIf="isSubmitted && form.controls.datos.get('marca').hasError('pattern')">El
                campo es
                obligatorio</small>
            </div>
            <div class="form-group col">
              <label for="modelo">Modelo <span style="color: red;">*</span></label>
              <select id="modelo" class="form-control form-select" formControlName="modelo">
                <option [value]=0>Selecciona un modelo</option>
                <option [value]="modelo.id" *ngFor="let modelo of listaModeloMarca">{{modelo.nombre}}</option>
              </select>
              <small class="text-danger" *ngIf="isSubmitted && form.controls.datos.get('modelo').hasError('pattern')">El
                campo es
                obligatorio</small>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--Seguro-->
    <div>

      <h2 class="divisiones-formularios mt-4">Seguro</h2>
      <hr>
      <div>
        <form formGroupName="seguro">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="ciaSeguros">Compañía de seguros / Broker</label>
              <select id="ciaSeguros" class="form-control form-select" formControlName="ciaSeguros"
                (change)="getTelefonoCiaSeguros($event)">
                <option [value]=0>Selecciona una compañía</option>
                <option [value]="cia.id" *ngFor="let cia of listaCiaSeguros">{{cia.nombre}}</option>
              </select>
              <small class="text-danger"
                *ngIf="isSubmitted && form.controls.seguro.get('ciaSeguros').hasError('required')">El campo es
                obligatorio</small>
            </div>
            <div class="form-group col">
              <label for="mutuaPatronal">Mutua patronal</label>
              <input class="form-control" type="text" id="mutuaPatronal" formControlName="mutuaPadronal">
            </div>
            <div class="form-group col">
              <label for="tlfContacto">Teléfono</label>
              <input class="form-control" type="text" id="tlfContacto" formControlName="tlfContacto" readonly>
            </div>
            <div class="form-group col">
              <label for="fechaVencimiento">Vencimiento</label>
              <input class="form-control" type="date" id="fechaVencimiento" formControlName="fechaVencimiento">
              <small class="text-danger"
                *ngIf="isSubmitted && form.controls.seguro.get('fechaVencimiento').hasError('required')">El
                campo es obligatorio</small>
            </div>
          </div>
        </form>
      </div>

    </div>
    <!--Fila de cards 2. Taxímetro y Mantenimiento-->
    <div>

      <!--Datos taxímetro-->
      <h2 class="divisiones-formularios mt-4">Taximetro</h2>
      <hr>
      <div>
        <form formGroupName="taximetro">
          <div class="row">
            <div class="form-group col-sm-3">
              <label for="casaTaximetro">Casa taxímetro</label>
              <input class="form-control" type="text" id="casaTaximetro" formControlName="casaTaximetro">
            </div>
            <div class="form-group col-sm-3">
              <label for="claseTaximetro">Clase taxímetro</label>
              <input class="form-control" type="text" id="claseTaximetro" formControlName="claseTaximetro">
            </div>
            <div class="form-group col-sm-3">
              <label for="marca">Cambio tarifa</label>
              <input class="form-control" type="date" id="marca" formControlName="fechaUltimoCambioDeTarifa">
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-sm-6">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="ultimaTarifaAplicada"
                  formControlName="ultimaTarifaAplicada">
                <label class="form-check-label ml-2" for="ultimaTarifaAplicada">Ejecutado último cambio de
                  tarifa</label>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>

    <div>

      <!--Datos mantenimiento-->
      <h2 class="divisiones-formularios mt-4">Mantenimiento</h2>
      <hr>
      <div>
        <form formGroupName="mantenimiento">
          <div class="row">
            <div class="form-group col">
              <label for="kmEntrada">Km. entrada</label>
              <input class="form-control" type="number" id="kmEntrada" formControlName="kmEntrada">
            </div>
            <div class="form-group col">
              <label for="fechaUltimaDistribucion">Última distribución</label>
              <input class="form-control" type="date" id="fechaUltimaDistribucion"
                formControlName="fechaUltimaDistribucion">
            </div>
            <div class="form-group col">
              <label for="fechaProximaDistribucion">Próxima distribución</label>
              <input class="form-control" type="date" id="fechaProximaDistribucion"
                formControlName="fechaProximaDistribucion">
            </div>
            <div class="form-group col">
              <label for="kmUltimoCambioDeAceite">Km. último aceite</label>
              <input class="form-control" type="number" id="kmUltimoCambioDeAceite"
                formControlName="kmUltimoCambioDeAceite">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-3">
              <label for="kmProximoCambioDeAceite">Km. próximo aceite</label>
              <input class="form-control" type="number" id="kmProximoCambioDeAceite"
                formControlName="kmProximoCambioDeAceite">
            </div>
            <div class="form-group col-sm-3">
              <label for="tipoAceite">Tipo de aceite</label>
              <select id="tipoAceite" class="form-control form-select" formControlName="tipoAceite">
                <option value="0">Selecciona un aceite</option>
                <option value="ORGANICO">Orgánico</option>
                <option value="SINTETICO">Sintético</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroAire" formControlName="hasFiltroAire">
                <label class="form-check-label" for="hasFiltroAire">Filtro aire</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroHabitaculo"
                  formControlName="hasFiltroHabitaculo">
                <label class="form-check-label" for="hasFiltroHabitaculo">Filtro habitáculo</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="hasFiltroCombustible"
                  formControlName="hasFiltroCombustible">
                <label class="form-check-label" for="hasFiltroCombustible">Filtro combustible</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="boletinMeteorologico"
                  formControlName="boletinMeteorologico">
                <label class="form-check-label" for="boletinMeteorologico">Boletín meteorológico</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="extintor" formControlName="extintor">
                <label class="form-check-label" for="extintor">Extintor</label>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="tarjetaTransporte"
                  formControlName="tarjetaTransporte">
                <label class="form-check-label" for="tarjetaTransporte">Tarjeta transporte</label>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>

    <!--Fila de cards 2. Revisión y Revista-->
    <div>

      <!--Datos vehículo-->
      <h2 class="divisiones-formularios mt-4">Revisión</h2>
      <hr>

      <form formGroupName="revision">
        <div class="row">
          <div class="form-group col-sm-3">
            <label for="ultimaRevision">Última revisión</label>
            <input class="form-control" type="date" id="ultimaRevision" formControlName="ultimaRevision">
          </div>
          <div class="form-group col-sm-3">
            <label for="vencimientoTaximetro">Vencimiento taxímetro</label>
            <input class="form-control" type="date" id="vencimientoTaximetro" formControlName="vencimientoTaximetro">
          </div>
          <div class="form-group col-sm-3">
            <label for="vencimientoITV">Vencimiento ITV</label>
            <input class="form-control" type="date" id="vencimientoITV" formControlName="vencimientoITV">
            <small class="text-danger"
              *ngIf="isSubmitted && form.controls.revision.get('vencimientoITV').hasError('required')">El
              campo es obligatorio</small>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label for="descripcionVehiculoITV">Observaciones ITV</label>
            <textarea class="form-control" id="descripcionVehiculoITV" rows="3"
              formControlName="descripcionVehiculoITV"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div>

      <!--Datos mantenimiento-->
      <h2 class="divisiones-formularios mt-4">Revista</h2>
      <hr>
      <div>
        <form [formGroup]="form">
          <div class="row">
            <div class="form-group col-sm-3">
              <label for="fechaRevista">Revista</label>
              <input [disabled]="vehiculoUpdate?.fechaBaja != null" class="form-control" type="date" id="fechaRevista">
            </div>
            <div class="form-group col">
              <label for="comentario">Comentario</label>
              <textarea [disabled]="vehiculoUpdate?.fechaBaja != null" class="form-control" id="comentario"
                rows="3"></textarea>
            </div>
          </div>
        </form>
      </div>

    </div>

    <div>

      <!--Datos revista-->
      <h2 class="divisiones-formularios mt-4">Observaciones</h2>
      <hr>
      <div>
        <form [formGroup]="form">
          <div class="row">
            <div class="form-group col">
              <label for="observacionesVehiculo">Observaciones</label>
              <textarea class="form-control" id="observacionesVehiculo" rows="3"
                formControlName="observacionesVehiculo"></textarea>
            </div>
          </div>
        </form>
      </div>

    </div>
    <div id="container" class="d-flex justify-content-end pt-3">
      <button *ngIf="vehiculoUpdate?.fechaBaja == null" class="btn btn-success mx-3" type="submit">Guardar</button>
      <button *ngIf="vehiculoUpdate?.fechaBaja != null" class="btn btn-warning mx-3" type="button"
        (click)="openDialogConfirmarActivate()">Activar</button>
      <button class="btn btn-secondary" type="button"
        (click)="form.dirty ? openDialogConfirmarUnsaved() : resetearForm()">Cerrar</button>
    </div>
  </div>
</form>
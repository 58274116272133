<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Proveedores</h1>
    <div>
        <button class="btn btn-outline-primary align-middle mr-3" (click)="generarInforme()">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar informe</span>
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true, false)"
            [hidden]="mostrarFormulario">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo proveedor</span>
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)"
            [hidden]="!mostrarFormulario">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
        </button>
    </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<!-- <app-card-detalles-graficos></app-card-detalles-graficos> -->
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4" id="form-container-scroll">

    <div class="mostrarFormulario" *ngIf="data.mostrarFormulario">
        <app-create-update-proveedor (mostrarFormulario)="getValueForm($event)"
            [data]="data"></app-create-update-proveedor>
    </div>

    <div class="mostrarTabla" [hidden]="mostrarFormulario">
        <div class="d-flex flex-row justify-content-end align-items-center mb-4">
            <app-multi-select-dropdown [listColumns]="listColumns">
            </app-multi-select-dropdown>
        </div>

        <div class="table_container">
            <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white">

                <ng-container matColumnDef="proveedor.nombre">
                    <th [hidden]="!listColumns['Nombre proveedor']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Nombre proveedor</th>
                    <td [hidden]="!listColumns['Nombre proveedor']" mat-cell *matCellDef="let element">
                        {{element.proveedor?.nombre}}</td>
                </ng-container>

                <ng-container matColumnDef="proveedor.cif">
                    <th [hidden]="!listColumns['CIF proveedor']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">
                        CIF proveedor</th>
                    <td [hidden]="!listColumns['CIF proveedor']" mat-cell *matCellDef="let element">
                        {{element.proveedor?.cif}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="concepto">
                    <th [hidden]="!listColumns['Concepto']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Concepto</th>
                    <td [hidden]="!listColumns['Concepto']" mat-cell *matCellDef="let element">{{element?.concepto}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="iva">
                    <th [hidden]="!listColumns['IVA']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">
                        IVA</th>
                    <td [hidden]="!listColumns['IVA']" mat-cell *matCellDef="let element">{{element?.iva}}</td>
                </ng-container>

                <ng-container matColumnDef="activo">
                    <th mat-header-cell [hidden]="!listColumns['Activo']" *matHeaderCellDef mat-sort-header
                      sortActionDescription="Sort by activo" class="header">Activo
                    </th>
                    <td mat-cell [hidden]="!listColumns['Activo']" *matCellDef="let element">
                      <p *ngIf="element.proveedor.fechaBaja == null; else no" class="m-0"><mat-icon>check</mat-icon></p>
                      <ng-template #no><mat-icon>close</mat-icon></ng-template>
                    </td>
                  </ng-container>

                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="header"></th>
                    <td mat-cell *matCellDef="let element" class="mat-column acciones">
                        <div class="buttons-flex-container">
                            <a class="eye-button activated" (click)="setMostrarFormulario(true, true, element)"></a>
                            <a class="delete-button activated" (click)="openDialogDelete(element)"></a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>

        <mat-paginator class="mt-3" #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
            aria-label="Select page">
        </mat-paginator>
    </div>
</div>
<div>
  <form [formGroup]="form" (ngSubmit)="!editar ? submit() : openDialogConfirmarUpdate()" class="crear-editar-form">

    <h1 class="titulo-formulario" *ngIf="!editar">Crea una nueva gestoría</h1>
    <h1 class="titulo-formulario" *ngIf="editar">Edita una gestoría</h1>

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="row mt-2 mb-4">
      <div class="form-group col-sm-4">
        <label for="nombre">Nombre <span style="color: red;">*</span></label>
        <input class="form-control" type="text" id="nombre" formControlName="nombre">
        <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es
          obligatorio</small>
      </div>
      <div class="form-group col-sm-4">
        <label for="cif">CIF <span style="color: red;">*</span></label>
        <input class="form-control" type="text" id="cif" formControlName="cif">
        <small class="text-danger" *ngIf="isSubmitted && form.get('cif').hasError('pattern')">El valor introducido no es
          un CIF válido</small>
      </div>
      <div *ngIf="editar == true" class="form-group col-sm-3">
        <label for="fechaAlta">Fecha de alta</label>
        <input class="form-control" type="text" id="fechaAlta" formControlName="fechaAlta" readonly
          formControlName="fechaAlta">
      </div>
    </div>

    <h2 class="divisiones-formularios">Información de contacto</h2>
    <hr>

    <div class="row mt-2 mb-4">
      <div class="form-group col-sm-4">
        <div class="form-group" formArrayName="email" *ngFor="let tel of emails.controls; let i = index">
          <label for="email">Correo electrónico<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
          <div class="input-group">
            <input class="form-control" type="tel" id="email" [formControlName]="i" />
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0" (click)="removeEmail(i)"><mat-icon>close</mat-icon></button>
            </div>
          </div>
          <small class="text-danger" *ngIf="isSubmitted && emails.controls[i].hasError('pattern')">El valor introducido
            no es un email válido</small>
        </div>
        <button *ngIf="expandedEmail != 3" type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"
          (click)="addEmail()"><mat-icon>add</mat-icon>Añadir email</button>
      </div>
      <div class="form-group col-sm-4">
        <div class="form-group" formArrayName="telefono" *ngFor="let tel of telefonos.controls; let i = index">
          <div>
            <label for="telefono">Teléfono<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
            <span class="input-group">
              <input class="form-control" type="tel" id="telefono" [formControlName]="i" />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary-input" *ngIf="i > 0"
                  (click)="removeTelefono(i)"><mat-icon>close</mat-icon></button>
              </div>
            </span>
            <small class="text-danger" *ngIf="isSubmitted && telefonos.controls[i].hasError('pattern')">El valor
              introducido no es un teléfono válido</small>
          </div>
        </div>
        <button *ngIf="expandedPhone != 3" type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center"
          (click)="addTelefono()"><mat-icon>add</mat-icon>Añadir teléfono</button>
      </div>

    </div>

    <h2 class="divisiones-formularios">Dirección</h2>
    <hr>

    <div class="row mt-2">
      <div class="form-group col-sm-4">
        <label for="domicilio">Domicilio</label>
        <input class="form-control" type="text" id="domicilio" formControlName="domicilio">
      </div>
    </div>
    <div class="row mb-4">
      <div class="form-group col-sm-4">
        <label for="provincia">Provincia</label>
        <select id="provincia" class="form-control form-select" formControlName="provincia"
          (change)="cargaMunicipios(form.value.provincia)">
          <option [value]=0>Selecciona una provincia</option>
          <option [value]="provincia.id" *ngFor="let provincia of listaProvincias">{{provincia.nombre}}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4">
        <label for="poblacion">Municipio</label>
        <select id="poblacion" class="form-control form-select" formControlName="municipio">
          <option [value]=0>Selecciona un municipio</option>
          <option [value]="municipio.nombre" *ngFor="let municipio of showMunicipiosList">{{municipio.nombre}}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4">
        <label for="codigoPostal">Código postal</label>
        <input class="form-control" type="text" maxlength="5" minlength="5" id="codigoPostal" formControlName="codigoPostal">
        <small class="text-danger" *ngIf="isSubmitted && form.get('codigoPostal').hasError('pattern')">El campo ha de
          contener 5 dígitos numéricos</small>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label for="observaciones">Observaciones</label>
        <textarea class="form-control" id="observaciones" rows="3" formControlName="observaciones"></textarea>
      </div>
    </div>

    <div id="container" class="d-flex justify-content-end pt-3">
      <button class="btn btn-success mx-3" type="submit" [disabled]="editar && !form.dirty">Guardar</button>
      <button class="btn btn-secondary" type="button"
        (click)="form.dirty ? openDialogConfirmarUnsaved() : resetearForm()">Cerrar</button>
    </div>
  </form>

  <!--<h1 class="divisiones-formularios">Datos</h1>
    <hr>

    <div class="row mt-2 mb-4">
        <div class="form-group col-sm-4">
            <label for="nombre">Nombre</label>
            <input class="form-control" type="text" id="nombre">
        </div>
        <div class="form-group col-sm-4">
            <label for="cif">CIF</label>
            <input class="form-control" type="text" id="cif">
        </div>
        <div class="form-group col-sm-4">
            <label for="fechaAlta">Fecha de alta</label>
            <input class="form-control" type="text" id="fechaAlta" readonly>
          </div>
    </div>

    <h1 class="divisiones-formularios">Información de contacto</h1>
    <hr>

    <div class="row">
        <div class="form-group col-sm-4">
            <label for="nombre">Nombre</label>
            <input class="form-control" type="text" id="nombre">
        </div>
        <div class="form-group col-sm-4">
            <label for="cif">CIF</label>
            <input class="form-control" type="text" id="cif">
        </div>
        <div class="form-group col-sm-4">
            <label for="fechaAlta">Fecha de alta</label>
            <input class="form-control" type="text" id="fechaAlta" readonly>
          </div>
    </div>


    <h1 class="divisiones-formularios">Dirección</h1>
    <hr>-->

</div>
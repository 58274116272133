import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Licencia } from "src/app/model/Emisora";
import { IGastosIngresos, Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { LicenciaServiece } from "src/app/_services/licenciaService.service";
import { NotificationService } from "src/app/_services/notification.service";
import { GastosIngresosService } from "src/app/_services/novotaxi/gastos-ingresos.service";
import { DatePipe } from "@angular/common";
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup } from "@angular/forms";
import { ConductorService } from "src/app/conductores/services/conductor.service";
import { IConductor } from "src/app/conductores/conductor.model";
import { VehiculoService } from "src/app/_services/vehiculo.service";
import { CardDetallesCajaComponent } from "../card-detalles-caja/card-detalles-caja.component";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { InformePDF } from "../../licencias/informe-licencia/informePDF";
import { ExcelExportService } from "src/app/_services/excel-export.service";
import { ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'app-gastos-ingresos',
    templateUrl: './gastos-ingresos.component.html',
    styleUrls: ['./gastos-ingresos.component.css']
})

export class GastosIngresosComponent implements OnInit {

    gastosIngresosList: IGastosIngresos[] = [];
    displayedColumns: string[] = ['fechaAlta', 'tipoGestion', 'importe', 'cantidadPropietario', 'cantidadConductor', 'concepto', 'tipo', 'numDocumento', 'licencia.numero_licencia', 'conductor.nombre', 'conductor.apellidos', 'usuario.nombre', 'acciones'];

    displayedSearchHeaders: string[] = ['fecha_buscador', 'tipoGestion_buscador', 'importe_buscador', 'cantidadPropietario_buscador', 'cantidadConductor_buscador', 'concepto_buscador', 'tipo_buscador', 'numDocumento_buscador', 'licencia_buscador',
        'conductor_nombre_buscador', 'conductor_apellido_buscador', 'usuario_buscador', 'acciones_buscador'];

    listColumns;
    dataSource = new MatTableDataSource(this.gastosIngresosList);

    licencias: Licencia[] = [];
    selectedValueLicencia: Licencia;
    fechainicio: string;
    fechafin: string;
    isSearch: boolean = false;
    searchMap = {
        fechaInicio: null,
        fechaFinal: null,
        idLicencia: null,
    };

    // -- Paginado
    length = this.gastosIngresosList.length;
    pageSize = 10;
    pageIndex = 0;
    pageSizeOptions = [5, 10, 25, 50, 100];
    showFirstLastButtons = true;

    // -- Variables optimización
    public filters = [];
    public staticFilters;
    public sorts = [];
    public totalElements = 0;

    // -- Variables para el control del usuario
    currentUser;
    userSaved: Usuario = null;

    // -- Variables filtrado select
    showDropDown = false;
    keys;
    allKeys;
    nameSearch: string;

    // -- Filtrado por columnas
    public searchForm: FormGroup;

    // -- Variables component actualizar/crear
    mostrarFormulario: Boolean = false;
    retencionLiquidacionUpdate: IGastosIngresos;
    editar: Boolean = false;
    tipoAccion: string;
    conductores: IConductor[] = []

    data: DataGastosIngresos = { mostrarFormulario: false, gastosIngresos: null, licencias: null, elementUpdate: null, isForUpdate: false }

    currentDate: Date = new Date();
    tipoDetalle: string = 'gastosIngresos';

    @Input() nombreMenu;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;

    @ViewChild(CardDetallesCajaComponent) child;

    constructor(
        private _gastosIngresosService: GastosIngresosService,
        private _licenciaService: LicenciaServiece,
        private _notificationService: NotificationService,
        private _conductorService: ConductorService,
        private _vehiculoService: VehiculoService,
        private _tokenStorageService: TokenStorageService,
        private _servicioGeneral: ServicioGeneral,
        public dialog: MatDialog,
        private datePipe: DatePipe,
        private informe: InformePDF,
        private _excelService: ExcelExportService,
    ) { }

    ngOnInit(): void {
        this.currentUser = this._tokenStorageService.getUser();
        this.getUser();
        this.getLicencias();
        this.pageIndex = 0;
        this.pageSize = 10;
        this.listColumns = {
            'Fecha': true,
            'G/I': true,
            'Importe': true,
            'Cantidad propietario': true,
            'Cantidad conductor': true,
            'Concepto': true,
            'Tipo': true,
            'Nº documento': true,
            'Licencia': true,
            'Nombre conductor': true,
            'Apellidos conductor': true,
            'Usuario': true,
        }

        this.fechainicio = this.datePipe.transform(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1), 'yyyy-MM-dd');
        this.fechafin = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

        this.searchMap.fechaInicio = this.fechainicio;
        this.searchMap.fechaFinal = this.fechafin;

        this.staticFilters = {
            key: 'fechaAlta',
            operator: "BETWEEN_DATES",
            field_type: 'DATE_BETWEEN',
            value: this.fechainicio.toString().split("-").reverse().join("-"),
            value_to: this.fechafin.toString().split("-").reverse().join("-"),
        };

        this.setRequestForSearch();
        //   this.searchGastosIngresos();
        this.searchFormInit();
    }

    generarInforme() {
        let nombre = 'Gastos/Ingresos';
        this.informe.crearPDF(this.dataSource.filteredData, nombre);
    }

    getUser() {
        this._servicioGeneral.getUserId(this.currentUser.id).subscribe((data) => {
            this.userSaved = data['Usuario'];
        })
    }

    handlePageEvent(event: PageEvent) {
        this.length = event.length;
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
    }

    // -- Sort
    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    )

    setMostrarFormulario(mode: Boolean, tipo?: string, element?: IGastosIngresos, isForUpdate?: Boolean) {

        if (element != null) {
            this._vehiculoService.getAllConductoresFromVehiculo(element.licencia.id).subscribe((data) => {
                this.mostrarFormulario = mode;
                this.conductores = data['conductores']
                this.data = { mostrarFormulario: mode, gastosIngresos: [], tipo: element != null ? element.tipoGestion : tipo, elementUpdate: element, isForUpdate: isForUpdate, licencias: this.licencias, conductores: this.conductores }
            })
        } else {
            this.mostrarFormulario = mode;
            this.data = { mostrarFormulario: mode, gastosIngresos: [], tipo: element != null ? element.tipoGestion : tipo, elementUpdate: element, isForUpdate: isForUpdate, licencias: this.licencias, conductores: this.conductores }
        }

        if (mode) {
            document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
        }
    }

    //Método en el que obtenemos el objeto guardado o actualizado en el formulario y se lo pasamos al dataSource
    getValueForm(event) {
        this.mostrarFormulario = event.mostrarFormulario;
        this.child.getToalesGastosIngresos(this.searchMap);
        this.setRequestForSearch();
    }

    getLicencias() {
        this._licenciaService.listLicencia().subscribe((data) => {
            this.licencias = data["licencias"];
            this.keys = this.licencias;
            this.allKeys = this.keys;
        })
    }

    isValidFieldsSearch() {
        if (this.fechainicio == undefined || this.fechafin == undefined /* || this.selectedValueLicencia == undefined */) {
            this._notificationService.warning("¡Atención!", "Debe rellenar las fechas para la búsqueda", 3000);
            return false;
        } else {
            if (this.fechainicio > this.fechafin) {
                this._notificationService.warning("¡Atención!", "Rango de fechas mal introducido", 3000);
                return false;
            }
            return true;
        }
    }

    validateFields(element) {
        if (element.tipo_gasto != undefined) {
            return element.tipo_gasto.nombre;
        } else if (element.tipo_ingreso != undefined) {
            return element.tipo_ingreso.nombre
        }
    }

    deleteGastoIngreso(element) {
        this._gastosIngresosService.delete(element.id).subscribe((result) => {
            if (result['status'] == '622') {
                this._notificationService.success('Hecho', 'Gasto/Ingreso eliminado con éxito', 3000);
                this.setRequestForSearch();
            }
        });
    }

    getConductoresByLicencia() {
        this._conductorService.getConductorByLicencia(this.selectedValueLicencia.id).subscribe((res) => {
            this.conductores = res['conductores'];
        });
    }

    formatDateForBE(dateString: string): string {
        if (dateString) {
            const splitDate = dateString.split('-');

            const date = new Date(
                Number(splitDate[2]),   // year
                Number(splitDate[1]) - 1, // month
                Number(splitDate[0])    // day
            );
            return this.datePipe.transform(date, 'yyyy-MM-dd')
        }
        return null;
    }

    async openDialogDelete(element) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25vw';
        dialogConfig.minWidth = '500px';
        dialogConfig.disableClose = true;
        dialogConfig.data = 'borrar'

        const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

        return dialogRef.afterClosed().subscribe(result => {
            if (result.result == 'Confirmar') {

                if (element.id != null) {
                    let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

                    if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && element.fechaAlta != currentDate) {
                        this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar un gasto/ingreso del día actual", 3000);
                    } else if (this.currentUser.rol.includes(1) && !this.currentUser.rol.includes(2) && !this.currentUser.rol.includes(3) && element.fechaAlta != currentDate) {
                        this._notificationService.info("INFO", "No tiene permisos, sólo puede eliminar un gasto/ingreso del día actual", 3000);
                    } else {
                        this.deleteGastoIngreso(element);
                    }
                }
            } else {
                null
            }
        })
    }

    // -- Select con filtrado
    focusOut() {
        this.nameSearch = "";
        this.keys = this.allKeys;
    }

    filterColumns(event) {
        this.nameSearch = event.target.value;
        if (this.nameSearch && this.nameSearch != "") {
            this.keys = this.allKeys.filter(key => key.numero_licencia.includes(this.nameSearch.toLowerCase()));
        } else {
            this.keys = this.allKeys;
        }
    }

    getSelectedValue(value: Licencia) {
        this.selectedValueLicencia = value;
        this.showDropDown = false;
    }
    // -- Fin select con filtrado


    // -- Inicio optimización
    searchFormInit() {
        this.searchForm = new FormGroup({
            fecha: new FormControl(''),
            tipoGestion: new FormControl(''),
            importe: new FormControl(''),
            cantidadPropietario: new FormControl(''),
            cantidadConductor: new FormControl(''),
            concepto: new FormControl(''),
            tipo: new FormControl(''),
            numDocumento: new FormControl(''),
            licencia: new FormControl(''),
            conductor: new FormControl(''),
            usuario: new FormControl(''),
        });
    }

    setRequestForSearch(request?) {
        let requestSearchFields;

        this.searchMap = {
            fechaInicio: this.fechainicio,
            fechaFinal: this.fechafin,
            idLicencia: this.selectedValueLicencia ? this.selectedValueLicencia.id : null,
        };

        if (this.isValidFieldsSearch) {
            if (this.selectedValueLicencia != null) {
                requestSearchFields = {
                    "filters": [{
                        key: 'fechaAlta',
                        operator: "BETWEEN_DATES",
                        field_type: 'DATE_BETWEEN',
                        value: this.fechainicio.toString().split("-").reverse().join("-"),
                        value_to: this.fechafin.toString().split("-").reverse().join("-"),
                    },
                    {
                        key: 'fechaBaja',
                        operator: "IS_NULL",
                        field_type: 'DATE',
                    },
                    {
                        key: 'licencia.id',
                        operator: "LIKE",
                        field_type: 'STRING',
                        value: this.selectedValueLicencia?.id,
                    },],
                    "sorts": this.sorts,
                    page: this.pageIndex,
                    size: this.pageSize,
                }
            } else if (this.selectedValueLicencia == null) {
                requestSearchFields = {
                    "filters": [{
                        key: 'fechaAlta',
                        operator: "BETWEEN_DATES",
                        field_type: 'DATE_BETWEEN',
                        value: this.fechainicio.toString().split("-").reverse().join("-"),
                        value_to: this.fechafin.toString().split("-").reverse().join("-"),
                    },
                    {
                        key: 'fechaBaja',
                        operator: "IS_NULL",
                        field_type: 'DATE',
                    },],
                    "sorts": this.sorts,
                    page: this.pageIndex,
                    size: this.pageSize,
                }
            }
        }

        this.searchGastosIngresos(requestSearchFields, request);
    }

    async searchGastosIngresos(requestSearchFields?, request?) {
        let filtersToSend = [];

        if (request) {
            if (requestSearchFields) {
                filtersToSend = [...request['filters'], ...requestSearchFields['filters']];
            } else {
                filtersToSend = [...request['filters']];
            }

        } else {
            filtersToSend = [...requestSearchFields['filters']];
        }

        let requestToSend = {
            "filters": filtersToSend,
            "sorts": this.sorts,
            page: this.pageIndex,
            size: this.pageSize,
        }

        await this._gastosIngresosService.getGastosIngresos(requestToSend).subscribe((data) => {
            this.gastosIngresosList = [];
            this.totalElements = 0;

            if (data['gastosIngresos']) {
                if (data['gastosIngresos']['content']) {
                    this.isSearch = true;
                    for (const gastosIngresosJSON of data['gastosIngresos']['content']) {
                        this.gastosIngresosList.push(new IGastosIngresos(gastosIngresosJSON));
                    }
                } else {
                    this._notificationService.info("INFO", "No se han encontrado datos", 3000);
                    this.isSearch = false;
                }
                this.totalElements = data['gastosIngresos']['totalElements'];
            }
            this.dataSource = new MatTableDataSource(this.gastosIngresosList);
        })
    }

    async applyFilter(event?, key?, type?) {
        let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];

        if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
            // this.paginator.firstPage();
            let value = event?.target?.value;
            this.doSearch(value, key, type);
        }
    }

    async doSearch(value, key, type) {


        if (!this.filters.find(x => x.key == key)) {
            let filter = {
                key: key,
                operator: "LIKE",
                field_type: type,
                value: value
            }

            if (type.toUpperCase() == 'TIPOS') {
                filter.operator = "TIPOS_GASTO_INGRESO"
                filter.field_type = "STRING"
            }

            if (type?.toUpperCase() == "DATE") {
                filter.operator = "BETWEEN"
            }

            if (type?.toUpperCase() == "IN") {
                filter.operator = "IN"
                filter.field_type = "STRING"
            }

            if (type?.toUpperCase() == "DATE_BETWEEN" || type?.toUpperCase() == "LOCALDATE_BETWEEN") {
                filter.operator = "BETWEEN_AUX"
            }

            if (type?.toUpperCase() == "CONTAINS_STRING") {
                filter.operator = "CONTAINS"
                filter.field_type = "STRING"
            }

            if (type?.toUpperCase() == "DOUBLE" || type?.toUpperCase() == "LONG" || type?.toUpperCase() == "BIG_DECIMAL" || type?.toUpperCase() == "INTEGER") {
                filter.operator = "LIKE"
                filter.field_type = "STRING"
            }

            if (type.toUpperCase() == 'ENUM_TIPO_GESTION') {
                filter.operator = "LIKE_TIPO_GESTION"
                filter.field_type = "STRING"
            }

            this.filters.push(filter)
        } else {
            this.filters.forEach(filter => {
                if (filter.key == key) {
                    if (!value || value == '') {
                        this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
                    } else {
                        filter.value = value
                    }
                }
            });
        }
        let request = {
            filters: this.filters,
            sorts: this.sorts,
            page: "0",
            size: this.paginator.pageSize,

        }

        this.paginator.firstPage();
        await this.setRequestForSearch(request);
    }

    async nextPage(event: PageEvent) {
        let request = {
            filters: this.filters,
            sorts: this.sorts,
            page: event.pageIndex.toString(),
            size: event.pageSize.toString(),
            // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
        };

        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        await this.setRequestForSearch(request);
    }

    async sortData(sort: Sort) {
        this.sorts = [];
        this.paginator.firstPage()
        let request = {
            filters: this.filters,
            page: "0",
            size: this.paginator.pageSize,
        }
        if (sort.direction) {
            let direction = sort.direction.toUpperCase()
            if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
                if (sort.direction.toUpperCase() == "ASC") {
                    direction = "CUSTOM_ASC"
                } else {
                    direction = "CUSTOM_DESC"
                }

            } else {
                if (sort.active == "tipo") {
                    this.sorts = [{
                        "key": 'tipo_gasto.nombre',
                        "direction": direction
                    },
                    {
                        "key": 'tipo_ingreso.nombre',
                        "direction": direction
                    }];

                } else {
                    this.sorts = [{
                        "key": sort.active,
                        "direction": direction
                    }];
                }

            }
            request['sorts'] = this.sorts;
        }

        await this.setRequestForSearch(request)
    }

    clearSearchInputs() {
        this.searchForm.reset();
        this.filters = [];
    }

    // -- Fin optimización

    // -- Exportación Excel
    formatDate(milliseconds) {
        const date = new Date(milliseconds);
        return date;
    }

    // Función para filtrar los campos no deseados y los valores de campos complejos
    filterFields(item) {
        const { id, fechaBaja, fechaModificacion, numDocumento, conductor, ...rest } = item;

        if (item.usuario) {
            rest.usuario = item.usuario.nombre;
        }

        if (item.licencia) {
            rest.licencia = item.licencia.numero_licencia;
        }

        if (item.tipo_gasto) {
            rest.tipo_gasto = item.tipo_gasto.nombre;
        }

        if (item.tipo_ingreso) {
            rest.tipo_ingreso = item.tipo_ingreso.nombre;
        }

        return rest;
    }

    generateExcel() {

        const data = this.dataSource.data.map((gastoIngreso) => {
            const formattedGastoIngreso = { ...gastoIngreso };
            formattedGastoIngreso.fechaAlta = this.formatDate(gastoIngreso.fechaAlta).toLocaleDateString();
            formattedGastoIngreso.fechaModificacion ? formattedGastoIngreso.fechaModificacion = this.formatDate(gastoIngreso.fechaModificacion) : null;
            return formattedGastoIngreso;
        });

        this.addRows(data);

        const dataFormatted = data.map((gastoIngreso) => {
            const formattedGastoIngreso = { ...gastoIngreso };
            formattedGastoIngreso.fechaAlta = this.formatDate(gastoIngreso.fechaAlta).toLocaleDateString();
            formattedGastoIngreso.fechaModificacion ? formattedGastoIngreso.fechaModificacion = this.formatDate(gastoIngreso.fechaModificacion) : null;
            return this.filterFields(formattedGastoIngreso);
        });

        const fileName = 'Tabla_GastosIngresos';
        this._excelService.exportToExcel(dataFormatted, fileName);
    }

    addRows(data) {
        data.forEach((gastoIngreso) => {
            gastoIngreso.num_factura = gastoIngreso.numDocumento ?? '';
            gastoIngreso.nombre_conductor = gastoIngreso.conductor.nombre;
            gastoIngreso.apellidos_conductor = gastoIngreso.conductor.apellidos;
        });
    }
}

export interface DataGastosIngresos {
    gastosIngresos?: IGastosIngresos[];
    tipo?: string;
    elementUpdate?: IGastosIngresos;
    licencias?: Licencia[];
    isForUpdate?: Boolean;
    mostrarFormulario?: Boolean;
    conductores?: IConductor[];
}

export interface DialogDataGastosIngresos {
    gastosIngresos?: IGastosIngresos[];
    tipo?: string;
    elementUpdate?: IGastosIngresos;
    licencias?: Licencia[];
    isForUpdate?: Boolean;
    mostrarFormulario?: Boolean;
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-graficos',
  templateUrl: './card-graficos.component.html',
  styleUrls: ['./card-graficos.component.css']
})
export class CardGraficosComponent {

  @Input()
  graficosVisible: Boolean;

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IGastosIngresos } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { GastosIngresosService } from 'src/app/_services/novotaxi/gastos-ingresos.service';
import { DatePipe } from '@angular/common';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';

@Component({
  selector: 'app-ingresos-a-caja',
  templateUrl: './ingresos-a-caja.component.html',
  styleUrls: ['./ingresos-a-caja.component.css']
})
export class IngresosACajaComponent implements OnInit {

  tipoDetalle: string = 'ingresosCaja';

  //Campos tabla
  displayedColumns: string[] = ['fechaRegistro', 'importe', 'concepto', 'usuario'];

  displayedSearchHeaders: string[] = ['fecha_buscador', 'importe_buscador', 'concepto_buscador', 'usuario_buscador'];

  dataSource;

  //Campos búsqueda
  isSearch: boolean = false;
  fecha: string;

  ingresosGastosList: IGastosIngresos[] = [];

  //Filtrado y ocultación de columnas
  listColumns;
  public searchForm: FormGroup;
  public fechaRegistro = '';
  public importe = '';
  public concepto = '';
  public usuario = '';

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _notificationService: NotificationService,
    private _ingresosGastosService: GastosIngresosService,
    private datePipe: DatePipe,
    private informe: InformePDF,

  ) { }

  ngOnInit(): void {
    this.listColumns = {
      'Fecha registro': true,
      'Importe': true,
      'Concepto': true,
      'Usuario': true,
    }


    this.fecha =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.searchIngresosCaja();

    this.searchFormInit();
  }
  generarInforme(){
    let nombre = 'Ingresos';
    var inicio= this.dataSource?.paginator?.pageSize * this.dataSource?.paginator?.pageIndex;
    var final=inicio + this.dataSource?.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData,nombre,inicio,final);
  }
  //Paginado
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchIngresosCaja() {
    if (this.isValidFieldsSearch()) {
      this.isSearch = true;

      this._ingresosGastosService.searchGastosIngresosByDay(this.fecha).subscribe((data) => {

        if (data['status'] == '630') {
          this.isSearch = false;
          this._notificationService.info("INFO", "No se han encontrado datos", 3000)
          this.ingresosGastosList = [];
        } else {
          this.ingresosGastosList = data['ingresos'];
        }

        this.dataSource = new MatTableDataSource(this.ingresosGastosList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.getFilterPredicate();
      })
    }
  }

  isValidFieldsSearch() {
    if (this.fecha == undefined) {
      this._notificationService.warning("¡Atención!", "Debe rellenar el campo de búsqueda", 3000);
      return false;
    } else {
      return true;
    }
  }

  //Inicio filtrado

  searchFormInit() {
    this.searchForm = new FormGroup({
      fechaRegistro: new FormControl(''),
      importe: new FormControl(''),
      concepto: new FormControl(''),
      usuario: new FormControl(''),
    });
  }

  getFilterPredicate() {
    return (row: IGastosIngresos, filters: string) => {

      const filterArray = filters.split('#');

      const fechaRegistro = filterArray[0];
      const importe = filterArray[1];
      const concepto = filterArray[2];
      const usuario = filterArray[3];

      const matchFilter = [];

      const columnFechaRegistro = row.fechaAlta != null ? row.fechaAlta.toString() : '';
      const columnImporte = row.importe != null ? row.importe.toString() : '';
      const columnConcepto = row.concepto != null ? row.concepto.toString() : '';
      const columnUsuario = (row.usuario.nombre ?? '') + ' ' + (row.usuario.apellido1 ?? '') + ' ' + (row.usuario.apellido2 ?? '');

      matchFilter.push(columnFechaRegistro.toLowerCase().includes(fechaRegistro));
      matchFilter.push(columnImporte.toLowerCase().includes(importe));
      matchFilter.push(columnConcepto.toLowerCase().includes(concepto));
      matchFilter.push(columnUsuario.toLowerCase().includes(usuario));

      return matchFilter.every(Boolean);
    }
  }

  applyFilter() {
    this.fechaRegistro = (this.searchForm.value.fechaRegistro === null || this.searchForm.value.fechaRegistro === '') ? '' : this.searchForm.value.fechaRegistro;
    this.importe = (this.searchForm.value.importe === null || this.searchForm.value.importe === '') ? '' : this.searchForm.value.importe;
    this.concepto = (this.searchForm.value.concepto === null || this.searchForm.value.concepto === '') ? '' : this.searchForm.value.concepto;
    this.usuario = (this.searchForm.value.usuario === null || this.searchForm.value.usuario === '') ? '' : this.searchForm.value.usuario;

    let filterValue = this.fechaRegistro + '#' + this.importe + '#' + this.concepto + '#' + this.usuario;

    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  //Fin filtrado
}


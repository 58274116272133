import { ICompaniaSeguros, NewCompaniaSeguros } from '../companiaSeguros.model';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.novotaxi';

export type PartialUpdateVehiculo = Partial<ICompaniaSeguros> & Pick<ICompaniaSeguros, 'id'>;

type RestOf<T extends ICompaniaSeguros | NewCompaniaSeguros> = Omit<T, 'fechaAlta' | 'fechaBaja' | 'fechaModificacion'> & {
  fechaAlta?: string | null;
  fechaBaja?: string | null;
  fechaModificacion?: string | null;
};

export type RestCompaniaSeguros = RestOf<ICompaniaSeguros>;

export type NewRestCompaniaSeguros = RestOf<NewCompaniaSeguros>;

export type EntityResponseType = HttpResponse<ICompaniaSeguros>;
export type EntityArrayResponseType = HttpResponse<ICompaniaSeguros[]>;

@Injectable({
    providedIn: 'root'
  })
  export class CompaniaSegurosService {
  
    protected resourceUrl = `${environment.API_URL}/companiaseguros`;
  
    constructor(protected http: HttpClient) { }
  
    create(companiaSeguros: ICompaniaSeguros){
      return this.http.post(`${this.resourceUrl}/`, companiaSeguros);
    }
  
    update(companiaSeguros: ICompaniaSeguros): Observable<EntityResponseType> {
      return this.http
        .post<RestCompaniaSeguros>(this.resourceUrl, companiaSeguros, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<RestCompaniaSeguros>(`${this.resourceUrl}/${id}`, { observe: 'response' })
        .pipe(map(res => this.convertResponseFromServer(res)));
    }
  
    query(req?: any){
      const options = {};
      return this.http
        .get<any[]>(this.resourceUrl, {})
    }
  
    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.resourceUrl}/delete/${id}`, { observe: 'response' });
    }

    protected convertDateFromClient<T extends ICompaniaSeguros | NewCompaniaSeguros | PartialUpdateVehiculo>(companiaSeguros: T): any {
        return {
          ...companiaSeguros,
          fechaAlta: companiaSeguros.fechaAlta?.toString() ?? null,
          fechaBaja: companiaSeguros.fechaBaja?.toISOString() ?? null,
          fechaModificacion: companiaSeguros.fechaModificacion?.toISOString() ?? null,
        };
      }
    
      protected convertDateFromServer(restCompaniaSeguros: RestCompaniaSeguros): ICompaniaSeguros {
        let fechaAlta = restCompaniaSeguros.fechaAlta?.split("-");
        let fechaBaja = restCompaniaSeguros.fechaBaja?.split("-");
        let fechaModificacion = restCompaniaSeguros.fechaModificacion?.split("-");
        return {
          ...restCompaniaSeguros,
          fechaBaja: restCompaniaSeguros.fechaBaja ? new Date(+fechaBaja[2], +fechaBaja[1] - 1, +fechaBaja[0]) : undefined,
          fechaModificacion: restCompaniaSeguros.fechaModificacion ? new Date(+fechaModificacion[2], +fechaModificacion[1] - 1, +fechaModificacion[0]) : undefined,
          
        };
      }


    protected convertResponseFromServer(res: HttpResponse<RestCompaniaSeguros>): HttpResponse<ICompaniaSeguros> {
        return res.clone({
          body: res.body ? this.convertDateFromServer(res.body) : null,
        });
      }
    
      protected convertResponseArrayFromServer(res: HttpResponse<RestCompaniaSeguros[]>): HttpResponse<ICompaniaSeguros[]> {
        return res.clone({
          body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
        });
      }
}
import { IConductor } from "src/app/conductores/conductor.model";
import { Usuario } from "./gastos-ingresos/gastos-ingresos.model";
import { Licencia } from "../Emisora";

export class DiasLibranza {
    id?: number;
    fecha_libranza_actual?: string | null;
    fecha_libranza_cambio?: string | null;
    fechaBaja?: string | null;
    conductor?: IConductor;
    user?: Usuario;
    licencia?: Licencia;

    constructor(mappedData) {
        this.id = mappedData?.id ?? null;
        this.fecha_libranza_actual = mappedData?.fecha_libranza_actual ?? null;
        this.fecha_libranza_cambio = mappedData?.fecha_libranza_cambio ?? null;
        this.fechaBaja = mappedData?.fechaBaja ?? null;
        this.conductor = mappedData?.conductor ? new IConductor(mappedData?.conductor) : null;
        this.user = new Usuario(mappedData?.user) ?? null;
        this.licencia = new Licencia(mappedData?.licencia) ?? null;
    }
}
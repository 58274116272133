<div class="bg-white rounded p-5">
    <div class="d-flex justify-content-center mb-2"><img src="/assets/novotaxi/trashCan.svg"></div>
    <div class="text-center pt-4">
        <h5 class="font-weight-bold">Seleccione fecha de baja</h5>

        <p class="mt-2">El elemento seleccionado será borrado</p>
        <input [ngModel]="fechaAlta | date:'yyyy-MM-dd'" (change)="getValue($event)" type="date" class="form-control">
    </div>
    <div class="mt-4 d-flex justify-content-center">
        <button class="btn btn-danger mx-3" (click)="close()">Borrar</button>
        <button class="btn btn-secondary mx-3" (click)="dialogRef.close({result: ''})">Cancelar</button>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-detalles-totales-caja',
  templateUrl: './card-detalles-totales-caja.component.html',
  styleUrls: ['./card-detalles-totales-caja.component.css']
})
export class CardDetallesTotalesCajaComponent implements OnInit {

  @Input() date: String;
  @Input() detallesVisible: Boolean;
  @Input() datosDetalles: Map<String, Map<String, String>> = new Map();
  @Input() tipoDetalle: string;

  infoGastosIngresos: Map<String, any>;
  infoLiquidaciones: Map<String, any>;
  infoPrestamos: Map<String, any>;
  infoRecaudacion: Map<String, any>;
  infoGastosProveedores: Map<String, any>;
  infoIngresosCaja: Map<String, any>;
  infoGestionCaja: Map<String, any>;

  constructor(

  ) { }

  ngOnInit(): void {
    this.fillMap();
  }

  fillMap() {    
    this.infoGastosIngresos = this.datosDetalles.get('infoGastos');
    this.infoLiquidaciones = this.datosDetalles.get('infoLiquidaciones');
    this.infoPrestamos = this.datosDetalles.get('infoPrestamos');
    this.infoRecaudacion = this.datosDetalles.get('infoRecaudacion');
    this.infoGastosProveedores = this.datosDetalles.get('infoGastosProveedores');
    this.infoIngresosCaja = this.datosDetalles.get('infoIngresosCaja');
    this.infoGestionCaja = this.datosDetalles.get('infoGestionCaja');    
  }
}

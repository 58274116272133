import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-modal-baja',
  templateUrl: './modal-baja.component.html',
  styleUrls: ['./modal-baja.component.css']
})
export class ModalBajaComponent {

  fechaAlta: Date = new Date();

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacionComponent>,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  getValue(event) {    
    this.fechaAlta = event.target.value;
  }

  close() {
    this.dialogRef.close({ result: this.datePipe.transform(this.fechaAlta, "yyyy-MM-dd") });
  }
}

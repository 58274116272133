import { Licencia } from 'src/app/model/Emisora';
import { IConductor } from '../conductores/conductor.model';
import { ICompaniaSeguros } from "../novotaxi/companiaSeguros/companiaSeguros.model";
import { IMarca, IModelo } from "../novotaxi/marca-modelo/modelo.model";


export class IVehiculo {
    id: number | null;
    fechaAlta?: string | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;
    licencia?: Licencia | null;
    matricula?: string | null;
    modelo?: IModelo | null;
    documentacion?: Documentacion | null;
    taximetro?: Taximetro | null;
    mantenimiento?: Mantenimiento | null;
    observaciones?: String | null;
    seguro?: Seguro | null;
    conductores?: IConductor[] | null;
    tipo?: Tipo | null;

    constructor(mappedData?) {
        this.id = mappedData?.id;
        this.fechaAlta = mappedData?.fechaAlta;
        this.fechaBaja = mappedData?.fechaBaja;
        this.fechaModificacion = mappedData?.fechaModificacion;
        this.licencia = new Licencia(mappedData?.licencia) ?? null;
        this.matricula = mappedData?.matricula;
        this.modelo = mappedData?.modelo;
        this.documentacion = mappedData?.documentacion;
        this.taximetro = mappedData?.taximetro;
        this.mantenimiento = mappedData?.mantenimiento;
        this.observaciones = mappedData?.observaciones;
        this.seguro = mappedData?.seguro != null ? new Seguro(mappedData?.seguro) : null;
        this.conductores = mappedData.conductores ? this.getConductores(mappedData.conductores) : null;
        this.tipo = mappedData?.tipo
    }

    getConductores?(conductores) {        
        let conductoresReturn: IConductor[] = [];

        conductores.forEach((data) => {
            conductoresReturn.push(new IConductor(data));
        })
        return conductoresReturn;
    }
}

export interface Documentacion {
    id?: number | null;
    revision?: Revision | null;
}

export interface Revision {
    id?: number | null;
    ultimaRevision?: string | null;
    vencimientoITV?: string | null;
    vencimientoTaximetro?: string | null;
    descripcionVehiculoITV?: string | null;
}

export interface Mantenimiento {
    id?: number | null;
    kmEntrada?: string | null;
    fechaUltimaDistribucion?: string | null;
    fechaProximaDistribucion?: string | null;
    kmUltimoCambioDeAceite?: string | null;
    kmProximoCambioDeAceite?: string | null;
    tipoAceite?: TipoAceite | null;
    hasFiltroAire?: boolean | null;
    hasFiltroCombustible?: boolean | null;
    hasFiltroHabitaculo?: boolean | null;
    boletinMeteorologico?: boolean | null;
    extintor?: boolean | null;
    tarjetaTransporte?: boolean | null;
}

export class Seguro {
    id?: number | null;
    ciaSeguros?: ICompaniaSeguros | null;
    mutuaPadronal?: string | null;
    tlfContacto?: string | null;
    fechaVencimiento?: string | null;

    constructor(mappedData?) {
        this.id = mappedData?.id;
        this.ciaSeguros = mappedData.ciaSeguros ? new ICompaniaSeguros(mappedData?.ciaSeguros) : null;
        this.mutuaPadronal = mappedData?.mutuaPadronal;
        this.tlfContacto = mappedData?.tlfContacto;
        this.fechaVencimiento = mappedData?.fechaVencimiento;
    }
}
export interface Tipo {
    id?: number | null;
    nombre?: string | null;
}
export interface Taximetro {
    id?: number | null;
    casaTaximetro?: string | null;
    claseTaximetro?: string | null;
    ultimaTarifaAplicada?: boolean | null;
    fechaUltimoCambioDeTarifa?: string | null;
}

export enum TipoAceite {
    ORGANICO = 'ORGANICO',
    SINTETICO = 'SINTETICO',
}


export type NewVehiculo = Omit<IVehiculo, 'id'> & { id: null };
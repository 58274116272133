<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Informe conductor</h1>
</div>

<hr class="linea-divisoria">

<div class="px-5 contenedor mb-5 pt-4" id="scroll">

    <div>
        <div class="d-flex flex-row justify-content-end align-items-center mb-4">

        </div>
        <form class="d-flex flex-row align-items-center crear-editar-form" [formGroup]="form">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="row mb-4 align-items-center flex-grow-1">
                    <div class="form-group col-sm-3">
                        <label>Licencia</label>
                        <select class="form-control form-select" formControlName="licencia"
                            (change)="obtenerLicencia()">
                            <option [value]=0>Selecciona una licencia</option>
                            <option [value]="licencia.id" *ngFor="let licencia of listaLicencias">
                                {{licencia.numero_licencia}}:
                                {{licencia.propietario?.nombre}} {{licencia.propietario?.apellidos}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-3 ">
                        <label>Conductor</label>
                        <select class="form-control form-select " formControlName="conductor">
                            <option [value]=0>Selecciona un conductor</option>
                            <option [value]="conductor.id" *ngFor="let conductor of licenciaConductores">
                                {{conductor.nombre}}
                                {{conductor.apellidos}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-2 ">
                        <label for="fechaProximaDistribucion">Desde</label>
                        <input class="form-control" type="date" id="desde" formControlName="desde">
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="fechaProximaDistribucion">Hasta</label>
                        <input class="form-control" type="date" id="hasta" formControlName="hasta">
                    </div>
                    <button class="btn btn-primary btn-sm ml-3 align-self-end boton-informe" (click)="crearPDF()"
                        type="button"
                        [disabled]="form.value.licencia == 0 || form.value.desde == null || form.value.hasta == null || form.value.conductor == 0">
                        <mat-icon class=" align-middle mr-2">picture_as_pdf</mat-icon><span
                            class="align-middle">Informe</span>
                    </button>
                </div>
            </div>
        </form>



        <div class="table_container">
            <table mat-table matSort class="table align-middle mb-0 bg-white">
            </table>
        </div>
    </div>
</div>
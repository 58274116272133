<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Gestión de licencias</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3"(click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle"
        >Generar informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)" [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva licencia</span>
    </button>
    <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)" [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>

  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-graficos [tipoDetalle]="tipoDetalle"></app-card-detalles-graficos>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">

  <div class="mostrarFormulario" *ngIf="mostrarFormulario">
    <app-crear-licencia (mostrarFormulario)="setMostrarFormulario($event)" *ngIf="!editar"></app-crear-licencia>
    <app-actualizar-licencia (mostrarFormulario)="setMostrarFormulario($event)" *ngIf="editar"
      [licenciaUpdateDialog]="licenciaUpdateDialog" [listaMunicipios]="listaMunicipios"></app-actualizar-licencia>
  </div>
  <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
      <app-multi-select-dropdown [listColumns]="listColumns">
      </app-multi-select-dropdown>
    </div>
    <div class="table_container" id="scroll">

      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
        class="table align-middle mb-0 bg-white">

        <!-- Columna licencia-->
        <ng-container matColumnDef="licencia_buscador" sticky>
          <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Número licencia" formControlName="numero_licencia"
                    (keyup)="applyFilter($event,'numero_licencia', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="numero_licencia" sticky>
          <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by numero_licencia" class="header">
            Licencia
          </th>
          <td mat-cell *matCellDef="let element" [hidden]="!listColumns['Licencia']">
            {{element.numero_licencia}} </td>

        </ng-container>

        <!-- Columna Estado licencia-->
        <ng-container matColumnDef="estadoLicencia_buscador">
          <th [hidden]="!listColumns['Estado licencia']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="activo" name="activo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'activo')">
              <label for="activo" class="form-check-label">Activo</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="inactivo" name="inactivo" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'inactivo')">
              <label for="inactivo" class="form-check-label">Inactivo</label>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="fechaBaja">
          <th mat-header-cell [hidden]="!listColumns['Estado licencia']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by numero_licencia" class="header">
            Estado licencia
          </th>
          <td mat-cell [hidden]="!listColumns['Estado licencia']" *matCellDef="let element">
            <p *ngIf="element.fechaBaja == null; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>
          <!-- <td mat-cell *matCellDef="let element" [hidden]="!listColumns['Estado licencia']">
          <p *ngIf="element.fechaBaja == null; else no" class="m-0">
            <mat-icon>check</mat-icon>
          </p>
          <ng-template #no>
            <mat-icon *ngIf="element.fechaBaja != null">
              close</mat-icon>
          </ng-template>
        </td> -->


        </ng-container>

        <!-- Columna Clave emisora-->
        <ng-container matColumnDef="claveEmisora_buscador">
          <th [hidden]="!listColumns['Clave emisora']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Clave emisora" formControlName="clave_emisora"
                    (keyup)="applyFilter($event,'clave_emisora', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="clave_emisora" class="cod_interno">

          <th mat-header-cell [hidden]="!listColumns['Clave emisora']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by clave_emisora" class="header">
            Clave emisora
          </th>
          <td mat-cell [hidden]="!listColumns['Clave emisora']" *matCellDef="let element">
            {{element.clave_emisora}} </td>
        </ng-container>

        <!-- Columna Día libranza -->
        <ng-container matColumnDef="libranza_buscador">
          <th [hidden]="!listColumns['Día libranza']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Día libranza" formControlName="dia_libranza"
                    (keyup)="applyFilter($event, 'dia_libranza','STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="dia_libranza">
          <th mat-header-cell [hidden]="!listColumns['Día libranza']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by dia_libranza" class="header">
            Día de libranza
          </th>
          <td mat-cell *matCellDef="let element" [hidden]="!listColumns['Día libranza']">
            {{element.dia_libranza}} </td>
        </ng-container>

        <!-- Columna Emisora -->
        <ng-container matColumnDef="emisora_buscador">
          <th [hidden]="!listColumns['Emisora']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Emisora" formControlName="emisora"
                    (keyup)="applyFilter($event, 'emisora.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="emisora.nombre">
          <th mat-header-cell [hidden]="!listColumns['Emisora']" *matHeaderCellDef class="header" mat-sort-header>
            Emisora
          </th>
          <td mat-cell [hidden]="!listColumns['Emisora']" *matCellDef="let element">
            {{element.emisora?.nombre}} </td>
        </ng-container>

        <!-- Columna Fecha alta -->
        <ng-container matColumnDef="fechaAlta_buscador">
          <th [hidden]="!listColumns['Fecha alta']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha alta" formControlName="fechaAlta"
                    (keyup)="applyFilter($event, 'fechaAlta', 'DATE_BETWEEN')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="fechaAlta">
          <th mat-header-cell [hidden]="!listColumns['Fecha alta']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Fecha alta
          </th>
          <td mat-cell [hidden]="!listColumns['Fecha alta']" *matCellDef="let element">
            {{element.fechaAlta | date:'dd-MM-yyyy'}} </td>
        </ng-container>

        <!-- Columna Nombre propietario -->
        <ng-container matColumnDef="nombrePropietario_buscador">
          <th [hidden]="!listColumns['Nombre propietario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre propietario" formControlName="nombre_propietario"
                    (keyup)="applyFilter($event, 'propietario.nombre','STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>
        <ng-container matColumnDef="propietario.nombre">
          <th mat-header-cell [hidden]="!listColumns['Nombre propietario']" *matHeaderCellDef class="header"
            mat-sort-header>
            Nombre propietario
          </th>
          <td mat-cell [hidden]="!listColumns['Nombre propietario']" *matCellDef="let element">
            {{element.propietario?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Apellidos propietario -->
        <ng-container matColumnDef="apellidosPropietario_buscador">
          <th [hidden]="!listColumns['Apellidos propietario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Apellidos propietario" formControlName="apellidos_propietario"
                    (keyup)="applyFilter($event, 'propietario.apellidos', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef=propietario.apellidos>
          <th mat-header-cell [hidden]="!listColumns['Apellidos propietario']" *matHeaderCellDef class="header"
            mat-sort-header>
            Apellidos propietario
          </th>
          <td mat-cell *matCellDef="let element" [hidden]="!listColumns['Apellidos propietario']">
            {{element.propietario?.apellidos}}
          </td>
        </ng-container>

        <!-- Columna teléfono propietario -->
        <ng-container matColumnDef="telefonoPropietario_buscador">
          <th [hidden]="!listColumns['Teléfono/s propietario']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Teléfono propietario" formControlName="telefonoPropietario"
                    (keyup)="applyFilter($event,'propietario.telefonos', 'CONTAINS_STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="propietario.telefonos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"
            [hidden]="!listColumns['Teléfono/s propietario']">
            Teléfono propietario
          </th>
          <td mat-cell *matCellDef="let element" class="py-2" [hidden]="!listColumns['Teléfono/s propietario']">
            <p class="m-0">{{validateTelefonoCondcutor(element)}}</p>
          </td>
        </ng-container>

        <!-- Columna Gestoría -->
        <ng-container matColumnDef="gestoria_buscador">
          <th [hidden]="!listColumns['Gestoría']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Gestoría" formControlName="gestoria"
                    (keyup)="applyFilter($event, 'propietario.gestoria.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="propietario.gestoria.nombre">
          <th [hidden]="!listColumns['Gestoría']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
            Gestoría
          </th>
          <td [hidden]="!listColumns['Gestoría']" mat-cell *matCellDef="let element">
            {{element.propietario?.gestoria?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Nombre conductor -->
        <ng-container matColumnDef="nombreConductor_buscador">
          <th [hidden]="!listColumns['Nombre conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre conductor" formControlName="nombre_conductor"
                    (keyup)="applyFilter($event, 'vehiculo.conductores.nombre','STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.conductores.nombre">
          <th [hidden]="!listColumns['Nombre conductor']" mat-header-cell *matHeaderCellDef class="header"
            mat-sort-header>
            Nombre conductor
          </th>
          <td [hidden]="!listColumns['Nombre conductor']" mat-cell *matCellDef="let element">
            <!-- {{element.vehiculo[0]?.conductores?.nombre}} -->
            {{element.vehiculo[0]?.conductores[0]?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Apellidos conductor -->
        <ng-container matColumnDef="apellidosConductor_buscador">
          <th [hidden]="!listColumns['Apellidos conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Apellidos conductor" formControlName="apellidos_conductor"
                    (keyup)="applyFilter($event, 'vehiculo.conductores.apellidos','STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.conductores.apellidos">
          <th [hidden]="!listColumns['Apellidos conductor']" mat-header-cell *matHeaderCellDef class="header"
            mat-sort-header>
            Apellidos conductor
          </th>
          <td [hidden]="!listColumns['Apellidos conductor']" mat-cell *matCellDef="let element">
            {{element.vehiculo[0]?.conductores[0]?.apellidos}}
          </td>
        </ng-container>

        <!-- Columna Teléfono conductor -->
        <ng-container matColumnDef="telefonoConductor_buscador">
          <th [hidden]="!listColumns['Teléfono conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Telefono conductor" formControlName="telefonoConductor"
                    (keyup)="applyFilter($event, 'vehiculo.conductores.telefono','CONTAINS_STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.conductores.telefono">
          <th mat-header-cell *matHeaderCellDef class="header" [hidden]="!listColumns['Teléfono conductor']"
            mat-sort-header>
            Teléfono conductor
          </th>
          <td mat-cell *matCellDef="let element" class="py-2" [hidden]="!listColumns['Teléfono conductor']">
            <p class="m-0">{{element.vehiculo[0]?.conductores[0]?.telefono[0]}}</p>
          </td>
        </ng-container>

        <!-- Columna Matrícula -->
        <ng-container matColumnDef="matricula_buscador">
          <th [hidden]="!listColumns['Matrícula']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Matrícula" formControlName="matricula"
                    (keyup)="applyFilter($event,'vehiculo.matricula', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.matricula">
          <th [hidden]="!listColumns['Matrícula']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
            Matrícula
          </th>
          <td [hidden]="!listColumns['Matrícula']" mat-cell *matCellDef="let element">
            {{element.vehiculo[0]?.matricula.toUpperCase()}}
          </td>
        </ng-container>

        <!-- Columna Marca -->
        <ng-container matColumnDef="marca_buscador">
          <th [hidden]="!listColumns['Marca']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Marca" formControlName="marca"
                    (keyup)="applyFilter($event,'vehiculo.modelo.marca.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.modelo.marca.nombre">
          <th [hidden]="!listColumns['Marca']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
            Marca
          </th>
          <td [hidden]="!listColumns['Marca']" mat-cell *matCellDef="let element">
            {{element.vehiculo[0]?.modelo?.marca?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Modelo -->
        <ng-container matColumnDef="modelo_buscador">
          <th [hidden]="!listColumns['Modelo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Modelo" formControlName="modelo"
                    (keyup)="applyFilter($event,'vehiculo.modelo.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.modelo.nombre">
          <th [hidden]="!listColumns['Modelo']" mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
            Modelo
          </th>
          <td [hidden]="!listColumns['Modelo']" mat-cell *matCellDef="let element">
            {{element.vehiculo[0]?.modelo?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Compañía Seguros-->
        <ng-container matColumnDef="ciaSeguros_buscador">
          <th [hidden]="!listColumns['Compañía de seguros']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Compañía de Seguros" formControlName="ciaSeguros"
                    (keyup)="applyFilter($event,'vehiculo.seguro.ciaSeguros.nombre', 'STRING')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="vehiculo.seguro.ciaSeguros.nombre">
          <th [hidden]="!listColumns['Compañía de seguros']" mat-header-cell *matHeaderCellDef class="header"
            mat-sort-header>
            Compañía de seguros
          </th>
          <td [hidden]="!listColumns['Compañía de seguros']" mat-cell *matCellDef="let element">
            {{element.vehiculo[0]?.seguro?.ciaSeguros?.nombre}}
          </td>
        </ng-container>

        <!-- Columna Acciones -->
        <ng-container matColumnDef="acciones_buscador" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
        </ng-container>

        <ng-container matColumnDef="Acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="header"></th>
          <td mat-cell *matCellDef="let element" class="mat-column acciones">
            <div class="buttons-flex-container">
              <!--<a class="eye-button activated" (click)="openDialogUpdate(element)"></a>-->
              <a class="eye-button activated" (click)="setMostrarFormulario(true, element, true)"></a>
              <!-- <a class="save-button not-activated"></a> -->
              <a class="delete-button activated" (click)="openDialogDelete(element.id)"></a>
              <!-- <a class="refresh-button not-activated"></a>
                  <a class="export-button not-activated"></a> -->
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontro el nombre "{{input.value}}"</td>
      </tr> -->
      </table>
    </div>

    <mat-paginator class="mt-3" #paginator (page)="nextPage($event)" [length]="totalElements" [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>
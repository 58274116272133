
import { Propietario } from "./novotaxi/propietario.model";
import { IConductor } from "../conductores/conductor.model";
import { IVehiculo } from "../vehiculo/vehiculo.model";
import { DatePipe } from "@angular/common";

export interface Emisora {
    id?: number;
    nombre?: string;
}

export interface Tpv {
    id?: number;
    numero_comercio?: string;
    numero_serie?: string;
}

export class Licencia {
    id?: number;
    dia_libranza?: string;
    numero_licencia?: string;
    fechaAlta?: Date | null;
    fechaBaja?: Date | null;
    fechaModificacion?: Date | null;;
    tpv?: Tpv;
    emisora?: Emisora;
    clave_emisora?: string;
    propietario?: Propietario | null;
    vehiculo?: IVehiculo[];
    isActive?: boolean;

    constructor(mappedData) {
        this.id = mappedData?.id;
        this.dia_libranza = mappedData?.dia_libranza;
        this.numero_licencia = mappedData?.numero_licencia;
        this.fechaAlta = mappedData?.fechaAlta;
        this.fechaBaja = this.convertFechaBaja(mappedData?.fechaBaja) ?? null;
        this.fechaModificacion = this.convertDatesPropietario(mappedData?.fechaModificacion) ?? null;
        this.tpv = mappedData?.tpv;
        this.emisora = mappedData?.emisora;
        this.clave_emisora = mappedData?.clave_emisora;
        this.propietario = mappedData?.propietario ? new Propietario(mappedData?.propietario) : null;
        // this.propietario =  mappedData?.propietario != null ? new Propietario(mappedData?.propietario ?? null) : null;;
        this.vehiculo = mappedData?.vehiculo ? mappedData.vehiculo : null;
        this.isActive = mappedData?.isActive;
    }

    convertDatesPropietario?(date) {
        if (date != null && date instanceof String) {
            let splitDate = date.split('-');
            let dateSaved = new Date(
                Number(splitDate[2]),   // year       
                Number(splitDate[1]), // month   
                Number(splitDate[0])    // day    
            );
            let miliseconds = dateSaved.getTime();
            date = miliseconds;
            return date;

        } else {
            if (date == null) {
                return null;
            } else {
                date = new Date(date)
            }
        }
    }

    convertFechaBaja?(date) {
        let string: String = "";

        if (date != null && typeof date == typeof string && date != '') {
            let splitDate = date.split('-');
            let dateSaved;

            if (splitDate[2].length > 2) {
                dateSaved = new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);
            } else {
                dateSaved = new Date(`${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`);
            }

            let miliseconds = dateSaved.getTime();
            date = miliseconds;

            return date;
        } else {
            if (date == null) {
                return null;
            } else {
                date = new Date(date)
            }
        }
    }
}

<div class="d-flex flex-row justify-content-between align-items-start mt-5">
    <h1 class="titulo-formulario">Histórico de licencia</h1>

    <button class="btn btn-primary btn-sm align-middle" (click)="setOcultarHistorico()">
        <mat-icon class="align-middle mr-2">edit_note</mat-icon><span class="align-middle">Mostrar formulario</span>
    </button>

</div>

<div>
    <form>

        <div class="row mt-4 mb-2">
            <div class="col-sm-3">
                <label for="licencia">Licencia</label>
            </div>
            <div class=" col-sm-3">
                <label for="licencia">Propietario</label>
            </div>
            <div class="col-sm-2">
                <label for="licencia">Desde</label>
            </div>
            <div class=" col-sm-2">
                <label for="licencia">Hasta</label>
            </div>
            <div class=" col-sm-2">
                <label for="licencia">Fecha de baja</label>
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let historico of licenciaHistoricoPropietario">
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico.licencia.numero_licencia}}">
            </div>
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico.propietario?.nombre ?? ''}} {{historico.propietario?.apellidos ?? ''}}">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(historico.fechaDesde)">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="modificacion" [value]="formatDate(historico.fechaHasta ?? null)">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(historico.licencia.fechaBaja ?? null)">
            </div>
        </div>

        <!-- <div class="row mt-1 mb-1" *ngFor="let historico of licenciaHistoricoPropietario">
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico.licencia.numero_licencia}}">
            </div>
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico.propietario?.nombre ?? ''}} {{historico.propietario?.apellidos ?? ''}}">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(historico.fechaDesde)">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="modificacion" [value]="formatDate(historico.fechaHasta ?? null)">
            </div>
            <div class="col-sm-2">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(historico.licencia.fechaBaja ?? null)">
            </div>
        </div> -->
    </form>
    <hr>
</div>
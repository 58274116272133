import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { ModalComponent } from './modal/modal.component';
import { NgImageSliderModule } from "ng-image-slider";
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask-2'
import { DecimaLimitDirective } from './directives/decima-limit.directive'
import { CookieModule } from 'ngx-cookie';
import { AuthGuard } from './guards/auth.guard';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgPipesModule } from 'ngx-pipes';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { MaterialModule } from './material/material.module'

import { ConfirmUpdateDialogComponent } from './modal/confirmar-update/confirm-update-dialog/confirm-update-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UrlService } from './_services/urlService';
import { NotificationComponent } from './notification/notification.component';


import { PopoverModule } from 'ngx-bootstrap/popover';
import { RaizComponent } from './raiz/raiz.component';
import { ListarLicenciaComponent } from './listar-licencia/listar-licencia.component';
import { BoardNovotaxiAdminComponent } from './board-admin-novotaxi/board-admin.component';

import { ListadoConductoresComponent } from './conductores/listado-conductores/listado-conductores.component';
import { ReloadDirectiveDirective } from './directives/reload-directive.directive';
import { EnvironmentGuard } from './guards/environment.guard';
import { ListadoVehiculosComponent } from './vehiculo/listado-vehiculos/listado-vehiculos.component';
import { BorrarConductorDialog } from './conductores/listado-conductores/listado-conductores.component';
import { CollapseInformacionComponent } from './conductores/mostrar-conductor/collapseInformacion.component';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MostrarConductorDialog, ConfirmarUpdateDialog, ConfirmarUnsavedDialog } from './conductores/listado-conductores/listado-conductores.component';
import { ListadoMarcaModeloComponent } from './novotaxi/marca-modelo/listado-marca-modelo/listado-marca-modelo.component';
import { RecaudacionListado } from './novotaxi/caja/recaudacion/listado-recaudacion';
import { GestionPropietariosComponent } from './novotaxi/licencias/gestion-propietarios/gestion-propietarios.component';
import { MultiSelectDropdownComponent } from './novotaxi/caja/recaudacion/multi-select-dropdown/multi-select-dropdown.component';
import { MatDialog, MatDialogModule,MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CrearMarcaModeloDialog } from './novotaxi/marca-modelo/listado-marca-modelo/listado-marca-modelo.component';
import { MostrarMarcaModeloDialog } from './novotaxi/marca-modelo/listado-marca-modelo/listado-marca-modelo.component';
import { ListadoEmisoraComponent } from './novotaxi/emisora/listado-emisora/listado-emisora.component';
import { CrearEmisoraDialog, MostrarEmisoraDialog } from './novotaxi/emisora/listado-emisora/listado-emisora.component';
import { CrearCompaniaSegurosDialog, ListadoCompaniaSegurosComponent, MostrarCompaniaSegurosDialog } from './novotaxi/companiaSeguros/listado-compania-seguros/listado-compania-seguros.component';
import { ListadoGestoriaComponent } from './novotaxi/gestoria/listado-gestoria/listado-gestoria.component';
import { CrearGestoriaDialog, MostrarGestoriaDialog } from './novotaxi/gestoria/listado-gestoria/listado-gestoria.component';
import { PrestamosDevolucionesComponent } from './novotaxi/caja/prestamos-devoluciones/prestamos-devoluciones.component';
import { LiquidacionesRetencionesComponent } from './novotaxi/caja/liquidaciones-retenciones/liquidaciones-retenciones.component';
import { GastosIngresosComponent } from './novotaxi/caja/gastos-ingresos/gastos-ingresos.component';
import { RankingConductoresComponent } from './novotaxi/ranking-conductores/ranking-conductores.component';
import { IngresosACajaComponent } from './novotaxi/caja/ingresos-a-caja/ingresos-a-caja.component';
import { GestionCajaComponent } from './novotaxi/caja/gestion-caja/gestion-caja.component';
import { OperacionesBancoComponent } from './novotaxi/caja/operaciones-banco/operaciones-banco.component';
import { CardDetallesGraficosComponent } from './novotaxi/licencias/card-detalles-graficos/card-detalles-graficos.component';
import { CardGraficosComponent } from './novotaxi/licencias/card-detalles-graficos/card-graficos/card-graficos.component';
import { CardDetallesComponent } from './novotaxi/licencias/card-detalles-graficos/card-detalles/card-detalles.component';
import { BuscadorComponent } from './novotaxi/buscador/buscador.component';
import { CrearEmisoraComponent } from './novotaxi/emisora/crear-emisora/crear-emisora.component';
import { CrearGestoriaComponent } from './novotaxi/gestoria/crear-gestoria/crear-gestoria.component';
import { CrearCompaniaSegurosComponent } from './novotaxi/companiaSeguros/crear-companiaSeguros/crear-compania-seguros.component';
import { CreateUpdatePrestamosComponent } from './novotaxi/caja/prestamos-devoluciones/dialogs/create-update.prestamos.component';
import { CrearMarcaModeloComponent } from './novotaxi/marca-modelo/crear-marca-modelo/crear-marca-modelo.component';
import { ActualizarLicenciaComponent } from './listar-licencia/actualizar-licencia/actualizar-licencia.component';
import { CreateUpdateGastosIngresosComponent } from './novotaxi/caja/gastos-ingresos/dialogs/create-update-gastos-ingresos.component';
import { CreateUpdatePropietarioComponent } from './novotaxi/licencias/gestion-propietarios/create-update-propietario/create-update-propietario.component';
import { ModalConfirmacionComponent } from './novotaxi/modal-confirmacion/modal-confirmacion.component';
import { CrearActualizarVehiculoComponent } from './vehiculo/crear-actualizar-vehiculo/crear-actualizar-vehiculo.component';
import { CrearConductorComponent } from './conductores/crear-conductor/crear-conductor.component';
import { CreateUpdateRecaudacionComponent } from './novotaxi/caja/recaudacion/create-update-recaudacion/create-update-recaudacion.component';
import { CreateUpdateLiquidacionRetencion } from './novotaxi/caja/liquidaciones-retenciones/dialogs/create-update-retenciones-liquidaciones.component';
import { HistoricoPropietarioComponent } from './novotaxi/licencias/gestion-propietarios/historico-propietario/historico-propietario.component';
import { HistoricoConductorComponent } from './conductores/historico-conductor/historico-conductor.component';
import { HistoricoVehiculoComponent } from './vehiculo/historico-vehiculo/historico-vehiculo.component';
import { CardDetallesCajaComponent } from './novotaxi/caja/card-detalles-caja/card-detalles-caja.component';
import { CardDetallesTotalesCajaComponent } from './novotaxi/caja/card-detalles-totales-caja/card-detalles-totales-caja.component';
import { InformeConductorComponent } from './novotaxi/licencias/informe-propietarios/informe-conductor.component';
import { HistoricoLicenciaComponent } from './listar-licencia/historico-licencia/historico-licencia.component';
import { CrearLicenciaComponent } from './listar-licencia/crear-licencia/crear-licencia.component';
import { CreateUpdateProveedorComponent } from './novotaxi/administrar/proveedores/create-update-proveedor/create-update-proveedor.component';
import { ListadoProveedoresComponent } from './novotaxi/administrar/proveedores/listado-proveedores/listado-proveedores.component';
import { InformePropietarioPDFComponent } from './novotaxi/licencias/informe-propietarioPDF/informe-propietarioPDF.component';
import { GastosProveedoresComponent } from './novotaxi/caja/gastos-proveedores/gastos-proveedores.component';
import { CreateUpdateGastosProveedoresComponent } from './novotaxi/caja/gastos-proveedores/create-update-gastos-proveedores/create-update-gastos-proveedores.component';
import { InformePDF } from './novotaxi/licencias/informe-licencia/informePDF';
import { CreateDiasLibranzaComponent } from './novotaxi/administrar/dias-libranza/create-dias-libranza/create-dias-libranza.component';
import { UpdateDiasLibranzaComponent } from './novotaxi/administrar/dias-libranza/update-dias-libranza/update-dias-libranza.component';
import { ListadoDiasLibranzaComponent } from './novotaxi/administrar/dias-libranza/listado-dias-libranza/listado-dias-libranza.component';
import { ModalBajaComponent } from './modal/modal-baja/modal-baja.component';
import { ControlInformesComponent } from './novotaxi/control-informes/control-informes.component';
import { ManualUsuarioComponent } from './manual-usuario/manual-usuario.component';
import { NumberFormat } from './_helpers/numberFormat';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalComponent,
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    DecimaLimitDirective,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    BoardNovotaxiAdminComponent,
    ListarLicenciaComponent,
    ConfirmUpdateDialogComponent,
    NotificationComponent,
    RaizComponent,
    RecaudacionListado,
    ListadoConductoresComponent, 
    ReloadDirectiveDirective,
    ListadoVehiculosComponent,
    BorrarConductorDialog,
    CollapseInformacionComponent,
    MostrarConductorDialog,
    ConfirmarUpdateDialog,
    ConfirmarUnsavedDialog,
    ListadoCompaniaSegurosComponent,
    CrearCompaniaSegurosDialog,
    MostrarCompaniaSegurosDialog,
    ListadoMarcaModeloComponent,
    GestionPropietariosComponent,
    MultiSelectDropdownComponent,
    LiquidacionesRetencionesComponent,
    CrearMarcaModeloDialog,
    MostrarMarcaModeloDialog,
    ListadoEmisoraComponent,
    CrearEmisoraDialog,
    MostrarEmisoraDialog,
    ListadoGestoriaComponent,
    CrearGestoriaDialog, 
    MostrarGestoriaDialog, 
    PrestamosDevolucionesComponent,
    RankingConductoresComponent, 
    IngresosACajaComponent,
    GestionCajaComponent,
    OperacionesBancoComponent,
    CardDetallesGraficosComponent, 
    CardGraficosComponent, 
    CardDetallesComponent, 
    BuscadorComponent,
    CreateUpdateLiquidacionRetencion,
    CrearEmisoraComponent,
    CrearGestoriaComponent,
    CrearCompaniaSegurosComponent, 
    CreateUpdatePrestamosComponent,
    CrearMarcaModeloComponent,
    ActualizarLicenciaComponent,
    CrearLicenciaComponent,
    CreateUpdateGastosIngresosComponent,
    GastosIngresosComponent,
    CreateUpdatePropietarioComponent,
    ModalConfirmacionComponent,
    CrearActualizarVehiculoComponent,
    CrearConductorComponent,
    CreateUpdateRecaudacionComponent,
    HistoricoPropietarioComponent,
    HistoricoConductorComponent,
    HistoricoVehiculoComponent,
    CardDetallesCajaComponent,
    CardDetallesTotalesCajaComponent,
    InformeConductorComponent,
    HistoricoLicenciaComponent,
    CreateUpdateProveedorComponent,
    ListadoProveedoresComponent,
    InformePropietarioPDFComponent,
    GastosProveedoresComponent,
    CreateUpdateGastosProveedoresComponent,
    CreateDiasLibranzaComponent,
    UpdateDiasLibranzaComponent,
    ListadoDiasLibranzaComponent,
    ModalBajaComponent,
    ControlInformesComponent,
    ManualUsuarioComponent,
    NumberFormat,
   ],
   
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgImageSliderModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    CookieModule.withOptions(),
    NgxMaskModule.forRoot({ dropSpecialCharacters: false }),
    SimpleNotificationsModule.forRoot(),
    NgPipesModule,
    MaterialModule,
    ModalModule,
    PopoverModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [authInterceptorProviders,
    //errorInterceptorProviders,
    [InformePDF],
    DatePipe,
    DecimalPipe,
    // PrecisionPipe,
    AuthGuard,
    EnvironmentGuard,
    MatTableDataSource,
    FormGroupDirective,
    MatDatepickerModule,
  ],
  exports: [
    MultiSelectDropdownComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private urlService: UrlService) { }

}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authservice: AuthService,
    private router: Router,
    private tokenservice: TokenStorageService,
    private authService: AuthService) {
  }

  isLoggedIn = false;


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    this.isLoggedIn = !!this.tokenservice.getToken();

    if (!this.isLoggedIn) {
      // const user = this.tokenservice.getUser();
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }


  /*

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (!this.authService.getAuthStatus()) {
      return true;
    }

    this.router.navigate(['/home'])
    return false;
  }
  */

}


import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicioGeneral } from "../_services/servicio-general.service";
import { first, finalize } from "rxjs/operators";
import { NotificationService } from "../_services/notification.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  form: any = {
    email: null,
  };


  loading = false;
  isSuccessful = false;
  errorMessage = "";


  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviciogeneral: ServicioGeneral,
    private _notificacionService: NotificationService,
    private _route: Router
  ) { }

  ngOnInit(): void {
    /*
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    */
  }

  onSubmit(): void {
    const { email } = this.form;
    this.loading = true;

    this.serviciogeneral.forgotPassword(email).subscribe({
      next: (data) => {
        this.isSuccessful = true;
        this._notificacionService.success("Hecho", "Hemos enviado un email con las instrucciones para cambiar la contraseña", 5000);
        this._route.navigate(['/inicio']);
      },
      error: (err) => {
        this.loading = true;
        this._notificacionService.error("Error", err.error.message, 5000);

      },
    });
  }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Propietario } from 'src/app/model/novotaxi/propietario.model';

@Component({
  selector: 'app-historico-propietario',
  templateUrl: './historico-propietario.component.html'
})
export class HistoricoPropietarioComponent {

  constructor(
    private datePipe: DatePipe,
  ) { }

  combinedArray;
  @Input() propietarioHistorico: Propietario[];
  @Input() propietarioLicenciaHistorico: any;
  @Output() mostrarHistorico = new EventEmitter<Boolean>();

  setOcultarHistorico() {
    this.mostrarHistorico.emit(false);
  }

  formatDate(date) {
    if (date) {
      const splitDate = date.split('-');

      const dateFormatted = new Date(
        Number(splitDate[2]),   // year
        Number(splitDate[1]) - 1, // month
        Number(splitDate[0])    // day
      );
      date = this.datePipe.transform(dateFormatted, 'yyyy-MM-dd');
      return date;
    }
    return '';
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs';
import { IConductor } from 'src/app/conductores/conductor.model';
import { ConductorService } from 'src/app/conductores/services/conductor.service';
import { Licencia } from 'src/app/model/Emisora';
import { IGastosIngresos, Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { LicenciaServiece } from 'src/app/_services/licenciaService.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { RecaudacionService } from 'src/app/_services/novotaxi/recaudacion/recaudacion.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { CardDetallesCajaComponent } from '../card-detalles-caja/card-detalles-caja.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Recaudacion } from 'src/app/model/novotaxi/recaudacion/recaudacion.model';
import { Prestamos } from 'src/app/model/novotaxi/prestamos.model';

@Component({
  selector: 'app-gestion-caja',
  templateUrl: './gestion-caja.component.html',
  styleUrls: ['./gestion-caja.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class GestionCajaComponent implements OnInit {

  @ViewChild(CardDetallesCajaComponent) child;
  tipoDetalle: string = 'gestionCaja';

  // -- Campos tabla
  displayedColumns: string[] = ['licencia', 'conductor', 'recaudacion', 'gastoLicencia', 'gastoGestoria', 'prestamo', 'retencion', 'ingreso', 'banco', 'cuadre', 'fecha', 'usuario'/* , 'acciones' */];

  /* displayedSearchHeaders: string[] = ['licencia_buscador', 'conductor_buscador', 'recaudacion_buscador', 'gastoLicencia_buscador', 'gastoLicencia_buscador', 'gastoGestoria_buscador',
    'prestamo_buscador', 'retencion_buscador', 'ingreso_buscador', 'banco_buscador', 'cuadre_buscador', 'fecha_buscador', 'usuario_buscador']; */

  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any | null;
  dataSource;

  // -- Campos de búsqueda
  usuarios: Usuario[] = [];
  allUsuarios: Usuario[] = [];
  fechaSearch: string = null;
  isSearch: boolean = false;
  allUser: string;
  searchMap = {
    fechaAlta: null,
    idUsuario: null,
  };

  // -- Listas de detalles
  recaudaciones: Recaudacion[] = [];
  gastosIngresos: IGastosIngresos[] = [];
  prestamos: Prestamos[] = [];
  efectivo: number = null;

  currentDate: Date = new Date();
  isSearchAll: boolean = false;
  cuadreGestionCaja;

  gestionCajaList: any[] = [];
  conductores: IConductor[] = [];
  licencias: Licencia[] = [];

  isShowTotales: boolean = false;
  showExpandButton: boolean = true;
  showExpandButtonForLastRow: boolean = true;

  // -- Campos filtrado y ocultación de columnas
  listColumns;
  public searchForm: FormGroup;
  public licencia = '';
  public conductor = '';
  public recaudacion = '';
  public gastoLicencia = '';
  public gastoGestoria = '';
  public prestamo = '';
  public retencion = '';
  public ingreso = '';
  public banco = '';
  public cuadre = '';
  public fecha = '';
  public usuario = '';

  // -- Variables para el filtrado del select
  selectedValueUser: Usuario;
  selectedValueString: string = '';
  showDropDown = false;
  keys;
  allKeys;
  nameSearch: string;
  filterArray: Usuario[] = [];
  isSideNavOpen = false;

  // -- Variables para añadir la fila extra con los totales
  nuevaFila: any = {};
  totalCuadre: number = 0
  totalIngreso: number = 0
  totalPrestamo: number = 0
  totalGastoLicencia: number = 0;
  totalRecaudacion: number = 0;
  total: number = 0;

  @Input() nombreMenu;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private _servicioGeneral: ServicioGeneral,
    private _recaudacionService: RecaudacionService,
    private _notificationService: NotificationService,
    private _licenciaService: LicenciaServiece,
    private _conductorService: ConductorService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.getUsersList();

    this.listColumns = {
      'Licencia': true,
      'Conductor': true,
      'Recaudación': true,
      'Gasto licencia': true,
      'Gasto gestoría': true,
      'Préstamo': true,
      'Retención': true,
      'Ingreso': true,
      'Banco': true,
      'Cuadre': true,
      'Fecha': true,
      'Usuario': true,
    };

    this.searchFormInit();
    this.getLicencias();
    this.getConductores();
    this.fecha = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // this.searchForAllUsers(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  }

  //Paginado
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  getUsersList() {
    this._servicioGeneral.getUserList().subscribe((data) => {
      this.usuarios = data['Usuario'];
      this.keys = data['Usuario'];
      this.allKeys = data['Usuario'];
      // this.allUsuarios = data['Usuario']
    })
  }

  getLicencias() {
    this._licenciaService.listLicencia().subscribe((data) => {
      this.licencias = data["licencias"];
    })
  }

  getConductores() {
    this._conductorService.query().subscribe((res) => {
      this.conductores = res['conductores'];
    });
  }

  search() {

    if(this.isShowTotales) {
      this.addFila();
    }

    this.fecha = this.fechaSearch;

    if (this.isValidFieldsSearch()) {
      if (this.selectedValueUser != null) {
        this.searchByUser();
      } else {
        this.searchForAllUsers(this.fechaSearch);
      }
    }
  }

  searchByUser() {
    this.searchMap = {
      fechaAlta: this.fechaSearch,
      idUsuario: this.selectedValueUser.id,
    };

    this._recaudacionService.getGestionCajaByUser(this.searchMap).subscribe((data) => {
      this.isSearch = true;

      this.gestionCajaList = data['gestionCaja']['gestionCaja'];

      this.gestionCajaList.forEach(element => {
        let conductor = this.conductores.find(conductor => conductor.id == element['conductor_id']);
        element['conductor_id'] = conductor;
        let licencia = this.licencias.find(licencia => licencia.id == element['licencia_id']);
        element['licencia_id'] = licencia;
        let usuario = this.usuarios.find(usuario => usuario.id == element['usuario_id']);
        element['usuario_id'] = usuario;

        element['cuadre'] = (element['importe_conductor'] ?? 0) * (-1) - (element['importe_total_gastos'] ?? 0) * (-1) - (element['importe_total_prestamos'] ?? 0) * (-1) + (element['importe_total_ingresos'] ?? 0) * (-1);
        element['fecha'] = this.fechaSearch;
      });
      
      this.dataSource = new MatTableDataSource(this.gestionCajaList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      let usuario = this.usuarios.filter(usuario => usuario.id == this.selectedValueUser.id,);
      this.child.getTotalGestionCaja(this.gestionCajaList, usuario);
      this.calcularTotales();
    })
  }

  searchForAllUsers(date) {
    this.gestionCajaList = [];

    let map = {
      fechaAlta: date
    };

    this._recaudacionService.getGestionCajaForAllUsers(map).subscribe((data) => {
      this.isSearch = true;

      data['gestionCaja']?.forEach(element => {
        element['gestionCaja'].forEach(gestionCaja => {
          let conductor = this.conductores.filter(conductor => conductor.id == gestionCaja['conductor_id']);
          gestionCaja['conductor_id'] = conductor;
          let licencia = this.licencias.filter(licencia => licencia.id == gestionCaja['licencia_id']);
          gestionCaja['licencia_id'] = licencia;
          let usuario = this.usuarios.filter(usuario => usuario.id == gestionCaja['usuario_id']);
          gestionCaja['usuario_id'] = usuario;
          gestionCaja['cuadre'] = (gestionCaja['importe_bruto'] ?? 0) * (-1) + (gestionCaja['importe_total_gastos'] ?? 0) * (-1) - (gestionCaja['importe_total_prestamos'] ?? 0) * (-1) + (gestionCaja['importe_total_ingresos'] ?? 0) * (-1);
          this.gestionCajaList.push(gestionCaja);
        });
      });

      this.dataSource = new MatTableDataSource(this.gestionCajaList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.child.getTotalGestionCaja(this.gestionCajaList);
      this.calcularTotales();
    })
  }

  isValidFieldsSearch() {
    if (this.fechaSearch == null || (this.selectedValueUser == null && !this.isSearchAll)) {
      this._notificationService.warning("¡Atención!", "Debe rellenar todos los campos de búsqueda", 3000);
      return false;
    } else {
      return true;
    }
  }

  // ------------ Inicio  filtrado
  searchFormInit() {
    this.searchForm = new FormGroup({

      licencia: new FormControl(''),
      conductor: new FormControl(''),
      recaudacion: new FormControl(''),
      gastoLicencia: new FormControl(''),
      gastoGestoria: new FormControl(''),
      prestamo: new FormControl(''),
      retencion: new FormControl(''),
      ingreso: new FormControl(''),
      banco: new FormControl(''),
      cuadre: new FormControl(''),
      fecha: new FormControl(''),
      usuario: new FormControl('')
    });
  }

  getFilterPredicate() {
    return (row: any, filters: string) => {
      const matchFilter = [];
      return matchFilter.every(Boolean);
    }
  }

  applyFilter() {
    let filterValue = '';

    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // ------------ Fin filtrado

  // -- Filtrado select

  focusOut() {
    this.nameSearch = "";
    this.keys = this.allKeys;
  }

  filterColumns(event) {
    this.nameSearch = event.target.value;
    if (this.nameSearch && this.nameSearch != "") {
      this.filterArray = [];

      this.allKeys.forEach(element => {
        let nombreCompleto = element.nombre + ' ' + element.apellido1 + ' ' + element.apellido2;
        if (nombreCompleto.toLowerCase().includes(this.nameSearch.toLowerCase())) {
          this.filterArray.push(element);
        }
      });

    } else {
      this.filterArray = this.allKeys;
    }
    this.keys = this.filterArray;
  }

  getSelectedValue(value) {
    this.selectedValueUser = value;

    if (typeof value == 'string') {
      this.selectedValueUser = null;
      this.isSearchAll = true;
      this.selectedValueString = value;
    } else {
      this.selectedValueString = value.nombre + ' ' + value.apellido1 + ' ' + value.apellido2;
      this.isSearchAll = false;
    }

    this.showDropDown = false;
  }

  // -- Fin filtrado select

  // -- Detalles ampliados de gestión de caja
  getDetails(element) {

    var mapa = {
      fechaAlta: this.searchMap.fechaAlta,
      idUsuario: this.searchMap.idUsuario,
      idLicencia: element.licencia_id.id,
      idConductor: element.conductor_id.id,
    };

    this._recaudacionService.getDetailsGestionCaja(mapa).subscribe((data) => {
      this.recaudaciones = data['result']['recaudaciones'];
      this.efectivo = data['result']['efectivo']['efectivo'];
      this.gastosIngresos = data['result']['gastos'];
      this.prestamos = data['result']['prestamos'];
    })
  }

  addFila() {
    // Agregar la nueva fila al final de la tabla
    if (!this.isShowTotales) {
      this.isShowTotales = true;

      this.nuevaFila.fecha = "";
      this.nuevaFila.conductor_id = { nombre: "Totales", apellidos: "" };
      this.nuevaFila.importe_conductor = this.totalRecaudacion;
      this.nuevaFila.importe_total_gastos = this.totalGastoLicencia;
      this.nuevaFila.importe_total_prestamos = this.totalPrestamo;
      this.nuevaFila.importe_total_ingresos = this.totalIngreso;
      this.nuevaFila.cuadre = this.totalCuadre;
      this.nuevaFila.usuario_id = { nombre: "Total", apellido1: `${this.total}`, apellido2: "" };


      this.dataSource.data.push(this.nuevaFila);
      this.dataSource.data[this.dataSource.data.length - 1].fecha = '';
      this.showExpandButtonForLastRow = false;

      this.dataSource._updateChangeSubscription();
    } else {
      if (this.dataSource.data.length > 0) {
        this.dataSource.data.pop();
        this.dataSource._updateChangeSubscription();
      }
      this.isShowTotales = false;
      this.showExpandButtonForLastRow = true;
      this.dataSource;
    }
  }

  isLastRow(rowData: any): boolean {
    const data = this.dataSource.data;
    return rowData === data[data.length - 1];
  }

  calcularTotales() {

    this.totalRecaudacion = this.dataSource.data.map(item => parseFloat(item.importe_conductor) || 0).reduce((acc, val) => acc + val, 0);
    this.totalGastoLicencia = this.dataSource.data.map(item => parseFloat(item.importe_total_gastos) || 0).reduce((acc, val) => acc + val, 0);
    this.totalPrestamo = this.dataSource.data.map(item => parseFloat(item.importe_total_prestamos) || 0).reduce((acc, val) => acc + val, 0);
    this.totalIngreso = this.dataSource.data.map(item => parseFloat(item.importe_total_ingresos) || 0).reduce((acc, val) => acc + val, 0);
    this.totalCuadre = this.dataSource.data.map(item => parseFloat(item.cuadre) || 0).reduce((acc, val) => acc + val, 0);
    
    this.total = this.totalRecaudacion - this.totalIngreso;    
  }
}

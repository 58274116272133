import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: 'root'
})
export class SueldoServiceService {

  protected resourceUrl = `${environment.API_URL}/sueldo`;

  constructor(protected http: HttpClient) { }

  getSueldoByConductorAndLicencia(idConductor, idLicencia) {
    return this.http.get(`${this.resourceUrl}/${idConductor}/${idLicencia}`);
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, throwError } from "rxjs";
import { Recaudacion } from "src/app/model/novotaxi/recaudacion/recaudacion.model";
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: "root",
})

export class RecaudacionService {

  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  getAllRecaudaciones() {
    return this.http
      .get(`${this.API_URL}/recaudacion/listAll`)
      .pipe(retry(1), catchError(this.handleError));
  }

  listActiveRecaudaciones() {
    return this.http
      .get(`${this.API_URL}/recaudacion/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  searchRecaudaciones(mapa) {
    return this.http
      .post(`${this.API_URL}/recaudacion/search`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }

  searchRecaudacionesConductor(mapa) {
    return this.http
      .post(`${this.API_URL}/recaudacion/searchConductorRecaudacion`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUpdateRecaudacion(recaudacion: Recaudacion) {
    return this.http
      .post(`${this.API_URL}/recaudacion`, recaudacion, {});
  }

  deleteRecaudacion(id_recaudacion) {
    const url = `${this.API_URL}/recaudacion/delete/` + id_recaudacion;
    return this.http
      .get(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getGestionCajaByUser(map) {
    return this.http
      .post(`${this.API_URL}/recaudacion/getGestionCaja`, map, {});
  }

  getGestionCajaForAllUsers(map) {
    return this.http
      .post(`${this.API_URL}/recaudacion/getGestionCajaForAll`, map, {});
  }

  getallTiposVales() {
    return this.http.get(`${this.API_URL}/recaudacion/listTipoVale`)
  }

  getallTiposTarjetas() {
    return this.http.get(`${this.API_URL}/recaudacion/listTipoTarjeta`)
  }

  getTotalRecaudacion(map) {
    return this.http
      .post(`${this.API_URL}/recaudacion/getTotal`, map)
  }

  getLastRecaudacion(map) {
    return this.http
      .post(`${this.API_URL}/recaudacion/getLastRecaudacion`, map)
  }

  getDetailsGestionCaja(map) {
    return this.http
      .post(`${this.API_URL}/recaudacion/getDetailsGestionCaja`, map)
  }

  getRecaudaciones(request?) {
    return this.http
      .post(`${this.API_URL}/recaudacion/list`, request)
  }

  cobrarRecaudacion(mapa) {
    return this.http
      .post(`${this.API_URL}/recaudacion/cobrar`, mapa)
  }

  liquidarRecaudacion(mapa) {
    return this.http
      .post(`${this.API_URL}/recaudacion/liquidar`, mapa);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}


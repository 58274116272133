import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IProvincia } from 'src/app/model/novotaxi/propietario.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { ICompaniaSeguros } from '../companiaSeguros.model';
import { CompaniaSegurosService } from '../services/companiaSeguros.service';

@Component({
  selector: 'app-crear-compania-seguros',
  templateUrl: './crear-compania-seguros.component.html',
  styleUrls: ['./crear-compania-seguros.component.css']
})

export class CrearCompaniaSegurosComponent {
  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  showMunicipiosList: any[] = [];

  constructor(
    private companiaSegurosService: CompaniaSegurosService,
    private dialog: MatDialog,
    public _notificationService: NotificationService,
    private _servicioGeneral: ServicioGeneral,
    private router: Router,
  ) { }

  @Input() companiaUpdate: ICompaniaSeguros;
  @Input() editar: Boolean;
  @Input() listMunicipios: any[];

  @Output() mostrarFormulario = new EventEmitter<Boolean>();

  companiaSeguros: ICompaniaSeguros;
  isSubmitted = false;
  expandedPhone: number = 1;
  expandedEmail: number = 1;
  listaProvincias: IProvincia[] = [];
  form;

  ngOnInit(): void {
    this.getProvincias();
    this.initiateForm();
    this.showMunicipiosList = this.listMunicipios.filter(option => this.companiaUpdate?.provincia?.id == option.municipioPK.provincia);
    this.showMunicipiosList = ordenarMunicipios(this.showMunicipiosList);
  }

  getProvincias() {
    this._servicioGeneral.getProvincias().subscribe((data) => {
      this.listaProvincias = data['Provincia']
    })
  }

  initiateForm() {

    if (this.editar) {
      if (this.companiaUpdate.provincia != null) {
        this.cargaMunicipios(this.companiaUpdate.provincia.id);
      }
    }

    this.form = new FormGroup({
      nombre: new FormControl(this.companiaUpdate?.nombre, Validators.required),
      cif: new FormControl(this.companiaUpdate?.cif ? this.companiaUpdate?.cif : null, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
      mail: this.camposEmail(),
      telefono: this.camposTelefono(),
      domicilio: new FormControl(this.companiaUpdate?.domicilio),
      municipio: new FormControl(this.companiaUpdate?.municipio ? this.companiaUpdate?.municipio : 0),
      provincia: new FormControl(this.companiaUpdate?.provincia?.id ? this.companiaUpdate?.provincia?.id : 0),
      codigoPostal: new FormControl(this.companiaUpdate?.codigoPostal, Validators.pattern("^[0-9]{5}")),
      observaciones: new FormControl(this.companiaUpdate?.observaciones),
      fechaAlta: new FormControl(this.companiaUpdate?.fechaAlta)
    });
  }

  // Para tener más de un teléfono o email

  camposTelefono() {
    let telefonosCompania = this.companiaUpdate?.telefono;

    if (telefonosCompania == null || telefonosCompania.length == 0) {
      var telefono = new FormArray([new FormControl("", Validators.pattern("^[0-9]{9}"))])
    }
    else {
      telefono = new FormArray([new FormControl(telefonosCompania[0], Validators.pattern("^[0-9]{9}"))])
      for (let i = 1; i < telefonosCompania.length; i++) {
        telefono.push(new FormControl(telefonosCompania[i].toString(), Validators.pattern("^[0-9]{9}")));
        this.expandedPhone++;
      }
    }
    return telefono;
  }

  addTelefono() {
    let tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl(null, Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  camposEmail() {
    let emailsCompania = this.companiaUpdate?.mail;

    if (emailsCompania == null || emailsCompania.length == 0) {
      var email = new FormArray([new FormControl("", Validators.pattern(this.REGEX_EMAIL))])
    }
    else {
      email = new FormArray([new FormControl(emailsCompania[0], Validators.pattern(this.REGEX_EMAIL))])
      for (let i = 1; i < emailsCompania.length; i++) {
        email.push(new FormControl(emailsCompania[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
        this.expandedEmail++;
      }
    }

    return email;
  }

  addEmail() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl(null, Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  get emails() {
    return this.form.get("mail") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    this.companiaSeguros = {
      id: this.companiaUpdate ? this.companiaUpdate.id : null,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif?.length > 0 ? this.form.value.cif : null,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      mail: this.form.value.mail?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio || null,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal != "" ? this.form.value.codigoPostal : null,
      observaciones: this.form.value.observaciones,
    }

    /*if (this.form.invalid) {
      this._notificationService.error("La operación no se ha podido realizar", "Existen datos incorrectos o incompletos")
      this.isSubmitted = true;
    }*/

    this.companiaSegurosService.create(this.companiaSeguros).subscribe({
      next: (data) => {
        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
          this.isSubmitted = false;
          this.reloadToMenuOption()
        } else {
          this.isSubmitted = true;
          let message = data['message'];
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);

        }

      }
    })

  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "guardar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "descartar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      }
    })
  }

  resetearForm() {
    this.form.reset()
    this.form.controls.provincia.setValue(0);
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  cargaMunicipios(idProvincia) {
    this.showMunicipiosList = this.listMunicipios.filter(option => idProvincia == option.municipioPK.provincia)
    this.showMunicipiosList = ordenarMunicipios(this.showMunicipiosList);
  }
}

function ordenarMunicipios(listado) {
  if(listado != null) {
    return listado.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-update-dialog',
  templateUrl: './confirm-update-dialog.component.html',
  styleUrls: ['./confirm-update-dialog.component.css']
})
export class ConfirmUpdateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmUpdateDialogComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  finish(){
    this.dialogRef.close({result: 'Confirmar'});
  }

  cancel(){
    this.dialogRef.close({result:'Cancelar'});
  }
}

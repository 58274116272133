<div class="bg-white rounded p-2" >
    <div class="container mw-100 mt-3">
      <div class="row" >
        <div class="col">
          <div class="card mb-3">
            <div class="card-header bg-white font-weight-bold h5">
              <span *ngIf="!editar">Nueva Emisora</span>
              <span *ngIf="editar">Editar Emisora</span>  
            </div>
              <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="editar == false ? submit() : openDialogConfirmarUpdate()">
  
                  <div class="row">
                    <div class="form-group col-sm-4">
                      <label for="nombre">Nombre</label>
                      <input class="form-control" type="text" id="nombre" formControlName="nombre">
                      <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es obligatorio</small>
                    </div>
                    <div class="form-group col-sm-4">
                      <label for="cif">CIF</label>
                      <input class="form-control" type="text" id="cif" formControlName="cif">
                      <small class="text-danger" *ngIf="isSubmitted && form.get('cif').hasError('pattern')">El valor introducido no es un CIF válido</small>
                    </div>
                    <div *ngIf="editar == true" class="form-group col-sm-4">
                      <label for="fechaAlta">Fecha de alta</label>
                      <input class="form-control" type="text" id="fechaAlta" formControlName="fechaAlta" readonly>
                    </div>
                  </div> 
                  <div class="row">
                    <div class="form-group col">
                      <div class="form-group" formArrayName="email" *ngFor="let tel of emails.controls; let i = index">
                          <div > 
                            <label for="email">Correo electrónico<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                            <span class="input-group">
                              <input class="form-control" type="tel" id="email" [formControlName]="i"/> 
                              <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="i > 0" (click)="removeEmail(i)"><mat-icon>close</mat-icon></button>
                            </span>                                 
                          </div> 
                          <small class="text-danger" *ngIf="isSubmitted && emails.controls[i].hasError('pattern')">El valor introducido no es un email válido</small>    
                      </div>
                      <button *ngIf="expandedEmail != 3" type="button" class="btn btn-outline-success btn-sm" (click)="addEmail()"><mat-icon>add</mat-icon> Añadir email</button>                         
                  </div>
                    <div class="form-group col">
                      <div class="form-group" formArrayName="telefono" *ngFor="let tel of telefonos.controls; let i = index">
                          <div > 
                            <label for="telefono">Teléfono<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                            <span class="input-group">
                              <input class="form-control" type="tel" id="telefono" [formControlName]="i"/> 
                              <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="i > 0" (click)="removeTelefono(i)"><mat-icon>close</mat-icon></button>
                            </span>                              
                          </div> 
                          <small class="text-danger" *ngIf="isSubmitted && telefonos.controls[i].hasError('pattern')">El valor introducido no es un teléfono válido</small>  
                      </div>
                      <button *ngIf="expandedPhone != 3" type="button" class="btn btn-outline-success btn-sm" (click)="addTelefono()"><mat-icon>add</mat-icon> Añadir teléfono</button>                         
                  </div>
                    <div class="form-group col">
                      <label for="domicilio">Domicilio</label>
                      <input class="form-control" type="text" id="domicilio" formControlName="domicilio">
                    </div>
                  </div> 
                  <div class="row">
                    <div class="form-group col">
                      <label for="municipio">Municipio</label>
                      <input class="form-control" type="text" id="municipio" formControlName="municipio">
                    </div>
                    <div class="form-group col">
                      <label for="provincia">Provincia</label>
                      <select id="provincia" class="form-control form-select" formControlName="provincia">
                        <option [value]=0>Selecciona una provincia</option>
                        <option [value]="provincia.id" *ngFor="let provincia of listaProvincias">{{provincia.nombre}}</option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label for="codigoPostal">Código postal</label>
                      <input class="form-control" type="number" id="codigoPostal" formControlName="codigoPostal">
                      <small class="text-danger" *ngIf="isSubmitted && form.get('codigoPostal').hasError('pattern')">El campo ha de contener 5 dígitos</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="observaciones">Observaciones</label>
                      <textarea class="form-control" id="observaciones" rows="3" formControlName="observaciones"></textarea>
                    </div>
                  </div>
  
                  <div id="container" class="d-flex justify-content-end pt-3">
                    <button *ngIf="editar == false" class="btn btn-success mx-3" type="submit">Guardar</button>
                    <button *ngIf="editar == true && form.dirty" class="btn btn-success mx-3" type="submit">Guardar</button>
                    <button class="btn btn-secondary ml-3" type="button" (click)="form.dirty ? openDialogConfirmarUnsaved() : dialogRef.close()">Cerrar</button>   
                  </div>
                </form>
              </div>         
          </div>
        </div>
      </div>
    </div>
  
  </div>
  

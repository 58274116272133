import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentGuard implements CanActivate {

  constructor(private router: Router,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
  ) {
    return true;
  }
}
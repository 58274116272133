<div class="d-flex flex-row justify-content-between align-items-center">
  <h1 class="m-0">Recaudación</h1>
  <div>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generateExcel()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar
        informe Excel</span>
    </button>
    <button class="btn btn-outline-primary align-middle mr-3" (click)='generarInforme()'>
      <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle">Generar
        informe PDF</span>
    </button>
    <button class="btn btn-primary align-middle ml-3" (click)="setMostrarFormulario(true, false)"
      [hidden]="mostrarFormulario">
      <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva recaudación</span>
    </button>
    <button class="btn btn-primary align-middle ml-3" (click)="setMostrarFormulario(false)"
      [hidden]="!mostrarFormulario">
      <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
    </button>
  </div>
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<app-card-detalles-caja #detalles [searchMap]="searchMap" [tipoDetalle]="tipoDetalle"></app-card-detalles-caja>
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4" id="scroll">

  <div class="mostrarFormulario" *ngIf="data.mostrarFormulario">
    <app-create-update-recaudacion (mostrarFormulario)="getValueForm($event)"
      [data]="data"></app-create-update-recaudacion>
  </div>

  <div class="mostrarTabla" [hidden]="mostrarFormulario">

    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="row align-items-center flex-grow-1 crear-editar-form">
        <div class="col-sm-2">
          <label>Desde</label>
          <input _ngcontent-vka-c280="" [(ngModel)]="fechaInicio"
            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
        </div>
        <div class="col-sm-2">
          <label>Hasta</label>
          <input _ngcontent-vka-c280="" [(ngModel)]="fechaFin"
            class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
        </div>
        <div class="col-sm-2">
          <label>Licencia</label>
          <select class="form-control form-control-sm form-select form-select-sm" [(ngModel)]="selectedValueLicencia"
            name="licencia" (change)="getConductores($event);">
            <option [ngValue]="null" selected>Selecciona una licencia</option>
            <option *ngFor="let licencia of licencias" [ngValue]="licencia">
              {{licencia.numero_licencia}}
            </option>
          </select>
        </div>
        <div class="col-sm-2" *ngIf="selectedValueLicencia != null">
          <label>Conductor</label>
          <select class="form-control form-control-sm form-select form-select-sm" [(ngModel)]="selectedValueConductor"
            name="conductor">
            <option [ngValue]="null" selected>Seleccione un conductor</option>
            <option *ngFor="let conductor of conductores" [ngValue]="conductor">
              {{conductor.nombre}} {{conductor.apellidos}}
            </option>
          </select>
        </div>

        <button class="btn btn-primary btn-sm align-self-end ml-3"
          (click)="setRequestForSearch(); detalles.getTotalesRecaudacion(searchMap); clearSearchInputs()">
          <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
        </button>
        <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="mostrarTotales()">
          <span class="align-middle">Mostrar totales</span>
        </button>
        <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="cobrar()"><span
            class="align-middle">Cobrar</span>
        </button>
        <button class="btn btn-primary btn-sm align-self-end ml-3" (click)="liquidar()"><span
            class="align-middle">Liquidar</span>
        </button>
      </div>
      <div class="btn-group btn-group-sm align-self-start" role="group" *ngIf="isSearch">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
      </div>
    </div>
    <div class="table_container mt-5" *ngIf="isSearch">

      <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
        (matSortChange)="sortData($event)">

        <!-- ------------------------------- Columna nombre conductor ----------------------- -->
        <ng-container matColumnDef="conductor_nombre_buscador" sticky>
          <th [hidden]="!listColumns['Nombre conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre" formControlName="nombre"
                    (keyup)="applyFilter($event,'conductor.nombre', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"><b>Totales</b></td>

        </ng-container>

        <ng-container matColumnDef="conductor.nombre" sticky>
          <th mat-header-cell [hidden]="!listColumns['Nombre conductor']" *matHeaderCellDef mat-sort-header
            class="header">
            Nombre conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Nombre conductor']" *matCellDef="let element">
            {{element?.conductor?.nombre}}</td>

        </ng-container>

        <!-- ------------------------------- Columna apellidos conductor ----------------------- -->
        <ng-container matColumnDef="conductor_apellidos_buscador" sticky>
          <th [hidden]="!listColumns['Apellidos conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Nombre" formControlName="nombre"
                    (keyup)="applyFilter($event,'conductor.apellidos', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="conductor.apellidos" sticky>
          <th mat-header-cell [hidden]="!listColumns['Apellidos conductor']" *matHeaderCellDef mat-sort-header
            class="header">
            Apellidos conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Apellidos conductor']" *matCellDef="let element">
            {{element?.conductor?.apellidos}}</td>

        </ng-container>



        <!-- ------------------------------- Columna porcentaje ----------------------- -->
        <ng-container matColumnDef="conductor_sueldos_cantidad_buscador">
          <th [hidden]="!listColumns['Porcentaje base']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="porcentaje" formControlName="porcentaje"
                    (keyup)="applyFilter($event,'conductor.sueldos.cantidad', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="conductor.sueldos.cantidad">
          <th mat-header-cell [hidden]="!listColumns['Porcentaje base']" *matHeaderCellDef mat-sort-header
            class="header">
            Porcentaje conductor
          </th>
          <!-- <td mat-cell [hidden]="!listColumns['Porcentaje base']" *matCellDef="let element">
            {{getporcentaje(element)}}</td> -->
          <td mat-cell [hidden]="!listColumns['Porcentaje base']" *matCellDef="let element">
            {{element.cantidad == null ? getporcentaje(element) :element.cantidad }}</td>
        </ng-container>





        <!-- ------------------------------- Columna pordentaje condicional ----------------------- -->
        <ng-container matColumnDef="conductor_sueldos_porcentajeCondicional_buscador">
          <th [hidden]="!listColumns['Porcentaje condicional']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="porcentajeCondicional" formControlName="porcentajeCondicional"
                    (keyup)="applyFilter($event,'conductor.sueldos.porcentajeCondicional', 'LONG')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="conductor.sueldos.porcentajeCondicional">
          <th mat-header-cell [hidden]="!listColumns['Porcentaje condicional']" *matHeaderCellDef mat-sort-header
            class="header">
            Porcentaje condicional
          </th>
          <td mat-cell [hidden]="!listColumns['Porcentaje condicional']" *matCellDef="let element">
            {{getporcentajeCondicional(element)}}</td>

        </ng-container>















        <!-- ------------------------------- Columna fecha alta ----------------------- -->
        <ng-container matColumnDef="fechaAlta_buscador">
          <th [hidden]="!listColumns['Fecha alta']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Alta" formControlName="fechaAlta"
                    (keyup)="applyFilter($event,'fechaAlta', 'LOCALDATE_BETWEEN')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="fechaAlta">
          <th mat-header-cell [hidden]="!listColumns['Fecha alta']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by fechaAlta" class="header">
            Fecha alta
          </th>
          <td mat-cell [hidden]="!listColumns['Fecha alta']" *matCellDef="let element">{{element?.fechaAlta }}
          </td>

        </ng-container>

        <!-- (No existe campo en BBDD para filtrado y optimización) NO BORRAR TEXTO COMENTADO  -->
        <!-- ------------------------------- Columna dia ----------------------- -->
        <!-- <ng-container matColumnDef="dia_buscador">
          <th [hidden]="!listColumns['Día']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Día" formControlName="dia" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="dayOfDate">
          <th mat-header-cell [hidden]="!listColumns['Día']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by día" class="header">
            Día
          </th>
          <td mat-cell [hidden]="!listColumns['Día']" *matCellDef="let element">{{element?.dayOfDate}}</td>
        </ng-container> -->

        <!-- (No existe campo en BBDD para filtrado y optimización) NO BORRAR TEXTO COMENTADO -->
        <!-- ------------------------------- Columna festivo ----------------------- -->
        <!-- <ng-container matColumnDef="festivo_buscador">
          <th [hidden]="!listColumns['Festivo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Festivo" formControlName="festivo" (keyup)="applyFilter()" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
        </ng-container>

        <ng-container matColumnDef="festivo">
          <th mat-header-cell [hidden]="!listColumns['Festivo']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by festivo" class="header">
            Festivo
          </th>
          <td mat-cell [hidden]="!listColumns['Festivo']" *matCellDef="let element">
            {{getDiaFestivo(element.dayOfDate)}}</td>
        </ng-container> -->

        <!-- ------------------------------- Columna km entrada ----------------------- -->
        <ng-container matColumnDef="kmEntrada_buscador">
          <th [hidden]="!listColumns['Km entrada']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Km Entrada" formControlName="kmEntrada"
                    (keyup)="applyFilter($event,'kilometraje.km_entrada', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="kilometraje.km_entrada">
          <th mat-header-cell [hidden]="!listColumns['Km entrada']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by kmEntrada" class="header">
            Km entrada
          </th>
          <td mat-cel [hidden]="!listColumns['Km entrada']" *matCellDef="let element">
            {{element.kilometraje?.km_entrada | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna km salida ----------------------- -->
        <ng-container matColumnDef="kmSalida_buscador">
          <th [hidden]="!listColumns['Km salida']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Km Salida" formControlName="kmSalida"
                    (keyup)="applyFilter($event,'kilometraje.km_salida', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="kilometraje.km_salida">
          <th mat-header-cell [hidden]="!listColumns['Km salida']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by kmSalida" class="header">
            Km salida
          </th>
          <td mat-cell [hidden]="!listColumns['Km salida']" *matCellDef="let element">
            {{element.kilometraje?.km_salida | numberFormat}}
          </td>

        </ng-container>

        <!-- ------------------------------- Columna km totales ----------------------- -->
        <ng-container matColumnDef="kmTotales_buscador">
          <th [hidden]="!listColumns['Km totales']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Fecha Alta" formControlName="kmTotales"
                    (keyup)="applyFilter($event,'kilometraje.km_totales', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> km {{km_totales |
            numberFormat}} </td>

        </ng-container>

        <ng-container matColumnDef="kilometraje.km_totales">
          <th mat-header-cell [hidden]="!listColumns['Km totales']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by kmTotales" class="header">
            Km totales
          </th>
          <td mat-cell [hidden]="!listColumns['Km totales']" *matCellDef="let element">
            {{element.kilometraje?.km_totales | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna taxímetro entrada ----------------------- -->
        <ng-container matColumnDef="taximetroEntrada_buscador">
          <th [hidden]="!listColumns['Taxímetro entrada']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Taxímetro Entrada" formControlName="taximetroEntrada"
                    (keyup)="applyFilter($event,'taximetro_entrada', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="taximetro_entrada">
          <th mat-header-cell [hidden]="!listColumns['Taxímetro entrada']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by taximetroEntrada" class="header">
            Taxímetro entrada
          </th>
          <td mat-cell [hidden]="!listColumns['Taxímetro entrada']" *matCellDef="let element">
            {{element?.taximetro_entrada | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna taxímetro salida ----------------------- -->
        <ng-container matColumnDef="taximetroSalida_buscador">
          <th [hidden]="!listColumns['Taxímetro salida']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Taxímetro Salida" formControlName="taximetroSalida"
                    (keyup)="applyFilter($event,'taximetro_salida', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="taximetro_salida">
          <th mat-header-cell [hidden]="!listColumns['Taxímetro salida']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by taximetroSalida" class="header">
            Taxímetro salida
          </th>
          <td mat-cell [hidden]="!listColumns['Taxímetro salida']" *matCellDef="let element">
            {{element?.taximetro_salida | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna importe bruto ----------------------- -->
        <ng-container matColumnDef="importeBruto_buscador">
          <th [hidden]="!listColumns['Importe bruto']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Importe Bruto" formControlName="importeBruto"
                    (keyup)="applyFilter($event,'informe.importe_bruto', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal">{{importe_bruto |
            numberFormat}} €</td>

        </ng-container>

        <ng-container matColumnDef="informe.importe_bruto">
          <th mat-header-cell [hidden]="!listColumns['Importe bruto']" *matHeaderCellDef mat-sort-header class="header">
            Importe bruto
          </th>
          <td mat-cell [hidden]="!listColumns['Importe bruto']" *matCellDef="let element">
            {{element.informe?.importe_bruto | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna Diferencia a conductor (total novotaxi antiguo) ----------------------- -->
        <ng-container matColumnDef="totalNovotaxi_buscador">
          <th [hidden]="!listColumns['Diferencia a conductor']" mat-header-cell *matHeaderCellDef
            class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Diferencia a conductor" formControlName="totalInvertaxi"
                    (keyup)="applyFilter($event,'informe.total_invertaxi', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal">{{total_importe_novotaxi |
            numberFormat}} €</td>

        </ng-container>

        <ng-container matColumnDef="informe.total_invertaxi">
          <th mat-header-cell [hidden]="!listColumns['Diferencia a conductor']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by totalInvertaxi" class="header">
            Diferencia a conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Diferencia a conductor']" *matCellDef="let element">
            {{element.informe?.total_invertaxi | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna importe conductor ----------------------- -->
        <ng-container matColumnDef="importeConductor_buscador">
          <th [hidden]="!listColumns['Importe conductor']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Importe Conductor" formControlName="importeConductor"
                    (keyup)="applyFilter($event,'informe.importe_conductor', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> {{importe_conductor |
            numberFormat}} € </td>

        </ng-container>

        <ng-container matColumnDef="informe.importe_conductor">
          <th mat-header-cell [hidden]="!listColumns['Importe conductor']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by importeConductor" class="header">
            Importe conductor
          </th>
          <td mat-cell [hidden]="!listColumns['Importe conductor']" *matCellDef="let element">
            {{element.informe?.importe_conductor | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna importe propietario ----------------------- -->
        <ng-container matColumnDef="importePropietario_buscador">
          <th [hidden]="!listColumns['Importe propietario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Importe Propietario" formControlName="importePropietario"
                    (keyup)="applyFilter($event,'informe.importe_propietario', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> {{importe_propietario |
            numberFormat}} € </td>

        </ng-container>

        <ng-container matColumnDef="informe.importe_propietario">
          <th mat-header-cell [hidden]="!listColumns['Importe propietario']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by importePropietario" class="header">
            Importe propietario
          </th>
          <td mat-cell [hidden]="!listColumns['Importe propietario']" *matCellDef="let element">
            {{element.informe?.importe_propietario | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna total propietario ----------------------- -->
        <ng-container matColumnDef="totalPropietario_buscador">
          <th [hidden]="!listColumns['Total propietario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Total Propietario" formControlName="totalPropietario"
                    (keyup)="applyFilter($event,'informe.total_propietario', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="informe.total_propietario">
          <th mat-header-cell [hidden]="!listColumns['Total propietario']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by totalPropietario" class="header">
            Total propietario
          </th>
          <td mat-cell [hidden]="!listColumns['Total propietario']" *matCellDef="let element">
            {{element.informe?.total_propietario | numberFormat}}</td>

        </ng-container>

        <!--<ng-container matColumnDef="rpro">
                    <th mat-header-cell [hidden]="!listColumns['rpro']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by rpro" class="header">
                        <div>
                    <p>Rpro</p>
                    <form [formGroup]="searchForm">
                        <mat-form-field class="search__form_field" appearance="outline">
                            <input type="text" matInput aria-label="rpro" formControlName="rpro"
                                (keyup)="applyFilter()"  placeholder="Buscar por rpro" >
                        </mat-form-field>
                    </form>
                </div>
                    </th>
                    <td mat-cell [hidden]="!listColumns['rpro']" *matCellDef="let element"></td>
                </ng-container>-->

        <!-- ------------------------------- Columna visa ----------------------- -->
        <ng-container matColumnDef="visa_buscador">
          <th [hidden]="!listColumns['Visa']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Visa" formControlName="visa" (keyup)="applyFilter($event,'total_tarjeta', 'DOUBLE')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> {{total_tarjeta |
            numberFormat}} € </td>

        </ng-container>

        <ng-container matColumnDef="total_tarjeta">
          <th mat-header-cell [hidden]="!listColumns['Visa']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by visa" class="header">
            Visa
          </th>
          <td mat-cell [hidden]="!listColumns['Visa']" *matCellDef="let element">{{element?.total_tarjeta |
            numberFormat}}
          </td>

        </ng-container>

        <!-- ------------------------------- Columna vales ----------------------- -->
        <ng-container matColumnDef="vales_buscador">
          <th [hidden]="!listColumns['Vales']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Vales" formControlName="vales" (keyup)="applyFilter($event,'total_vales', 'DOUBLE')"
                    placeholder="Buscar por..." class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> {{total_vales |
            numberFormat}} € </td>

        </ng-container>

        <ng-container matColumnDef="total_vales">
          <th mat-header-cell [hidden]="!listColumns['Vales']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by vales" class="header">
            Vales
          </th>
          <td mat-cell [hidden]="!listColumns['Vales']" *matCellDef="let element">{{element?.total_vales |
            numberFormat}}
          </td>

        </ng-container>

        <!-- ------------------------------- Columna banco ----------------------- -->
        <ng-container matColumnDef="banco_buscador">
          <th [hidden]="!listColumns['Banco']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Banco" formControlName="banco"
                    (keyup)="applyFilter($event,'operacion_conductor.prestamo', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"> {{prestamo |
            numberFormat}} €</td>

        </ng-container>

        <ng-container matColumnDef="operacion_conductor.prestamo">
          <th mat-header-cell [hidden]="!listColumns['Banco']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by banco" class="header">
            Banco
          </th>
          <td mat-cell [hidden]="!listColumns['Banco']" *matCellDef="let element">
            {{element.operacion_conductor?.prestamo | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna efectivo ----------------------- -->
        <ng-container matColumnDef="efectivo_buscador">
          <th [hidden]="!listColumns['Efectivo']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Efectivo" formControlName="efectivo"
                    (keyup)="applyFilter($event,'operacion_conductor.efectivo', 'DOUBLE')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal">

            {{efectivoTotal | numberFormat}} €
          </td>

        </ng-container>

        <ng-container matColumnDef="operacion_conductor.efectivo">
          <th mat-header-cell [hidden]="!listColumns['Efectivo']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by efectivo" class="header">
            Efectivo
          </th>
          <td mat-cell [hidden]="!listColumns['Efectivo']" *matCellDef="let element">
            {{element.operacion_conductor?.efectivo | numberFormat}}</td>

        </ng-container>

        <!-- ------------------------------- Columna observaciones ----------------------- -->
        <ng-container matColumnDef="observaciones_buscador">
          <th [hidden]="!listColumns['Observaciones']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
            <form [formGroup]="searchForm" class="search__form">
              <div class="d-flex flex-row align-items-center bg-white rounded">
                <div class="flex-grow-1 d-flex align-items-center">
                  <mat-icon class="align-middle pl-1">search</mat-icon>
                </div>
                <span>
                  <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="text"
                    aria-label="Observaciones" formControlName="observaciones"
                    (keyup)="applyFilter($event,'observaciones', 'STRING')" placeholder="Buscar por..."
                    class="form-control">
                </span>
              </div>
            </form>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="observaciones">
          <th mat-header-cell [hidden]="!listColumns['Observaciones']" *matHeaderCellDef mat-sort-header class="header">
            Observaciones
          </th>
          <td mat-cell [hidden]="!listColumns['Observaciones']" *matCellDef="let element">
            {{element?.observaciones}}
          </td>

        </ng-container>

        <!-- ------------------------------- Columna computa ----------------------- -->
        <ng-container matColumnDef="computa_buscador">
          <th [hidden]="!listColumns['Computa']" mat-header-cell *matHeaderCellDef class="buscador-tabla-checkbox">
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="computa" name="computa" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'computa')">
              <label for="computa" class="form-check-label">Computa</label>
            </div>
            <div class="form-check form-check-inline">
              <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" type="checkbox"
                id="noComputa" name="no computa" class="form-check-input" checked
                (change)="setActivoInactivo($event, 'noComputa')">
              <label for="no computa" class="form-check-label">No computa</label>
            </div>
          </th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <ng-container matColumnDef="no_computa">
          <th mat-header-cell [hidden]="!listColumns['Computa']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by computa" class="header">
            Computa
          </th>
          <td mat-cell [hidden]="!listColumns['Computa']" *matCellDef="let element">
            <p *ngIf="!element.no_computa; else no" class="m-0"><mat-icon>check</mat-icon></p>
            <ng-template #no><mat-icon>close</mat-icon></ng-template>
          </td>

        </ng-container>

        <!-- Columna validado -->
        <ng-container matColumnDef="validado">
          <th mat-header-cell [hidden]="!listColumns['Validado']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by validado" class="header">Validado</th>
          <td mat-cell [hidden]="!listColumns['Validado']" *matCellDef="let element"></td>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <!-- Columna ingreso asignado-->
        <ng-container matColumnDef="ingresoAsignado">
          <th mat-header-cell [hidden]="!listColumns['Ingreso asignado']" *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by ingresoAsignado" class="header">Ingreso Asignado</th>
          <td mat-cell [hidden]="!listColumns['Ingreso asignado']" *matCellDef="let element"></td>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal"></td>

        </ng-container>

        <!-- Columna acciones -->
        <ng-container matColumnDef="acciones_buscador" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
          <td style="padding-top: 0.5%;" mat-footer-cell *matFooterCellDef [hidden]="isTotal">
            <b>Total </b>
            <span> {{total | numberFormat}} €</span>
          </td>

        </ng-container>

        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="header"></th>
          <td mat-cell *matCellDef="let element" class="mat-column acciones">
            <div class="buttons-flex-container">
              <!-- <a class="eye-button activated" (click)="openDialogUpdate(element)"></a> -->
              <!-- <a class="eye-button activated" (click)="setMostrarFormulario(true, element)"></a> -->
              <a class="eye-button activated" (click)="setMostrarFormulario(true, true, element)"></a>
              <a class="delete-button activated" (click)="openDialogDelete(element)"></a>
            </div>
          </td>

        </ng-container>
        <!--  <ng-container matColumnDef="disclaimer">
          <td mat-footer-cell *matFooterCellDef colspan="20">
            <div class="col-sm-12 pt-4 d-flex pb-4 align-items-center" >
              <div class="col-sm-1">
                <b>Totales</b>
              </div>
              <div class="col-sm-1">
                <p class="totalesTitulos">Km totaltes</p>
                <span class="totales">{{km_totales | numberFormat}}</span>
              </div>
              <div class="col-sm-1">
                <p class="totalesTitulos">Importe bruto</p>
                <span class="totales">{{importe_bruto | numberFormat}}</span>
              </div>

              <div class="col-sm-1">
                <p class="totalesTitulos">Importe conductor</p>
                <span class="totales">{{importe_conductor | numberFormat}}</span>
              </div>

              <div class="col-sm-1">
                <p class="totalesTitulos">Importe propietario</p>
                <span class="totales">{{importe_propietario | numberFormat}}</span>
              </div>

              <div class="col-sm-1">
                <p class="totalesTitulos">Tarjetas</p>
                <span class="totales">{{total_tarjeta | numberFormat}}</span>
              </div>

              <div class="col-sm-1">
                <p class="totalesTitulos">Vales</p>
                <span class="totales">{{total_vales | numberFormat}}</span>
              </div>

              <div class="col-sm-1">
                <p class="totalesTitulos">Efectivo</p>
                <span class="totales">{{efectivoTotal | numberFormat}}</span>
              </div>
            </div>
          </td>

//llamada footer(sacar fuera)
 <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="example-second-footer-row">


        </ng-container>-->
        <tr mat-footer-row *matFooterRowDef="displayedSearchHeaders; sticky: true" [hidden]="isTotal"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"> </tr>




      </table>

    </div>

    <mat-paginator class="mt-3" #paginator *ngIf="isSearch" (page)="nextPage($event)" [length]="totalElements"
      [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>


  </div>


</div>
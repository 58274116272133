<div class="d-flex flex-row justify-content-between align-items-start mt-2">
    <h1 class="titulo-formulario">Histórico de conductor</h1>

    <button class="btn btn-primary btn-sm align-middle" (click)="setOcultarHistorico()">
        <mat-icon class="align-middle mr-2">edit_note</mat-icon><span class="align-middle">Mostrar formulario</span>
    </button>

</div>

<div>
    <form>

        <div class="row mt-4 mb-2">
            <div class="col-sm-3">
                <label for="coonductor">Nombre</label>
            </div>
            <div class="col">
                <label for="coonductor">DNI</label>
            </div>
            <div class="col">
                <label for="coonductor">Licencia</label>
            </div>
            <div class="col">
                <label for="coonductor">Fecha desde</label>
            </div>
            <div class="col">
                <label for="coonductor">Fecha hasta</label>
            </div>
            <div class=" col">
                <label for="coonductor">Fecha de baja</label>
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let historico of conductorLicenciaHistorico">
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="conductor"
                    placeholder="{{historico.conductor.nombre}} {{historico.conductor.apellidos}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni" placeholder="{{historico.conductor.dni}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="licencia"
                    placeholder="{{historico.licencia.numero_licencia}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(historico?.fechaDesde)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="alta" [value]="formatDate(historico?.fechaHasta)">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja"
                    [value]="formatDate(historico?.conductor?.fechaBaja)">
            </div>
        </div>

        <div class="row mt-1 mb-1" *ngFor="let conductor of conductorHistorico">
            <div class="col-sm-3">
                <input disabled class="form-control" type="text" id="propietario"
                    placeholder="{{conductor.nombre}} {{conductor.apellidos}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni" placeholder="{{conductor.dni}}">
            </div>
            <div class="col">
                <input disabled class="form-control" type="text" id="dni">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="alta">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="alta">
            </div>
            <div class="col">
                <input disabled class="form-control" type="date" id="baja" [value]="formatDate(conductor.fechaBaja)">
            </div>
        </div>
    </form>
    <hr>
</div>
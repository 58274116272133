<div class="d-flex flex-column min-vh-100 justify-content-between" *ngIf="currentEnv != 'novotaxi'">

    <div *ngIf="currentEnv == 'aeioros'">
        <app-header *appReloadDirective="reloadHeader"></app-header>
    </div>
    <div *ngIf="currentEnv == 'novotaxi'">
        <app-header *appReloadDirective="reloadHeader"></app-header>
    </div>
    <router-outlet></router-outlet>
    <div *ngIf="this.location.path() == ''">
        <app-inicio></app-inicio>
    </div>
</div>

<!--Login personalizado Novotaxi-->
<div *ngIf="currentEnv == 'novotaxi'">

    <div class="d-flex flex-column min-vh-100 justify-content-between" *ngIf="this.location.path() != ''">
        <div>
            <app-header *appReloadDirective="reloadHeader"></app-header>
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>

    <div class="d-flex flex-row vh-100" *ngIf="this.location.path() == ''">
        <div class="contenedor-login-image vh-100 d-flex flex-column justify-content-end align-items-center">
            <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                <div class="logo-compania"></div>
                <h2 class="m-0 ml-2">Novotaxi</h2>
            </div>
            <p class="text-uppercase subtitulo-imagen">La manera más sencilla de gestionar tu día a día</p>
            <p class="texto-imagen mb-5">Gestiona tus vehículos, licencias y propietarios desde cualquier lugar y a un
                solo clic</p>
        </div>
        <router-outlet></router-outlet>
        <div class="contenedor-login-form vh-100 align-self-center justify-self-center">
            <app-inicio></app-inicio>
        </div>
    </div>

</div>
<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Días de libranza</h1>
    <div>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true, false)"
            [hidden]="mostrarFormulario">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Cambiar día de libranza</span>
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false)"
            [hidden]="!mostrarFormulario">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
        </button>

    </div>
</div>

<hr class="linea-divisoria">
<div class="px-5 pb-4 contenedor mb-5 pt-4" id="form-container-scroll">
    <div class="mostrarFormulario" *ngIf="data.mostrarFormulario">
        <app-create-dias-libranza (mostrarFormulario)="getValueForm($event)" *ngIf="!isForUpdate"
            [data]="data"></app-create-dias-libranza>
        <app-update-dias-libranza (mostrarFormulario)="getValueForm($event)" [data]="data"
            *ngIf="isForUpdate"></app-update-dias-libranza>
    </div>
    <div class="mostrarTabla" [hidden]="mostrarFormulario">
        <div class="d-flex flex-row justify-content-end align-items-center mb-4">
            <app-multi-select-dropdown [listColumns]="listColumns">
            </app-multi-select-dropdown>
        </div>

        <div class="table_container">
            <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white">

                <ng-container matColumnDef="libranzaActual">
                    <th [hidden]="!listColumns['Libranza actual']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Libranza actual</th>
                    <td [hidden]="!listColumns['Libranza actual']" mat-cell *matCellDef="let element">
                        {{element?.fecha_libranza_actual}}</td>
                </ng-container>

                <ng-container matColumnDef="libranzaNueva">
                    <th [hidden]="!listColumns['Libranza nueva']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Libranza actual</th>
                    <td [hidden]="!listColumns['Libranza nueva']" mat-cell *matCellDef="let element">
                        {{element?.fecha_libranza_cambio}}</td>
                </ng-container>

                <ng-container matColumnDef="conductor">
                    <th [hidden]="!listColumns['Conductor']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Conductor</th>
                    <td [hidden]="!listColumns['Conductor']" mat-cell *matCellDef="let element">
                        {{element?.conductor?.nombre}}</td>
                </ng-container>

                <ng-container matColumnDef="licencia">
                    <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Licencia</th>
                    <td [hidden]="!listColumns['Licencia']" mat-cell *matCellDef="let element">
                        {{element?.licencia?.numero_licencia}}</td>
                </ng-container>

                <ng-container matColumnDef="usuario">
                    <th [hidden]="!listColumns['Usuario']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">Usuario</th>
                    <td [hidden]="!listColumns['Usuario']" mat-cell *matCellDef="let element">
                        {{element?.user?.nombre}}</td>
                </ng-container>

                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="header"></th>
                    <td mat-cell *matCellDef="let element" class="mat-column acciones">
                        <div class="buttons-flex-container">
                            <a class="eye-button activated" (click)="setMostrarFormulario(true,true,element)"></a>
                            <a class="delete-button activated" (click)="openDialogDelete(element)"></a>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <mat-paginator class="mt-3" #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
            aria-label="Select page">
        </mat-paginator>
    </div>
</div>
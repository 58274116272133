import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmarUnsavedDialog, ConfirmarUpdateDialog } from 'src/app/conductores/listado-conductores/listado-conductores.component';
import { IProvincia } from 'src/app/model/novotaxi/propietario.model';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { ICompaniaSeguros } from '../companiaSeguros.model';
import { CompaniaSegurosService } from '../services/companiaSeguros.service';
import { InformePDF } from '../../licencias/informe-licencia/informePDF';

@Component({
  selector: 'app-listado-compania-seguros',
  templateUrl: './listado-compania-seguros.component.html',
  styleUrls: ['./listado-compania-seguros.component.css']
})
export class ListadoCompaniaSegurosComponent implements OnInit {

  displayedColumns: string[] = ['nombre', 'cif', 'mail', 'telefono', 'domicilio', 'municipio', 'provincia.nombre', 'codigoPostal', 'fechaAlta', 'acciones'];

  listMunicipios: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() nombreMenu;

  dataSource = new MatTableDataSource();
  listColumns;
  listaCompaniaSeguros?: ICompaniaSeguros[];
  companiaUpdate: ICompaniaSeguros;
  editar: Boolean = false;
  mostrarFormulario: Boolean = false;

  constructor(
    private companiaSegurosService: CompaniaSegurosService,
    private _servicioGeneral: ServicioGeneral,
    private dialog: MatDialog,
    private router: Router,
    public _notificationService: NotificationService,
    private informe: InformePDF,
  ) { }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  ngOnInit(): void {
    window.scroll(0, 0)
    this.getAllCompaniaSeguros();
    this.getMunicipios();

    this.listColumns = {
      'Nombre': true,
      'CIF': true,
      'Correo electrónico': true,
      'Teléfono': true,
      'Domicilio': true,
      'Municipio': true,
      'Provincia': true,
      'Código postal': true,
      'Fecha alta': true,
    }
  }
  generarInforme(){
    let nombre = 'Compania';
    var inicio= this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
    var final=inicio + this.dataSource.paginator.pageSize;
    this.informe.crearPDF(this.dataSource.filteredData,nombre,inicio,final);
  }
  //Paginado
  length = this.listaCompaniaSeguros?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  getAllCompaniaSeguros() {
    this.companiaSegurosService.query().subscribe((res) => {
      this.listaCompaniaSeguros = res['CompaniasSeguros']
      this.listaCompaniaSeguros = this.listaCompaniaSeguros?.filter(companiaSeguros => companiaSeguros.fechaBaja == null) //Mostramos el conjunto de elementos que no están borrados, es decir, que no tienen fecha de baja
      this.dataSource = new MatTableDataSource(this.listaCompaniaSeguros);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    });
  }

  setMostrarFormulario(mode: Boolean, compania?: ICompaniaSeguros, tipo?: Boolean) {
    this.mostrarFormulario = mode;
    this.companiaUpdate = compania;
    this.editar = tipo;
    if (mode) {
      document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
    }
  }

  openDialogCrearCompaniaSeguros() {
    const dialogConfig = this.dialog.open(CrearCompaniaSegurosDialog, {
      minWidth: '50vw',
      maxHeight: '90vh',
      autoFocus: false,
      exitAnimationDuration: '500ms',
      disableClose: true,
      data: { companiaSaved: {} }
    })

    dialogConfig.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.listaCompaniaSeguros.push(result.companiaSeguros);
        this.dataSource.data = this.listaCompaniaSeguros;
      }
    })
  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogBorrarCompaniaSeguros(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = "borrar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.delete(id);
      }
    })
  }

  openDialogMostrarCompania(companiaSeguros: ICompaniaSeguros) {
    /* const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '50vw';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data = companiaSeguros;
    this.dialog.open(MostrarCompaniaSegurosDialog, dialogConfig); */

    const dialogConfig = this.dialog.open(MostrarCompaniaSegurosDialog, {
      minWidth: '50vw',
      maxHeight: '90vh',
      autoFocus: false,
      exitAnimationDuration: '500ms',
      disableClose: true,
      data: { companiaSaved: companiaSeguros }
    })

    dialogConfig.afterClosed().subscribe(result => {
      if (result != undefined) {
        let element = this.listaCompaniaSeguros.find(ciaSeguro => ciaSeguro.id == result.companiaSeguros.id);
        let index = this.listaCompaniaSeguros.indexOf(element);
        index != -1 ? this.listaCompaniaSeguros[index] = result.companiaSeguros : ''
        this.dataSource.data = this.listaCompaniaSeguros;
      }
    })
  }

  delete(id: number): void {
    this.companiaSegurosService.delete(id).subscribe({
      next: (data) => {
        this._notificationService.success("Operación realizada con éxito", "Los datos han sido borrados correctamente", 3000)
        let element = this.listaCompaniaSeguros.find(ciaSeguro => ciaSeguro.id == id)
        let index = this.listaCompaniaSeguros.indexOf(element)
        index != -1 ? this.listaCompaniaSeguros.splice(index, 1) : '';
        this.dataSource.data = this.listaCompaniaSeguros;

        // this.reloadToMenuOption();
      }
    });
  }

  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 19 } });
  }

  getMunicipios() {
    this._servicioGeneral.getMunicipios().subscribe((data) => {
      this.listMunicipios = data['Municipio']
    })
  }
}

@Component({
  selector: 'app-crear-companiaSeguros-dialog',
  templateUrl: '../crear-companiaSeguros/crear-companiaSeguros-dialog.html',
  styleUrls: ['../crear-companiaSeguros/crear-companiaSeguros-dialog.css']
})
export class CrearCompaniaSegurosDialog implements OnInit {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private companiaSegurosService: CompaniaSegurosService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CrearCompaniaSegurosDialog>,
    public _notificationService: NotificationService,
    private tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  companiaSeguros: ICompaniaSeguros;
  isSubmitted = false;
  editar = false;
  expandedPhone: number = 1;
  expandedEmail: number = 1;
  provincias: IProvincia[] = [];

  ngOnInit(): void {
    this.getProvincias();
  }

  getProvincias() {
    this._servicioGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia']
    })
  }

  form = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    cif: new FormControl(null, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    mail: new FormArray([new FormControl(null, Validators.pattern(this.REGEX_EMAIL))]),
    telefono: new FormArray([new FormControl(null, Validators.pattern("[0-9]{9}"))]),
    domicilio: new FormControl(""),
    municipio: new FormControl(""),
    provincia: new FormControl(0),
    codigoPostal: new FormControl(),
    observaciones: new FormControl(),
  });

  // Para tener más de un teléfono o email

  addTelefono() {
    let tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl(null, Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  addEmail() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl(null, Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  get emails() {
    return this.form.get("mail") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    this.companiaSeguros = {
      id: null,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif?.length > 0 ? this.form.value.cif : null,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      mail: this.form.value.mail?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal,
      observaciones: this.form.value.observaciones,
    }

    /*if (this.form.invalid) {
      this._notificationService.error("La operación no se ha podido realizar", "Existen datos incorrectos o incompletos")
      this.isSubmitted = true;
    }*/

    this.companiaSegurosService.create(this.companiaSeguros).subscribe({
      next: (data) => {
        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
          this.data.companiaSaved = data;
          this.isSubmitted = false;
          this.dialogRef.close({ companiaSeguros: this.data.companiaSaved });
        } else {
          this.isSubmitted = true;
          let message = data['message'];
          this._notificationService.error("La operación no se ha podido realizar", message, 3000);

        }

      }
    })

  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
        // this.reloadToMenuOption()
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }


  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 19 } });
  }
}


@Component({
  templateUrl: '../crear-companiaSeguros/crear-companiaSeguros-dialog.html',
  styleUrls: ['../crear-companiaSeguros/crear-companiaSeguros-dialog.css']
})

export class MostrarCompaniaSegurosDialog implements OnInit {

  REGEX_EMAIL = "(^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)";

  constructor(
    private companiaSegurosService: CompaniaSegurosService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MostrarCompaniaSegurosDialog>,
    private router: Router,
    public _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _servicioGeneral: ServicioGeneral,
  ) { }

  companiaSegurosUpdate: ICompaniaSeguros;
  isSubmitted: Boolean = false;
  editar: Boolean = true; //Para cambiar el título de 'nueva compañía' a 'editar compañía'
  expandedPhone: number = 1;
  expandedEmail: number = 1;

  provincias: IProvincia[] = []

  ngOnInit(): void {
    this.getProvincias()
  }

  getProvincias() {
    this._servicioGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia']
    })
  }

  form = new FormGroup({
    nombre: new FormControl(this.data.companiaSaved.nombre, Validators.required),
    cif: new FormControl(this.data.companiaSaved.cif, [Validators.pattern("^[A-Z]{1}[0-9]{7}[0-9A-Z]{1}$")]),
    mail: this.camposEmail(),
    domicilio: new FormControl(this.data.companiaSaved.domicilio),
    municipio: new FormControl(this.data.companiaSaved.municipio),
    provincia: new FormControl(this.data.companiaSaved.provincia?.id ? this.data.companiaSaved.provincia.id : 0),
    codigoPostal: new FormControl(this.data.companiaSaved.codigoPostal),
    telefono: this.camposTelefono(),
    fechaAlta: new FormControl(this.data.companiaSaved.fechaAlta),
    observaciones: new FormControl(this.data.companiaSaved.observaciones)
  });

  // Si el usuario tiene varios telefonos o emals, los muestra y permite así eliminarlos o añadir más
  camposTelefono() {
    let telefonosEmisora = this.data.companiaSaved.telefono;
    let telefono = new FormArray([new FormControl(telefonosEmisora[0], Validators.pattern("[0-9]{9}"))])
    for (let i = 1; i < telefonosEmisora.length; i++) {
      telefono.push(new FormControl(telefonosEmisora[i].toString(), Validators.pattern("[0-9]{9}")));
      this.expandedPhone++;
    }
    return telefono;
  }

  addTelefono() {
    const tels = this.form.controls.telefono as FormArray;
    tels.push(
      new FormControl(null, Validators.pattern("[0-9]{9}")),
    )
    this.expandedPhone++;
  }

  get telefonos() {
    return this.form.get("telefono") as FormArray;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedPhone--;
    this.form.markAsDirty();
  }

  camposEmail() {
    let emailsEmisora = this.data.companiaSaved.mail;
    let email = new FormArray([new FormControl(emailsEmisora[0], Validators.pattern(this.REGEX_EMAIL))])
    for (let i = 1; i < emailsEmisora.length; i++) {
      email.push(new FormControl(emailsEmisora[i].toString(), Validators.pattern(this.REGEX_EMAIL)));
      this.expandedPhone++;
    }
    return email;
  }

  addEmail() {
    let emails = this.form.controls.mail as FormArray;
    emails.push(
      new FormControl(null, Validators.pattern(this.REGEX_EMAIL)),
    )
    this.expandedEmail++;
  }

  get emails() {
    return this.form.get("mail") as FormArray;
  }

  removeEmail(i: number) {
    this.emails.removeAt(i);
    this.expandedEmail--;
    this.form.markAsDirty();
  }

  submit() {
    this.companiaSegurosUpdate = {
      id: this.data.companiaSaved.id,
      nombre: this.form.value.nombre,
      cif: this.form.value.cif?.length > 0 ? this.form.value.cif : null,
      mail: this.form.value.mail?.filter(email => email != "" && email != null),
      domicilio: this.form.value.domicilio,
      municipio: this.form.value.municipio,
      provincia: this.form.value.provincia == 0 ? null : (
        {
          id: this.form.value.provincia
        }
      ),
      codigoPostal: this.form.value.codigoPostal,
      telefono: this.form.value.telefono?.filter(tel => tel != "" && tel != null),
      observaciones: this.form.value.observaciones,
      fechaAlta: this.form.value.fechaAlta,
    }

    /*if (this.form.invalid) {
      this._notificationService.error("La operación no se ha podido realizar", "Existen datos incorrectos o incompletos")
      this.isSubmitted = true;
    }
    else {
      
      this.companiaSegurosService.update(this.companiaSegurosUpdate).subscribe({
        next: (data) => {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente")
          
          this.data.companiaSaved = data.body;
          this.isSubmitted = false;
          this.onNoClick();
          // this.reloadToMenuOption()
        }
      })
    }*/

    this.companiaSegurosService.create(this.companiaSegurosUpdate).subscribe({
      next: (data) => {
        if (data['message'].status == "608") {
          this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
          this.data.companiaSaved = data;
          this.isSubmitted = false;
          this.onNoClick();
        } else {
          this.isSubmitted = true;
          this._notificationService.error("La operación no se ha podido realizar", data['message'], 3000);
        }
      }
    })

  }

  onNoClick() {
    this.dialogRef.close({ companiaSeguros: this.data.companiaSaved });
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUnsavedDialog, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'descartar') {
        this.dialogRef.close()
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '30vw';
    dialogConfig.maxWidth = '30vw'
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '200ms';
    const dialogRef = this.dialog.open(ConfirmarUpdateDialog, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
        // this.reloadToMenuOption()
      }
    })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 19 } });
  }
}

export interface DialogData {
  companiaSaved: ICompaniaSeguros;
}
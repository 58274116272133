import { Component } from '@angular/core';

@Component({
  selector: 'app-manual-usuario',
  templateUrl: './manual-usuario.component.html',
  styleUrls: ['./manual-usuario.component.css']
})
export class ManualUsuarioComponent {

  showIndice = true;

  showFuncionamiento = false;
  showLicencias = false;
  showPropietarios = false;
  showConductores = false;
  showVehiculos = false;
  showSubidaDoc = false;
  showCaja = false;
  showGestionCaja = false;
  showAdministrar = false;
  showInformes = false;

  toggleIndice() {
    this.showIndice = !this.showIndice;
    if (this.showIndice) {
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleFuncionamiento() {
    this.showFuncionamiento = !this.showFuncionamiento;
    if (this.showFuncionamiento) {
      this.showIndice = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleLicencias() {
    this.showLicencias = !this.showLicencias;
    if (this.showLicencias) {
      this.showFuncionamiento = false;
      this.showIndice = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  togglePropietarios() {
    this.showPropietarios = !this.showPropietarios;
    if (this.showPropietarios) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleConductores() {
    this.showConductores = !this.showConductores;
    if (this.showConductores) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleVehiculos() {
    this.showVehiculos = !this.showVehiculos;
    if (this.showVehiculos) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleSubidaDoc() {
    this.showSubidaDoc = !this.showSubidaDoc;
    if (this.showSubidaDoc) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleCaja() {
    this.showCaja = !this.showCaja;
    if (this.showCaja) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleGestionCaja() {
    this.showGestionCaja = !this.showGestionCaja;
    if (this.showGestionCaja) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showAdministrar = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleAdministrar() {
    this.showAdministrar = !this.showAdministrar;
    if (this.showAdministrar) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showInformes = false;
    } else {
      this.showIndice = true;
    }
  }

  toggleInformes() {
    this.showInformes = !this.showInformes;
    if (this.showInformes) {
      this.showIndice = false;
      this.showFuncionamiento = false;
      this.showLicencias = false;
      this.showPropietarios = false;
      this.showConductores = false;
      this.showVehiculos = false;
      this.showSubidaDoc = false;
      this.showCaja = false;
      this.showGestionCaja = false;
      this.showAdministrar = false;
    } else {
      this.showIndice = true;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConceptosProveedores } from 'src/app/model/novotaxi/proveedores/concepto-proveedores.model';
import { Proveedor } from 'src/app/model/novotaxi/proveedores/proveedor.model';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { NotificationService } from 'src/app/_services/notification.service';
import { ProveedoresService } from 'src/app/_services/novotaxi/proveedores/proveedores.service';
import { DataConceptosProveedores } from '../listado-proveedores/listado-proveedores.component';

@Component({
  selector: 'app-create-update-proveedor',
  templateUrl: './create-update-proveedor.component.html',
  styleUrls: ['./create-update-proveedor.component.css']
})
export class CreateUpdateProveedorComponent implements OnInit {

  @Input() data: DataConceptosProveedores;
  @Output() mostrarFormulario = new EventEmitter<DataConceptosProveedores>();

  conceptosList = [{ id: null, concepto: null, iva: null }];
  proveedorSave: Proveedor = {};
  conceptoProveedor: ConceptosProveedores = null;
  conceptosProveedoresList: ConceptosProveedores[] = [];
  conceptosByProveedor: ConceptosProveedores[] = [];
  isDelete: boolean = false;


  constructor(
    private _proveedorService: ProveedoresService,
    private _notificationService: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (this.data.isForUpdate) {
      this.proveedorSave = JSON.parse(JSON.stringify(this.data.elementUpdate.proveedor))
      if (this.proveedorSave.fechaBaja != null) {
        this.isDelete = true;
      } else {
        this.isDelete = false;
      }
      this.getConceptos();
    }
  }

  saveProveedorConceptos() {
    this.validConceptos();    

    this._proveedorService.save(this.conceptosProveedoresList).subscribe((data) => {      
      if (data['status'] == 608) {
        this._notificationService.success("OK", "Proveedor guardado correctamente", 3000);
        this.resetearForm();
      } else {
        this._notificationService.error("ERROR", data['message']['message'], 3000);
        this.resetearForm();
      }
      this.conceptosProveedoresList = [];
    })
  }

  getConceptos() {
    this._proveedorService.getConceptosByProveedor(this.data.elementUpdate.proveedor.id).subscribe((data) => {
      this.conceptosByProveedor = data['conceptos'];
      if (data['conceptos'] != null) {
        this.conceptosList.pop();
        data['conceptos'].forEach(concepto => {
          this.conceptosList.push({
            id: concepto.id, concepto: concepto.concepto, iva: concepto.iva
          });
        });
      }
    })
  }

  validConceptos() {
    this.conceptosList.forEach((element) => {
      if (element.concepto != null || element.concepto != '') {
        if (element.id != null) {
          let concepto = this.conceptosByProveedor.find(concepto => concepto.id == element.id);
          concepto.iva = element.iva;
          concepto.concepto = element.concepto;

          let conceptoSave = new ConceptosProveedores(concepto);
          conceptoSave.proveedor = new Proveedor(this.proveedorSave);
          this.conceptosProveedoresList.push(conceptoSave);
        } else {
          let concepto = new ConceptosProveedores(element);
          concepto.proveedor = new Proveedor(this.proveedorSave);
          this.conceptosProveedoresList.push(concepto);
        }

      }
    });
  }

  async openDialogConfirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = "guardar"

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.saveProveedorConceptos();
      } else {
        null
      }
    })
  }

  async confirmCancelOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = 'descartar';

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      } else {
        null
      }
    })
  }

  openDialogConfirmarActivate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = "activar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.activateProveedor();
      }
    })
  }

  activateProveedor() {
    this._proveedorService.activateProveedor(this.data.elementUpdate.proveedor.id).subscribe((data) => {
      if (data['status'] == 642) {
        this._notificationService.success("OK", "Proveedor activado correctamente", 3000)
      } else {
        this._notificationService.error("ERROR", "Error al activar el proveedor", 3000)
      }
      this.resetearForm();
    })
  }

  addConcepto() {
    this.conceptosList.push({
      id: null, concepto: null, iva: null
    });
  }

  removeConcepto(i) {
    this.conceptosList.splice(i, 1);
  }

  resetearForm() {
    this.data.mostrarFormulario = false;
    this.mostrarFormulario.emit(this.data);
  }
}

import { IProvincia } from "src/app/model/novotaxi/propietario.model";

export class ICompaniaSeguros {
    id?:                number | null;
    fechaAlta?:         string | null;
    fechaBaja?:         Date | null;
    fechaModificacion?: Date | null;
    nombre?:            string | null;
    cif?:               string | null;
    domicilio?:         string | null;
    codigoPostal?:      string | null;
    municipio?:         string | null;
    provincia?:         IProvincia | null;
    mail?:              string[] | null;
    telefono?:          string[] | null;
    observaciones?:     string | null;

    constructor(mappedData?) {
        this.id =                mappedData?.id
        this.fechaAlta =         mappedData?.fechaAlta;
        this.fechaBaja =         mappedData?.fechaBaja;
        this.fechaModificacion = mappedData?.fechaModificacion;
        this.nombre =            mappedData?.nombre;
        this.cif =               mappedData?.cif;
        this.domicilio =         mappedData?.domicilio;
        this.codigoPostal =      mappedData?.codigoPostal;
        this.municipio =         mappedData?.municipio;
        this.provincia =         mappedData?.provincia;
        this.mail =              mappedData?.mail;
        this.telefono =          mappedData?.telefono;
        this.observaciones =     mappedData?.observaciones;
    }
}

export type NewCompaniaSeguros = Omit<ICompaniaSeguros, 'id'> & { id: null };

import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.css']
})

export class MultiSelectDropdownComponent implements AfterViewInit {

    @Input() listColumns: Map<string, boolean>;
    @Output() shareCheckedList = new EventEmitter();
    @Output() shareIndividualCheckedList = new EventEmitter();

    checkedList: any[];

    currentSelected: {};
    showDropDown = false;
    keys;
    allKeys;
    constructor() {
        this.checkedList = [];
    }

    ngAfterViewInit(): void {
        this.keys = Object.keys(this.listColumns);
        this.allKeys = this.keys;
    }

    focusOut() {
        this.nameSearch = "";
        this.keys = this.allKeys; 
    }

    getSelectedValue(status: Boolean, value: String) {
        if (status) {
            this.checkedList.push(value);
        } else {
            var index = this.checkedList.indexOf(value);
            this.checkedList.splice(index, 1);
        }
        this.currentSelected = { checked: status, name: value };
        this.shareCheckedlist();
        this.shareIndividualStatus();
    }

    shareCheckedlist() {
        this.shareCheckedList.emit(this.checkedList);
    }

    shareIndividualStatus() {
        this.shareIndividualCheckedList.emit(this.currentSelected);
    }
    nameSearch: string;
    filterColumns(event) {
        this.nameSearch = event.target.value;
        if (this.nameSearch && this.nameSearch != "") {
            this.keys = this.allKeys.filter(key => key.toLowerCase().includes(this.nameSearch.toLowerCase()));
        } else {
            this.keys = this.allKeys;
        }
    }

    showAllColumns(){
        Object.keys(this.listColumns).forEach(value => this.listColumns[value] = true)
    }
}
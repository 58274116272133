export class Informe {
    id?:                  number;
    fechaAlta?:           string | null;
    fechaBaja?:           Date | null;
    fechaModificacion?:   Date | null;
    importe_bruto?:       number;
    total_invertaxi?:     number;
    importe_conductor?:   number;
    importe_propietario?: number;
    total_propietario?:   number;

    constructor() {
        this.id =                   null;
        this.fechaAlta =            null
        this.fechaBaja =            null
        this.fechaModificacion =    null
        this.importe_bruto =        null;
        this.total_invertaxi =      null;
        this.importe_conductor =    null;
        this.importe_propietario =  null;
        this.total_propietario =    null;
    }
}
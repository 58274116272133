import { IVehiculo } from './../vehiculo.model';
import { VehiculoService } from "../services/vehiculo.service";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalConfirmacionComponent } from 'src/app/novotaxi/modal-confirmacion/modal-confirmacion.component';
import { InformePDF } from 'src/app/novotaxi/licencias/informe-licencia/informePDF';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CardDetallesGraficosComponent } from 'src/app/novotaxi/licencias/card-detalles-graficos/card-detalles-graficos.component';
import { ModalBajaComponent } from 'src/app/modal/modal-baja/modal-baja.component';
import { ExcelExportService } from 'src/app/_services/excel-export.service';

@Component({
  selector: 'app-listado-vehiculos',
  templateUrl: './listado-vehiculos.component.html',
  styleUrls: ['./listado-vehiculos.component.css'],
})

export class ListadoVehiculosComponent implements OnInit {

  displayedColumns: string[] = ['licencia.numero_licencia', 'matricula', 'licencia.dia_libranza', 'modelo.marca.nombre', 'modelo.nombre',
    'seguro.ciaSeguros.nombre', 'seguro.fechaVencimiento', 'seguro.ciaSeguros.telefono', 'seguro.mutuaPadronal',
    'taximetro.casaTaximetro', 'taximetro.claseTaximetro', 'documentacion.revision.vencimientoTaximetro', 'taximetro.ultimaTarifaAplicada',
    'taximetro.fechaUltimoCambioDeTarifa', 'mantenimiento.kmEntrada', 'mantenimiento.kmUltimoCambioDeAceite', 'mantenimiento.kmProximoCambioDeAceite',
    'mantenimiento.tipoAceite', 'mantenimiento.fechaUltimaDistribucion', 'mantenimiento.fechaProximaDistribucion',
    'mantenimiento.hasFiltroCombustible', 'mantenimiento.hasFiltroAire', 'mantenimiento.hasFiltroHabitaculo', 'mantenimiento.extintor',
    'mantenimiento.boletinMeteorologico', 'mantenimiento.tarjetaTransporte', 'documentacion.revision.vencimientoITV', 'documentacion.revision.ultimaRevision',
    'fechaBaja', 'tipo.nombre', 'acciones'];

  displayedSearchHeaders: string[] = ['licencia_buscador', 'matricula_buscador', 'libranza_buscador', 'marca_buscador', 'modelo_buscador', 'ciaSeguros_buscador',
    'vencimientoSeguro_buscador', 'telefonoSeguro_buscador', 'mutua_buscador', 'casaTaximetro_buscador', 'claseTaximetro_buscador', 'vencimientoTaximetro_buscador',
    'ultimaTarifa_buscador', 'fechaUltimaTarifa_buscador', 'kmEntrada_buscador', 'kmUltimoAceite_buscador', 'kmProximoAceite_buscador', 'tipoAceite_buscador', 'fechaUltimaDistribucion_buscador',
    'fechaProximaDistribucion_buscador', 'filtroCombustible_buscador', 'filtroAire_buscador', 'filtroHabitaculo_buscador', 'extintor_buscador', 'boletinMeteorologico_buscador', 'tarjetaTransporte_buscador', 'vencimientoITV_buscador',
    'fechaUltimaRevision_buscador', 'activo_buscador', 'tipoVehiculo_buscador', 'acciones_buscador'];

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesGraficosComponent) detallesComponent;

  @Input() nombreMenu;

  dataSource = new MatTableDataSource<IVehiculo>;
  listaVehiculos?: IVehiculo[];
  listColumns;
  tipoDetalle: string = 'vehiculos';
  vehiculoUpdate;

  mostrarFormulario;
  editar;

  //Filtrado por columnas:
  public searchForm: FormGroup;
  licencia: ''
  matricula: '';
  libranza: '';
  marca: '';
  modelo: '';
  ciaSeguros: '';
  fechaVencimiento: '';
  telefono: '';
  mutua: '';
  casaTaximetro: '';
  claseTaximetro: '';
  vencimientoTaximetro: '';
  fechaUltimoCambioTarifa: '';
  kmEntrada: '';
  ultimoCambioAceite: '';
  proximoCambioAceite: '';
  tipoAceite: '';
  ultimaDistribucion: '';
  proximaDistribucion: '';
  vencimientoITV: '';
  ultimaRevision: '';
  tipo: '';

  //Variables para filtrar con ckeckbox
  isUltimaTarifaAplicadaActiva = true;
  isUltimaTarifaAplicadaInactiva = true;

  isHasFiltroCombustibleActivo = true;
  isHasFiltroCombustibleInactivo = true;
  isHasFiltroAireActivo = true;
  isHasFiltroAireInactivo = true;
  isHasFiltroHabitaculoActivo = true;
  isHasFiltroHabitaculoInactivo = true;
  isHasExtintorActivo = true;
  isHasExtintorInactivo = true;
  isHasBoletinMeteorologicoActivo = true;
  isHasBoletinMeteorologicoInactivo = true;
  isHasTarjetaTransporteActivo = true;
  isHasTarjetaTransporteInactivo = true;
  isActivo = true;
  isInactivo = true;

  // -- Variables optimización
  public filters = [];
  public sorts = [];
  public totalElements = 0;

  // -- Variables filtrado booleanos
  isUltimaTarifaAplicada: boolean = null;
  isHasCombustible: boolean = null;
  isHasAire: boolean = null;
  isHasHabitaculo: boolean = null;
  isExtintor: boolean = null;
  isBoletinMeteorologico: boolean = null;
  isTarjetaTransporte: boolean = null;

  // -- Paginado
  length = this.listaVehiculos?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  constructor(
    private vehiculoService: VehiculoService,
    private dialog: MatDialog,
    private router: Router,
    public _notificationService: NotificationService,
    private informe: InformePDF,
    private _excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {

    this.listColumns = {
      'Licencia': true,
      'Matrícula': true,
      'Libranza': true,
      'Marca': true,
      'Modelo': true,
      'Compañía de seguros': true,
      'Fecha vencimiento': true,
      'Tlf contacto': true,
      'Mutua padronal': true,
      'Casa taxímetro': true,
      'Clase taxímetro': true,
      'Vencimiento taxímetro': true,
      'Última tarifa aplicada': true,
      'Último cambio de tarifa': true,
      'Km entrada': true,
      'Último cambio de aceite': true,
      'Próximo cambio de aceite': true,
      'Tipo de aceite': true,
      'Última distribución': true,
      'Próxima distribución': true,
      'Filtro de combustible': true,
      'Filtro de aire': true,
      'Filtro de habitáculo': true,
      'Extintor': true,
      'Boletín meteorologico': true,
      'Tarjeta transporte': true,
      'Vencimiento ITV': true,
      'Última revisión': true,
      'Tipo': true,
      'Activo': true
    }

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getAllVehiculos(request)
    this.searchFormInit();
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  generarInforme() {
    let nombre = 'Vehiculo';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  setMostrarFormulario(mode: Boolean, vehiculo?: IVehiculo, tipo?: Boolean) {
    this.mostrarFormulario = mode;
    this.vehiculoUpdate = vehiculo;
    this.editar = tipo;
  }

  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
    }

    this.getAllVehiculos(request);
    this.detallesComponent.getAllVehiculos();
  }

  async getAllVehiculos(request?) {
    await this.vehiculoService.getVehiculos(request).subscribe((data) => {
      this.listaVehiculos = []
      this.totalElements = 0;
      if (data['vehiculos']) {
        if (data['vehiculos']['content']) {
          for (const vehiculoJSON of data['vehiculos']['content']) {
            this.listaVehiculos.push(new IVehiculo(vehiculoJSON));
          }
        }

        this.totalElements = data['vehiculos']['totalElements'];
      }

      this.dataSource = new MatTableDataSource(this.listaVehiculos);
    })
  }

  //Muestra un dialog para que el usuario confirme el borrado de un determinado elemento
  openDialogBorrarVehiculo(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(ModalBajaComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result != '') {
        this.delete(id, result.result);
      }
    })
  }

  delete(id: number, fecha: Date): void {
    this.vehiculoService.delete(id, fecha).subscribe({
      next: (data) => {

        let request = {
          "filters": this.filters,
          "sorts": this.sorts,
          page: this.paginator.pageIndex,
          size: this.paginator.pageSize,
          //groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
        }

        this.getAllVehiculos(request);
        this._notificationService.success("Operación realizada con éxito", "El conductor ha sido desactivado", 3000)
        this.detallesComponent.getAllVehiculos();
      }
    });
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin-novotaxi'], { queryParams: { action: 7 } });
  }

  // -- Filtrado vehiculos con optimización

  searchFormInit() {
    this.searchForm = new FormGroup({
      licencia: new FormControl(''),
      matricula: new FormControl(''),
      libranza: new FormControl(''), // Se realizará este filtrado cuando se haga la relación con licencia y se obtenga el día de libranza desde licencia
      marca: new FormControl(''),
      modelo: new FormControl(''),
      ciaSeguros: new FormControl(''),
      fechaVencimiento: new FormControl(''),
      tlfContacto: new FormControl(''),
      mutuaPadronal: new FormControl(''),
      casaTaximetro: new FormControl(''),
      claseTaximetro: new FormControl(''),
      vencimientoTaximetro: new FormControl(''),
      fechaUltimoCambioDeTarifa: new FormControl(''),
      kmEntrada: new FormControl(''),
      kmUltimoCambioDeAceite: new FormControl(''),
      kmProximoCambioDeAceite: new FormControl(''),
      tipoAceite: new FormControl(''),
      fechaUltimaDistribucion: new FormControl(''),
      fechaProximaDistribucion: new FormControl(''),
      vencimientoITV: new FormControl(''),
      ultimaRevision: new FormControl(''),
      tipo: new FormControl(''),
    });
  }

  async applyFilter(event?, key?, type?) {

    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];
    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event.target.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {


    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type.toUpperCase() == "BOOLEAN") {
        filter.operator = "EQUAL"
        this.setFilterBoolean(filter);
      }

      if (type.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }
      if (type.toUpperCase() == "DATE_BETWEEN" || type.toUpperCase() == "LOCALDATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == "ISNULLDATE") {
        this.isNullFechaBaja(filter);
      }

      if (type.toUpperCase() == "DOUBLE" || type.toUpperCase() == "LONG" || type.toUpperCase() == "BIG_DECIMAL" || type.toUpperCase() == "INTEGER") {
        filter.operator = "LIKE"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == 'ENUM_ACEITE') {
        filter.operator = "LIKE_ENUM_ACEITE"
        filter.field_type = "STRING"
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
            if (type.toUpperCase() == "ISNULLDATE") {
              this.isNullFechaBaja(filter);
            } else if (type.toUpperCase() == "BOOLEAN") {
              this.setFilterBoolean(filter);
            }
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,

    }

    this.paginator.firstPage();
    await this.getAllVehiculos(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
      // groupedby: ["vehiculo.conductores.id", "vehiculo.id"]
    };

    await this.getAllVehiculos(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }
      }
      this.sorts = [{
        "key": sort.active,
        "direction": direction
      }];
      request['sorts'] = this.sorts;
    }

    await this.getAllVehiculos(request)
  }

  isNullFechaBaja(filter) {
    filter.field_type = "DATE";
    if (
      (this.isActivo == true && this.isInactivo == false) ||
      (this.isActivo == false && this.isInactivo == true)
    ) {
      if (this.isActivo == true) {
        filter.operator = "IS_NULL";
        filter.value = null;
      }

      if (this.isInactivo == true) {
        filter.operator = "IS_NOT_NULL";
      }
    } else if (this.isActivo == false && this.isInactivo == false) {
      filter.operator = "IS_NULL";
    } else if (this.isActivo == true && this.isInactivo == true) {
      filter.operator = "IS_NULL";
    }
  }

  // método para setear filtrado en booleanos
  setFilterBoolean(filter) {

    switch (filter.key) {
      case 'taximetro.ultimaTarifaAplicada':
        if (!this.isUltimaTarifaAplicadaActiva || !this.isUltimaTarifaAplicadaInactiva) {
          filter.value = this.isUltimaTarifaAplicada;
        } else {
          let index = this.filters.findIndex(x => x.key === "taximetro.ultimaTarifaAplicada");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.hasFiltroCombustible':
        if (!this.isHasFiltroCombustibleActivo || !this.isHasFiltroCombustibleInactivo) {
          filter.value = this.isHasCombustible;
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.hasFiltroCombustible");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.hasFiltroAire':
        if (!this.isHasFiltroAireActivo || !this.isHasFiltroAireInactivo) {
          filter.value = this.isHasAire;
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.hasFiltroAire");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.hasFiltroHabitaculo':
        if (!this.isHasFiltroHabitaculoActivo || !this.isHasFiltroHabitaculoInactivo) {
          filter.value = this.isHasHabitaculo;
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.hasFiltroHabitaculo");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.extintor':
        if (!this.isHasExtintorActivo || !this.isHasExtintorInactivo) {
          filter.value = this.isExtintor;
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.extintor");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.boletinMeteorologico':
        if (!this.isHasBoletinMeteorologicoActivo || !this.isHasBoletinMeteorologicoInactivo) {
          filter.value = this.isBoletinMeteorologico
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.boletinMeteorologico");
          this.filters.splice(index, 1);
        }
        break;
      case 'mantenimiento.tarjetaTransporte':
        if (!this.isHasTarjetaTransporteActivo || !this.isHasTarjetaTransporteInactivo) {
          filter.value = this.isTarjetaTransporte;
        } else {
          let index = this.filters.findIndex(x => x.key === "mantenimiento.tarjetaTransporte");
          this.filters.splice(index, 1);
        }
        break;
    }
  }

  //  -- CheckBox
  setActivoInactivo(event, activoInactivo) {
    if (activoInactivo == "activo") {
      this.isActivo = event.target.checked;
    }
    if (activoInactivo == "inactivo") {
      this.isInactivo = event.target.checked;
    }
    this.applyFilter(event, "fechaBaja", "ISNULLDATE");
  }

  // método para booleanos
  setUltimaTarifaAplicada(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isUltimaTarifaAplicadaActiva = event.target.checked
      this.isUltimaTarifaAplicada = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isUltimaTarifaAplicadaInactiva = event.target.checked
      this.isUltimaTarifaAplicada = true;
    }

    this.applyFilter(event, "taximetro.ultimaTarifaAplicada", "BOOLEAN");
  }

  setHasCombustible(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasFiltroCombustibleActivo = event.target.checked
      this.isHasCombustible = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasFiltroCombustibleInactivo = event.target.checked
      this.isHasCombustible = true;
    }
    this.applyFilter(event, "mantenimiento.hasFiltroCombustible", "BOOLEAN");
  }

  setHasAire(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasFiltroAireActivo = event.target.checked
      this.isHasAire = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasFiltroAireInactivo = event.target.checked
      this.isHasAire = true;
    }
    this.applyFilter(event, "mantenimiento.hasFiltroAire", "BOOLEAN");
  }

  setHasHabitaculo(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasFiltroHabitaculoActivo = event.target.checked
      this.isHasHabitaculo = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasFiltroHabitaculoInactivo = event.target.checked
      this.isHasHabitaculo = true;
    }
    this.applyFilter(event, "mantenimiento.hasFiltroHabitaculo", "BOOLEAN");
  }

  setHasExtintor(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasExtintorActivo = event.target.checked
      this.isExtintor = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasExtintorInactivo = event.target.checked
      this.isExtintor = true;
    }
    this.applyFilter(event, "mantenimiento.extintor", "BOOLEAN");
  }

  setHasBoletinMeteorologico(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasBoletinMeteorologicoActivo = event.target.checked;
      this.isBoletinMeteorologico = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasBoletinMeteorologicoInactivo = event.target.checked
      this.isBoletinMeteorologico = true;
    }
    this.applyFilter(event, "mantenimiento.boletinMeteorologico", "BOOLEAN");

  }

  setHasTarjetaTransporte(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isHasTarjetaTransporteActivo = event.target.checked;
      this.isTarjetaTransporte = false;
    }
    if (activoInactivo == 'inactivo') {
      this.isHasTarjetaTransporteInactivo = event.target.checked
      this.isTarjetaTransporte = true;
    }
    this.applyFilter(event, "mantenimiento.tarjetaTransporte", "BOOLEAN");
  }
  // -- Fin filtrado vehiculos con optimización

  // -- Exportación a excel
  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, fechaBaja, fechaModificacion, documentacion, taximetro, mantenimiento, observaciones, conductores, seguro, ...rest } = item;

    if (item.licencia) {
      rest.licencia = item.licencia.numero_licencia;
    }

    if (item.tipo) {
      rest.tipo = item.tipo.nombre
    }

    if (item.modelo) {
      rest.modelo = item.modelo.nombre
    }

    if (item.tlf_cia_seguros) {
      rest.tlf_cia_seguros = item.tlf_cia_seguros?.join(', ');
    }

    return rest;
  }

  generateExcel() {
    const data = this.dataSource.data.map((vehiculo) => {
      const formattedVehiculo = { ...vehiculo };
      formattedVehiculo.fechaAlta = this.formatDate(vehiculo.fechaAlta).toLocaleDateString();
      formattedVehiculo.fechaModificacion ? formattedVehiculo.fechaModificacion = this.formatDate(vehiculo.fechaModificacion) : null;
      return formattedVehiculo;
    });

    this.addRows(data);

    const dataFormatted = data.map((vehiculo) => {
      const formattedVehiculo = { ...vehiculo };
      formattedVehiculo.fechaAlta = this.formatDate(vehiculo.fechaAlta).toLocaleDateString();
      formattedVehiculo.fechaModificacion ? formattedVehiculo.fechaModificacion = this.formatDate(vehiculo.fechaModificacion) : null;
      return this.filterFields(formattedVehiculo);
    });

    const fileName = 'Tabla_vehiculos';
    this._excelService.exportToExcel(dataFormatted, fileName);
  }

  addRows(data) {
    data.forEach((vehiculo) => {      
      vehiculo.marca = vehiculo.modelo?.marca?.nombre;
      vehiculo.fecha_vencimiento = vehiculo.seguro?.fechaVencimiento;
      vehiculo.libranza = vehiculo.licencia?.dia_libranza;
      vehiculo.cia_seguros = vehiculo.seguro?.ciaSeguros?.nombre;
      vehiculo.tlf_cia_seguros = vehiculo.seguro?.ciaSeguros?.telefono;
      vehiculo.mutua_patronal = vehiculo.seguro?.mutuaPadronal;
      vehiculo.casa_taximetro = vehiculo.taximetro?.casaTaximetro;
      vehiculo.clase_taximetro = vehiculo.taximetro?.claseTaximetro;
      vehiculo.vencimiento_taximetro = vehiculo.documentacion?.revision?.vencimientoTaximetro;
      vehiculo.ultima_tarifa_aplicada = vehiculo.taximetro?.ultimaTarifaAplicada;
      vehiculo.fecha_ultimo_cambio_de_tarifa = vehiculo.taximetro?.fechaUltimoCambioDeTarifa;
      vehiculo.km_entrada = vehiculo.mantenimiento?.kmEntrada;
      vehiculo.ultimo_cambio_aceite = vehiculo.mantenimiento?.kmUltimoCambioDeAceite;
      vehiculo.proximo_cambio_aceite = vehiculo.mantenimiento?.kmProximoCambioDeAceite;
      vehiculo.tipo_aceite = vehiculo.mantenimiento?.tipoAceite;
      vehiculo.fecha_ultima_distribucion = vehiculo.mantenimiento?.fechaUltimaDistribucion;
      vehiculo.fecha_proxima_distribucion = vehiculo.mantenimiento?.fechaProximaDistribucion;
      vehiculo.filtro_combustible = vehiculo.mantenimiento?.hasFiltroCombustible;
      vehiculo.filtro_aire = vehiculo.mantenimiento?.hasFiltroAire;
      vehiculo.filtro_habitaculo = vehiculo.mantenimiento?.hasFiltroHabitaculo;
      vehiculo.extintor = vehiculo.mantenimiento?.extintor;
      vehiculo.boletin_meteorologico = vehiculo.mantenimiento?.boletinMeteorologico;
      vehiculo.tarjeta_transporte = vehiculo.mantenimiento?.tarjetaTransporte;
      vehiculo.vencimientoITV = vehiculo.documentacion?.revision?.vencimientoITV;
      vehiculo.ultima_revision = vehiculo.documentacion?.revision?.ultimaRevision;
      vehiculo.activo = vehiculo.fechaBaja ? "Activo" : "Inactivo";
    });
  }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, throwError } from "rxjs";
import { environment } from 'src/environments/environment.novotaxi';

@Injectable({
  providedIn: "root",
})

export class PrestamosService {

  API_URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  searchPrestamos(mapa) {
    return this.http
      .post(`${this.API_URL}/prestamosdevoluciones/search`, mapa)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveAllPrestamos(listPrestamos) {  
    return this.http
      .post(`${this.API_URL}/prestamosdevoluciones/saveAll`, listPrestamos, {})
      /* .pipe(retry(1), catchError(this.handleError)); */
  }

  savePrestamo(prestamo) {  
    return this.http
      .post(`${this.API_URL}/prestamosdevoluciones`, prestamo, {})
  }

  delete(id: number) {
    return this.http.get(`${this.API_URL}/prestamosdevoluciones/delete/${id}`, {})
  }

  geTTotalesPrestamos(map) {
    return this.http
      .post(`${this.API_URL}/prestamosdevoluciones/getTotal`, map)
  }

  getPrestamos(request?) {
    return this.http
      .post(`${this.API_URL}/prestamosdevoluciones/list`, request)
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}


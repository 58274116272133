import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { ModalConfirmacionComponent } from '../../modal-confirmacion/modal-confirmacion.component';
import { IMarca, IModelo } from '../modelo.model';
import { MarcaService } from '../services/marca.service';

@Component({
  selector: 'app-crear-marca-modelo',
  templateUrl: './crear-marca-modelo.component.html'
})
export class CrearMarcaModeloComponent {

  constructor(
    protected marcaService: MarcaService,
    public servicioGeneral: ServicioGeneral,
    private router: Router,
    public dialog: MatDialog,
    public _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getAllMarca()
    this.getAllModelo()
    this.initiateForm();
  }

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  //@Input() companiaUpdate: ICompaniaSeguros;
  @Input() editar: Boolean;
  @Input() isMarca: Boolean;
  @Input() marcaModelo;

  @Output() mostrarFormulario = new EventEmitter<Boolean>();

  form;
  dataSource;
  vehiculoMarca: IMarca;
  vehiculoModelo: IModelo;
  isSubmitted: Boolean = false;
  isModelo: Boolean = false;
  listaMarcas?: IMarca[];
  listaModelos?: IModelo[];
  listaMarcasModelosFormat = [];
  vehiculoModeloUpdate;

  initiateForm(){
    this.form = new FormGroup({
      nombre: new FormControl(this.marcaModelo?.nombre ? this.marcaModelo.nombre : null, Validators.required),
      marca: new FormControl(this.marcaModelo?.marca.id ? this.marcaModelo.marca.id : 0, Validators.min(1)),
    })
  }
  
  getAllModelo(){
    this.marcaService.query().subscribe({
      next: (res) => {
        this.listaModelos = res['modelos'];
        this.listaModelos = this.listaModelos?.filter(modelo => modelo.fechaBaja == null);
        this.dataSource = new MatTableDataSource(this.listaModelos);
        //this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      }
    })
  }

  getAllMarca(){
    this.marcaService.queryMarcas().subscribe({
      next: (res) => {
        this.listaMarcas = res['marcas'];
        this.listaMarcas = this.listaMarcas?.filter(marca => marca.fechaBaja == null);
        this.listaMarcas = ordenarMarcasModelos(this.listaMarcas);
      }
    })
  }

  submit() {

    if(!this.editar){
      if (this.isMarca) {
        this.vehiculoMarca = {
          nombre: this.form.value.nombre,
        }
  
        this.marcaService.createMarca(this.vehiculoMarca).subscribe({
          next: (data) => {
    
            if (data['message'].status == "608") {
              this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente",3000);
              //this.dialogRef.close();
              this.isSubmitted = false;
              this.reloadToMenuOption();
            } else {
              this.isSubmitted = true;
              let message = data['message'];
              this._notificationService.error("La operación no se ha podido realizar", message, 3000);
              
            }
          }
        })
      }
  
      else {
        this.vehiculoModelo = {
          nombre: this.form.value.nombre,
          marca: this.form.value.marca == 0 ? null : (
            {
              id: this.form.value.marca
            }
          ),
        }
  
        this.marcaService.create(this.vehiculoModelo).subscribe({
          next: (data) => {
            if (data['message'].status == "608") {
              this._notificationService.success("Operación realizada con éxito", "Los datos han sido guardados correctamente", 3000);
              //this.dialogRef.close();
              this.isSubmitted = false;
              this.reloadToMenuOption();
            } 
            else {
              this.isSubmitted = true;
              let message = data['message'];
              this._notificationService.error("La operación no se ha podido realizar", message, 3000);
                    
            }
        
          }
        })
    }

    }
    else{
      this.vehiculoModeloUpdate = {
        id: this.marcaModelo.id,
        nombre: this.form.value.nombre,
        marca: this.form.value.marca == 0 ? null : (
          {
            id: this.form.value.marca
          }
        ),
      }
  
      this.marcaService.update(this.vehiculoModeloUpdate).subscribe({
        next: (data) => {
                
            if (data.body['message'].status == "608") {
              this._notificationService.success("Operación realizada con éxito", "Los datos han sido actualizados correctamente", 3000);
              //this.dialogRef.close();
              this.isSubmitted = false;
              this.reloadToMenuOption();
            } 
            else {
              this.isSubmitted = true;
              let message = data.body['message'];
              this._notificationService.error("La operación no se ha podido realizar", message, 3000);  
            }
          }
   
        }) 
    }
  }

  //Se muestra un dialog para que el usuario confirme la edición de los datos de un determinado elemento
  openDialogConfirmarUpdate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data="guardar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.submit();
      }
    })
  }

  //Se muestra un dialog para que el usuario confirme el descartar la edición de los datos de un determinado elemento
  openDialogConfirmarUnsaved() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.exitAnimationDuration = '500ms';
    dialogConfig.disableClose = true;
    dialogConfig.data="descartar";

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, dialogConfig);
    return dialogRef.afterClosed().subscribe(result => {
      if (result.result == 'Confirmar') {
        this.resetearForm();
      }
    })
  }

  //Recargamos el componente para mostrar los cambios realizados, tanto para crear, como para editar y borrar
  reloadToMenuOption() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  resetearForm(){
    this.form.reset()
    //this.form.controls.provincia.setValue(0);
    this.isSubmitted = false;
    this.mostrarFormulario.emit(false);
  }

  convertDate(date: string): string {
    if (date != null) {
      let fecha = new Date(date);
      let annio = fecha.getFullYear();
      let dia = fecha.getDate().toString().padStart(2, '0') //Añádir el 0 a la izquierda;
      let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      return (dia + '-' + mes + '-' + annio);
    }
    else return null
  }
}


function ordenarMarcasModelos(listado) {
  return listado?.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
}
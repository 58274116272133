<form [formGroup]="form" (ngSubmit)="openDialogConfirmarUpdate()" class="crear-editar-form">

  <h1 class="titulo-formulario" *ngIf="!editar">Crea un nuevo conductor</h1>

  <app-historico-conductor *ngIf="mostrarHistorico" (mostrarHistorico)="setMostrarHistorico($event)"
    [conductorHistorico]="conductorHistorico"
    [conductorLicenciaHistorico]="conductorLicenciaHistorico"></app-historico-conductor>

  <div *ngIf="!mostrarHistorico">
    <div class="d-flex flex-row justify-content-between align-items-start mt-2" *ngIf="editar">
      <h1 class="titulo-formulario">Edita un conductor</h1>

      <button class="btn btn-primary btn-sm align-middle" (click)="setMostrarHistorico(true)">
        <mat-icon class="align-middle mr-2">access_time</mat-icon><span class="align-middle">Mostrar
          histórico</span>
      </button>
    </div>







    <div class="row mb-4">
      <div class="form-group col-sm-4">
        <label for="vencimientoBTP">Vencimiento BTP</label>
        <input class="form-control" type="date" id="vencimientoBTP" formControlName="fechaVencimientoBTP">
      </div>
    </div>


    <div class="row mb-4">
      <div class="form-group mb-10" formArrayName="sueldos" style="width: 100%;">
        <div *ngFor="let item of sueldos.controls; let i = index" [formGroupName]="i" class="col-12">
          <span class="input-group">

            <div class="form-group col-sm-4 pl-0">
              <mat-label for="vehiculo">Licencia</mat-label>
              <mat-select id="vehiculo" class="form-control form-select" formControlName="vehiculo">
                <mat-option [value]=0>Selecciona una opción</mat-option>
                <mat-option [value]="vehiculo.id" *ngFor="let vehiculo of listaVehiculos">Licencia:
                  {{vehiculo?.licencia?.numero_licencia}}, vehículo:
                  {{vehiculo?.matricula}}</mat-option>
              </mat-select>
            </div>

            <div class="form-group col-sm-4">
              <mat-label for="tipoSueldo">Tipo sueldo</mat-label>
              <mat-select id="tipoSueldo" class="form-control form-select" formControlName="tipoSueldo">
                <mat-option [value]=0>Selecciona una opción</mat-option>
                <mat-option [value]="tipoSueldo.id"
                  *ngFor="let tipoSueldo of tipoSueldoList">{{tipoSueldo?.nombre}}</mat-option>
              </mat-select>
            </div>

            <div class="form-group col-sm-4">
              <mat-label for="tipoSueldo">Cantidad</mat-label>
              <span class="input-group">
                <input class="form-control" type="number" id="tipoSueldo" formControlName="cantidad">
                <div class="input-group-append">
                  <button type="button" class="btn-close btn btn-outline-primary-input"
                    *ngIf="i > 0 && conductorUpdate?.fechaBaja == null"
                    (click)="removeSueldos(i)"><mat-icon>close</mat-icon></button>
                </div>
              </span>
            </div>
          </span>
        </div>

        <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3 mb-2 ml-3"
          (click)="addSueldos()" *ngIf="conductorUpdate?.fechaBaja == null"><mat-icon>add</mat-icon> Añadir
          licencia</button>
      </div>
    </div>








    <!-- <div class="row mb-10">

      antigu
      <div class="form-group mb-10" formArrayName="vehiculo" style="width: 100%;">
        <div *ngFor="let option of filterList, let i = index" class="mb-3">
          <mat-label for="vehiculo">Licencia<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></mat-label>
          <span class="input-group">

            Antiguo
            <mat-select id="vehiculo" class="form-control form-select" formControlName="vehiculo" [formControlName]="i">
              <input #searchValue style="width: 100%;" (keyup)="onKey($event, i)">
              <mat-option [value]=0>Selecciona una licencia</mat-option>
              <mat-option [value]="vehiculo.id" *ngFor="let vehiculo of option.listVehiculos"
                (click)="setValueList(i)">Licencia:
                {{vehiculo?.licencia?.numero_licencia}}, vehículo: {{vehiculo?.matricula}}</mat-option>
            </mat-select>
            Fin antiguo

            <div class="form-group col-sm-3 pl-0">
              <mat-label for="vehiculo">Licencia<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></mat-label>
              <mat-select id="vehiculo" class="form-control form-select" formControlName="vehiculo"
                [formControlName]="i">
                <input #searchValue style="width: 100%;" (keyup)="onKey($event, i)">
                <mat-option [value]=0>Selecciona una licencia</mat-option>
                <mat-option [value]="vehiculo.id" *ngFor="let vehiculo of option.listVehiculos"
                  (click)="setValueList(i)">Licencia:
                  {{vehiculo?.licencia?.numero_licencia}}, vehículo: {{vehiculo?.matricula}}</mat-option>
              </mat-select>
            </div>

            <div class="form-group col-sm-3">
              <mat-label for="tipoSueldo">Tipo sueldo</mat-label>
              <mat-select id="tipoSueldo" class="form-control form-select" formControlName="tipoSueldo"
                [formControlName]="i">
                <mat-option [value]=0>Selecciona una opción</mat-option>
                <mat-option [value]="tipoSueldo.id" *ngFor="let tipoSueldo of tipoSueldoList">{{tipoSueldo?.nombre}}</mat-option>
              </mat-select>
            </div>


            <div class="form-group col-sm-3">
              <mat-label for="tipoSueldo">Cantidad</mat-label>
              <input class="form-control" type="number" id="tipoSueldo" formControlName="cantidad"
                [formControlName]="i">

              <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary-input"
                  *ngIf="i > 0 && conductorUpdate?.fechaBaja == null"
                  (click)="removeVehiculo(i)"><mat-icon>close</mat-icon></button>
              </div>
            </div>
          </span>
        </div>
        <button type="button" class="btn btn-primary btn-sm d-flex flex-row align-items-center mt-3 mb-2"
          (click)="addVehiculo()" *ngIf="conductorUpdate?.fechaBaja == null"><mat-icon>add</mat-icon> Añadir
          vehículo</button>
      </div>

      Antiguo
      <div class="form-group col-sm-4">
        <label for="vencimientoBTP">Vencimiento BTP</label>
        <input class="form-control" type="date" id="vencimientoBTP" formControlName="fechaVencimientoBTP">
      </div> 

    </div> -->

    <h2 class="divisiones-formularios">Datos</h2>
    <hr>

    <div class="row mb-4">

      <div class="form-group col-sm-1">
        <label for="tipoDocumento">Tipo <span style="color: red;">*</span></label>
        <select id="tipoDocumento" class="form-control form-select" formControlName="tipo_documento">
          <option [value]=0>Selecciona un tipo de documento</option>
          <option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of tipoDocumentoList">{{tipoDocumento.nombre}}
          </option>
        </select>
        <small class="text-danger" *ngIf="isSubmitted && form.get('tipo_documento').hasError('required')">El campo es
          obligatorio</small>
      </div>
      <div class="form-group col-sm-3">
        <label for="dni">Número de documento<span style="color: red;">*</span></label>
        <input class="form-control" type="text" id="dni" maxlength="9" minlength="9" formControlName="dni">
        <small class="text-danger" *ngIf="isSubmitted && form.get('dni').hasError('required')">El campo es
          obligatorio</small>
      </div>
      <div class="form-group col-sm-4">
        <label for="nombre">Nombre <span style="color: red;">*</span></label>
        <input class="form-control" type="text" id="nombre" formControlName="nombre">
        <small class="text-danger" *ngIf="isSubmitted && form.get('nombre').hasError('required')">El campo es
          obligatorio</small>
      </div>
      <div class="form-group col-sm-4">
        <label for="apellidos">Apellidos</label>
        <input class="form-control" type="text" id="apellidos" formControlName="apellidos">
      </div>

      <!-- <div class="form-group col-sm-4">
        <label for="tipoSueldo">Tipo sueldo<span style="color: red;">*</span></label>
        <select id="tipoSueldo" class="form-control form-select" formControlName="tipoSueldo">
          <option [value]=0>Selecciona una opción</option>
          <option [value]="tipoSueldo.id" *ngFor="let tipoSueldo of tipoSueldoList">{{tipoSueldo.nombre}}
          </option>
        </select>
        <small class="text-danger" *ngIf="isSubmitted && form.get('tipoSueldo').hasError('required')">El campo es
          obligatorio</small>
      </div> -->

      <!-- <div class="form-group col-sm-4" *ngIf="this.form.value?.tipoSueldo == 1">
        <label>Porcentaje % <span style="color: red;">*</span></label>
        <input class="form-control" type="number" id="porcentaje" formControlName="porcentaje"
          (focusout)="checkporcentaje()">
      </div>

      <div class="form-group col-sm-4" *ngIf="this.form.value?.tipoSueldo == 2">
        <label>Salario fijo <span style="color: red;">*</span></label>
        <input class="form-control" type="number" id="salario_fijo" formControlName="salario_fijo">
      </div>

      <div class="form-group col-sm-4" *ngIf="this.form.value?.tipoSueldo == 3">
        <label>Nómina <span style="color: red;">*</span></label>
        <input class="form-control" type="number" id="nomina" formControlName="nomina">
      </div> -->
    </div>

    <h2 class="divisiones-formularios">Dirección</h2>
    <hr>

    <div class="row">
      <div class="form-group col-sm-4">
        <label for="domicilio">Domicilio</label>
        <input class="form-control" type="text" id="domicilio" formControlName="domicilio">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4">
        <label for="provincia">Provincia</label>
        <select id="provincia" class="form-control form-select" formControlName="provincia"
          (change)="cargaMunicipios(form.value.provincia)">
          <option [value]=0>Selecciona una provincia</option>
          <option [value]="provincia.id" *ngFor="let provincia of listaProvincias">{{provincia.nombre}}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4">
        <label for="poblacion">Municipio</label>
        <select id="poblacion" class="form-control form-select" formControlName="poblacion">
          <option [value]=0>Selecciona un municipio</option>
          <option [value]="municipio.nombre" *ngFor="let municipio of municipiosToSelect">{{municipio.nombre}}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4">
        <label for="codigoPostal">Código postal</label>
        <input class="form-control" type="text" id="codigoPostal" formControlName="codigoPostal" minlength="5"
          maxlength="5" />
      </div>
    </div>

    <h2 class="divisiones-formularios mt-4">Contacto</h2>
    <hr>

    <div class="row mb-4">
      <div class="form-group col">
        <div class="form-group" formArrayName="telefono" *ngFor="let tel of telefonos.controls; let i = index">
          <div>
            <label for="telefono">Teléfono<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
            <span class="input-group">
              <input class="form-control" type="tel" id="telefono" [formControlName]="i" />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary-input"
                  *ngIf="i > 0 && conductorUpdate?.fechaBaja == null"
                  (click)="removeTelefono(i)"><mat-icon>close</mat-icon></button>
              </div>
            </span>
          </div>
          <small class="text-danger" *ngIf="isSubmitted && telefonos.controls[i].hasError('pattern')">El valor
            introducido no es un teléfono válido</small>
        </div>
        <button *ngIf="expandedPhone != 3 && conductorUpdate?.fechaBaja == null" type="button"
          class="btn btn-primary btn-sm d-flex flex-row align-items-center"
          (click)="addTelefono()"><mat-icon>add</mat-icon> Añadir
          teléfono</button>
      </div>
      <div class="form-group col">
        <div class="form-group" formArrayName="mail" *ngFor="let tel of emails.controls; let i = index">
          <div>
            <label for="mail">Correo electrónico<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
            <span class="input-group">
              <input class="form-control" type="email" id="mail" [formControlName]="i" />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary-input"
                  *ngIf="i > 0 && conductorUpdate?.fechaBaja == null"
                  (click)="removeEmail(i)"><mat-icon>close</mat-icon></button>
              </div>
            </span>
          </div>
          <small class="text-danger" *ngIf="isSubmitted && emails.controls[i].hasError('pattern')">El valor
            introducido no es un email válido</small>
        </div>
        <button *ngIf="expandedEmail != 3 && conductorUpdate?.fechaBaja == null" type="button"
          class="btn btn-primary btn-sm d-flex flex-row align-items-center"
          (click)="addEmail()"><mat-icon>add</mat-icon> Añadir email</button>
      </div>
      <div class="form-group col">
        <div class="form-group" formArrayName="cuentaBancaria"
          *ngFor="let tel of cuentasBancarias.controls; let i = index">
          <div>
            <label for="cuentaBancaria">Cuenta bancaria<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
            <span class="input-group">
              <input class="form-control" type="text" id="cuentaBancaria" [formControlName]="i" />
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary-input"
                  *ngIf="i > 0 && conductorUpdate?.fechaBaja == null"
                  (click)="removeCuentaBancaria(i)"><mat-icon>close</mat-icon></button>
              </div>
            </span>
          </div>
          <small class="text-danger" *ngIf="isSubmitted && cuentasBancarias.controls[i].hasError('pattern')">El valor
            introducido no es
            un iban válido</small>
        </div>
        <button *ngIf="expandedIban != 3 && conductorUpdate?.fechaBaja == null" type="button"
          class="btn btn-primary btn-sm d-flex flex-row align-items-center"
          (click)="addCuentaBancaria()"><mat-icon>add</mat-icon> Añadir
          cuenta</button>
      </div>
    </div>

    <h2 class="divisiones-formularios">Otros</h2>
    <hr>

    <div class="row">
      <div class="form-group col-sm-2">
        <label for="retencion">Retención</label>
        <input class="form-control" type="number" id="retencion" formControlName="retencion" />
      </div>
      <div class="form-group col-sm-2">
        <label for="valoracion">Valoración</label>
        <input class="form-control" type="number" id="valoracion" formControlName="valoracion" min="0" />
      </div>
      <div class="form-group col-sm-2">
        <label for="puntuacion">Puntuación</label>
        <input class="form-control" type="number" id="puntuacion" formControlName="puntuacion" min="0" />
      </div>
      <div class="form-check form-check-inline ml-5">
        <input class="form-check-input" type="checkbox" id="lopd" formControlName="lopd" />
        <label class="form-check-label" for="lopd">LOPD</label>
      </div>
      <div class="form-check form-check-inline ml-2">
        <input class="form-check-input" type="checkbox" id="riesgosLaborales" formControlName="riesgosLaborales">
        <label class="form-check-label" for="riesgosLaborales">Riesgos laborales</label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label for="observaciones">Observaciones</label>
        <textarea class="form-control" id="observaciones" rows="3" formControlName="observaciones"></textarea>
      </div>
    </div>


    <h2 class="divisiones-formularios">Documentos</h2>
    <hr>

    <br>
    <h6 class="col-sm-12 tituloAdjuntar">Adjuntar Ficheros</h6>

    <div class="col-sm-12 d-flex" style="padding: 0;">

      <!-- Parte adjuntar -->
      <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-3 p-3" appDrag
        (files)="filesDropped($event)">

        <div class="text-center ">
          <h6 class="text_bold mt-1">Arrastre y suelte aqui</h6>
          <h6 class="text">o</h6>
          <button class="btn btn-light btn_file">Seleccionar Archivos</button>
          <input id="file" value="file" name="file" class="input_file" (change)="onFileChange($event)"
            onclick="event.target.value=null" type="file" />
          <h6 class="indicaciones mt-1">PDF, JPEG o PNG de 20MB máximo. </h6>
        </div>
      </div>

      <!-- Parte documentos -->
      <div class="col-8 ordenar-archivos">
        <div *ngFor="let documento of this.arrayDocuments" class="card card-generic archivos-seleccionados">
          <a *ngIf="editar" class="cortar-texto">{{documento['nombre']}}</a>
          <a *ngIf="!editar" class="cortar-texto">{{documento['nombre']}}</a>
          <div class="card border-0 card-width-actions">
            <div>
              <a (click)="removeFile(arrayDocuments.indexOf(documento))"
                class="delete-button activated boton-eliminar"></a>
              <a *ngIf="editar" (click)="downloadDocument(documento)"
                class="download-button activated boton-eliminar"></a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="container" class="d-flex justify-content-end pt-3">

      <button [hidden]="editar && conductorUpdate?.fechaBaja != null" class="btn btn-success mx-3" type="submit">
        <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
        <div *ngIf="!uploadDocuments">Guardar</div>
      </button>
      <button *ngIf="conductorUpdate?.fechaBaja != null" class="btn btn-warning mx-3"
        (click)="openDialogConfirmarActivate()" type="button">Activar</button>
      <button class="btn btn-secondary" type="button"
        (click)="form.dirty ? openDialogConfirmarUnsaved() : resetearForm()">Cerrar</button>
    </div>
  </div>
</form>
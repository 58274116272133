<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Marcas/Modelos</h1>
    <div>
        <button class="btn btn-outline-primary align-middle">
            <mat-icon class="align-middle mr-2">article</mat-icon><span class="align-middle"(click)="generarInforme()">Generar informe</span> 
        </button>
        <button class="btn btn-primary align-middle mx-3" (click)="setMostrarFormulario(true, null, true)" [hidden]="ocultarBotonMarca">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva Marca</span> 
        </button>
        <button class="btn btn-primary align-middle mx-3" (click)="setMostrarFormulario(false, null, true)" [hidden]="!ocultarBotonMarca">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span> 
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true, null, false)" [hidden]="ocultarBotonModelo">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Modelo</span> 
        </button>
        <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(false, null, false)" [hidden]="!ocultarBotonModelo">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span> 
        </button>
    </div> 
</div>

<hr class="linea-divisoria">

<!-- Detalles/Gráficos -->
<!-- <app-card-detalles-graficos></app-card-detalles-graficos> -->
<!---->

<div class="px-5 pb-4 contenedor mb-5 pt-4">
    <div class="mostrarFormulario" *ngIf="mostrarFormulario">
        <app-crear-marca-modelo (mostrarFormulario)="setMostrarFormulario($event)" [isMarca]="isMarca" [marcaModelo]="marcaModelo" [editar]="editar"></app-crear-marca-modelo>
    </div>
    <div class="mostrarTabla" [hidden]="mostrarFormulario">
    <div class="d-flex flex-row justify-content-end align-items-center mb-4">
        <app-multi-select-dropdown [listColumns]="listColumns">
        </app-multi-select-dropdown>
    </div>
    <div class="table_container" id="scroll">

        <table mat-table [dataSource]="dataSource" matSort
        class="table align-middle mb-0 bg-white">
    
            <ng-container matColumnDef="marca.nombre">
                <th [hidden]="!listColumns['Marca']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Marca</th>
                <td [hidden]="!listColumns['Marca']" mat-cell *matCellDef="let element">{{element.marca.nombre}}</td>
            </ng-container>
    
            <ng-container matColumnDef="nombre">
                <th [hidden]="!listColumns['Modelo']" mat-header-cell *matHeaderCellDef mat-sort-header class="header">Modelo</th>
                <td [hidden]="!listColumns['Modelo']" mat-cell *matCellDef="let element">{{element.nombre}}</td>
            </ng-container>

            <ng-container matColumnDef="blank" class="blank-column">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"></th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>
            
            <ng-container matColumnDef="acciones" class="acciones" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="header"></th>
                <td mat-cell *matCellDef="let element" class="mat-column acciones">
                    <div class="buttons-flex-container justify-content-start">
                        <a class="eye-button activated" (click)="setMostrarFormulario(true, element, false, true)"></a> 
                        <a class ="delete-button activated" (click)="openDialogBorrarMarcaModelo(element.id)"></a>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
        
    </div> 
    
    <mat-paginator class="mt-3" #paginator
                (page)="handlePageEvent($event)" 
                [length]="length" 
                [pageSize]="pageSize"
                [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="pageIndex"
                aria-label="Select page" >
    </mat-paginator>
    </div>
</div>


import { IConductor } from "src/app/conductores/conductor.model";
import { Licencia } from "../../Emisora";
import { Propietario } from "../propietario.model";
import { Usuario } from "../gastos-ingresos/gastos-ingresos.model";
import { TipoInforme } from "./tipo_informe.model";

export class ControlInformes {
    id?: number | null;
    propietario?: Propietario | null;
    licencia?: Licencia | null;
    conductor?: IConductor | null;
    documento?: any | null;
    fechaDesde?: Date | null;
    fechaHasta?: Date | null;
    fechaCreacion?: Date | null;
    usuario?: Usuario | null;
    tipoInforme?: TipoInforme | null;

    constructor(mappedData) {
        this.id = mappedData?.id ?? null;
        this.propietario = new Propietario(mappedData?.propietario) ?? null;
        this.licencia = new Licencia(mappedData?.licencia) ?? null;
        this.conductor = mappedData?.conductor ? new IConductor(mappedData?.conductor) : null;
        this.documento = mappedData?.documento ?? null;
        this.fechaDesde = mappedData?.fechaDesde ?? null;
        this.fechaHasta = mappedData?.fechaHasta ?? null;
        this.fechaCreacion = mappedData?.fechaCreacion ?? null;
        this.usuario = new Usuario(mappedData?.usuario) ?? null;
        this.tipoInforme = mappedData?.tipoInforme ?? null;
    }
}


export class Kilometraje {
    id?: number;
    km_entrada?: number;
    km_salida?: number;
    km_totales?: number;

    constructor() {
        this.id = null;
        this.km_entrada = null;
        this.km_salida = null;
        this.km_totales = null;
    }
}
<div class="btn-group" role="group">
    <div>
        <div (mouseleave)="showDropDown = false">
            <button class="btn btn-sm btn-outline-primary" (click)="showDropDown=!showDropDown; focusOut()">
                <span>Mostrar/Ocultar columnas</span>
                <!--             <span *ngIf="checkedList.length<=0">Mostrar/Ocultar columnas</span> -->
                <!-- <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span> -->
                <i class="fa fa-angle-down"></i>
            </button>
            <div class="drop-show" *ngIf="showDropDown"
                style="max-height: 500px; overflow-y: scroll; overflow-x: hidden; width: max-content; text-align: center;">
                <input type="text" placeholder="Nombre de columna" (keyup)="filterColumns($event)"
                    style="margin: 5px 0; max-width: 95%; text-align: center;">
                <label *ngFor="let a of keys" style="text-align: start; display: flex;">
                    <input type="checkbox" [(ngModel)]="listColumns[a]" (change)="getSelectedValue(listColumns[a],a)"
                        style=" margin:0 2px;" />
                    <span style=" margin-left:2px;">{{a}}</span>
                </label>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary btn-sm rounded-right" (click)="showAllColumns()">Ver todo</button>
</div>

<div class="bg-white rounded p-2 pb-4">
  <div class="container mw-100 mt-3 mb-5">
    <div class="card mb-3">
      <div class="card-header bg-white font-weight-bold h5">
        Ver/editar conductor
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="card mb-3">
              <div class="card-header">Condiciones conductor</div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col">
                    <label for="turno">Turno en licencia</label>
                    <input class="form-control" type="text" readonly id="turno">
                  </div>
                  <div class="form-group col">
                    <label for="porcentaje">Porcentaje</label>
                    <input class="form-control" type="text" readonly id="porcentaje">
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-header">Datos conductor</div>
              <div class="card-body">
                <form [formGroup]="form"
                  (ngSubmit)="form.valid ? openDialogConfirmarUpdate() : openDialogConfirmarUpdate()">


                  <div class="row">
                    <div class="form-group col-sm-6" formArrayName="vehiculo">
                      <div *ngFor="let ve of vehiculo.controls; let i = index">
                        <label><span> Vehiculo : <span>&nbsp; {{i + 1}}º</span></span> </label>
                        <span class="input-group">
                          <select id="vehiculo" class="form-control form-select" formControlName="vehiculo"
                            [formControlName]="i">
                            <option [value]="null">Desvincular licencia</option>
                            <option [value]="vehiculo.id" *ngFor="let vehiculo of listaVehiculos">
                              <span *ngIf="vehiculo.id != 8 && vehiculo.licencia.numero_licencia != null">Licencia :{{vehiculo?.licencia?.numero_licencia}}  -  Vehículo {{vehiculo?.matricula}}</span>
                              <span *ngIf="vehiculo.id == 8">Licencia :{{vehiculo?.licencia?.numero_licencia}}  -  Sin vehículo asociado</span>
                            </option>
                          </select>
                          <button [disabled]="conductor?.fechaBaja != null" type="button"
                            class="btn btn-outline-danger btn-sm" *ngIf="i > 0"
                            (click)="removeVehiculo(i)"><mat-icon>close</mat-icon></button>
                        </span>
                      </div>
                      <button [disabled]="conductor?.fechaBaja != null" type="button"
                        class="btn btn-outline-success btn-sm" (click)="addVehiculo()"><mat-icon>add</mat-icon> Añadir
                        vehículo</button>
                    </div>
                  </div>
                  <div class="row">

                    <div class="form-group col">
                      <label for="fechaAlta">Fecha alta</label>
                      <input class="form-control" type="date" readonly id="fechaAlta" formControlName="fechaAlta">
                    </div>
                    <div class="form-group col">
                      <label for="fechaVencimientoBTP">Vencimiento BTP</label>
                      <input class="form-control" type="date" id="fechaVencimientoBTP"
                        formControlName="fechaVencimientoBTP">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="dni">DNI</label>
                      <input class="form-control" type="text" id="dni" maxlength="9" minlength="9"
                        formControlName="dni">
                      <small class="text-danger"
                        *ngIf="isSubmitted && form.get('dni').errors && form.get('dni').hasError('required')">El campo
                        es obligatorio</small>
                    </div>
                    <div class="form-group col">
                      <label for="nombre">Nombre</label>
                      <input class="form-control" type="text" id="nombre" formControlName="nombre">
                      <small class="text-danger"
                        *ngIf="isSubmitted && form.get('nombre').errors && form.get('nombre').hasError('required')">El
                        campo es obligatorio</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-6">
                      <label for="apellidos">Apellidos</label>
                      <input class="form-control" type="text" id="apellidos" formControlName="apellidos">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-6">
                      <label for="domicilio">Domicilio</label>
                      <input class="form-control" type="text" id="domicilio" formControlName="domicilio">
                    </div>
                    <div class="form-group col-sm-3">
                      <label for="codigoPostal">Código postal</label>
                      <input class="form-control" type="text" id="codigoPostal" formControlName="codigoPostal">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="provincia">Provincia</label>
                      <select id="provincia" class="form-control form-select" formControlName="provincia">
                        <option [value]=0>Selecciona una provincia</option>
                        <option [value]="provincia.id" *ngFor="let provincia of listaProvincias">{{provincia.nombre}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label for="poblacion">Municipio</label>
                      <input class="form-control" type="text" id="poblacion" formControlName="poblacion">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <div class="form-group" formArrayName="mail" *ngFor="let tel of emails.controls; let i = index">
                        <div>
                          <label for="mail">Correo electrónico<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                          <span class="input-group">
                            <input class="form-control" type="email" id="mail" [formControlName]="i" />
                            <button [disabled]="conductor?.fechaBaja != null" type="button"
                              class="btn btn-outline-danger btn-sm" *ngIf="i > 0" (click)="removeEmail(i)"><mat-icon
                                class="iconoTelefono">close</mat-icon></button>
                          </span>
                        </div>
                        <small class="text-danger" *ngIf="isSubmitted && emails.controls[i].hasError('pattern')">El
                          valor introducido no es un email válido</small>
                      </div>
                      <button [disabled]="conductor?.fechaBaja != null" *ngIf="expandedEmail != 3" type="button"
                        class="btn btn-outline-success btn-sm" (click)="addEmail()"><mat-icon
                          class="iconoTelefono">add</mat-icon> Añadir email</button>
                    </div>
                    <div class="form-group col">
                      <div class="form-group" formArrayName="cuentaBancaria"
                        *ngFor="let tel of cuentasBancarias.controls; let i = index">
                        <div>
                          <label for="cuentaBancaria">Cuenta Bancaria<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                          <span class="input-group">
                            <input class="form-control" type="text" id="cuentaBancaria" [formControlName]="i" />
                            <button [disabled]="conductor?.fechaBaja != null" type="button"
                              class="btn btn-outline-danger btn-sm" *ngIf="i > 0"
                              (click)="removeCuentaBancaria(i)"><mat-icon
                                class="iconoTelefono">close</mat-icon></button>
                          </span>
                        </div>
                      </div>
                      <button [disabled]="conductor?.fechaBaja != null" *ngIf="expandedIban != 3" type="button"
                        class="btn btn-outline-success btn-sm" (click)="addCuentaBancaria()"><mat-icon
                          class="iconoTelefono">add</mat-icon> Añadir
                        cuenta</button>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="form-group col-sm-6">
                      <div class="form-group" formArrayName="telefono"
                        *ngFor="let tel of telefonos.controls; let i = index">
                        <div>
                          <label for="telefono">Teléfono<span *ngIf="i > 0">&nbsp;{{i + 1}}</span></label>
                          <span class="input-group">
                            <input class="form-control" type="tel" id="telefono" [formControlName]="i" />
                            <button [disabled]="conductor?.fechaBaja != null" type="button"
                              class="btn btn-outline-danger btn-sm" *ngIf="i > 0" (click)="removeTelefono(i)"><mat-icon
                                class="iconoTelefono">close</mat-icon></button>
                          </span>
                        </div>
                        <small class="text-danger" *ngIf="isSubmitted && telefonos.controls[i].hasError('pattern')">El
                          valor introducido no es un teléfono válido</small>
                      </div>
                      <button [disabled]="conductor?.fechaBaja != null" *ngIf="expandedPhone != 3" type="button"
                        class="btn btn-outline-success btn-sm" (click)="addTelefono()"><mat-icon
                          class="iconoTelefono">add</mat-icon> Añadir teléfono</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-2">
                      <label for="retencion">Retención</label>
                      <input class="form-control" type="text" id="retencion" formControlName="retencion">
                    </div>
                    <div class="form-group col-sm-2">
                      <label for="puntuacion">Puntuación</label>
                      <input class="form-control" type="text" id="puntuacion" formControlName="puntuacion">
                    </div>
                    <div class="form-group col-sm-2">
                      <label for="valoracion">Valoración</label>
                      <input class="form-control" type="text" id="valoracion" formControlName="valoracion">
                    </div>
                  </div>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" [checked]="conductor.lopd"
                      formControlName="lopd">
                    <label class="form-check-label" for="inlineCheckbox1">LOPD</label>
                  </div>
                  <div class="form-check form-check-inline ml-2">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                      [checked]="conductor.riesgosLaborales" formControlName="riesgosLaborales">
                    <label class="form-check-label" for="inlineCheckbox2">Riesgos laborales</label>
                  </div>
                  <div class="row mt-3">
                    <div class="form-group col">
                      <label for="observaciones">Observaciones</label>
                      <textarea class="form-control" id="observaciones" rows="3"
                        formControlName="observaciones"></textarea>
                    </div>
                  </div>

                  <div id="container" class="d-flex justify-content-between position-fixed"
                    style="bottom:6.5vh; right:12vw; z-index: 100">
                    <button *ngIf="conductor?.fechaBaja == null" class="btn btn-success mx-3"
                      type="submit">Guardar</button>
                    <button *ngIf="conductor?.fechaBaja != null" class="btn btn-warning mx-3"
                      type="submit">Activar</button>
                    <button class="btn btn-secondary mx-3" type="button"
                      (click)="form.dirty ? openDialogConfirmarUnsaved() : dialogRef.close()">Cerrar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col" id="collapse">
            <collapse-informacion-component></collapse-informacion-component>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LicenciaServiece } from "../_services/licenciaService.service";
import { Licencia } from "../model/Emisora";
import { NotificationService } from "src/app/_services/notification.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CardDetallesGraficosComponent } from "../novotaxi/licencias/card-detalles-graficos/card-detalles-graficos.component";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ModalBajaComponent } from "../modal/modal-baja/modal-baja.component";
import { ExcelExportService } from "../_services/excel-export.service";
import { InformePDF } from "../novotaxi/licencias/informe-licencia/informePDF";


@Component({
  selector: 'app-listar-licencia',
  templateUrl: './listar-licencia.component.html',
  styleUrls: ['./listar-licencia.component.css'],
})

export class ListarLicenciaComponent implements OnInit {
  displayedColumns: string[] = ['numero_licencia', 'fechaAlta', "propietario.nombre", "propietario.apellidos", "propietario.telefonos", "vehiculo.conductores.nombre",
    "vehiculo.conductores.apellidos", "vehiculo.conductores.telefono", "vehiculo.matricula", "vehiculo.modelo.marca.nombre", "vehiculo.modelo.nombre", "vehiculo.seguro.ciaSeguros.nombre", "propietario.gestoria.nombre", 'dia_libranza', 'emisora.nombre',
    'clave_emisora', 'fechaBaja', 'Acciones'];

  displayedSearchHeaders: string[] = ['licencia_buscador', 'fechaAlta_buscador', 'nombrePropietario_buscador', 'apellidosPropietario_buscador', 'telefonoPropietario_buscador', 'nombreConductor_buscador',
    'apellidosConductor_buscador', 'telefonoConductor_buscador', 'matricula_buscador', 'marca_buscador', 'modelo_buscador', 'ciaSeguros_buscador', 'gestoria_buscador', 'libranza_buscador', 'emisora_buscador',
    'claveEmisora_buscador', 'estadoLicencia_buscador', 'acciones_buscador'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(CardDetallesGraficosComponent) detallesComponent;

  mostrarFormulario: Boolean = false;
  listaMunicipios: any[] = [];
  isMostrarHistorico: Boolean = false;
  tipoDetalle: string = 'licencias';
  licenciasList: Licencia[] = [];

  // -- Variables optimización
  public filters = [];
  public sorts = [];
  public totalElements = 0;

  // -- Paginado
  length = this.licenciasList?.length;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  //variables almacenan listados devueltos del back para mostrarlos.
  listaLicencia = [];

  //variables checkColumnas
  listColumns;

  //Variables filtrado por columnas
  public searchForm: FormGroup;
  public numero_licencia: '';
  public fechaAlta: '';
  public nombre_propietario: '';
  public apellidos_propietario: '';
  public telefonoPropietario: '';
  public nombre_conductor: '';
  public apellidos_conductor: '';
  public telefonoConductor: '';
  public matricula: '';
  public marca: '';
  public modelo: '';
  public ciaSeguros: '';
  public gestoria: '';
  public dia_libranza: '';
  public emisora: '';
  public clave_emisora: '';

  public isEstado_licenciaActivo = true;
  public isEstado_licenciaInactivo = true;


  licenciaActivaAux;
  licenciaTotalAux;
  ELEMENT_DATA: any[] = []
  dataSource: any;
  licenciasCargadas = false;

  constructor(
    private servicioLicencia: LicenciaServiece,
    private matDialog: MatDialog,
    private _notificationService: NotificationService,
    private _excelService: ExcelExportService,
    private informe: InformePDF,
  ) { }


  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  licenciaUpdateDialog;
  editar

  ngOnInit(): void {
    this.listColumns = {
      'Licencia': true,
      'Fecha alta': true,
      'Nombre propietario': true,
      'Apellidos propietario': true,
      'Teléfono propietario': true,
      'Nombre conductor': true,
      'Apellidos conductor': true,
      'Teléfono conductor': true,
      'Matrícula': true,
      'Marca': true,
      'Modelo': true,
      'Compañía de seguros': true,
      'Gestoría': true,
      'Día libranza': true,
      'Emisora': true,
      'Clave emisora': true,
      'Estado licencia': true,
    }

    /*  this.sorts.push({
       "key": "numero_licencia",
       "direction": "DESC"
     }); */

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: 0,
      size: 10,
      /* "groupedby": [
        "vehiculo.id"
      ] */
    }

    // this.listaLicenciasActivas();
    // this.listLicencia();

    this.getAllLicencias(request);
    this.searchFormInit();
    this.listMunicipios();
  }

  generarInforme() {
    let nombre = 'Licencia';
    this.informe.crearPDF(this.dataSource.filteredData, nombre);
  }

  listaLicenciasActivas() {
    this.servicioLicencia.listLicenciasActivas().subscribe(
      async (data) => {
        this.licenciaActivaAux = data.licencias ? data.licencias.length : 0
      });
  }

  listMunicipios() {
    this.servicioLicencia.listMunicipio().subscribe(
      (data) => {
        this.listaMunicipios = data['Municipio']
      });
  }


  setMostrarFormulario(mode: Boolean, licencia?: any, tipo?: Boolean) {
    if (licencia != undefined) {
      if (licencia?.propietario?.id == undefined) {
        licencia.propietario = null;
      }
    }
    this.mostrarFormulario = mode;
    this.licenciaUpdateDialog = licencia;
    this.editar = tipo;
    document.getElementById('scroll').scrollIntoView({ behavior: "smooth" })
    this.detallesComponent.getAllLicencias();

    let request = {
      "filters": this.filters,
      "sorts": this.sorts,
      page: this.paginator.pageIndex,
      size: this.paginator.pageSize,
    }
    this.getAllLicencias(request);
  }

  // -- Inicio filtrado por columnas
  /* searchFormInit() {
    this.searchForm = new FormGroup({
      numero_licencia: new FormControl(''),
      fechaAlta: new FormControl(''),
      nombre_propietario: new FormControl(''),
      apellidos_propietario: new FormControl(''),
      telefonoPropietario: new FormControl(''),
      nombre_conductor: new FormControl(''),
      apellidos_conductor: new FormControl(''),
      telefonoConductor: new FormControl(''),
      matricula: new FormControl(''),
      marca: new FormControl(''),
      modelo: new FormControl(''),
      ciaSeguros: new FormControl(''),
      gestoria: new FormControl(''),
      dia_libranza: new FormControl(''),
      emisora: new FormControl(''),
      clave_emisora: new FormControl(''),
    });
  }

  setActivoInactivo(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isEstado_licenciaActivo = event.target.checked
    }
    if (activoInactivo == 'inactivo') {
      this.isEstado_licenciaInactivo = event.target.checked
    }
    this.applyFilter()
  }

  getFilterPredicate() {
    return (row: any, filters: string) => {

      const filterArray = filters.split('#');
      const numero_licencia = filterArray[0];
      const fechaAlta = filterArray[1];
      const nombre_propietario = filterArray[2];
      const apellidos_propietario = filterArray[3];
      const telefonoPropietario = filterArray[4];
      const nombre_conductor = filterArray[5];
      const apellidos_conductor = filterArray[6];
      const telefonoConductor = filterArray[7];
      const matricula = filterArray[8];
      const marca = filterArray[9];
      const modelo = filterArray[10];
      const ciaSeguros = filterArray[11];
      const gestoria = filterArray[12];
      const dia_libranza = filterArray[13];
      const emisora = filterArray[14];
      const clave_emisora = filterArray[15];

      const matchFilter = [];

      const columnNumeroLicencia = row.numero_licencia != null ? row.numero_licencia : '';
      var date = new Date(row.fechaAlta);
      var dateFormatted = this.datePipe.transform(date, 'dd-MM-yyyy')
      const columnFechaAlta = dateFormatted != null ? dateFormatted : '';
      const columnNombrePropietario = (row.propietario != null && row.propietario?.nombre != null) ? row.propietario.nombre : '';
      const columnApellidosPropietario = (row.propietario != null && row.propietario?.apellidos != null) ? row.propietario.apellidos : '';
      const columnNombreConductor = (row.vehiculo != null && row.vehiculo[0]?.conductores != null && row.vehiculo[0]?.conductores?.nombre != null) ? row.vehiculo[0].conductores.nombre : '';
      const columnApellidosConductor = (row.vehiculo != null && row.vehiculo[0]?.conductores != null && row.vehiculo[0]?.conductores?.apellidos != null) ? row.vehiculo[0].conductores.apellidos : '';
      const columnMatricula = (row.vehiculo != null && row.vehiculo[0]?.matricula != null) ? row.vehiculo[0].matricula : '';
      const columnMarca = (row.vehiculo != null && row.vehiculo[0]?.modelo != null && row.vehiculo[0]?.modelo?.marca != null) ? row.vehiculo[0].modelo.marca.nombre : '';
      const columnModelo = (row.vehiculo != null && row.vehiculo[0]?.modelo != null) ? row.vehiculo[0].modelo.nombre : '';
      const columnCiaSeguros = (row.vehiculo != null && row.vehiculo[0]?.seguro && row.vehiculo[0]?.seguro?.ciaSeguros?.nombre != null) ? row.vehiculo[0].seguro.ciaSeguros.nombre : '';
      const columnGestoria = (row.propietario != null && row.propietario?.gestoria != null && row.propietario?.gestoria?.nombre != null) ? row.propietario.gestoria.nombre : '';
      const columnDiaLibranza = row.dia_libranza != null ? row.dia_libranza : '';
      const columnEmisora = (row.emisora != null && row.emisora?.nombre != null) ? row.emisora.nombre : '';
      const columnClaveEmisora = row.clave_emisora != null ? row.clave_emisora : '';
      const columnIsLicenciaActiva = row.isActive != null ? row.isActive : '';
      const columnTelefonoPropietario = row.propietario?.telefonos.length > 0 ? row.propietario?.telefonos[0] : '';
      const columnTelefonoConductor = row.vehiculo[0]?.conductores?.telefono.length > 0 ? row.vehiculo[0]?.conductores?.telefono[0] : '';




      // if (row.propietario != null) {
      //   columnTelefonoPropietario = row.propietario.telefonos.length > 0 ? row.propietario.telefonos : [''];
      // } else {
      //   columnTelefonoPropietario = ['']
      // }

      // for (let index = 0; index < columnTelefonoPropietario.length; index++) {
      //   if (columnTelefonoPropietario[index].toLowerCase().includes(telefonoPropietario)) {
      //     customFilterTelefonoPropietario = true;
      //     break;
      //   } else {
      //     customFilterTelefonoPropietario = false;
      //   }
      // }



      if (this.isEstado_licenciaActivo && !this.isEstado_licenciaInactivo) {
        matchFilter.push(row.fechaBaja == null)
      } else {
        if (!this.isEstado_licenciaActivo && this.isEstado_licenciaInactivo) {
          matchFilter.push(row.fechaBaja != null)
        }
      }

      matchFilter.push(columnNumeroLicencia.toLowerCase().includes(numero_licencia));
      matchFilter.push(columnFechaAlta.toLowerCase().includes(fechaAlta));
      matchFilter.push(columnNombrePropietario.toLowerCase().includes(nombre_propietario));
      matchFilter.push(columnApellidosPropietario.toLowerCase().includes(apellidos_propietario));
      matchFilter.push(columnTelefonoPropietario.toLowerCase().includes(telefonoPropietario))
      matchFilter.push(columnNombreConductor.toLowerCase().includes(nombre_conductor));
      matchFilter.push(columnApellidosConductor.toLowerCase().includes(apellidos_conductor));
      matchFilter.push(columnTelefonoConductor.toLowerCase().includes(telefonoConductor))
      matchFilter.push(columnMatricula.toLowerCase().includes(matricula));
      matchFilter.push(columnMarca.toLowerCase().includes(marca));
      matchFilter.push(columnModelo.toLowerCase().includes(modelo));
      matchFilter.push(columnCiaSeguros.toLowerCase().includes(ciaSeguros));
      matchFilter.push(columnGestoria.toLowerCase().includes(gestoria));
      matchFilter.push(columnDiaLibranza.toLowerCase().includes(dia_libranza));
      matchFilter.push(columnEmisora.toLowerCase().includes(emisora));
      matchFilter.push(columnClaveEmisora.toLowerCase().includes(clave_emisora));

      return matchFilter.every(Boolean);
    }
  }

  applyFilter() {

    this.numero_licencia = (this.searchForm.value.numero_licencia === null || this.searchForm.value.numero_licencia === '') ? '' : this.searchForm.value.numero_licencia;
    this.fechaAlta = (this.searchForm.value.fechaAlta === null || this.searchForm.value.fechaAlta === '') ? '' : this.searchForm.value.fechaAlta;
    this.nombre_propietario = (this.searchForm.value.nombre_propietario === null || this.searchForm.value.nombre_propietario === '') ? '' : this.searchForm.value.nombre_propietario;
    this.apellidos_propietario = (this.searchForm.value.apellidos_propietario === null || this.searchForm.value.apellidos_propietario === '') ? '' : this.searchForm.value.apellidos_propietario;
    this.telefonoPropietario = (this.searchForm.value.telefonoPropietario === null || this.searchForm.value.telefonoPropietario === '') ? '' : this.searchForm.value.telefonoPropietario;
    this.nombre_conductor = (this.searchForm.value.nombre_conductor === null || this.searchForm.value.nombre_conductor === '') ? '' : this.searchForm.value.nombre_conductor;
    this.apellidos_conductor = (this.searchForm.value.apellidos_conductor === null || this.searchForm.value.apellidos_conductor === '') ? '' : this.searchForm.value.apellidos_conductor;
    this.telefonoConductor = (this.searchForm.value.telefonoConductor === null || this.searchForm.value.telefonoConductor == '') ? '' : this.searchForm.value.telefonoConductor;
    this.matricula = (this.searchForm.value.matricula === null || this.searchForm.value.matricula === '') ? '' : this.searchForm.value.matricula;
    this.marca = (this.searchForm.value.marca === null || this.searchForm.value.marca === '') ? '' : this.searchForm.value.marca;
    this.modelo = (this.searchForm.value.modelo === null || this.searchForm.value.modelo === '') ? '' : this.searchForm.value.modelo;
    this.ciaSeguros = (this.searchForm.value.ciaSeguros === null || this.searchForm.value.ciaSeguros === '') ? '' : this.searchForm.value.ciaSeguros;
    this.gestoria = (this.searchForm.value.gestoria === null || this.searchForm.value.gestoria === '') ? '' : this.searchForm.value.gestoria;
    this.dia_libranza = (this.searchForm.value.dia_libranza === null || this.searchForm.value.dia_libranza === '') ? '' : this.searchForm.value.dia_libranza;
    this.emisora = (this.searchForm.value.emisora === null || this.searchForm.value.emisora === '') ? '' : this.searchForm.value.emisora;
    this.clave_emisora = (this.searchForm.value.clave_emisora === null || this.searchForm.value.clave_emisora === '') ? '' : this.searchForm.value.clave_emisora

    let filterValue = this.numero_licencia + '#' + this.fechaAlta + '#' + this.nombre_propietario + '#' + this.apellidos_propietario + '#' + this.telefonoPropietario + '#' +
      this.nombre_conductor + '#' + this.apellidos_conductor + '#' + this.telefonoConductor + '#' + this.matricula + '#' + this.marca + '#' + this.modelo + '#' + this.ciaSeguros
      + '#' + this.gestoria + '#' + this.dia_libranza + '#' + this.emisora + '#' + this.clave_emisora;

    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  } */

  // -- Fin filtrado por columnas

  newListLicencia = [];

  async getAllLicencias(request?) {

    await this.servicioLicencia.getAllLicencias(request).subscribe(async (data) => {
      this.licenciasList = []
      this.totalElements = 0;

      if (data['licencias']) {
        if (data['licencias']['content']) {
          for (const licenciaJSON of data['licencias']['content']) {
            this.licenciasList.push(new Licencia(licenciaJSON));
          }
        }

        this.totalElements = data['licencias']['totalElements'];
      }

      await this.sortLicencias(request);

      this.dataSource = new MatTableDataSource(this.licenciasList);
    })
  }

  sortLicencias(request) {
    request['sorts']?.forEach(sort => {
      if (sort['key'] == 'propietario.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.propietario?.nombre?.localeCompare(b.propietario?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.propietario?.nombre?.localeCompare(a.propietario?.nombre));
        }
      }

      if (sort['key'] == 'propietario.apellidos') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.propietario?.apellidos?.localeCompare(b.propietario?.apellidos));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.propietario?.apellidos?.localeCompare(a.propietario?.apellidos));
        }
      }

      if (sort['key'] == 'vehiculo.conductores.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.conductores[0]?.nombre?.localeCompare(b.vehiculo[0]?.conductores[0]?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.conductores[0]?.nombre?.localeCompare(a.vehiculo[0]?.conductores[0]?.nombre));
        }
      }

      if (sort['key'] == 'vehiculo.conductores.apellidos') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.conductores[0]?.apellidos?.localeCompare(b.vehiculo[0]?.conductores[0]?.apellidos));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.conductores[0]?.apellidos?.localeCompare(a.vehiculo[0]?.conductores[0]?.apellidos));
        }
      }

      if (sort['key'] == 'vehiculo.matricula') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.matricula?.localeCompare(b.vehiculo[0]?.matricula));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.matricula?.localeCompare(a.vehiculo[0]?.matricula));
        }
      }

      if (sort['key'] == 'vehiculo.modelo.marca.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.modelo?.marca?.nombre?.localeCompare(b.vehiculo[0]?.modelo?.marca?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.modelo?.marca?.nombre?.localeCompare(a.vehiculo[0]?.modelo?.marca?.nombre));
        }
      }

      if (sort['key'] == 'vehiculo.modelo.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.modelo?.nombre?.localeCompare(b.vehiculo[0]?.modelo?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.modelo?.nombre?.localeCompare(a.vehiculo[0]?.modelo?.nombre));
        }
      }

      if (sort['key'] == 'vehiculo.seguro.ciaSeguros.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.vehiculo[0]?.seguro?.ciaSeguros?.nombre?.localeCompare(b.vehiculo[0]?.seguro?.ciaSeguros?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.vehiculo[0]?.seguro?.ciaSeguros?.nombre?.localeCompare(a.vehiculo[0]?.seguro?.ciaSeguros?.nombre));
        }
      }

      if (sort['key'] == 'propietario.gestoria.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.propietario?.gestoria?.nombre?.localeCompare(b.propietario?.gestoria?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.propietario?.gestoria?.nombre?.localeCompare(a.propietario?.gestoria?.nombre));
        }
      }

      if (sort['key'] == 'emisora.nombre') {
        if (sort.direction == "ASC") {
          this.licenciasList = this.licenciasList.sort((a, b) => a.emisora?.nombre?.localeCompare(b.emisora?.nombre));
        } else {
          this.licenciasList = this.licenciasList.sort((a, b) => b.emisora?.nombre?.localeCompare(a.emisora?.nombre));
        }
      }
    });
  }

  /*  listLicencia() {
     this.servicioLicencia.listLicencia().subscribe(
       async (data) => {
         this.licenciasList = data.licencias;
         this.licenciaTotalAux = this.licenciasList ? this.licenciasList.length : 0;
         this.newListLicencia = [];
         let vehiculosToAdd = []
 
         if (this.licenciasList) {
           for (let licencia of this.licenciasList) {
             if (licencia.vehiculo != null && licencia.vehiculo.length > 0) {
               vehiculosToAdd = [];
 
               for (let vehiculo of licencia.vehiculo) {
                 vehiculosToAdd.push(vehiculo)
               }
 
               await firstValueFrom(this.vehiculoService.getAllConductoresFromVehiculo(licencia.id)).then((res) => {
                 let conductores = res['conductores']
                 if (conductores) {
                   for (let conductor of conductores) {
                     let vehiculos = conductor['vehiculos']
                     for (let vehiculo of vehiculos) {
                       conductor.vehiculos = null;
                       vehiculo['conductores'] = conductor;
                       if (licencia?.id == vehiculo?.licencia?.id) {
                         licencia.vehiculo = [vehiculo];
 
                         vehiculosToAdd = vehiculosToAdd.filter(ve => ve.matricula != vehiculo.matricula);
 
                         let licenciaNew = new Licencia(licencia)
 
                         this.newListLicencia.push(licenciaNew);
                       }
                     }
                   }
 
                   if (vehiculosToAdd.length > 0) {
                     for (let vehiculo of vehiculosToAdd) {
                       licencia.vehiculo = [vehiculo]
                       let licenciaNew = new Licencia(licencia)
                       this.newListLicencia.push(licenciaNew);
                     }
                   }
 
                 } else {
                   for (let vehiculo of licencia.vehiculo) {
                     licencia.vehiculo = [vehiculo]
                     let licenciaNew = new Licencia(licencia)
                     this.newListLicencia.push(licenciaNew);
                   }
                 }
               });
 
             } else {
               this.newListLicencia.push(licencia);
             }
           }
         }
 
         this.dataSource = new MatTableDataSource(this.newListLicencia);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
         this.dataSource.sort = this.sort;
 
         //Pasamos el filtro de búsqueda al datasource
         this.dataSource.filterPredicate = this.getFilterPredicate();
 
       });
   } */

  conductorVehiculoAsociado;

  mostrarHistorico(mostrar: Boolean) {
    this.isMostrarHistorico = mostrar;
  }

  validateTelefonoCondcutor(element) {

    var telefono
    if (element.propietario?.telefonos != undefined) {
      telefono = element.propietario.telefonos[0];
      return telefono;
    }
  }

  async openDialogDelete(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '25vw';
    dialogConfig.minWidth = '500px';

    const dialogRef = this.matDialog.open(ModalBajaComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe(result => {
      if (result.result != '') {
        this.deleteLicencia(id, result.result);
      } else {
        null;
      }
    })
  }

  deleteLicencia(id, fecha) {
    this.servicioLicencia.deleteLicencia(id, fecha).subscribe({
      next: (data) => {
        if (data['status'] == '622') {
          this._notificationService.success('Hecho', 'Licencia dada de baja correctamente', 3000);
          let request = {
            "filters": this.filters,
            "sorts": this.sorts,
            page: this.paginator.pageIndex,
            size: this.paginator.pageSize,
          }
          this.getAllLicencias(request);
          this.detallesComponent.getAllLicencias();
        }
      },
    })
  }

  // -- Inicio optimización

  searchFormInit() {
    this.searchForm = new FormGroup({
      numero_licencia: new FormControl(''),
      fechaAlta: new FormControl(''),
      nombre_propietario: new FormControl(''),
      apellidos_propietario: new FormControl(''),
      telefonoPropietario: new FormControl(''),
      nombre_conductor: new FormControl(''),
      apellidos_conductor: new FormControl(''),
      telefonoConductor: new FormControl(''),
      matricula: new FormControl(''),
      marca: new FormControl(''),
      modelo: new FormControl(''),
      ciaSeguros: new FormControl(''),
      gestoria: new FormControl(''),
      dia_libranza: new FormControl(''),
      emisora: new FormControl(''),
      clave_emisora: new FormControl(''),
    });
  }

  async applyFilter(event?, key?, type?) {

    let listOfKeyCodeNotAllowed = [13, 16, 17, 18, 27, 91, 92];
    if (!listOfKeyCodeNotAllowed.includes(event?.keyCode)) {
      // this.paginator.firstPage();
      let value = event.target.value;
      this.doSearch(value, key, type);
    }
  }

  async doSearch(value, key, type) {

    if (!this.filters.find(x => x.key == key)) {
      let filter = {
        key: key,
        operator: "LIKE",
        field_type: type,
        value: value
      }

      if (type.toUpperCase() == "DATE") {
        filter.operator = "BETWEEN"
      }

      if (type.toUpperCase() == "BOOLEAN") {
        filter.operator = "EQUAL"
      }


      if (type.toUpperCase() == "IN") {
        filter.operator = "IN"
        filter.field_type = "STRING"
      }
      if (type.toUpperCase() == "DATE_BETWEEN") {
        filter.operator = "BETWEEN_AUX"
      }

      if (type.toUpperCase() == "CONTAINS_STRING") {
        filter.operator = "CONTAINS"
        filter.field_type = "STRING"
      }

      if (type.toUpperCase() == "ISNULLDATE") {
        this.isNullFechaBaja(filter);
      }

      this.filters.push(filter)
    } else {
      this.filters.forEach(filter => {
        if (filter.key == key) {
          if (!value || value == '') {
            this.filters.splice(this.filters.indexOf(filter), 1).slice(0)
          } else {
            filter.value = value
            if (type.toUpperCase() == "ISNULLDATE") {
              this.isNullFechaBaja(filter);
            }
          }
        }
      });
    }
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: "0",
      size: this.paginator.pageSize,
      /* "groupedby": [
        "vehiculo.id"
      ] */
    }

    this.paginator.firstPage();
    await this.getAllLicencias(request);
  }

  async nextPage(event: PageEvent) {
    let request = {
      filters: this.filters,
      sorts: this.sorts,
      page: event.pageIndex.toString(),
      size: event.pageSize.toString(),
    };
    await this.getAllLicencias(request);
  }

  async sortData(sort: Sort) {
    this.sorts = [];
    this.paginator.firstPage()
    let request = {
      filters: this.filters,
      page: "0",
      size: this.paginator.pageSize,
      // groupedby: ["vehiculo.id"]
    }
    if (sort.direction) {
      let direction = sort.direction.toUpperCase()
      if (sort.active == "telefonos" || sort.active == "emails" || sort.active == "licencias.numero_licencia") {
        if (sort.direction.toUpperCase() == "ASC") {
          direction = "CUSTOM_ASC"
        } else {
          direction = "CUSTOM_DESC"
        }
      }
      this.sorts = [{
        "key": sort.active,
        "direction": direction
      }];
      request['sorts'] = this.sorts;
    }
    await this.getAllLicencias(request)
  }

  isNullFechaBaja(filter) {
    filter.field_type = "DATE";
    if (
      (this.isEstado_licenciaActivo == true && this.isEstado_licenciaInactivo == false) ||
      (this.isEstado_licenciaActivo == false && this.isEstado_licenciaInactivo == true)
    ) {
      if (this.isEstado_licenciaActivo == true) {
        filter.operator = "IS_NULL";
        filter.value = null;
      }

      if (this.isEstado_licenciaInactivo == true) {
        filter.operator = "IS_NOT_NULL";
      }
    } else if (this.isEstado_licenciaActivo == false && this.isEstado_licenciaInactivo == false) {
      filter.operator = "IS_NULL";
    } else if (this.isEstado_licenciaActivo == true && this.isEstado_licenciaInactivo == true) {
      filter.operator = "IS_NULL";
    }
  }

  setActivoInactivo(event, activoInactivo) {
    if (activoInactivo == 'activo') {
      this.isEstado_licenciaActivo = event.target.checked
    }
    if (activoInactivo == 'inactivo') {
      this.isEstado_licenciaInactivo = event.target.checked
    }
    this.applyFilter(event, "fechaBaja", "ISNULLDATE");
  }

  // -- Fin optimización

  formatDate(milliseconds) {
    const date = new Date(milliseconds);
    return date;
  }

  // Función para filtrar los campos no deseados y los valores de campos complejos
  filterFields(item) {
    const { id, propietario, isActive, fechaBaja, fechaModificacion, tpv, vehiculo, usuario, ...rest } = item;

    if (item.usuario) {
      rest.usuario = item.usuario.nombre;
    }

    if (item.emisora) {
      rest.emisora = item.emisora.nombre;
    }

    return rest;
  }

  generateExcel() {

    const data = this.dataSource.data.map((licencia) => {
      const formattedLicencia = { ...licencia };
      formattedLicencia.fechaAlta = this.formatDate(licencia.fechaAlta).toLocaleDateString();
      formattedLicencia.fechaModificacion ? formattedLicencia.fechaModificacion = this.formatDate(licencia.fechaModificacion) : null;
      return formattedLicencia;
    });

    this.addRows(data);

    const dataFormatted = data.map((licencia) => {
      const formattedLicencia = { ...licencia };
      formattedLicencia.fechaAlta = this.formatDate(licencia.fechaAlta).toLocaleDateString();
      formattedLicencia.fechaModificacion ? formattedLicencia.fechaModificacion = this.formatDate(licencia.fechaModificacion) : null;
      return this.filterFields(formattedLicencia);
    });

    const fileName = 'Tabla_licencias';
    this._excelService.exportToExcel(dataFormatted, fileName);
  }

  addRows(data) {
    data.forEach((licencia) => {
      // -- Propietario
      licencia.nombre_propietario = licencia.propietario?.nombre;
      licencia.apellidos_propietario = licencia.propietario?.apellidos;
      licencia.gestoria = licencia.propietario?.gestoria?.nombre;

      // -- conductor
      licencia.nombre_conductor = licencia.vehiculo[0]?.conductores[0]?.nombre;
      licencia.apellidos_conductor = licencia.vehiculo[0]?.conductores[0]?.apellidos;
      licencia.telefonos_conductor = licencia.vehiculo[0]?.conductores[0]?.telefono.join(", ");

      // -- Vehiculo
      licencia.marca = licencia.vehiculo[0]?.modelo?.marca?.nombre;
      licencia.modelo = licencia.vehiculo[0]?.modelo?.nombre;
      licencia.matricula = licencia.vehiculo[0]?.matricula;
      licencia.cia_seguros = licencia.vehiculo[0]?.seguro?.ciaSeguros?.nombre;

      licencia.clave_emisora = licencia.clave_emisora;
      licencia.estado_licencia = licencia.fechaBaja ? "Inactiva" : "Activa";
    });
  }
}

<!-- Título -->
<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Control de informes</h1>
</div>

<hr class="linea-divisoria">

<!-- Tabla y buscador -->
<div class="px-5 pb-4 contenedor mb-5 pt-4" id="scroll">
    <div>

        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="row align-items-center flex-grow-1 crear-editar-form">
                <div class="col-sm-2">
                    <label>Desde</label>
                    <input _ngcontent-vka-c280="" [(ngModel)]="fechainicio"
                        class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
                </div>
                <div class="col-sm-2">
                    <label>Hasta</label>
                    <input _ngcontent-vka-c280="" [(ngModel)]="fechafin"
                        class="form-control form-control-sm ng-pristine ng-invalid ng-touched" type="date">
                </div>
                <!-- <div class="col-sm-2">
                    <label>Licencia</label>
                    <div>
                        <div (mouseleave)="showDropDown = false">
                            <button
                                class="form-control form-control-sm ng-pristine ng-invalid ng-touched btn-outline btn-select"
                                (click)="showDropDown=!showDropDown; focusOut()">
                                <i class="fa fa-angle-down"></i>
                                {{selectedValueLicencia?.numero_licencia}}
                            </button>
                            <div class="drop-show div-drop" *ngIf="showDropDown">
                                <input class="input-filter-select" type="text" placeholder="Licencia"
                                    (keyup)="filterColumns($event)">
                                <label class="label-select" *ngFor="let a of keys">
                                    <input class="input-select" type="button" (click)="getSelectedValue(a)"
                                        [(ngModel)]="a.numero_licencia" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div> -->

                <button class="btn btn-primary btn-sm align-self-end ml-3"
                    (click)="setRequestForSearch(); clearSearchInputs()">
                    <mat-icon class="align-middle mr-2">search</mat-icon><span class="align-middle">Buscar</span>
                </button>
            </div>

            <div class="btn-group btn-group-sm align-self-start" role="group">
                <app-multi-select-dropdown [listColumns]="listColumns">
                </app-multi-select-dropdown>
            </div>
        </div>

        <div class="table_container mt-5">

            <table mat-table [dataSource]="dataSource" matSort class="table align-middle mb-0 bg-white"
                (matSortChange)="sortData($event)">

                <!-- Columna fecha creación-->
                <ng-container matColumnDef="fechaCreacion_buscador">
                    <th [hidden]="!listColumns['Fecha de creación']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="date" aria-label="Fecha de Creacion" formControlName="fechaCreacion"
                                        (change)="applyFilter($event,'fechaCreacion', 'LOCALDATE_BETWEEN')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="fechaCreacion">
                    <th [hidden]="!listColumns['Fecha de creación']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">
                        Fecha de creación
                    </th>
                    <td mat-cell [hidden]="!listColumns['Fecha de creación']" *matCellDef="let element">
                        {{element.fechaCreacion}} </td>
                </ng-container>


                <!-- Columna fecha desde-->
                <ng-container matColumnDef="fechaDesde_buscador">
                    <th [hidden]="!listColumns['Fecha desde']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="date" aria-label="Fecha Desde" formControlName="fechaDesde"
                                        (change)="applyFilter($event,'fechaDesde', 'LOCALDATE_BETWEEN')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="fechaDesde">
                    <th [hidden]="!listColumns['Fecha desde']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">
                        Fecha desde
                    </th>
                    <td mat-cell [hidden]="!listColumns['Fecha desde']" *matCellDef="let element">
                        {{element.fechaDesde}} </td>
                </ng-container>

                <!-- Columna fecha hasta-->
                <ng-container matColumnDef="fechaHasta_buscador">
                    <th [hidden]="!listColumns['Fecha hasta']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="date" aria-label="Fecha Hasta" formControlName="fechaHasta"
                                        (change)="applyFilter($event,'fechaHasta', 'LOCALDATE_BETWEEN')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="fechaHasta">
                    <th [hidden]="!listColumns['Fecha hasta']" mat-header-cell *matHeaderCellDef mat-sort-header
                        class="header">
                        Fecha hasta
                    </th>
                    <td mat-cell [hidden]="!listColumns['Fecha hasta']" *matCellDef="let element">
                        {{element.fechaHasta}} </td>
                </ng-container>


                <!-- Columna tipo gestion-->
                <ng-container matColumnDef="tipoInforme_buscador">
                    <th [hidden]="!listColumns['Tipo informe']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Tipo informe" formControlName="tipoInforme"
                                        (keyup)="applyFilter($event,'tipoInforme', 'ENUM_TIPO_INFORME')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>



                <ng-container matColumnDef="tipoInforme">
                    <th mat-header-cell [hidden]="!listColumns['Tipo informe']" *matHeaderCellDef mat-sort-header
                        class="header">
                        Tipo informe
                    </th>
                    <td mat-cel [hidden]="!listColumns['Tipo informe']" *matCellDef="let element">
                        {{element.tipoInforme}}</td>
                </ng-container>


                <!-- Columna nombre conductor-->
                <ng-container matColumnDef="conductor_nombre_buscador">
                    <th [hidden]="!listColumns['Nombre conductor']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Conductor" formControlName="conductor"
                                        (keyup)="applyFilter($event,'conductor.nombre', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="conductor.nombre">
                    <th mat-header-cell [hidden]="!listColumns['Nombre conductor']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by conductor" class="header">
                        Nombre conductor
                    </th>
                    <td mat-cell [hidden]="!listColumns['Nombre conductor']" *matCellDef="let element">
                        {{element.conductor?.nombre}}</td>
                </ng-container>


                <!-- Columna apellido conductor-->
                <ng-container matColumnDef="conductor_apellido_buscador">
                    <th [hidden]="!listColumns['Apellidos conductor']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Conductor" formControlName="conductor"
                                        (keyup)="applyFilter($event,'conductor.apellidos', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="conductor.apellidos">
                    <th mat-header-cell [hidden]="!listColumns['Apellidos conductor']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by conductor" class="header">
                        Apellidos conductor
                    </th>
                    <td mat-cell [hidden]="!listColumns['Apellidos conductor']" *matCellDef="let element">
                        {{element.conductor?.apellidos}}</td>
                </ng-container>

                <!-- Columna documento-->
                <ng-container matColumnDef="documento_buscador">
                    <th [hidden]="!listColumns['Documento']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Documento" formControlName="documento"
                                        (keyup)="applyFilter($event,'documento.nombre_documento', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="documento.nombre_documento">
                    <th mat-header-cell [hidden]="!listColumns['Documento']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by conductor" class="header">
                        Documento
                    </th>
                    <td mat-cell [hidden]="!listColumns['Documento']" *matCellDef="let element" class="documento">
                        {{element.documento?.nombre_documento}}</td>
                </ng-container>


                <!-- Columna licencia -->
                <ng-container matColumnDef="licencia_buscador">
                    <th [hidden]="!listColumns['Licencia']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Licencia" formControlName="licencia"
                                        (keyup)="applyFilter($event,'licencia.numero_licencia', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="licencia.numero_licencia">
                    <th mat-header-cell [hidden]="!listColumns['Licencia']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by licencia" class="header">
                        Licencia
                    </th>
                    <td mat-cell [hidden]="!listColumns['Licencia']" *matCellDef="let element">
                        {{element.licencia.numero_licencia}}</td>
                </ng-container>




                <!-- Columna nombre propietario-->
                <ng-container matColumnDef="propietario_nombre_buscador">
                    <th [hidden]="!listColumns['Nombre propietario']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="propietario" formControlName="propietario"
                                        (keyup)="applyFilter($event,'propietario.nombre', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="propietario.nombre">
                    <th mat-header-cell [hidden]="!listColumns['Nombre propietario']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by propietario" class="header">
                        Nombre propietario
                    </th>
                    <td mat-cell [hidden]="!listColumns['Nombre propietario']" *matCellDef="let element">
                        {{element.propietario?.nombre}}</td>
                </ng-container>


                <!-- Columna apellido propietario -->
                <ng-container matColumnDef="propietario_apellido_buscador">
                    <th [hidden]="!listColumns['Apellidos propietario']" mat-header-cell *matHeaderCellDef
                        class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Propietario" formControlName="propietario"
                                        (keyup)="applyFilter($event,'propietario.apellidos', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="propietario.apellidos">
                    <th mat-header-cell [hidden]="!listColumns['Apellidos propietario']" *matHeaderCellDef
                        mat-sort-header sortActionDescription="Sort by propietario" class="header">
                        Apellidos propietario
                    </th>
                    <td mat-cell [hidden]="!listColumns['Apellidos propietario']" *matCellDef="let element">
                        {{element.propietario?.apellidos}}</td>
                </ng-container>

                <!-- Columna usuario-->
                <ng-container matColumnDef="usuario_buscador">
                    <th [hidden]="!listColumns['Usuario']" mat-header-cell *matHeaderCellDef class="buscador-tabla">
                        <form [formGroup]="searchForm" class="search__form">
                            <div class="d-flex flex-row align-items-center bg-white rounded">
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mat-icon class="align-middle pl-1">search</mat-icon>
                                </div>
                                <span>
                                    <input (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                                        type="text" aria-label="Usuario" formControlName="usuario"
                                        (keyup)="applyFilter($event,'usuario.nombre', 'STRING')"
                                        placeholder="Buscar por..." class="form-control">
                                </span>
                            </div>
                        </form>
                    </th>
                </ng-container>

                <ng-container matColumnDef="usuario.nombre">
                    <th mat-header-cell [hidden]="!listColumns['Usuario']" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by usuario" class="header">
                        Usuario
                    </th>
                    <td mat-cell [hidden]="!listColumns['Usuario']" *matCellDef="let element">
                        {{element.usuario?.nombre}}</td>
                </ng-container>

                <!-- Columna acciones-->
                <ng-container matColumnDef="acciones_buscador" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="buscador-tabla"></th>
                </ng-container>

                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="header"></th>
                    <td mat-cell *matCellDef="let element" class="mat-column acciones">
                        <div class="buttons-flex-container">
                            <a class="download-button activated" (click)="getDocument(element)"></a>
                            <a class="delete-button activated" (click)="openDialogDelete(element)"></a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedSearchHeaders"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>


            </table>

        </div>

        <mat-paginator class="mt-3" #paginator *ngIf="isSearch" (page)="nextPage($event)" [length]="totalElements"
            [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex" aria-label="Select page">
        </mat-paginator>
    </div>
</div>